import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TourHeader, TourTopbar, RecommendationsTabsBar } from './parts';
import TYPES from '../../constants/types';
import getRelatedEntitiesFromRedux from '../../utils/redux-bee/get-related-entities-from-redux';
import generateCacheResources from '../../utils/redux-bee/generate-cache-resources';
import TourContents from './parts/tour-contents';
import AuthorBox from '../author-box';
import TourSponsorBox from '../tour-sponsor-box';
import { isEmptyObject, isEmpty } from '../../utils';
import VirtualShopBox from './parts/virtual-shop-box';
import {
  CIRCLE_CROSS_ICON,
} from '../../helpers/svg-inline';
import Modal from 'react-modal';
import axios from 'axios';
import ENV from '../../config/environment';

class TourFile extends Component {
  static sortDayTourContents(tourContentsArray) {
    return tourContentsArray.sort((a, b) => a.attributes.order - b.attributes.order);
  }

  static getMappedToursContentsDays(tourContentsData) {
    const tourContentsMappedToDays = {};
    tourContentsData.forEach((tourContent) => {
      if (tourContent) {
        const newTourContent = { ...tourContent };
        const { attributes } = tourContent;
        const { day } = attributes;
        let currentDayLatestTourContentArray = [];
        if (tourContentsMappedToDays[day]) {
          currentDayLatestTourContentArray = [...tourContentsMappedToDays[day]];
        }
        tourContentsMappedToDays[day] = [...currentDayLatestTourContentArray, newTourContent];
      }
    });
    Object.keys(tourContentsMappedToDays).forEach((key) => {
      tourContentsMappedToDays[key] = TourFile.sortDayTourContents(tourContentsMappedToDays[key]);
    });
    return tourContentsMappedToDays;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { tourTourContents, activeDay } = nextProps;
    if (tourTourContents) {
      const { tourContentTypeFilter } = prevState;
      let displayedTourContents = [];
      const tourRecommendations = tourTourContents.filter(
        tourContent =>
          !isEmpty(tourContent) && parseInt(tourContent.attributes['content-type'], 10) === 2,
      );
      const tourTips = tourTourContents.filter(
        tourContent =>
          !isEmpty(tourContent) && parseInt(tourContent.attributes['content-type'], 10) === 8,
      );
      if (tourContentTypeFilter === 1) {
        // If filter = 1 (tour / timeline) all tour-content are visible
        // except the tour-content services with display mode equals to 2 or 5
        displayedTourContents = tourTourContents.filter((tourContent) => {
          const { attributes: { 'content-type': currType, 'display-mode': displayMode } = {} } = tourContent || {};
          return (
            (currType !== 2 && currType !== 8)
            || (currType === 2 && !isEmpty(displayMode) && displayMode !== 2 && displayMode !== 5)
          );
        });
      } else if (tourContentTypeFilter === 2) {
        displayedTourContents = tourRecommendations;
      } else if (tourContentTypeFilter === 8) {
        displayedTourContents = tourTips;
      }

      const nextDayContentMap = TourFile.getMappedToursContentsDays(displayedTourContents);
      const hasRecommedations = tourRecommendations.filter((tourContent) => {
        const {
          attributes: { day: tourContentDay },
        } = tourContent;
        return tourContentDay === activeDay;
      }).length > 0;

      return {
        tourContentsMappedToDays: nextDayContentMap,
        hasRecommedations,
        hasTips: tourTips.length > 0,
      };
    }
    return {};
  }

  static filterTourContents({ tourContents, filters }) {
    return isEmpty(tourContents)
      ? []
      : tourContents.filter((tourContent) => {
        const { attributes } = tourContent;
        let flag = true;
        filters.forEach(({ accessor, comparison, value }) => {
          switch (comparison) {
            case 'equals':
            default:
              if (attributes[accessor] !== value) flag = false;
              break;
          }
        });
        return flag;
      });
  }

  constructor(props) {
    super(props);
    this._requestedMissingResource = false;
    this.state = {
      tourContentTypeFilter: 1,
      tourContentsMappedToDays: {},
      activeCategory: 100,
      hasRecommedations: false,
      hasTips: false,
      virtualShopRefElement: null,
      showModalComments: false,
      tourCampaign: false,
      campaignCarousel: false,
      refresh: true,
    };
    this.handleTourContentTypeFilter = this.handleTourContentTypeFilter.bind(this);
    this.handleDaySelectionAndResetScroll = this.handleDaySelectionAndResetScroll.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleShowRecommendation = this.handleShowRecommendation.bind(this);
    this.handleVirtualShopRef = this.handleVirtualShopRef.bind(this);
    this.authorBoxRef = React.createRef();
    this._previusTimelineScroll = 0;
    this._previusRecommendationsScroll = 0;
    this.__previusTipsScroll = 0;
  }

  openModalComments = () => {
    this.setState({ showModalComments: true })
    const script = document.createElement("script");
    script.src = "https://afkar.ams3.cdn.digitaloceanspaces.com/commento_v16.afkar.js";
    script.async = true;
    document.body.appendChild(script);
  }
  closeModalComments = () => {
    this.setState({ showModalComments: false })
  }

  componentDidMount() {
    const script2 = document.createElement("script");
    script2.src = "https://afkar.ams3.cdn.digitaloceanspaces.com/count.afkar.js";
    document.body.appendChild(script2);
    window.axios = axios;
    const { isMobile } = this.props;
    if (isMobile) {
      window.scrollTo(0, 0);
    }
    const { notCachedResources } = this.props;
    generateCacheResources(notCachedResources);
    this.getCampaign();
  }
  getCampaign = () => {
    if (this.props.tour) {
      fetch(`${ENV.host}/${ENV.namespace}/tours/getCampaign/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tourId: this.props.tour.id
        }),
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.campaign) {
            this.setState({
              tourCampaign: data.campaign
            })
          }
          if (data.campaignCarousel) {
            this.setState({
              campaignCarousel: data.campaignCarousel
            })
          }
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { notCachedResources } = this.props;
    const { tourContentTypeFilter: prevTourContentTypeFilter } = prevState;
    const { tourContentTypeFilter } = this.state;
    if (notCachedResources.length && !this._requestedMissingResource) {
      this._requestedMissingResource = true;
      generateCacheResources(notCachedResources);
    }
    if (prevTourContentTypeFilter !== tourContentTypeFilter) {
      switch (tourContentTypeFilter) {
        case 1:
          window.scrollTo(0, this._previusTimelineScroll);
          break;
        case 2:
          window.scrollTo(0, this._previusRecommendationsScroll);
          break;
        case 8:
          window.scrollTo(0, this._previusTipsScroll);
          break;
        default:
          break;
      }
    }
  }

  handleTourContentTypeFilter(newFilter) {
    const scrollPos = window.scrollY || 0;
    const { tourContentTypeFilter } = this.state;
    switch (tourContentTypeFilter) {
      case 1:
        this._previusTimelineScroll = scrollPos;
        break;
      case 2:
        this._previusRecommendationsScroll = scrollPos;
        break;
      case 8:
        this._previusTipsScroll = scrollPos;
        break;
      default:
        break;
    }
    this.setState({ tourContentTypeFilter: newFilter });
    this.changeDay(true);
  }

  handleDaySelectionAndResetScroll(e, day) {
    const { handleDaySelection } = this.props;

    window.scrollTo(0, 0);
    if (handleDaySelection) handleDaySelection(e, day);
    this.changeDay(true);
  }
  changeDay = (e) => {
    this.setState({ refresh: e })
  }

  handleChangeCategory(newCategory) {
    this.setState({ activeCategory: newCategory });
  }

  handleShowRecommendation(recommendation) {
    this.handleChangeCategory(recommendation.attributes.category);
    this.handleTourContentTypeFilter(2);

  }

  handleVirtualShopRef(virtualShopRefElement) {
    this.setState({
      virtualShopRefElement,
    });
  }

  render() {
    const {
      tour,
      tourOwner,
      tourOwnerAvatar,
      tourDesktopImage,
      tourPhoneImage,
      tourTags,
      selectedImage,
      activeDay,
      handleNavigation,
      handleImageModal,
      isMobile,
    } = this.props;
    const {
      tourContentsMappedToDays,
      tourContentTypeFilter,
      activeCategory,
      hasRecommedations,
      hasTips,
      virtualShopRefElement,

    } = this.state;
    const { filterTourContents } = TourFile;
    const currentTourContents = tourContentTypeFilter === 1
      ? tourContentsMappedToDays[activeDay]
      : tourContentTypeFilter === 8
        ? tourContentsMappedToDays[0]
        : filterTourContents({
          tourContents: tourContentsMappedToDays[activeDay],
          filters: [
            {
              accessor: 'category',
              value: activeCategory,
            },
          ],
        });
    const { 'tour-type': tourKind, 'no-virtual-shop': noVirtualShop } = tour.attributes;
    const hideExtraInfo = tourContentTypeFilter !== 1 || activeDay > 1;
    // console.log("this.state.tourCampaign", this.state.tourCampaign);
    var arraySponsorBox = []
    if (currentTourContents && tour.attributes.selection) {
      arraySponsorBox = currentTourContents.filter(item => item.attributes["content-type"] == 4 && item.attributes["header-text"])
    }
    /* if (isMobile) {
      window.location.href = "https://m.topdesti.com/tourView/selection/" + tour.attributes.selection + "/" + tour.id;
    } */

    return isMobile ? (
      document.getElementById("appWrapper").style.color = "white",
      window.location.href = "https://m.topdesti.com/tourView/selection/" + tour.attributes.selection + "/" + tour.id
    ) : (
      <section className="tour-file">
        <TourTopbar
          tour={tour}
          tourOwner={tourOwner}
          activeDay={activeDay}
          handleDaySelection={this.handleDaySelectionAndResetScroll}
          tourContentTypeFilter={tourContentTypeFilter}
          handleTourContentTypeFilter={this.handleTourContentTypeFilter}
          handleNavigation={handleNavigation}
          hasRecommedations={hasRecommedations}
          hasTips={hasTips}
          virtualShopRefElement={virtualShopRefElement}
          tourTourContents={this.props.tourTourContents}
        />


        {hideExtraInfo ? null : (
          <TourHeader
            tour={tour}
            tourTags={tourTags}
            tourOwner={tourOwner}
            tourOwnerAvatar={tourOwnerAvatar}
            tourDesktopImage={tourDesktopImage}
            tourPhoneImage={tourPhoneImage}
            activeDay={activeDay}
            handleDaySelection={this.handleDaySelectionAndResetScroll}
            authorBoxRef={this.authorBoxRef}
            openModalComments={this.openModalComments}
          />
        )}
        {tourContentTypeFilter === 2 ? (
          <RecommendationsTabsBar
            handleChangeCategory={this.handleChangeCategory}
            activeCategory={activeCategory}
            tourContents={tourContentsMappedToDays[activeDay]}
            activeDay={activeDay}
          />
        ) : null}
        {arraySponsorBox.map((item, index) => (
          <div className="textHeader d-flex flex-row justify-content-center">
            <div className="d-flex flex-column">
              <h3>
                {item.attributes.title}
              </h3>
              {/* {item.attributes.text.replace("<p>", "").replace("</p>", "")} */}
              <div dangerouslySetInnerHTML={{
                __html:
                  item.attributes.text
              }} className="w-100"></div>
            </div>
          </div>
        ))}
        {tourContentTypeFilter === 1 ?
          <TourSponsorBox
            tour={tour}
            tourCampaign={this.state.tourCampaign}
            campaignCarousel={this.state.campaignCarousel}
          /> : null}
        <TourContents
          tour={tour}
          layoutMode={tourKind}
          tourContentTypeFilter={tourContentTypeFilter}
          tourContents={currentTourContents}
          author={tourOwner}
          authorAvatar={tourOwnerAvatar}
          selectedImage={selectedImage}
          activeCategory={activeCategory}
          handleShowRecommendation={this.handleShowRecommendation}
          handleImageModal={handleImageModal}
          refresh={this.state.refresh}
          changeDay={this.changeDay}
        />
        {isEmpty(tourOwner) || noVirtualShop || tourContentTypeFilter !== 1 ? null : (
          <VirtualShopBox authorUser={tourOwner} handleVirtualShopRef={this.handleVirtualShopRef} />
        )}
        {!isEmpty(tourOwner) && !hideExtraInfo ? (
          <AuthorBox author={tourOwner} avatar={tourOwnerAvatar} authorBoxRef={this.authorBoxRef} relatedType="tour" />
        ) : null}
        <div className="d-flex justify-content-center mt-3">
          <button className="btn btn-primary" onClick={this.openModalComments}>comentar</button>
          <Modal className="ModalComments"
            overlayClassName="ContentModal justify-content-end"
            isOpen={this.state.showModalComments}
            contentLabel="ContentModal"
            onRequestClose={this.closeModalComments}
            shouldCloseOnOverlayClick={true}
          >
            <div className="row m-0 d-flex justify-content-center">
              <div className="buttonClose">
                <button onClick={this.closeModalComments} className="closeIcon p-0">
                  {CIRCLE_CROSS_ICON}
                </button>
              </div>
              <div className="d-flex justify-content-start w-100 mt-5 mb-5">
                <span className="title">Tus comentarios de: {tour.attributes.title}</span>
              </div>
              <div id="commento"></div>
            </div>
          </Modal>
        </div>
      </section>
    );
  }
}

TourFile.propTypes = {
  tour: TYPES.tourType.isRequired,
  handleDaySelection: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  tourOwner: TYPES.userType,
  tourOwnerAvatar: TYPES.imageType,
  tourDesktopImage: TYPES.imageType,
  tourPhoneImage: TYPES.imageType,
  selectedImage: TYPES.imageType,
  tourTags: PropTypes.arrayOf(TYPES.tagType),
  notCachedResources: PropTypes.arrayOf(PropTypes.object),
  activeDay: PropTypes.number,
  handleNavigation: PropTypes.func,
  handleImageModal: PropTypes.func,
};

TourFile.defaultProps = {
  tourOwner: null,
  tourOwnerAvatar: null,
  tourDesktopImage: null,
  tourPhoneImage: null,
  selectedImage: null,
  tourTags: null,
  notCachedResources: [],
  activeDay: 1,
  handleNavigation: null,
  handleImageModal: null,
};

function mapStateToProps(state, ownProps) {
  const { tour } = ownProps;
  let tourTags;
  let tourDesktopImage;
  let tourPhoneImage;
  let tourOwner;
  let tourOwnerAvatar;
  let tourTourContents;
  const notCachedResources = [];

  if (tour) {
    // get tour tags from cache
    const [cachedTourTags, missingTourTagsResources] = getRelatedEntitiesFromRedux(
      tour,
      'tags',
      'getTags',
    );
    if (!isEmptyObject(cachedTourTags)) tourTags = cachedTourTags;
    if (!isEmptyObject(missingTourTagsResources)) notCachedResources.push(...missingTourTagsResources);

    // get tour desktop image
    const [cachedTourDesktopImage, missingTourDesktopImageResoruce] = getRelatedEntitiesFromRedux(
      tour,
      'desktop-image',
      'getImage',
    );
    if (!isEmptyObject(cachedTourDesktopImage)) tourDesktopImage = cachedTourDesktopImage;
    if (!isEmptyObject(missingTourDesktopImageResoruce)) notCachedResources.push(...missingTourDesktopImageResoruce);

    // get tour phone image
    const [cachedPhoneImage, missingPhoneImageResources] = getRelatedEntitiesFromRedux(
      tour,
      'phone-image',
      'getImage',
    );
    if (!isEmptyObject(cachedPhoneImage)) tourPhoneImage = cachedPhoneImage;
    if (!isEmptyObject(missingPhoneImageResources)) notCachedResources.push(...missingPhoneImageResources);

    // get tour Owner from cache
    const [cachedTourOwner, missingTourOwnerResources] = getRelatedEntitiesFromRedux(
      tour,
      'owner',
      'getUser',
    );
    if (!isEmptyObject(cachedTourOwner)) tourOwner = cachedTourOwner;
    if (!isEmptyObject(missingTourOwnerResources)) notCachedResources.push(...missingTourOwnerResources);

    if (!isEmptyObject(tourOwner) && !isEmptyObject(tourOwner.relationships)) {
      // tries to get the owner avatar from cache
      const [cachedTourOwnerAvatar, missingTourOwnerAvatarResources] = getRelatedEntitiesFromRedux(
        tourOwner,
        'avatar',
        'getImage',
      );
      if (cachedTourOwnerAvatar) tourOwnerAvatar = cachedTourOwnerAvatar;
      if (!isEmptyObject(missingTourOwnerAvatarResources)) notCachedResources.push(...missingTourOwnerAvatarResources);
    }
    // get child tour contents from cache
    const [cachedToutTourContents, missingTourTourContents] = getRelatedEntitiesFromRedux(
      tour,
      'tour-contents',
      'getTourContent',
    );
    if (!isEmptyObject(cachedToutTourContents)) tourTourContents = cachedToutTourContents;
    if (!isEmptyObject(missingTourTourContents)) notCachedResources.push(...missingTourTourContents);
  }

  return {
    notCachedResources,
    tourTags,
    tourDesktopImage,
    tourPhoneImage,
    tourOwner,
    tourOwnerAvatar,
    tourTourContents,
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(TourFile);
