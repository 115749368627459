const ENV = {
  namespace: 'api/v1',
  environment: 'production',
  deployTarget: 'production',
  // TODO: Change API Key by the owned by TopDesti
  googleApiKey: 'AIzaSyBL9z9dBKYqpJNWSeTyRmCkGtcMQAY6GBg',
  newsletterEndpoint: 'https://acumbamail.com/newform/subscribe/uumyr5vUGWFwzzzV8YZDkKwlZ6qblUBHkIEOdVd/13645/',
};

if (ENV.environment === 'development') {
  ENV.host = 'http://localhost:3000';
} else if (ENV.environment === 'production' && ENV.deployTarget === 'production') {
  ENV.host = 'https://production-topdesti.afkar.digital:3000';
  ENV.newsletterEndpoint = 'https://acumbamail.com/newform/subscribe/5EZyk7Mt9rC7bXXzsDfJKlQmceL9CvdQj28YCbtNFiyawXXfiJahdC27M/13676/';
} else if (ENV.environment === 'staging' && ENV.deployTarget === 'staging') {
  ENV.host = 'https://staging-topdesti.afkar.digital:3000';
  ENV.newsletterEndpoint = 'https://acumbamail.com/newform/subscribe/5EZyk7Mt9rC7bXXzsDfJKlQmceL9CvdQj28YCbtNFiyawXXfiJahdC27M/13677/';
}

export default ENV;