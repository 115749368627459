/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import Gmaps from '../../gmaps';
import {
  CIRCLE_CROSS_ICON,
  EYE_ICON,
  // SIMPLE_FLAG_ICON,
  SOLID_CAMERA_ICON,
  TOUR_SIGNAL_ICON,
  HEART_ICON,
  RECOMMENDATION_BLUE,
} from '../../../helpers/svg-inline';
import {
  isEmpty, formattedDuration, checkExternalUrl, getImageSrcSet,
} from '../../../utils';
import { defaultMapCenter } from '../../../constants';
import TYPES from '../../../constants/types';
import ResponsiveImg from '../../responsive-img';
import Modal from 'react-modal';
import ENV from '../../../config/environment';
import { Helmet } from 'react-helmet';

class PoiFile extends React.Component {
  // this.state = {
  //   showModalTours: false
  // };
  constructor(props, poi) {
    super(props);
    //Estado de los modal por defecto
    this.state = {
      showModalTours: false,
      tours: false,
    };
    //Bind de abrir y cerrar de cada modal
    this.handleOpenModalTours = this.handleOpenModalTours.bind(this);
    this.handleCloseModalTours = this.handleCloseModalTours.bind(this);
  };

  componentDidMount() {
    if (this.props.poi) {
      this.handleOpenModalTours(this.props.poi.id)
    }
  }

  handleOpenModalTours(poi) {
    fetch(`${ENV.host}/${ENV.namespace}/pois/sponsorPlace/${parseInt(poi)}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          /* poi: data.destino,
          avatar: data.avatar.smallThumb, */
          tours: data.tourContent
        });
      });
  }
  handleCloseModalTours() {
    this.setState({ showModalTours: false });
  }
  // modalCloseAndAlert = () => {
  //   this.handleCloseModalMap();
  //   this.handleCloseModalInfo();
  //   this.modalLogOutOpen();
  // }




  render() {

    const { isMobile, poi, currentUrl, likes, views } = this.props;
    const {
      relatedTags: tags,
      // poiOwner: author,
      // poiOwnerAvatar: ownerAvatar,
      attributes = null,
      relatedMainImage,
      relatedImages
    } = poi || {};
    const {
      title = null,
      visualizations,
      description = null,
      'full-address': fullAddress = null,
      website = null,
      'poi-duration': poiDuration = null,
      lat = null,
      long = null,
      'images-counter': imagesCounter = null,
      // 'likes-number': likesNumber = null,
      'tours-counter': toursCounter = null,
    } = attributes || {};



    // const { attributes: { name: authorName = null, surname: authorSurname = null } = {} } = author || {};

    const duration = formattedDuration(poiDuration);

    // deconstruct relationships if present else put some default values
    // relatedMainImage.relatedCreator &&  relatedMainImage.relatedCreator.relatedAvatar
    const { relatedCreator } = relatedMainImage || { relatedCreator: null };
    relatedImages.sort((a, b) => {
      if (a.attributes && b.attributes) {
        if (a.attributes.likes > b.attributes.likes) {
          return -1;
        }
        if (a.attributes.likes < b.attributes.likes) {
          return 1;
        }
        // a must be equal to b
        return 0;
      }
    });

    var mainImage = relatedImages;
    if (mainImage.length > 0)
      mainImage[0].attributes["imageable-property"] = "main-image";

    let metaImage = mainImage[0].attributes && mainImage[0].attributes["small-thumb"] ? mainImage[0].attributes["small-thumb"] : mainImage[0].attributes.smallThumb;
    let genericTextTopdesti = "Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas";
    let genericTitleTopdesti = "Encuentra, comparte y gana dinero con tus viajes y Topdesti";
    return (
      <>
        <Helmet>
          <title>{`| ${title}`}</title>
          <meta property="og:url" content={currentUrl} />
          <meta name="title" content={title ? title : genericTitleTopdesti} />
          <meta property="og:title" content={title ? title : genericTitleTopdesti} />
          <meta property="twitter:title" content={title ? title : genericTitleTopdesti} />
          <meta property="description" content={description ? description : genericTextTopdesti} />
          <meta property="og:description" content={description ? description : genericTextTopdesti} />
          <meta property="twitter:description" content={description ? description : genericTextTopdesti} />
          <meta property="og:url" content={currentUrl} />
          <meta property="og:image" content={metaImage} />
          <meta property="og:image:secure_url" content={metaImage} />
          <meta property="og:image:width" content={isEmpty(metaImage) ? '132' : '600'} />
          <meta property="og:image:height" content={isEmpty(metaImage) ? '38' : '399'} />
          <meta property="twitter:url" content={currentUrl} />
          <meta property="twitter:image" content={metaImage} />
        </Helmet>
        <article className="container-fluid poi-file">
          {isEmpty(mainImage) ? null : (
            <header className="row">
              <div className="col-12 px-0">
                <ResponsiveImg
                  srcSet={getImageSrcSet(mainImage[0])}
                  alt="Portada"
                  className="poi-file__cover-image"
                  wrapperClassName="poi-file__cover"
                >
                  {!isEmpty(relatedCreator) ? (
                    <div className="poi-file__cover-author">
                      <ResponsiveImg
                        src={relatedCreator.relatedAvatar}
                        wrapperClassName="poi-file__cover-author-avatar"
                        type="avatar"
                      >
                        <span className="sr-only">Avatar usuario</span>
                      </ResponsiveImg>

                      <h4 className="poi-file__cover-author-name">
                        {!isMobile ? <small className="poi-file__cover-type">Portada:</small> : null}
                        {`${relatedCreator.attributes.name} ${relatedCreator.attributes.surname}`}
                      </h4>
                      {RECOMMENDATION_BLUE}
                    </div>
                  ) : null}
                </ResponsiveImg>
              </div>
            </header>
          )}
          <div className="row poi-file__wrapper">
            {/* HIDE UNTIL ACTIONS (LIKE, REPORT, ETC.) IMPLEMENTATION
        <div className="col-12 col-lg-6"> */}
            <div className="col-12 col-lg">
              <h3 className="poi-file__title">{title}</h3>
              <h4 className="poi-file__address">{fullAddress}</h4>
              <ul className="poi-file__info">
                <li className="poi-file__info-item">
                  {SOLID_CAMERA_ICON}
                  {isEmpty(imagesCounter) ? 0 : imagesCounter}
                </li>
                <li className="poi-file__info-item">
                  {HEART_ICON}
                  {likes.length}
                </li>
                <li className="poi-file__info-item">
                  {EYE_ICON}
                  {views ? views : 0}
                </li>
                <li className="poi-file__info-item">
                  {TOUR_SIGNAL_ICON}
                  {isEmpty(toursCounter) ? 0 : toursCounter}
                </li>
              </ul>
              <p className="poi-file__description" dangerouslySetInnerHTML={{ __html: description }}></p>
              {!isEmpty(tags) ? (
                <ul className="poi-file__tags">
                  {tags.map(tag => (
                    <li key={tag.id} className="poi-file__tags-item">
                      {tag.attributes.name}
                    </li>
                  ))}
                </ul>
              ) : null}

              {isEmpty(website) ? null : (
                <>
                  <h4 className="poi-file__data-title">Web Oficial:</h4>
                  <a
                    href={checkExternalUrl(website)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="poi-file__data-link"
                  >
                    {website}
                  </a>
                </>
              )}

              {isEmpty(poiDuration) || poiDuration === 0 ? null : (
                <>
                  <h4 className="poi-file__data-title">Tiempo estimado de visita:</h4>
                  <p className="poi-file__data-text">
                    {!isEmpty(duration.hour) ? `${duration.hour}h ` : '0h '}
                    {!isEmpty(duration.min) ? `${duration.min}min` : '00min'}
                  </p>
                </>
              )}

              <div className="poi-file__rrss">
                <div>
                  <p>COMPARTE ESTE DESTINO</p>
                  <FacebookShareButton className="poi-file__rrss-link" url={currentUrl}>
                    <FacebookIcon size={50} round />
                  </FacebookShareButton>

                  <TwitterShareButton className="poi-file__rrss-link" url={currentUrl} title={title}>
                    <TwitterIcon size={50} round />
                  </TwitterShareButton>

                  <WhatsappShareButton className="poi-file__rrss-link" url={currentUrl}>
                    <WhatsappIcon size={50} round />
                  </WhatsappShareButton>
                </div>
                {
                  this.props.isAuthenticated ? (<div className="modal-image-file__rrss-link likePoi">
                    <p>ME GUSTA</p>
                    <button onClick={() => this.props.handleLike()} className="buttonLike">
                      <img
                        alt="like poi button"
                        src={
                          this.props.liked
                            ? '../../../../assets/images/heart.svg'
                            : '../../../../assets/images/heart-outline.svg'
                        }
                      />
                    </button>
                  </div>) : (<div className="modal-image-file__rrss-link likePoi">
                    <p>ME GUSTA</p>
                    <button onClick={this.props.modalLogOutOpen} className="buttonLike">
                      <img
                        alt="like poi button"
                        src={
                          this.props.liked
                            ? '../../../../assets/images/heart.svg'
                            : '../../../../assets/images/heart-outline.svg'
                        }
                      />
                    </button>
                  </div>)
                }
                <div className="modal-image-file__rrss-link">
                  <p>COMENTA</p>
                  <button className="buttonComment" onClick={this.props.openModalComments}>
                    <img alt="comment poi button" src="../../../../assets/images/comment.svg" />
                  </button>
                </div>
              </div>
              <div className="col-12 d-flex flex-column flex-md-row flex-lg-row flex-xl-row poi-file__routes mt-3 mb-3">
                <div className="row d-flex justify-content-center align-items-center">
                  <p>Este destino ha sido agregado a </p><span className="ml-1">{isEmpty(toursCounter) ? 0 : toursCounter} rutas</span><p>, {this.state.tours ? this.state.tours.length : 0}  públicas</p>
                </div>
                <div className="row d-flex justify-content-center align-items-center pl-0 pl-md-1 ml-0 ml-md-3 ml-lg-4 ml-xl-4 mt-3 mt-md-0 mt-lg-0 mt-xl-0">
                  <button className="routesWhite">
                    <img src="../../../../assets/images/rutas_blanco.svg" alt="routes-white" />
                  </button>
                  <button className="externalBlack ml-1" onClick={() => this.setState({ showModalMap: false, showModalInfo: false, showModalTours: true })}>
                    <img src="../../../../assets/images/external-link-symbol_negro.svg" alt="externalBlack" />
                  </button>
                </div>
              </div>
            </div>
            {/* HIDE UNTIL ACTIONS (LIKE, REPORT, ETC.) IMPLEMENTATION
        <div className="col-12 col-lg-6"> */}

            {/*Modal rutas*/}
            <Modal className="ModalMap"
              overlayClassName="ContentModal"
              isOpen={this.state.showModalTours}
              contentLabel="ModalTours"
              shouldCloseOnOverlayClick={true}
              onRequestClose={this.handleCloseModalTours}
            >
              <div className="row m-0 d-flex head-map-modal justify-content-between">
                <div className="contentOneTwo">
                  <div className="d-flex oneH" >

                    {/* {
                            this.props.currentUser.isAuthenticated ? <button onClick={this.handleLike} className="buttonLike">
                              <img
                                alt='like button'
                                src={
                                  this.state.liked ? '../assets/images/heart.svg' : '../assets/images/heart-outline.svg'
                                }
                              />

                            </button> : <button onClick={this.modalCloseAndAlert} className="buttonLike">
                                <img
                                  alt='like button'
                                  src={
                                    this.state.liked ? '../assets/images/heart.svg' : '../assets/images/heart-outline.svg'
                                  }
                                />
                              </button>
                          } */}



                    <div className="col">
                      {poi && poi.attributes ? (
                        <>
                          <h3 className="tour-content-poi-box__title" title={poi.attributes.title}>
                            {poi.attributes.title}
                          </h3>
                          <h4 className="tour-content-poi-box__address">{poi.attributes.township}</h4>
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className="twoH pb-1">
                    {/* <button onClick={this.handleOpenModalMap} className="actionIcon">
                            <img
                              alt='pin button'
                              src={'../assets/images/pin.svg'}
                            />
                          </button>

                          <button onClick={this.handleOpenModalInfo} className="actionIcon">
                            <img
                              alt='information button'
                              src={'../assets/images/information.svg'}
                            />
                          </button> */}

                    {/* <button onClick={()=>this.handleOpenModalTours(poi.id)} className="actionIcon buttonActive">
                      <img
                        alt='reward button'
                        src={'../assets/images/reward.svg'}
                      />
                    </button> */}
                    {/*  <button className="actionIcon">
                      <img
                        alt='comment button'
                        src={'../assets/images/comment2.svg'}
                      />
                    </button> */}
                    {/* <button className="actionIcon">
                      <img
                        alt='camera button'
                        src={'../assets/images/camera.svg'}
                      />
                    </button> */}

                  </div>
                </div>

                <div className="threeH">
                  <button onClick={this.handleCloseModalTours} className="closeIcon p-0">
                    {CIRCLE_CROSS_ICON}
                  </button>
                </div >


              </div>
              <div className={isMobile ? "row modal-mapa-imagenes m-0 h-100" : "row modal-mapa-imagenes m-0"}>
                <div className="MapaImage widthAternative">
                  <div className="col d-flex justify-content-center">
                    {poi && this.state.tours ? (
                      <>
                        <div className="infoTours">
                          <h4 className='ml-4 mb-5'>Rutas públicas donde se han añadido ({this.state.tours.length}):</h4>
                          <div className="tour">
                            {this.state.tours.map((tour, i) => {
                              return (
                                <div className="d-flex division2 align-items-between w-100 tour">
                                  <div className="d-flex align-items-center w-100 ">
                                    <div className='d-block d-sm-block d-md-none d-lg-none d-xl-none'>
                                      <div className="d-flex justify-content-between">
                                        <h5 className='mb-2'>{tour.title}</h5>
                                        <Link to={`/tour/${tour.urlTitle}`} className="mr-2">
                                          <img
                                            src="../../../assets/images/external-link-symbol_azul.svg"
                                            width="22"
                                            alt=""
                                            className=""
                                          />
                                        </Link>
                                      </div>
                                      <div className="d-flex">
                                        <img className='col-5 portada mr-3 p-0' src={tour.desktopImage && tour.desktopImage.tinyThumb ? tour.desktopImage.tinyThumb : '../../../assets/images/empty_photo.svg'} alt="" />
                                        <p> {tour.previousSearchText && tour.previousSearchText.length <= 100 ? tour.previousSearchText : tour.previousSearchText ? tour.previousSearchText.substr(0, 100) + '...' : ''} </p>
                                      </div>
                                    </div>
                                    <img className=' col-3 portada mr-3 d-none  d-md-block d-lg-block d-xl-block' src={tour.desktopImage && tour.desktopImage.tinyThumb ? tour.desktopImage.tinyThumb : '../../../assets/images/empty_photo.svg'} alt="" />
                                    <div className='desc d-none  d-md-block d-lg-block d-xl-block'>
                                      <h5>{tour.title}</h5>
                                      <p>{tour.previousSearchText && tour.previousSearchText.length <= 120 ? tour.previousSearchText : tour.previousSearchText ? tour.previousSearchText.substr(0, 120) + '...' : ''}</p>
                                      {/* <p>{tour.previousSearchText }</p> */}
                                    </div>
                                  </div>
                                  <Link target="_blank" to={`/tour/${tour.urlTitle}`} className="col-1 justify-content-center align-items-center d-none  d-md-flex d-lg-flex d-xl-flex">
                                    <img
                                      src="../../../assets/images/external-link-symbol_azul.svg"
                                      width="25"
                                      alt=""
                                      className="img-link2"
                                    />
                                  </Link>
                                </div>
                              );
                            })}


                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  {/* <div className="contentPartner">
                    <img className="partnerImageMobile"
                      alt='partner button'
                      src={'../assets/images/test-partner.svg'} />
                  </div> */}
                </div>
                <div className="mapRight pdg0 col">
                  <div className="mapRight__top">
                    <img className="partnerImage"
                      alt='partner button'
                      src={this.state.poiCampaign ? this.state.poiCampaign.logo : '../assets/images/test-partner.svg'} />
                    <p>{this.state.poiCampaign ? this.state.poiCampaign.smallText : ""}</p>
                  </div>
                  {this.state.poiCampaign ? (
                    <div className="mapRight__bot">
                      {this.state.poiCampaign.banner ? (
                        <img
                          className="collage"
                          alt="collage button"
                          src={this.state.poiCampaign.banner}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            </Modal>



            <div className="col-12 col-lg-4">
              <div className="row h-100">
                {/* HIDE UNTIL ACTIONS (LIKE, REPORT, ETC.) IMPLEMENTATION
            <div className="col-5 col-lg-6 align-self-lg-center">
              <button
                type="button"
                className={`btn btn-block btn-light btn-rounded poi-file__action-btn ${
                  isMobile ? 'btn-sm' : ''
                }`}
              >
                Me gusta
              </button>
              <button
                type="button"
                className={`btn btn-block btn-light btn-rounded poi-file__action-btn ${
                  isMobile ? 'btn-sm' : ''
                }`}
              >
                Añadir a favoritos
              </button>
              <button
                type="button"
                className={`btn btn-block btn-light btn-rounded poi-file__action-btn ${
                  isMobile ? 'btn-sm' : ''
                }`}
              >
                Enviar a un amigo
              </button>
              <button
                type="button"
                className={`btn btn-block btn-light btn-rounded poi-file__action-btn ${
                  isMobile ? 'btn-sm' : ''
                }`}
              >
                Denunciar contenido
              </button>
            </div> */}
                {isMobile ? null : (
                  // (
                  // <div className="col-7 poi-file__author">
                  //   <ResponsiveImg
                  //     wrapperClassName="poi-file__author-avatar-wrapper"
                  //     className="poi-file__author-avatar"
                  //     srcSet={getImageSrcSet(ownerAvatar)}
                  //     type="avatar"
                  //   >
                  //     <span className="poi-file__flag-icon">{SIMPLE_FLAG_ICON}</span>
                  //   </ResponsiveImg>
                  //   <h4 className="poi-file__author-name">{`${authorName} ${authorSurname}`}</h4>
                  // </div>
                  // )
                  // HIDE UNTIL ACTIONS (LIKE, REPORT, ETC.) IMPLEMENTATION
                  // <div className="col-6 poi-file__map">
                  <div className="col-6 col-lg poi-file__map">
                    <Gmaps
                      defaultZoom={17}
                      defaultCenter={{
                        lat: isEmpty(lat) ? defaultMapCenter.lat : lat,
                        lng: isEmpty(long) ? defaultMapCenter.lng : long,
                      }}
                      markers={[{ lat, lng: long }]}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* {!isMobile ? (
        <footer className="row poi-file__footer">
          <div className="col-12">
            <p className="poi-file__footer-title">Autor</p>
            <ResponsiveImg
              className="poi-file__author-avatar"
              wrapperClassName="poi-file__author-avatar-wrapper"
              type="avatar"
              srcSet={getImageSrcSet(ownerAvatar)}
            >
              <span className="sr-only">{`${authorName} ${authorSurname}`}</span>
            </ResponsiveImg>
            <p className="poi-file__author-name">
              {`${authorName} ${authorSurname}`}
              {SIMPLE_FLAG_ICON}
            </p>
          </div>
        </footer>
      ) : null} */}
        </article>
      </>
    );
  }
}
PoiFile.propTypes = {
  isMobile: PropTypes.bool,
  poi: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.oneOf(['pois']),
    attributes: PropTypes.shape({
      title: PropTypes.string,
    }).isRequired,
    relatedImages: PropTypes.arrayOf(TYPES.imageType).isRequired,
  }).isRequired,
  currentUrl: PropTypes.string,
};

PoiFile.defaultProps = {
  isMobile: false,
  currentUrl: null,
};

function mapStateToProps(state) {
  const currentUrl = window.location.href;

  return {
    currentUrl,
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(PoiFile);
