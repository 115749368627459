import { PropTypes } from 'prop-types';

const imageType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.oneOf(['images']).isRequired,
  attributes: PropTypes.shape({
    'created-at': PropTypes.string,
    description: PropTypes.string,
    'file-content-type': PropTypes.string,
    'file-size': PropTypes.string,
    'imageable-id': PropTypes.number,
    'imageable-property': PropTypes.string,
    'imageable-type': PropTypes.string,
    name: PropTypes.string,
    order: PropTypes.number,
    url: PropTypes.string,
    thumb: PropTypes.string,
    'small-thumb': PropTypes.string,
    'tiny-thumb': PropTypes.string,
    'updated-at': PropTypes.string,
  }),
});

export default imageType;
