import React from 'react';
import { PropTypes } from 'prop-types';

import MultipleThumbnailsWrapper from './parts/multiple-thumbnails-wrapper';
import SingleThumbnailWrapper from './parts/single-thumbnail-wrapper';

function ThumbnailsWrapper(props) {
  const {
    uploaderFiles,
    filesProgresses,
    filesThumbnails,
    uploader,
    uploadBtnCB,
    totalProgress,
    removeFromQueueCB,
    withProgressBars = true,
    withUploadBtn = false,
    withTotalProgressBar = false,
    isDropzone,
    multiple,
    lastQueuedFile,
    children,
    uploadGoingOn,
    totalProgressBarType,
  } = props;
  if (multiple) {
    return (
      <MultipleThumbnailsWrapper
        uploaderFiles={uploaderFiles}
        filesProgresses={filesProgresses}
        filesThumbnails={filesThumbnails}
        uploader={uploader}
        uploadBtnCB={uploadBtnCB}
        totalProgress={totalProgress}
        removeFromQueueCB={removeFromQueueCB}
        withProgressBars={withProgressBars}
        withUploadBtn={withUploadBtn}
        withTotalProgressBar={withTotalProgressBar}
        isDropzone={isDropzone}
        uploadGoingOn={uploadGoingOn}
        totalProgressBarType={totalProgressBarType}
      >
        {children || null}
      </MultipleThumbnailsWrapper>
    );
  }
  let lastQueuedFileId;
  let lastUploadedFileProgress;
  let lastUploadedFileThumbnailUrl;
  if (lastQueuedFile) {
    lastQueuedFileId = lastQueuedFile.id;
    lastUploadedFileProgress = filesProgresses[`file${lastQueuedFileId}`];
    lastUploadedFileThumbnailUrl = filesThumbnails[lastQueuedFile.file.name];
  }
  return (
    <SingleThumbnailWrapper
      uploaderFile={lastQueuedFile}
      fileProgress={lastUploadedFileProgress}
      fileThumbnailUrl={lastUploadedFileThumbnailUrl}
      uploader={uploader}
      uploadBtnCB={uploadBtnCB}
      totalProgress={totalProgress}
      removeFromQueueCB={removeFromQueueCB}
      withProgressBar={withProgressBars}
      withUploadBtn={withUploadBtn}
      withTotalProgressBar={withTotalProgressBar}
      isDropzone={isDropzone}
      uploadGoingOn={uploadGoingOn}
      totalProgressBarType={totalProgressBarType}
    >
      {children}
    </SingleThumbnailWrapper>
  );
}
ThumbnailsWrapper.propTypes = {
  uploader: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.func])).isRequired,
  uploaderFiles: PropTypes.objectOf(PropTypes.object),
  lastQueuedFile: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  ),
  filesProgresses: PropTypes.objectOf(PropTypes.object),
  filesThumbnails: PropTypes.objectOf(PropTypes.string),
  totalProgress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDropzone: PropTypes.bool,
  multiple: PropTypes.bool,
  uploadGoingOn: PropTypes.bool,
  withTotalProgressBar: PropTypes.bool,
  withProgressBars: PropTypes.bool,
  withUploadBtn: PropTypes.bool,
  uploadBtnCB: PropTypes.func,
  removeFromQueueCB: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  totalProgressBarType: PropTypes.string,
};

ThumbnailsWrapper.defaultProps = {
  uploaderFiles: [],
  filesProgresses: [],
  filesThumbnails: [],
  totalProgress: 0,
  isDropzone: true,
  multiple: true,
  uploadGoingOn: false,
  withTotalProgressBar: false,
  withProgressBars: true,
  withUploadBtn: true,
  uploadBtnCB: null,
  removeFromQueueCB: null,
  lastQueuedFile: null,
  totalProgressBarType: 'circular',
};

export default ThumbnailsWrapper;
