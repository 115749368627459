import React from 'react';
import PropTypes from 'prop-types';
import TYPES from '../../../../../../constants/types';
import { isEmpty } from '../../../../../../utils';
import { PromiseButton } from '../../../../../../components';

const TourContentDurationFormTemplate = (props) => {
  const {
    localTourContent,
    handleToggleVisibility,
    handleDelete,
    arrivalTimeFormatted,
    durationHours,
    durationMins,
    handleDurationMinsChange,
    handleDurationHourChange,
    handleArrivalTimeFormattedChange,
  } = props;

  const {
    attributes: { visible: isVisible } = {},
    relationships: {
      'tour-content': { data: parentTourContent },
    },
  } = localTourContent;
  const isVisibleExtraBtns = !isEmpty(localTourContent.id);

  return (
    <div className="container-fluid tour-content-duration-form">
      <div className="row">
        <div className="col-12">
          <label htmlFor="arrival-time" className="tour-content-duration-form__label">
            Hora de llegada (formato 24 horas):
          </label>
          <input
            id="arrival-time"
            type="text"
            value={isEmpty(arrivalTimeFormatted) ? '' : arrivalTimeFormatted}
            placeholder="12:00"
            onChange={handleArrivalTimeFormattedChange}
            className="form-control"
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <label htmlFor="duration" className="tour-content-duration-form__label">
            Tiempo de visita:
          </label>
        </div>
        <div className="col input-group">
          <input
            type="text"
            className="form-control text-right mr-1"
            value={isEmpty(durationHours) ? '' : durationHours}
            onChange={handleDurationHourChange}
          />
          <div className="input-group-append align-self-center">
            <span>h</span>
          </div>
        </div>
        <div className="col input-group">
          <input
            type="text"
            className="form-control text-right mr-1"
            value={isEmpty(durationMins) ? '' : durationMins}
            onChange={handleDurationMinsChange}
          />
          <div className="input-group-append align-self-center">
            <span>min</span>
          </div>
        </div>
      </div>

      {isVisibleExtraBtns
        ? (
          <div className="row justify-content-end">
            <div className="col-12 col-md-auto pr-md-1">
              <button
                type="button"
                onClick={() => handleToggleVisibility()}
                className="btn btn-primary d-block w-100 mt-3"
              >
                {`${isVisible ? 'ocultar' : 'mostrar'} en ${
                  isEmpty(parentTourContent) ? 'la ruta' : 'el destino'
                }`}
              </button>
            </div>
            <div className="col-12 col-md-auto px-md-1">
              <PromiseButton
                type="button"
                onClick={() => handleDelete()}
                className="btn btn-danger d-block w-100 mt-3"
                disabled={isEmpty(localTourContent.id)}
              >
                {`eliminar ${isEmpty(parentTourContent) ? 'de la ruta' : 'del destino'}`}
              </PromiseButton>
            </div>
          </div>
        )
        : null}
    </div>
  );
};

TourContentDurationFormTemplate.propTypes = {
  localTourContent: TYPES.tourContentType.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  arrivalTimeFormatted: PropTypes.string,
  durationHours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  durationMins: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleDurationMinsChange: PropTypes.func.isRequired,
  handleDurationHourChange: PropTypes.func.isRequired,
  handleArrivalTimeFormattedChange: PropTypes.func.isRequired,
};

TourContentDurationFormTemplate.defaultProps = {
  arrivalTimeFormatted: null,
  durationHours: null,
  durationMins: null,
};

TourContentDurationFormTemplate.defaultProps = {};

export default TourContentDurationFormTemplate;
