import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MAP_ICON } from '../helpers';
import TYPES from '../constants/types';
import SimpleMap from './simple-map';
import { isEmpty } from '../utils';
import { GOOGLE_MARKER } from '../utils/google-maps-utils';

function printDirections(map, response, originPosition, destinationPosition) {
  const directionsDisplay = new window.google.maps.DirectionsRenderer();
  window.google.maps.event.clearInstanceListeners(directionsDisplay);
  directionsDisplay.setMap(map);
  directionsDisplay.setOptions({
    optimizeWaypoints: true,
    suppressMarkers: true,
  });

  const { overview_path: overviewPath } = response.routes[0];
  const { duration, distance } = response.routes[0].legs[0];
  const externalLink = `https://www.google.com/maps?saddr=${originPosition.lat()},${originPosition.lng()}`
    + `&daddr=${destinationPosition.lat()},${destinationPosition.lng()}`;

  const infowindow = new window.google.maps.InfoWindow({
    content: `
      <h6 class="mb-0">
        <strong>${duration.text}</strong>
      </h6>
      <p class="mb-1">${distance.text}</p>
      <a href=${externalLink} target="_blank">
        Ver en Google Maps
      </a>
    `,
    position: overviewPath[Math.ceil(overviewPath.length / 2)],
  });

  infowindow.open(map);
  directionsDisplay.setDirections(response);
}

function getDirections(map, markers) {
  const directionsService = new window.google.maps.DirectionsService();
  const [originMarker, destinationMarker] = markers;
  const originPosition = originMarker.getPosition();
  const destinationPosition = destinationMarker.getPosition();
  const request = {
    origin: originPosition,
    destination: destinationPosition,
    travelMode: window.google.maps.TravelMode.DRIVING,
    unitSystem: window.google.maps.UnitSystem.METRIC,
    provideRouteAlternatives: true,
  };

  directionsService.route(request, (response, status) => {
    switch (status) {
      case 'OK':
        printDirections(map, response, originPosition, destinationPosition);
        break;
      case 'OVER_QUERY_LIMIT':
        setTimeout(() => getDirections(map, markers), 500);
        break;
      default:
        console.log(`Error on Google Directions calc with status:${status}`);
        break;
    }
  });
}

function cleanMarkers(markers) {
  if (markers.length > 0) {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
  }
}

function isPoisChanged(prevPois, pois) {
  const noChangedPois = pois.filter(poi => prevPois.find(prevPoi => poi.id === prevPoi.id));

  return prevPois.length !== pois.length || noChangedPois.length !== pois.length;
}

class MapBox extends Component {
  constructor(props) {
    super(props);
    this._mapBoxRef = React.createRef();
    this._mapInstance = null;
    this._mapId = `map-box-${Math.floor(Math.random() * (10000 - 1)) + 1}`;
    this._mapMarkers = [];
    this.isPoisLoaded = false;

    this.state = {
      isVisible: false,
    };

    this.initializeMapBounds = this.initializeMapBounds.bind(this);
    this.handleMapMount = this.handleMapMount.bind(this);
  }

  componentDidMount() {
    const { current: mapBoxElement } = this._mapBoxRef;
    if (!isEmpty(mapBoxElement)) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.setState({
                isVisible: true,
              });
              observer.unobserve(entry.target);
            }
          });
        },
        { rootMargin: '1000px' },
      );
      observer.observe(mapBoxElement);
    }
  }

  componentDidUpdate(prevProps) {
    const { pois: prevPois } = prevProps;
    const { pois } = this.props;

    if (isPoisChanged(prevPois, pois)) {
      this.initializeMapBounds();
    }
  }

  handleMapMount(map) {
    this._mapInstance = map;
  }

  initializeMapBounds() {
    const { _mapInstance } = this;
    const {
      pois, originLabel, showItinerary, zoom, maxZoom,
    } = this.props;
    if (!isEmpty(_mapInstance)) {
      const { _mapMarkers: mapMarkers } = this;
      cleanMarkers(mapMarkers);
      const bounds = new window.google.maps.LatLngBounds();
      pois.forEach((poi, idx) => {
        const { lat, long: lng } = poi.attributes;
        const label = isEmpty(originLabel)
          ? pois.length > 1
            ? idx + 1
            : null
          : idx === 0
            ? originLabel
            : originLabel + 1;
        const mapMarker = new window.google.maps.Marker({
          position: { lat, lng },
          map: _mapInstance,
          icon: GOOGLE_MARKER(label),
          clickable: false,
        });
        mapMarkers.push(mapMarker);
        bounds.extend(mapMarker.getPosition());
      });
      this._mapMarkers = mapMarkers;
      _mapInstance.fitBounds(bounds, 5);
      if (!isEmpty(zoom)) {
        _mapInstance.setZoom(zoom);
      }
      if (!isEmpty(maxZoom) && _mapInstance.getZoom() > maxZoom) {
        _mapInstance.setZoom(maxZoom);
      }

      if (showItinerary) {
        getDirections(_mapInstance, mapMarkers);
      }
    }
  }
  _renderPoisTitle(pois) {
    return pois.map((poi,i) => {
      const label = isEmpty(this.props.originLabel)
        ? pois.length > 1
          ? i + 1
          : null
        : i === 0
          ? this.props.originLabel
          : this.props.originLabel + 1;
      return (
          <p key={i}>
          {poi.attributes.title} ({label})
            <span>
            {
            pois.length>1 && i!=pois.length-1 ? "a" : null
            }
            </span>
          </p>
      )
    })
  }

  render() {
    const { pois, boxCategoryTitle, showItinerary } = this.props;
    const { isVisible } = this.state;
    if (!this.isPoisLoaded) {
      const notLoadedPois = pois.filter(poi => !poi.attributes);
      this.isPoisLoaded = notLoadedPois.length === 0;
    }

    return (
      <article className="container map-box" ref={this._mapBoxRef}>
        {showItinerary ? (<header className="row justify-content-between map-box__header">
          <div className="row m-0 contentTitlesMap">
            {
              pois.length > 1 && <span>De</span>
            }
            {
              pois.length >= 1 && this._renderPoisTitle(pois)
            }
          </div>
          <div className="d-flex align-items-center">
            <div className="col-auto px-1">
              <span className="map-box__category">{boxCategoryTitle}</span>
            </div>
            <div className="col-auto pl-1">
              <figure className="map-box__header-icon">{MAP_ICON}</figure>
            </div>
          </div>
        </header>) :
          (<header className="row justify-content-end map-box__header">
          <div className="d-flex align-items-center">
            <div className="col-auto px-1">
              <span className="map-box__category">{boxCategoryTitle}</span>
            </div>
            <div className="col-auto pl-1">
              <figure className="map-box__header-icon">{MAP_ICON}</figure>
            </div>
          </div>
        </header>)
      }
        <div className="row">
          <div className="col-12 px-0">
            {this.isPoisLoaded && isVisible ? (
              <SimpleMap
                key={this._mapId}
                mapElementId={this._mapId}
                onMapLoad={this.handleMapMount}
                onIdle={this.initializeMapBounds}
              />
            ) : null}
          </div>
        </div>
      </article>
    );
  }
}

MapBox.propTypes = {
  boxCategoryTitle: PropTypes.string.isRequired,
  pois: PropTypes.arrayOf(TYPES.poiType),
  originLabel: PropTypes.number,
  showItinerary: PropTypes.bool,
  zoom: PropTypes.number,
  maxZoom: PropTypes.number,
};

MapBox.defaultProps = {
  pois: [],
  originLabel: null,
  showItinerary: false,
  zoom: null,
  maxZoom: null,
};

export default MapBox;
