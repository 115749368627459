import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TourConfigTipsTemplate from './tour-config-tips-template';
import { isEmpty, confirmationToastr } from '../../../../../utils';
import TYPES from '../../../../../constants/types';
import reduxBeeMakeRequest from '../../../../../utils/redux-bee/redux-bee-make-request';
import { push } from 'connected-react-router';

const TourConfigTips = (props) => {
  const {
    tourInEdition, tourContentTips, tipCovers, dispatch, handleContentSelection, handleBindSaves,
  } = props;

  useEffect(() => {
    handleBindSaves(null);
  }, [handleBindSaves]);

  const handleToggleVisibility = useCallback(
    (tourContent) => {
      const contentToToggle = {
        ...tourContent,
        attributes: { ...tourContent.attributes, visible: !tourContent.attributes.visible },
      };

      return reduxBeeMakeRequest(
        dispatch,
        'updateTourContent',
        { id: contentToToggle.id },
        contentToToggle,
      );
    },
    [dispatch],
  );

  const handleDelete = useCallback(async tourContentToDelete => new Promise((resolve) => {
    confirmationToastr(
      '¿Estás seguro de eliminarlo?',
      () =>
        reduxBeeMakeRequest(
          dispatch,
          'deleteTourContent',
          { id: tourContentToDelete.id },
          {},
        ).then(() => reduxBeeMakeRequest(dispatch, 'getTour', { id: tourInEdition.id }, {})),
      () => resolve(false),
    );
  }), [dispatch, tourInEdition.id]);

  const handleCreateNewTip = () => {
    const newTourContent = {
      type: 'tourContents',
      attributes: {
        'affiliate-link': null,
        'affiliate-text': null,
        'affiliate-title': null,
        'arrival-time': null,
        category: null,
        'content-type': 8,
        day: 0,
        'display-mode': null,
        duration: null,
        'link-url': null,
        lat: null,
        long: null,
        order: null,
        subtitle: null,
        text: null,
        title: null,
        'video-url': null,
        visible: true,
      },
      relationships: {
        tour: {
          data: { type: 'tours', id: tourInEdition.id },
        },
        'tour-content': {
          data: null,
        },
      },
    };

    handleContentSelection(newTourContent);
  };

  return (
    <TourConfigTipsTemplate
      handleCreateNewTip={handleCreateNewTip}
      handleToggleVisibility={handleToggleVisibility}
      handleDelete={handleDelete}
      handleContentSelection={handleContentSelection}
      tourContentTips={tourContentTips}
      tipCovers={tipCovers}
    />
  );
};

TourConfigTips.propTypes = {
  tourInEdition: TYPES.tourType.isRequired,
  tourContentTips: PropTypes.arrayOf(TYPES.tourContentType),
  tipCovers: PropTypes.arrayOf(TYPES.imageType),
  dispatch: PropTypes.func.isRequired,
  handleContentSelection: PropTypes.func.isRequired,
  handleBindSaves: PropTypes.func.isRequired,
};

TourConfigTips.defaultProps = {
  tourContentTips: [],
  tipCovers: [],
};

function mapStateToProps(state) {
  let arrayImages = [];
  let i = 0;
  const { id: tourInEditionId } = state.workspace.tourInEdition;
  const tourInEdition = state.bees.entities.tours &&  state.bees.entities.tours[tourInEditionId];
  const {
    relationships: {
      'tour-contents': { data: tourContentsData },
    },
  } = tourInEdition;
  const tourContents = isEmpty(tourContentsData)
    ? []
    : tourContentsData.map(tourContentData => state.bees.entities && state.bees.entities.tourContents[tourContentData.id]);
  const tourContentTips = isEmpty(tourContents)
    ? []
    : tourContents.filter(tourContent => tourContent.attributes['content-type'] === 8);
  const tipCovers = isEmpty(tourContentTips)
    ? []
    : tourContentTips.reduce((acc, tourContentTip) => {
      let archivesData = tourContentTip.relationships.archives.data;
      if (!isEmpty(archivesData)) {
        archivesData.forEach(element => {
          element.order = state.bees.entities[element.type][element.id].attributes.order;
        });
        archivesData.sort(function (a, b) {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        const coverData = archivesData[0];
        const tipCover = state.bees.entities[coverData.type][coverData.id];
        if (!isEmpty(tipCover)) {
          acc.push(tipCover);
        }
      }
      return acc;
    }, []);

  return {
    tourInEdition,
    tourContentTips,
    tipCovers,
  };
}

export default connect(mapStateToProps)(TourConfigTips);
