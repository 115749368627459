import React from 'react';
import PropTypes from 'prop-types';
import RecommendationImagesBoxTemplate from './RecommendationImagesBoxTemplate';
import TYPES from '../../../../../../../../constants/types';

const RecommendationImagesBox = (props) => {
  const { images, handleShowImages, title } = props;
  return (
    <RecommendationImagesBoxTemplate title={title} images={images} handleShowImages={handleShowImages} />
  );
};

RecommendationImagesBox.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(TYPES.imageType),
  handleShowImages: PropTypes.func.isRequired,
};

RecommendationImagesBox.defaultProps = {
  images: [],
};

export default RecommendationImagesBox;
