/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ModalContentsList from './parts/tour-contents-list/modal-contents-list';
import {
  CLOSE_ICON, PLUS_ICON, CLICK_ICON, DRAG_ICON, INBOX_ICON,
} from '../../../../helpers';
import handleError from '../../../../utils/errorHandler';
import TYPES from '../../../../constants/types';
import { lockBodyScrolling, restoreBodyScrolling } from '../../../../utils';
import { PromiseButton } from '../../../../components';

const SHOW_SEARCH = {
  showSearchTab: true,
  showTourContentModal: false,
  showConfigModal: false,
  showPreviewTab: false,
};
class DayContentModalVersion extends Component {
  static sortDayTourContents(tourContentsArray) {
    return tourContentsArray.sort((a, b) => a.attributes.order - b.attributes.order);
  }

  static getUpdatedOrderLocalTourContents(
    currDayTourContents,
    movedTourContents,
    startIndex,
    newIndex,
  ) {
    const reorderDayTourContents = [...currDayTourContents];
    reorderDayTourContents.splice(startIndex, 1);
    reorderDayTourContents.splice(newIndex, 0, movedTourContents);
    return reorderDayTourContents.map((tourContent, index) => {
      const updatedTourContent = { ...tourContent };
      updatedTourContent.attributes.order = index + 1;
      return updatedTourContent;
    });
  }

  static getMappedToursContentsDays(tourContentsData) {
    const tourContentsMappedToDays = {};
    tourContentsData.forEach((tourContent) => {
      const newTourContent = { ...tourContent };
      const { attributes } = tourContent;
      const { day } = attributes;
      let currentDayLatestTourContentArray = [];
      if (tourContentsMappedToDays[day]) {
        currentDayLatestTourContentArray = [...tourContentsMappedToDays[day]];
      }
      tourContentsMappedToDays[day] = [...currentDayLatestTourContentArray, newTourContent];
    });
    Object.keys(tourContentsMappedToDays).forEach((key) => {
      tourContentsMappedToDays[key] = DayContentModalVersion.sortDayTourContents(
        tourContentsMappedToDays[key],
      );
    });
    return tourContentsMappedToDays;
  }

  static getDerivedStateFromProps(nextProps) {
    const { tourContentsData: nextToursData } = nextProps;
    const nextDayContentMap = DayContentModalVersion.getMappedToursContentsDays(nextToursData);
    return { tourContentsMappedToDays: nextDayContentMap };
  }

  constructor(props) {
    super(props);

    this.state = {
      tourContentsMappedToDays: {},
    };
    this.mapToursContentsDays = this.mapToursContentsDays.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.localTourContentsReorder = this.localTourContentsReorder.bind(this);
    this.serverTourContentsReorder = this.serverTourContentsReorder.bind(this);
    this.handleResetScroll = this.handleResetScroll.bind(this);
  }

  componentDidMount() {
    const { mapToursContentsDays } = this;
    mapToursContentsDays();
  }

  componentDidUpdate(prevProps) {
    const { mapToursContentsDays } = this;
    const {
      tourContentsData: prevTourContentsData,
      isOpen: prevIsOpen,
      isMobile: prevMobile,
    } = prevProps;
    const { tourContentsData, isOpen, isMobile } = this.props;
    if (JSON.stringify(tourContentsData) !== JSON.stringify(prevTourContentsData)) mapToursContentsDays();
    if (prevIsOpen !== isOpen || isMobile !== prevMobile) {
      if (isOpen && !isMobile) lockBodyScrolling();
      else restoreBodyScrolling();
    }
  }

  onDragStart() {
    this.setState({ ongoingDrag: true });
  }

  onDragEnd({ oldIndex, newIndex }) {
    const { serverTourContentsReorder, localTourContentsReorder } = this;
    const { activeDay } = this.props;
    const { tourContentsMappedToDays } = this.state;
    if (oldIndex === newIndex) return;

    const { [activeDay]: currDayTourContents } = tourContentsMappedToDays;
    const movedTourContent = currDayTourContents[oldIndex];
    localTourContentsReorder(oldIndex, newIndex)
      .then(() => serverTourContentsReorder(movedTourContent, newIndex))
      .finally(() => {
        this.setState({ ongoingDrag: false });
      });
  }

  mapToursContentsDays() {
    return new Promise((resolve) => {
      const { tourContentsData } = this.props;
      const tourContentsMappedToDays = DayContentModalVersion.getMappedToursContentsDays(
        tourContentsData,
      );
      this.setState({ tourContentsMappedToDays }, resolve);
    });
  }

  localTourContentsReorder(oldIndex, newIndex) {
    return new Promise((resolve) => {
      const { activeDay, triggerOrderSynchWithDesktop } = this.props;
      const { tourContentsMappedToDays } = this.state;

      const { [activeDay]: currDayTourContents } = tourContentsMappedToDays;
      const movedTourContents = currDayTourContents[oldIndex];
      movedTourContents.attributes.order = newIndex;

      const reorderedDayTourContents = DayContentModalVersion.getUpdatedOrderLocalTourContents(
        currDayTourContents,
        movedTourContents,
        oldIndex,
        newIndex,
      );

      const newState = {
        tourContentsMappedToDays: {
          ...tourContentsMappedToDays,
          [activeDay]: reorderedDayTourContents,
        },
      };
      this.setState(newState, () => {
        triggerOrderSynchWithDesktop();
        resolve();
      });
    });
  }

  serverTourContentsReorder(movedTourContents, newIndex) {
    const { activeDay, handleServerTourContentsReoreder } = this.props;
    const { attributes: movedTourAttrs } = movedTourContents;
    const payload = {
      id: movedTourContents.id,
      type: movedTourContents.type,
      attributes: { ...movedTourAttrs, order: newIndex + 1, day: activeDay },
    };
    return handleServerTourContentsReoreder(movedTourContents.id, payload).catch(err =>
      handleError(err),
    );
  }

  handleResetScroll() {
    const { isMobile } = this.props;
    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { onDragStart, onDragEnd, handleResetScroll } = this;
    const {
      isOpen,
      isMobile,
      handleContentSelection,
      tour,
      activeDay,
      handleNavigation,
      handleDaySelection,
      handleAddDayToTour,
      handleRemoveDay,
      handleDeleteTourContent,
      handleToogleTourContentVisibility,
    } = this.props;
    const { tourContentsMappedToDays, ongoingDrag } = this.state;
    const currDayTourContents = tourContentsMappedToDays[activeDay] || [];

    const { 'tour-duration': tourDuration = 1, 'tour-type': tourType } = tour.attributes || {};
    const days = [];
    for (let i = 1; i <= tourDuration; i += 1) {
      if (i === activeDay) {
        days.push({ value: i, text: `DIA ${i}`, active: true });
      } else {
        days.push({ value: i, text: i, active: false });
      }
    }
    return (
      <Modal
        className="container-fluid tour-content-modal"
        overlayClassName="tour-content-modal__overlay"
        isOpen={isOpen}
        onRequestClose={() => handleNavigation(SHOW_SEARCH)}
        onAfterOpen={handleResetScroll}
      >
        {!isMobile ? (
          <header className="row tour-content-modal__header">
            <div className="col-lg-12 tour-content-edition-modal__header-actions">
              <h4 className="tour-content-modal__header-title">
                Ordenar/editar elementos de la ruta
              </h4>
              <button
                type="button"
                className="tour-content-modal__header-btn"
                onClick={() => handleNavigation(SHOW_SEARCH)}
              >
                {CLOSE_ICON}
              </button>
            </div>
          </header>
        ) : null}
        <div className="row tour-content-modal__content">
          {parseInt(tourType, 10) === 1 ? null : (
            <ul className="col-12 tour-content-modal__day-list">
              {days.map((day, index) => (
                <li key={day.value} className="tour-content-modal__day-item">
                  <button
                    type="button"
                    className={`tour-content-modal__day-btn ${
                      day.active ? 'tour-content-modal__day-btn--active' : ''
                    }`}
                    onClick={() => handleDaySelection(index + 1)}
                  >
                    {day.active ? (
                      <span className="tour-content-modal__day-header">día</span>
                    ) : null}
                    {day.value}
                  </button>
                </li>
              ))}
              <li key="new-day" className="tour-content-modal__day-item">
                <PromiseButton
                  type="button"
                  className="tour-content-modal__day-btn tour-content-modal__day-btn--circle"
                  onClick={handleAddDayToTour}
                  title="Añadir día"
                >
                  {PLUS_ICON}
                </PromiseButton>
              </li>
              <li key="delete-day" className="tour-content-modal__day-item">
                <PromiseButton
                  type="button"
                  className="tour-content-modal__day-btn tour-content-modal__day-btn--circle bg-danger"
                  onClick={handleRemoveDay}
                  title="Eliminar día"
                >
                  {CLOSE_ICON}
                </PromiseButton>
              </li>
            </ul>
          )}
          <div className="col-12 px-1">
            <div className="row tour-content-modal__captions">
              <div className="col-auto px-1 px-lg-3">
                <p className="tour-content-modal__captions-item">
                  <span className="tour-content-modal__captions-item-icon">{INBOX_ICON}</span>
                  <span className="tour-content-modal__captions-item-text">
                    Contenido en el interior
                  </span>
                </p>
              </div>
              <div className="col-auto px-1 px-lg-3">
                <p className="tour-content-modal__captions-item tour-content-modal__captions-item--blue">
                  <span className="tour-content-modal__captions-item-text">Recomendación</span>
                </p>
              </div>
              {/* <div className="col-auto px-1 px-lg-3">
                <p className="tour-content-modal__captions-item tour-content-modal__captions-item--green">
                  <span className="tour-content-modal__captions-item-text">Consejo de viaje</span>
                </p>
              </div> */}
            </div>
          </div>

          <div className="col-12 mb-4 my-lg-2">
            <div className="row flex-nowrap justify-content-between justify-content-lg-center align-items-end">
              <div className="col col-lg-4 pr-1">
                <div className="row flex-nowrap align-items-end">
                  <span className="col-auto tour-content-modal__tuto-icon">{CLICK_ICON}</span>
                  <div className="col pl-1">
                    <h4 className="tour-content-modal__tuto-title">Editar</h4>
                    <p className="tour-content-modal__tuto-text">
                      Edita el contenido haciendo click sobre la caja contenedora
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col-lg-4 pl-1">
                <div className="row flex-nowrap align-items-end">
                  <span className="col-auto tour-content-modal__tuto-icon">{DRAG_ICON}</span>
                  <div className="col pl-1">
                    <h4 className="tour-content-modal__tuto-title">Ordenar</h4>
                    <p className="tour-content-modal__tuto-text">
                      Arrastra la caja con el dedo para ordenar los elementos
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalContentsList
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            handleContentSelection={handleContentSelection}
            currDayTourContents={currDayTourContents}
            handleDeleteTourContent={handleDeleteTourContent}
            handleToogleTourContentVisibility={handleToogleTourContentVisibility}
            ongoingDrag={ongoingDrag}
            handleNavigation={handleNavigation}
            pois={this.props.pois}
          />
        </div>
      </Modal>
    );
  }
}

DayContentModalVersion.propTypes = {
  triggerOrderSynchWithDesktop: PropTypes.func.isRequired,
  handleServerTourContentsReoreder: PropTypes.func.isRequired,
  tour: TYPES.tourType.isRequired,
  handleDeleteTourContent: PropTypes.func.isRequired,
  handleToogleTourContentVisibility: PropTypes.func.isRequired,
  handleDaySelection: PropTypes.func.isRequired,
  handleAddDayToTour: PropTypes.func.isRequired,
  handleRemoveDay: PropTypes.func.isRequired,
  handleContentSelection: PropTypes.func.isRequired,
  handleNavigation: PropTypes.func.isRequired,
  activeDay: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  tourContentsData: PropTypes.arrayOf(TYPES.tourContentType),
};
DayContentModalVersion.defaultProps = {
  tourContentsData: [],
};
function MapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(MapStateToProps)(DayContentModalVersion);
