import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import {
  STAR_ICON,
  SIMPLE_FLAG_ICON,
  INFO_CIRCLE_ICON,
  RECOMMENDATION_ICON,
  TOUR_SIGNAL_ICON,
  TOP_TRAVELER_IMG,
} from '../../../../../../helpers';
import { isEmpty, getImageSrcSet, checkExternalUrl } from '../../../../../../utils';
import TYPES from '../../../../../../constants/types';
import ResponsiveImg from '../../../../../responsive-img';

const TourContentServiceBoxTimelineTemplate = (props) => {
  const {
    owner,
    author,
    authorAvatar,
    poi,
    tourContent,
    images,
    isMobile,
    layoutMode,
    handleCarouselChange,
    carouselPosition,
    handleShowImages,
    handleShowInfo,
    handleShowRecommendation,
  } = props;

  /* Poi info */
  const {
    attributes: {
      title: poiTitle = null,
      'full-address': fullAddress,
      phone,
      website,
      'tours-counter': toursCounter,
    } = {},
  } = poi || {};
  /* Poi owner info */
  const {
    attributes: {
      name: ownerName = null,
      surname: ownerSurname = null,
      toptraveler: ownerTopTraveler = false,
    } = {},
  } = owner || {};
  /* Tour-content info */
  const { attributes: { text: tourContentText = null } = {} } = tourContent;

  const { attributes: { toptraveler: isAuthorToptraveler = false } = {} } = author || {};

  return (
    <article
      className={`container tour-content-service-box tour-content-service-box--timeline ${
        layoutMode === 1
          ? 'tour-content-service-box--simple'
          : layoutMode === 2
            ? 'tour-content-service-box--premium'
            : 'd-none'
      }`}
    >
      <header className="row d-flex justify-content-end tour-content-service-box__header">
          <div className="col-auto px-1">
            <span className="tour-content-service-box__category">Recomendación</span>
          </div>
        <div className="col-auto pl-lg-1">
          <figure className="tour-content-service-box__header-icon">{RECOMMENDATION_ICON}</figure>
        </div>
      </header>

      <div className="row">
        {layoutMode === 2 ? (
          <div className="col-12 col-lg-7 px-0 order-lg-1 mb-3 mb-lg-0">
            {isEmpty(images) ? null : (
              <Carousel
                showThumbs={false}
                showStatus={false}
                emulateTouch
                onChange={handleCarouselChange}
                selectedItem={carouselPosition}
              >
                {images.map(image => (
                  <ResponsiveImg
                    key={`service-image-${image.id}`}
                    className="tour-content-service-box__slide-image"
                    srcSet={getImageSrcSet(image)}
                    forzedVersion={2}
                  />
                ))}
              </Carousel>
            )}
          </div>
        ) : null}

        <div className={`col-12 order-lg-2 ${layoutMode === 1 ? '' : 'col-lg-5'}`}>
          {layoutMode === 2 && !isMobile ? (
            <svg
              className="tour-content-service-box__decorator"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 47.992 464"
            >
              <path
                fill="#FFF"
                d="M47.992 464V0h-7.971s-16.476 63.498-26 166c-4.542 48.881-17.768 166.01-13 298"
              />
            </svg>
          ) : null}
          <div className="row">
            <div className="col">
              <h3 className="tour-content-service-box__title">
                {isEmpty(poiTitle) ? 'Servicio sin nombre' : poiTitle}
                <button
                  type="button"
                  className="tour-content-service-box__info-btn"
                  onClick={handleShowInfo}
                >
                  {INFO_CIRCLE_ICON}
                </button>
              </h3>
              {isEmpty(ownerName) && isEmpty(ownerSurname) ? null : (
                <div className="col p-0 m-0">
                  <p className="tour-content-service-box__owner">
                    Creador original:
              <span className="tour-content-service-box__owner-name">
                      {`${ownerName} ${ownerSurname}`}
                      {ownerTopTraveler
                        ? (
                          <>
                            {STAR_ICON}
                            {SIMPLE_FLAG_ICON}
                          </>
                        )
                        : null}
                    </span>
                  </p>
                </div>
              )}
            </div>
            {isMobile ? (
              <div className="col-auto">
                <span className="tour-content-service-box__tours-counter">
                  {isEmpty(toursCounter) ? 0 : toursCounter}
                  {TOUR_SIGNAL_ICON}
                </span>
              </div>
            ) : null}
          </div>
          {layoutMode === 2 ? (
            <div className="row">
              {isEmpty(fullAddress) ? null : (
                <div className="col col-lg-12 order-lg-1">
                  <p className="tour-content-service-box__address">{fullAddress}</p>
                </div>
              )}
              {isEmpty(phone) ? null : (
                <div className="col col-lg-12 order-lg-3">
                  <p className="tour-content-service-box__phone">{phone}</p>
                </div>
              )}
              {isEmpty(website) ? null : (
                <div className="col-12 order-lg-2">
                  <a
                    className="tour-content-service-box__website"
                    href={checkExternalUrl(website)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {website}
                  </a>
                </div>
              )}
            </div>
          ) : null}
          <div className="row align-items-end">
            <div
              className={`col-12 ${
                layoutMode === 1 ? 'col-lg' : ''
              } order-lg-3 tour-content-service-box__recommendation`}
            >
              <div className="row">
                {isMobile && layoutMode === 2 ? null : (
                  <div className="col-auto tour-content-service-box__author">
                    <ResponsiveImg
                      srcSet={getImageSrcSet(authorAvatar)}
                      className="tour-content-service-box__author-avatar"
                    >
                      {isAuthorToptraveler ? (<span className="tour-content-service-box__author-toptraveler">{TOP_TRAVELER_IMG}</span>) : null}
                    </ResponsiveImg>
                  </div>
                )}
                <div className="col">
                  <p
                    className="tour-content-service-box__recommendation-text"
                    dangerouslySetInnerHTML={{ __html: tourContentText }}
                  />
                </div>
              </div>
            </div>
            {isEmpty(images) ? null : (
              <div className="col-12 col-sm-auto order-lg-6 ml-sm-auto pr-sm-1">
                <button
                  type="button"
                  className="btn btn-primary btn-block mb-3"
                  onClick={handleShowImages}
                >
                  Mis Fotos
                </button>
              </div>
            )}
            <div className="col-12 col-sm-auto order-lg-6 pl-sm-1">
              <button
                type="button"
                className="btn btn-primary btn-block mb-3"
                onClick={() => handleShowRecommendation(tourContent)}
              >
                Ver recomendación
              </button>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

TourContentServiceBoxTimelineTemplate.propTypes = {
  tourContent: TYPES.tourContentType.isRequired,
  poi: TYPES.poiType,
  images: PropTypes.arrayOf(TYPES.imageType),
  owner: TYPES.userType,
  author: TYPES.userType,
  authorAvatar: TYPES.imageType,
  isMobile: PropTypes.bool,
  layoutMode: PropTypes.number,
  carouselPosition: PropTypes.number.isRequired,
  handleCarouselChange: PropTypes.func.isRequired,
  handleShowImages: PropTypes.func.isRequired,
  handleShowInfo: PropTypes.func.isRequired,
  handleShowRecommendation: PropTypes.func.isRequired,
};

TourContentServiceBoxTimelineTemplate.defaultProps = {
  poi: null,
  images: [],
  author: null,
  authorAvatar: null,
  owner: null,
  isMobile: false,
  layoutMode: 0,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(TourContentServiceBoxTimelineTemplate);
