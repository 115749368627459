import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';
import { isEmpty, getImageSrcSet, checkExternalUrl } from '../../../../utils';
import TYPES from '../../../../constants/types';
import { SHOPPING_CART_ICON, TOP_TRAVELER_IMG } from '../../../../helpers';
import ResponsiveImg from '../../../responsive-img';
import { VIDEO_PLAYER_CONFIG } from '../../../../constants';

const VirtualShopBoxTemplate = (props) => {
  const {
    authorUser,
    authorAvatar,
    mainProduct,
    mainProductImages,
    asideProducts,
    productImages,
    isMobile,
    handleCarouselChange,
    carouselPosition,
    handleSelectMainProduct,
    virtualShopRef,
  } = props;

  const {
    id: mainProductId,
    attributes: {
      title, text, 'product-name': productName, price, 'video-url': videoUrl, link,
    },
  } = mainProduct;

  const { attributes: { 'small-thumb': smallThumb = null } = {} } = authorAvatar || {};
  const { attributes: { toptraveler } = {} } = authorUser || {};

  return (
    <article
      className="container virtual-shop-box virtual-shop-box--timeline"
      ref={virtualShopRef}
    >
      <header className="row virtual-shop-box__header">
        {isMobile ? null : (
          <div className="col-auto px-1">
            <span className="virtual-shop-box__category">Tienda online</span>
          </div>
        )}
        <div className="col-auto pl-lg-1">
          <figure className="virtual-shop-box__header-icon">{SHOPPING_CART_ICON}</figure>
        </div>
      </header>

      <div className="row align-items-lg-stretch h-100">
        <div
          className={`col-12 ${
            isEmpty(videoUrl) ? 'col-lg-6' : 'col-md-6'
          } order-2 order-lg-1 virtual-shop-box__carousel-wrapper`}
        >
          <Carousel
            showThumbs={false}
            showStatus={false}
            emulateTouch
            onChange={handleCarouselChange}
            selectedItem={carouselPosition}
          >
            {mainProductImages.map(image => (
              <ResponsiveImg
                key={`carousel-img-${mainProductId}-${image.id}`}
                className="virtual-shop-box__image"
                wrapperClassName="virtual-shop-box__image-wrapper"
                srcSet={getImageSrcSet(image)}
              />
            ))}
          </Carousel>
        </div>
        <div
          className="col-12 col-lg order-1 order-lg-2 pl-lg-5 virtual-shop-box__product-data"
        >
          <div className="row align-items-lg-center">
            {isMobile ? null : (
              <div className="">
                {toptraveler ? (
                  <span className="virtual-shop-box__author-toptraveler">
                    {TOP_TRAVELER_IMG}
                  </span>
                ) : null}
                <ResponsiveImg
                  src={smallThumb}
                  className="virtual-shop-box__author-avatar"
                  type="avatar"
                  alt="Avatar autor"
                  wrapperClassName="col-auto px-0"
                >
                  <figcaption className="sr-only">Avatar</figcaption>

                </ResponsiveImg>
              </div>
            )}
            <div className="col-12 col-lg">
              <h4 className={`virtual-shop-box__title ${toptraveler && 'marginTopTitle'}`}>
                {title}
              </h4>
            </div>
          </div>
          <div className="col-12 px-0 pr-lg-0">
            <p
              className="virtual-shop-box__text customized-text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
          {isEmpty(videoUrl) || isMobile ? null : (
            <div className="col-12 virtual-shop-box__video-wrapper">
              <ReactPlayer url={videoUrl} config={VIDEO_PLAYER_CONFIG} width="100%" height="100%" />
            </div>
          )}
          {isEmpty(productName) && isEmpty(price) ? null : (
            <div className="col-12 mb-md-3 mb-lg-0">
              <div className="row align-items-center">
                {isEmpty(productName) ? null : (
                  <div className="col col-lg-12 pl-0 pl-lg-3">
                    <h4 className="virtual-shop-box__product-name">{productName}</h4>
                  </div>
                )}
                {isEmpty(price) ? null : (
                  <div className="col-auto col-lg-12 pr-0 pr-lg-3">
                    <span className="virtual-shop-box__price">{`${price}€`}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          {isEmpty(link) || isMobile ? null : (
            <div className="col-auto mx-auto my-3">
              <a
                href={checkExternalUrl(link)}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary virtual-shop-box__buy-btn"
              >
                comprar
              </a>
            </div>
          )}
        </div>

        {isEmpty(videoUrl) || !isMobile ? null : (
          <div className="col-12 col-md-6 order-3 virtual-shop-box__video-wrapper">
            <ReactPlayer url={videoUrl} config={VIDEO_PLAYER_CONFIG} width="100%" height="100%" />
          </div>
        )}
        {isEmpty(link) || !isMobile ? null : (
          <div className="col-12 order-4">
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary virtual-shop-box__buy-btn"
            >
              comprar
            </a>
          </div>
        )}
        {isEmpty(asideProducts) ? null : (
          <aside className="col-12 col-lg-auto order-5 order-lg-3 px-0 virtual-shop-box__aside">
            {asideProducts.map((product) => {
              const productMainImage = isEmpty(productImages) || isEmpty(productImages[product.id])
                ? null
                : productImages[product.id][0];
              const {
                attributes: { 'product-name': asideProductName, price: asidePrice },
              } = product;
              return (
                <button
                  type="button"
                  className="virtual-shop-box__aside-product"
                  onClick={() => handleSelectMainProduct(product)}
                  key={`aside-product-${product.id}`}
                >
                  <ResponsiveImg
                    className="virtual-shop-box__aside-image"
                    srcSet={getImageSrcSet(productMainImage)}
                  />
                  {isMobile ? (
                    <>
                      <h5 className="virtual-shop-box__aside-name">{asideProductName}</h5>
                      <span className="virtual-shop-box__aside-price">{`${asidePrice} €`}</span>
                    </>
                  ) : null}
                </button>
              );
            })}
          </aside>
        )}
      </div>
    </article>
  );
};

VirtualShopBoxTemplate.propTypes = {
  authorUser: TYPES.userType,
  authorAvatar: TYPES.imageType,
  mainProduct: TYPES.productType.isRequired,
  mainProductImages: PropTypes.arrayOf(TYPES.imageType),
  asideProducts: PropTypes.arrayOf(TYPES.productType),
  productImages: PropTypes.objectOf(PropTypes.arrayOf(TYPES.imageType)),
  isMobile: PropTypes.bool,
  handleCarouselChange: PropTypes.func.isRequired,
  carouselPosition: PropTypes.number.isRequired,
  handleSelectMainProduct: PropTypes.func.isRequired,
  virtualShopRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

VirtualShopBoxTemplate.defaultProps = {
  authorUser: null,
  authorAvatar: null,
  mainProductImages: [],
  asideProducts: [],
  productImages: null,
  isMobile: false,
  virtualShopRef: null,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(VirtualShopBoxTemplate);
