import React, {
  useEffect, useState, useCallback, Fragment,
} from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import _cloneDeep from 'lodash.clonedeep';
import ReactQuill from 'react-quill';
import ReactTooltip from 'react-tooltip';
import _isEqual from 'lodash.isequal';
import { FineUploader } from '../../../../components';
import TagAjaxManager from '../../../../components/autocomplete-tag/tag-ajax-manager';
import { INFO_CIRCLE_ICON, PENCIL_ICON } from '../../../../helpers';
import { isEmpty, handleError } from '../../../../utils';
import api from '../../../../config/api-endpoints';
import TYPES from '../../../../constants/types';
import reduxBeeMakeRequest from '../../../../utils/redux-bee/redux-bee-make-request';
import { TOUR_STATES, UPDATE_PENDING_CHANGES } from '../../../../constants';

const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];

const TourConfigMainTemplate = (props) => {
  const {
    isMobile,
    dispatch,
    handleBindSaves,
    onFileUploadComplete,
    desktopImageUrl,
    phoneImageUrl,
    tour,
    hasPendingChanges,
  } = props;

  const [localTour, setLocalTour] = useState(_cloneDeep(tour));
  const [showStatusSelection, setShowStatusSelection] = useState(false);
  const [currentState, setCurrentState] = useState({});

  const {
    attributes: {
      title = '',
      'tour-type': tourType = null,
      'previous-search-text': previousSearchText = '',
      description = '',
      state: tourState,
    } = {},
  } = localTour || {};

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      const updatedTour = _cloneDeep(localTour);
      updatedTour.attributes[`${name}`] = name === 'tour-type' ? parseInt(value, 10) : value;
      setLocalTour(updatedTour);
    },
    [localTour],
  );


  const handleTextChange = useCallback((value) => {
    // the empty editor value is '<p><br></p>'
    // before setting the state, check if editor is empty and convert it to empty string if it is
    const richText = value === '<p><br></p>' ? '' : value;

    const updatedTour = _cloneDeep(localTour);
    updatedTour.attributes.description = richText;
    setLocalTour(updatedTour);
  }, [localTour]);

  const handlePreviousSearchTextChange = useCallback((value) => {
    // the empty editor value is '<p><br></p>'
    // before setting the state, check if editor is empty and convert it to empty string if it is
    const richText = value === '<p><br></p>' ? '' : value;

    const updatedTour = _cloneDeep(localTour);
    updatedTour.attributes["previous-search-text"] = richText;
    setLocalTour(updatedTour);
  }, [localTour]);

  const handleShowStatusSelectClick = useCallback(() => {
    setShowStatusSelection(!showStatusSelection);
  }, [showStatusSelection]);

  const handleStatusSelect = useCallback((e) => {
    const {
      target: { value },
    } = e;

    const updatedTour = _cloneDeep(localTour);
    updatedTour.attributes.state = parseInt(value, 10);
    setLocalTour(updatedTour);
    setShowStatusSelection(false);
  }, [localTour]);

  const handleSaveTour = useCallback(
    () => reduxBeeMakeRequest(dispatch, 'updateTour', { id: localTour.id }, localTour)
      .then((updatedTour) => {
        setLocalTour(updatedTour);
        toastr.success('Ruta actualizada', 'Se han guardado los cambios correctamente');
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => Promise.resolve()), [dispatch, localTour],
  );

  useEffect(() => {
    const localTourHasChanges = !_isEqual(localTour.attributes, tour.attributes);
    const tourStateOption = TOUR_STATES
      .reduce((acc, stateOption) => (stateOption.value === tourState ? stateOption : acc), {});
    setCurrentState(tourStateOption);
    if (hasPendingChanges !== localTourHasChanges) {
      dispatch({ type: UPDATE_PENDING_CHANGES, hasPendingChanges: localTourHasChanges });
    }
  }, [dispatch, hasPendingChanges, localTour, tour, tourState]);

  useEffect(() => {
    handleBindSaves(handleSaveTour);
    return () => { handleBindSaves(null); };
  }, [handleBindSaves, handleSaveTour]);

  const coverLayout = (coverUrl, type) => (
    <div className="tour-config-main__input">
      <figure
        className={`
          tour-config-main__input-photo tour-config-main__input-photo--${type}
        `}
        style={
          isEmpty(coverUrl) ? null : { backgroundImage: `url('${coverUrl}')` }
        }
      >
        <span
          className={`${isEmpty(coverUrl) ? 'tour-config-main__input-msg' : 'sr-only'
            }`}
        >
          {type === 'desktop' ? 'ESCRITORIO' : 'MOVIL'}
        </span>
      </figure>
      <span className="tour-config-main__input-icon">{PENCIL_ICON}</span>
    </div>
  );

  return (
    <form onSubmit={e => e.preventDefault()} className="row tour-config-main">
      {isMobile ? (
        <div className="form-group col-12">
          <div className="row">
            <div className="col-auto ml-auto">
              <span className="tour-config-main__label">Estado:</span>
              <button
                type="button"
                className={`tour-config-main__state-btn tour-config-main__state-btn--${currentState.name}`}
                onClick={handleShowStatusSelectClick}
              >
                {currentState.label}
              </button>
              {showStatusSelection ? (
                <div className="custom-state-select">
                  <div className="custom-state-select__list">
                    {TOUR_STATES
                      .filter(stateOption => stateOption.selectable && stateOption.value !== tourState)
                      .map(selectableState => (selectableState.value === 2 && tourState === 3 ? null : (
                        <Fragment key={`tour-state-${selectableState.name}`}>
                          <input
                            type="radio"
                            name="state"
                            id={selectableState.name}
                            aria-checked="false"
                            aria-hidden="true"
                            aria-labelledby={selectableState.name}
                            onChange={handleStatusSelect}
                            value={selectableState.value}
                            checked={selectableState.value === tourState}
                          />
                          <label
                            className={`
                              custom-state-select__list-item custom-state-select__list-item--${selectableState.name}
                            `}
                            htmlFor={selectableState.name}
                            aria-label={selectableState.name}
                            data-tip={`
                              <p class="data-tip">${selectableState.description}</p>
                            `}
                          >
                            {selectableState.label}
                          </label>
                        </Fragment>
                      )))
                    }
                  </div>
                  <ReactTooltip effect="solid" html />
                </div>
              ) : null}
            </div>
            <div className="col-12">
              <p className="tour-config-main__state-description">
                {currentState.description}
              </p>
            </div>
          </div>
        </div>
      ) : null}
      <div className="form-group col-12">
        <label htmlFor="title" className="tour-config-main__label">
          <span
            className="tour-config-main__label-icon"
            data-tip="<p class='data-tip'>Nombre de la ruta y dirección de cómo se verá en los navegadores.
             Por ejemplo “10 cosas que hacer en Madrid” se mostrará como dirección web:<br/>
             www.topdesti.com/ruta/10-cosas-que-hacer-en-madrid</p>"
          >
            {INFO_CIRCLE_ICON}
          </span>
          Título:
        </label>
        <input
          type="text"
          className="form-control"
          id="title"
          name="title"
          value={title || ''}
          onChange={handleChange}
        />
      </div>

      <div className="form-group col-12">
        <p className="tour-config-main__label">
          <span
            className="tour-config-main__label-icon"
            data-place="right"
            data-tip="<p class='data-tip'><b>Itinerario:</b> Muestra los recorridos entre cada destino incorporado.
            Pueden ser de varios días. Ej: 3 días recorriendo Andalucía.<br/>
            <b>Ranking:</b> Muestra una selección de destinos ordenados por importancia e
             incorporando su posición mediante un número. No se pueden dividir por días. Ej:
             Las 5 mejores playas de Ibiza.<br/>
            <b>Simple:</b> No muestra itinerarios ni números de ranking. Pueden ser de varios días.<br/></p>"
          >
            {INFO_CIRCLE_ICON}
          </span>
          Tipo de ruta:
        </p>
        <label htmlFor="itinerary-type" className="tour-config-main__label custom-checkbox right">
          <span>Itinerario</span>
          <input
            type="radio"
            name="tour-type"
            id="itinerary-type"
            value={2}
            checked={tourType === 2}
            onChange={handleChange}
          />
          <i />
        </label>
        <label htmlFor="ranking-type" className="tour-config-main__label custom-checkbox right">
          <span>Ranking</span>
          <input
            type="radio"
            name="tour-type"
            id="ranking-type"
            value={1}
            checked={tourType === 1}
            onChange={handleChange}
          />
          <i />
        </label>
      </div>

      <div className="form-group col-12">
        <p className="tour-config-main__label">
          <span
            className="tour-config-main__label-icon"
            data-place="right"
            data-tip="<p class='data-tip'>
              La primera imagen que verán tus usuarios sobre tus rutas y la que se mostrará en
               los resultados de búsquedas. Para móvil podrás incorporar una en vertical o si no
               quieres mostrar portada puedes dejarla en blanco.
            </p>"
          >
            {INFO_CIRCLE_ICON}

          </span>
          Portada de la ruta:
        </p>
        <div className="tour-config-main__images">
          <FineUploader
            multiple={false}
            endpoint="/images"
            autoUpload
            params={{
              imageableId: tour.id,
              imageableType: 'tour',
              imageableProperty: 'desktop-image',
            }}
            onComplete={onFileUploadComplete || null}
            withProgressBars
            removeThumbAfter={800}
            isDropzone={false}
          >
            {coverLayout(desktopImageUrl, 'desktop')}
          </FineUploader>
          <FineUploader
            endpoint="/images"
            multiple={false}
            autoUpload
            params={{
              imageableId: tour.id,
              imageableType: 'tour',
              imageableProperty: 'phone-image',
            }}
            onComplete={onFileUploadComplete || null}
            withProgressBars
            removeThumbAfter={800}
            isDropzone={false}
          >
            {coverLayout(phoneImageUrl, 'mobile')}
          </FineUploader>
        </div>
      </div>
      <div className="form-group col-12">
        <label className="tour-config-main__label" htmlFor="previous-search-text">
          <span
            className="tour-config-main__label-icon"
            data-place="right"
            data-tip="<p class='data-tip'>Escribe una descripción de una frase para las ventanas previas
             que se muestran en los resultados de búsqueda o cuando compartas la ruta en las redes sociales.<br/>
            Ej: Descubre los mejores lugares donde disfrutar de la naturaleza a menos de una hora de Madrid.</p>"
          >
            {INFO_CIRCLE_ICON}

          </span>
          Texto previo en búsquedas:
        </label>
        <ReactQuill
          id="previous-search-text"
          value={previousSearchText || ''}
          onChange={handlePreviousSearchTextChange}
          className="tour-config-main__rich-text-editor"
          modules={MODULES}
          formats={FORMATS}
        />
      </div>
      <div className="form-group col-12">
        <label className="tour-config-main__label" htmlFor="description">
          <span
            className="tour-config-main__label-icon"
            data-place="right"
            data-tip="<p class='data-tip'>Texto inicial de la ruta tras la portada. Aquí podrás hacer un
             breve resumen al usuario de lo que va a disfrutar leyendo tu contenido.</p>"
          >
            {INFO_CIRCLE_ICON}
          </span>
          Introducción:
        </label>
        <ReactQuill
          id="description"
          value={description || ''}
          onChange={handleTextChange}
          className="tour-config-main__rich-text-editor"
          modules={MODULES}
          formats={FORMATS}
        />
      </div>
      {isMobile ? (
        <div className="form-group col-12">
          <p className="tour-config-main__label">
            <span
              className="tour-config-main__label-icon"
              data-place="right"
              data-tip="<p class='data-tip'>
              Muy importante para que tus rutas aparezcan en las búsquedas de los usuarios.
              Selecciona las etiquetas que mejor identifiquen tu contenido, como categorías
              (#naturaleza, # surf), duración (3d-madrid= 3 días en Madrid, 2h-madrid= 2 horas en
              Madrid) o cualquier aspecto que quieras resaltar y que los usuarios puedan
              incorporar en sus búsquedas.</p>"
            >
              {INFO_CIRCLE_ICON}

            </span>
            Palabras clave:
          </p>
          <TagAjaxManager
            getCurrentResourceTags={() => dispatch(api.getTourTags({ id: tour.id }))}
            associateTagToResource={jsonApiRequest =>
              dispatch(api.upsertTourTags({ id: tour.id }, jsonApiRequest))
            }
            removeTagFromResource={jsonApiRequest =>
              dispatch(
                api.deleteTourTags({ id: tour.id, tagId: jsonApiRequest.data.id }, jsonApiRequest),
              )
            }
            canDelete
            showInput
          />
        </div>
      ) : null}
      <ReactTooltip effect="solid" html />
    </form>
  );
};

TourConfigMainTemplate.propTypes = {
  isMobile: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  handleBindSaves: PropTypes.func.isRequired,
  onFileUploadComplete: PropTypes.func.isRequired,
  desktopImageUrl: PropTypes.string,
  phoneImageUrl: PropTypes.string,
  tour: TYPES.tourType.isRequired,
  hasPendingChanges: PropTypes.bool,
};

TourConfigMainTemplate.defaultProps = {
  isMobile: false,
  hasPendingChanges: false,
  desktopImageUrl: null,
  phoneImageUrl: null,
};

function mapStateToProps(state, ownProps) {
  const { tourId } = ownProps;
  const tour = state.bees.entities.tours[tourId];
  const { hasPendingChanges } = state.workspace;
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    hasPendingChanges,
    tour,
  };
}

export default connect(mapStateToProps)(TourConfigMainTemplate);
