/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { /* withScriptjs, */ withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

class Map extends Component {
  constructor(props) {
    super(props);
    this._map = React.createRef();
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.onBoundsChanged = this.onBoundsChanged.bind(this);
    this.onCenterChanged = this.onCenterChanged.bind(this);
  }

  componentDidMount() {
    const { onMapComponentDidMount } = this.props;
    if (onMapComponentDidMount) onMapComponentDidMount(this._map.current);
  }

  onDragEnd() {
    const { onDragEnd } = this.props;
    if (onDragEnd) onDragEnd();
  }

  onIdle() {
    const { onIdle } = this.props;
    if (onIdle) onIdle();
  }

  // this method name and related this.props function must not be the same to avoid overwriting
  onCenterChanged() {
    const newCoord = this._map.current.getCenter();
    const newBounds = this._map.current.getBounds();
    const { mapCenterChanged } = this.props;
    return mapCenterChanged
      ? mapCenterChanged({ lat: newCoord.lat(), lng: newCoord.lng(), bounds: newBounds })
      : null;
  }

  // this method name and related this.props function must not be the same to avoid overwriting
  onBoundsChanged() {
    const newCoord = this._map.current.getCenter();
    const newBounds = this._map.current.getBounds();
    const { mapBoundsChanged } = this.props;
    return mapBoundsChanged
      ? mapBoundsChanged({ lat: newCoord.lat(), lng: newCoord.lng(), bounds: newBounds })
      : null;
  }

  render() {
    const {
      onCenterChanged, onBoundsChanged, onDragEnd, onIdle,
    } = this;
    const { markers } = this.props;
    return (
      <GoogleMap
        ref={this._map}
        onCenterChanged={onCenterChanged}
        onBoundsChanged={onBoundsChanged}
        onDragEnd={onDragEnd}
        onIdle={onIdle}
        {...this.props}
      >
        {markers
          ? markers.map((marker, index) => {
            const { props: { isHighlighted = false } = {} } = marker;
            let markerSVGUrl = '/assets/images/marker.svg';
            if (isHighlighted) markerSVGUrl = '/assets/images/blue-map.svg';

            return (
              <Marker
                className="testClass"
                icon={{
                  url: markerSVGUrl,
                  scaledSize: new window.google.maps.Size(27, 43),
                }}
                key={`marker-${index}`}
                position={{ lat: marker.lat, lng: marker.lng }}
                {...marker.props}
              />
            );
          })
          : null}
      </GoogleMap>
    );
  }
}

Map.propTypes = {
  onDragEnd: PropTypes.func,
  onIdle: PropTypes.func,
  mapBoundsChanged: PropTypes.func,
  mapCenterChanged: PropTypes.func,
  onMapComponentDidMount: PropTypes.func,
  markers: PropTypes.arrayOf(PropTypes.object),
};

Map.defaultProps = {
  onIdle: null,
  onDragEnd: null,
  mapBoundsChanged: null,
  mapCenterChanged: null,
  onMapComponentDidMount: null,
  markers: null,
};

// withScriptjs(withGoogleMap(Map)); //withScriptJs must be avoided if script is already loaded in index.html
export default withGoogleMap(Map);
