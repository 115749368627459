/* eslint-disable no-shadow */
import React from 'react';
import { handleError, isEmpty } from '../../utils';
import reduxBeeMakeRequest from '../../utils/redux-bee/redux-bee-make-request';
function poiSearchSideEffects(poisRequest, localSearchId) {
  let responseMeta = null;
  // creates a promise that handle the server requests
  // and if the newSearchLocalId is the current one also the rendering of the results
  const serverSearchChainPromise = new Promise((resolve, reject) => {
    this.setState({ isSearchPending: true }, () => {
      let requestData = [];
      if (poisRequest.status !== 200) {
        return Promise.reject(new Error('Problema inesperado en la busqueda de destinos'));
      }
      requestData = (poisRequest.body && poisRequest.body.data) || [];
      responseMeta = poisRequest.body.meta;
      const { populatePois } = this;
      return populatePois(requestData, localSearchId)
        .then((populatedPois) => {
          const { getPoisMarkers } = this;
          // generate a react.ref for every populated poi used when
          // mouse hover a marker to highlight the card
          const poisWithRefs = populatedPois.map(populatedPoi => ({
            ...populatedPoi,
            cardRef: React.createRef(),
          }));
          this.setState({
            isLoading : false,
            isSearchPending: false,
            results: poisWithRefs,
            markers: getPoisMarkers(poisWithRefs),
          });
          return resolve({ responseMeta, localSearchId });
        })
        .catch((err) => {
          const { latestSearchId } = this;
          // latestServerSearch is fetched again because since this code is async this value may have changed!
          // only reject if the failed research is the latest/current one
          if (latestSearchId === localSearchId) {
            this.setState({ isSearchPending: false }, () => {
              handleError(err);
              reject(err);
            });
          }
        });
    });
  });
  return serverSearchChainPromise;
}

function populatePois(pois, currSearchId = null) {
  return new Promise((resolve, reject) => {
    const { populatePoi } = this;
    const populatedPois = [...pois];
    const imagePopulationPromises = pois.map((poi, index) =>
      populatePoi(poi, currSearchId)
        .then((populatedPoi) => {
          populatedPois[index] = populatedPoi;
        })
        .catch(err => reject(err)),
    );

    return Promise.all(imagePopulationPromises).then(() => resolve(populatedPois));
  });
}

function populatePoi(poi, currSearchId = null) {
  return new Promise((resolve, reject) => {
    const { dispatch } = this.props;
    const { latestSearchId } = this;
    const populatedPoi = { ...poi };
    if (currSearchId && latestSearchId !== currSearchId) {
      resolve();
    }

    const imagesPromise = [];
    // const imagesPromise = reduxBeeMakeRequest(dispatch, 'getPoiImages', { id: poi.id }, {});
    const ownerPromise = reduxBeeMakeRequest(dispatch, 'getUser', { id: poi.relationships.owner.data.id }, {});

    return Promise.all([imagesPromise, ownerPromise])
      .then(([poiImages, poiOwner]) => {
        const { relationships: { 'main-image': { data: mainImageData = null } = {} } = {} } = populatedPoi || {};
        const poiMainImage = isEmpty(mainImageData) || isEmpty(poiImages)
          ? null
          : poiImages.find(img => img.id === mainImageData.id);

        populatedPoi.attributes.mainImage = poiMainImage || poiImages[0] || null;
        populatedPoi.attributes.relatedOwner = poiOwner;
        populatedPoi.reactRef = React.createRef();
        resolve(populatedPoi);
      })
      .catch(err => reject(err));
  });
}

function getPoisMarkers(pois) {
  const { highlightRelatedPoiCard, setSelectedPoi } = this;
  return pois.reduce((acc, poi, index) => {
    if (poi) {
      const { cardRef, id: poiId } = poi;
      const { lat, long } = poi.attributes;
      if (!isEmpty(lat) && !isEmpty(long)) {
        acc.push({
          lat,
          lng: long,
          poiId,
          props: {
            ref: cardRef,
            onMouseOver: () => {
              highlightRelatedPoiCard(index, poi);
            },
            onMouseOut: () => {
              highlightRelatedPoiCard(null, poi);
            },
            onClick: () => {
              setSelectedPoi(poi);
              highlightRelatedPoiCard(index, poi);
            },
            onTouch: () => {
              setSelectedPoi(poi);
              highlightRelatedPoiCard(index, poi);
            },
          },
        });
      }
    }
    return acc;
  }, []);
}

function highlightRelatedPoiCard(index) {
  const { results } = this.state;
  const highlightedResults = results.map((currPoi, currIndx) => {
    const newPoi = { ...currPoi };
    newPoi.isHighlighted = index === currIndx;
    return newPoi;
  });
  this.setState({ results: highlightedResults });
}

function getArrayOfUniqueIdsOfRelatedPois(tourContentsAlreadyPresent) {
  if (tourContentsAlreadyPresent) {
    // create an array of related poi ids from the received tour contents with relationships poi
    const alreadyPresentPoiIds = tourContentsAlreadyPresent.reduce((aggregator, currTourContent) => {
      if (currTourContent.relationships && currTourContent.relationships.poi) {
        const {
          relationships: {
            poi: { data: poi },
          },
        } = currTourContent;
        if (poi) {
          const { id } = poi;
          aggregator.push(id);
        }
      }
      return aggregator;
    }, []);
    // since server accepted multiple tour-content of poi type refering the same poi,
    // makes sure the poi id retrueved are not already present (unique) in the array used for testing
    return [...new Set(alreadyPresentPoiIds)];
  }
  return [];
}

export default {
  poiSearchSideEffects,
  populatePois,
  populatePoi,
  getPoisMarkers,
  highlightRelatedPoiCard,
  getArrayOfUniqueIdsOfRelatedPois,
};
