/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes, { func } from 'prop-types';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { isEmpty, isEmptyObject, getImageSrcSet } from '../utils';
import {
  PLACE_PAGE_DINAMIC_URL,
  TOUR_INFO_DINAMIC_URL,
  TOUR_EDITION_DINAMIC_URL,
  POI_EDITION_INFO_DINAMIC_URL,
  USER_DINAMIC_URL,
} from '../constants/routes.constants';
import {
  CLOSE_ICON, PENCIL_ICON, PLUS_ICON, CHECK_ICON, TOUR_SIGNAL_ICON, STAR_ICON, HEART_ICON
} from '../helpers';
import getRelatedEntitiesFromRedux from '../utils/redux-bee/get-related-entities-from-redux';
import generateCacheResources from '../utils/redux-bee/generate-cache-resources';
import TYPES from '../constants/types';
import ResponsiveImg from './responsive-img';
import { dispatch } from '../reduxStore';
import ModalPreviewContent from './modal-preview-content';
import ModalPreviewContentTour from './modal-preview-content-tour';
import ENV from '../config/environment';

class ContentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalPreviewContent: false,
      poiCampaign: false,
      imagesTour: []
    };
    this._requestedMissingResource = false;

    this.handleAvatarClick = this.handleAvatarClick.bind(this);
  }

  redirectToApp = () => {
    let tour = this.props.contentModel;
    window.location.href = "https://m.topdesti.com/tourView/selection/"+tour.attributes.selection+"/"+tour.id;
  }

  modalPreviewContentOpen = () => {
    this.setState({ showModalPreviewContent: true })
  }

  modalPreviewContentClose = () => {
    this.setState({ showModalPreviewContent: false })
  }

  componentDidMount() {
    // const { notCachedResources } = this.props;
    // if (notCachedResources.length && !this._requestedMissingResource) {
    //   generateCacheResources(notCachedResources);
    // }
    if (this.props.contentModel) {
      this._getLikes()
    }
  }

  // componentDidUpdate() {
  // const { notCachedResources } = this.props;
  // if (notCachedResources.length && !this._requestedMissingResource) {
  //   this._requestedMissingResource = true;
  //   generateCacheResources(notCachedResources).then(() => {
  //     this._requestedMissingResource = false;
  //   });
  // }
  // }
  _getLikes() {
    if (this.props.contentModel.id) {
      fetch(`${ENV.host}/${ENV.namespace}/pois/likespoi/${this.props.contentModel.id}/${false}`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data) {
            this.setState({
              likes: data.likes,
              poiId: this.props.contentModel.id,
              views: data.views,
              poiCampaign: data.campaign && data.campaign,
            });
          }
        });
    }
  }

  handleAvatarClick() {
    const { contentOwner } = this.props;
    if (!contentOwner) return;
    const { attributes: { nickname } } = contentOwner;

    dispatch(push(USER_DINAMIC_URL(nickname)));
  }

  render() {
    const { handleAvatarClick } = this;
    // reactRef is used by location-search to read the scroll offset when clicking on a marker
    const {
      contentModel,
      withAuthor,
      withEditButton,
      addButtonCB,
      handleRemoveBtnClick,
      reactRef = false,
      contentMainImage,
      contentOwner,
      contentOwnerAvatar,
      isAlreadyInTour,
      sponsorLogo,
      isMobile,
      onMouseEnter,
      onMouseLeave,
      onFocus,
      onBlur,
      isSelected,
      showCounter,
      imageArray,
      auth,
      tour,
      indexSelection,
      countSelection,
      homeCardView,
    } = this.props;

    if (tour) {
      tour.sponsor = sponsorLogo;
    }

    const mainDivConditionalEvents = {
      onMouseEnter: onMouseEnter || null,
      onMouseLeave: onMouseLeave || null,
      onFocus: onFocus || null,
      onBlur: onBlur || null,
    };
    const { id, type } = contentModel;
    // state is not null when content is tour and it has a state, else it's null
    // state 1 borrador - state 3 publicado
    const {
      attributes: {
        'url-title': urlTitle = null,
        state = null,
        'poi-type': poiType = null,
        'staff-pick': staffPick = null,
      } = {},
    } = contentModel;
    // const mainImageVersions = getImageSrcSet(contentMainImage);
    let findMainImage;

    if (type == 'pois') {
      findMainImage = contentModel.attributes ? contentModel.attributes.images : [];
    } else {
      findMainImage = this.props.imageArray ? Object.values(this.props.imageArray) : [];
    }
    // let findMainImage = this.props.imageArray ? Object.values(this.props.imageArray) : [];
    findMainImage = findMainImage ? findMainImage : []
    let findMainImage2 = findMainImage.filter(images => {
      if (images.imageableType === "poi" && images.imageableId == id) {
        if (images.likes == null) {
          images.likes = 0;
          return images;
        } else {
          return images;
        }
      }
    });
    if (findMainImage2) {
      findMainImage2.sort((a, b) => {
        // if (a && b) {
        if (a.likes > b.likes) {
          return -1;
        }
        if (a.likes < b.likes) {
          return 1;
        }
        // a must be equal to b
        return 0;
        // }
      });
    }
    const mainImageVersions = findMainImage2 && findMainImage2.length > 0 && type == "pois" ? getImageSrcSet(findMainImage2[0]) : getImageSrcSet(contentMainImage);
    /* const mainImageVersions = getImageSrcSet(contentMainImage); */
    const ownerName = contentOwner ? contentOwner.attributes.name : null;
    const ownerSurname = contentOwner ? contentOwner.attributes.surname : null;

    const { title, 'tours-counter': toursCounter = null, selection, 'likes-number': likesNumber = null } = contentModel.attributes;
    const isHighlighted = contentModel.isHighlighted || false;
    const editUrl = contentModel.type === 'tours'
      ? TOUR_EDITION_DINAMIC_URL(contentModel.id)
      : POI_EDITION_INFO_DINAMIC_URL(contentModel.id);
    // todo poi avatar (owner->avatar) doesn't exist
    const contentCardClassNames = () => {
      let classNames = `content-card content-card--${contentModel.type}`;
      if (withAuthor) classNames += ' content-card--with-overlayer';
      if (isAlreadyInTour) classNames += ' content-card--added';
      if (state === 1) classNames += ' content-card--transparent';
      if (state === 2) classNames += ' content-card--red';
      if (isHighlighted) classNames += ' content-card--highlighted';
      if (isSelected) classNames += ' content-card--selected';
      if (!isEmpty(staffPick) && isEmpty(addButtonCB) && staffPick && !withEditButton && !selection) {
        classNames += ' content-card--staff-pick';
      }
      if (selection && type == "tours") {
        classNames += homeCardView ? ' content-card--selection homeCardView' : ' content-card--selection';
      } else if (selection && type == "pois" && countSelection <= 3 && contentModel.attributes["type-preview"] == false && contentModel.attributes["priority-order"] >= 3) {
        classNames += ' content-card--selection';
      }
      if (selection && type == "pois" && contentModel.attributes["priority-order"] >= 3) {
        classNames += ' content-card--iconSelectionPoi';
      }
      return classNames;
    };
    let redirectURL = '#';
    switch (type) {
      case 'pois':
        redirectURL = state !== 1 ? PLACE_PAGE_DINAMIC_URL(urlTitle) : POI_EDITION_INFO_DINAMIC_URL(id);
        break;
      case 'tours':
        redirectURL = state !== 1 ? TOUR_INFO_DINAMIC_URL(urlTitle) : TOUR_EDITION_DINAMIC_URL(id);
        break;
      default:
        break;
    }

    return (
      <div className={contentCardClassNames()} ref={reactRef} {...mainDivConditionalEvents}>
        {!isEmpty(addButtonCB) ? (
          <button
            type="button"
            className="content-card__state"
            title="Añadir"
            onClick={e => (isAlreadyInTour ? e.preventDefault() : addButtonCB(e, contentModel))}
          >
            {isAlreadyInTour ? CHECK_ICON : PLUS_ICON}
          </button>
        ) : null}
        {handleRemoveBtnClick ? (
          <button
            type="button"
            className="btn-resetted content-card__state content-card__state--remove-btn"
            onClick={(e) => {
              handleRemoveBtnClick({ event: e, content: contentModel });
            }}
            title="Eliminar"
          >
            {CLOSE_ICON}
          </button>
        ) : null}
        {withEditButton && type === "tours" ? (
          <Link to={editUrl} className="content-card__state" title="Editar">
            {PENCIL_ICON}
          </Link>
        ) : null}
        {(withEditButton && type === "pois") && state != 3 ? (
          <Link to={editUrl} className="content-card__state" title="Editar">
            {PENCIL_ICON}
          </Link>
        ) : null}
        {poiType == 2 && withEditButton ? (
          <div

            className={`btn-resetted w-100 ${isEmpty(contentModel) || poiType === 2 ? 'disabled' : ''}`}
            disabled={isEmpty(contentModel) || poiType === 2}
          >
            <ResponsiveImg
              className="content-card__carrousel"
              srcSet={mainImageVersions}
              type="card"
            />
            {/*
          // not included in mvp
          <button type="button" className="content-card__carrousel-control content-card__carrousel-control--back">
            <span className="sr-only">Atras</span>
          </button>
          <button type="button" className="content-card__carrousel-control content-card__carrousel-control--next">
            <span className="sr-only">Siguiente</span>
          </button>
          */}
            <div
              className={`content-card__content ${withAuthor ? '' : 'mt-2'} ${showCounter ? 'content-card__content--counter' : ''
                }`}
            >
              <span className="content-card__counter opac">
                {TOUR_SIGNAL_ICON}
              </span>
              <h3
                style={{ WebkitBoxOrient: 'vertical' }}
                className="content-card__title"
                title={title}
              >
                {
                  selection && type === "tours" && title && title.length >= 45 ? title.substring(1, 45) + '...' : title
                }
              </h3>
              {showCounter ? (
                <span className="content-card__counter flex-column align-items-center">
                  {/* {TOUR_SIGNAL_ICON} */}
                  <img
                    width="30"
                    alt="like button"
                    src='../assets/images/ruta_azul.svg'
                  />
                  {toursCounter}
                </span>
              ) : null}
            </div>
            {/* <div className="content-card__footer">
              <ResponsiveImg
                className="content-card__partner-logo"
                wrapperClassName="content-card__partner-logo-wrapper"
                srcSet={getImageSrcSet(sponsorLogo)}
                type="sponsor"
                alt="Patrocinador"
                loadingInverse
              />
            </div> */}
          </div>) : (
            <>
              {isMobile || withEditButton || (selection && type === "tours") ? (
                <>
                  {isMobile && !withEditButton ? (
                    <div
                      /* <Link */
                      onClick={this.redirectToApp}
                      /* to={redirectURL} */
                      className={`btn-resetted w-100 ${isEmpty(contentModel) || poiType === 2 ? 'disabled' : ''} ${selection && type === "pois" && this.state.poiCampaign ? 'd-flex' : ''}`}
                      disabled={isEmpty(contentModel) || poiType === 2}
                    >
                      <div className={selection && type === "pois" && this.state.poiCampaign && countSelection <= 3 && contentModel.attributes["priority-order"] >= 3 ? 'col-6' : ''}>
                        {contentModel.attributes["type-preview"] && contentModel.attributes["type-preview"] != 0 ? (
                          <>
                            {
                              contentModel.attributes["type-preview"] == 1 ? (
                                <img
                                  alt="like button"
                                  src='../assets/images/destino_modo_previo_trazado.svg'
                                />
                              ) : (
                                  <img
                                    alt="like button"
                                    src='../assets/images/destino_pendiente.svg'
                                  />
                                )
                            }
                          </>
                        ) : (
                            <div className="d-flex">
                              {!isEmpty(staffPick) && staffPick && selection ? (
                                <div className="d-flex align-items-center flex-column mr-2">
                                  <div className="iconStar">{STAR_ICON}</div>
                                  <img
                                    width="30"
                                    alt="like button"
                                    src='../assets/images/iconSelection.svg'
                                  />
                                </div>
                              ) : null}
                              <ResponsiveImg
                                className="content-card__carrousel"
                                wrapperClassName="w-100"
                                srcSet={mainImageVersions}
                                type="card"
                              />
                            </div>
                          )}
                        {/*
                      // not included in mvp
                      <button type="button" className="content-card__carrousel-control content-card__carrousel-control--back">
                        <span className="sr-only">Atras</span>
                      </button>
                      <button type="button" className="content-card__carrousel-control content-card__carrousel-control--next">
                        <span className="sr-only">Siguiente</span>
                      </button>
                    */}
                        <div
                          className={`content-card__content justify-content-between ${withAuthor ? '' : 'mt-2'} ${showCounter ? 'content-card__content--counter' : ''
                            }`}
                        >
                          <span className="content-card__counter opac">
                            {TOUR_SIGNAL_ICON}

                          </span>
                          <div>
                            <h3
                              style={{ WebkitBoxOrient: 'vertical' }}
                              className="content-card__title"
                              title={title}
                            >
                              {
                                selection && type === "tours" && title && title.length >= 45 ? title.substring(1, 45) + '...' : title
                              }
                            </h3>
                            {/* {selection && type === "tours" ? (
                            <h3
                              style={{ WebkitBoxOrient: 'vertical' }}
                              className="content-card__textSelection"
                              title="TOPDESTI SELECTION"
                            >
                              topdesti selection
                            </h3>
                          ) : null} */}
                          </div>
                          {type === "tours" ? (
                            <span className="likesTour">
                              {HEART_ICON}
                              {likesNumber}
                            </span>
                          ) : null}
                          {showCounter ? (
                            <span className="content-card__counter flex-column align-items-center">
                              <img
                                width="30"
                                alt="like button"
                                src='../assets/images/ruta_azul.svg'
                              />
                              {toursCounter}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {this.state.poiCampaign && countSelection <= 3 && contentModel.attributes["priority-order"] >= 3 ? (
                        <div className="col-6">
                          <h3
                            style={{ WebkitBoxOrient: 'vertical' }}
                            className="content-card__textSelection text-center"
                            title="patrocina"
                          >
                            patrocina
                        </h3>
                          <img
                            className="logoPoiCampaign"
                            alt="like button"
                            src={this.state.poiCampaign.logo}
                          />
                          {/* <h3
                          style={{ WebkitBoxOrient: 'vertical' }}
                          className="content-card__title text-center"
                          title={title}
                        >
                          {this.state.poiCampaign.smallText}
                        </h3> */}
                        </div>
                      ) : null}


                      {/* <div className="content-card__footer">
                      <ResponsiveImg
                        className="content-card__partner-logo"
                        wrapperClassName="content-card__partner-logo-wrapper"
                        srcSet={getImageSrcSet(sponsorLogo)}
                        type="sponsor"
                        alt="Patrocinador"
                        loadingInverse
                      />
                    </div> */}
                      {/* </Link> */}
                    </div>
                  ) : (
                      <Link
                        to={redirectURL}
                        className={`btn-resetted w-100 ${isEmpty(contentModel) || poiType === 2 ? 'disabled' : ''}`}
                        disabled={isEmpty(contentModel) || poiType === 2}
                      >
                        <div className="d-flex">
                          {!isEmpty(staffPick) && staffPick && selection ? (
                            <div className="d-flex align-items-center flex-column mr-2">
                              <div className="iconStar">{STAR_ICON}</div>
                              <img
                                width="30"
                                alt="like button"
                                src='../assets/images/iconSelection.svg'
                              />
                            </div>
                          ) : null}
                          <ResponsiveImg
                            className="content-card__carrousel"
                            wrapperClassName="w-100"
                            srcSet={mainImageVersions}
                            type="card"
                          />
                        </div>
                        {/*
                      // not included in mvp
                      <button type="button" className="content-card__carrousel-control content-card__carrousel-control--back">
                      <span className="sr-only">Atras</span>
                      </button>
                      <button type="button" className="content-card__carrousel-control content-card__carrousel-control--next">
                      <span className="sr-only">Siguiente</span>
                      </button>
                  */}
                        <div
                          className={`content-card__content justify-content-between ${withAuthor ? '' : 'mt-2'} ${showCounter ? 'content-card__content--counter' : ''
                            }`}
                        >
                          <span className="content-card__counter opac">
                            {TOUR_SIGNAL_ICON}

                          </span>
                          <div>
                            <h3
                              style={{ WebkitBoxOrient: 'vertical' }}
                              className="content-card__title"
                              title={title}
                            >
                              {
                                selection && type === "tours" && title && title.length >= 55 ? title.substring(1, 55) + '...' : title
                              }
                            </h3>
                            {/* {selection ? (
                        <h3
                          style={{ WebkitBoxOrient: 'vertical' }}
                          className="content-card__textSelection"
                          title="TOPDESTI SELECTION"
                        >
                          topdesti selection
                        </h3>
                      ) : null} */}
                          </div>
                          {type === "tours" ? (
                            <span className="likesTour">
                              {HEART_ICON}
                              {likesNumber}
                            </span>
                          ) : null}
                          {showCounter ? (
                            <span className="content-card__counter flex-column align-items-center">
                              <img
                                width="30"
                                alt="like button"
                                src='../assets/images/ruta_azul.svg'
                              />
                              {toursCounter}
                            </span>
                          ) : null}
                        </div>
                        {/* <div className="content-card__footer">
                    <ResponsiveImg
                      className="content-card__partner-logo"
                      wrapperClassName="content-card__partner-logo-wrapper"
                      srcSet={getImageSrcSet(sponsorLogo)}
                      type="sponsor"
                      alt="Patrocinador"
                      loadingInverse
                    />
                  </div> */}
                      </Link>
                    )}
                </>
              ) : (
                  <div
                    /* <Link */
                    onClick={this.modalPreviewContentOpen}
                    /* to={redirectURL} */
                    className={`btn-resetted w-100 ${isEmpty(contentModel) || poiType === 2 ? 'disabled' : ''} ${selection && type === "pois" && this.state.poiCampaign ? 'd-flex' : ''}`}
                    disabled={isEmpty(contentModel) || poiType === 2}
                  >
                    <div className={selection && type === "pois" && this.state.poiCampaign && countSelection <= 3 && contentModel.attributes["priority-order"] >= 3 ? 'col-6' : ''}>
                      {contentModel.attributes["type-preview"] && contentModel.attributes["type-preview"] != 0 ? (
                        <>
                          {
                            contentModel.attributes["type-preview"] == 1 ? (
                              <img
                                alt="like button"
                                src='../assets/images/destino_modo_previo_trazado.svg'
                              />
                            ) : (
                                <img
                                  alt="like button"
                                  src='../assets/images/destino_pendiente.svg'
                                />
                              )
                          }
                        </>
                      ) : (
                          <div className="d-flex">
                            {!isEmpty(staffPick) && staffPick && selection ? (
                              <div className="d-flex align-items-center flex-column mr-2">
                                <div className="iconStar">{STAR_ICON}</div>
                                <img
                                  width="30"
                                  alt="like button"
                                  src='../assets/images/iconSelection.svg'
                                />
                              </div>
                            ) : null}
                            <ResponsiveImg
                              className="content-card__carrousel"
                              wrapperClassName="w-100"
                              srcSet={mainImageVersions}
                              type="card"
                            />
                          </div>
                        )}
                      {/*
                      // not included in mvp
                      <button type="button" className="content-card__carrousel-control content-card__carrousel-control--back">
                        <span className="sr-only">Atras</span>
                      </button>
                      <button type="button" className="content-card__carrousel-control content-card__carrousel-control--next">
                        <span className="sr-only">Siguiente</span>
                      </button>
                    */}
                      <div
                        className={`content-card__content justify-content-between ${withAuthor ? '' : 'mt-2'} ${showCounter ? 'content-card__content--counter' : ''
                          }`}
                      >
                        <span className="content-card__counter opac">
                          {TOUR_SIGNAL_ICON}

                        </span>
                        <div>
                          <h3
                            style={{ WebkitBoxOrient: 'vertical' }}
                            className="content-card__title"
                            title={title}
                          >
                            {
                              selection && type === "tours" && title && title.length >= 45 ? title.substring(1, 45) + '...' : title
                            }
                          </h3>
                          {/* {selection && type === "tours" ? (
                            <h3
                              style={{ WebkitBoxOrient: 'vertical' }}
                              className="content-card__textSelection"
                              title="TOPDESTI SELECTION"
                            >
                              topdesti selection
                            </h3>
                          ) : null} */}
                        </div>
                        {type === "tours" ? (
                          <span className="likesTour">
                            {HEART_ICON}
                            {likesNumber}
                          </span>
                        ) : null}
                        {showCounter ? (
                          <span className="content-card__counter flex-column align-items-center">
                            <img
                              width="30"
                              alt="like button"
                              src='../assets/images/ruta_azul.svg'
                            />
                            {toursCounter}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {this.state.poiCampaign && countSelection <= 3 && contentModel.attributes["priority-order"] >= 3 ? (
                      <div className="col-6">
                        <h3
                          style={{ WebkitBoxOrient: 'vertical' }}
                          className="content-card__textSelection text-center"
                          title="patrocina"
                        >
                          patrocina
                        </h3>
                        <img
                          className="logoPoiCampaign"
                          alt="like button"
                          src={this.state.poiCampaign.logo}
                        />
                        {/* <h3
                          style={{ WebkitBoxOrient: 'vertical' }}
                          className="content-card__title text-center"
                          title={title}
                        >
                          {this.state.poiCampaign.smallText}
                        </h3> */}
                      </div>
                    ) : null}


                    {/* <div className="content-card__footer">
                      <ResponsiveImg
                        className="content-card__partner-logo"
                        wrapperClassName="content-card__partner-logo-wrapper"
                        srcSet={getImageSrcSet(sponsorLogo)}
                        type="sponsor"
                        alt="Patrocinador"
                        loadingInverse
                      />
                    </div> */}
                    {/* </Link> */}
                  </div>
                )}
            </>
          )}
        {type === "pois" && this.state.poiId ? <ModalPreviewContent
          modalPreviewContent={this.state.showModalPreviewContent}
          modalPreviewContentClose={this.modalPreviewContentClose}
          poi={contentModel}
          imagen={mainImageVersions}
          imageArray={contentModel.attributes.images}
          // imageArray={imageArray}
          auth={auth}
          redirectURL={redirectURL}
          needGetLikes={false}
          likes={this.state.likes}
          poiId={this.state.poiId}
          views={this.state.views}
          poiCampaign={this.state.poiCampaign}
        /> : <ModalPreviewContentTour
            modalPreviewContent={this.state.showModalPreviewContent}
            modalPreviewContentClose={this.modalPreviewContentClose}
            poi={contentModel}
            imagen={mainImageVersions}
            auth={auth}
            redirectURL={redirectURL}
            tour={tour}
            sponsor={sponsorLogo}
          />
        }

        <div className="content-card__overlayer">
          {/* <p className={`content-card__visualizations ${withAuthor ? '' : 'w-100'}`}>
            {EYE_ICON}
            <span className="content-card__visualizations-value">
              {isEmpty(visualizations) ? 0 : visualizations}
            </span>
          </p> */}
          {withAuthor ? (
            <>
              <button type="button" className="btn-resetted" onClick={handleAvatarClick}>
                <ResponsiveImg
                  srcSet={getImageSrcSet(contentOwnerAvatar)}
                  wrapperClassName="content-card__avatar-wrapper"
                  className="content-card__avatar"
                  type="avatar"
                />
              </button>
              {isMobile ? null : (
                <p className="content-card__author">{`${ownerName} ${ownerSurname}`}</p>
              )}
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

ContentCard.propTypes = {
  contentModel: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string,
  }),
  sponsorLogo: TYPES.imageType,
  isAlreadyInTour: PropTypes.bool,
  withEditButton: PropTypes.bool,
  withAuthor: PropTypes.bool,
  addButtonCB: PropTypes.func,
  handleRemoveBtnClick: PropTypes.func,
  contentMainImage: TYPES.imageType,
  contentOwner: TYPES.userType,
  contentOwnerAvatar: TYPES.imageType,
  reactRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  notCachedResources: PropTypes.arrayOf(PropTypes.object),
  isMobile: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isSelected: PropTypes.bool,
  showCounter: PropTypes.bool,
};

ContentCard.defaultProps = {
  contentModel: null,
  isAlreadyInTour: false,
  withEditButton: false,
  withAuthor: true,
  addButtonCB: null,
  handleRemoveBtnClick: null,
  contentMainImage: null,
  contentOwner: null,
  contentOwnerAvatar: null,
  sponsorLogo: null,
  reactRef: null,
  notCachedResources: [],
  isMobile: false,
  onMouseEnter: null,
  onMouseLeave: null,
  onFocus: null,
  onBlur: null,
  isSelected: false,
  showCounter: false,
};

function mapStateToProps(state, ownProps) {

  const tour = state.bees.entities;
  const auth = state.auth;
  const imageArray = state.bees.entities.images;
  const { contentModel } = ownProps;
  const notCachedResources = [];
  const isMobile = state.viewport.isXs || state.viewport.isSm || state.viewport.isMd;
  let contentMainImage;
  let contentOwner;
  let contentOwnerAvatar;
  let contentSponsor;
  let sponsorLogo;

  if (contentModel) {
    // get tour Owner from cache
    const [cacheContentOwner, missingContentOwnerResources] = getRelatedEntitiesFromRedux(
      contentModel,
      'owner',
      'getUser',
    );
    if (cacheContentOwner) contentOwner = cacheContentOwner;
    if (!isEmptyObject(missingContentOwnerResources)) notCachedResources.push(...missingContentOwnerResources);
    const canFetchRelationships = !isEmptyObject(contentOwner)
      && !isEmptyObject(contentOwner.relationships)
      && !isEmptyObject(contentOwner.relationships.avatar)
      && !isEmptyObject(contentOwner.relationships.avatar.data);
    if (canFetchRelationships) {
      // try to get the owner avatar from cache
      const [
        cacheContentOwnerAvatar,
        missingContentOwnerAvatarResources,
      ] = getRelatedEntitiesFromRedux(contentOwner, 'avatar', 'getImage');
      if (cacheContentOwnerAvatar) contentOwnerAvatar = cacheContentOwnerAvatar;
      if (!isEmptyObject(missingContentOwnerAvatarResources)) {
        notCachedResources.push(...missingContentOwnerAvatarResources);
      }
    }
    // get current content type and id
    const { type } = contentModel;

    // try to get current content related main image from cache based on content type and id
    const imageNameAttr = type === 'tours' ? 'desktop-image' : 'main-image';
    const [cacheContentMainImage, missingContentMainImageResources] = getRelatedEntitiesFromRedux(
      contentModel,
      imageNameAttr,
      'getImage',
    );
    if (cacheContentMainImage) contentMainImage = cacheContentMainImage;
    if (!isEmptyObject(missingContentMainImageResources)) notCachedResources.push(...missingContentMainImageResources);

    // if the content type is pois and no main image was found
    if (type === 'pois') {
      if (isEmptyObject(contentMainImage)) {
        // try to get the first image of the related "images" and set is as the "main image"
        const [cachedPoiImages, missingPoiImagesResources] = getRelatedEntitiesFromRedux(
          contentModel,
          'images',
          'getImage',
        );
        if (!isEmptyObject(missingPoiImagesResources)) notCachedResources.push(...missingPoiImagesResources);

        if (
          !isEmptyObject(cachedPoiImages)
          || (Array.isArray(cachedPoiImages) && cachedPoiImages.length > 0)
        ) {
          [contentMainImage] = cachedPoiImages;
        }
        // if no related images is found, contentMainImage is null and default photo is used during render
      }
    } else if (type === 'tours') {
      const [cacheSponsor, missingSponsorResources] = getRelatedEntitiesFromRedux(
        contentModel,
        'sponsor',
        'getSponsor',
      );
      if (!isEmptyObject(missingSponsorResources)) notCachedResources.push(...missingSponsorResources);
      if (!isEmpty(cacheSponsor) && cacheSponsor.attributes.visibility) {
        const [cacheSponsorLogo, missingSponsorLogoResources] = getRelatedEntitiesFromRedux(
          cacheSponsor,
          'logo',
          'getImage',
        );
        if (!isEmptyObject(missingSponsorLogoResources)) notCachedResources.push(...missingSponsorLogoResources);
        sponsorLogo = cacheSponsorLogo;
      }
    }
  }

  return {
    notCachedResources,
    contentMainImage,
    contentOwner,
    contentOwnerAvatar,
    contentSponsor,
    sponsorLogo,
    isMobile,
    imageArray,
    auth,
    tour,
  };
}
export default connect(mapStateToProps)(ContentCard);