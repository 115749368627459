import { PropTypes } from 'prop-types';

const productType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['products']).isRequired,
  attributes: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    'product-name': PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    link: PropTypes.string,
    'video-url': PropTypes.string,
    'platform-logo': PropTypes.string.isRequired,
    order: PropTypes.number,
    visible: PropTypes.bool.isRequired,
  }),
  relationships: PropTypes.shape({
    images: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          type: PropTypes.oneOf(['images', 'archives']),
        }),
      ),
    }),
  }),
});

export default productType;
