import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';

import reduxStore from './reduxStore';
import App from './App';
import { ErrorPage } from './pages';

// kick off the polyfill! https://github.com/iamdustan/smoothscroll
smoothscroll.polyfill();

// import * as serviceWorker from './serviceWorker';
const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={reduxStore}>
    <ErrorPage>
      <App />
    </ErrorPage>
  </Provider>,
  rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
