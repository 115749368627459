import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { isEmpty } from '../../utils';
import { Gmaps } from '../../components';
import { defaultMapCenter } from '../../constants';

export default class PoiPositionModal extends Component {
  constructor(props) {
    super(props);

    this.onMarkerCoordSave = this.onMarkerCoordSave.bind(this);
    this.state = {
      address: '',
      mapInstance: null,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onMapComponentDidMount = this.onMapComponentDidMount.bind(this);
  }

  onMarkerCoordSave(markerCoord) {
    const { savePoiPosition } = this.props;

    savePoiPosition(markerCoord);
  }

  onMapComponentDidMount(mapInstance) {
    this.setState({ mapInstance });
  }

  handleSelect(address) {
    let results = null;
    const { mapInstance } = this.state;
    geocodeByAddress(address)
      .then((selectionResults) => {
        results = selectionResults;
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        this.setState({ address: results[0].formatted_address }, () => {
          mapInstance.panTo(latLng);
        });
      })
      .catch(error => console.error('Error', error));
  }

  handleChange(address) {
    this.setState({ address });
  }

  static renderAutoCompleteFunction({
    getInputProps, suggestions, getSuggestionItemProps, loading,
  }) {
    return (
      <>
        <input
          {...getInputProps({
            placeholder: 'Busca un punto de interés...',
            className: 'form-control poi-position-modal__input',
          })}
        />
        {!isEmpty(suggestions) ? (
          <div className="poi-position-modal__suggestions">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = `
                    poi-position-modal__suggestions-item ${
                      suggestion.active ? 'poi-position-modal__suggestions-item--active' : ''
                    }`;
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        ) : null}
      </>
    );
  }

  render() {
    const { handleChange, handleSelect } = this;
    const { address } = this.state;
    const {
      isOpen, closeModal, latitude, longitude,
    } = this.props;

    return (
      <Modal
        className="poi-position-modal"
        overlayClassName="poi-position-modal__overlay"
        isOpen={isOpen}
        onRequestClose={() => closeModal('positionModalIsOpen')}
        contentLabel="Example Modal"
      >
        <section className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h4 className="poi-position-modal__title">Marca un lugar y pulsa en guardar:</h4>
            </div>
            <div className="col-12 col-md-8 col-lg-6 mx-auto mb-3">
              <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
                {PoiPositionModal.renderAutoCompleteFunction}
              </PlacesAutocomplete>
            </div>
            <div className="col-12 poi-position-modal__map">
              <Gmaps
                onMapComponentDidMount={this.onMapComponentDidMount}
                onMarkerCoordSave={this.onMarkerCoordSave}
                defaultZoom={10}
                defaultCenter={{
                  lat: latitude || defaultMapCenter.lat,
                  lng: longitude || defaultMapCenter.lng,
                }}
                positionBtnsInset
              />
            </div>
          </div>
        </section>
      </Modal>
    );
  }
}

PoiPositionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  savePoiPosition: PropTypes.func.isRequired,
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PoiPositionModal.defaultProps = {
  latitude: null,
  longitude: null,
};
