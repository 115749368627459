import { PropTypes } from 'prop-types';

const tourContentType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['tourContents']).isRequired,
  attributes: PropTypes.shape({
    'affiliate-link': PropTypes.string,
    'affiliate-text': PropTypes.string,
    'affiliate-title': PropTypes.string,
    'arrival-time': PropTypes.number,
    category: PropTypes.number,
    'content-type': PropTypes.number,
    day: PropTypes.number.isRequired,
    'display-mode': PropTypes.string,
    duration: PropTypes.number,
    link: PropTypes.string,
    lat: PropTypes.number,
    long: PropTypes.number,
    order: PropTypes.number,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    'video-url': PropTypes.string,
    visible: PropTypes.bool.isRequired,
  }),
  relationships: PropTypes.shape({
    tour: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.oneOf(['tours']),
      }),
    }).isRequired,
  }),
});

export default tourContentType;
