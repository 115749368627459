import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { CLOSE_ICON, CHECK_ICON } from '../../../../helpers';
import { isEmptyObject } from '../../../../utils';
import { PromiseButton } from '../../../../components';
import TYPES from '../../../../constants/types';

function ContentEditionModalTemplate(props) {
  const {
    handleCloseModal,
    localContent,
    children,
    title,
    handleSave,
    isLocallyModified,
    isMobile,
  } = props;

  return (
    <Modal
      className="container-fluid tour-content-edition-modal"
      overlayClassName="tour-content-edition-modal__overlay"
      name="tourContentEditionModal"
      isOpen={!isEmptyObject(localContent)}
      onRequestClose={handleCloseModal}
    >
      {/* Basic header from all modals */}
      <header className="row tour-content-edition-modal__header">
        {isMobile ? (
          <div className="col">
            <img
              src="/assets/images/topdesti-logo.png"
              alt="TopDesti"
              className="tour-content-edition-modal__header-logo"
            />
          </div>
        ) : null}
        <div className="col-lg-12 tour-content-edition-modal__header-actions">
          {isMobile ? null : <h4 className="tour-content-edition-modal__header-title">{title}</h4>}

          <PromiseButton
            type="button"
            className={`tour-content-edition-modal__header-btn tour-content-edition-modal__header-btn--blue ${
              isLocallyModified ? 'tour-content-edition-modal__header-btn--highlighted' : ''
            }`}
            onClick={handleSave}
          >
            {CHECK_ICON}
          </PromiseButton>

          <button
            type="button"
            className="tour-content-edition-modal__header-btn"
            onClick={handleCloseModal}
          >
            {CLOSE_ICON}
          </button>
        </div>
      </header>
      {/* Body for the forms and left side of the modal */}
      <div className="row tour-content-edition-modal__form-wrapper">{children || null}</div>
    </Modal>
  );
}

ContentEditionModalTemplate.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isLocallyModified: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  localContent: PropTypes.oneOfType([TYPES.productType, TYPES.tourContentType]),
  isMobile: PropTypes.bool,
};
ContentEditionModalTemplate.defaultProps = {
  localContent: null,
  isMobile: false,
  children: null,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(ContentEditionModalTemplate);
