import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { slide as BurgerMenu } from 'react-burger-menu';
import session from '../services/session';
import {
  LOGOUT,
  TOUR_EDITION_DINAMIC_URL,
  POI_EDITION_INFO_DINAMIC_URL,
  defaultAvatar,
  UPDATE_CURRENT_USER,
  LOCALSTORAGE_SESSION,
} from '../constants';
import DropDown from './dropdown';
import { isEmpty, isEmptyObject } from '../utils';
import {
  USER_ICON,
  MAGNIFYING_ICON,
  ADD_BLUE,
  ROUTE_BLUE,
  DESTINY_BLUE,
  RECOMMENDATION_BLUE,
  LOGOUT_ICON,
  HOME_ICON,
  CENTER_ICON,
  // INFO_CIRCLE_ICON,
} from '../helpers/svg-inline';
import api from '../config/api-endpoints';
import handleError from '../utils/errorHandler';
import * as ROUTES from '../constants/routes.constants';
import ModalDuplicatedContent from './modal-duplicated-content';
import ModalOnlyUsers from './modal-log-out';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLogOut: false
    };
    this.logout = this.logout.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.createNewTour = this.createNewTour.bind(this);
    this.createNewPoi = this.createNewPoi.bind(this);
    this.state = {
      burguerMenuIsOpen: false,
      duplicatedModalValue: null,
    };
  }
  modalLogOutOpen = () => {
    this.setState({ showModalLogOut: true })
  }
  handlemodalLogOutClose = () => {
    this.setState({ showModalLogOut: false })
  }

  componentDidMount() {
    const { currentUser, dispatch, isAuthenticated } = this.props;
    if (isEmpty(currentUser) && isAuthenticated) {
      dispatch(api.getCurrentUser())
        .then((response) => {
          if (response.status === 200) {
            const user = response.body.data;
            dispatch({ type: UPDATE_CURRENT_USER, currentUser: user });
          }
        }).catch(() => {
          // if /me fails, logout the user
          localStorage.removeItem(LOCALSTORAGE_SESSION);
          // and refresh the page
          window.location.reload();
        });
    }
  }

  closeMenu() {
    this.setState({
      burguerMenuIsOpen: false,
    });
  }

  logout() {
    session.unauthenticate().then(() => {
      function cookieSet(name, value) {
        var expires = "";
        var date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    
        document.cookie = name + "=" + value + expires + "; path=/";
      }
      cookieSet("commentoCommenterToken", "anonymous");
      const { dispatch } = this.props;
      dispatch({ type: LOGOUT });
    });
  }

  createNewTour() {
    const { dispatch, isAuthenticated } = this.props;
    if (!isAuthenticated) {
      dispatch(push(ROUTES.LOGIN_URL));
      return false;
    }
    return dispatch(
      api.createTour({
        data: {
          type: 'tours',
          attributes: {
            title: 'Mi nueva ruta',
          },
        },
      }),
    ).then((TOUR) => {
      dispatch(push(TOUR_EDITION_DINAMIC_URL(TOUR.body.data.id)));
    });
  }

  createNewPoi(poiType) {
    const { dispatch, isAuthenticated } = this.props;
    if (!isAuthenticated) {
      dispatch(push(ROUTES.LOGIN_URL));
      this.handleDuplicatedModal(null);
      return false;
    }
    return dispatch(
      api.createPoi({
        data: {
          type: 'pois',
          attributes: {
            title: poiType === 1 ? 'Mi nuevo destino' : 'Mi nuevo servicio',
            language: 'es-es',
            'poi-type': poiType === 3 ? 1 : poiType,
            'type-preview': poiType === 3 ? 1 : 0
          },
        },
      }),
    )
      .then((POI) => {
        dispatch(push(POI_EDITION_INFO_DINAMIC_URL(POI.body.data.id)));
      })
      .catch(error => handleError(error));
  }

  handleDuplicatedModal(value) {
    const {
      currentUser, isAuthenticated, dispatch, isMobile,
    } = this.props;
    const { attributes: { 'hide-duplicated-modal': hideDuplicatedModal = null } = {} } = currentUser || {};

    if (!isAuthenticated) {
      dispatch(push(ROUTES.LOGIN_URL));
      // Provisionally, when user navigates from mobile, it's forzed to show the duplicated-content modal
      // because from here is show the info about the disabled feature of creation/edition of pois.
    } else if (hideDuplicatedModal && !isMobile) {
      this.createNewPoi(value);
    } else {
      const newState = {
        duplicatedModalValue: value,
        ...(!isEmpty(value) && { burguerMenuIsOpen: false }),
      };
      this.setState(newState);
    }
  }

  render() {
    const {
      isAuthenticated, ContextualActions, alterBrand, isMobile, currentUser, avatarUrl, currentRoute, isMobileSP
    } = this.props;
    if(isMobileSP){
      isMobileSP(isMobile)
    }
    const { name, surname, nickname } = !isEmpty(currentUser) ? currentUser.attributes : { name: null, surname: null };
    const { burguerMenuIsOpen, duplicatedModalValue } = this.state;
    // ACTIVE ROUTE FLAGS
    const isDiscoverRoute = currentRoute.includes('/discover');
    const isCreationRoute = currentRoute.includes('/tour-edition')
      || (currentRoute.includes('/poi') && (currentRoute.includes('/info') || currentRoute.includes('/images')));
    const isAccountRoute = currentRoute.includes('/account');
    const isRegisterRoute = currentRoute.includes('/register');
    const isLoginRoute = currentRoute.includes('/login');
    // const isFaqsRoute = currentRoute.includes('/faqs');
    return currentRoute.includes('/tour/') ? (null) : (
      <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <BurgerMenu
            className="burguer-menu"
            burgerButtonClassName="burguer-menu__button"
            burgerBarClassName="burguer-menu__bars"
            width={320}
            isOpen={burguerMenuIsOpen}
            onStateChange={state => {
              this.setState({ burguerMenuIsOpen: state.isOpen });
            }}
            left
          >
            {isAuthenticated ? (
              <div className="text-center burguer-menu__user">
                <figure
                  style={{ backgroundImage: `url('${avatarUrl}')` }}
                  className="burguer-menu__user-avatar circular-img"
                >
                  <span className="sr-only">Avatar</span>
                </figure>
                <h3>{`${name} ${surname}`}</h3>
              </div>
            ) : null}
            <Link
              className={`burguer-menu__link ${
                currentRoute === '/' ? 'burguer-menu__link--active' : ''
                }`}
              to="/"
              onClick={this.closeMenu}
            >
              {HOME_ICON}
              Inicio
            </Link>
            <div className="d-flex descubre">
              <Link
                className={`burguer-menu__link ${
                  isDiscoverRoute ? 'burguer-menu__link--active' : ''
                  }`}
                to={ROUTES.DISCOVER_TOURS_URL}
                onClick={this.closeMenu}
              >
                {MAGNIFYING_ICON}
                Descubre
            </Link>
              {/* <Link type="" className={`search-filters__toggle ${
                isDiscoverRoute ? 'search-filters__toggle--active' : ''
                }`} to={{
                  pathname: "/discover",
                  search: "?searchType=1",
                  state: { centerToUserPosition: true }
                }}
                onClick={this.closeMenu}>
                {CENTER_ICON}
              </Link> */}
            </div>
            {isAuthenticated ? (
              <div className="burguer-menu__link">
                {ADD_BLUE}
                Crea
                <div className="burguer-menu__crea-items">
                  {isAuthenticated && currentUser && !currentUser.attributes['approved-user'] ? (
                    <div className="user_not_approved">
                      <p className='mobile'>Versión Beta</p>
                      <p>
                        Las opciones de crear están limitadas en este momento a usuarios restringidos. Si te apasiona viajar y tienes buenos conocimientos de fotografía, contacta con nosotros en info@topdesti.com y podrás unirte a nuestro equipo inicial de creadores.
                      </p>
                      <p className='mobile'>Muchas gracias</p>
                    </div>
                  ) : (
                      <div className="d-flex justify-content-start w-100">
                        <button
                          type="button"
                          className="burguer-menu__crea-items-btn flex-column"
                          onClick={this.createNewTour}
                        >
                          {ROUTE_BLUE}
                          <span className="burguer-menu__crea-items-text">Rutas</span>
                        </button>
                        {/* <button
                          type="button"
                          className="burguer-menu__crea-items-btn flex-column"
                          onClick={() => this.handleDuplicatedModal(1)}
                        >
                          {DESTINY_BLUE}
                          <span className="burguer-menu__crea-items-text">Destinos</span>
                        </button> */}
                        <button
                          type="button"
                          className="burguer-menu__crea-items-btn flex-column"
                          onClick={() => this.createNewPoi(2)}
                        >
                          {RECOMMENDATION_BLUE}
                          <span className="burguer-menu__crea-items-text">Servicios</span>
                        </button>
                      </div>
                    )}
                </div>
              </div>
            ) : null}
            {/*
            <Link className="burguer-menu__link" to="/" onClick={this.closeMenu}>
              {ANGEL_ICON}
              Travel Angel
            </Link>
            */}

            {/* <Link
              className={`burguer-menu__link ${isFaqsRoute ? 'burguer-menu__link--active' : ''}`}
              to={ROUTES.FAQ_URL}
              onClick={this.closeMenu}
            >
              {INFO_CIRCLE_ICON}
              FAQS / Ayuda
            </Link> */}
            {/*
            <Link className="burguer-menu__link" to="/" onClick={this.closeMenu}>
              {YOUTUBE_ICON}Vídeo Tutoriales
            </Link>
          */}
            {isAuthenticated ? (
              <>
                <Link
                  className={`burguer-menu__link ${
                    isAccountRoute ? 'burguer-menu__link--active' : ''
                    }`}
                  to={
                    isEmptyObject(nickname) ? ROUTES.ACCOUNT_URL : ROUTES.USER_DINAMIC_URL(nickname)
                  }
                  onClick={this.closeMenu}
                >
                  {USER_ICON}
                  Tu Perfil
                </Link>

                <button className="burguer-menu__link" onClick={this.logout} type="button">
                  {LOGOUT_ICON}
                  Cerrar Sesión
                </button>
              </>
            ) : (
                <>
                  <Link
                    className={`burguer-menu__link ${
                      isLoginRoute ? 'burguer-menu__link--active' : ''
                      }`}
                    to={ROUTES.LOGIN_URL}
                  >
                    Acceder
                </Link>
                  <Link
                    className={`burguer-menu__link ${
                      isRegisterRoute ? 'burguer-menu__link--active' : ''
                      }`}
                    to={ROUTES.REGISTER_URL}
                  >
                    Registrarse
                </Link>
                </>
              )}
          </BurgerMenu>
          {isEmpty(alterBrand) || !isMobile ? (
            <Link className="navbar-brand" to="/">
              <img
                src="/assets/images/topdesti-logo.png"
                className="d-inline-block align-top"
                alt="Topdesti"
              />
            </Link>
          ) : (
              <h2 className="navbar-brand navbar-brand--text">{alterBrand}</h2>
            )}
          {!isEmpty(ContextualActions) ? <ContextualActions /> : <></>}

          <div className="collapse navbar-collapse" id="headerNavbarCollapse">
            <ul className="navbar-nav mt-2 mt-lg-0">
              <li className="nav-item">
                <Link
                  className={`nav-link ${isDiscoverRoute ? 'nav-link--active' : ''}`}
                  to={ROUTES.DISCOVER_URL}
                >
                  Descubre
                </Link>
              </li>
              <li className="nav-item">
                {isAuthenticated && currentUser && !currentUser.attributes['approved-user'] ? (
                  <div className="user_not_approved">
                    <DropDown
                      align="left"
                      toggle={<p className='textButtonDrpdown'>CREA</p>}
                      toggleClass={`nav-link afkar ${isCreationRoute ? 'nav-link--active' : ''}`}
                    >
                      <h5>Versión Beta</h5>
                      <p>
                        Las opciones de crear están limitadas en este momento a usuarios restringidos. Si te apasiona viajar y tienes buenos conocimientos de fotografía, contacta con nosotros en info@topdesti.com y podrás unirte a nuestro equipo inicial de creadores.
                      </p>
                      <h6>Muchas gracias</h6>
                    </DropDown>
                  </div>
                ) : (
                    <>
                      <DropDown
                        align="left"
                        toggle={<p className='textButtonDrpdown'>Crea</p>}
                        toggleClass={`nav-link ${isCreationRoute ? 'nav-link--active' : ''}`}
                      > {/* autenticacion para alerta */}
                        {isAuthenticated ? (
                          <>
                            <button type="button" className="dropdown-item" onClick={this.createNewTour}>
                              Ruta
                            </button>
                            {currentUser && (currentUser.id == 14 || currentUser.id == 40 || currentUser.id == 22) ? (
                              <>
                                <button
                                  type="button"
                                  className="dropdown-item"
                                  onClick={() => this.handleDuplicatedModal(1)}
                                >
                                  Destino
                              </button>
                                <button
                                  type="button"
                                  className="dropdown-item"
                                  onClick={() => this.handleDuplicatedModal(3)}
                                >
                                  Destino Previo
                              </button>
                              </>
                            ) : (
                                <button
                                  type="button"
                                  className="dropdown-item"
                                  onClick={() => this.handleDuplicatedModal(1)}
                                >
                                  Destino
                                </button>
                              )}
                            <button
                              type="button"
                              className="dropdown-item"
                              // onClick={() => this.handleDuplicatedModal(2)}
                              onClick={() => this.createNewPoi(2)}
                            >
                              Servicio
                    </button>
                          </>
                        ) : (
                            <>
                              <button type="button" className="dropdown-item" onClick={this.modalLogOutOpen}>
                                Ruta
                    </button>
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={this.modalLogOutOpen}
                              >
                                Destino
                    </button>
                              <button
                                type="button"
                                className="dropdown-item"
                                // onClick={() => this.handleDuplicatedModal(2)}
                                onClick={this.modalLogOutOpen}
                              >
                                Servicio
                    </button>
                            </>
                          )}
                      </DropDown>
                    </>
                  )}
                <ModalOnlyUsers
                  modalLogOut={this.state.showModalLogOut}
                  modalLogOutClose={this.handlemodalLogOutClose}
                />
              </li>
              {/*
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Travel Angel
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${isFaqsRoute ? 'nav-link--active' : ''}`}
                  to={ROUTES.FAQ_URL}
                >
                  FAQS
                </Link>
              </li>
              */}
              {/*
            <li className="nav-item">
              <Link className="nav-link" to="/">
                TOPS
              </Link>
            </li> */}
            </ul>
            <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
              {/* <li className="nav-item">
              <Link className="nav-link nav-icon nav-icon--light-blue" to="/">
                {STAR_ICON}
                Hazte premium
              </Link>
            </li> */}
            </ul>

            {/* <form className="form-inline my-2 my-lg-0 flex-nowrap">
            <span className="nav-icon nav-icon--white">{MAGNIFYING_ICON}</span>
            <input className="form-control text-dark mr-sm-2" type="text" />
          </form> */}
            <DropDown align="right" toggle={USER_ICON} toggleClass="nav-dropdown-toggle">
              {isAuthenticated ? (
                <>
                  <Link
                    className={`dropdown-item ${isAccountRoute ? 'dropdown-item--active' : ''}`}
                    to={
                      isEmptyObject(nickname)
                        ? ROUTES.ACCOUNT_URL
                        : ROUTES.USER_DINAMIC_URL(nickname)
                    }
                  >
                    Tu perfil
                  </Link>
                  <button className="dropdown-item" onClick={this.logout} type="button">
                    Salir
                  </button>
                </>
              ) : (
                  <>
                    <Link
                      className={`dropdown-item ${isLoginRoute ? 'dropdown-item--active' : ''}`}
                      to={ROUTES.LOGIN_URL}
                    >
                      Acceder
                  </Link>
                    <Link
                      className={`dropdown-item ${isRegisterRoute ? 'dropdown-item--active' : ''}`}
                      to={ROUTES.REGISTER_URL}
                    >
                      Registrarse
                  </Link>
                  </>
                )}
            </DropDown>
            {/* <div className="version-beta">
              <h2>
                <span>Versión Beta</span>
                Gran Canaria
              </h2>
              <img src="/assets/images/beta_gran_canaria.png" alt="palm trees" />
            </div> */}
          </div>
        </nav>
        {isEmpty(duplicatedModalValue) ? null : (
          <ModalDuplicatedContent
            poiType={duplicatedModalValue}
            closeModal={() => this.handleDuplicatedModal(null)}
            createNewPoi={this.createNewPoi}
            currentUser={currentUser}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const currentUser = state.auth.isAuthenticated && !isEmpty(state.auth.currentUser) ? state.auth.currentUser : null;
  const avatarUrl = isEmpty(currentUser) || isEmpty(currentUser.relationships.avatar.data)
    ? defaultAvatar
    : state.bees.entities.images[currentUser.relationships.avatar.data.id].attributes.thumb;
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    currentUser,
    avatarUrl,
    currentRoute: state.router.location.pathname,
  };
}

Navbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  ContextualActions: PropTypes.func,
  alterBrand: PropTypes.string,
  isMobile: PropTypes.bool,
  currentUser: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
      surname: PropTypes.string,
    }),
  }),
  avatarUrl: PropTypes.string,
  currentRoute: PropTypes.string.isRequired,
};

Navbar.defaultProps = {
  ContextualActions: null,
  alterBrand: null,
  isMobile: false,
  currentUser: null,
  avatarUrl: null,
};

export default connect(
  mapStateToProps,
  null,
)(Navbar);

