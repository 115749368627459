import { PropTypes } from 'prop-types';

const poiType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.oneOf(['pois']).isRequired,
  attributes: PropTypes.shape({
    title: PropTypes.string,
  }),
});

export default poiType;
