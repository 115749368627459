import handleError from '../utils/errorHandler';

function getClientLocation() {
  return fetch(
    'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCygd0p2XG8yyNaG8S3yzu6rt3HYcR22GI',
    {
      method: 'POST',
    },
  )
    .then(response => response.json())
    .catch((err) => {
      handleError(err);
    });
}

export default getClientLocation;
