/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import {
  lockBodyScrolling, restoreBodyScrolling, isEmpty, confirmationToastr,
} from '../../../../utils';
import api from '../../../../config/api-endpoints';
import TourConfigMainTemplate from './tour-config-main-template';
import TYPES from '../../../../constants/types';
import TourConfigNavMenu from './tour-config-nav-menu';
import { PromiseButton } from '../../../../components';
import { CHECK_ICON, CLOSE_ICON } from '../../../../helpers';
import TourConfigTips from './tour-config-tips';
import TourConfigShop from './tour-config-shop';
import TourConfigSponsor from './tour-config-sponsor';
import reduxBeeMakeRequest from '../../../../utils/redux-bee/redux-bee-make-request';
import { dispatch } from '../../../../reduxStore';
import { UPDATE_PENDING_CHANGES } from '../../../../constants';
import ENV from '../../../../config/environment';

class TourConfigModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      desktopImageRequest: { status: 'uninitialized' }, // loadImages() will change this state
      phoneImageRequest: { status: 'uninitialized' }, // loadImages() will change this state
      handleConfigSave: null,
      campaigns: [],
      currentCampaign: false,
    };
    this.loadImages = this.loadImages.bind(this);
    this.loadDesktopImage = this.loadDesktopImage.bind(this);
    this.loadPhoneImage = this.loadPhoneImage.bind(this);

    this.shouldUpdateImagesState = this.shouldUpdateImagesState.bind(this);
    this.shouldUpdatePhoneImageState = this.shouldUpdatePhoneImageState.bind(this);
    this.shouldUpdateDesktopImageState = this.shouldUpdateDesktopImageState.bind(this);

    this.onFileUploadComplete = this.onFileUploadComplete.bind(this);
    this.handleBindSaves = this.handleBindSaves.bind(this);
    this.handleResetScroll = this.handleResetScroll.bind(this);
    this.handleConfigNavigation = this.handleConfigNavigation.bind(this);
  }

  componentDidMount() {
    const { loadImages } = this;
    loadImages();
    this.initialLoad();
  }
  initialLoad = (x) => {
    if (this.props.user) {
      fetch(`${ENV.host}/${ENV.namespace}/pois/sponsoredPois/${this.props.user ? this.props.user.id : ''}`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          if(x === false){
            this.setState({
              campaigns: data.campaigns,
            })
          }else{
            this.getCampaign()
            this.setState({
              campaigns: data.campaigns,
            })
          }
        });
    }
  }

  getCampaign = () => {
    fetch(`${ENV.host}/${ENV.namespace}/tours/getCampaign/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tourId: this.props.tourId
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.campaign) {
          this.setState({
            currentCampaign: data.campaign
          })
        }
      });
  }
  
  componentDidUpdate(prevProps, prevState) {
    const {
      loadDesktopImage,
      loadPhoneImage,
      shouldUpdateDesktopImageState,
      shouldUpdatePhoneImageState,
    } = this;
    const shouldUpdateDesktoImage = shouldUpdateDesktopImageState(prevProps, prevState);
    const shouldUpdatePhoneImage = shouldUpdatePhoneImageState(prevProps, prevState);
    const { isOpen: prevIsOpen, isMobile: prevMobile } = prevProps;
    const { isOpen, isMobile } = this.props;

    if (shouldUpdateDesktoImage) loadDesktopImage();
    if (shouldUpdatePhoneImage) loadPhoneImage();

    if (prevIsOpen !== isOpen || isMobile !== prevMobile) {
      if (isOpen && !isMobile) lockBodyScrolling();
      else restoreBodyScrolling();
    }
  }

  // can receive id, name, response
  onFileUploadComplete() {
    const { tourId } = this.props;
    reduxBeeMakeRequest(dispatch, 'getTour', { id: tourId }, {});
  }

  shouldUpdateImagesState(prevProps) {
    const { isOpen, tour } = this.props;
    const { phoneImageRequest, desktopImageRequest } = this.state;
    const prevTour = prevProps.tour;

    if (isOpen) {
      if (
        phoneImageRequest.status === 'uninitialized'
        || desktopImageRequest.status === 'uninitialized'
      ) return true;

      const prevDesktopImageId = prevTour.relationships['desktop-image'].data.id;
      const desktopImageId = tour.relationships['desktop-image'].data.id;
      if (desktopImageId !== prevDesktopImageId) return true;

      const prevPhoneImageId = prevTour.relationships['phone-image'].data.id;
      const phoneImageId = tour.relationships['phone-image'].data.id;
      if (phoneImageId !== prevPhoneImageId) return true;
    }

    return false;
  }

  shouldUpdatePhoneImageState(prevProps) {
    const { isOpen, tour } = this.props;
    const { phoneImageRequest } = this.state;
    const prevTour = prevProps.tour;

    if (isOpen) {
      if (phoneImageRequest.status === 'uninitialized') return true;

      const prevPhoneImageRel = prevTour.relationships['phone-image'];
      const phoneImageRel = tour.relationships['phone-image'];
      if (JSON.stringify(prevPhoneImageRel) !== JSON.stringify(phoneImageRel)) return true;
    }

    return false;
  }

  shouldUpdateDesktopImageState(prevProps) {
    const { isOpen, tour } = this.props;
    const { desktopImageRequest } = this.state;
    const prevTour = prevProps.tour;

    if (isOpen) {
      /* if (desktopImageRequest.status === 'uninitialized') return true; */
      const prevDesktopImageRel = prevTour.relationships['desktop-image'];
      const desktopImageRel = tour.relationships['desktop-image'];
      if (JSON.stringify(prevDesktopImageRel) !== JSON.stringify(desktopImageRel)) return true;
    }

    return false;
  }

  loadImages() {
    const { loadDesktopImage, loadPhoneImage } = this;
    loadDesktopImage()
      .then(loadPhoneImage)
      .catch(err => toastr.error('error', err));
  }

  loadDesktopImage() {
    const { tour } = this.props;
    const tourDesktopImgRel = tour.relationships['desktop-image'];
    if (tourDesktopImgRel && tourDesktopImgRel.data) {
      const desktopImage = tour.relationships['desktop-image'].data;
      if (desktopImage) {
        return dispatch(api.getImage({ id: desktopImage.id })).then((image) => {
          this.setState({ desktopImageRequest: image });
        });
      }
      this.setState({ desktopImageRequest: { status: 'empty' } });
    }
    return Promise.resolve();
  }

  loadPhoneImage() {
    const { tour } = this.props;
    const tourPhoneImgRel = tour.relationships['phone-image'];
    if (tourPhoneImgRel && tourPhoneImgRel.data) {
      const phoneImage = tour.relationships['phone-image'].data;
      if (phoneImage) {
        dispatch(api.getImage({ id: phoneImage.id })).then((image) => {
          this.setState({ phoneImageRequest: image });
        });
      }
      this.setState({ phoneImageRequest: { status: 'empty' } });
    }
    return Promise.resolve();
  }

  handleBindSaves(saveMethod) {
    const { bindOnSave } = this.props;
    bindOnSave(saveMethod);
    const newState = { ...this.state, handleConfigSave: saveMethod };
    this.setState(newState);
  }

  handleResetScroll() {
    const { isMobile } = this.props;
    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }

  handleConfigNavigation(activeTab) {
    const { hasPendingChanges } = this.props;
    if (hasPendingChanges) {
      return new Promise((resolve) => {
        confirmationToastr(
          '¿Tienes cambios pendientes por guardar, si continuas los perderás, estás segur@ de continuar?',
          () => {
            this.setState({ activeTab }, () => {
              dispatch({
                type: UPDATE_PENDING_CHANGES,
                hasPendingChanges: false,
              });
              resolve(true);
            });
          },
          () => {
            resolve(false);
          },
        );
      });
    }
    this.setState({ activeTab });
    return true;
  }

  static renderModalTitle(activeTab) {
    switch (activeTab) {
      case 1:
        return 'Configuración';
      case 2:
        return 'Campaña';
      case 3:
        return 'Consejos de viaje';
      case 4:
        return 'Tienda virtual';
      case 5:
        return 'Ofertas';
      default:
        return null;
    }
  }
  

  render() {
    const {
      isOpen, isMobile, closeModal, handleContentSelection, tour,
    } = this.props;
    const {
      activeTab, desktopImageRequest, phoneImageRequest, handleConfigSave,
    } = this.state;

    var myCampaigns = this.state.campaigns ? this.state.campaigns.filter(item => item.createdBy == this.props.user.id) : []
    return isOpen ? (
      <Modal
        className="tour-config-modal"
        overlayClassName="tour-config-modal__overlay"
        isOpen={isOpen}
        onRequestClose={closeModal}
        onAfterOpen={this.handleResetScroll}
      >
        <TourConfigNavMenu
          activeTab={activeTab}
          handleConfigNavigation={this.handleConfigNavigation}
        />
        <section className="container-fluid tour-config-modal__content">
          {isMobile ? null : (
            <header className="row tour-config-modal__header">
              <div className="col-lg-12 tour-config-modal__header-actions">
                <h4 className="tour-config-modal__header-title">
                  {TourConfigModal.renderModalTitle(activeTab)}
                </h4>
                {isEmpty(handleConfigSave) || activeTab === 2 ? null : (
                  <PromiseButton
                    type="submit"
                    className="tour-config-modal__header-btn tour-config-modal__header-btn--blue"
                    title="Guardar"
                    onClick={handleConfigSave}
                  >
                    {CHECK_ICON}
                  </PromiseButton>
                )}
                <button
                  className="closeIcon p-0"
                  name="warningModal"
                  type="button"
                  onClick={closeModal}
                >
                  {CLOSE_ICON}
                </button>
              </div>
            </header>
          )}
          {activeTab === 1 ? (
            <TourConfigMainTemplate
              handleBindSaves={this.handleBindSaves}
              closeModal={closeModal}
              onFileUploadComplete={this.onFileUploadComplete}
              handleResetScroll={this.handleResetScroll}
              desktopImageUrl={
                desktopImageRequest.status === 200
                  ? desktopImageRequest.body.data.attributes['tiny-thumb']
                  : null
              }
              phoneImageUrl={
                phoneImageRequest.status === 200 ? phoneImageRequest.body.data.attributes['tiny-thumb'] : null
              }
              tourId={tour.id}
            />
          ) : null}
          {activeTab === 2 ? (
            <TourConfigSponsor tour={tour} closeModal={closeModal} handleBindSaves={this.handleBindSaves} myCampaigns={myCampaigns} initialLoad={this.initialLoad} getCampaign={this.getCampaign} currentCampaign={this.state.currentCampaign} />
          ) : null}
          {activeTab === 3 ? (
            <TourConfigTips
              handleContentSelection={handleContentSelection}
              handleBindSaves={this.handleBindSaves}
            />
          ) : null}
          {activeTab === 4 ? (
            <TourConfigShop
              handleContentSelection={handleContentSelection}
              tour={tour}
              handleBindSaves={this.handleBindSaves}
            />
          ) : null}
        </section>
      </Modal>
    ) : null;
  }
}

TourConfigModal.propTypes = {
  isOpen: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  hasPendingChanges: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  bindOnSave: PropTypes.func.isRequired,
  tourId: PropTypes.string.isRequired,
  tour: TYPES.tourType.isRequired,
  handleContentSelection: PropTypes.func.isRequired,
};
TourConfigModal.defaultProps = {
  isOpen: false,
  hasPendingChanges: false,
};

function mapStateToProps(state, ownProps) {
  const user = state.auth.currentUser;
  const { tourId } = ownProps;
  const { hasPendingChanges } = state.workspace;
  const tour = isEmpty(tourId)
    ? null
    : state.bees.entities.tours && state.bees.entities.tours[tourId];
  return {
    tour,
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    hasPendingChanges,
    user,
  };
}

export default connect(mapStateToProps)(TourConfigModal);
