import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TourConfigShopTemplate from './tour-config-shop-template';
import { confirmationToastr, isEmpty, handleError } from '../../../../../utils';
import reduxBeeMakeRequest from '../../../../../utils/redux-bee/redux-bee-make-request';
import TYPES from '../../../../../constants/types';
import { LOCALSTORAGE_SESSION } from '../../../../../constants';

const TourConfigShop = (props) => {
  const {
    dispatch,
    handleContentSelection,
    handleBindSaves,
    currentUserId,
    currentUserProducts,
    productCovers,
    tour,
  } = props;

  const [products, setProducts] = useState(currentUserProducts);

  useEffect(() => {
    reduxBeeMakeRequest(dispatch, 'getCurrentUserProducts', { id: currentUserId }, {}).catch(handleError);
    handleBindSaves(null);
  }, [currentUserId, dispatch, handleBindSaves]);

  useEffect(() => {
    setProducts(currentUserProducts);
  }, [currentUserProducts, productCovers]);

  const handleCreateNewProduct = () => {
    const newProduct = {
      type: 'products',
      attributes: {
        title: null,
        text: null,
        'product-name': null,
        price: null,
        link: null,
        'video-url': null,
        'platform-logo': '0',
        order: null,
        visible: true,
      },
    };

    handleContentSelection(newProduct);
  };

  const handleToggleVisibility = useCallback((product) => {
    const productToToggle = {
      ...product,
      attributes: { ...product.attributes, visible: !product.attributes.visible },
    };

    return reduxBeeMakeRequest(
      dispatch,
      'updateProduct',
      { id: productToToggle.id },
      productToToggle,
    ).catch(handleError);
  }, [dispatch]);

  const handleDelete = useCallback(
    async productToDelete =>
      new Promise((resolve) => {
        confirmationToastr(
          '¿Estás seguro de eliminarlo?',
          () =>
            reduxBeeMakeRequest(dispatch, 'deleteProduct', { id: productToDelete.id }, {}).then(
              () => reduxBeeMakeRequest(dispatch, 'getCurrentUser', null, {}).catch(() => {
                // if /me fails, logout the user
                localStorage.removeItem(LOCALSTORAGE_SESSION);
                // and refresh the page
                window.location.reload();
              }),
            ).catch((e) => {
              handleError(e);
              resolve(false);
            }),
          () => resolve(false),
        );
      }),
    [dispatch],
  );

  const handleShopVisibility = useCallback(async () => {
    const {
      attributes: { 'no-virtual-shop': noVirtualShop },
    } = tour;
    return reduxBeeMakeRequest(
      dispatch,
      'updateTour',
      { id: tour.id },
      { ...tour, attributes: { ...tour.attributes, 'no-virtual-shop': !noVirtualShop } },
    ).catch(handleError);
  });

  return (
    <TourConfigShopTemplate
      products={products}
      productCovers={productCovers}
      shopIsHidden={isEmpty(tour) ? false : tour.attributes['no-virtual-shop']}
      handleContentSelection={handleContentSelection}
      handleCreateNewProduct={handleCreateNewProduct}
      handleToggleVisibility={handleToggleVisibility}
      handleShopVisibility={handleShopVisibility}
      handleDelete={handleDelete}
    />
  );
};

TourConfigShop.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  currentUserProducts: PropTypes.arrayOf(TYPES.productType),
  productCovers: PropTypes.arrayOf(TYPES.imageType),
  handleContentSelection: PropTypes.func.isRequired,
  handleBindSaves: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  tour: TYPES.tourType,
};

TourConfigShop.defaultProps = {
  currentUserProducts: [],
  productCovers: [],
  tour: null,
};

function mapStateToProps(state) {
  const { isAuthenticated } = state.auth;
  const currentUserId = isAuthenticated ? parseInt(state.auth.currentUser.id, 10) : null;
  const currentUser = isEmpty(currentUserId) ? null : state.bees.entities.me[currentUserId];
  const { data: currentUserProductsData } = isEmpty(currentUser)
    ? []
    : currentUser.relationships.products;
  const storedProducts = state.bees.entities.products;

  const currentUserProducts = isEmpty(currentUserProductsData)
    ? []
    : currentUserProductsData.reduce((acc, productData) => {
      if (!isEmpty(storedProducts)) {
        const product = storedProducts[productData.id];
        if (!isEmpty(product)) {
          acc.push(product);
        }
      }
      return acc;
    }, []);

  const productCovers = isEmpty(currentUserProducts)
    ? []
    : currentUserProducts.reduce((acc, product) => {
      const imagesData = product.relationships.images.data;
      if (!isEmpty(imagesData)) {
        const productCover = imagesData
          .map(imageData => state.bees.entities[imageData.type][imageData.id])
          .sort((a, b) => a.attributes.order - b.attributes.order)[0];
        if (!isEmpty(productCover)) {
          acc.push(productCover);
        }
      }
      return acc;
    }, []);

  return {
    currentUserId,
    currentUserProducts,
    productCovers,
  };
}

export default connect(mapStateToProps)(TourConfigShop);
