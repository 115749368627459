/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash.clonedeep';
import {
  TourEditionHeader,
  DayContent,
  TourConfigModal,
  DayContentModalVersion,
  TourEditionNav,
  MenuTourContentModal,
} from './parts';
import {
  Navbar, TourFile, LocationSearch, PromiseButton,
} from '../../components';
import './tour-edition-page.scss';
import api from '../../config/api-endpoints';
import { TOUR_EDITION_DINAMIC_URL, UPDATE_ACTIVE_TOUR, UPDATE_PENDING_CHANGES } from '../../constants';
import { CHECK_ICON } from '../../helpers';
import { isEmpty, confirmationToastr } from '../../utils';
import handleError from '../../utils/errorHandler';
import reduxBeeMakeRequest from '../../utils/redux-bee/redux-bee-make-request';
import ContentEditionModal from './parts/content-edition-modal';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery'
const SHOW_SEARCH = {
  showSearchTab: true,
  showTourContentModal: false,
  showConfigModal: false,
  showPreviewTab: false,
  showTourContentMenuModal: false,
};
const SHOW_TOUR_PREVIEW = {
  showSearchTab: false,
  showTourContentModal: false,
  showConfigModal: false,
  showPreviewTab: true,
  resetSelection: true,
};

class TourEditionPage extends Component {
  constructor(props) {
    super(props);
    this._isEditMode = false;
    this.state = {
      showSearchTab: true,
      showTourContentModal: false,
      showConfigModal: false,
      showPreviewTab: false,
      selectedContent: null,
      selectedChildTourContent: null,
      selectedDay: 1,
      tourTagsData: [],
      tourContentsData: [],
      synchWithModal: 0,
      synchWithDesktop: 0,
      isLoadingContent: true,
      pois:[],
    };

    this.loadRemoteTour = this.loadRemoteTour.bind(this);
    this.savePopulatedTourContentsToState = this.savePopulatedTourContentsToState.bind(this);
    this.updateLocalContentState = this.updateLocalContentState.bind(this);
    this.updateLocalTourContentsDataTourContent = this.updateLocalTourContentsDataTourContent.bind(
      this,
    );
    this.createNewTour = this.createNewTour.bind(this);
    this.createPoiTourContent = this.createPoiTourContent.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.bindOnSave = this.bindOnSave.bind(this);
    this.handleDeleteTourContent = this.handleDeleteTourContent.bind(this);
    this.handleToogleTourContentVisibility = this.handleToogleTourContentVisibility.bind(this);
    this.handleServerTourContentsReoreder = this.handleServerTourContentsReoreder.bind(this);
    this.handleDaySelection = this.handleDaySelection.bind(this);
    this.handleAddDayToTour = this.handleAddDayToTour.bind(this);
    this.triggerOrderSynchWithModal = this.triggerOrderSynchWithModal.bind(this);
    this.triggerOrderSynchWithDesktop = this.triggerOrderSynchWithDesktop.bind(this);
    this.handleContentSelection = this.handleContentSelection.bind(this);
    this.handleRemoveDay = this.handleRemoveDay.bind(this);
/*     this.setPois = this.setPois.bind(this); */
  }

 /*  setPois(pois) {
    this.setState({
      pois: pois,
    });
  } */

  componentDidMount() {
    const { dispatch, tourInEdition } = this.props;
    this.ensureEditMode();
    this.loadRemoteTour().then(() => {
      dispatch({ type: UPDATE_ACTIVE_TOUR, tourInEdition });
    });
    this.setState({
      pois: this.props.pois,
    })
  }

  componentDidUpdate(prevProps) {
    const prevTourId = prevProps.tourId;

    const { tourId, tourInEdition } = this.props;
    if (prevTourId !== tourId) {
      this.ensureEditMode();
    }
    if (tourId !== 'new') {
      const prevTour = (prevProps.tourInEdition && { ...prevProps.tourInEdition }) || null;
      const currTour = (tourInEdition && { ...tourInEdition }) || null;
      const firstLoad = prevTour === null && currTour === null;
      // if the id is a number and there is currently no "tourInEdition" in the state
      // loads the remote tour from the server
      if (firstLoad) {
        this.loadRemoteTour();
      }
    }
    if (this.props.pois.length !== prevProps.pois.length) {
      this.setState({
        pois: this.props.pois,
      })
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({ type: UPDATE_ACTIVE_TOUR, tourInEdition: null });
  }

  bindOnSave(handleHeaderSave) {
    this.setState({
      handleHeaderSave,
    });
  }

  savePopulatedTourContentsToState(populatedTourContents) {
    return new Promise((resolve) => {
      const { selectedContent } = this.state;
      const newState = {
        tourContentsData: populatedTourContents,
      };
      if (selectedContent) {
        const newSelectedTourContent = populatedTourContents.find(
          tourContent => parseInt(tourContent.id, 10) === parseInt(selectedContent.id, 10),
        );
        newState.selectedContent = newSelectedTourContent;
      }
      this.setState(newState, resolve);
    });
  }

  updateLocalTourContentsDataTourContent(tourContent) {
    return new Promise((resolve) => {
      const { tourContentsData } = this.state;
      const newState = {
        tourContentsData: [...tourContentsData],
      };
      const { id: tourContentId } = tourContent;
      const localTourContentPosition = tourContentsData.findIndex(
        localTourContent => localTourContent.id === tourContentId,
      );
      if (localTourContentPosition >= 0) {
        newState.tourContentsData[localTourContentPosition] = tourContent;
      }
      this.setState(newState, resolve);
    });
  }

  handleNavigation({
    showSearchTab,
    showTourContentModal,
    showConfigModal,
    showPreviewTab,
    showTourContentMenuModal,
    resetSelection = false,
  }) {
    const { hasPendingChanges, dispatch } = this.props;
    // !!variable
    //  false when:
    //    - undefined/null
    //    - false
    //    - 0
    //    - empty string
    //  true otherwhise
    const newState = {
      showSearchTab: !!showSearchTab,
      showTourContentModal: !!showTourContentModal,
      showConfigModal: !!showConfigModal,
      showPreviewTab: !!showPreviewTab,
      showTourContentMenuModal: !!showTourContentMenuModal,
    };

    if (resetSelection) {
      newState.selectedContent = null;
      newState.selectedChildTourContent = null;
    }
    if (hasPendingChanges) {
      return new Promise((resolve) => {
        confirmationToastr(
          '¿Tienes cambios pendientes por guardar, si continuas los perderás, estás segur@ de continuar?',
          () => {
            this.setState(newState, () => {
              dispatch({
                type: UPDATE_PENDING_CHANGES,
                hasPendingChanges: false,
              });
              resolve(true);
            });
          },
          () => {
            resolve(false);
          },
        );
      });
    }
    this.setState(newState);
    return true;
  }

  handleDaySelection(selectedDay) {
    this.setState({ selectedDay });
  }

  handleDeleteTourContent(tourContent, parentTourContent = false) {
    return new Promise((resolve) => {
      confirmationToastr(
        '¿Estás seguro de eliminarlo?',
        () => {
          const { dispatch } = this.props;
          const { updateLocalContentState, loadRemoteTour } = this;
          dispatch(api.deleteTourContent({ id: tourContent.id })).then(() => {
            if (parentTourContent) {
              updateLocalContentState(parentTourContent).then(() => {
                resolve(true);
              });
            } else {
              loadRemoteTour();
              resolve(true);
            }
          }).catch((e) => {
            handleError(e);
            resolve(false);
          });
        },
        () => {
          resolve(false);
        },
      );
    });
  }

  handleToogleTourContentVisibility(tourContent) {
    const { updateLocalContentState } = this;
    const { dispatch } = this.props;
    const toggledTourContent = { ...tourContent };
    const {
      attributes, id, type, relationships,
    } = toggledTourContent;
    // invert visible bool
    let { visible } = attributes;
    visible = !visible;

    const newAttributes = { ...attributes, visible };
    toggledTourContent.attributes.visible = visible;
    updateLocalContentState(toggledTourContent);
    return reduxBeeMakeRequest(
      dispatch,
      'updateTourContent',
      { id },
      {
        id,
        type,
        attributes: newAttributes,
        relationships,
      },
    )
      .catch((err) => {
        handleError(err);
        // if an error occurred during visibility toggle, return local visibility to previous value
        toggledTourContent.attributes.visible = !visible;
        updateLocalContentState(toggledTourContent);
      })
      .finally(() => Promise.resolve());
  }

  handleContentSelection(content) {
    const { selectedChildTourContent } = this.state;
    let newState = {};

    if (isEmpty(content)) {
      newState = isEmpty(selectedChildTourContent)
        ? { selectedContent: null }
        : { selectedChildTourContent: null };
    } else {
      newState = content.type !== 'tourContents' || isEmpty(content.relationships['tour-content'].data)
        ? { selectedContent: content }
        : { selectedChildTourContent: content };
    }
    return new Promise((resolve) => {
      this.setState(newState, resolve);
    });
  }

  handleServerTourContentsReoreder(tourContentId, reorderPayload) {
    const { dispatch } = this.props;
    return reduxBeeMakeRequest(
      dispatch,
      'updateTourContent',
      { id: tourContentId },
      reorderPayload,
    ).catch(handleError);
  }

  handleAddDayToTour() {
    const { dispatch, tourInEdition } = this.props;
    const patchPayload = {
      data: {
        type: 'tours',
        id: tourInEdition.id,
        attributes: tourInEdition.attributes,
        relationships: tourInEdition.relationships,
      },
    };
    const currTourDuration = patchPayload.data.attributes['tour-duration'] || 1;
    patchPayload.data.attributes['tour-duration'] = currTourDuration + 1;
    return dispatch(api.updateTour({ id: tourInEdition.id }, patchPayload))
      .then(() => dispatch(api.getTour({ id: tourInEdition.id })))
      .catch(handleError)
      .finally(() => Promise.resolve());
    // return currTourDuration + 1;
  }

  handleRemoveDay() {
    const { dispatch, tourInEdition } = this.props;
    const { selectedDay } = this.state;
    const { id: tourId, attributes, relationships } = tourInEdition || {};

    const params = {
      id: tourId,
      'delete-day': selectedDay,
    };
    const payload = {
      type: 'tours',
      id: tourId,
      attributes: { ...attributes },
      relationships: { ...relationships },
    };

    return reduxBeeMakeRequest(dispatch, 'updateTour', params, payload)
      .then(tour =>
        reduxBeeMakeRequest(dispatch, 'getTourTourContents', { id: tourId })
          .then(this.savePopulatedTourContentsToState)
          .then(() => {
            const { 'tour-duration': tourDuration } = tour.attributes;
            if (selectedDay > tourDuration) {
              this.setState({ selectedDay: tourDuration });
            }
          }),
      )
      .catch(error => handleError(error))
      .finally(() => Promise.resolve());
  }

  ensureEditMode() {
    const { tourId, dispatch } = this.props;
    if (tourId === 'new') {
      this._isEditMode = false;
      this.createNewTour().then((tour) => {
        dispatch(push(TOUR_EDITION_DINAMIC_URL(tour.body.data.id)));
      });
    } else {
      this._isEditMode = true;
    }
  }

  createNewTour() {
    const { dispatch } = this.props;
    return dispatch(
      api.createTour({
        data: {
          type: 'tour',
          attributes: {
            title: 'Mi nueva ruta',
          },
        },
      }),
    ).catch(handleError);
  }

  createPoiTourContent(e, poi) {
    e.preventDefault();
    e.stopPropagation(); // this prevents the wrapping anchor to fire
    const { dispatch, tourInEdition } = this.props;
    const { selectedDay, tourContentsData } = this.state;
    const newState = { tourContentsData: [...tourContentsData] };
    const {
      attributes: { 'poi-type': poiType },
    } = poi;

    const requestPayload = {
      type: 'tourContents',
      attributes: { day: selectedDay, contentType: poiType },
      relationships: {
        tour: {
          data: {
            type: 'tours',
            id: tourInEdition.id,
          },
        },
        poi: {
          data: {
            type: 'pois',
            id: poi.id,
          },
        },
      },
    };
    reduxBeeMakeRequest(dispatch, 'createTourContent', {}, requestPayload)
      .then((populatedTourContent) => {
        newState.tourContentsData.push(populatedTourContent);
        this.setState(newState);
        dispatch(api.getTour({ id: tourInEdition.id }));
      })
      .catch(err => handleError(err));
  }

  loadRemoteTour() {
    const { tourId, dispatch } = this.props;
    const newState = {
      tourTagsData: [],
    };

    if (tourId !== 'new') {
      return dispatch(api.getTour({ id: tourId })).then((tour) => {
        dispatch(api.getTourTags({ id: tourId }))
          .then((tourTagsRequest) => {
            newState.tourTagsData = tourTagsRequest.body.data;
            return reduxBeeMakeRequest(dispatch, 'getTourTourContents', { id: tourId });
          })
          .then(this.savePopulatedTourContentsToState)
          .then(() =>
            this.setState({ ...newState, isLoadingContent: false }, () => Promise.resolve(tour)),
          );
      });
    }
    return Promise.resolve(null);
  }

  updateLocalContentState(content) {
    const { selectedContent, tourContentsData } = this.state;
    const { id, type, attributes: { 'content-type': contentType = null } = {} } = content;

    if (type === 'products' || parseInt(contentType, 10) === 8) {
      this.setState({ selectedContent: content });
    } else if (type === 'tourContents') {
      const newState = {
        tourContentsData: _cloneDeep(tourContentsData),
      };
      const position = tourContentsData.findIndex(
        currTourCont => parseInt(id, 10) === parseInt(currTourCont.id, 10),
      );
      if (position !== -1) {
        newState.tourContentsData[position] = { ...content };
        if (selectedContent) {
          const newSelectedTourContent = newState.tourContentsData.find(
            currTourCont => parseInt(currTourCont.id, 10) === parseInt(selectedContent.id, 10),
          );
          newState.selectedContent = newSelectedTourContent;
        }
        this.setState(newState);
      }
    }
  }

  triggerOrderSynchWithModal() {
    const { synchWithModal } = this.state;
    this.setState({ synchWithModal: synchWithModal + 1 });
  }

  triggerOrderSynchWithDesktop() {
    const { synchWithDesktop } = this.state;
    this.setState({ synchWithDesktop: synchWithDesktop + 1 });
  }

  scrollPage = (pos) => {
    if (pos == 1) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo({
        top: $('.search-filters').position().top,
        left: 0,
        behavior: 'smooth'
      });
    }
    // $('#homePageEdition').animate({ scrollTop: $('#homePageEdition').prop("scrollHeight")}, 1000);
  }

  tourEditionNavDesktop = () => {
    return (
      <nav className='tour-edition-nav-desktop'>
        <button data-toggle="tooltip" data-tip="Vista previa" data-class='tooltipNavEditionDesktop' data-place='right' className=' tour-edition-nav-desktop__button' onClick={() => this.handleNavigation(SHOW_TOUR_PREVIEW)} >
          <img width='28' src="../../../../assets/images/cohete.svg" alt="" />
        </button>
        <button data-toggle="tooltip" data-tip="Configuración" data-class='tooltipNavEditionDesktop' data-place='right' className='tour-edition-nav-desktop__button' onClick={() => this.setState({ showConfigModal: true })}>
          <img width='25' src="../../../../assets/images/configurar_azul.svg" alt="" />
        </button>
        <button data-toggle="tooltip" data-tip="Añadir elementos" data-class='tooltipNavEditionDesktop' data-place='right' className='tour-edition-nav-desktop__button' onClick={() => this.setState({ showTourContentMenuModal: true })}>
          <img width='25' src="../../../../assets/images/anadir_azul.svg" alt="" />
        </button>
        <button data-toggle="tooltip" data-tip="Ordenar" data-class='tooltipNavEditionDesktop' data-place='right' className='tour-edition-nav-desktop__button' onClick={() => this.setState({ showTourContentModal: true })}>
          <img width='25' src="../../../../assets/images/ruta_azul.svg" alt="" />
        </button>
        <button data-toggle="tooltip" data-tip="Inicio" data-class='tooltipNavEditionDesktop' data-place='right' className='tour-edition-nav-desktop__button' onClick={() => this.scrollPage(1)}>
          <img width='23' src="../../../../assets/images/subir_azull.svg" alt="" />
        </button>
        <button data-toggle="tooltip" data-tip="Mapa" data-class='tooltipNavEditionDesktop' data-place='right' className='tour-edition-nav-desktop__button' onClick={() => this.scrollPage(2)}>
          <img width='25' src="../../../../assets/images/mapa_azul.svg" alt="" />
        </button>
        <ReactTooltip effect="solid" html />
      </nav>

    )
  }

  render() {
    const { tourId, isMobile, tourInEdition } = this.props;

    const {
      showSearchTab,
      showTourContentModal,
      showConfigModal,
      showPreviewTab,
      showTourContentMenuModal,
      handleHeaderSave,
      selectedContent,
      selectedChildTourContent,
      tourTagsData,
      tourContentsData,
      isLoadingContent,
      selectedDay,
      synchWithModal,
      synchWithDesktop,
    } = this.state;

    if (!tourInEdition) return null;

    const ContextualActions = () =>
      (isEmpty(handleHeaderSave) ? null : (
        <ul className="navbar-nav d-lg-none navbar-contextuals">
          <li className="nav-item nav-item--circle">
            <PromiseButton
              type="button"
              className="nav-link"
              title="Guardar"
              onClick={handleHeaderSave}
            >
              {CHECK_ICON}
            </PromiseButton>
          </li>
        </ul>
      ));
    const { title, 'tour-duration': tourDuration } = tourInEdition.attributes;
    const contentInEdition = isEmpty(selectedChildTourContent)
      ? selectedContent
      : selectedChildTourContent;
    const { attributes: { 'content-type': tourContentInEditionType = null } = {} } = contentInEdition || {};
    const isTourContentPoiType = tourContentInEditionType === 1 || tourContentInEditionType === 2;
    const navigationState = {
      showSearchTab,
      showTourContentModal,
      showConfigModal,
      showPreviewTab,
      showTourContentMenuModal,
    };
    const firstLevelTourContents = tourContentsData.filter(
      tourContent =>
        tourContent.relationships && isEmpty(tourContent.relationships['tour-content'].data),
    );
    return (
      <div id='homePageEdition'>
        {showPreviewTab && !isMobile ? null : (
          <Navbar ContextualActions={ContextualActions} alterBrand={title} />
        )}
        {showPreviewTab ? (
          <TourFile
            tour={tourInEdition}
            activeDay={selectedDay}
            handleDaySelection={this.handleDaySelection}
            handleNavigation={() => this.handleNavigation(SHOW_SEARCH)}
          />
        ) : (
            <main className="tour-edition-page">
              <div className='content-tour-edition-nav-desktop'>
                <TourEditionHeader
                  tour={tourInEdition}
                  tourId={tourId}
                  tourTagsData={tourTagsData}
                  handleNavigation={this.handleNavigation}
                />
                {
                  !isMobile && this.tourEditionNavDesktop()
                }
              </div>
              <DayContent /* aqui 2 */
                key={`desktop-${synchWithDesktop}`}
                triggerOrderSynchWithModal={this.triggerOrderSynchWithModal}
                tour={tourInEdition}
                activeDay={selectedDay}
                tourContentsData={firstLevelTourContents}
                isLoadingContent={isLoadingContent}
                handleNavigation={this.handleNavigation}
                handleContentSelection={this.handleContentSelection}
                handleDaySelection={this.handleDaySelection}
                handleAddDayToTour={this.handleAddDayToTour}
                handleRemoveDay={this.handleRemoveDay}
                handleDeleteTourContent={this.handleDeleteTourContent}
                handleToogleTourContentVisibility={this.handleToogleTourContentVisibility}
                handleServerTourContentsReoreder={this.handleServerTourContentsReoreder}
                pois={this.state.pois}
              />
              {!isMobile || showSearchTab ? (
                <LocationSearch
                  addedTourContents={firstLevelTourContents}
                  addButtonCB={this.createPoiTourContent}
                  handleDaySelection={this.handleDaySelection}
                  handleAddDayToTour={this.handleAddDayToTour}
                  tourDuration={tourDuration}
                  activeDay={selectedDay}
                  showTypeFilter
                  orderBy="tours-counter"
                  enableNoValidated
                />
              ) : null}
            </main>
          )}
        {isMobile ? (
          <TourEditionNav
            tabState={navigationState}
            handleNavigation={this.handleNavigation}
          />
        ) : null}
        <DayContentModalVersion
          key={`modal-${synchWithModal}`}
          triggerOrderSynchWithDesktop={this.triggerOrderSynchWithDesktop}
          tour={tourInEdition}
          activeDay={selectedDay}
          isOpen={isEmpty(contentInEdition) && !showTourContentMenuModal && showTourContentModal}
          showTourContentModal={showTourContentModal}
          handleNavigation={this.handleNavigation}
          tourContentsData={firstLevelTourContents}
          handleContentSelection={this.handleContentSelection}
          handleAddDayToTour={this.handleAddDayToTour}
          handleRemoveDay={this.handleRemoveDay}
          handleDaySelection={this.handleDaySelection}
          handleDeleteTourContent={this.handleDeleteTourContent}
          handleToogleTourContentVisibility={this.handleToogleTourContentVisibility}
          handleServerTourContentsReoreder={this.handleServerTourContentsReoreder}
          pois={this.state.pois}
        />

        <TourConfigModal
          isOpen={showConfigModal}
          closeModal={() => this.handleNavigation(SHOW_SEARCH)}
          tourId={tourId}
          tour={tourInEdition}
          bindOnSave={this.bindOnSave}
          handleContentSelection={this.handleContentSelection}
        />

        {isEmpty(contentInEdition) || showTourContentMenuModal ? null : (
          <ContentEditionModal /* aqui 1 */
            contentInEdition={contentInEdition}
            updateTourContentData={this.updateLocalTourContentsDataTourContent}
            refreshTour={this.loadRemoteTour}
            updateLocalContentState={this.updateLocalContentState}
            openTourContentsMenu={() =>
              this.handleNavigation({ ...navigationState, showTourContentMenuModal: true })
            }
            handleContentSelection={this.handleContentSelection}
            pois={this.state.pois}
          />
        )}

        {showTourContentMenuModal ? (
          <MenuTourContentModal
            isOpen={showTourContentMenuModal}
            closeModal={() =>
              this.handleNavigation({ ...navigationState, showTourContentMenuModal: false })
            }
            tourInEdition={tourInEdition}
            activeDay={selectedDay}
            handleContentSelection={this.handleContentSelection}
            selectedContent={isTourContentPoiType ? contentInEdition : null}
            /* TODO: refactor to avoid refresh tour and population methods, change to agnostic logic
            where retrieve the required content throug redux srtate from it's neccesary */
            reloadTourContents={this.loadRemoteTour}
            updateLocalContentState={this.updateLocalContentState}
          />
        ) : null}
      </div>
    );
  }
}


TourEditionPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tourId: PropTypes.string.isRequired,
  tourInEdition: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  isMobile: PropTypes.bool,
  hasPendingChanges: PropTypes.bool,
};

TourEditionPage.defaultProps = {
  tourInEdition: null,
  isMobile: false,
  hasPendingChanges: false,
};

function mapStateToProps(state, ownProps) {
  const tourId = ownProps.match.params.tour_id;
  const poisContent = state.bees.entities.pois ? Object.values(state.bees.entities.pois) : [];
  const pois = poisContent.filter(poi => poi.attributes["poi-type"] == 1)
  const { hasPendingChanges } = state.workspace;
  // const tourContents = getRelationship(state, ownProps.tourInEdition, 'tour-contents');
  let tourInEdition = null;

  if (tourId !== 'new' && state.bees.entities.tours) {
    tourInEdition = state.bees.entities.tours[tourId];
  }

  return {
    tourId,
    tourInEdition,
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    hasPendingChanges,
    pois,
  };
}

export default connect(mapStateToProps)(TourEditionPage);
