import React from 'react';
import TYPES from '../../../../../constants/types';
import { isEmpty } from '../../../../../utils';

function renderHeader(title, subtitle) {
  return (
    <header className="col px-0">
      {isEmpty(title) ? null : (
        <div
          className={`m-0 ${
            isEmpty(subtitle) ? 'col' : 'col-12 col-md-4 mr-2'
            } tour-content-text-box__decorator px-0`}
        >
          <h4 className="tour-content-text-box__title">{title}</h4>
        </div>
      )}
      {isEmpty(subtitle) ? null : (
        <div className="col px-0">
          <p className="tour-content-text-box__subtitle">{subtitle}</p>
        </div>
      )}
    </header>
  );
}
function TourContentTextBox(props) {
  const { tourContent } = props;
  const { title, subtitle, text } = tourContent && tourContent.attributes;
  const hasHeader = !isEmpty(title) || !isEmpty(subtitle);
  return (
    <article className="container tour-content-text-box">
      {isEmpty(hasHeader) ? null : renderHeader(title, subtitle)}

      {isEmpty(text) ? null : (
        <div className={`row m-0 ${hasHeader ? 'mt-4 mx-0' : ''}`}>
          <div className="col px-0">
            <div className="tour-content-text-box__main-text" dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </div>
      )}
    </article>
  );
}

TourContentTextBox.propTypes = {
  tourContent: TYPES.tourContentType.isRequired,
};

export default TourContentTextBox;
