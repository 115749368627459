import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TYPES from '../../../constants/types';
import ResponsiveImg from '../../responsive-img';
import { getImageSrcSet } from '../../../utils';

function PoiGallery(props) {
  const {
    results,
    onPoiClick,
  } = props;
  /* console.log(results) */

  return (
    <div className="poi-gallery">
      {results.map((poi) => {
        poi.attributes.images.sort((a, b) => b.likes - a.likes)
        const {
          id,
          attributes: { title, mainImage, state },
        } = poi;
        return (
          <button
            type="button"
            className="poi-gallery__poi"
            key={`PoiGallery${id}`}
            onClick={() => {
              onPoiClick(poi);
            }}
          >
            <ResponsiveImg
              className="poi-gallery__poi-image"
              wrapperClassName={`
                poi-gallery__poi-image-wrapper ${state !== 3 ? ' poi-gallery__poi-image-wrapper--red' : ''} ${poi.attributes["type-preview"] != 0 ? "d-flex justify-content-center align-items-center" : ""}
              `}
              srcSet={getImageSrcSet(poi.attributes.images.length >0 ? poi.attributes.images[0] : undefined)}
            >
              <span className="sr-only">{title}</span>
              {poi.attributes["type-preview"] != 0 ? (
                <h6 className="position-absolute">{title}</h6>
              ) : null}
            </ResponsiveImg>
          </button>
        );
      })}
    </div>
  );
}

PoiGallery.propTypes = {
  results: PropTypes.arrayOf(TYPES.poiType),
  onPoiClick: PropTypes.func.isRequired,
};

PoiGallery.defaultProps = {
  results: [],
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(PoiGallery);
