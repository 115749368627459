import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { CIRCLE_CROSS_ICON } from '../helpers/svg-inline';
import * as ROUTES from '../constants/routes.constants';
import { lockBodyScrolling, restoreBodyScrolling, isEmpty } from '../utils';
import TYPES from '../constants/types';
import reduxBeeMakeRequest from '../utils/redux-bee/redux-bee-make-request';
import { dispatch } from '../reduxStore';
import { UPDATE_CURRENT_USER } from '../constants';
import DisabledPlaceEdition from './disabled-place-edition';

Modal.setAppElement('#root');

const ModalDuplicatedContent = (props) => {
  const {
    poiType, closeModal, currentUser, createNewPoi, isMobile,
  } = props;
  const { attributes: { 'hide-duplicated-modal': hideDuplicatedModal = false } = {} } = currentUser || {};

  useEffect(() => {
    lockBodyScrolling();
    return restoreBodyScrolling;
  }, []);

  const handleCheckboxChange = (e) => {
    const { target: { value } } = e;
    const newCurrentUser = {
      ...currentUser,
      attributes: {
        ...currentUser.attributes,
        'hide-duplicated-modal': value === 'on',
      },
    };
    reduxBeeMakeRequest(dispatch, 'updateCurrentUser', null, newCurrentUser)
      .then((updatedCurrentUser) => {
        dispatch({ type: UPDATE_CURRENT_USER, currentUser: updatedCurrentUser });
      });
  };

  return (
    <Modal
      className={`modal-duplicated-content ${isMobile ? 'modal-duplicated-content--fullscreen' : ''}`}
      overlayClassName="modal-duplicated-content__overlay"
      name="modal-duplicated-content"
      isOpen={!isEmpty(poiType)}
      onRequestClose={closeModal}
    >
      {isMobile ? (
        <DisabledPlaceEdition closeModal={closeModal} />
      ) : (
        <div className="container-fluid">
          <button
            className="modal-duplicated-content__close-btn"
            type="button"
            onClick={closeModal}
          >
            {CIRCLE_CROSS_ICON}
          </button>
          <div className="row align-items-md-center">
            <div className="col-12 col-md-auto">
              <figure>
                <img
                  src="/assets/images/miron.svg"
                  alt="Aviso"
                  className="modal-duplicated-content__main-image"
                />
              </figure>
            </div>
            <div className="col ml-md-auto modal-duplicated-content__content">
              <h4 className="modal-duplicated-content__content-title">
              Evita contenido duplicado
              </h4>
              <p className="modal-duplicated-content__content-text">
              Comprueba primero que el recurso que quieres crear no existe ya
               en la base de datos desde la sección de &quot;Descubre&quot;
              </p>

              <div className="row justify-content-center justify-content-md-start mt-md-5">
                <div className="col-auto pr-1">
                  <Link
                    className="btn btn-primary btn-block"
                    to={{
                      pathname: ROUTES.DISCOVER_URL,
                      search: `?searchType=${poiType}`,
                    }}
                  >
                  ir a descubre
                  </Link>
                </div>
                <div className="col-auto pl-1">
                  <button
                    className="btn btn-primary"
                    onClick={() => createNewPoi(poiType)}
                    type="button"
                  >
                    {`crear ${poiType === 1 ? 'destino' : 'servicio'}`}
                  </button>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <label
                    htmlFor="duplicatedCheckbox"
                    className="custom-checkbox"
                  >
                    No volver a mostrar este mensaje
                    <input
                      type="checkbox"
                      id="duplicatedCheckbox"
                      onChange={handleCheckboxChange}
                      defaultChecked={hideDuplicatedModal}
                    />
                    <i />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

ModalDuplicatedContent.propTypes = {
  poiType: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
  createNewPoi: PropTypes.func.isRequired,
  currentUser: TYPES.meType,
  isMobile: PropTypes.bool,
};

ModalDuplicatedContent.defaultProps = {
  poiType: null,
  currentUser: null,
  isMobile: false,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(ModalDuplicatedContent);
