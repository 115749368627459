import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '../utils';

const DisabledPlaceEdition = (props) => {
  const { closeModal } = props;

  return (
    <section
      className={`
        container-fluid disabled-place-edition ${isEmpty(closeModal) ? '' : 'disabled-place-edition--modal'}
      `}
    >
      <div className="row disabled-place-edition__container">
        <div className="col-12">
          <figure className="mb-0">
            <img
              src="/assets/images/no-destino.svg"
              alt="Edición deshabilitada"
              className="disabled-place-edition__image"
            />
          </figure>
        </div>
        <div className="col-11 mx-auto">
          <h4 className="disabled-place-edition__title">
            La creación de destinos es sólo posible desde el ordenador.
          </h4>
          <p className="disabled-place-edition__description">
            Para garantizar la mejor calidad de estos contenidos no será posible
             hacerlo desde el móvil en una primera fase. Queremos con ello favorecer
             a creadores con cámara reflex y proporcionar así una mayor calidad
             fotográfica para las rutas de toda la comunidad.
          </p>
        </div>
      </div>
      {isEmpty(closeModal) ? null : (
        <footer className="row disabled-place-edition__footer">
          <div className="col-auto mx-auto">
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-primary"
            >
              Aceptar
            </button>
          </div>
        </footer>
      )}
    </section>
  );
};

DisabledPlaceEdition.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default DisabledPlaceEdition;
