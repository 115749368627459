import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { sortableContainer, sortableElement, SortableHandle } from 'react-sortable-hoc';
import { isEmpty, getImageSrcSet } from '../../../../../../utils';
import {
  PLUS_ICON, INFO_ICON, CLOSE_ICON, EYE_NOT_ICON,
} from '../../../../../../helpers';
import { ResponsiveImg, PromiseButton, FineUploader } from '../../../../../../components';
import TYPES from '../../../../../../constants/types';

const FORMATS = ['bold', 'italic', 'link'];
const MODULES = {
  toolbar: FORMATS,
};

const SortableContainer = sortableContainer(({ children }) => (
  <ul className="product-form__photos-list">{children}</ul>
));

const SortableItem = sortableElement(({ children }) => (
  <li className="product-form__photos-item">{children}</li>
));

const DragHandle = SortableHandle(({ children }) => <>{children}</>);

const ProductFormTemplate = (props) => {
  const {
    handleSave,
    handleInputChange,
    handleTextChange,
    handleToggleVisibility,
    handleDelete,
    handleOnImageUpload,
    localContent,
    productImages,
    isMobile,
    handleDeleteImage,
    handleOnDragEnd,
  } = props;

  const {
    id: productId = null,
    attributes: {
      title,
      text,
      visible,
      link,
      'product-name': productName,
      price,
      'video-url': videoUrl,
    } = {},
  } = localContent;

  const uploaderParams = {
    imageableId: productId,
    imageableType: 'product',
    imageableProperty: 'images',
  };

  const sortTrigger = {
    ...(!isMobile && { distance: 1 }),
    ...(isMobile && { pressDelay: 100 }),
  };

  return (
    <section className="container-fluid product-form">
      <div className="row">
        {isMobile ? (
          <div className="col-12">
            <h3 className="product-form__title">Editar producto</h3>
          </div>
        ) : null}
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-auto">
              {isEmpty(productImages) ? (
                <figure className="product-form__cover product-form__cover--no-image">
                  <span className="product-form__cover-icon">{INFO_ICON}</span>
                  <p className="mb-0">No has añadido ninguna imagen</p>
                </figure>
              ) : (
                <ResponsiveImg
                  className="product-form__cover"
                  srcSet={getImageSrcSet(productImages[0])}
                >
                  {visible ? null : (
                    <div className="product-form__no-visible-layer">{EYE_NOT_ICON}</div>
                  )}
                </ResponsiveImg>
              )}
            </div>
            {!isEmpty(productId) ? (
              <div className="col">
                <button
                  type="button"
                  className="btn btn-primary btn-block mt-3"
                  onClick={() => handleToggleVisibility()}
                >
                  {`${visible ? 'Ocultar' : 'Mostrar'}`}
                </button>
                <PromiseButton
                  type="button"
                  className="btn btn-danger btn-block"
                  onClick={handleDelete}
                >
              Eliminar
                </PromiseButton>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <div className="row">
            <div className="col-12 mb-3">
              <label htmlFor="tour-content-title" className="tour-content-text-form__label">
                Título:
              </label>
              <input
                id="tour-content-title"
                type="text"
                value={isEmpty(title) ? '' : title}
                onChange={handleInputChange}
                name="title"
                className="form-control"
              />
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="tour-content-text" className="product-form__label">
                Opinion personal:
              </label>
              <ReactQuill
                id="tour-content-text"
                type="text"
                value={text || ''}
                onChange={handleTextChange}
                className="product-form__rich-text-editor"
                modules={MODULES}
                formats={FORMATS}
              />
            </div>
            <div className="col-12 mb-3">
              <div className="row">
                <div className="col-12 col-lg-7 pr-lg-1">
                  <label htmlFor="product-name" className="product-form__label mb-0">
                    Nombre del producto:
                  </label>
                  <input
                    id="product-name"
                    type="text"
                    className="form-control"
                    name="product-name"
                    value={productName || ''}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-12 col-lg-5 pl-lg-1">
                  <label htmlFor="price" className="product-form__label mb-0">
                    Precio:
                  </label>
                  <input
                    id="price"
                    type="number"
                    className="form-control mt-2 mt-lg-0"
                    name="price"
                    value={price || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="link" className="product-form__label mb-0">
                Enlace para ventas:
              </label>
              <input
                id="link"
                type="text"
                value={link || ''}
                onChange={handleInputChange}
                name="link"
                className="form-control"
              />
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="video-url" className="product-form__label mb-0">
                Insertar vídeo personal (copia tu dirección de youtube o vimeo):
              </label>
              <input
                id="video-url"
                type="text"
                value={videoUrl || ''}
                onChange={handleInputChange}
                name="video-url"
                className="form-control"
              />
            </div>
            <div className="col-12">
              <h4 className="product-form__label mb-2">
                Imágenes:
                <small className="d-block">
                  Puedes arrastrar las miniaturas para cambiar su orden.
                </small>
              </h4>
              {isEmpty(productId) ? (
                <p className="text-standard text-center text-danger">
                  Es necesario guardar antes de poder subir imágenes
                </p>
              ) : null}
              <SortableContainer
                onSortEnd={handleOnDragEnd}
                axis="xy"
                lockToContainerEdges
                useDragHandle
                {...sortTrigger}
              >
                {isEmpty(productImages)
                  ? null
                  : productImages.map((image, idx) => (
                    <SortableItem key={`service-image-${image.id}`} index={idx}>
                      <DragHandle>
                        <ResponsiveImg
                          className="product-form__photos-img"
                          srcSet={getImageSrcSet(image)}
                        >
                          <PromiseButton
                            type="button"
                            className="product-form__photos-remove"
                            onClick={() => handleDeleteImage(image)}
                            title="Eliminar imagen"
                            primarySpinner
                          >
                            {CLOSE_ICON}
                          </PromiseButton>
                        </ResponsiveImg>
                      </DragHandle>
                    </SortableItem>
                  ))}
                <li className="product-form__photos-item" key="service-image-upload">
                  {isEmpty(uploaderParams.imageableId) ? (
                    <button type="button" className="product-form__photos-add" disabled>
                      {PLUS_ICON}
                    </button>
                  ) : (
                    <FineUploader
                      endpoint="/images"
                      autoUpload
                      params={uploaderParams}
                      onComplete={handleOnImageUpload || null}
                      withProgressBars
                      withTotalProgressBar
                      isDropzone={false}
                      removeThumbAfter={800}
                      onBeforeUpload={() => handleSave(true)}
                    >
                      <button type="button" className="product-form__photos-add">
                        {PLUS_ICON}
                      </button>
                    </FineUploader>
                  )}
                </li>
              </SortableContainer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ProductFormTemplate.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleOnImageUpload: PropTypes.func.isRequired,
  localContent: TYPES.productType,
  productImages: PropTypes.arrayOf(TYPES.imageType),
  isMobile: PropTypes.bool,
  handleDeleteImage: PropTypes.func.isRequired,
  handleOnDragEnd: PropTypes.func.isRequired,
};

ProductFormTemplate.defaultProps = {
  localContent: null,
  productImages: [],
  isMobile: false,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(ProductFormTemplate);
