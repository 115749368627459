import React from 'react';
import TYPES from '../../../../../constants/types';
import { isEmpty, getImageSrcSet } from '../../../../../utils';
import useGetResource from '../../../../../helpers/hooks/useGetResource';
import ResponsiveImg from '../../../../responsive-img';

function TourContentTextBox(props) {
  const { tourContent } = props;

  const images = useGetResource({ id: tourContent.id }, 'archives', 'getTourContentArchives');
  const orderedImages = isEmpty(images)
    ? []
    : images.sort((a, b) => a.attributes.order - b.attributes.order);

  return isEmpty(orderedImages) ? null : (
    <article className="container tour-content-images-box">
      {orderedImages.map(image => (
        <section
          className="row tour-content-images-box__content"
          key={`tour-content-image-${image.id}`}
        >
          <div className="col-12 px-0">
            <ResponsiveImg
              srcSet={getImageSrcSet(image)}
              wrapperClassName="tour-content-images-box__figure"
              className="tour-content-images-box__img"
              alt={image.attributes.title}
            >
              {/* <div className="tour-content-images-box__image-author">
                    <figure
                      className="tour-content-images-box__image-author-avatar"
                      style={{ backgroundImage: `url('${image.author.avatarUrl}')` }}
                    >
                      <span className="sr-only">owner name</span>
                    </figure>
                    <h4 className="tour-content-images-box__image-author-name">
                      {`
                          ${image.author.name} ${image.author.lastName}
                        `}
                    </h4>
                  </div> */}
            </ResponsiveImg>
            {
              image && image.attributes.name ? <h4 className="tour-content-images-box__title">{image.attributes.name}</h4> : null
            }
            {
              image && image.attributes.description ? <p className="tour-content-images-box__description" dangerouslySetInnerHTML={{ __html: image.attributes.description }}></p> : null
            }
            
          </div>
        </section>
      ))}
    </article>
  );
}

TourContentTextBox.propTypes = {
  tourContent: TYPES.tourContentType.isRequired,
};

export default TourContentTextBox;
