import {
  buildApi, get, post, patch, destroy,
} from 'redux-bees';
import ENV from './environment';
import authHeader from '../helpers/auth-header';

import axios from 'axios'

const apiEndpoints = {
  // **************************  ME ENDPOINTS *********************************
  getCurrentUser: { method: get, path: '/me' },
  updateCurrentUser: { method: patch, path: '/me' },
  getCurrentUserOwnedPois: { method: get, path: '/me/:id/:limit/owned-pois' },
  getCurrentUserOwnedServices: { method: get, path: '/me/:id/:limit/owned-services' },
  getCurrentUserTours: { method: get, path: '/me/:id/:limit/tours' },
  getCurrentUserProducts: { method: get, path: '/me/:id/products' },
  // ************************** POI ENDPOINTS *********************************
  getPoi: { method: get, path: '/pois/:id' },
  // getLikesPoi: { method: get, path: '/pois/likespoi/:id' },
  deletePoi: { method: destroy, path: '/pois/:id' },
  getPois: { method: get, path: '/pois' },
  createPoi: { method: post, path: '/pois' },
  updatePoi: { method: patch, path: '/pois/:id' },
  getPoiImages: { method: get, path: '/pois/:id/images' },
  getPoiTags: { method: get, path: '/pois/:id/tags' },
  upsertPoiTags: { method: post, path: '/pois/:id/relationships/tags' },
  deletePoiTags: { method: destroy, path: '/pois/:id/relationships/tags/:tagId' },
  patchPoiMainImage: { method: patch, path: '/pois/:id/relationships/main-image' },
  // ************************* TOURS ENDPOINTS *********************************
  getTour: { method: get, path: '/tours/:id' },
  likeDislike: { method: post, path: '/tours/like' },
  deleteTour: { method: destroy, path: '/tours/:id/:limit' },
  getTours: { method: get, path: '/tours' },
  createTour: { method: post, path: '/tours' },
  updateTour: { method: patch, path: '/tours/:id' },
  getTourTags: { method: get, path: '/tours/:id/tags' },
  getTourTourContents: { method: get, path: '/tours/:id/tour-contents' },
  upsertTourTags: { method: post, path: '/tours/:id/relationships/tags' },
  deleteTourTags: { method: destroy, path: '/tours/:id/relationships/tags/:tagId' },
  // ********************* TOUR-CONTENTS ENDPOINTS *****************************
  createTourContent: { method: post, path: '/tour-contents' },
  deleteTourContent: { method: destroy, path: '/tour-contents/:id' },
  getTourContent: { method: get, path: '/tour-contents/:id' },
  updateTourContent: { method: patch, path: '/tour-contents/:id' },
  getTourContentChildren: { method: get, path: '/tour-contents/:id/tour-contents' },
  createTourContentChild: { method: post, path: '/tour-contents/:id/tour-contents' },
  getTourContentArchives: { method: get, path: '/tour-contents/:id/archives/' },
  getTourContentArchivesRel: { method: get, path: '/tour-contents/:id/relationships/archives/' },
  // ************************** TAGS ENDPOINTS *********************************
  getTags: { method: get, path: '/tags' },
  createNewTag: { method: post, path: '/tags' },
  // ************************ IMAGES ENDPOINTS *********************************
  updateImage: { method: patch, path: '/images/:id' },
  getImage: { method: get, path: '/images/:id' },
  deleteImage: { method: destroy, path: '/images/:id' },
  // ************************ USERS ENDPOINTS *********************************
  getUser: { method: get, path: '/users/:id' },
  getUsers: { method: get, path: '/users' },
  getUserOwnedPois: { method: get, path: '/users/:id/:limit/owned-pois' },
  getUserOwnedServices: { method: get, path: '/users/:id/:limit/owned-services' },
  getUserTours: { method: get, path: '/users/:id/:limit/tours' },
  getUserProducts: { method: get, path: '/users/:id/products' },
  // ************************ PRODUCTS ENDPOINTS *********************************
  getProduct: { method: get, path: '/products/:id' },
  createProduct: { method: post, path: '/products' },
  updateProduct: { method: patch, path: '/products/:id' },
  deleteProduct: { method: destroy, path: '/products/:id' },
  getProductImages: { method: get, path: '/products/:id/images' },
  // ************************ SPONSOR ENDPOINTS *********************************
  getSponsor: { method: get, path: '/sponsors/:id' },
  createSponsor: { method: post, path: '/sponsors' },
  updateSponsor: { method: patch, path: '/sponsors/:id' },
  deleteSponsor: { method: destroy, path: '/sponsors/:id' },
  getSponsorImages: { method: get, path: '/sponsors/:id/images' },
};

// const headers = {
//   "content-length": 54138,
// }

const config = {
  baseUrl: `${ENV.host}/${ENV.namespace}`,
  configureHeaders(headers) {
    return {
      ...headers,
      Authorization: authHeader(),
    };
  },
};
// const configInterceptor = {
//   configureHeaders(headers) {
//     return {
//       ...headers,
//       Authorization: authHeader(),
//     };
//   },
// };

// const instance = axios.create({
//   baseURL: 'https://commento.io/'
// });

// Alter defaults after instance has been created
// instance.defaults.headers.common['Authorization'] = 'AUTH_TOKEN';



axios.interceptors.request.use((config) => {
  const token = authHeader();
  config.headers.Authorization = token;

  return config;
});

const api = buildApi(apiEndpoints, config);

export default api;
