import React from 'react';
import PropTypes from 'prop-types';
import {
  ImagesEdition,
  PromiseButton,
} from '../../../../../../components';
import TYPES from '../../../../../../constants/types';
import { isEmptyObject } from '../../../../../../utils';

const TourContentImagesFormTemplate = (props) => {
  const {
    localTourContent,
    tourContentImages,
    uploaderParams,
    onImageUpload,
    handleOnDeleteImage,
    handleToggleVisibility,
    handleDelete,
    onImageInEditionChange,
    hideExtraBtns,
  } = props;
  const {
    attributes: { visible: isVisible } = {},
    relationships: {
      'tour-content': { data: parentTourContent },
    },
  } = localTourContent;

  return (
    <>
      <ImagesEdition
        localTourContent={localTourContent}
        tourContentImages={tourContentImages}
        uploaderParams={uploaderParams}
        onImageUploaded={onImageUpload}
        onDeleteImage={handleOnDeleteImage}
        onImageInEditionChange={onImageInEditionChange}
      />
      {!hideExtraBtns
        ? (
          <div className="container-fluid">
            <div className="row justify-content-end">
              <div className="col-12 col-md-auto pr-md-1">
                <button
                  type="button"
                  onClick={() => handleToggleVisibility()}
                  className="btn btn-primary d-block w-100 mt-3"
                >
                  {`${isVisible ? 'ocultar' : 'mostrar'} en ${
                    isEmptyObject(parentTourContent) ? 'la ruta' : 'el destino'
                  }`}
                </button>
              </div>
              <div className="col-12 col-md-auto px-md-1">
                <PromiseButton
                  type="button"
                  onClick={() => handleDelete()}
                  className="btn btn-danger d-block w-100 mt-3"
                >
                  {`eliminar ${isEmptyObject(parentTourContent) ? 'de la ruta' : 'del destino'}`}
                </PromiseButton>
              </div>
            </div>
          </div>
        )
        : null}
    </>
  );
};

TourContentImagesFormTemplate.propTypes = {
  localTourContent: TYPES.tourContentType,
  tourContentImages: PropTypes.arrayOf(TYPES.imageType),
  onImageUpload: PropTypes.func.isRequired,
  handleOnDeleteImage: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  uploaderParams: PropTypes.shape({
    imageableId: PropTypes.string,
    imageableType: PropTypes.string.isRequired,
    imageableProperty: PropTypes.string.isRequired,
  }).isRequired,
  onImageInEditionChange: PropTypes.func.isRequired,
  hideExtraBtns: PropTypes.bool,
};
TourContentImagesFormTemplate.defaultProps = {
  localTourContent: null,
  tourContentImages: [],
  hideExtraBtns: false,
};

export default TourContentImagesFormTemplate;
