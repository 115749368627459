import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import qs from 'query-string';
import _isEqual from 'lodash.isequal';
import {
  STAR_ICON,
  MAGNIFYING_ICON,
  FILTERS_ICON,
  INFO_CIRCLE_ICON,
} from '../../helpers/svg-inline';
import FiltersModal from './parts/filters-modal';
import AutocompleteTag from '../autocomplete-tag/autocomplete-tag';
import api from '../../config/api-endpoints';
import handleError from '../../utils/errorHandler';
import { isEmpty } from '../../utils';
import ContentCard from '../content-card';
import { CURRENT_URL_UPSERT_QUERY_PARAMS } from '../../constants';
import LoadingSpinner from '../loading-spinner';

function jsonApiTagsToSuggestion(jsonApiTagsArray) {
  return jsonApiTagsArray.map(jsonApiTag => ({
    displayedText: jsonApiTag.attributes.name,
    ...jsonApiTag,
  }));
}

function onKeyUp(e) {
  e.preventDefault();
}

class TourSearch extends Component {
  static didServerFiltersQueryParamsChange(prevQueryString, currQueryString) {
    const prevQueryParam = qs.parse(prevQueryString);
    const currQueryParam = qs.parse(currQueryString);
    const paramKeyToCompare = ['tTypes', 'tTags'];
    return paramKeyToCompare.some(
      currKey => !_isEqual(currQueryParam[currKey], prevQueryParam[currKey]),
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpenFiltersModal: false,
      suggestionsTagsArray: [],
      selectedTags: [],
      selectedTourTypes: {},
      tourResults: [],
      showTagsInfo: false,
      isLoading: true,
    };

    this.handleFiltersModal = this.handleFiltersModal.bind(this);
    this.handleSaveFilters = this.handleSaveFilters.bind(this);
    this.onSelectTag = this.onSelectTag.bind(this);
    this.onDeleteTag = this.onDeleteTag.bind(this);
    this.getTags = this.getTags.bind(this);
    this.handleTourSearch = this.handleTourSearch.bind(this);
    this.triggerQueryParamChange = this.triggerQueryParamChange.bind(this);
    this.syncStateDiffBetweenQueryParams = this.syncStateDiffBetweenQueryParams.bind(this);
    this.updateStateServerFilters = this.updateStateServerFilters.bind(this);
    this.toggleInfoVisibility = this.toggleInfoVisibility.bind(this);
  }

  componentDidMount() {
    const { syncStateDiffBetweenQueryParams } = this;
    const { storedTags } = this.props;
    const getTagsPromise = isEmpty(storedTags) ? this.getTags() : Promise.resolve(storedTags);
    this.setState(
      {
        isLoading: true,
      },
      () => {
        getTagsPromise
          .then((response) => {
            let suggestionsTagsArray = [];
            if (!isEmpty(response)) {
              const tags = (response.body && response.body.data) || Object.values(response);
              const formattedSuggestions = jsonApiTagsToSuggestion(tags);
              suggestionsTagsArray = formattedSuggestions;
            }
            return new Promise((resolve) => {
              this.setState(
                {
                  suggestionsTagsArray,
                },
                resolve,
              );
            });
          })
          .then(syncStateDiffBetweenQueryParams(null))
          .catch((error) => {
            handleError(error);
          })
        // .finally(() => {
        //   this.setState({
        //     isLoading: false,
        //   });
        // });
      },
    );
  }

  componentDidUpdate(prevProps) {
    const { syncStateDiffBetweenQueryParams } = this;
    const { queryParamsString: prevQueyParamsString } = prevProps;
    syncStateDiffBetweenQueryParams(prevQueyParamsString);
  }


  activateLoading(){
    this.setState({
      isLoading: true,
      tourResults: []
    })
  }

  onSelectTag(e, { suggestion: selectedTag }) {
    const { triggerQueryParamChange } = this;
    const { selectedTags } = this.state;
    const newSelectedTags = [...selectedTags];
    newSelectedTags.push(selectedTag);
    this.activateLoading();
    triggerQueryParamChange({ selectedTags: newSelectedTags });
  }

  onDeleteTag(tag, index) {
    const { triggerQueryParamChange } = this;
    const { selectedTags } = this.state;
    const newSelectedTags = [...selectedTags];
    newSelectedTags.splice(index, 1);
    this.activateLoading();

    triggerQueryParamChange({ selectedTags: newSelectedTags });
  }

  getTags() {
    const { dispatch } = this.props;
    return dispatch(api.getTags()).catch(() => {
      handleError('No pudo encontrar palabras clave...');
    });
  }

  syncStateDiffBetweenQueryParams(prevQueyParamsString = '') {
    const { updateStateServerFilters, handleTourSearch } = this;
    const { queryParamsString: currQueryParamsString } = this.props;
    const isChangedFilters = prevQueyParamsString !== currQueryParamsString;
    if (isChangedFilters) {
      const serverFiltersChanged = TourSearch.didServerFiltersQueryParamsChange(
        prevQueyParamsString,
        currQueryParamsString,
      );
      const promiseChain = Promise.resolve();
      if (serverFiltersChanged || isEmpty(prevQueyParamsString)) {
        promiseChain.then(updateStateServerFilters).then(handleTourSearch);
      }
    }
  }

  triggerQueryParamChange(newState) {
    const { dispatch } = this.props;
    const { selectedTags, selectedTourTypes } = this.state;
    const {
      selectedTags: receivedSelectedTags,
      selectedTourTypes: receivedSelectedTourTypes,
    } = newState;

    const newSelectedTags = receivedSelectedTags || selectedTags;
    const newSelectedTourTypes = receivedSelectedTourTypes || selectedTourTypes;

    const newQueryParams = {};

    const tourTypesArray = (newSelectedTourTypes
      && Object.keys(newSelectedTourTypes).reduce((acc, key) => {
        const newAcc = [...acc];
        newAcc.push(key);
        return newAcc;
      }, []))
      || null;
    newQueryParams.tTypes = tourTypesArray;

    newQueryParams.tTags = newSelectedTags.map(tag => tag.id);

    dispatch(push(CURRENT_URL_UPSERT_QUERY_PARAMS(newQueryParams)));
    return Promise.resolve();
  }

  updateStateServerFilters() {
    this.activateLoading();
    const { queryParamsString } = this.props;
    const newFilters = qs.parse(queryParamsString);
    const { tTags: tourTags, tTypes: tourTypes } = newFilters;
    // set selectedTourTypes back to empty object & selectedTags to empty array to makes sure that
    // react setState set an empty object/array instead of "null"
    // when no tour type filter is applied, this allows us to set "no type/tag selected" filter
    // based on the url.
    const newState = { selectedTourTypes: {}, selectedTags: [] };

    if (tourTypes) {
      // qs parse returns a string if only one element is present
      // ensure that tourTypes is an array even when only 1 category is selected!
      let selectedTourTypesArray = tourTypes;
      if (typeof tourTypes === 'string') {
        selectedTourTypesArray = [parseInt(tourTypes, 10)];
      }
      // converts the array into the state expected object {typeIdentifier:bool->true}
      selectedTourTypesArray.forEach((typeIdentifier) => {
        newState.selectedTourTypes[typeIdentifier] = true;
      });
    }
    if (tourTags) {
      const { suggestionsTagsArray } = this.state;
      // qs parse returns a string if only one element is present
      // ensure that tourTags is an array even when only 1 category is selected!
      let selectedTourTagsArray = tourTags;
      if (typeof tourTags === 'string') {
        selectedTourTagsArray = [parseInt(tourTags, 10)];
      }
      // converts the array of ids in an array of tags, consulting "suggestionsTagsArray"
      newState.selectedTags = selectedTourTagsArray.map((currId) => {
        // findIndex is faster than find, first retrieve the index, then access the object
        const tagIndex = Object.keys(suggestionsTagsArray).findIndex(
          currTagKey => parseInt(suggestionsTagsArray[currTagKey].id, 10) === parseInt(currId, 10),
        );
        return suggestionsTagsArray[tagIndex];
      });
    }

    return new Promise((resolve) => {
      this.setState(newState, resolve);
    });
  }

  handleSaveFilters(newTypeFilters) {
    const { triggerQueryParamChange } = this;
    triggerQueryParamChange({ selectedTourTypes: newTypeFilters });
  }

  handleFiltersModal(value) {
    this.setState({
      isOpenFiltersModal: value,
    });
  }

  handleTourSearch() {
    const { dispatch } = this.props;
    const { selectedTags, selectedTourTypes } = this.state;
    const searchRequestFilters = {
      include: 'desktop-cover',
      orderBy: 'staffPick',
    };
    const tagFilters = selectedTags.map(tag => tag.id);
    const tourTypeFilters = Object.keys(selectedTourTypes);
    if (!isEmpty(tagFilters)) {
      searchRequestFilters['filters[tagId]'] = tagFilters;
    }
    if (!isEmpty(tourTypeFilters)) {
      searchRequestFilters['filters[tourType]'] = tourTypeFilters;
    }

    dispatch(api.getTours(searchRequestFilters)).then((response) => {
      if (response.status === 200) {
        this.setState({ tourResults: response.body.data, isLoading: false });
      }
    });
  }

  toggleInfoVisibility() {
    const { showTagsInfo } = this.state;
    this.setState({ showTagsInfo: !showTagsInfo });
  }

  render() {
    const {
      handleFiltersModal,
      handleSaveFilters,
      onSelectTag,
      onDeleteTag,
      toggleInfoVisibility,
    } = this;
    const {
      isOpenFiltersModal,
      suggestionsTagsArray,
      selectedTags,
      selectedTourTypes,
      tourResults,
      showTagsInfo,
      isLoading,
    } = this.state;
    const { isMobile } = this.props;
    return (
      <>
        <section className="container-fluid tour-search">
          <div className="row align-items-top">
            {!isMobile ? (
              <div className="col-12 col-lg-auto pl-lg-5">
                <h3 className="tour-search__title">
                  Las mejores rutas
                  <small className="tour-search__title-legend">
                    <img
                      className="mr-2"
                      width="32"
                      alt="like button"
                      src='../assets/images/iconStar.svg'
                    />
                    Staff Pick
                  </small>
                  <small className="tour-search__title-legend">
                    <img
                      className="mr-2"
                      width="30"
                      alt="like button"
                      src='../assets/images/iconSelection.svg'
                    />
                    topdesti selection
                  </small>
                </h3>
              </div>
            ) : null}
            <div className="col col-lg-5 col-xl-4 pr-lg-0">
              <label htmlFor="search-inp" className="tour-search__search">
                <AutocompleteTag
                  uniqueIdentificator="discover-tours-tag-autocomplete"
                  placeholder="BUSCAR TU RUTA"
                  suggestionsArray={suggestionsTagsArray}
                  suggestionSource="props"
                  onValueSelected={onSelectTag}
                  onDeleteTag={onDeleteTag}
                  onKeyUp={onKeyUp}
                  canDelete
                  prevSuggestions={selectedTags}
                  showInput
                />
                <span className="tour-search__search-icon">{MAGNIFYING_ICON}</span>
              </label>
            </div>
            <div className="col-auto px-lg-0">
              <button
                type="button"
                className="tour-search__toggle"
                title="Filtrar búsqueda"
                onClick={() => handleFiltersModal(true)}
              >
                {FILTERS_ICON}
              </button>
            </div>
            <div className="col-auto ml-auto pr-lg-5">
              <button
                type="button"
                className={`tour-search__toggle ${showTagsInfo ? 'tour-search__toggle--blue' : ''}`}
                title={showTagsInfo ? 'Ocultar guía palabras clave' : 'Mostrar guía palabras clave'}
                onClick={() => toggleInfoVisibility()}
              >
                {INFO_CIRCLE_ICON}
              </button>
            </div>
          </div>
          <div className="row">
            <div
              className={`col-12 col-lg-3 order-lg-2 tour-search__guide ${
                showTagsInfo ? '' : 'tour-search__guide--hidden'
                }`}
            >
              <h3 className="tour-search__guide-title">Búsqueda por palabras clave:</h3>
              <p className="tour-search__guide-example">
                #3dGranCanaria
                <span>Rutas de 3 días en Gran Canaria</span>
              </p>
              <p className="tour-search__guide-example">
                #TopPlayasGranCanaria
                <span>Las mejores playas de Gran Canaria</span>
              </p>
              <p className="tour-search__guide-example">
                #naturaleza #GranCanaria
                <span>Rutas con temática de naturaleza en Gran Canaria</span>
              </p>
            </div>
            <div className="col-12 col-lg order-lg-1 tour-search__results">
              <div className="row justify-content-center">
                {isLoading && (
                  <>
                    <div className="mt-5">
                      <LoadingSpinner onlySpinner />
                    </div>
                    <p className="w-100 text-center mt-3">Cargando...</p>
                  </>
                )}
                <>
                  {
                    tourResults.length <= 0 && !isLoading ? (
                      <div className="col-12">
                        <h4 className="text-center mt-5">No se han encontrado resultados</h4>
                      </div>
                    ) : (
                        tourResults.map(tour => (
                          <ContentCard key={tour.id} contentModel={tour} withAuthor={false} />
                        ))
                      )
                  }
                </>
              </div>
            </div>
          </div>
        </section>
        {isOpenFiltersModal ? (
          <FiltersModal
            isOpen={isOpenFiltersModal}
            closeModal={() => handleFiltersModal(false)}
            handleSaveFilters={handleSaveFilters}
            currentTypeFilters={selectedTourTypes}
          />
        ) : null}
      </>
    );
  }
}

TourSearch.propTypes = {
  dispatch: PropTypes.func.isRequired,
  queryParamsString: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  storedTags: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ),
};

TourSearch.defaultProps = {
  storedTags: null,
  isMobile: false,
};

function mapStateToProps(state) {
  const storedTags = state.bees.entities.tags;
  return {
    storedTags,
    queryParamsString: state.router.location.search,
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(TourSearch);
