import { isEmpty, handleError } from '../../utils';
import { defaultBounds } from '../../constants';
import { getClientLocation } from '../../helpers';

export function isValidBounds(bounds) {
  if (isEmpty(bounds)) return false;
  const {
    nel, nelng, swl, swlng,
  } = bounds;
  return !isEmpty(nel) && !isEmpty(nelng) && !isEmpty(swl) && !isEmpty(swlng);
}

export function getUrlBounds(urlQueryParams) {
  return {
    nel: urlQueryParams.get('nel'),
    nelng: urlQueryParams.get('nelng'),
    swl: urlQueryParams.get('swl'),
    swlng: urlQueryParams.get('swlng'),
  };
}

export function setUrlBounds(urlQueryParams, bounds) {
  Object.keys(bounds).forEach((coordName) => {
    urlQueryParams.set(`${coordName}`, bounds[coordName]);
  });
  return urlQueryParams;
}

export function getMapBounds(mapInstance) {
  const bounds = isEmpty(mapInstance) ? null : mapInstance.getBounds();
  const ne = isEmpty(bounds) ? null : bounds.getNorthEast();
  const sw = isEmpty(bounds) ? null : bounds.getSouthWest();

  return {
    nel: isEmpty(ne) ? null : ne.lat(),
    nelng: isEmpty(ne) ? null : ne.lng(),
    swl: isEmpty(sw) ? null : sw.lat(),
    swlng: isEmpty(sw) ? null : sw.lng(),
  };
}

export function isEqualBounds(bounds1, bounds2) {
  let notEqual = false;
  Object.keys(bounds1).forEach((key) => {
    if (
      parseFloat(bounds1[key]) !== parseFloat(bounds2[key])
      && !(isEmpty(bounds1[key]) && isEmpty(bounds2[key]))
    ) notEqual = true;
  });
  return !notEqual;
}

export function shouldUpdateMapBoundsFromUrl(prevUrlQueryParams, urlQueryParams, currentMapBounds) {
  const isAddressSearch = urlQueryParams.has('adr');
  const currentUrlBounds = getUrlBounds(urlQueryParams);
  const prevUrlBounds = getUrlBounds(prevUrlQueryParams);
  const isNewUrlBounds = !isEqualBounds(currentUrlBounds, prevUrlBounds);
  const isMapUpdated = isEqualBounds(currentMapBounds, currentUrlBounds);

  if (isAddressSearch || !isValidBounds(currentUrlBounds)) return false;

  return (
    (isNewUrlBounds && !isMapUpdated) || (!isValidBounds(currentMapBounds) && isValidBounds(currentUrlBounds))
  );
}

export function needDefaultPosition(currentUrlBounds, currentMapBounds) {
  return !isValidBounds(currentUrlBounds) && !isValidBounds(currentMapBounds);
}

export function buildSearchQueryParams(urlQueryParams, mapBounds, limit) {
  const categoryFilters = [];
  const searchQueryParams = {
    include: 'images',
    orderBy: 'toursCounter',
    limit,
  };

  if (!isEmpty(mapBounds) && isValidBounds(mapBounds)) {
    setUrlBounds(urlQueryParams, mapBounds);
  } else {
    const urlBounds = getUrlBounds(urlQueryParams);
    if (
      !isValidBounds(urlBounds)
      && !urlQueryParams.has('adr')
      && (!urlQueryParams.has('lat') || !urlQueryParams.has('lng'))
    ) {
      setUrlBounds(urlQueryParams, defaultBounds);
    }
  }
  [...urlQueryParams.entries()].forEach(([key, value]) => {
    if (key === 'searchType' && value < 3) {
      searchQueryParams['filters[poiType]'] = value;
    } else if (key === 'cat') {
      categoryFilters.push(value);
    } else {
      searchQueryParams[`filters[${key}]`] = value;
    }
  });
  if (!isEmpty(categoryFilters)) {
    searchQueryParams['filters[category]'] = categoryFilters;
  }

  return searchQueryParams;
}

export function checkFilterQueryParamChanges(currentQP, prevQP) {
  const omittedQueryParams = ['nel', 'nelng', 'swl', 'swlng', 'cat', 'select'];
  let hasChanges = false;
  const currentCategoriesString = currentQP
    .getAll('cat')
    .sort()
    .toString();
  const prevCategoriesString = prevQP
    .getAll('cat')
    .sort()
    .toString();
  const isChangedCategoryFilters = currentCategoriesString !== prevCategoriesString;

  [...currentQP.entries()].forEach(([key, value]) => {
    if (!omittedQueryParams.includes(`${key}`) && value !== prevQP.get(`${key}`)) {
      hasChanges = true;
    }
  });

  return hasChanges || isChangedCategoryFilters;
}

export function getUserPosition() {
  if (navigator.geolocation) {
    const getUserLocation = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    return getUserLocation
      .then((position) => {
        const { latitude: lat, longitude: lng } = position.coords;
        return { lat, lng };
      })
      .catch((err) => {
        if (err.message && err.message === 'User denied Geolocation') {
          handleError('Permisos denegados por la geolocalización');
        } else {
          handleError(err);
        }
      });
  }
  // Browser doesn't support Geolocation
  handleError('El browser no soporta geolocalización!');
  // use google geolocalization
  return getClientLocation().then(location => location.location);
}

export function isEqualMarkers(markers1, markers2) {
  const markers1String = markers1
    .reduce((acc, item) => {
      acc.push(item.poiId);
      return acc;
    }, [])
    .sort()
    .toString();
  const markers2String = markers2
    .reduce((acc, item) => {
      acc.push(item.poiId);
      return acc;
    }, [])
    .sort()
    .toString();
  return markers1String === markers2String;
}
