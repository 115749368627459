import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import {
  CIRCLE_CROSS_ICON, EYE_ICON,
  HEART_ICON,
  TOUR_SIGNAL_ICON,
  STAR_ICON,
  SIMPLE_FLAG_ICON,
  CHECK_ICON,
  POINTS_CROSS_ICON,
  PENCIL_ICON,
} from '../helpers/svg-inline';
import { toastr } from 'react-redux-toastr';
import * as ROUTES from '../constants/routes.constants';
import { lockBodyScrolling, restoreBodyScrolling, isEmpty } from '../utils';
import TYPES from '../constants/types';
import reduxBeeMakeRequest from '../utils/redux-bee/redux-bee-make-request';
import { dispatch } from '../reduxStore';
import { UPDATE_CURRENT_USER, defaultPartner, USER_DINAMIC_URL, POI_EDITION_INFO_DINAMIC_URL } from '../constants';
import DisabledPlaceEdition from './disabled-place-edition';
import { Carousel } from 'react-responsive-carousel';
import { getImageSrcSet } from '../utils';
import ResponsiveImg from './responsive-img';
import ModalOnlyUsers from './modal-log-out';
import ENV from '../config/environment';
import SimpleMap from './simple-map';
import { GOOGLE_MARKER } from '../utils/google-maps-utils';
import api from '../config/api-endpoints';
import {
  FineUploader,
} from '../components';
import {
  UPLOAD_CLOUD_URL,
  DROP_COLOR_URL,
  history
} from '../helpers';
import createHistory from 'history/createBrowserHistory'
import axios from 'axios';

/* function orderImages(images, firstImageId) {
  const orderedImages = images.reduce((acc, image) => {
    if (image.id === firstImageId) {
      acc.splice(0, 0, image);
    } else {
      acc.push(image);
    }
    
    return acc;
  }, []);
  return orderedImages;
} */
const location = {
  pathname: "/discover"
}
class ModalPreviewContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLogOut: false,
      showModal: false,
      poi: {},
      images: {},
      carouselPosition: 0,
      likes: [],
      imageP: [],
      savePreview: false,
      approvedPoi: false,
      showMessagePreviewPoi: false,
      rejectedPreviewPoi: false,
      poiCampaign: false,
    }
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleChangeCarousel = this.handleChangeCarousel.bind(this);
    this._mapId = `map-box-${Math.floor(Math.random() * (10000 - 1)) + 1}`;
    this.initializeMapBounds = this.initializeMapBounds.bind(this);
    this.handleMapMount = this.handleMapMount.bind(this);
  }
  componentWillReceiveProps(newProps) {
    if (newProps) {
      this.setState({ showModal: newProps.modalPreviewContent })
      this.setState({ poi: newProps.poi.attributes })
      if (newProps.poi.relationships != undefined && newProps.poi.relationships.images != undefined) {
        this.setState({ images: newProps.poi.relationships.images.data })
      } else {
        this.setState({ images: null })
      }
    }
  }
  componentDidMount() {
    if ((this.props.auth.currentUser && this.props.poi.attributes["type-preview"] != 0 && this.props.auth.currentUser.id == this.props.poi.attributes["provisional-author"])
      ||
      (this.props.auth.currentUser && this.props.poi.attributes["type-preview"] != 0 && this.props.auth.currentUser.id == 14)
      ||
      (this.props.auth.currentUser && this.props.poi.attributes["type-preview"] != 0 && this.props.auth.currentUser.id == 40)
      ||
      (this.props.auth.currentUser && this.props.poi.attributes["type-preview"] != 0 && this.props.auth.currentUser.id == 22)
      ) {
      this.showPreviewImages();
    }
    if (this.props.poi && this.props.needGetLikes !== false) {
      this._getLikes()
    } else {
      this.setState({
        likes: this.props.likes,
        poiId: this.props.poiId,
        views: this.props.views,
        poiCampaign: this.props.poiCampaign,
      });
    }

  }
  /*   componentDidUpdate() {
      if (this.props.poi) {
        this._getLikes()
      }
    } */
  _getLikes() {
    if (!this.state.poiId) {
      fetch(`${ENV.host}/${ENV.namespace}/pois/likespoi/${this.props.poi.id}/${false}`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.setState({
            likes: data.likes,
            poiId: this.props.poi.id,
            views: data.views,
            poiCampaign: data.campaign,
          });
          this._liked()
        });
    }
  }
  handleLike = () => {
    this.setState({ liked: !this.state.liked });
    axios.post(`${ENV.host}/${ENV.namespace}/pois/likepoi`, {
      token: this.props.auth.token,
      poiId: this.props.poi.id
    })
      .then((res) => {
        this.setState({ likes: res.data.likes });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  _liked = () => {
    if (this.props.auth.isAuthenticated && this.props.auth.currentUser) {
      let liked = Object.values(this.state.likes).find(
        like =>
          like.userId === Number(this.props.auth.currentUser.id)
      );
      this.setState({ liked: liked });
    } else {
      this.setState({ liked: false });
    }
  };

  modalLogOutOpen = () => {
    this.setState({ showModalLogOut: true })
  }
  handlemodalLogOutClose = () => {
    this.setState({ showModalLogOut: false })
  }

  handleChangeCarousel(index) {
    this.setState({ carouselPosition: index });
  }

  handleCloseModal() {
    if (this.state.approvedPoi) {
      this.redirect()
    } else {
      this.props.modalPreviewContentClose();
    }
  }
  handleMapMount(map) {
    this._mapInstance = map;
  }
  initializeMapBounds() {
    const originLabel = "";
    const showItinerary = "";
    const { _mapInstance } = this;
    const {
      poi, zoom,
    } = this.props;
    const maxZoom = 10;
    if (!isEmpty(_mapInstance)) {
      const { _mapMarkers: mapMarkers } = this;
      const bounds = new window.google.maps.LatLngBounds();
      const { lat, long: lng } = poi.attributes;
      const label = "";
      const mapMarker = new window.google.maps.Marker({
        position: { lat, lng },
        map: _mapInstance,
        icon: GOOGLE_MARKER(label),
        clickable: false,
      });
      bounds.extend(mapMarker.getPosition());
      this._mapMarkers = mapMarkers;
      _mapInstance.fitBounds(bounds, 5);
      if (!isEmpty(zoom)) {
        _mapInstance.setZoom(zoom);
      }
      if (!isEmpty(maxZoom) && _mapInstance.getZoom() > maxZoom) {
        _mapInstance.setZoom(maxZoom);
      }
    }
  }
  showPreviewImages = () => {
    fetch(`${ENV.host}/${ENV.namespace}/images/imagepreviewpoi/${this.props.poi.id}/${this.props.auth.currentUser.id}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({ imageP: data.images });
      });
  }
  revisionPreviewPoi = () => {
    fetch(`${ENV.host}/${ENV.namespace}/pois/revisionpreviewpoi`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        poiId: this.props.poi.id,
        userId: this.props.auth.currentUser.id,
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({ savePreview: true });
      });
  }

  approvedPreviewPoi = () => {
    fetch(`${ENV.host}/${ENV.namespace}/pois/approvedPreviewPoi`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        poiId: this.props.poi.id,
        userId: this.props.auth.currentUser.id,
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        toastr.success('Destino aprobado');
        this.setState({ approvedPoi: true })
      });
  }
  deleteImage = (id) => {
    fetch(`${ENV.host}/${ENV.namespace}/images/removeimagepreviewpoi/${id}/${this.props.poi.id}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        let array = [...this.state.imageP]
        let index = array.findIndex(image => image.id == id)
        if (index > -1) {
          array.splice(index, 1)
          this.setState({
            imageP: array
          })
        }

      });
  }

  redirect = () => {
    const route = window.location.pathname.search('/tour/')
    if (route == 0) {
      history.go(window.location.pathname)
    } else {
      const search = window.location.search;
      history.push({
        search: ''
      });
      setTimeout(() => {
        history.push({
          search: search
        });
      }, 0)
    }
  }

  approvedPreviewPoiAdmin = () => {
    fetch(`${ENV.host}/${ENV.namespace}/pois/approvedPreviewPoiAdmin/${this.props.poi.id}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        toastr.success('Destino aprobado');
        this.setState({ showMessagePreviewPoi: true })
      });
  }
  rejectedPreviewPoiAdmin = () => {
    fetch(`${ENV.host}/${ENV.namespace}/pois/rejectedPreviewPoiAdmin/${this.props.poi.id}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        toastr.error('Destino rechazado');
        this.setState({ showMessagePreviewPoi: true, rejectedPreviewPoiAdmin: true, imageP: [] })
      });
  }
  messagesPreviewPoi = () => {
    if (this.state.rejectedPreviewPoiAdmin) {
      return (
        <div className="d-flex justify-content-center align-items-center w-100">
          <img width="30"
            src='../assets/images/multiply.svg'
            alt="rejected"
          />
          <h5 className="mb-0 ml-2">Este destino fue rechazado correctamente.</h5>
        </div>
      )
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center w-100">
          <img width="30"
            src='../assets/images/check.svg'
            alt="check"
          />
          <h5 className="mb-0 ml-2">Este destino fue aprobado correctamente.</h5>
        </div>
      )
    }
  }

  render() {
    const notCachedResources = [];
    const { carouselPosition } = this.state;
    const orderedImages = this.props.imageArray ? Object.values(this.props.imageArray).filter(image => {
      if (image.imageableType == "poi" && image.imageableId == this.props.poi.id) {
        return image;
      }
    }) : []
    orderedImages.sort((a, b) => {
      if (a.likes > b.likes) {
        return -1;
      }
      if (a.likes < b.likes) {
        return 1;
      }
      // a must be equal to b
      return 0;
    })
    /*
   const { id: mainImageId = null } = this.state.poi ? this.state.images || {} : {};

   const orderedImages = notCachedResources.length !== 0
     ? this.state.images
     : orderImages(this.state.images, mainImageId); */
    /* if (this.state.images != undefined) {
      var orderedImages = this.state.images;
    } */
    const uploaderParams = {
      imageableId: this.props.poi.id,
      imageableType: 'poi',
      imageableProperty: 'imageP',
    };
    let disabledSave = this.state.imageP.length >= 5;
    let images = [];
    const editUrl = POI_EDITION_INFO_DINAMIC_URL(this.props.poi.id, true);

    return (
      <div>
        <Modal
          className="modal-preview-content"
          overlayClassName="modal-preview-content__overlay"
          isOpen={this.state.showModal}
          contentLabel="modal log out"
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.handleCloseModal}
        >
          <div className="contentModal d-flex justify-content-center">
            <div className="nav d-flex justify-content-end">
              {
                !this.state.approvedPoi && (
                  <>
                    {this.props.auth.currentUser && (this.props.auth.currentUser.id == 40 || this.props.auth.currentUser.id == 14 || this.props.auth.currentUser.id == 22) ? (
                      <Link to={editUrl} title="Editar">
                        <button className="checkIcon">
                          {PENCIL_ICON}
                        </button>
                      </Link>
                    ) : null}

                    {(this.props.poi.attributes["type-preview"] == 1 && this.props.auth.currentUser && this.state.savePreview == false) || this.state.approvedPoi ? (
                      <button
                        type="button"
                        className={this.state.imageP.length >= 5 ? "checkIcon" : "checkIcon opacity"}
                        onClick={() => this.props.auth.currentUser && this.props.auth.currentUser.attributes.toptraveler ? this.approvedPreviewPoi() : this.revisionPreviewPoi()}
                        disabled={!disabledSave}>
                        {CHECK_ICON}
                      </button>
                    ) : null}
                    <button onClick={this.handleCloseModal} className="closeIcon p-0">
                      {CIRCLE_CROSS_ICON}
                    </button>
                  </>
                )
              }
            </div>
            {
              this.state.approvedPoi ? (
                <div className='d-flex justify-content-center align-items-center flex-wrap mt-5'>
                  <h1 className='text-center mb-3'>Enhorabuena</h1>
                  <h5 className='text-center mb-5 col-10 enhorabuena_text'>has ganado la bandera de este destino y por lo tanto el 20% de su patrocinio mientras mantegas tu condición de Toptraveler, Premium o entidad social / medioambiental.</h5>
                  <img width='80%' src="../assets/images/enhorabuena_bandera.svg" alt="" />
                  <h5 className='text-center mt-5 col-10 enhorabuena_text'>Recuerda que todavía puedes gana un 30% más si tu contenido es el más valorado al finalizar el año de patrocinio. ¡No pierdas la oportunidad y sigue creando contenido ahora que esta todavía libre!</h5>
                  <button type="button" className="btn btn-primary  mt-4" onClick={() => this.redirect()}>Aceptar</button>
                </div>
              ) : (
                  <div className="contentCenter d-flex justify-content-center">
                    <div className={
                      this.state.poi.township
                        ? 'row m-0 tour-content-poi-box__topleft'
                        : 'row m-0 tour-content-poi-box__topleft align-items-center'
                    }>
                      {/* {true && !isEmpty(orderPosition) ? (
                    // aqui mostramos el día en el que está el tour content de tipo POI
                    <span className="tour-content-poi-box__order">{orderPosition}</span>
                  ) : null} */}
                      {this.props.auth.isAuthenticated ? (
                        <button onClick={this.handleLike} className="buttonLike">
                          <img
                            alt="like button"
                            src={
                              this.state.liked
                                ? '../assets/images/heart.svg'
                                : '../assets/images/heart-outline.svg'
                            }
                          />
                        </button>
                      ) : (
                          <button onClick={this.modalLogOutOpen} className="buttonLike">
                            <img
                              alt="like button"
                              src={
                                this.state.liked
                                  ? '../assets/images/heart.svg'
                                  : '../assets/images/heart-outline.svg'
                              }
                            />
                          </button>
                        )}
                      <ModalOnlyUsers
                        modalLogOut={this.state.showModalLogOut}
                        modalLogOutClose={this.handlemodalLogOutClose}
                      />
                      <div className="col">
                        {this.state.poi ? (
                          <>
                            <h3 className="tour-content-poi-box__title" title={this.state.poi.title}>
                              {this.state.poi.title}
                            </h3>
                            <h4 className="tour-content-poi-box__address">{this.state.poi.township}</h4>
                          </>
                        ) : null}
                      </div>
                    </div>
                    {/* carrusel de imagenes */}
                    <div>
                      {orderedImages.length != 0 && this.props.poi.attributes["type-preview"] == 0 ? (
                        <>
                          <Carousel
                            showThumbs={false}
                            showStatus={false}
                            emulateTouch
                            onChange={this.handleChangeCarousel}
                            selectedItem={carouselPosition}
                          >
                            {orderedImages.map(image => {
                              const srcSetArray = getImageSrcSet(image);
                              return (
                                <div key={`carousel-img-${image.id}`}>
                                  <ResponsiveImg className="tour-content-poi-box__image" srcSet={srcSetArray}>
                                  </ResponsiveImg>
                                </div>
                              );
                            })}
                          </Carousel>
                          <div className="col-auto mt-3 mt-sm-0">
                            <ul className="modal-preview-content__info">
                              <li className="modal-preview-content__info-item">
                                {HEART_ICON}
                                {this.state.likes && this.state.likes.length}
                              </li>
                              <li className="modal-preview-content__info-item">
                                {EYE_ICON}
                                {this.state.views ? this.state.views : 0}
                              </li>
                              <li className="modal-preview-content__info-item">
                                {TOUR_SIGNAL_ICON}
                                {this.state.poi['tours-counter'] ? this.state.poi['tours-counter'] : 0}
                              </li>
                            </ul>
                          </div>
                        </>
                      ) : null}
                      <div className="d-flex justify-content-center align-items-center">
                        {/* <p className="modal-preview-content__description">
                          {!isEmpty(this.props.poi.attributes.description) ? `${this.props.poi.attributes.description.substr(0, 150)} ${this.props.poi.attributes.description.length >= 151 ? "..." : ""}` : null}
                        </p> */}
                        {
                          this.props.poi.attributes.description && <p className="modal-preview-content__description" dangerouslySetInnerHTML={{ __html: this.props.poi.attributes.description && `${this.props.poi.attributes.description.substr(0, 200)} ${this.props.poi.attributes.description.length >= 201 ? "..." : ""}` }} ></p>
                        }
                        <div className="modal-preview-content__partnerContainer">
                          <h5 className="tour-content-poi-box__partner-call">
                            {this.state.poiCampaign ? 'patrocina a los creadores de este destino' : "PATROCINA ESTE DESTINO"}
                          </h5>
                          <figure className="modal-preview-content__partner">
                            <img
                              className="modal-preview-content__partner-image"
                              src={this.state.poiCampaign ? this.state.poiCampaign.logo : defaultPartner}
                              alt="Partner name"
                            />
                          </figure>
                          <div className="d-flex justify-content-center mt-2">
                            <h5 className="tour-content-poi-box__partner-description">
                              {this.state.poiCampaign ? this.state.poiCampaign.smallText : ""}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <header className="row modal-preview-content__header">
                        {isEmpty(this.props.poi.attributes.relatedOwner) ? null : (
                          <>
                            <p className="modal-preview-content__owner mb-1">
                              Autor original:
                            <Link
                                to={USER_DINAMIC_URL(this.props.poi.attributes.relatedOwner.attributes.nickname)}
                                className="modal-preview-content__owner-name"
                              >
                                {`${this.props.poi.attributes.relatedOwner.attributes.name} 
                      ${this.props.poi.attributes.relatedOwner.attributes.surname}`}
                                {this.props.poi.attributes.relatedOwner.attributes.toptraveler ? (
                                  <>
                                    {STAR_ICON}
                                    {SIMPLE_FLAG_ICON}
                                  </>
                                ) : null}
                              </Link>
                            </p>
                            <p className="modal-preview-content__owner mt-0">Id: {this.props.poi.id}</p>
                          </>
                        )}
                      </header>
                      <div className={this.props.poi.attributes["type-preview"] != 0 ? "d-flex justify-content-between align-items-center division" : "d-flex justify-content-center division"}>
                        {this.props.poi.attributes["type-preview"] != 0 ? (
                          <>
                            {this.props.poi.attributes["type-preview"] == 1 ? (
                              <>
                                {this.props.auth.currentUser && (this.props.auth.currentUser.attributes["active-subscription"] || this.props.auth.currentUser.attributes.toptraveler) && this.state.savePreview == false ? (
                                  <>
                                    <div className="d-none d-lg-block col-lg-4">
                                      <FineUploader
                                        key={`desktop-uploader-${this.state.imageP.length}`}
                                        endpoint="/images"
                                        autoUpload
                                        multiple={this.props.poi.attributes['poi-type'] === 1}
                                        withUploadBtn={true}
                                        params={uploaderParams}
                                        onAllComplete={null}
                                        withProgressBars
                                        withTotalProgressBar
                                        removeThumbAfter={800}
                                        imageUpload={this.showPreviewImages}
                                        countUpload={true}
                                        lengthPoiImages={this.state.imageP.length}
                                      >
                                        <div
                                          className={`
                      poi-images-box__dropzone ${false
                                              ? 'poi-images-box__dropzone--dragging'
                                              : ''}
                    `}
                                        /*                           onDragStart={() => handleDraggingState(true)}
                                                                onDragOver={() => handleDraggingState(false)} */
                                        >
                                          <img
                                            src={UPLOAD_CLOUD_URL}
                                            alt="Sube tus imágenes"
                                            className="poi-images-box__dropzone-img poi-images-box__dropzone-img--nodrag"
                                          />
                                          <img
                                            src={DROP_COLOR_URL}
                                            alt="Sube tus imágenes"
                                            className="poi-images-box__dropzone-img poi-images-box__dropzone-img--drag"
                                          />
                                          <p className="poi-images-box__dropzone-text">Arrastra aquí tus imágenes</p>
                                        </div>
                                      </FineUploader>
                                      {/*  <p className="poi-images-page__total-images">
                          Total de imagenes:
                    <span>{images && images.length}</span>
                        </p> */}
                                    </div>
                                    <div className="row d-none d-lg-flex my-5 modal-warning__footer">
                                      <div className="col-auto modal-warning__cross-icon">{POINTS_CROSS_ICON}</div>
                                      <div className="col pl-0">
                                        <p className="mb-0">No subas imágenes personales</p>
                                        <p className="mb-0">No imágenes sin la propiedad intelectual</p>
                                        <p className="mb-0">Necesitas un mínimo de 5 para habilitar el boton de guardar</p>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                    <div className="d-flex justify-content-center">
                                      <div className="d-flex flex-column align-items-center w-75">
                                        <img
                                          width="40"
                                          className="information_azul"
                                          src='../assets/images/information_azul.svg'
                                          alt="information"
                                        />
                                        <p className="information_text">Creación de destinos desde ventanas prevías solo disponible para usuarios Premium o Toptravelers.</p>
                                      </div>
                                    </div>
                                  )}
                              </>
                            ) : (
                                <>
                                  {this.props.auth.isAuthenticated ? (
                                    <>
                                      {this.props.auth.currentUser && (this.props.auth.currentUser.id == 14 || this.props.auth.currentUser.id == 40 || this.props.auth.currentUser.id == 22) ? (
                                        <div className="d-flex justify-content-center w-100 m-3">
                                          {this.state.showMessagePreviewPoi ? (
                                            this.messagesPreviewPoi()
                                          ) : (
                                              <>
                                                <button className="btn btn-primary mr-1" onClick={() => this.approvedPreviewPoiAdmin()} >Aprobar</button>
                                                <button className="btn btn-danger ml-1" onClick={() => this.rejectedPreviewPoiAdmin()} >Rechazar</button>
                                              </>
                                            )}
                                        </div>
                                      ) : (
                                          <>
                                            <div className="m-4">
                                              <img
                                                className="verificando"
                                                src='../assets/images/verificando.svg'
                                                alt="verificando"
                                              />
                                            </div>
                                            <div className="m-4">
                                              <h3 className="text-center">Destino a la espera de ser aprobado por el equipo de Topdesti.</h3>
                                              <h6 className="text-center">Una vez que verifiquemos que las fotografías son correctas y de calidad pasará a formar parte del catálogo de destinos en Descubre.</h6>
                                            </div>
                                          </>
                                        )}
                                    </>
                                  ) : (
                                      <>
                                        <div className="m-4">
                                          <img
                                            className="verificando"
                                            src='../assets/images/verificando.svg'
                                            alt="verificando"
                                          />
                                        </div>
                                        <div className="m-4">
                                          <h3 className="text-center">Destino a la espera de ser aprobado por el equipo de Topdesti.</h3>
                                          <h6 className="text-center">Una vez que verifiquemos que las fotografías son correctas y de calidad pasará a formar parte del catálogo de destinos en Descubre.</h6>
                                        </div>
                                      </>
                                    )}
                                </>
                              )}
                          </>
                        ) : (
                            <a target='_blank' href={this.props.redirectURL} className="btn btn-primary boton">
                              ver destino
                            </a>
                          )}
                        {/* <button className="btn btn-primary boton">
                    + favoritos
                  </button> */}
                      </div>
                      <div className="d-flex division">
                        <div className="botLeft">
                          {this.props.poi.attributes["type-preview"] != 0 && this.state.imageP.length == 0 ? (
                            <h6 className="sinCargar">Tus imágenes una vez cargadas en el servidor aparecerán aquí. Puedes publicar hasta 12 fotografías de este destino
                      y una vez aprobadas pasarás a ser el <span>propietario de la bandera</span> de este lugar.
                            </h6>
                          ) : (
                              <h6>Imágenes ({this.props.poi.attributes["type-preview"] != 0 ? this.state.imageP.length : orderedImages.length}):</h6>
                            )}
                          {
                            this.props.poi.attributes["type-preview"] != 0 ? (
                              <div className="father">
                                {this.state.imageP.map((image, i) => {
                                  if (i <= 11) {
                                    const srcSetArray = getImageSrcSet(image);
                                    return (
                                      <ResponsiveImg className="previewImages" srcSet={Object.values(image)}>
                                        {this.props.poi.attributes["type-preview"] == 1 && this.props.auth.currentUser && this.state.savePreview == false ? (
                                          <button onClick={() => this.deleteImage(image.id)} className="closeIcon p-0">
                                            {CIRCLE_CROSS_ICON}
                                          </button>
                                        ) : null}
                                      </ResponsiveImg>
                                      /* <figure>
                                        <img src={image.url} />
                                      </figure> */
                                    );
                                  }
                                })}
                              </div>
                            ) : (
                                <div className="father">
                                  {orderedImages.map((image, i) => {
                                    if (i <= 11) {
                                      const srcSetArray = getImageSrcSet(image);
                                      return (
                                        <ResponsiveImg className="previewImages" srcSet={srcSetArray}>
                                        </ResponsiveImg>
                                      );
                                    }
                                  })}
                                </div>
                              )
                          }
                        </div>
                        <div className="botRight MapaImage">
                          <SimpleMap
                            key={this._mapId}
                            mapElementId={this._mapId}
                            onMapLoad={this.handleMapMount}
                            onIdle={this.initializeMapBounds}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
            }
            <div className="contentBot d-flex justify-content-end">

            </div>
          </div>
        </Modal>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const urlQueryParams = new URLSearchParams(state.router.location.search);
  const savedValidatedContentFilter = urlQueryParams.get('includeNoVal');
  const currentUserId = state.auth.isAuthenticated
    && !isEmpty(state.auth.currentUser)
    ? state.auth.currentUser.id : null;

  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    urlQueryParams,
    savedValidatedContentFilter,
    currentUserId,
  };
}

export default connect(mapStateToProps)(ModalPreviewContent);
