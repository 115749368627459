import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  FacebookIcon,
  TwitterIcon,
} from 'react-share';
import TYPES from '../../../../../../../../constants/types';
import {
  CLOSE_ICON,
  TOUR_SIGNAL_ICON,
  INSTAGRAM_ICON,
} from '../../../../../../../../helpers';
import SimpleMap from '../../../../../../../simple-map';
import { isEmpty, checkExternalUrl } from '../../../../../../../../utils';

const RecommendationInfoBoxTemplate = (props) => {
  const {
    service, handleShowInfo, handleMapMount, initializeMapBounds, isShowedMap,
    // currentUrl,
  } = props;
  const recommendationInfoRef = useRef();

  useEffect(() => {
    const scrollPosition = recommendationInfoRef.current.offsetTop - 150;
    window.scrollTo(0, scrollPosition);
  }, []);

  const [isClosing, setIsClosing] = useState(false);
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      handleShowInfo(false);
    }, 450);
  };

  const {
    title,
    'full-address': fullAddress,
    website,
    'twitter-url': twitterUrl,
    'facebook-url': facebookUrl,
    'instagram-url': instagramUrl,
    phone,
    'tours-counter': toursCounter,
  } = service.attributes;

  const hasRRSS = !isEmpty(twitterUrl) || !isEmpty(facebookUrl) || !isEmpty(instagramUrl);

  return (
    <article
      ref={recommendationInfoRef}
      className={`container recommendation-info-box swing-in-top-fwd ${isClosing ? 'swing-out-top-bck' : ''}`}
    >
      <header className="row recommendation-info-box__header">
        <div className="col pr-0">
          <h4 className="recommendation-info-box__header-title">{title}</h4>
        </div>
        <div className="col-auto">
          <button
            type="button"
            onClick={handleClose}
            className="recommendation-info-box__header-btn"
            title="Cerrar Mis Fotos"
          >
            {CLOSE_ICON}
          </button>
        </div>
      </header>
      <section className="row recommendation-info-box__content">
        <div className="col-12 col-lg-8">
          <h4 className="recommendation-info-box__title">{title}</h4>
          <p className="recommendation-info-box__address">{fullAddress}</p>
          <p className="recommendation-info-box__website">{website}</p>
          <p className="recommendation-info-box__phone">{phone}</p>
          {hasRRSS ? (
            <ul className="recommendation-info-box__rrss-list">
              {isEmpty(facebookUrl) ? null : (
                <li className="recommendation-info-box__rrss-item">
                  <a
                    className="recommendation-info-box__rrss-link"
                    href={checkExternalUrl(facebookUrl)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon size={50} round />
                  </a>
                </li>
              )}
              {isEmpty(twitterUrl) ? null : (
                <li className="recommendation-info-box__rrss-item">
                  <a
                    className="recommendation-info-box__rrss-link"
                    href={checkExternalUrl(twitterUrl)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon size={50} round />
                  </a>
                </li>
              )}
              {isEmpty(instagramUrl) ? null : (
                <li className="recommendation-info-box__rrss-item">
                  <a
                    href={checkExternalUrl(instagramUrl)}
                    className="recommendation-info-box__rrss-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {INSTAGRAM_ICON}
                  </a>
                </li>
              )}
            </ul>
          ) : null}
          <p className="recommendation-info-box__tours-counter">
            {TOUR_SIGNAL_ICON}
            <span className="recommendation-info-box__tours-counter-text">Recomendada en</span>
            <span className="recommendation-info-box__tours-counter-value">{`${toursCounter} rutas`}</span>
          </p>
        </div>
        <div className="col-12 col-lg-4 px-0">
          {isShowedMap ? (
            <SimpleMap
              mapElementId={`service-position-${service.id}`}
              onMapLoad={handleMapMount}
              onIdle={initializeMapBounds}
            />
          ) : null}
        </div>
      </section>
      <footer className="row recommendation-images-box__footer">
        <div className="col">
          <button
            type="button"
            className="btn btn-primary recommendation-images-box__footer-btn"
            onClick={handleClose}
            title="Cerrar Mis Fotos"
          >
            cerrar info
          </button>
        </div>
      </footer>
    </article>
  );
};

RecommendationInfoBoxTemplate.propTypes = {
  service: TYPES.poiType.isRequired,
  handleShowInfo: PropTypes.func.isRequired,
  handleMapMount: PropTypes.func.isRequired,
  initializeMapBounds: PropTypes.func.isRequired,
  isShowedMap: PropTypes.bool.isRequired,
  // currentUrl: PropTypes.string,
};

// RecommendationInfoBoxTemplate.defaultProps = {
//   currentUrl: null,
// };

function mapStateToProps() {
  const currentUrl = window.location.href;
  return { currentUrl };
}
export default connect(mapStateToProps)(RecommendationInfoBoxTemplate);
