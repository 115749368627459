import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';
import {
  FacebookIcon,
  TwitterIcon,
} from 'react-share';
import {
  STAR_ICON,
  SIMPLE_FLAG_ICON,
  INFO_CIRCLE_ICON,
  RECOMMENDATION_ICON,
  TOUR_SIGNAL_ICON,
  TOP_TRAVELER_IMG,
  INSTAGRAM_ICON,
} from '../../../../../../helpers';
import { isEmpty, getImageSrcSet, checkExternalUrl } from '../../../../../../utils';
import TYPES from '../../../../../../constants/types';
import { SERVICE_CATEGORIES, VIDEO_PLAYER_CONFIG } from '../../../../../../constants';
import ResponsiveImg from '../../../../../responsive-img';
import SimpleMap from '../../../../../simple-map';

const TourContentServiceBoxRecommendationsTemplate = (props) => {
  const {
    owner,
    author,
    authorAvatar,
    poi,
    tourContent,
    images,
    isMobile,
    layoutMode,
    handleCarouselChange,
    carouselPosition,
    handleMapOnMount,
    initializeMapBounds,
    handleShowInfo,
    handleShowImages,
  } = props;

  /* Poi info */
  const {
    attributes: {
      category: categoryValue = null,
      title: poiTitle = null,
      'full-address': fullAddress,
      phone,
      website,
      'twitter-url': twitterUrl,
      'facebook-url': facebookUrl,
      'instagram-url': instagramUrl,
      'tours-counter': toursCounter,
    } = {},
  } = poi || {};
  const hasRRSS = !isEmpty(twitterUrl) || !isEmpty(instagramUrl) || !isEmpty(facebookUrl);

  const categoryName = isEmpty(categoryValue)
    ? 'Recomendación'
    : SERVICE_CATEGORIES.reduce(
      (acc, option) =>
        (parseInt(option.value, 10) === parseInt(categoryValue, 10) ? option.label : acc),
      '',
    );
  /* Poi owner info */
  const {
    attributes: {
      name: ownerName = null,
      surname: ownerSurname = null,
      toptraveler: ownerTopTraveler = false,
    } = {},
  } = owner || {};
  /* Tour-content info */
  const {
    attributes: {
      text: tourContentText = null,
      'affiliate-link': affiliateLink,
      'affiliate-title': affiliateTitle,
      'affiliate-text': affiliateText,
      'video-url': videoUrl,
    } = {},
  } = tourContent;

  const {
    attributes: {
      toptraveler: isAuthorToptraveler,
    } = {},
  } = author || {};

  return (
    <article
      className={`container tour-content-service-box ${
        layoutMode === 1
          ? 'tour-content-service-box--simple'
          : layoutMode === 2
            ? 'tour-content-service-box--premium'
            : 'd-none'
      }`}
    >
      <header className="row d-flex justify-content-end tour-content-service-box__header">
        <div className="col-auto px-1">
          <span className="tour-content-service-box__category">{categoryName}</span>
        </div>
        <div className="col-auto pl-lg-1">
          <figure className="tour-content-service-box__header-icon">{RECOMMENDATION_ICON}</figure>
        </div>
      </header>

      <div className="row">
        {layoutMode === 2 ? (
          <div className="col-12 px-0 mb-3 mt-lg-3 order-lg-3">
            {isEmpty(images) ? null : (
              <Carousel
                showThumbs={false}
                showStatus={false}
                emulateTouch
                onChange={handleCarouselChange}
                selectedItem={carouselPosition}
              >
                {images.map(image => (
                  <ResponsiveImg
                    key={`service-image-${image.id}`}
                    className="tour-content-service-box__slide-image"
                    srcSet={getImageSrcSet(image)}
                    forzedVersion={2}
                  />
                ))}
              </Carousel>
            )}
          </div>
        ) : !isMobile && !isEmpty(images) ? (
          <div className="col-4 order-lg-2">
            <ResponsiveImg
              className="tour-content-service-box__thumb"
              srcSet={getImageSrcSet(images[0])}
              forzedVersion={2}
            />
          </div>
        ) : null}

        <div className={`col-12 order-lg-1 ${layoutMode === 1 ? 'mb-3' : 'col-lg-5'}`}>
          <div className="row">
            <div className="col">
              <h3 className="tour-content-service-box__title">
                {isEmpty(poiTitle) ? 'Servicio sin nombre' : poiTitle}
                {layoutMode === 1 ? (
                  <button
                    type="button"
                    className="tour-content-service-box__info-btn"
                    onClick={handleShowInfo}
                  >
                    {INFO_CIRCLE_ICON}
                  </button>
                ) : null}
              </h3>
              {isEmpty(ownerName) && isEmpty(ownerSurname) ? null : (
                <div className="col mx-0 p-0">
                  <p className="tour-content-service-box__owner">
                    Creador original:
              <span className="tour-content-service-box__owner-name">
                      {`${ownerName} ${ownerSurname}`}
                      {ownerTopTraveler
                        ? (
                          <>
                            {STAR_ICON}
                            {SIMPLE_FLAG_ICON}
                          </>
                        )
                        : null}
                    </span>
                  </p>
                </div>
              )}
            </div>
            {isMobile ? (
              <div className="col-auto">
                <span className="tour-content-service-box__tours-counter">
                  {isEmpty(toursCounter) ? 0 : toursCounter}
                  {TOUR_SIGNAL_ICON}
                </span>
              </div>
            ) : null}
          </div>
          {layoutMode === 2 ? (
            <>
              <div className="row">
                {isEmpty(fullAddress) ? null : (
                  <div className="col col-lg-12 order-lg-1">
                    <p className="tour-content-service-box__address">{fullAddress}</p>
                  </div>
                )}
                {isEmpty(phone) ? null : (
                  <div className="col col-lg-12 order-lg-3">
                    <p className="tour-content-service-box__phone">{phone}</p>
                  </div>
                )}
                {isEmpty(website) ? null : (
                  <div className="col-12 order-lg-2">
                    <a
                      className="tour-content-service-box__website"
                      href={checkExternalUrl(website)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {website}
                    </a>
                  </div>
                )}
              </div>
              <div className="row mt-3 mt-lg-2">
                <div className="col col-lg-12">
                  {hasRRSS ? (
                    <div className="tour-content-service-box__rrss">
                      {isEmpty(facebookUrl) ? null : (
                        <a
                          className="recommendation-info-box__rrss-link"
                          href={checkExternalUrl(facebookUrl)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FacebookIcon size={50} round />
                        </a>
                      )}
                      {isEmpty(twitterUrl) ? null : (
                        <a
                          className="recommendation-info-box__rrss-link"
                          href={checkExternalUrl(twitterUrl)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TwitterIcon size={50} round />
                        </a>
                      )}
                      {isEmpty(instagramUrl) ? null : (
                        <a
                          className="recommendation-info-box__rrss-link"
                          href={checkExternalUrl(instagramUrl)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {INSTAGRAM_ICON}
                        </a>
                      )}
                    </div>
                  ) : null}
                </div>

                <div className="col-6 col-lg-12">
                  {isMobile ? null : (
                    // (
                    //   <Link to={PLACE_PAGE_DINAMIC_URL(poiId)} className="btn btn-primary btn-block">
                    //     Ver Perfil
                    //   </Link>
                    // )
                    <p className="tour-content-service-box__tours-counter">
                      <span className="tour-content-service-box__tours-counter-icon">
                        {TOUR_SIGNAL_ICON}
                      </span>
                      Recomendado en
                      <button type="button" className="btn-link">
                        {`${isEmpty(toursCounter) ? 0 : toursCounter} rutas`}
                      </button>
                    </p>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div
          className={`col-12 ${
            layoutMode === 1 ? 'col-lg-5' : 'col-lg-7'
          } order-lg-2 tour-content-service-box__recommendation`}
        >
          <div className="row">
            <div className="col-auto tour-content-service-box__author">
              <ResponsiveImg
                srcSet={getImageSrcSet(authorAvatar)}
                className={
                  `tour-content-service-box__author-avatar
                  ${isAuthorToptraveler ? ' tour-content-service-box__author-avatar--toptraveler' : ''}`
                }
              />
              {isAuthorToptraveler
                ? (
                  <span className="tour-content-service-box__author-toptraveler">
                    {TOP_TRAVELER_IMG}
                  </span>
                ) : null
              }
            </div>
            <div className="col">
              <p
                className="tour-content-service-box__recommendation-text"
                dangerouslySetInnerHTML={{ __html: tourContentText }}
              />
            </div>
          </div>
          {/* {!isMobile && layoutMode === 2 ? (
            <div className="row mt-3">
              <div className="col-auto pl-0 pr-1">
                <Link to={PLACE_PAGE_DINAMIC_URL(poiId)} className="btn btn-primary btn-block">
                  Ver Perfil
                </Link>
              </div>
            </div>
          ) : null} */}
        </div>
        {isMobile && layoutMode === 1 && !isEmpty(images) ? (
          <div className="col-12 col-lg-3 text-right">
            <button className="btn btn-primary" type="button" onClick={handleShowImages}>
              Mis fotos
            </button>
          </div>
        ) : null}
        {layoutMode === 2 && !isEmpty(videoUrl) ? (
          <div className="col-12 col-lg-8 order-lg-4 tour-content-service-box__video-wrapper">
            <ReactPlayer url={videoUrl} config={VIDEO_PLAYER_CONFIG} width="100%" height="100%" />
          </div>
        ) : null}
        {layoutMode === 2 && !isEmpty(poi) ? (
          <div
            className={`col-12 col-sm ${
              isEmpty(videoUrl) ? '' : 'col-lg-4'
            } order-lg-5 tour-content-service-box__map`}
          >
            <SimpleMap
              mapElementId={`map-content-${tourContent.id}`}
              onMapLoad={handleMapOnMount}
              onIdle={initializeMapBounds}
            />
          </div>
        ) : null}

        <div
          className={`col-12 order-lg-6 ${
            layoutMode === 1 ? 'col-lg-3' : ''
          } tour-content-service-box__affiliate`}
        >
          <div className="row">
            <div className={`col-6 ${layoutMode === 1 ? 'col-lg-12' : 'col-lg-auto'} mx-auto`}>
              {layoutMode === 1 && !isMobile && !isEmpty(images) ? (
                <>
                  {/* <Link to={PLACE_PAGE_DINAMIC_URL(poiId)} className="btn btn-primary btn-block">
                      Ver Perfil
                    </Link> */}
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={handleShowImages}
                  >
                    Mis Fotos
                  </button>
                </>
              ) : null}
              {isEmpty(affiliateLink) ? null : (
                <a
                  href={checkExternalUrl(affiliateLink)}
                  className="btn btn-primary btn-block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Reservar
                </a>
              )}
            </div>
            {isEmpty(affiliateLink) ? null : (
              <div className="col-12 col-lg mt-3">
                <div className="row align-items-center align-items-lg-end">
                  {layoutMode === 2 ? (
                    <div className="col-auto">
                      <ResponsiveImg
                        srcSet={getImageSrcSet(authorAvatar)}
                        className="tour-content-service-box__author-avatar"
                      />
                    </div>
                  ) : null}
                  <div className="col">
                    <p className="tour-content-service-box__affiliate-title">
                      {isEmpty(affiliateTitle)
                        ? 'El 100% de la comisión de esta reserva irá a este usuario y'
                          + ' al mismo precio que si reservas directamente desde la web.'
                        : affiliateTitle}
                    </p>
                    <p className="tour-content-service-box__affiliate-text">
                      {isEmpty(affiliateText)
                        ? 'Reserva desde este enlace y me ayudarás a que pueda seguir cumpliendo'
                          + ' mi sueño de viajar por el mundo y crear más rutas como ésta.'
                        : affiliateText}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};

TourContentServiceBoxRecommendationsTemplate.propTypes = {
  tourContent: TYPES.tourContentType.isRequired,
  poi: TYPES.poiType,
  images: PropTypes.arrayOf(TYPES.imageType),
  owner: TYPES.userType,
  author: TYPES.userType,
  authorAvatar: TYPES.imageType,
  isMobile: PropTypes.bool,
  layoutMode: PropTypes.number,
  carouselPosition: PropTypes.number.isRequired,
  initializeMapBounds: PropTypes.func.isRequired,
  handleCarouselChange: PropTypes.func.isRequired,
  handleMapOnMount: PropTypes.func.isRequired,
  handleShowInfo: PropTypes.func.isRequired,
  handleShowImages: PropTypes.func.isRequired,
};

TourContentServiceBoxRecommendationsTemplate.defaultProps = {
  poi: null,
  images: [],
  author: null,
  authorAvatar: null,
  owner: null,
  isMobile: false,
  layoutMode: 0,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(TourContentServiceBoxRecommendationsTemplate);
