import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash.clonedeep';
import { toastr } from 'react-redux-toastr';
import ProductFormTemplate from './product-form-template';
import TYPES from '../../../../../../constants/types';
import reduxBeeMakeRequest from '../../../../../../utils/redux-bee/redux-bee-make-request';
import { isEmpty } from '../../../../../../utils';

const ProductForm = (props) => {
  const {
    handleToggleVisibility,
    handleDelete,
    localContent,
    productImages,
    setLocalContent,
    handleOnImageUpload,
    handleSave,
    dispatch,
  } = props;

  const [orderedProductImages, setOrderedProductImages] = useState([]);

  useEffect(() => {
    if (!isEmpty(productImages)) {
      const orderedImages = productImages.sort((a, b) => a.attributes.order - b.attributes.order);
      setOrderedProductImages(orderedImages);
    }
  }, [productImages]);

  // ***************** USERS INPUTS HANDLERS *********************
  const handleTextChange = (value) => {
    // the empty editor value is '<p><br></p>'
    // before setting the state, check if editor is empty and convert it to empty string if it is
    const richText = value === '<p><br></p>' ? '' : value;

    const immutableProduct = _cloneDeep(localContent);
    immutableProduct.attributes.text = richText;
    setLocalContent(immutableProduct);
  };

  const handleInputChange = (e) => {
    const immutableProduct = _cloneDeep(localContent);
    const {
      target: { value: newValue, name: attributeIdentifier },
    } = e;
    immutableProduct.attributes[attributeIdentifier] = newValue;
    setLocalContent(immutableProduct);
  };

  // *************** USERS INPUTS HANDLERS END *******************

  const handleDeleteImage = image =>
    reduxBeeMakeRequest(dispatch, 'deleteImage', { id: image.id }, {})
      .then(() => reduxBeeMakeRequest(dispatch, 'getProduct', { id: localContent.id }, {}))
      .catch(() => {
        toastr.error('No se ha podido eliminar la imagen, por favor, intentalo de nuevo');
      });

  const handleOnDragEnd = ({ oldIndex, newIndex }) => {
    if (isEmpty(newIndex) || oldIndex === newIndex) return;

    const movedImage = orderedProductImages[oldIndex];
    reduxBeeMakeRequest(
      dispatch,
      'updateImage',
      { id: movedImage.id },
      { ...movedImage, attributes: { ...movedImage.attributes, order: newIndex + 1 } },
    )
      .then(() => reduxBeeMakeRequest(dispatch, 'getProductImages', { id: localContent.id }, {}))
      .then((images) => {
        const newOrderedImages = images.sort((a, b) => a.attributes.order - b.attributes.order);
        setOrderedProductImages(newOrderedImages);
      });
  };

  return (
    <ProductFormTemplate
      localContent={localContent}
      productImages={orderedProductImages}
      handleToggleVisibility={handleToggleVisibility}
      handleDelete={handleDelete}
      handleTextChange={handleTextChange}
      handleInputChange={handleInputChange}
      handleOnImageUpload={() => handleOnImageUpload(localContent)}
      handleDeleteImage={handleDeleteImage}
      handleOnDragEnd={handleOnDragEnd}
      handleSave={handleSave}
    />
  );
};

ProductForm.propTypes = {
  localContent: TYPES.productType,
  productImages: PropTypes.arrayOf(TYPES.imageType),
  setLocalContent: PropTypes.func.isRequired,
  handleOnImageUpload: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

ProductForm.defaultProps = {
  localContent: null,
  productImages: null,
};

function mapStateToProps(state, ownProps) {
  const { localContent } = ownProps;
  const product = isEmpty(localContent.id)
    ? localContent
    : state.bees.entities.products[localContent.id];
  const imagesData = isEmpty(localContent.id) ? [] : product.relationships.images.data;
  const productImages = imagesData.map(
    imageData => state.bees.entities[imageData.type][imageData.id],
  );

  return {
    productImages,
  };
}

export default connect(mapStateToProps)(ProductForm);
