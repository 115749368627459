import React from 'react';
import PropTypes from 'prop-types';
import {
  SIMPLE_LAUNCH_ICON, MARKER_PLUS, ROUTE_ICON, SCREW_ICON,
} from '../../../helpers';

const SHOW_SEARCH = {
  showSearchTab: true,
  showTourContentModal: false,
  showConfigModal: false,
  showPreviewTab: false,
  resetSelection: true,
};

const SHOW_TOUR_CONTENT = {
  showSearchTab: false,
  showTourContentModal: true,
  showConfigModal: false,
  showPreviewTab: false,
  resetSelection: true,
};

const SHOW_TOUR_CONFIG = {
  showSearchTab: false,
  showTourContentModal: false,
  showConfigModal: true,
  showPreviewTab: false,
  resetSelection: true,
};

const SHOW_TOUR_PREVIEW = {
  showSearchTab: false,
  showTourContentModal: false,
  showConfigModal: false,
  showPreviewTab: true,
  resetSelection: true,
};

export default function TourEditionNav(props) {
  const { handleNavigation, tabState } = props;
  const {
    showSearchTab, showTourContentModal, showConfigModal, showPreviewTab,
  } = tabState;

  return (
      <nav className="container-fluid tour-edition-nav">
        <div className="row">
          <div className="col px-0">
            <button
              type="button"
              className={`tour-edition-nav__button ${showSearchTab ? 'tour-edition-nav__button--active' : ''}`}
              onClick={() => handleNavigation(SHOW_SEARCH)}
            >
              {MARKER_PLUS}
            </button>
          </div>
          <div className="col px-0">
            <button
              type="button"
              className={`tour-edition-nav__button ${
                showTourContentModal ? 'tour-edition-nav__button--active' : ''
                }`}
              onClick={() => handleNavigation(SHOW_TOUR_CONTENT)}
            >
              {ROUTE_ICON}
            </button>
          </div>
          <div className="col px-0">
            <button
              type="button"
              className={`tour-edition-nav__button ${
                showConfigModal ? 'tour-edition-nav__button--active' : ''
                }`}
              onClick={() => handleNavigation(SHOW_TOUR_CONFIG)}
            >
              {SCREW_ICON}
            </button>
          </div>
          <div className="col px-0">
            <button
              type="button"
              className={`tour-edition-nav__button ${showPreviewTab ? 'tour-edition-nav__button--active' : ''}`}
              onClick={() => handleNavigation(SHOW_TOUR_PREVIEW)}
            >
              {SIMPLE_LAUNCH_ICON}
            </button>
          </div>
        </div>
      </nav>
  );
}

TourEditionNav.propTypes = {
  handleNavigation: PropTypes.func.isRequired,
  tabState: PropTypes.shape({
    showSearchTab: PropTypes.bool,
    showTourContentModal: PropTypes.bool,
    showConfigModal: PropTypes.bool,
    showPreviewTab: PropTypes.bool,
  }).isRequired,
};
