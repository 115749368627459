import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { connect } from 'react-redux';
import TourContentModal from './parts/tour-content-modal';
import { PLUS_ICON } from '../../../../../../helpers';
import TYPES from '../../../../../../constants/types';

const SHOW_TOUR_CONTENT_CREATION_MENU = {
  showSearchTab: false,
  showTourContentModal: true,
  showConfigModal: false,
  showPreviewTab: false,
  showTourContentMenuModal: true,
};

const SortableItem = sortableElement(({ children, contentType }) => (
  <li
    className={`tour-content-modal__content-list-item ${
      contentType === 2 ? 'tour-content-modal__content-list-item--blue' : ''
    } ${contentType === 8 ? 'tour-content-modal__content-list-item--green' : ''}`}
  >
    {children}
  </li>
));

const SortableContainer = sortableContainer(({ children }) => (
  <ul className="tour-content-modal__content-list">{children}</ul>
));

class TourContentsListModalVersion extends Component {
  render() {
    const {
      onDragStart,
      onDragEnd,
      handleContentSelection,
      handleNavigation,
      handleDeleteTourContent,
      currDayTourContents,
      handleToogleTourContentVisibility,
      isMobile,
    } = this.props;

    const sortTrigger = {
      ...(!isMobile && { distance: 1 }),
      ...(isMobile && { pressDelay: 100 }),
    };

    return (
      <SortableContainer
        onSortStart={onDragStart}
        onSortEnd={onDragEnd}
        axis="xy"
        lockToContainerEdges
        useDragHandle
        {...sortTrigger}
      >
        {currDayTourContents.map((tourContent, index) => (
          <SortableItem
            key={`tourContent-${tourContent.id}`}
            index={index}
            contentType={tourContent.attributes['content-type']}
          >
            <div>
              <TourContentModal
                tourContent={tourContent}
                handleContentSelection={handleContentSelection}
                handleDeleteTourContent={handleDeleteTourContent}
                handleToogleTourContentVisibility={handleToogleTourContentVisibility}
                pois={this.props.pois}
              />
            </div>
          </SortableItem>
        ))}
        <li className="tour-content-modal__content-list-item">
          <button
            type="button"
            className="tour-content-modal__add-btn"
            onClick={() => handleNavigation(SHOW_TOUR_CONTENT_CREATION_MENU)}
          >
            {PLUS_ICON}
          </button>
        </li>
      </SortableContainer>
    );
  }
}

TourContentsListModalVersion.propTypes = {
  onDragStart: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  handleContentSelection: PropTypes.func.isRequired,
  handleDeleteTourContent: PropTypes.func.isRequired,
  handleToogleTourContentVisibility: PropTypes.func.isRequired,
  handleNavigation: PropTypes.func.isRequired,
  currDayTourContents: PropTypes.arrayOf(TYPES.tourContentType),
  isMobile: PropTypes.bool,
};
TourContentsListModalVersion.defaultProps = {
  currDayTourContents: [],
  isMobile: false,
};

function MapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(MapStateToProps)(TourContentsListModalVersion);
