import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import TourContent from './parts/tour-content';
import TYPES from '../../../../../../constants/types';

class TourContentsList extends React.Component {
  render() {
    const {
      currDayTourContents,
      scrollableListRef,
      handleContentSelection,
      handleDeleteTourContent,
      handleToogleTourContentVisibility,
      dropAreaId,
      ongoingDrag,
    } = this.props;

    return (
      <Droppable droppableId={`${dropAreaId}`} direction="horizontal">
        {(provided, snapshot) => (
          <div className="day-content__slider" ref={provided.innerRef} {...provided.droppableProps}>
            <ul
              className={`day-content__list${snapshot.isDraggingOver ? ' dragging-over' : ''}${
                ongoingDrag ? ' ongoingDrag' : ''
              }`}
              ref={scrollableListRef}
            >
              {currDayTourContents.map((tourContent, index) => (
                <TourContent
                  tourContent={tourContent}
                  handleContentSelection={handleContentSelection}
                  key={`tourContent-${tourContent.id}`}
                  handleDeleteTourContent={handleDeleteTourContent}
                  handleToogleTourContentVisibility={handleToogleTourContentVisibility}
                  index={index}
                  pois={this.props.pois}
                />
              ))}
            </ul>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

TourContentsList.propTypes = {
  handleToogleTourContentVisibility: PropTypes.func.isRequired,
  handleDeleteTourContent: PropTypes.func.isRequired,
  handleContentSelection: PropTypes.func.isRequired,
  dropAreaId: PropTypes.string.isRequired,
  currDayTourContents: PropTypes.arrayOf(TYPES.tourContentType),
  ongoingDrag: PropTypes.bool,
  scrollableListRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};
TourContentsList.defaultProps = {
  currDayTourContents: [],
  ongoingDrag: false,
  scrollableListRef: null,
};

export default connect()(TourContentsList);
