/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { ROCKET_COLOR_ICON, LAUNCH_ICON } from '../../../helpers';
import TagAjaxManager from '../../../components/autocomplete-tag/tag-ajax-manager';
import api from '../../../config/api-endpoints';
import reduxBeeMakeRequest from '../../../utils/redux-bee/redux-bee-make-request';
import { TOUR_STATES } from '../../../constants';
import TYPES from '../../../constants/types';
import { handleError } from '../../../utils';

const OPEN_CONFIG_MODAL = {
  showSearchTab: false,
  showTourContentModal: false,
  showConfigModal: true,
  showPreviewTab: false,
};

const SHOW_PREVIEW = {
  showSearchTab: false,
  showTourContentModal: false,
  showConfigModal: false,
  showPreviewTab: true,
};


class TourEditionHeader extends Component {
  constructor(props) {
    super(props);
    // const startingState = selectedStatus || 1;
    this.state = {
      showTagInput: false,
      showStatusSelection: false,
    };
    this.handleStatusSelect = this.handleStatusSelect.bind(this);
    this.handleShowStatusSelectClick = this.handleShowStatusSelectClick.bind(this);
    this.handleRemoveTag = this.handleRemoveTag.bind(this);
  }

  handleStatusSelect(e) {
    const { dispatch, tour } = this.props;
    const {
      target: { value },
    } = e;

    const newState = {
      showStatusSelection: false,
    };

    this.setState(newState, () => {
      const { id, type } = tour;
      reduxBeeMakeRequest(
        dispatch,
        'updateTour',
        { id },
        { id, type, attributes: { state: parseInt(value, 10) } },
      ).catch(handleError);
    });
  }

  handleShowStatusSelectClick() {
    const { showStatusSelection } = this.state;
    this.setState({ showStatusSelection: !showStatusSelection });
  }

  handleRemoveTag(payload) {
    const { tour, dispatch } = this.props;
    return dispatch(
      api.deleteTourTags({ id: tour.id, tagId: payload.data.id }, payload),
    );
  }

  render() {
    const { handleStatusSelect, handleShowStatusSelectClick } = this;
    const {
      tour, isMobile, handleNavigation, dispatch,
    } = this.props;
    const {
      showTagInput,
      showStatusSelection,
    } = this.state;

    const { title, state: tourState = null } = tour.attributes;
    const tourId = tour.id;

    const currentState = TOUR_STATES.reduce((acc, stateOption) => (stateOption.value === tourState ? stateOption : acc),
      {});

    return isMobile ? null : (
      <header className="container-fluid tour-edition-header">
        <div className="tour-edition-header__tutorial">
          <button type="button" className="tour-edition-header__tutorial-btn">
            <img
              src="/assets/images/video.png"
              alt="Tutorial"
              className="tour-edition-header__tutorial-img"
            />
          </button>
          <p className="tour-edition-header__tutorial-text">
            Aprende con este vídeo todo lo que debes saber para crear las mejores rutas.
          </p>
        </div>
        <div className="row">
          <div className="container">
            <div className="col-12 tour-edition-header__content">
              <div className="tour-edition-header__top">
                <h3 className="tour-edition-header__title" title={title}>
                  {title}

                  {/* <button
                    type="button"
                    className="tour-edition-header__preview"
                    onClick={() => handleNavigation(SHOW_PREVIEW)}
                  >
                    {ROCKET_COLOR_ICON}
                  </button> */}
                </h3>
              </div>

              <div className="tour-edition-header__keywords">
                <TagAjaxManager
                  className='autocomplete-edition-page'
                  getCurrentResourceTags={() => dispatch(api.getTourTags({ id: tourId }))}
                  associateTagToResource={jsonApiRequest =>
                    dispatch(api.upsertTourTags({ id: tourId }, jsonApiRequest))
                  }
                  removeTagFromResource={jsonApiRequest => this.handleRemoveTag(jsonApiRequest)}
                  canDelete
                  showInput={showTagInput}
                  defaultContent={showTagInput ? null : (
                    <p className="tour-edition-header__keywords-placeholder">Añade tus palabras clave (sin ellas tu ruta no se mostrará en "Descubre"). Para ello pulsa en el icono "+", escribe la palabra que quieras añadir y a continuación pulsa la barra de "espacio" en tu teclado. </p>
                  )}
                />
                <button
                  type="button"
                  className="tour-edition-header__keywords-btn"
                  onClick={() => {
                    this.setState({ showTagInput: !showTagInput });
                  }}
                >
                  {showTagInput ? <img width='30' src="../../../../assets/images/minus_azul.svg" alt="" /> : <img width='30' src="../../../../assets/images/anadir_azul.svg" alt="" />}
                </button>
              </div>
              {/*
                  <p className="tour-edition-header__keywords">
                    <span>#grancanaria</span>
                    <span>#3dgrancanaria</span>
                    <span>#islascanarias</span>
                    <span>#surf</span>
                    <button type="button" className="tour-edition-header__keywords-btn">
                      +
                    </button>
                  </p>
                */}

              <div className="row justify-content-end">
                <div className="col-auto align-self-end">
                  {/* <button
                    type="button"
                    className="tour-edition-header__config"
                    onClick={() => handleNavigation(OPEN_CONFIG_MODAL)}
                  >
                    <span>configuración</span>
                    {LAUNCH_ICON}
                  </button> */}
                </div>
              </div>
              <div className="row align-items-center mt-2 tour-edition-header__state">
                <div className="col-auto">
                  <span>Estado:</span>
                  <button
                    type="button"
                    className={`tour-edition-header__state-btn tour-edition-header__state-btn--${currentState.name}`}
                    onClick={handleShowStatusSelectClick}
                  >
                    {currentState.label}
                  </button>
                  {showStatusSelection ? (
                    <div className="custom-state-select">
                      <div className="custom-state-select__list">
                        {TOUR_STATES
                          .filter(stateOption => stateOption.selectable && stateOption.value !== tourState)
                          .map(selectableState => (selectableState.value === 2 && tourState === 3 ? null
                            : (
                              <Fragment key={`tour-state-${selectableState.name}`}>
                                <input
                                  type="radio"
                                  name="state"
                                  id={selectableState.name}
                                  aria-checked="false"
                                  aria-hidden="true"
                                  aria-labelledby={selectableState.name}
                                  onChange={handleStatusSelect}
                                  value={selectableState.value}
                                  checked={selectableState.value === tourState}
                                />
                                <label
                                  className={`
                                  custom-state-select__list-item custom-state-select__list-item--${selectableState.name}
                                `}
                                  htmlFor={selectableState.name}
                                  aria-label={selectableState.name}
                                  data-tip={`
                                    <p class="data-tip">${selectableState.description}</p>
                                  `}
                                >
                                  {selectableState.label}
                                </label>
                              </Fragment>
                            )
                          ))
                        }
                      </div>
                      <ReactTooltip effect="solid" html />
                    </div>
                  ) : null}
                </div>
                <div className="col">
                  <p className="tour-edition-header__state-description">
                    {currentState.description}
                    {tourState === 2 ? (
                      <>
                        <br />
                        Si eres &quot;toptraveler&quot; tus rutas serán aprobadas automáticamente
                      </>
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

TourEditionHeader.propTypes = {
  tour: TYPES.tourType,
  isMobile: PropTypes.bool,
  handleNavigation: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

TourEditionHeader.defaultProps = {
  tour: null,
  isMobile: false,
};

function mapStateToProps(state, ownProps) {
  const { tourId } = ownProps;
  // const storedTours = state.entities;
  const tour = state.bees.entities.tours[tourId];
  return { tour, isMobile: state.viewport.currentWidth <= 991 };
}

export default connect(mapStateToProps)(TourEditionHeader);
