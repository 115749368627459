import React from 'react';
import PropTypes from 'prop-types';
import Navbar from '../components/navbar';

export default function MainLayout(props) {
  const { children, ContextualActions, alterBrand } = props;

  return (
    <>
      <Navbar ContextualActions={ContextualActions} alterBrand={alterBrand} />
      {children}
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  ContextualActions: PropTypes.func,
  alterBrand: PropTypes.string,
};

MainLayout.defaultProps = {
  children: null,
  ContextualActions: null,
  alterBrand: null,
};
