import { PropTypes } from 'prop-types';

const tourContentType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['sponsors']).isRequired,
  attributes: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    linkUrl: PropTypes.string,
    visibility: PropTypes.bool,
    'start-date': PropTypes.date,
    'expiration-date': PropTypes.date,
  }),
  relationships: PropTypes.shape({
    tour: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.oneOf(['tours']),
      }),
    }).isRequired,
    images: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          type: PropTypes.oneOf(['images']),
        }),
      ),
    }).isRequired,
  }),
});

export default tourContentType;
