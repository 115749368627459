import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Select from 'react-select';
import { CATEGORY_OPTIONS, SERVICE_CATEGORIES } from '../../../../../constants';
import { CLOSE_ICON, history } from '../../../../../helpers';
import { lockBodyScrolling, restoreBodyScrolling, isEmpty } from '../../../../../utils';

const LIMIT_OPTIONS = [
  { value: 25, label: '25 resultados' },
  { value: 50, label: '50 resultados' },
  { value: 100, label: '100 resultados' },
  { value: 150, label: '150 resultados' },
  { value: 200, label: '200 resultados' },
];

class FiltersModal extends Component {
  constructor(props) {
    super(props);
    const {
      savedCategoryFilters: categoryFilters,
      savedValidatedContentFilter: validatedContentFilter,
      limit,
    } = props;

    this.state = {
      categoryFilters,
      validatedContentFilter,
      localLimit: limit,
      onlyPreviewApproval: false,
      onlyPreview: false,
      noPreview: false,
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSaveFilters = this.handleSaveFilters.bind(this);
    this.handleSelectLimit = this.handleSelectLimit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    const { isOpen: prevOpen } = prevProps;
    if (prevOpen !== isOpen) {
      if (isOpen) {
        lockBodyScrolling();
      } else restoreBodyScrolling();
    }
  }
  componentDidMount(){
    const {urlQueryParams} = this.props;
    if(urlQueryParams.get("tp") == 1){
      this.setState({onlyPreview: true})
    }else if(urlQueryParams.get("tp") == 2){
      this.setState({onlyPreviewApproval: true})
    }else if(urlQueryParams.get("tp") == 3){
      this.setState({noPreview: true})
    }
    // if(this.state.noPreview){
    //   this.handleSaveFilters();
    // }
  }

  handleCheckboxChange(e, value) {
    const { categoryFilters } = this.state;
    const newCategoryFilters = { ...categoryFilters };
    if (e.target.checked) {
      newCategoryFilters[value] = true;
    } else {
      delete newCategoryFilters[value];
    }
    this.setState({ categoryFilters: newCategoryFilters });
  }

  handleValidatedContent(e) {
    const { currentUserId } = this.props;
    const { checked } = e.target;
    this.setState({ validatedContentFilter: checked ? null : currentUserId });
  }

  handleSaveFilters() {
    const {
      urlQueryParams, enableNoValidated, handleSelectLimit, limit,
    } = this.props;
    var { categoryFilters, validatedContentFilter, localLimit, onlyPreview, onlyPreviewApproval, noPreview } = this.state;
    urlQueryParams.delete('cat');
    urlQueryParams.delete('tp');
    Object.keys(categoryFilters).forEach((categoryKey) => {
      urlQueryParams.append('cat', categoryKey);
    });
    if (onlyPreview) {
      urlQueryParams.append('tp', 1);
    } else if (onlyPreviewApproval) {
      urlQueryParams.append('tp', 2);
    } else if(noPreview){
      urlQueryParams.append('tp', 3);
    }else{
      urlQueryParams.append('tp', 0);
    }
    if (enableNoValidated && !isEmpty(validatedContentFilter)) {
      urlQueryParams.set('includeNoVal', validatedContentFilter);
      urlQueryParams.delete('state');
    } else if (enableNoValidated && isEmpty(validatedContentFilter)) {
      urlQueryParams.delete('includeNoVal');
      urlQueryParams.set('state', 2);
    }
    if (localLimit !== limit) {
      handleSelectLimit(localLimit);
    }
    history.push({
      search: `?${urlQueryParams.toString()}`,
    });
  }

  handleSelectLimit({ value }) {
    this.setState({ localLimit: value });
  }
  onlyPreviewApproval = () => {
    this.setState({onlyPreviewApproval: !this.state.onlyPreviewApproval})
    this.props.onlyPreviewApproval();
  }
  onlyPreview = () => {
    this.setState({ onlyPreview: !this.state.onlyPreview })
    this.props.onlyPreview();
  }
  noPreview = () =>{
    this.setState({ noPreview: !this.state.noPreview })
    this.props.noPreview();
  }

  render() {
    const {
      isOpen,
      closeModal,
      isMobile,
      savedCategoryFilters,
      activePoiType,
      enableNoValidated,
      savedValidatedContentFilter,
      currentUserId,
    } = this.props;
    const { localLimit } = this.state;
    const poiCategories = activePoiType === 1 ? CATEGORY_OPTIONS : SERVICE_CATEGORIES;
    const currentLimitOption = LIMIT_OPTIONS.find(option => option.value === localLimit);
    return (
      <Modal
        className="filters-modal"
        overlayClassName="filters-modal__overlay"
        name="filtersModal"
        isOpen={isOpen}
        onRequestClose={closeModal}
      >
        <div className="filters-modal__content">
          {!isMobile ? (
            <header className="filters-modal__header">
              <button type="button" className="filters-modal__close-btn" onClick={closeModal}>
                {CLOSE_ICON}
              </button>
              <h4 className="filters-modal__title">Filtrar los resultados</h4>
            </header>
          ) : null}
          <div className="filters-modal__category-list">
            <h5 className="filters-modal__category-title">Máximo de resultados de la búsqueda</h5>
            <Select
              onChange={this.handleSelectLimit}
              options={LIMIT_OPTIONS}
              value={currentLimitOption}
              className="day-select"
              classNamePrefix="day-select"
              isSearchable={false}
            >
              {LIMIT_OPTIONS}
            </Select>
          </div>

          {enableNoValidated ? (
            <ul className="filters-modal__category-list">
              <h5 className="filters-modal__category-title">
                Contenido no validado
              </h5>
              <li className="filters-modal__category-item">
                <label
                  htmlFor="no-validated-content"
                  className={`custom-checkbox ${isMobile ? 'right LimiteEspacios' : ''}`}
                >
                  Mostrar contenido de otros usuarios pendientes de ser aprobados (se marcarán con un borde rojo)
                  <input
                    type="checkbox"
                    id="no-validated-content"
                    onChange={e => this.handleValidatedContent(e)}
                    defaultChecked={isEmpty(savedValidatedContentFilter) || false}
                  />
                  <i />
                </label>
              </li>
            </ul>
          ) : null}
          <ul className="filters-modal__category-list">
            <h5 className="filters-modal__category-title">Categoría</h5>
            {poiCategories.map(category => (
              <li className="filters-modal__category-item" key={`category${category.value}`}>
                <label
                  htmlFor={`category${category.value}`}
                  className={`custom-checkbox ${isMobile ? 'right' : ''}`}
                >
                  {category.label}
                  <input
                    type="checkbox"
                    id={`category${category.value}`}
                    onChange={e => this.handleCheckboxChange(e, category.value)}
                    defaultChecked={savedCategoryFilters[category.value] || false}
                  />
                  <i />
                </label>
              </li>
            ))}
            {currentUserId == 14 || currentUserId == 40 || currentUserId == 22 ? (
              <li className="filters-modal__category-item">
                <label
                  htmlFor="onlyPreviewApproval"
                  className={`custom-checkbox ${isMobile ? 'right' : ''}`}
                >
                  {"Solo destinos previos pendientes de aprobación"}
                  <input
                    type="checkbox"
                    id="onlyPreviewApproval"
                    onChange={this.onlyPreviewApproval}
                    defaultChecked={this.state.onlyPreviewApproval || false}
                  />
                  <i />
                </label>
              </li>
            ) : null}
            <li className="filters-modal__category-item">
                <label
                  htmlFor="onlyPreview"
                  className={`custom-checkbox ${isMobile ? 'right' : ''}`}
                >
                  {"Solo mostrar destinos previos"}
                  <input
                    type="checkbox"
                    id="onlyPreview"
                    onChange={this.onlyPreview}
                    defaultChecked={this.state.onlyPreview || false}
                  />
                  <i />
                </label>
              </li>
            <li className="filters-modal__category-item">
                <label
                  htmlFor="noPreview"
                  className={`custom-checkbox ${isMobile ? 'right' : ''}`}
                >
                  {"Ocultar destinos previos"}
                  <input
                    type="checkbox"
                    id="noPreview"
                    onChange={this.noPreview}
                    defaultChecked={this.state.noPreview || false}
                  />
                  <i />
                </label>
              </li>
          </ul>
          <footer className="text-right">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                this.handleSaveFilters();
                closeModal();
              }}
            >
              Aceptar
            </button>
          </footer>
        </div>
      </Modal>
    );
  }
}

FiltersModal.propTypes = {
  activePoiType: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  savedCategoryFilters: PropTypes.objectOf(PropTypes.bool),
  urlQueryParams: PropTypes.objectOf(PropTypes.object),
  enableNoValidated: PropTypes.bool,
  savedValidatedContentFilter: PropTypes.string,
  currentUserId: PropTypes.string,
  handleSelectLimit: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
};

FiltersModal.defaultProps = {
  savedCategoryFilters: null,
  isMobile: false,
  urlQueryParams: null,
  enableNoValidated: false,
  savedValidatedContentFilter: null,
  currentUserId: null,
};

function mapStateToProps(state) {
  const urlQueryParams = new URLSearchParams(state.router.location.search);
  const savedValidatedContentFilter = urlQueryParams.get('includeNoVal');
  const currentUserId = state.auth.isAuthenticated
    && !isEmpty(state.auth.currentUser)
    ? state.auth.currentUser.id : null;

  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    urlQueryParams,
    savedValidatedContentFilter,
    currentUserId,
  };
}

export default connect(mapStateToProps)(FiltersModal);
