import React from 'react';
import { LEFT_WIRE_IMG, RIGHT_WIRE_IMG } from '../../helpers';
import { isEmpty } from '../../utils';
import ENV from '../../config/environment';

// import PropTypes from 'prop-types';
// import MainLayout from '../../layouts/main-layout';

const ErrorPageTemplate = (props) => {
  const {
    error,
    errorInfo,
  } = props;
  return (
    <main className="container error-page">

      <figure className="error-page__main-image-wrapper">
        <img className="error-page__main-image" src="/assets/images/error-logo.svg" alt="Error page" />
      </figure>

      <div className="row align-items-center">
        <div className="col">
          <figure className="error-page__wire-image error-page__wire-image--left">{LEFT_WIRE_IMG}</figure>
        </div>
        <div className="col col-md-5 text-center">
          <h2 className="error-page__title">ERROR</h2>
          <p className="error-page__description error-page__description--primary">
          Algo ha ido mal, disculpa las molestias
          </p>
          <p className="error-page__description error-page__description--grey">
          Something went wrong, sorry for the inconvenience
          </p>
          <a className="error-page__link" href="/">
          Inténtalo de nuevo / Try again
          </a>
          {ENV.environment === 'development' || ENV.deployTarget === 'staging' ? (
            <>
              <p>
                Error:
                <br />
                {isEmpty(error) ? null : error.toString()}
              </p>
              <p>
                Error info:
                <br />
                {JSON.stringify(errorInfo)}
              </p>
            </>
          ) : null}
        </div>
        <div className="col">
          <figure className="error-page__wire-image error-page__wire-image--right">{RIGHT_WIRE_IMG}</figure>
        </div>
      </div>
    </main>
  );
};

export default ErrorPageTemplate;
