import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import MainLayout from '../../layouts/main-layout';
import {
  SIMPLE_FLAG_ICON,
  // CHECK_ICON,
  UPLOAD_CLOUD_URL,
  DROP_COLOR_URL,
} from '../../helpers';
import { POI_EDITION_INFO_DINAMIC_URL } from '../../constants';
import {
  PoiEditionHeader, PromiseButton, FineUploader,
} from '../../components';
import { isEmpty } from '../../utils';
import TYPES from '../../constants/types';
import PoiImage from './poi-image';
import DisabledPlaceEdition from '../../components/disabled-place-edition';

const PoiImagesPageTemplate = (props) => {
  const {
    images,
    poiMainImage,
    poi,
    handleSave,
    onAllFileComplete,
    handleChange,
    handleSetMainImage,
    handleDeleteImage,
    handleDraggingState,
    handleShowPublishModal,
    isMobile,
    isPublishable,
    isDragginOver,
  } = props;
  const { attributes: { state: poiState = null, 'poi-type': poiType = null } = {} } = poi || {};
  const hideUploadFeature = poiType === 2 && !isEmpty(images);
  // Disabled edition on mobile
  // const ContextualActions = () => (
  //   <ul className="navbar-nav d-lg-none navbar-contextuals">
  //     <li className="nav-item">
  //       <Link
  //         className="nav-link"
  //         to={POI_EDITION_INFO_DINAMIC_URL(poi.id)}
  //         title="Volver a la ficha"
  //       >
  //         {SIMPLE_FLAG_ICON}
  //       </Link>
  //     </li>
  //     <li className="nav-item">
  //       <button
  //         type="button"
  //         className="nav-link"
  //         title="Guardar"
  //         onClick={() => handleSave(null)}
  //       >
  //         {CHECK_ICON}
  //       </button>
  //     </li>
  //   </ul>
  // );

  const headerTitle = poiType === 1
    ? '2. Añade las fotografías del destino.'
    : '2. Añade las fotografías del servicio.';
  const headerDescription = poiType === 1
    ? 'Recuerda no usar imágenes personales en este apartado. Tienes un máximo de 12 fotografías '
    + 'por destino, así que elije las que puedan ser más representativas. Tampoco validaremos imágenes de poca calidad '
    + 'que no pongan en valor el destino que has añadido. Cuando tengas al menos una imagen cargada podrás pulsar '
    + 'en el botón de “publicar” (asegúrate de que toda la información es correcta porque una vez validada la ficha '
    + 'ya no podrás volver a editarla).'
    : 'Necesitas subir una 1 foto tuya para poder publicar el servicio, recuerda que no debe ser de caracter personal.';

  const uploaderParams = {
    imageableId: poi.id,
    imageableType: 'poi',
    imageableProperty: 'image',
  };

  return (
    <MainLayout>
      {isMobile && poiType !== 2 ? (
        // Disabled edition on mobile
        <DisabledPlaceEdition />
      ) : (
          <section className="container-fluid poi-edition">
            <div className="row">
              <PoiEditionHeader
                mobileTitle="Editar imágenes"
                mobileDescription={headerDescription}
              >
                <div className="col-lg-8 mx-auto">
                  <h3 className="poi-edition-header__title">
                    {headerTitle}
                  </h3>
                  <p className="poi-edition-header__description">
                    {headerDescription}
                  </p>
                </div>
              </PoiEditionHeader>

              <section className="container poi-images-page">
                <div className="row">
                  <div className={`col-12 ${hideUploadFeature ? '' : 'col-lg-8'}`}>
                    {isEmpty(images) ? (
                      <h4 className="text-dark text-center my-5">No se han encontrado imágenes</h4>
                    ) : (
                        images.map(image =>
                          (isEmpty(image) ? null : (
                            <PoiImage
                              key={`image-${image.id}`}
                              image={image}
                              handleChange={handleChange}
                              setMainImage={handleSetMainImage}
                              deleteImage={handleDeleteImage}
                              highlight={!isEmpty(poiMainImage) && poiMainImage.id === image.id}
                              isMobile={isMobile}
                            />
                          )),
                        )
                      )}
                  </div>
                  {hideUploadFeature ? null : (
                    <div className="d-none d-lg-block col-lg-4">
                      <FineUploader
                        key={`desktop-uploader-${images.length}`}
                        endpoint="/images"
                        autoUpload
                        multiple={poiType === 1}
                        params={uploaderParams}
                        onAllComplete={onAllFileComplete || null}
                        withProgressBars
                        withTotalProgressBar
                        removeThumbAfter={800}
                        withUploadBtn
                      >
                        <div
                          className={`
                        poi-images-box__dropzone ${isDragginOver
                              ? 'poi-images-box__dropzone--dragging'
                              : ''}
                      `}
                          onDragStart={() => handleDraggingState(true)}
                          onDragOver={() => handleDraggingState(false)}
                        >
                          <img
                            src={UPLOAD_CLOUD_URL}
                            alt="Sube tus imágenes"
                            className="poi-images-box__dropzone-img poi-images-box__dropzone-img--nodrag"
                          />
                          <img
                            src={DROP_COLOR_URL}
                            alt="Sube tus imágenes"
                            className="poi-images-box__dropzone-img poi-images-box__dropzone-img--drag"
                          />
                          <p className="poi-images-box__dropzone-text">Arrastra aquí tus imágenes</p>
                        </div>
                      </FineUploader>
                      <p className="poi-images-page__total-images">
                        Total de imagenes:
                      <span>{images && images.length}</span>
                      </p>
                    </div>
                  )}
                </div>
                {isMobile ? (
                  <div className="row poi-images-page__footer d-flex justify-content-center">
                    <div className="col-10 col-md-4 mt-2">
                      <PromiseButton
                        type="button"
                        className="btn btn-primary btn-block"
                        onClick={() => handleSave(null, POI_EDITION_INFO_DINAMIC_URL(poi.id))}
                      >
                        información
                    </PromiseButton>
                    </div>
                    <div className="col-10 col-md-4 mt-2">
                      <PromiseButton
                        type="button"
                        className="btn btn-primary btn-block"
                        onClick={() => handleSave(null, null)}
                      >
                        guardar
                    </PromiseButton>
                    </div>
                    <div className="col-10 col-md-4 mt-2">
                      {poiState === 3 ? (
                        <button type="button" className="btn btn-block btn-success" disabled>
                          publicado
                      </button>
                      ) : (
                          <PromiseButton
                            type="submit"
                            className={`btn btn-block ${
                              poiState === 1 ? 'btn-primary' : 'btn-outline-dark'
                              }`}
                            onClick={() => (poiState === 1 ? handleShowPublishModal(true) : handleSave(1))}
                            disabled={poiState === 3}
                          >
                            {poiState === 1 ? 'Publicar' : 'Despublicar'}
                          </PromiseButton>
                        )}
                    </div>
                    {hideUploadFeature ? null : (
                      <div className="col-10 col-md-4 mt-2">
                        <FineUploader
                          key={`mobile-uploader-${images.length}`}
                          endpoint="/images"
                          autoUpload
                          params={uploaderParams}
                          onAllComplete={onAllFileComplete}
                          isDropzone={false}
                          withProgressBars={false}
                          withTotalProgressBar
                          totalProgressBarType="linear"
                          multiple={poiType === 1}
                        >
                          <button className="btn btn-block btn-primary" type="button">
                            Subir imágen
                        </button>
                        </FineUploader>
                      </div>
                    )}
                    <div className="col-12">
                      <p className="poi-images-page__footer-text">
                        Mínimo 1 para obtener tu
                      {SIMPLE_FLAG_ICON}
                      </p>
                    </div>
                  </div>
                ) : (
                    <div className="row poi-images-page__desktop-footer">
                      <div className="col-lg-auto pr-lg-1">
                        <PromiseButton
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={() => handleSave(null, POI_EDITION_INFO_DINAMIC_URL(poi.id))}
                        >
                          información
                    </PromiseButton>
                      </div>
                      <div className="col-lg-auto px-lg-1">
                        <PromiseButton
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={() => handleSave(null, null)}
                        >
                          guardar
                    </PromiseButton>
                      </div>
                      <div className="col-lg-auto pl-lg-1">
                        {poiState === 3 ? (
                          <button type="button" className="btn btn-block btn-success" disabled>
                            publicado
                      </button>
                        ) : (
                            <PromiseButton
                              type="submit"
                              className={`btn btn-block ${
                                poiState === 1 ? 'btn-primary' : 'btn-outline-dark'
                                }`}
                              onClick={() => (poiState === 1 ? handleShowPublishModal(true) : handleSave(1))}
                              disabled={poiState === 3 || !isPublishable}
                            >
                              {poiState === 1 ? 'publicar' : 'despublicar'}
                            </PromiseButton>
                          )}
                      </div>
                    </div>
                  )}
              </section>
            </div>
          </section>
        )}
    </MainLayout>
  );
};

PoiImagesPageTemplate.propTypes = {
  poiMainImage: TYPES.imageType,
  images: PropTypes.arrayOf(TYPES.imageType),
  poi: TYPES.poiType,
  handleSave: PropTypes.func.isRequired,
  onAllFileComplete: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSetMainImage: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  handleDraggingState: PropTypes.func.isRequired,
  handleShowPublishModal: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isDragginOver: PropTypes.bool,
  isPublishable: PropTypes.bool.isRequired,
};

PoiImagesPageTemplate.defaultProps = {
  poiMainImage: null,
  images: [],
  poi: null,
  isMobile: false,
  isDragginOver: false,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(PoiImagesPageTemplate);
