import React from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash.clonedeep';
import TYPES from '../../../../../../constants/types';
import TourContentVideoFormTemplate from './tour-content-video-form-template';

const TourContentVideoForm = (props) => {
  const {
    localTourContent,
    setLocalTourContent,
    handleToggleVisibility,
    handleDelete,
  } = props;

  // ***************** USERS INPUTS HANDLERS *********************
  const handleInput = (e) => {
    const immutableTourContent = _cloneDeep(localTourContent);
    const {
      target: { value: newValue, name: attributeIdentifier },
    } = e;
    immutableTourContent.attributes[attributeIdentifier] = newValue;
    setLocalTourContent(immutableTourContent);
  };

  const handleTextChange = (value) => {
    // the empty editor value is '<p><br></p>'
    // before setting the state, check if editor is empty and convert it to empty string if it is
    const richText = value === '<p><br></p>' ? '' : value;

    const immutableTourContent = _cloneDeep(localTourContent);
    immutableTourContent.attributes.text = richText;
    setLocalTourContent(immutableTourContent);
  };
  // *************** USERS INPUTS HANDLERS END *******************
  return (
    <TourContentVideoFormTemplate
      localTourContent={localTourContent}
      handleInput={handleInput}
      handleTextChange={handleTextChange}
      handleToggleVisibility={handleToggleVisibility}
      handleDelete={handleDelete}
    />
  );
};

TourContentVideoForm.propTypes = {
  localTourContent: TYPES.tourContentType.isRequired,
  setLocalTourContent: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
TourContentVideoForm.defaultProps = {};

export default TourContentVideoForm;
