import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MenuTourContentModalTemplate from './menu-tour-content-modal-template';
import TYPES from '../../../../constants/types';
import { isEmpty, handleError } from '../../../../utils';
import reduxBeeMakeRequest from '../../../../utils/redux-bee/redux-bee-make-request';
import { dispatch } from '../../../../reduxStore';

const MenuTourContentModal = (props) => {
  const {
    isOpen,
    closeModal,
    tourInEdition,
    activeDay,
    handleContentSelection,
    selectedContent,
    reloadTourContents,
    updateLocalContentState,
  } = props;

  const handleTourContentCreation = (contentType) => {
    const params = isEmpty(selectedContent) ? null : { id: selectedContent.id };
    const methodName = isEmpty(selectedContent) ? 'createTourContent' : 'createTourContentChild';
    const newTourContent = {
      type: 'tourContents',
      attributes: {
        'affiliate-link': null,
        'affiliate-text': null,
        'affiliate-title': null,
        'arrival-time': null,
        category: null,
        'content-type': contentType,
        day: contentType !== 8 ? activeDay : 0,
        'display-mode': null,
        duration: null,
        'link-url': null,
        lat: null,
        long: null,
        order: null,
        subtitle: null,
        text: null,
        title: null,
        'video-url': null,
        visible: true,
      },
      relationships: {
        tour: {
          data: { type: 'tours', id: tourInEdition.id },
        },
        'tour-content': {
          data: isEmpty(selectedContent) ? null : { type: 'tourContents', id: selectedContent.id },
        },
      },
    };

    const tourContentCreationPromise = contentType === 3
      ? reduxBeeMakeRequest(dispatch, methodName, params, newTourContent).then((tourContent) => {
        /* TODO: refactor to avoid refresh tour and population methods, change to agnostic logic
        where retieve the required content thorugh redux state from it's neccesary */
        const refreshPromise = isEmpty(selectedContent)
          ? reloadTourContents()
          : reduxBeeMakeRequest(dispatch, 'getTourContent', { id: selectedContent.id }, {}).then(
            refreshedParentTourContent => updateLocalContentState(refreshedParentTourContent),
          );
        return refreshPromise.then(() => tourContent);
      })
      : Promise.resolve(newTourContent);

    tourContentCreationPromise.then((tourContent) => {
      closeModal();
      handleContentSelection(tourContent);
    }).catch(handleError);
  };
  return (
    <MenuTourContentModalTemplate
      isOpen={isOpen}
      isChild={!isEmpty(selectedContent)}
      closeModal={closeModal}
      handleTourContentCreation={handleTourContentCreation}
    />
  );
};

MenuTourContentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  tourInEdition: TYPES.tourType.isRequired,
  activeDay: PropTypes.number.isRequired,
  handleContentSelection: PropTypes.func.isRequired,
  selectedContent: TYPES.tourContentType,
  reloadTourContents: PropTypes.func.isRequired,
  updateLocalContentState: PropTypes.func.isRequired,
};

MenuTourContentModal.defaultProps = { selectedContent: null };

MenuTourContentModal.defaultProps = {};

export default connect()(MenuTourContentModal);
