import React from 'react';
import PropTypes from 'prop-types';
import { PLUS_ICON, CHECK_ICON } from '../../../helpers';
import { isEmpty, getImageSrcSet } from '../../../utils';
import getPoiCategoryLabel from '../../../utils/get-poi-category-label';
import ResponsiveImg from '../../responsive-img';

function SimplePoiList(props) {
  const {
    results, addButtonCB, addedPoiIds, onPoiClick,
  } = props;
  return (
    <section className="simple-poi-list">
      {results.map((poi) => {
        poi.attributes.images.sort((a, b) => b.likes - a.likes)
        const { id } = poi;
        const { attributes } = poi;
        const {
          mainImage, category, 'poi-type': poiType, state,
        } = attributes;
        const categoryLabel = isEmpty(category) ? null : getPoiCategoryLabel({ poiCategoryId: category, poiType });

        // check if the current poi is already included in the poiIdsAlreadyPresent prop array
        let alreadyPresent = false;
        if (addedPoiIds) alreadyPresent = addedPoiIds.indexOf(id) >= 0;

        return (
          <article
            className={`
              simple-poi-list__item
              ${alreadyPresent ? ' simple-poi-list__item--added' : ''}
              ${state !== 3 ? ' simple-poi-list__item--red' : ''}`}
            key={poi.id}
            ref={poi.reactRef}
          >
            <button
              type="button"
              className="col simple-poi-list__poi"
              key={poi.id}
              onClick={() => {
                // const redirectToURL = PLACE_PAGE_DINAMIC_URL(id);
                // reduxStore.dispatch(push(redirectToURL));
                onPoiClick(poi);
              }}
            >
              <div className="row flex-nowrap align-items-center">
                <div className="col-auto px-0">
                  {
                    poi.attributes["type-preview"] == 1 ? (
                      <img src="../assets/images/destino_modo_previo_trazado.svg" className="imagePreview" alt="" srcset="" />
                    ) :
                      <ResponsiveImg
                        className="simple-poi-list__poi-image"
                        srcSet={getImageSrcSet(poi.attributes.images.length > 0 ? poi.attributes.images[0] : undefined)}
                      >
                        <span className="sr-only">{poi.title}</span>
                      </ResponsiveImg>
                  }

                </div>
                <div className="col pl-2">
                  <h3 className="simple-poi-list__poi-title">{poi.attributes.title}</h3>
                  {/* <h4 className="simple-poi-list__poi-author">
                    {SIMPLE_FLAG_ICON}
                    {`${relatedOwner.attributes.name} ${relatedOwner.attributes.surname}`}
                  </h4> */}
                  <h4 className="simple-poi-list__poi-category">{categoryLabel}</h4>
                </div>
              </div>
            </button>
            {!isEmpty(addButtonCB) ? (
              <div className="col-auto px-0">
                <button
                  type="button"
                  className="simple-poi-list__add-btn"
                  onClick={e => (alreadyPresent ? e.preventDefault() : addButtonCB(e, poi))}
                >
                  {alreadyPresent ? CHECK_ICON : PLUS_ICON}
                </button>
              </div>
            ) : null}
          </article>
        );
      })}
    </section>
  );
}

SimplePoiList.propTypes = {
  onPoiClick: PropTypes.func.isRequired,
  addedPoiIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  results: PropTypes.arrayOf(PropTypes.object),
  addButtonCB: PropTypes.func,
};
SimplePoiList.defaultProps = {
  addedPoiIds: [],
  results: [],
  addButtonCB: null,
};

export default SimplePoiList;
