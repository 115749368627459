import React, {
  Component,
  // useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { push } from 'connected-react-router';
import reduxBeeMakeRequest from '../../utils/redux-bee/redux-bee-make-request';
import TourFile from '../../components/tour-file/index';
import Navbar from '../../components/navbar';
import TYPES from '../../constants/types';
import {
  isEmpty, isEmptyObject,
  //  isEmptyObject
} from '../../utils';
import { dispatch } from '../../reduxStore';
import { TOUR_INFO_DINAMIC_URL, TOUR_IMAGE_PAGE_DINAMIC_URL } from '../../constants';
// import useGetResource from '../../helpers/hooks/useGetResource';

// deploy forced

// untested functional version of the component

// const TourInfoPage = (props) => {
//   const { tourId, isMobile } = props;
//   const [activeDay, setActiveDay] = useState(1);
//   const handleDaySelection = (newDay) => {
//     setActiveDay(newDay);
//   };

//   const tourOnDisplay = useGetResource({id:tourId}, 'tours', 'getTour');

//   if (isEmptyObject(tourOnDisplay)) return null;

//   return (
//     <>
//       {isMobile ? (
//         <Navbar
//           alterBrand={(tourOnDisplay && tourOnDisplay.attributes && tourOnDisplay.attributes.title) || ''}
//         />
//       ) : null}
//       <TourFile tour={tourOnDisplay} activeDay={activeDay} handleDaySelection={handleDaySelection} />
//     </>
//   );
// };

class TourInfoPage extends Component {
  constructor(props) {
    super(props);
    this._requestedMissingResource = false;

    this.state = { activeDay: 1, storedScroll: 0 };

    this.handleDaySelection = this.handleDaySelection.bind(this);
    this.getRemoteTour = this.getRemoteTour.bind(this);
    this.handleImageModal = this.handleImageModal.bind(this);
  }

  componentDidMount() {
    const { getRemoteTour } = this;
    const { tourInEdition } = this.props;
    if (!tourInEdition) {
      getRemoteTour();
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedImage: prevSelectedImage } = prevProps;
    const { selectedImage: currentSelectedImage } = this.props;
    const { storedScroll } = this.state;
    if (!isEmpty(prevSelectedImage) && isEmpty(currentSelectedImage)) {
      window.scrollTo(0, storedScroll);
    }
  }

  getRemoteTour() {
    return new Promise((resolve, reject) => {
      const { urlTitle, tourId } = this.props;
      if (tourId !== 'new' && urlTitle !== 'new') {
        reduxBeeMakeRequest(dispatch, 'getTours', { 'filters[urlTitle]': urlTitle }).then(resolve);
        // reduxBeeMakeRequest(dispatch, 'getTour', { id: tourId }).then(resolve);
      } else {
        reject(new Error('tour id is not valid'));
      }
    });
  }

  handleDaySelection(newDay) {
    this.setState({ activeDay: newDay });
  }

  handleImageModal(imageId) {
    const { urlTitle, selectedImage } = this.props;

    if (isEmpty(imageId)) {
      dispatch(push(TOUR_INFO_DINAMIC_URL(urlTitle)));
    } else {
      // Store the current scroll to restore it when the image file modal will be closed.
      if (isEmpty(selectedImage)) {
        this.setState({ storedScroll: window.scrollY });
      }

      dispatch(push(TOUR_IMAGE_PAGE_DINAMIC_URL(urlTitle, imageId)));
    }
  }

  render() {
    const {
      tourInEdition, desktopImage, isMobile, selectedImage,
    } = this.props;
    const { activeDay } = this.state;
    const {
      attributes: {
        title: tourTitle = null,
        'previous-search-text': previousSearchText = null,
      } = {},
    } = tourInEdition || {};
    const {
      attributes: {
        'small-thumb': coverUrl = null,
      } = {},
    } = desktopImage || {};
    const metaImageUrl = isEmpty(coverUrl) ? `${window.location.origin}/assets/images/topdesti-logo.png` : coverUrl;

    return isEmpty(tourInEdition) ? null : (
      <>
        <Helmet>
          <title>{`| ${tourTitle}`}</title>
          <meta name="title" content={tourTitle} />
          <meta property="og:title" content={tourTitle} />
          <meta property="twitter:title" content={tourTitle} />
          {isEmpty(previousSearchText)
            ? null
            : <meta name="description" content={previousSearchText} />
          }
          {isEmpty(previousSearchText)
            ? null
            : <meta property="og:description" content={previousSearchText} />
          }
          {isEmpty(previousSearchText)
            ? null
            : <meta property="twitter:description" content={previousSearchText} />
          }
          <meta property="og:image" content={metaImageUrl} />
          <meta property="og:image:secure_url" content={metaImageUrl} />
          <meta property="twitter:image" content={metaImageUrl} />
          <meta property="og:image:width" content={isEmpty(coverUrl) ? '132' : '600'} />
          <meta property="og:image:height" content={isEmpty(coverUrl) ? '38' : '399'} />
        </Helmet>
        {isMobile ? <Navbar alterBrand={tourInEdition.attributes.title} /> : null}
        <TourFile
          tour={tourInEdition}
          selectedImage={selectedImage}
          activeDay={activeDay}
          handleDaySelection={this.handleDaySelection}
          handleImageModal={this.handleImageModal}
        />
      </>
    );
  }
}

TourInfoPage.propTypes = {
  tourId: PropTypes.string.isRequired,
  urlTitle: PropTypes.string.isRequired,
  tourInEdition: TYPES.tourType,
  desktopImage: TYPES.imageType,
  selectedImage: TYPES.imageType,
  isMobile: PropTypes.bool,
};

TourInfoPage.defaultProps = {
  tourInEdition: null,
  desktopImage: null,
  selectedImage: null,
  isMobile: false,
};

function mapStateToProps(state, ownProps) {
  const { match: { params: { urlTitle = null, imageId } } } = ownProps;
  const { bees: { entities: { tours = null, images: storedImages = null } } } = state;
  const selectedImage = isEmpty(imageId) || isEmpty(storedImages) ? null : storedImages[imageId];
  let tourInEdition;
  let tourId;

  if (!isEmptyObject(urlTitle) && !isEmptyObject(tours)) {
    tourInEdition = Object.entries(tours).reduce(
      (matchedTour, [cachedTourId, cacheTour]) => {
        const {
          attributes: {
            'url-title': cacheUrlTitle,
          } = {},
        } = cacheTour;
        if (cacheUrlTitle === urlTitle) {
          tourId = cachedTourId;
          return cacheTour;
        }
        return matchedTour;
      },
      null,
    );
  }

  const { relationships: { 'desktop-image': { data: desktopImageData = null } = {} } = {} } = tourInEdition || {};
  const desktopImage = isEmpty(desktopImageData) || isEmpty(storedImages) ? null : storedImages[desktopImageData.id];
  return {
    tourInEdition,
    urlTitle,
    tourId,
    selectedImage,
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    desktopImage,
  };
}

export default connect(mapStateToProps)(TourInfoPage);
