import { toastr } from 'react-redux-toastr';

function confirmationToastr(message, confirmCB, cancelCB, toasterConfirmOptions = {}) {
  const finalToastrConfirmOptions = {
    ...toasterConfirmOptions,
    onOk: confirmCB,
    onCancel: cancelCB,
  };
  if (toasterConfirmOptions) toastr.confirm(message, finalToastrConfirmOptions);
}

export default confirmationToastr;
