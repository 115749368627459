export default function generateReduxBeePayload(id, type, attributes, relationships = null) {
  const newPayload = {
    data: {
      type,
      id,
      attributes: {
        ...attributes,
      },
    },
  };
  if (relationships) newPayload.data.relationships = { ...relationships };
  return newPayload;
}
