import React from 'react';
// import PropTypes from 'prop-types';
import MainLayout from '../../layouts/main-layout';
import { LEFT_WIRE_IMG, RIGHT_WIRE_IMG } from '../../helpers';

const NotFoundPageTemplate = () => (
  <MainLayout>
    <main className="container not-found-page">

      <figure className="not-found-page__main-image-wrapper">
        <img className="not-found-page__main-image" src="/assets/images/not-found.svg" alt="Not found" />
      </figure>

      <div className="row align-items-center">
        <div className="col">
          <figure className="not-found-page__wire-image not-found-page__wire-image--left">{LEFT_WIRE_IMG}</figure>
        </div>
        <div className="col-auto">
          <h2 className="not-found-page__title">ERROR</h2>
          <p className="not-found-page__description not-found-page__description--primary">Página no encontrada</p>
          <p className="not-found-page__description not-found-page__description--grey">Page not found</p>
        </div>
        <div className="col">
          <figure className="not-found-page__wire-image not-found-page__wire-image--right">{RIGHT_WIRE_IMG}</figure>
        </div>
      </div>
    </main>
  </MainLayout>
);

export default NotFoundPageTemplate;
