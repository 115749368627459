import React, { useState, useEffect, useCallback } from 'react';
// import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import { toastr } from 'react-redux-toastr';
import ContentEditionModalTemplate from './content-edition-modal-template';
import { dispatch } from '../../../../reduxStore';
import VALIDATIONS from '../../../../utils/validations';
import {
  confirmationToastr,
  isEmptyObject,
  handleError,
  lockBodyScrolling,
  restoreBodyScrolling,
  isEmpty,
} from '../../../../utils';
import reduxBeeMakeRequest from '../../../../utils/redux-bee/redux-bee-make-request';
import {
  TourContentPoiForm,
  TourContentRecommendationForm,
  TourContentImagesForm,
  TourContentTextForm,
  TourContentVideoForm,
  TourContentMapForm,
  TourContentDurationForm,
  TourContentTipForm,
  ProductForm,
} from './parts';
import TYPES from '../../../../constants/types';
import { LOCALSTORAGE_SESSION } from '../../../../constants';

function ContentEditionModal(props) {
  const {
    contentInEdition,
    updateTourContentData,
    refreshTour,
    openTourContentsMenu,
    handleContentSelection,
    updateLocalContentState,
  } = props;
  let title;
  let DisplayedTemplate = null;
  let ValidationCB = null;

  const { id: localContentId, attributes: { 'content-type': contentType } = {} } = contentInEdition || {};

  const [localContent, setLocalContent] = useState(_cloneDeep(contentInEdition));
  const [isLocallyModified, setIsLocallyModified] = useState(false);

  const handleOnImageUpload = useCallback(() => {
    const apiMethodName = localContent.type === 'tourContents' ? 'getTourContent' : 'getProduct';
    reduxBeeMakeRequest(dispatch, apiMethodName, { id: localContentId }, {}).then(content =>
      updateLocalContentState(content),
    );
  }, [localContent.type, localContentId, updateLocalContentState]);

  useEffect(() => {
    lockBodyScrolling();
    return restoreBodyScrolling;
  }, []);

  useEffect(() => {
    // this should be invoked when the tour-content is PATCHed or GET with a reduxBee request...
    const immutableEditedTourContent = _cloneDeep(contentInEdition);
    setLocalContent(immutableEditedTourContent);
  }, [contentInEdition]);

  useEffect(() => {
    const { id: idInEdition, attributes: inEditionAttrs } = contentInEdition;
    const { id: localId, attributes: localAttributes } = localContent;
    if (idInEdition === localId && !_isEqual(inEditionAttrs, localAttributes)) {
      setIsLocallyModified(true);
    }
    if (idInEdition === localId && _isEqual(inEditionAttrs, localAttributes)) {
      setIsLocallyModified(false);
    }
  }, [contentInEdition, localContent]);

  const pendingLocalModifiedAttributesConfirmation = () =>
    new Promise((resolve) => {
      if (!isLocallyModified) resolve(true);
      else {
        confirmationToastr(
          '¿Tienes cambios pendientes por guardar, si continuas los perderás, estás segur@ de continuar?',
          () => resolve(true),
          () => resolve(false),
        );
      }
    });

  const handleToggleVisibility = ({ contentToToggle } = { contentToToggle: localContent }) => {
    const togglingParentTourContentVisibility = contentToToggle.id === localContent.id;
    if (togglingParentTourContentVisibility) {
      const immutableEditedTourContent = _cloneDeep(localContent);
      immutableEditedTourContent.attributes.visible = !immutableEditedTourContent.attributes
        .visible;
      setLocalContent(immutableEditedTourContent);
    }
    const attributes = { ...contentToToggle.attributes };
    attributes.visible = !attributes.visible;
    return reduxBeeMakeRequest(
      dispatch,
      'updateTourContent',
      { id: contentToToggle.id },
      { attributes },
    ).finally(() => Promise.resolve());
  };

  const handleDelete = async ({ contentToDelete } = { contentToDelete: localContent }) => {
    const deletingParentContent = contentToDelete.id === localContent.id;

    let canContinue = true;
    if (!deletingParentContent) canContinue = await pendingLocalModifiedAttributesConfirmation();

    return canContinue
      ? new Promise((resolve) => {
        confirmationToastr(
          '¿Estás seguro de eliminarlo?',
          () => {
            const { id: contentId, type } = contentToDelete;
            const deletePromise = type === 'tourContents'
              ? reduxBeeMakeRequest(dispatch, 'deleteTourContent', { id: contentId }, {})
                .then(() => refreshTour())
                .then(() =>
                  (deletingParentContent
                    ? handleContentSelection(null)
                    : reduxBeeMakeRequest(
                      dispatch,
                      'getTourContent',
                      { id: localContentId },
                      {},
                    ).then((updatedTourContent) => {
                      setLocalContent(updatedTourContent);
                    })),
                )
              : reduxBeeMakeRequest(dispatch, 'deleteProduct', { id: contentId }, {})
                .then(
                  () => reduxBeeMakeRequest(dispatch, 'getCurrentUser', null, {})
                    .catch(() => {
                      // should be impossible to get here
                      // the previous request fails if not authorized
                      // if authorized /me should not fail...
                      // here just as a fallback...

                      // if /me fails, logout the user
                      localStorage.removeItem(LOCALSTORAGE_SESSION);
                      // and refresh the page
                      window.location.reload();
                    }),
                )
                .then(() => handleContentSelection(null));

            return deletePromise.catch(() => {
              toastr.error('Error', 'No se pudo eliminar el contenido');
            });
          },
          () => resolve(false),
        );
      })
      : Promise.resolve(false);
  };

  const handleOpenTourContentMenu = () => {
    pendingLocalModifiedAttributesConfirmation().then((canContinue) => {
      if (canContinue) {
        openTourContentsMenu();
      }
    });
  };

  const handleSave = async (skipClose = false) => {
    const {
      id: selectedId,
      type,
      attributes: { 'content-type': localContentType = null },
      relationships: { 'tour-content': { data: tourContentData = null } = {} } = {},
    } = localContent;
    const skipCloseModal = skipClose
      || (isEmpty(selectedId) && (type === 'products' || parseInt(localContentType, 10) === 8));

    if (!isEmptyObject(ValidationCB)) {
      const [isValid, ErrorMsg] = await ValidationCB(localContent);

      if (!isValid) {
        return Promise.reject(handleError(new Error(ErrorMsg)));
      }
    }

    const params = isEmpty(selectedId)
      ? isEmpty(tourContentData)
        ? null
        : { id: tourContentData.id }
      : { id: selectedId };
    const isTourContent = type === 'tourContents';
    const createMethodName = isTourContent ? 'createTourContent' : 'createProduct';
    const updateMethodName = isTourContent ? 'updateTourContent' : 'updateProduct';
    const methodName = isEmpty(selectedId)
      ? isEmpty(tourContentData)
        ? createMethodName
        : 'createTourContentChild'
      : updateMethodName;

    return reduxBeeMakeRequest(dispatch, methodName, params, localContent)
      .then((updatedContent) => {
        updateTourContentData(updatedContent)
          .then(() =>
            (isTourContent
              ? refreshTour()
              : reduxBeeMakeRequest(dispatch, 'getCurrentUser', null, {})
                .catch(() => {
                  // should be impossible to get here
                  // the previous request fails if not authorized
                  // if authorized /me should not fail...
                  // here just as a fallback...

                  // if /me fails, logout the user
                  localStorage.removeItem(LOCALSTORAGE_SESSION);
                  // and refresh the page
                  window.location.reload();
                })
            ),
          )
          .then(() =>
            (skipCloseModal === true
              ? updateLocalContentState(updatedContent)
              : handleContentSelection(null)),
          )
          .then(() => toastr.success('Se han guardado los cambios correctamente.'))

          .finally(() => updatedContent);
      }).catch((err) => {
        toastr.error('Error', 'No se pudieron guardar los cambios');
      });
  };

  const handleCloseModal = async () => {
    const canContinue = await pendingLocalModifiedAttributesConfirmation();
    if (canContinue) {
      handleContentSelection(null);
    }
  };

  if (contentInEdition.type === 'tourContents') {
    switch (contentType) {
      case 1:
        title = 'Editar destino';
        DisplayedTemplate = (
          <TourContentPoiForm
            localTourContent={localContent}
            setLocalTourContent={setLocalContent}
            refreshTour={refreshTour}
            handleOnImageUpload={handleOnImageUpload}
            handleToggleVisibility={handleToggleVisibility}
            handleDelete={handleDelete}
            handleOpenTourContentMenu={handleOpenTourContentMenu}
            handleCloseModal={handleCloseModal}
            handleContentSelection={handleContentSelection}
            handleSave={handleSave}
            pois={props.pois}
          />
        );
        break;
      case 2:
        title = 'Editar recomendación';
        DisplayedTemplate = (
          <TourContentRecommendationForm
            localTourContent={localContent}
            handleToggleVisibility={handleToggleVisibility}
            handleDelete={handleDelete}
            tourContentInEdition={contentInEdition}
            setLocalTourContent={setLocalContent}
            handleOnImageUpload={handleOnImageUpload}
            handleSave={handleSave}
          />
        );
        ValidationCB = VALIDATIONS.isValidServiceTourContent;
        break;
      case 3:
        // title = 'Añadir/ tomar imágenes';
        title = 'Editar imágenes personales';
        DisplayedTemplate = (
          <TourContentImagesForm
            localTourContent={localContent}
            handleToggleVisibility={handleToggleVisibility}
            handleDelete={handleDelete}
            handleOnImageUpload={handleOnImageUpload}
          />
        );
        break;
      case 4:
        title = 'Editar bloque de texto';
        DisplayedTemplate = (
          <TourContentTextForm
            localTourContent={localContent}
            setLocalTourContent={setLocalContent}
            handleToggleVisibility={handleToggleVisibility}
            handleDelete={handleDelete}
          />
        );
        ValidationCB = VALIDATIONS.isValidTextTourContent;
        break;
      case 5:
        title = 'Editar video';
        DisplayedTemplate = (
          <TourContentVideoForm
            localTourContent={localContent}
            setLocalTourContent={setLocalContent}
            handleToggleVisibility={handleToggleVisibility}
            handleDelete={handleDelete}
          />
        );
        ValidationCB = VALIDATIONS.isValidVideoTourContent;
        break;
      case 6:
        title = 'Localización GPS';
        DisplayedTemplate = (
          <TourContentMapForm
            localTourContent={localContent}
            setLocalTourContent={setLocalContent}
            handleToggleVisibility={handleToggleVisibility}
            handleDelete={handleDelete}
          />
        );
        ValidationCB = VALIDATIONS.isValidMapTourContent;
        break;
      case 7:
        title = 'Editar duración';
        DisplayedTemplate = (
          <TourContentDurationForm
            localTourContent={localContent}
            setLocalTourContent={setLocalContent}
            handleToggleVisibility={handleToggleVisibility}
            handleDelete={handleDelete}
          />
        );
        ValidationCB = VALIDATIONS.isValidDurationTourContent;
        break;
      case 8:
        title = 'Editar consejo';
        DisplayedTemplate = (
          <TourContentTipForm
            localTourContent={localContent}
            handleToggleVisibility={handleToggleVisibility}
            handleDelete={handleDelete}
            setLocalTourContent={setLocalContent}
            handleOnImageUpload={handleOnImageUpload}
            handleSave={handleSave}
          />
        );
        ValidationCB = VALIDATIONS.isValidTipTourContent;
        break;
      default:
        break;
    }
  } else {
    title = 'Editar producto';
    DisplayedTemplate = (
      <ProductForm
        localContent={localContent}
        handleToggleVisibility={handleToggleVisibility}
        handleDelete={handleDelete}
        setLocalContent={setLocalContent}
        handleOnImageUpload={handleOnImageUpload}
        handleSave={handleSave}
      />
    );
    ValidationCB = VALIDATIONS.isValidProduct;
  }

  return (
    <ContentEditionModalTemplate
      localContent={localContent}
      title={title}
      handleDelete={handleDelete}
      handleSave={handleSave}
      handleCloseModal={handleCloseModal}
      isLocallyModified={isLocallyModified}
    >
      {contentInEdition.id !== localContent.id ? null : DisplayedTemplate || null}
    </ContentEditionModalTemplate>
  );
}

ContentEditionModal.propTypes = {
  updateTourContentData: PropTypes.func.isRequired,
  updateLocalContentState: PropTypes.func.isRequired,
  refreshTour: PropTypes.func.isRequired,
  contentInEdition: PropTypes.oneOfType([TYPES.productType, TYPES.tourContentType]),
  openTourContentsMenu: PropTypes.func.isRequired,
  handleContentSelection: PropTypes.func.isRequired,
};

ContentEditionModal.defaultProps = {
  contentInEdition: null,
};

export default ContentEditionModal;
