import { dispatch } from '../../reduxStore';
import reduxBeeMakeRequest from './redux-bee-make-request';


export default function generateCacheResources(requestDescribers) {
  return new Promise((resolve) => {
    const PromiseArray = [];
    requestDescribers.forEach((reqDesc) => {
      PromiseArray.push(reduxBeeMakeRequest(dispatch, reqDesc.endpoint, { id: reqDesc.id }));
    });
    Promise.all(PromiseArray).then(resolve);
  });
}
