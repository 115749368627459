import React, { Component } from 'react';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import { toastr } from 'react-redux-toastr';
import Dropzone from 'react-fine-uploader/dropzone';

import { PropTypes } from 'prop-types';
import FileInput from 'react-fine-uploader/file-input';
import ENV from '../../config/environment';
import BootstrapLoader from '../loaders/bootstrap-loader';
import ThumbnailsWrapper from './parts/thumbnails-wrapper/thumbnails-wrapper';
import authHeader from '../../helpers/auth-header';
import { isEmpty, isEmptyObject } from '../../utils';

class FineUploader extends Component {
  static async checkCanContinue({ canContinueFunc, event }) {
    const canContinue = await canContinueFunc().then(() => true).catch(() => false);

    if (!canContinue) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      lastQueuedFile: null,
      uploaderFiles: {},
      filesProgresses: {},
      filesThumbnails: {},
      totalProgress: 0,
      loading: false,
      uploadGoingOn: false,
    };
    
    this._isMounted = false;
    this.backEndBaseUrl = `${ENV.host}/${ENV.namespace}`;

    this.removeFromQueueCB = this.removeFromQueueCB.bind(this);
    this.uploadBtnCB = this.uploadBtnCB.bind(this);
    this.allFilesProgressBar = this.allFilesProgressBar.bind(this);
    this.FileProgressBar = this.FileProgressBar.bind(this);
    this.setStatePromise = this.setStatePromise.bind(this);
    this.onProcessingDroppedFilesComplete = this.onProcessingDroppedFilesComplete.bind(this);
    this.onProcessingDroppedFiles = this.onProcessingDroppedFiles.bind(this);
    this.uploadBtnCB = this.uploadBtnCB.bind(this);
    this.renderUserInterface = this.renderUserInterface.bind(this);
    const {
      multiple = true,
      autoUpload = false,
      chunking = false,
      maxConnections = 1,
      inputName = 'image',
      params = {},
      paramsInBody = true, // parameters in body, if false send param as GET query params
      method = 'POST',
      clearStoredFilesOnComplete = true,
      removeThumbAfter,
      endpoint,
      withProgressBars,
      onTotalProgress,
      onProgress,
      onStatusChange,
      onUpload,
      onComplete,
      onAllComplete,
    } = this.props;
    this.uploaderBaseConfig = {
      options: {
        multiple,
        autoUpload,
        chunking: {
          enabled: chunking,
        },
        maxConnections,
        request: {
          customHeaders: {
            Authorization: authHeader(),
          },
          inputName,
          params,
          paramsInBody,
          method,
          endpoint: `${this.backEndBaseUrl}${endpoint}`,
        },
        cors: {
          expected: true,
          sendCredentials: true,
        },
        callbacks: {
          onTotalProgress: (totalUploadedBytes, totalBytes) => {
            const percentage = ((100 * totalUploadedBytes) / totalBytes).toFixed(0);
            if (onTotalProgress) {
              onTotalProgress(totalUploadedBytes, totalBytes, percentage);
            }
            return this.allFilesProgressBar({ percentage });
          },
          onProgress: (id, name, uploadedBytes, totalBytes) => {
            if (withProgressBars) {
              const percentage = ((100 * uploadedBytes) / totalBytes).toFixed(0);
              // send the progress callback to parent with additional percentage calculation
              if (onProgress) {
                onProgress({
                  id,
                  name,
                  percentage,
                  uploadedBytes,
                  totalBytes,
                });
              }
              return this.FileProgressBar({ id, percentage });
            }
            return Promise.resolve;
          },
          onUpload: (id, name) => {
            if (onUpload) onUpload(id, name);
            if (!multiple) {
              const { uploaderFiles, filesThumbnails } = this.state;
              const uploaderfile = uploaderFiles[`file${id}`];
              const browserApiFile = uploaderfile.file;
              const newThumbnails = { ...filesThumbnails };
              return FineUploader.getThumbnailDataPromise(browserApiFile).then((thumbnailData) => {
                newThumbnails[name] = thumbnailData;
                return this.setStatePromise({ filesThumbnails: newThumbnails });
              });
            }
            return Promise.resolve();
          },
          onStatusChange: (id, oldStatus, newStatus) => {
            const newState = this.getUpdatedQueue();
            this.setStatePromise({ ...newState });
            if (onStatusChange) onStatusChange(id, oldStatus, newStatus);
          },
          onComplete: (id, name, response) => {
            if (this.props.imageUploadName) {
              this.props.imageUploadName(response)
            }
            if (this.props.imageUploadNameBanner) {
              this.props.imageUploadNameBanner(response)
            }
            if (this.props.imageUploadNameBanner) {
              this.props.imageUploadNameBanner(response)
            }
            if (this.props.imageUploadNameLogo) {
              this.props.imageUploadNameLogo(response)
            }
            if (this.props.imageUpload != undefined) {
              this.props.imageUpload();
            }
            const clearStoredFiles = clearStoredFilesOnComplete;
            if (clearStoredFiles) {
              this.uploader.methods.clearStoredFiles();
            }
            if (!isEmpty(removeThumbAfter)) {
              setTimeout(() => {
                this.ClearFileThumbnail({ id, name });
              }, removeThumbAfter);
            }
            if (onComplete) onComplete(id, name, response);
          },
          onAllComplete: () => {
            if (onAllComplete) onAllComplete();
            this.setStatePromise({ uploadGoingOn: false });
          },
          onSubmitted: () => {
            if (autoUpload) this.setStatePromise({ uploadGoingOn: true });
          },
          onError: (id, name, errorReason, xhrOrXdr) => {
            this.ClearFileThumbnail({ id, name });
            const { responseText } = xhrOrXdr;
            if (responseText) {
              const errorJson = JSON.parse(responseText);
              const errorDetail = errorJson.errors[0].detail;
              toastr.error('Error', errorDetail);
            } else {
              toastr.error(
                'Error',
                `Error on file number ${id} - ${name}.  Reason: ${errorReason}`,
              );
            }
          },
        },
      },
    };
    this.uploader = new FineUploaderTraditional(this.uploaderBaseConfig);
  }

  // componentDidUpdate(prevProps, prevState) {}
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getThumbnailDataPromise(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const { result } = reader;
          resolve(result);
        };
      } else {
        reject(new Error('no file received'));
      }
    });
  }

  static getUploaderFilesThumbnailsPromise(files) {
    const thumbnailsDatas = {};
    const thumbnailsPromises = [];
    files.forEach((file) => {
      thumbnailsPromises.push(
        FineUploader.getThumbnailDataPromise(file).then((thumbnailData) => {
          thumbnailsDatas[file.name] = thumbnailData;
          return Promise.resolve();
        }),
      );
    });
    return Promise.all(thumbnailsPromises).then(() => ({ filesThumbnails: thumbnailsDatas }));
  }


  onProcessingDroppedFiles() {
    const { multiple } = this.props;
    if (!multiple) {
      this.uploader.methods.cancelAll();
    }
    return Promise.resolve();
  }

  onProcessingDroppedFilesComplete(files, dropTarget) {
    const { onProcessingDroppedFilesComplete, multiple } = this.props;
    if (onProcessingDroppedFilesComplete) onProcessingDroppedFilesComplete(this.uploader, files, dropTarget);
    const fileQueue = this.getUpdatedQueue();
    if (!multiple) {
      return this.setStatePromise({
        ...fileQueue,
      });
    }

    return FineUploader.getUploaderFilesThumbnailsPromise(files).then(filesThumbnails =>
      this.setStatePromise({ ...fileQueue, ...filesThumbnails }),
    );
  }

  static onDropError(errorCode, errorRelatedData) {
    if (errorCode === 'tooManyFilesError') {
      toastr.error('Error', 'Se puede subir solo una imagen a la vez');
    } else {
      toastr.error('Error', `Error ${errorCode}: ${errorRelatedData}`);
    }
  }

  getUpdatedQueue() {
    const { status } = this.uploader.qq;
    const currentDroppedFiles = this.uploader.methods.getUploads({
      status: [status.SUBMITTING, status.UPLOADING, status.QUEUED],
    });
    const uploaderFiles = {};
    const filesProgresses = {};
    const { multiple } = this.props;
    if (!multiple) {
      let lastQueuedFile;
      if (currentDroppedFiles.length === 1) {
        lastQueuedFile = currentDroppedFiles.pop();
        uploaderFiles[`file${lastQueuedFile.id}`] = lastQueuedFile;
        filesProgresses[`file${lastQueuedFile.id}`] = {
          percentage: null,
        };
      }
      return { uploaderFiles, filesProgresses, lastQueuedFile };
    }
    currentDroppedFiles.forEach((currFile) => {
      uploaderFiles[`file${currFile.id}`] = currFile;
      filesProgresses[`file${currFile.id}`] = {
        percentage: null,
      };
    });
    const lastQueuedFile = currentDroppedFiles.pop();
    return { uploaderFiles, filesProgresses, lastQueuedFile };
  }


  setStatePromise(newState) {
    return new Promise((resolve) => {
      if (this._isMounted) this.setState(newState, resolve);
    });
  }

  // receives an object containg:
  //    "id" ---> id of fineUploader file
  //    "name" ---> id of fineUploader file
  ClearFileThumbnail({ name }) {
    const updatedQueue = this.getUpdatedQueue();
    const { onThumbnailCleared } = this.props;
    const { filesThumbnails } = this.state;
    const newThumbnails = { ...filesThumbnails };
    delete newThumbnails[name];
    return this.setStatePromise({ ...updatedQueue, filesThumbnails: newThumbnails }).then(() => {
      if (onThumbnailCleared) onThumbnailCleared();
      return Promise.resolve();
    });
  }

  // Given a file id, handle it's progress bar percentage
  FileProgressBar({ id, percentage }) {
    const { filesProgresses } = this.state;
    const newFileProgresses = { ...filesProgresses };
    newFileProgresses[`file${id}`] = {
      percentage,
    };
    return this.setStatePromise({
      filesProgresses: newFileProgresses,
    });
  }

  allFilesProgressBar({ percentage }) {
    return this.setStatePromise({
      totalProgress: percentage,
    });
  }

  uploadBtnCB() {
    // infos on methods invokeable by this.uploader.methods check out
    // https://docs.fineuploader.com/branch/master/api/methods.html
    const { uploadBtnCB } = this.props;
    if (uploadBtnCB) uploadBtnCB(this.uploader);
    else this.uploader.methods.uploadStoredFiles();
  }

  // can receive event, id of removed file, file (browser api file object)
  removeFromQueueCB(e, id) {
    const { uploader } = this;
    uploader.methods.cancel(id);
  }

  renderUserInterface() {
    const {
      uploaderFiles,
      filesProgresses,
      filesThumbnails,
      totalProgress,
      loading, // unused, we use progress bars instead
      lastQueuedFile,
      uploadGoingOn,
    } = this.state;
    const {
      withProgressBars,
      withUploadBtn,
      children,
      withTotalProgressBar,
      multiple,
      isDropzone,
      totalProgressBarType,
    } = this.props;

    const { uploadBtnCB, removeFromQueueCB, uploader } = this;
    const noUploadedFiles = Object.keys(uploaderFiles).length === 0;
    if (loading) {
      // unused, we use progress bars instead
      return <BootstrapLoader />;
    }
    if (isDropzone && noUploadedFiles) {
      return (
        <>
          {children}
          {withUploadBtn ? (
            <div className="col-12">
              <FileInput
                className="fine-uploader__upload-input"
                multiple={multiple}
                accept="image/*"
                uploader={uploader}
              >
                {this.props.addBlue ? (
                  <img
                    width="50"
                    className=""
                    alt="button"
                    src='../../../assets/images/anadir_azul.svg'
                  />
                ) : (
                    <button type="button" className="btn btn-primary btn-block">
                      Subir imágenes
                    </button>
                  )}
              </FileInput>
            </div>
          ) : null}
        </>
      );
    }
    return (
      <ThumbnailsWrapper
        uploadGoingOn={uploadGoingOn}
        lastQueuedFile={lastQueuedFile}
        multiple={multiple}
        uploaderFiles={uploaderFiles}
        withTotalProgressBar={withTotalProgressBar}
        totalProgress={totalProgress}
        filesProgresses={filesProgresses}
        filesThumbnails={filesThumbnails}
        withProgressBars={withProgressBars}
        withUploadBtn={withUploadBtn}
        uploadBtnCB={uploadBtnCB}
        removeFromQueueCB={removeFromQueueCB}
        uploader={uploader}
        isDropzone={isDropzone}
        totalProgressBarType={totalProgressBarType}
      >
        <>
          {children}
          {withUploadBtn ? (
            <div className="col-12">
              <FileInput
                className="fine-uploader__upload-input"
                multiple
                accept="image/*"
                uploader={uploader}
              >
                <button type="button" className="btn btn-primary btn-block">
                  Subir imágenes
                </button>
              </FileInput>
            </div>
          ) : null}
        </>
      </ThumbnailsWrapper>
    );
  }


  render() {
    const { uploader, onProcessingDroppedFilesComplete, onProcessingDroppedFiles } = this;
    const { onDropError } = FineUploader;
    const {
      multiple,
      isDropzone,
      className,
      onBeforeUpload,
      params
    } = this.props;
    

    let interceptUserUploadGotcha = {};
    if (!isEmptyObject(onBeforeUpload)) {
      if (isDropzone) {
        throw new Error(`Due to react-fine-uploader limitations a dropzone (or any of it's children)
         can't catch onDrop, onDragOver onDragEnter.
         as such there is no way for drop zone to implement onBeforeUpload`);
      }
      interceptUserUploadGotcha = {
        // onDrop is not catched if onDragOver and onDragEneter isn't stopped...
        onDragOver: (e) => { e.stopPropagation(); e.preventDefault(); },
        onDragEnter: (e) => { e.stopPropagation(); e.preventDefault(); },
        onDrop: (e) => {
          e.persist(); // this is required due to how synteticEvents works in reactjs
          FineUploader.checkCanContinue({ canContinueFunc: () => onBeforeUpload(e), event: e });
        },
        onClick: (e) => {
          e.persist(); // this is required due to how synteticEvents works in reactjs
          FineUploader.checkCanContinue({ canContinueFunc: () => onBeforeUpload(e), event: e });
        },
      };
    }

    if (isDropzone) {
      return (
        <>
          <Dropzone
            uploader={uploader}
            className={`dropzone ${className || ''}`}
            multiple={multiple}
            onProcessingDroppedFilesComplete={onProcessingDroppedFilesComplete}
            onProcessingDroppedFiles={onProcessingDroppedFiles}
            onDropError={onDropError}
            {...interceptUserUploadGotcha}
          >
            {this.renderUserInterface()}
          </Dropzone>
        </>
      );
    }
    return (
      <div
        className={`fileInput ${className || ''}`}
        {...interceptUserUploadGotcha}
      >
        {this.renderUserInterface()}
      </div>
    );
  }
}
FineUploader.propTypes = {
  endpoint: PropTypes.string.isRequired,
  isDropzone: PropTypes.bool,
  multiple: PropTypes.bool,
  autoUpload: PropTypes.bool,
  chunking: PropTypes.bool,
  withTotalProgressBar: PropTypes.bool,
  withProgressBars: PropTypes.bool,
  paramsInBody: PropTypes.bool, // parameters in body, if false send param as GET query params
  clearStoredFilesOnComplete: PropTypes.bool,
  withUploadBtn: PropTypes.bool,
  maxConnections: PropTypes.number,
  removeThumbAfter: PropTypes.number,
  inputName: PropTypes.string,
  method: PropTypes.string,
  params: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  ),
  children: PropTypes.element.isRequired,
  onProcessingDroppedFilesComplete: PropTypes.func,
  onTotalProgress: PropTypes.func,
  onProgress: PropTypes.func,
  onUpload: PropTypes.func,
  onStatusChange: PropTypes.func,
  onComplete: PropTypes.func,
  onAllComplete: PropTypes.func,
  onThumbnailCleared: PropTypes.func,
  uploadBtnCB: PropTypes.func,
  totalProgressBarType: PropTypes.string,
  className: PropTypes.string,

  onBeforeUpload: PropTypes.func,
};
FineUploader.defaultProps = {
  isDropzone: true,
  multiple: true,
  autoUpload: false,
  chunking: false,
  clearStoredFilesOnComplete: true,
  paramsInBody: true, // parameters in body, if false send param as GET query params
  withTotalProgressBar: false,
  withProgressBars: true,
  withUploadBtn: false,
  maxConnections: 1,
  removeThumbAfter: null,
  inputName: 'image',
  params: {},
  method: 'POST',
  onProcessingDroppedFilesComplete: null,
  onTotalProgress: null,
  onProgress: null,
  onUpload: null,
  onStatusChange: null,
  onComplete: null,
  onAllComplete: null,
  onThumbnailCleared: null,
  uploadBtnCB: null,
  totalProgressBarType: 'circular',
  className: '',

  onBeforeUpload: null,
};
export default FineUploader;
