import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { history } from '../helpers';
import MainLayout from '../layouts/main-layout';
import {
  REGISTER_ENDPOINT, LOGIN_URL, PRIVACY_URL, TERM_CONDITIONS_URL,
} from '../constants';
import PromiseButton from '../components/promise-button';

function handleResponse(response) {
  if (!response.ok) {
    return response.json().then((jsonResponse) => {
      const mappedErrors = {};
      jsonResponse.errors.forEach((error) => {
        if (error.source && error.detail) {
          mappedErrors[`${error.source.pointer.slice(17)}`] = error.detail;
        }
      });
      return Promise.reject(mappedErrors);
    });
  }
  return Promise.resolve();
}

class RegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      surname: '',
      nickname: '',
      email: '',
      emailConfirmation: '',
      password: '',
      passwordConfirmation: '',
      isAcceptedConditions: false,
      isLoading: false,
      errors: {},
      showForm: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderInfos = this.renderInfos.bind(this);
    this.showForm = this.showForm.bind(this);
  }

  showForm() {
    this.setState({ showForm: true });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleCheckboxChange(e) {
    const { checked } = e.target;
    this.setState({ isAcceptedConditions: checked });
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      name, surname, nickname, email, emailConfirmation, password, passwordConfirmation, isAcceptedConditions,
    } = this.state;

    this.setState({ isLoading: true });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name,
        surname,
        nickname,
        email,
        emailConfirmation,
        password,
        passwordConfirmation,
        acceptConditions: isAcceptedConditions,
      }),
    };

    return fetch(`${REGISTER_ENDPOINT}`, requestOptions)
      .then(response => handleResponse(response))
      .then(() => {
        history.push(LOGIN_URL);
      })
      .catch((errors) => {
        this.setState({ isLoading: false, errors });
      });
  }

  renderInfos() {
    const { showForm } = this;
    return (
      <>
        <div className="col-11 col-md-6 b-right register-option pb-2 mx-auto mb-2">
          <img src="/assets/images/viajeros_gratis.png" alt="travelers" className="mb-3" />
          <p>Viajero: Registro gratuito.</p>
          <p className="mb-5">
            Da de alta tu perfil de forma completamente gratuita y empieza a crear tus mejores rutas de
            viaje. Recuerda que es una versión beta, exclusiva de la isla de Gran Canaria y sin que
            estén disponibles de momento todas las funcionalidades que iremos incorporando para
            ayudarte a planificar y difundir tus viajes de la mejor forma.
          </p>
          <button type="button" className="btn btn-block btn-primary mb-2 mx-auto" onClick={showForm}>
            Nuevo viajero
          </button>
        </div>
        <div className="col-11 col-md-6 register-option not-allowed pb-2 mx-auto">
          <img src="/assets/images/perfil_profesional.png" alt="hotel building" className="mb-3" />
          <p>Perfil Profesional.</p>
          <p>
            Conozca las ventajas del perfil profesional para promocionar sus servicios y ofrecer contenido de
            la mejor calidad a sus clientes.
          </p>
          <p className="price">
            Negocio turístico
            {' '}
            <span>100€ / año</span>
          </p>
          <p className="price">
            Entidad sin ánimo de lucro
            {' '}
            <span>gratuito</span>
          </p>
          <button type="button" className="btn btn-block btn-primary mb-2 mx-auto">
            + Información
          </button>
          <button type="button" className="btn btn-block btn-primary mb-2 mx-auto">
            Perfil Profesional
          </button>
        </div>
      </>
    );
  }

  renderForm() {
    const {
      name,
      surname,
      nickname,
      email,
      emailConfirmation,
      password,
      passwordConfirmation,
      isAcceptedConditions,
      isLoading,
      errors,
    } = this.state;

    return (
      <div className="col-12 col-md-7 mx-auto">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="name" className="d-block">
              <input
                name="name"
                type="text"
                className={`form-control${errors.name ? ' is-invalid' : ''}`}
                placeholder="NOMBRE"
                value={name}
                onChange={this.handleChange}
                required
              />
              {errors.name ? <span className="d-block text-danger">{errors.name}</span> : null}
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="surname" className="d-block">
              <input
                name="surname"
                type="text"
                className={`form-control${errors.surname ? ' is-invalid' : ''}`}
                placeholder="APELLIDO"
                value={surname}
                onChange={this.handleChange}
                required
              />
              {errors.surname ? <span className="d-block text-danger">{errors.surname}</span> : null}
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="nickname" className="d-block">
              <input
                name="nickname"
                type="text"
                className={`form-control${errors.nickname ? ' is-invalid' : ''}`}
                placeholder="NOMBRE USUARIO *"
                value={nickname}
                onChange={this.handleChange}
                required
              />
              <span className="register-page__input-extra-info">
              * Tu nickname será el nombre de la dirección de tu cuenta en Topdesti (www.topdesti.com/user/nickname).
              Es único y una vez seleccionado no podrá volver a ser editado,
               así que asegúrate de elegir el que más te guste.
              </span>
              {errors.nickname ? <span className="d-block text-danger">{errors.nickname}</span> : null}
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="email" className="d-block">
              <input
                name="email"
                type="email"
                placeholder="EMAIL"
                className={`form-control${errors.email ? ' is-invalid' : ''}`}
                value={email}
                onChange={this.handleChange}
                required
              />
              {errors.email ? <span className="d-block text-danger">{errors.email}</span> : null}
            </label>
          </div>

          <div className="form-group">
            <label htmlFor="emailConfirmation" className="d-block">
              <input
                name="emailConfirmation"
                type="email"
                placeholder="REPETIR EMAIL"
                className={`form-control${errors['email-confirmation'] ? ' is-invalid' : ''}`}
                value={emailConfirmation}
                onChange={this.handleChange}
                required
              />
              {errors['email-confirmation'] ? (
                <span className="d-block text-danger">{errors['email-confirmation']}</span>
              ) : null}
            </label>
          </div>

          <div className="form-group">
            <label htmlFor="password" className="d-block">
              <input
                name="password"
                type="password"
                placeholder="CONTRASEÑA"
                className={`form-control${errors.password ? ' is-invalid' : ''}`}
                value={password}
                onChange={this.handleChange}
                required
              />
              {errors.password ? <span className="d-block text-danger">{errors.password}</span> : null}
            </label>
          </div>

          <div className="form-group">
            <label htmlFor="passwordConfirmation" className="d-block">
              <input
                name="passwordConfirmation"
                type="password"
                placeholder="REPETIR CONTRASEÑA"
                className={`form-control${errors['password-confirmation'] ? ' is-invalid' : ''}`}
                value={passwordConfirmation}
                onChange={this.handleChange}
                required
              />
              {errors['password-confirmation'] ? (
                <span className="d-block text-danger">{errors['password-confirmation']}</span>
              ) : null}
            </label>
          </div>

          <div className="form-groud">
            <label
              htmlFor="conditions"
              className="custom-checkbox text-standard-small"
            >
              He leído y acepto las
              <Link to={TERM_CONDITIONS_URL}> condiciones de uso </Link>
              y
              <Link to={PRIVACY_URL}> política de privacidad</Link>
              <input
                type="checkbox"
                id="conditions"
                onChange={this.handleCheckboxChange}
                // defaultChecked={false}
                checked={isAcceptedConditions}
              />
              <i />
            </label>
          </div>

          <PromiseButton
            type="submit"
            className="btn btn-block btn-primary mt-3"
            loadingCondition={isLoading}
            disabled={!isAcceptedConditions}
          >
            Enviar
          </PromiseButton>
        </form>
      </div>
    );
  }

  render() {
    const { renderForm, renderInfos } = this;
    const { showForm } = this.state;

    return (
      <MainLayout>
        <Helmet>
          <title>: Regístrate en Topdesti</title>
          <meta name="title" content="Topdesti: Regístrate en Topdesti" />
          <meta property="og:title" content="Topdesti: Regístrate en Topdesti" />
          <meta property="twitter:title" content="Topdesti: Regístrate en Topdesti" />
          <meta
            name="description"
            content="Registrate de forma 100% gratuita para empezar a ganar dinero con tus viajes."
          />
          <meta
            property="og:description"
            content="Registrate de forma 100% gratuita para empezar a ganar dinero con tus viajes."
          />
          <meta
            property="twitter:description"
            content="Registrate de forma 100% gratuita para empezar a ganar dinero con tus viajes."
          />
          <meta property="of:image" content={`${window.location.origin}/assets/images/topdesti-logo.png`} />
          <meta property="twitter:image" content={`${window.location.origin}/assets/images/topdesti-logo.png`} />
          <meta property="og:image:width" content="132" />
          <meta property="og:image:height" content="38" />
        </Helmet>
        <div className="container-fluid grey-bg">
          <div className="row">
            <div className="container col-12 col-lg-8 col-xl-6">
              <div className="row intro-box pb-5">
                <div className="col-12 mx-auto">
                  <h3 className="text-center mb-4">Nuevo Usuario</h3>
                </div>
                {showForm ? renderForm() : renderInfos()}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const connectedRegisterPage = connect()(RegisterPage);

export default connectedRegisterPage;
