import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sortableContainer, sortableElement, SortableHandle } from 'react-sortable-hoc';
import DatePicker from 'react-datepicker';
import TYPES from '../../../../../constants/types';
import { isEmpty, getImageSrcSet, confirmationToastr } from '../../../../../utils';
import { PromiseButton, FineUploader, ResponsiveImg, ValidationInput } from '../../../../../components';
import {
  CLOSE_ICON, PLUS_ICON, ANGEL_ICON, CHECK_ICON, ROUTE_ICON, LOGO_YOUTUBE_ICON, LOGO_VIMEO_ICON, CIRCLE_CROSS_ICON, UPLOAD_CLOUD_URL,
  DROP_COLOR_URL, PENCIL_ICON,
} from '../../../../../helpers';
import { Link } from 'react-router-dom'
import moment from 'moment'
import ENV from '../../../../../config/environment';
import Textarea from 'react-textarea-autosize';
import ReactQuill from 'react-quill';
import { uuid } from 'uuidv4';
import { toastr } from 'react-redux-toastr';

const SortableContainer = sortableContainer(({ children }) => (
  <ul className="tour-config-sponsor__photos-list">{children}</ul>
));

const SortableItem = sortableElement(({ children }) => (
  <li className="tour-config-sponsor__photos-item">{children}</li>
));

const DragHandle = SortableHandle(({ children }) => <>{children}</>);

const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];

class TourConfigSponsorTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptionsProfileModal: false,
      activeBtn: 1,
      activeBtn2: 2,
      defaultView: false,
      addCampaign: false,
      assignCampaign: true,
      seeCampaign: false,
      campaignName: "",
      title: "",
      shortText: "",
      text: "",
      link: "",
      linkVideo: "",
      logoCampaign: '',
      bannerCampaign: '',
      images: [],
      idCampaignExterna: "",
      pois: [],
      poi: {},
      sponsorPoi: [],
      campaigns: [],
      campaignSelectable: '',
      currentPoiCampaign: '',
      currentCampaign: false,
      galleryCampaign: [],
    };
  }
  componentDidMount() {
    if (this.props.currentCampaign) {
      this.setState({
        currentCampaign: this.props.currentCampaign,
        campaignSelectable: this.props.currentCampaign.id
      })
    }
  }
  componentDidUpdate(PrevProps) {
    if (this.props.currentCampaign != PrevProps.currentCampaign) {
      this.setState({
        currentCampaign: this.props.currentCampaign,
        campaignSelectable: this.props.currentCampaign.id
      })
    }
  }
  addCampaignTour = () => {
    if (document.getElementById('idCampaignInput').value == '') {
      fetch(`${ENV.host}/${ENV.namespace}/tours/addCampaign`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tourId: this.props.tourId,
          campaingId: this.state.campaignSelectable
        }),
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.props.getCampaign()
          this.viewsModal(false, false, true, false)
          toastr.success('Campaña asignada');
        });
    } else {
      fetch(`${ENV.host}/${ENV.namespace}/tours/addCampaignOthers`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tourId: this.props.tourId,
          campaingId: this.state.campaignSelectable
        }),
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.update) {
            this.props.getCampaign()
            this.viewsModal(false, false, true, false)
            toastr.success('Campaña asignada');
          } else {
            toastr.error('La campaña no existe o es posible que el ID este incorrecto');
          }
        });
    }

  }

  getCampaign = () => {
    fetch(`${ENV.host}/${ENV.namespace}/tours/getCampaign/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tourId: this.props.tourId
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.campaign) {
          this.setState({
            currentCampaign: data.campaign,
            campaignSelectable: data.campaign.id
          })
        }
      });
  }


  saveCampaign = () => {
    this.setState({
      title: '',
      campaignName: '',
      shortText: '',
      text: '',
      link: '',
      logoCampaign: '',
      bannerCampaign: '',
      linkVideo: '',
      currentCampaign: ''
    })
    if (this.state.text.length <= 500) {
      fetch(`${ENV.host}/${ENV.namespace}/pois/poiCampaing`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user: this.props.user.id,
          campaingId: uuid()
        }),
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.props.initialLoad(false)
          /* campaignId = data.poiSaved.id */
          this.setState({
            galleryCampaign: [],
            addCampaign: true, defaultView: false, assignCampaign: false,
            seeCampaign: false, currentCampaign: data.poiSaved
          })
        });
    } else {
      toastr.error('El campo de texto no puede tener mas de 500 caracteres');
    }
  }
  updateCampaign = () => {
    if (this.state.text && this.state.title && this.state.campaignName) {
      if (this.state.text.length <= 500) {
        fetch(`${ENV.host}/${ENV.namespace}/pois/updatePoiCampaing`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.state.currentCampaign.id,
            title: this.state.title,
            name: this.state.campaignName,
            smallText: this.state.shortText,
            description: this.state.text,
            link: this.state.link,
            logo: this.state.logoCampaign,
            banner: this.state.bannerCampaign,
            linkVideo: this.state.linkVideo
          }),
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.props.initialLoad()
            this.setState({
              defaultView: false,
              addCampaign: false,
              assignCampaign: true,
              seeCampaign: false,
              title: '',
              campaignName: '',
              shortText: '',
              text: '',
              link: '',
              logoCampaign: '',
              bannerCampaign: '',
              linkVideo: '',
              currentCampaign: ''
            })
            toastr.success('Campaña guardada');
          });
      } else {
        toastr.error('El campo de texto no puede tener mas de 500 caracteres');
      }
    } else {
      toastr.error('Hay campos sin llenar');
    }

  }
  viewsModal = (e1, e2, e3, e4) => {
    if (e1) {
      this.setState({
        defaultView: e1,
        addCampaign: e2,
        assignCampaign: e3,
        seeCampaign: e4,
        title: '',
        shortText: '',
        campaignName: '',
        text: '',
        link: '',
        logoCampaign: '',
        bannerCampaign: '',
        linkVideo: '',
      });
    } else {
      this.setState({
        defaultView: e1,
        addCampaign: e2,
        assignCampaign: e3,
        seeCampaign: e4,
      });
    }
  }
  viewCampaign = (campaign) => {
    fetch(`${ENV.host}/${ENV.namespace}/pois/imagesCampaign`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: campaign.id
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        /*         this.props.initialLoad() */
        this.setState({
          currentCampaign: campaign,
          galleryCampaign: data.images,
          defaultView: false,
          addCampaign: false,
          assignCampaign: false,
          seeCampaign: true,
        });
        this.editarCampaign()
      })
  }
  editarCampaign = () => {

    this.setState({
      title: this.state.currentCampaign.title,
      campaignName: this.state.currentCampaign.name,
      shortText: this.state.currentCampaign.smallText,
      text: this.state.currentCampaign.description,
      link: this.state.currentCampaign.linkCampaign,
      logoCampaign: this.state.currentCampaign.logo,
      bannerCampaign: this.state.currentCampaign.banner,
      linkVideo: this.state.currentCampaign.videoLink,
      defaultView: false,
      addCampaign: true,
      assignCampaign: false,
      seeCampaign: false,
    })
  }
  deleteImageCampaign = (image) => {
    fetch(`${ENV.host}/${ENV.namespace}/pois/deleteImageCampaign/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: image.id,
        idCampaign: image.imageableId
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        let array = this.state.galleryCampaign
        let index = array.findIndex(img => img.id == image.id)
        if (index > -1) {
          array.splice(index, 1)
          this.setState({
            galleryCampaign: array
          })
        }
      });
  }
  showPreviewImagesCampaignLogo = (photo) => {
    this.setState({ logoCampaign: photo.data.attributes.url })
  }

  showPreviewImagesCampaignBanner = (photo) => {
    this.setState({ bannerCampaign: photo.data.attributes.url })
  }
  showPreviewImagesCampaign = (image) => {
    image.data.attributes.id = image.data.id
    this.setState(prevState => ({
      galleryCampaign: [...prevState.galleryCampaign, image.data.attributes]
    }))
  }
  /* assaignCampaign = () => {
    fetch(`${ENV.host}/${ENV.namespace}/pois/assaignCampaign`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        idCampaign: this.state.campaignSelectable,
        poiId: this.state.currentPoi.id
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.props.initialLoad()
        this.setState({
          defaultView: true,
          addCampaign: false,
          assignCampaign: false,
          seeCampaign: false,
          currentPoi: {}
        });
        toastr.success('Campaña asignada');
      });
  } */
  handleSelectCampaign = (e) => {
    this.setState({ campaignSelectable: e })
  }

  onChange = (value) => {
    this.setState({ text: value })
  }
  deleteCampaign = () => {
    confirmationToastr(
      '¿Estás segur@ de eliminar esta campaña?',
      () => {
        fetch(`${ENV.host}/${ENV.namespace}/pois/deleteCampaign`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.state.currentCampaign && this.state.currentCampaign.id,
            campaignId: this.state.currentCampaign && this.state.currentCampaign.campaingId,
          }),
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.props.initialLoad()
            toastr.success(data.mensaje);
          });
        this.viewsModal(false, false, true, false)
        return Promise.resolve();
      },
      () => {
        return false;
      },
    );
  }
  removeCampaign = () => {
    fetch(`${ENV.host}/${ENV.namespace}/tours/removeCampaign`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tour: this.props.tourId
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.props.initialLoad()
        this.setState({
          currentCampaign: false,
          campaignSelectable: false
        })
        toastr.success('Campaña desasignada');
      });
  }
  handleOnDragEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex > newIndex) {
      this.state.galleryCampaign[oldIndex].order = newIndex + 1;
      for (let i = newIndex; i < oldIndex; i++) {
        this.state.galleryCampaign[i].order = i + 2;
      }
    } else if (oldIndex < newIndex) {
      for (let i = oldIndex; i <= newIndex; i++) {
        this.state.galleryCampaign[i].order = i;
      }
      this.state.galleryCampaign[oldIndex].order = newIndex + 1;
    }
    this.state.galleryCampaign.sort(function (a, b) {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    fetch(`${ENV.host}/${ENV.namespace}/images/orderGalleryCampaign`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        images: this.state.galleryCampaign,
      }),
    })
      .then(response => {
        return response.json();
      }).then(data => {
        this.setState({ galleryCampaign: this.state.galleryCampaign })
      })

  };

  render() {
    const {
      localSponsor,
      orderedImages,
      sponsorLogo,
      handleSponsorCreation,
      handleDeleteSponsor,
      handleChange,
      handleOnImageUpload,
      handleDeleteImage,
      handleOnDragEnd,
      handleVisibility,
      isMobile,
      myCampaigns,
    } = this.props;
    const {
      id: sponsorId = null,
      attributes: {
        title = null,
        text = null,
        'link-url': linkUrl = null,
        visibility = null,
        'start-date': startDate = null,
        'expiration-date': expirationDate = null,
      } = {},
    } = localSponsor || {};

    const uploaderImagesParams = {
      imageableId: sponsorId,
      imageableType: 'sponsor',
      imageableProperty: 'images',
    };

    const uploaderLogoParams = {
      imageableId: sponsorId,
      imageableType: 'sponsor',
      imageableProperty: 'logo',
    };

    const sortTrigger = {
      ...(!isMobile && { distance: 1 }),
      ...(isMobile && { pressDelay: 100 }),
    };

    const selectedStartDate = isEmpty(startDate) ? null : new Date(startDate);
    const selectedDate = isEmpty(expirationDate) ? null : new Date(expirationDate);

    let imageParams = {
      imageableId: this.state.currentCampaign && this.state.currentCampaign.id,
      imageableType: 'campaign',
      imageableProperty: 'image',
    }

    this.state.galleryCampaign.sort(function (a, b) {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return (
      <section className="row tour-config-sponsor">
        {/* new config sponsor */}
        <div className="content w-100">
          {
            this.state.addCampaign &&
            <div className="w-100 d-flex justify-content-end align-items-center division3 fixedObject">
              <h4 className="mb-0 mr-2">Guardar campaña</h4>
              <button onClick={() => this.updateCampaign()} className="checkIcon p-0">
                {CHECK_ICON}
              </button>
              <button onClick={() => {
                this.viewsModal(false, false, true, false)
              }} className="closeIcon p-0">
                {CLOSE_ICON}
              </button>
            </div>
          }

          <div className={this.state.defaultView ? "contentCenter d-flex justify-content-center" : "contentCenter d-none"}>

            <div className="campaign w-100 mx-3">
              <div className="d-flex justify-content-center align-items-center">
                <button className="btn btn-primary" onClick={() => this.viewsModal(false, false, true, false)}>Asignar campaña a la ruta</button>
                {isMobile ? null : (
                  <button className="btn btn-primary ml-3" onClick={() => this.saveCampaign()}>Crear campaña</button>
                )}
              </div>
              <div className="d-flex justify-content-start align-items-start">
                <h4 className="division2 w-100">Campañas</h4>
                {isMobile ? (
                  <img
                    width="32"
                    className="cursor añadir_azul mr-2 mt-2"
                    alt="button"
                    src='../../../assets/images/anadir_azul.svg'
                    onClick={() => this.saveCampaign()}
                  />
                ) : null}
              </div>
              {myCampaigns ? (
                myCampaigns.map((campaign, i) => {
                  if (this.props.user.id == campaign.createdBy) {
                    return <div className='d-flex justify-content-start align-items-center'>
                      <h6 key={i} className="px-3 my-2 cursor" onClick={() => this.viewCampaign(campaign)}>{campaign.title}</h6>
                      {
                        this.state.campaignSelectable && this.state.campaignSelectable == campaign.id ? <img
                          src="../../../assets/images/closeSquare.svg"
                          width="22"
                          alt=""
                          className="ml-2 cursor mb-1"
                          onClick={() => this.removeCampaign()}
                        /> : null
                      }
                    </div>
                  }
                })
              ) : (
                  <>
                    <h5 className="text-danger text-center">No tienes ninguna campaña creada.</h5>
                    <p className="text-center">1. Crea una nueva campaña pulsando el icono "+" de la parte superior.</p>
                    <p className="text-center">2. Una vez creada aparecerá en este espacio de información y ya podrás asociarla a tus destinos pulsando en el icono: <img
                      src="../../../assets/images/refresh.svg"
                      width="22"
                      alt=""
                      className="cursor"
                    /></p>
                  </>
                )
              }
            </div>
          </div>
          {/* campaña */}
          {
            this.state.addCampaign && (
              <div className="d-flex addCampaign">
                <div className="left col-7">
                  <h4 className="division2 ml-0 mb-4">{this.state.currentCampaign.title}</h4>
                  <h5 className="d-flex mb-3">ID único: <h5 className="textBlue ml-2">{this.state.currentCampaign && this.state.currentCampaign.campaingId}</h5></h5>
                  {/* <li className="left__category-item mb-3">
                      <label
                        htmlFor="noPreview"
                        className="custom-checkbox"
                      >
                        {"Activar en todos mis destinos patrocinados"}
                        <input
                          type="checkbox"
                          id="noPreview"
                          onChange={this.noPreview}
                          defaultChecked={this.state.noPreview || false}
                        />
                        <i />
                      </label>
                    </li> */}
                  <div className="form-group row mx-0 mb-3">
                    <label htmlFor="campaignName" className="col-auto col-form-label text-medium pl-0">
                      Nombre de la campaña:
                                      </label>
                    <ValidationInput
                      className="col-12 col-lg pl-lg-0"
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="campaignName"
                        name="campaignName"
                        onChange={(e) => this.setState({
                          campaignName: e.target.value
                        })}
                        value={this.state.campaignName || ''}
                      />
                    </ValidationInput>
                  </div>
                  <div className="form-group row mx-0 mb-3">
                    <label htmlFor="title" className="col-auto col-form-label text-medium pl-0">
                      Título:
                                      </label>
                    <ValidationInput
                      className="col-12 col-lg pl-lg-0"
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        onChange={(e) => this.setState({
                          title: e.target.value
                        })}
                        value={this.state.title || ''}
                      />
                    </ValidationInput>
                  </div>
                  <div className="form-group row mx-0 mb-3">
                    <label htmlFor="shortText" className="col-auto w-100 d-flex justify-content-between align-items-center col-form-label text-medium pl-0">
                      <span>Texto corto:</span>
                      <span style={{ fontSize: 14 }}>{this.state.shortText ? this.state.shortText.length : 0}/120</span> </label>
                    <ValidationInput
                      className="col-12"
                    >
                      <Textarea
                        placeholder="Máximo 120 caracteres."
                        type="text"
                        className="form-control"
                        id="shortText"
                        name="shortText"
                        minRows={4}
                        maxLength="120"
                        onChange={(e) => this.setState({
                          shortText: e.target.value
                        })}
                        value={this.state.shortText || ''}
                      />
                    </ValidationInput>
                  </div>
                  <div className="col mx-0 mb-3">
                    <label htmlFor="text" className="left__label col-auto w-100 d-flex justify-content-between align-items-center col-form-label text-medium pl-0">
                      <span>Descripción:</span>
                      <span style={{ fontSize: 14 }}>{this.state.text ? this.state.text.length : 0}/500</span>
                    </label>
                    <ValidationInput
                      className="col-12"
                    >
                      <ReactQuill
                        type="text"
                        className="left__text-editor"
                        id="text"
                        name="text"
                        minRows={4}
                        onChange={this.onChange}
                        value={this.state.text || ''}
                        modules={MODULES}
                        formats={FORMATS}
                      />
                    </ValidationInput>
                  </div>
                  <div className="form-group row mx-0 mb-4">
                    <label htmlFor="link" className="col-auto col-form-label text-medium pl-0">
                      Enlace:
                                      </label>
                    <ValidationInput
                      className="col-12 col-lg pl-lg-0"
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="link"
                        name="link"
                        onChange={(e) => this.setState({
                          link: e.target.value
                        })}
                        value={this.state.link || ''}
                      />
                    </ValidationInput>
                  </div>
                  <div className="form-group row mx-0 mb-3 flex-column">
                    <div className="col-auto ml-auto">
                      <p className="tour-content-video-form__supported-text">Formatos soportados:</p>
                      <span className="tour-content-video-form__supported-type">
                        {LOGO_YOUTUBE_ICON}
                                      Youtube
                                      </span>
                      <span className="tour-content-video-form__supported-type">
                        {LOGO_VIMEO_ICON}
                                      Vimeo
                                      </span>
                    </div>
                    <label htmlFor="link" className="col-auto col-form-label text-medium pl-0">
                      Url del vídeo:
                                      </label>
                    <ValidationInput
                      className="col-12 col-lg pl-lg-0"
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="link"
                        name="link"
                        onChange={(e) => this.setState({
                          linkVideo: e.target.value
                        })}
                        value={this.state.linkVideo || ''}
                      />
                    </ValidationInput>
                  </div>
                  <h5>Imágenes del carrusel:</h5>
                  <p>Puedes añadir un máximo de 5 imágenes, en formato cuadrado (1300px de lado recomendado) y en "jpg", "png" o "svg".
                                      Para cambiar el orden simplemente arrastra las imágenes.</p>
                  {/* {this.imageParams} */}
                  <div className=" d-lg-block col-lg-4">
                    <FineUploader
                      key={`desktop-uploader-carousel`}
                      endpoint="/images"
                      autoUpload
                      multiple={/* this.props.poi.attributes['poi-type'] === 1 */ true}
                      withUploadBtn={true}
                      params={imageParams}
                      onAllComplete={null}
                      withProgressBars
                      withTotalProgressBar
                      removeThumbAfter={800}
                      imageUploadName={this.showPreviewImagesCampaign}
                      countUpload={true}
                      // lengthPoiImages={this.state.images.length}
                      addBlue={true}
                    >
                      <div
                        className={`
                                      poi-images-box__dropzone ${false
                            ? 'poi-images-box__dropzone--dragging'
                            : ''}
                                        `}
                      /*                           onDragStart={() => handleDraggingState(true)}
                      onDragOver={() => handleDraggingState(false)} */
                      >
                        <img
                          src={UPLOAD_CLOUD_URL}
                          alt="Sube tus imágenes"
                          className="poi-images-box__dropzone-img poi-images-box__dropzone-img--nodrag"
                        />
                        <img
                          src={DROP_COLOR_URL}
                          alt="Sube tus imágenes"
                          className="poi-images-box__dropzone-img poi-images-box__dropzone-img--drag"
                        />
                        <p className="poi-images-box__dropzone-text">Arrastra aquí tus imágenes</p>
                      </div>
                    </FineUploader>
                    {/*  <p className="poi-images-page__total-images">
                                        Total de imagenes:
                                        <span>{images && images.length}</span>
                                      </p> */}
                  </div>
                  <div className='galleryCampaign flex-wrap w-100 d-flex justify-content-start align-items-center'>
                    <SortableContainer
                      onSortEnd={this.handleOnDragEnd}
                      axis="xy"
                      lockToContainerEdges
                      /* useDragHandle */
                      {...sortTrigger}
                    >
                      {isEmpty(this.state.galleryCampaign)
                        ? null
                        : this.state.galleryCampaign.map((image, idx) => (
                          <SortableItem key={`service-image-${image.id}`} index={idx}>
                            <DragHandle>
                              <figure key={idx} className='mr-1 mb-1 dragg'>
                                <button onClick={() => this.deleteImageCampaign(image)} className="deleteImgGalleryCampaign p-0">
                                  {CIRCLE_CROSS_ICON}
                                </button>
                                <img src={image.url} alt="" className="imageC" />
                              </figure>
                            </DragHandle>
                          </SortableItem>
                        ))}
                    </SortableContainer>
                  </div>
                </div>
                <div className="divisorLateral"></div>
                <div className="right col-5">
                  <div className="d-flex flex-column align-items-center mb-3">
                    <p className="textBlue mt-5 mb-0">Elementos gráficos sólo necesarios para el patrocinio de destinos o colecciones, no para las rutas.</p>
                    <img
                      src="../../../assets/images/down-arrow.svg"
                      width="45"
                      alt=""
                      className="pointer"
                    />
                  </div>
                  <h5>Logotipo:</h5>
                  <p className="mb-0">Medidas 250px x 180px</p>
                  <p>preferiblemente en archivo SVG.</p>
                  <div className="logotipo">
                    <div className="mr-3 cuadrado logo">
                      {
                        this.state.logoCampaign ? <img src={this.state.logoCampaign} alt="" /> : (
                          <div className="d-lg-block">
                            <FineUploader
                              key={`desktop-uploader-logo`}
                              endpoint="/images"
                              autoUpload
                              multiple={/* this.props.poi.attributes['poi-type'] === 1 */ true}
                              withUploadBtn={true}
                              params={{
                                imageableId: '00',
                                imageableType: 'campaign-logo',
                                imageableProperty: 'image',
                              }}
                              onAllComplete={null}
                              withProgressBars
                              withTotalProgressBar
                              removeThumbAfter={800}
                              imageUploadNameLogo={this.showPreviewImagesCampaignLogo}
                              countUpload={true}
                              addBlue={true}
                            >
                              <div
                                className={`
                                          poi-images-box__dropzone ${false
                                    ? 'poi-images-box__dropzone--dragging'
                                    : ''}
                                            `}
                              /*                           onDragStart={() => handleDraggingState(true)}
                              onDragOver={() => handleDraggingState(false)} */
                              >
                                <img
                                  src={UPLOAD_CLOUD_URL}
                                  alt="Sube tus imágenes"
                                  className="poi-images-box__dropzone-img poi-images-box__dropzone-img--nodrag"
                                />
                                <img
                                  src={DROP_COLOR_URL}
                                  alt="Sube tus imágenes"
                                  className="poi-images-box__dropzone-img poi-images-box__dropzone-img--drag"
                                />
                                <p className="poi-images-box__dropzone-text">Arrastra aquí tus imágenes</p>
                              </div>
                            </FineUploader>
                            {/*  <p className="poi-images-page__total-images">
                                            Total de imagenes:
                                            <span>{images && images.length}</span>
                                          </p> */}
                          </div>
                        )
                      }

                    </div>
                    {
                      this.state.logoCampaign && <img
                        src="../../../assets/images/closeSquare.svg"
                        width="22"
                        alt=""
                        className="pointer cursor"
                        onClick={() => this.setState({ logoCampaign: '' })}
                      />
                    }

                  </div>
                  <h5 className="mt-3">Banner vertical:</h5>
                  <p className="">Medidas 250px x 400px</p>
                  <div className="logotipo">
                    <div className="rectangulo banner">
                      {
                        this.state.bannerCampaign ? <img src={this.state.bannerCampaign} alt="" /> : (
                          <div className="d-lg-block col-lg-4">
                            <FineUploader
                              key={`desktop-uploader-banner`}
                              endpoint="/images"
                              autoUpload
                              multiple={/* this.props.poi.attributes['poi-type'] === 1 */ true}
                              withUploadBtn={true}
                              params={{
                                imageableId: '00',
                                imageableType: 'campaign-banner',
                                imageableProperty: 'image',
                              }}
                              onAllComplete={null}
                              withProgressBars
                              withTotalProgressBar
                              removeThumbAfter={800}
                              imageUploadNameBanner={this.showPreviewImagesCampaignBanner}
                              countUpload={true}
                              // lengthPoiImages={this.state.images.length}
                              addBlue={true}
                            >
                              <div
                                className={`
                                            poi-images-box__dropzone ${false
                                    ? 'poi-images-box__dropzone--dragging'
                                    : ''}
                                              `}
                              /*                           onDragStart={() => handleDraggingState(true)}
                              onDragOver={() => handleDraggingState(false)} */
                              >
                                <img
                                  src={UPLOAD_CLOUD_URL}
                                  alt="Sube tus imágenes"
                                  className="poi-images-box__dropzone-img poi-images-box__dropzone-img--nodrag"
                                />
                                <img
                                  src={DROP_COLOR_URL}
                                  alt="Sube tus imágenes"
                                  className="poi-images-box__dropzone-img poi-images-box__dropzone-img--drag"
                                />
                                <p className="poi-images-box__dropzone-text">Arrastra aquí tus imágenes</p>
                              </div>
                            </FineUploader>
                          </div>
                        )
                      }
                    </div>
                    {
                      this.state.bannerCampaign && <img
                        src="../../../assets/images/closeSquare.svg"
                        width="22"
                        alt=""
                        className="ml-2 pointer cursor"
                        onClick={() => this.setState({ bannerCampaign: '' })}
                      />
                    }

                  </div>
                </div>
              </div>
            )
          }
          {/* ver campaña */}
          <div className={this.state.seeCampaign ? "d-flex seeCampaign" : "d-none"}>
            <div className="left col-7">
              <h4 className="division2 ml-0 mb-4">{this.state.currentCampaign && this.state.currentCampaign.title}</h4>
              <h5 className="d-flex mb-3">ID único: <h5 className="textBlue ml-2">{this.state.currentCampaign && this.state.currentCampaign.campaingId}</h5></h5>
              <div className="d-flex mb-3">
                {/* <div className="division4 mr-3 pr-3">
                    {ROUTE_ICON}
                                          214
                                          </div>
                  <div className="division4 mr-3 pr-3">
                    {EYE_ICON}
                                          35K
                                          </div> */}
              </div>
              {/* <li className="left__category-item mb-3">
                                          <label
                                          htmlFor="xdd"
                                          className="custom-checkbox"
                                          >
                                          {"Activar en todos mis destinos patrocinados"}
                                          <input
                                          type="checkbox"
                                          id="xdd"
                                          onChange={this.noPreview}
                                          defaultChecked={this.state.noPreview || false}
                                          />
                                          <i />
                                          </label>
                                        </li> */}
              <div className="form-group row mx-0 mb-3 align-items-center">
                <label className="col-auto text-medium pl-0">
                  Título:
                                        </label>
                <h6> {this.state.currentCampaign && this.state.currentCampaign.title}</h6>
              </div>
              <div className="form-group row mx-0 mb-3 align-items-center">
                <label className="col-auto text-medium pl-0">
                  Nombre:
                                        </label>
                <h6> {this.state.currentCampaign && this.state.currentCampaign.name}</h6>
              </div>
              <div className="col mx-0 mb-3 pl-0 contentText">
                <label className="col-auto text-medium pl-0">
                  Texto Corto:
                                        </label>
                <h6> {this.state.currentCampaign && this.state.currentCampaign.smallText}</h6>
              </div>
              <div className="col mx-0 mb-3 pl-0">
                <label className="col-auto text-medium pl-0">
                  Descripción:
                                        </label>
                <div className='boldTextCampaing contentText' dangerouslySetInnerHTML={{
                  __html: this.state.currentCampaign && this.state.currentCampaign.description
                }}></div>
              </div>
              <div className="form-group row mx-0 mb-3 align-items-center">
                <label className="col-auto text-medium pl-0">
                  Enlace:
                                        </label>
                <h6>{this.state.currentCampaign && this.state.currentCampaign.linkCampaign}</h6>
              </div>
              <div className="form-group row mx-0 mb-3 align-items-center">
                <label className="col-auto text-medium pl-0">
                  Video:
                                        </label>
                <h6>{this.state.currentCampaign && this.state.currentCampaign.videoLink}</h6>
              </div>
              <h5>Imágenes del carrusel:</h5>
              <div className='galleryCampaign flex-wrap d-flex justify-content-start align-items-center'>
                {
                  this.state.galleryCampaign.map((image, i) => {
                    return (
                      <figure key={i} className='mr-1 mb-1'>
                        <img src={image.url} alt="" />
                      </figure>
                    )
                  })
                }
              </div>

            </div>
            <div className="divisorLateral"></div>
            <div className="right col-5">
              <div className="d-flex flex-column align-items-center mb-3">
                <button className="btn btn-primary mt-3 mb-2" onClick={() => this.editarCampaign()}>Editar campaña</button>
                <button className="btn btn-danger" onClick={() => this.deleteCampaign()}>Eliminar campaña</button>
                <button onClick={() => this.viewsModal(false, false, true, false)} className="btn btn mt-2 mb-2" style={{ border: "1px solid #303030" }}>
                  Volver
              </button>
              </div>
              <h5>Logotipo:</h5>
              <p className="mb-0">Medidas 250px x 180px</p>
              <p>preferiblemente en archivo SVG.</p>
              <div className="logotipo">
                <div className="cuadrado logo">
                  <img src={this.state.currentCampaign && this.state.currentCampaign.logo} alt="" />
                </div>
              </div>
              <h5 className='mt-3'>Banner vertical:</h5>
              <p className="">Medidas 250px x 400px</p>
              <div className="logotipo">
                <div className="rectangulo banner">
                  <img src={this.state.currentCampaign && this.state.currentCampaign.banner} alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* añadir campaña */}
          {
            this.state.assignCampaign ? <div className="d-flex flex-column assignCampaign">
              <h5 className="font-weight-normal ml-3">Campaña asignada:</h5>
              <div className="w-100 d-flex justify-content-center align-items-center flex-column mt-3">
                {
                  this.state.currentCampaign ? <div><p className="mb-2 mr-2 my-3 textBlue text-center">
                    {
                      this.state.currentCampaign && this.state.currentCampaign.createdBy != this.props.user.id ? <p className="mb-2 mr-2 my-3 text-center d-flex font-weight-normal"><p className="externalCampaign">Campaña externa:</p> {this.state.currentCampaign.campaingId}</p> : null
                    }
                  </p>
                    <p className="textBlue">{this.state.currentCampaign.title}
                      <img
                        src="../../../assets/images/closeSquare.svg"
                        width="22"
                        alt=""
                        className="ml-2 cursor mb-1"
                        onClick={() => this.removeCampaign()}
                      />
                    </p>
                  </div>
                    : <p className="text-center mt-3">Esta ruta no tiene asignado ningún patrocinador</p>
                }
                <button className="btn btn-primary mt-3" onClick={() => this.saveCampaign()}>Crear una nueva campaña</button>
              </div>
              <div>
                <h5 className="mb-3 mr-2 division2 mt-5 ml-3 font-weight-normal">Mis campañas:</h5>
                {

                  myCampaigns.map((campaign, i) => {
                    // let sponsor = this.state.sponsorPoi.find(sponsor => { sponsor.poiId == this.state.currentPoi.id })
                    if (parseInt(this.props.user.id) === parseInt(campaign.createdBy)) {
                      return (
                        <li key={i} className="left__category-item mb-3 ml-5">
                          <label
                            htmlFor={campaign.id}
                            className="custom-checkbox"
                          >
                            {campaign.title}
                            <input
                              style={{ border: '1px solid red' }}
                              type="radio"
                              id={campaign.id}
                              value={campaign.id}
                              onChange={() => {
                                document.getElementById('idCampaignInput').value = '';
                                this.setState({ campaignSelectable: campaign.id })
                              }}
                              checked={this.state.campaignSelectable === campaign.id}
                            />
                            <i />
                            <button
                              type="button"
                              onClick={() => this.viewCampaign(campaign)}
                              className="editCampaignButton"
                              title="Editar"
                            >
                              {PENCIL_ICON}
                            </button>
                          </label>
                        </li>
                      )
                    }
                  })
                }
                <h5 className="mb-3 mr-2 division2 mt-5 font-weight-normal">Añade un ID de una campaña de terceros:</h5>
                <p className="ml-5">Tu patrocinio se actualizará con los  cambios que haga el creador de esa campaña.</p>
                <div className="form-group row mx-0 mb-3 ml-5">
                  <label htmlFor="idCampaign" className="col-auto col-form-label text-medium pl-0">
                    ID:
                                            </label>
                  <ValidationInput
                    className="col-12 col-lg pl-lg-0"
                  >
                    <input
                      type="text"
                      className="form-control w-50"
                      id="idCampaignInput"
                      name="idCampaign"
                      onChange={(e) => {
                        this.setState({
                          campaignSelectable: e.target.value
                        })
                      }}
                    />
                  </ValidationInput>
                </div>
                {/*                 <div className="w-100 d-flex justify-content-center align-items-center division3 mt-5">
                  <button onClick={() => this.addCampaignTour()} className="btn btn-primary mr-3">
                    Asignar
                      </button>
                  <button onClick={() => this.viewsModal(true, false, false, false)} className="btn" style={{ border: "1px solid #303030" }}>
                    Volver
                      </button>
                </div> */}
                <div className="w-100 d-flex justify-content-end align-items-center division3 fixedObject">
                  <h4 className="mb-0 mr-2">Campaña</h4>
                  <button onClick={() => this.addCampaignTour()} className="checkIcon p-0">
                    {CHECK_ICON}
                  </button>
                  <button onClick={
                    this.props.closeModal
                  } className="closeIcon p-0">
                    {CLOSE_ICON}
                  </button>
                </div>
              </div>
            </div> : null
          }
        </div>
      </section>
    );
  };
}

TourConfigSponsorTemplate.propTypes = {
  localSponsor: TYPES.sponsorType,
  orderedImages: PropTypes.arrayOf(TYPES.imageType),
  sponsorLogo: TYPES.imageType,
  handleSponsorCreation: PropTypes.func.isRequired,
  handleDeleteSponsor: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleOnImageUpload: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  handleOnDragEnd: PropTypes.func.isRequired,
  handleVisibility: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  user: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
      surname: PropTypes.string,
      description: PropTypes.string,
      website: PropTypes.string,
    }),
  }),
};

TourConfigSponsorTemplate.defaultProps = {
  localSponsor: null,
  orderedImages: [],
  sponsorLogo: null,
  isMobile: false,
  user: null,
};

function mapStateToProps(state) {
  const user = state.auth.currentUser;
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    user,
  };
}

export default connect(mapStateToProps)(TourConfigSponsorTemplate);
