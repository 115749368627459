import React from 'react';
import FileInput from 'react-fine-uploader/file-input';

import { PropTypes } from 'prop-types';
import CircularBar from '../../progress-bars/circular-bar/circular-bar';
import LinearBar from '../../progress-bars/linear-bar';
import { CLOSE_ICON } from '../../../../../helpers';
import { isEmpty } from '../../../../../utils';

function SingleThumbnailWrapper(props) {
  const {
    uploaderFile,
    fileProgress,
    fileThumbnailUrl,
    uploader,
    // uploadBtnCB,
    totalProgress,
    removeFromQueueCB,
    withProgressBars,
    // withUploadBtn,
    withTotalProgressBar,
    isDropzone,
    children,
    uploadGoingOn,
    totalProgressBarType,
  } = props;
  const showProgressBar = withProgressBars && uploadGoingOn;
  const shouldRenderFileInput = !isDropzone && !showProgressBar;
  return (
    <div className="fine-uploader__files-wrapper">
      {uploaderFile ? (
        <div key={`thumbnail-box-${uploaderFile.file.name}`} className="thumbnails-wrapper__box">
          {withTotalProgressBar ? (
            totalProgressBarType === 'linear' ? (
              <LinearBar progress={totalProgress} />
            ) : (
              <CircularBar progress={totalProgress} />
            )
          ) : null}
          {showProgressBar ? <LinearBar progress={fileProgress.percentage} /> : null}
          {fileThumbnailUrl ? (
            <>
              <button
                className="thumbnails-wrapper__remove-btn"
                type="button"
                onClick={(e) => {
                  removeFromQueueCB(e, uploaderFile.id, uploaderFile.file);
                }}
              >
                {CLOSE_ICON}
              </button>
              <img
                src={fileThumbnailUrl}
                key={`${uploaderFile.file.name}-${uploaderFile.id}-thumbnail`}
                className="thumbnails-wrapper__preview"
                alt={`${uploaderFile.file.name} thumbnail`}
              />
            </>
          ) : null}
          {/* {withUploadBtn ? (
            <button type="button" className="btn btn-primary" onClick={uploadBtnCB}>
              Upload
            </button>
          ) : null} */}
        </div>
      ) : null}
      {shouldRenderFileInput ? (
        <FileInput
          className="fine-uploader__file-input"
          multiple={false}
          accept="image/*"
          uploader={uploader}
        >
          {isEmpty(children) ? <span className="">Choose Files</span> : children}
        </FileInput>
      ) : null}
    </div>
  );
}

SingleThumbnailWrapper.propTypes = {
  uploader: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.func])).isRequired,
  uploaderFile: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  ),
  fileProgress: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  ),
  fileThumbnailUrl: PropTypes.string,
  totalProgress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withTotalProgressBar: PropTypes.bool,
  isDropzone: PropTypes.bool,
  uploadGoingOn: PropTypes.bool,
  withProgressBars: PropTypes.bool,
  // withUploadBtn: PropTypes.bool,
  // uploadBtnCB: PropTypes.func,
  removeFromQueueCB: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  totalProgressBarType: PropTypes.string,
};
SingleThumbnailWrapper.defaultProps = {
  isDropzone: true,
  uploaderFile: null,
  fileProgress: null,
  fileThumbnailUrl: null,
  totalProgress: 0,
  // uploadBtnCB: null,
  removeFromQueueCB: null,
  uploadGoingOn: false,
  withTotalProgressBar: false,
  withProgressBars: true,
  // withUploadBtn: true,
  totalProgressBarType: 'circular',
};
export default SingleThumbnailWrapper;
