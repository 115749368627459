import { PropTypes } from 'prop-types';

const tagType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['tags']),
  attributes: PropTypes.shape({
    name: PropTypes.string,
  }),
});

export default tagType;
