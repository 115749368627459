import React from 'react';
import { Helmet } from 'react-helmet';
import MainLayout from '../layouts/main-layout';
import { Footer } from '../components';

const ConditionsPage = () => (
  <MainLayout>
    <Helmet>
      <title>: Términos y condiciones de uso</title>
      <meta name="title" content="Topdesti: Términos y condiciones de uso" />
      <meta property="og:title" content="Topdesti: Términos y condiciones de uso" />
      <meta property="twitter:title" content="Topdesti: Términos y condiciones de uso" />
      <meta name="description" content="Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas" />
      <meta property="og:description" content="Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas" />
      <meta property="twitter:description" content="Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas" />
      <meta property="of:image" content={`${window.location.origin}/assets/images/topdesti-logo.png`} />
      <meta property="twitter:image" content={`${window.location.origin}/assets/images/topdesti-logo.png`} />
      <meta property="og:image:width" content="132" />
      <meta property="og:image:height" content="38" />
    </Helmet>
    <main className="container f-opensans">
      <div className="row">
        <div className="col-12">
          <h2 className="text-center mt-3">
            Términos y Condiciones de Uso de Topdesti
          </h2>
          <p>Antes de registrarte como usuario de Topdesti y crearte una cuenta para contratar y/o utilizar nuestros servicios, debes leer y aceptar tanto nuestra Política de privacidad y cookies como estas condiciones de uso.</p>
          <p>Las presentes condiciones de uso regulan el acceso y la utilización del sitio web alojado bajo el nombre de dominio www.topdesti.com (el "Sitio Web") y de la aplicación móvil. Es una plataforma web con la que podrás planificar de forma rápida tus viajes, diseñando tu propio itinerario o consultando las rutas creadas por otros viajeros y locales, de forma gratuita. Además la plataforma está diseñada con dos objetivos: que conozcas a otros viajeros con perfiles similares con los que disfrutar de experiencias únicas y que puedas monetizar tus viajes y dedicarte a ello de forma profesional. Formar parte de una comunidad de viajeros afines a ti, con los que podrás compartir destinos, rutas y recomendaciones.</p>
          <h4>Te damos la bienvenida a Topdesti</h4>
          <p>
            <strong>Topdesti, S.L.</strong>
            , con CIF B-76327972 y domicilio en calle Calle Simancas, 49, 2 D, 35010, Las Palmas (España) es la compañía tecnológica que desarrolla la aplicación móvil para crear tus rutas de viaje y conocer a aventuret@s como tú (en adelante,
            <strong> “Topdesti”</strong>
            ).
          </p>
          <p>La normativa vigente determinará las leyes que deban regir y la jurisdicción que deba conocer las relaciones entre Topdesti y los usuarios. No obstante, en aquellos casos en los que la normativa vigente no prevea la obligación de someterse a un fuero o legislación determinado, Topdesti y sus usuarios, con renuncia a cualquier otro fuero que pudiera corresponderles, se someten a los juzgados y tribunales de Madrid capital (España).</p>
          <p>Adicionalmente, para aquellos usuarios que contraten cualesquiera servicios de Topdesti, se informa que el soporte al usuario en caso de incidencias y/o reclamaciones será principalmente online y será atendido siempre a la mayor brevedad. Para ello, podrás ponerte en contacto, entre otros medios, a través del correo electrónico contacto@topdesti.com.</p>

          <h4>Alta como usuario registrado</h4>
          <p>El acceso a nuestros servicios implica tu registro como usuario en Topdesti y para poder identificarte deberás facilitarnos una serie de datos identificativos y de contacto básicos sobre ti como usuario.</p>
          <p>Para tramitar tu alta y/o disfrutar de determinados servicios, Topdesti podrá solicitarte información adicional, así como la verificación de tu dirección de correo electrónico o tu teléfono móvil e n cualquier momento. A tal efecto, es muy importante que tengas en cuenta que deberás facilitarnos siempre tu dirección de correo electrónico o número de teléfono actual, garantizando que es tuyo y que sólo tú tienes derecho a utilizarlo.</p>
          <p>Para poder descargar y utilizar la App y/o el Sitio Web, el usuario debe ser mayor de 14 años y tener capacidad para otorgar un contrato vinculante así como no estar sujeto por ninguna causa de incapacidad. Por tanto, al aceptar estas Condiciones, reconoces ser una persona con capacidad suficiente para adquirir las obligaciones derivadas de tus actuaciones y que has leído, comprendes y aceptas su contenido. Si eres menor de edad deberás contar con la autorización previa de tus padres, tutores o representantes legales, a quienes se les advierte que, según la legislación vigente, serán considerados responsables de todos los actos realizados por los menores a su cargo.</p>
          <p>Podrás desinstalar Topdesti de tu dispositivo o terminal móvil en cualquier momento mediante las distintas opciones de configuración del mismo, así como solicitar a Topdesti, o gestionar tú mismo a través de las herramientas que ofrecemos, tu baja como usuario.</p>

          <h4>Tus obligaciones</h4>
          <p>El acceso y/o contratación de los servicios de Topdesti implica tu compromiso de hacer siempre un uso correcto de los mismos, de acuerdo con estas Condiciones y, en su caso, aquellas específicas aplicables a cada servicio y, en general, la legalidad vigente y los principios de buena fe, la moral y el orden público.</p>
          <p>En este sentido, queda terminantemente prohibido cualquier acceso y/o uso de Topdesti contrario a lo previsto en el párrafo anterior con o sin finalidad económica, entre otros, aquellos que puedan resultar ilegales, abusivos, fraudulentos o, en general, que puedan perjudicar los derechos de Topdesti y/o de terceras personas.</p>
          <p>Tú eres el responsable del uso que decidas hacer de Topdesti, incluyendo los contenidos que decidas compartir o enviar en cada momento a través de los servicios ofrecidos en Topdesti. Si bien Topdesti hará todo lo razonablemente posible para vigilar la legalidad de los contenidos que se encuentren en Topdesti, Topdesti actúa como mero intermediario que pone a disposición de sus usuarios su plataforma tecnológica, asumiendo única y exclusivamente la responsabilidad derivada de la diligencia que le pudiera ser exigible por ley.</p>
          <p>En lo que respecta a los contenidos que decidas compartir con tus contactos en Topdesti, garantizas, manteniendo indemne a Topdesti de cualquier responsabilidad al respecto, que eres el propietario o titular de los derechos necesarios y/o bien que cuentas con la autorización o permisos necesarios para ello y que, en consecuencia, no vulnera los derechos de terceros.</p>
          <p>Queda terminantemente prohibido el envío de comunicaciones comerciales no deseadas a través de Topdesti, la publicación o la transmisión de cualquier contenido que, a juicio de Topdesti resulte violento, obsceno, pornográfico, ofensivo, abusivo, ilegal, xenófobo o difamatorio. Los cracks, números de serie de programas o cualquier otro contenido que vulnere derechos de la propiedad intelectual de terceros. La recogida y/o utilización de datos personales de otros usuarios sin su consentimiento expreso o contraviniendo lo dispuesto en la normativa sobre protección de datos vigente en cada momento. La utilización del servicio con fines de spam, mail bombing, phishing, escrow fraud, caling scam, pharming, difusión de virus (troyanos, gusanos, etc.), o cualquier otro tipo de actividad realizada con ánimo saboteador, fraudulento o delictivo.</p>
          <p>En virtud de lo establecido en la Ley 34/2002 de Servicios de la Sociedad de la Información y de Comercio Electrónico referente al deber de colaboración con los organismos competentes, suspenderá el servicio contratado cuando sea instado por el organismo judicial o administrativo que se lo solicite formalmente cumpliendo con los requisitos de la Ley. Asimismo, si tiene conocimiento de que se están cometiendo acciones claramente ilícitas o que vulneren derechos de terceros, tendrá la potestad de interrumpir el servicio, cancelar la cuenta de usuario del infractor y ponerlo en conocimiento de las autoridades competentes.</p>
          <p>Si Topdesti por sus propios medios o a instancias de un tercero, detectase un uso del servicio que pudiera comprometer la seguridad, la estabilidad o el normal funcionamiento del servicio, tendrá la potestad de interrumpir el servicio. Las suspensiones del servicio derivadas de estas circunstancias, así como las consecuencias de cualquier tipo derivadas de ello, no generarán en tu favor ninguna compensación, indemnización o rebaja del precio del servicio.</p>
          <p>El incumplimiento de tus obligaciones como usuario establecidas en las presentes condiciones, una inactividad prolongada de tu cuenta o las necesidades tecnológicas o de negocio de Topdesti podrán implicar la suspensión y/o baja inmediata de los servicios e incluso tu baja como usuario registrado de Topdesti; todo ello sin derecho a recibir compensación y/o reembolso de ningún tipo.</p>

          <h4>Notificaciones y promociones</h4>
          <p>Topdesti podrá enviarte notificaciones para informarte sobre promociones, servicios, funcionalidades y otras novedades disponibles en Topdesti en cada momento, así como en relación con aquellos otros servicios de pago de Topdesti que pudieras haber contratado.</p>
          <p>En línea con lo anterior, Topdesti podrá ofrecerte promociones, concursos y/o beneficios exclusivos sobre los servicios y funcionalidades disponibles en Topdesti para que, si lo deseas, los disfrutes en condiciones más ventajosas. En todo caso, la participación en este tipo promociones implicará la aceptación, además de lo previsto en las presentes Condiciones, de las condiciones o bases legales específicas que sean de aplicación a las mismas.</p>
          <p>Igualmente, conoces y aceptas que en Topdesti puede haber contenidos publicitarios y que, por tanto, como usuario podrás acceder a publicidad de productos y servicios de Topdesti y/o de terceras empresas con los que tengamos acuerdos. Del mismo modo consientes en que dicha publicidad y/o comunicaciones comerciales podrán ser enviadas al correo electrónico que nos has facilitado en tu registro como usuario de Topdesti.</p>
          <p>Recuerda que Topdesti quiere hacer tu experiencia en la app lo más productiva posible, por lo que intentaremos que la publicidad y las promociones que te hacemos llegar se adapten a tu perfil. Nuestros sistemas automatizados analizan el contenido de tus comunicaciones para ofrecerte funciones de productos que sean relevantes para ti, como resultados de búsqueda y anuncios personalizados, y detección de software malicioso y spam. Este análisis se realiza cuando el contenido se envía, se recibe y cuando se almacena.</p>
          <p>En nuestra Política de privacidad y cookies te explicamos con más detalle qué cookies instala Topdesti para obtener y retener información tuya con lo que hacer tu navegación más dinámica e interesante.</p>

          <h4>Servicios de pago: contratación, tarifas y forma de pago</h4>
          <p>Topdesti podrá condicionar el acceso y/o uso a determinados servicios en Topdesti al pago de un precio o de una tarifa determinada, así como al cumplimiento de otros requisitos legales, comerciales o técnicos tales como la cumplimentación previa de formularios de solicitud de los datos del usuario que sean necesarios. En estos casos, la contratación de los servicios de pago disponibles en Topdesti se llevará a cabo, a través de los medios electrónicos habilitados en nuestra aplicación móvil, directamente entre el usuario y Topdesti, rigiéndose la misma por estas Condiciones, así como por lo dispuesto en cualesquiera otras adicionales que se pudiesen establecer al efecto por Topdesti.</p>
          <p>Por ello, antes de proceder a la contratación de nuestros productos y/o servicios de pago o los de terceros, recuerda comprobar siempre, en la información disponible en nuestra web y/o en la aplicación móvil, sus condiciones específicas, tarifas, impuestos aplicables, formas de pago, terminales compatibles, así como las funcionalidades asociadas a los mismos para valorar si se adecúan a tus expectativas. Además, si durante el proceso de contratación detectaras que los datos facilitados y/o forma de pago son incorrectos, erróneos, o deseas modificarlos, Topdesti podrá darte la opción de corregir los mismos hasta el momento en que se confirme la contratación.</p>
          <p>A estos efectos, serán de aplicación los precios, bonos y/o, en su caso, otras condiciones económicas, contratadas de acuerdo con las tarifas y demás condiciones generales y/o particulares, ofertas o promociones en vigor en el momento de su utilización. En este sentido, Topdesti se reserva el derecho a modificar los precios de sus productos y/o servicios en cualquier momento. Si continúas utilizando los servicios una vez comunicado el cambio de precio, entendemos que muestras tu aceptación.</p>
          <p>Ten en cuenta que, en aplicación de la legislación de consumidores vigente y, con carácter general, una vez hayas finalizado la compra o contratación no podrás reclamar la devolución o el reembolso de la cantidad abonada, ya que por la propia naturaleza de los servicios prestados por Topdesti, los mismos se encuentran exentos de la aplicación del derecho de desistimiento.</p>
          <p>En lo que respecta a la forma de pago y salvo que se indicase otra cosa, ésta será mediante la tarjeta bancaria del usuario, a través de una pasarela de pagos segura ofrecida en Topdesti. Una vez verificada la realización efectiva del pago correspondiente, Topdesti pondrá a disposición del usuario el justificante de la contratación donde se incluirá el detalle de los servicios contratados y las condiciones de los mismos.</p>
          <p>Topdesti no se hace responsable de la prestación de servicios de terceras empresas que hayas podido contratar a través de la Plataforma o en páginas externas.</p>

          <h4>Monetización</h4>
          <p>A través de Topdesti podrás ganar dinero por el contenido que creas, ya sea por medio de sponsors, donaciones rápidas de otros usuarios que encuentran útil tu información, con la creación de rutas personalizadas de pago a otros usuarios, a través de campañas de “Travel Angel” que pagan por las mejores rutas o por tus recomendaciones, según las condiciones particulares y los porcentajes vigentes de cada servicio en cada momento y que podrás consultar a través de la Plataforma.</p>

          <h4>Seguridad y privacidad</h4>
          <p>Recuerda que tú eres el único responsable de custodiar tus claves de acceso a Topdesti, así como de los posibles daños y/o perjuicios que se pudiesen ocasionar debido a un uso no autorizado de las mismas.</p>
          <p>Te recomendamos que no utilices una contraseña que contenga los mismos caracteres que tu nombre de usuario, ya que disminuye la protección sobre tu cuenta.</p>
          <p>En todo caso, queda prohibido el suministro de datos falsos, por tanto, te deberás identificar siempre con tus datos reales y veraces, así como facilitar un correo electrónico y/o número de teléfono móvil de tu propiedad y se te podrá requerir su verificación. Topdesti podrá desactivar temporalmente o cancelar cualquier cuenta de usuario automáticamente sin previo aviso y sin que ello te dé derecho a compensación o indemnización alguna. A modo de ejemplo, en el momento en que tengamos indicios de que has podido suplantar la identidad de un tercero en Topdesti, bien en tu alta de usuario, en la contratación de nuestros servicios y/o a la hora de verificar tu dirección de correo electrónico o número de teléfono móvil, procederemos a verificar tu identidad y/o tu número de teléfono móvil y, en su caso, a desactivar temporalmente y/o cancelar tu cuenta de Topdesti.</p>
          <p>Igualmente, Topdesti podrá poner en conocimiento de las autoridades competentes o bien colaborar con éstas, cualquier posible infracción de la legislación vigente que se detecte en Topdesti, especialmente en caso de que tal infracción pudiera suponer la comisión de delito.</p>
          <p>En nuestra Política de privacidad y Cookies te explicamos más detalladamente el tratamiento de datos y la protección de privacidad que realizamos en Topdesti.</p>

          <h4>Propiedad intelectual e industrial</h4>
          <p>Topdesti es el propietario y titular de todos los derechos sobre Topdesti (incluyendo tanto la plataforma web como sus aplicaciones para dispositivos móviles). En este sentido, se te otorga únicamente una licencia limitada, temporal, no exclusiva y revocable para que pueda utilizar, descargar y/o instalar Topdesti en tus dispositivos, conforme a lo previsto en estas Condiciones y para los usos previstos. En todo caso, Topdesti se reserva los derechos no expresamente otorgados al usuario en virtud de las presentes Condiciones.</p>
          <p>Como usuario de Topdesti eres el titular de todos los derechos de propiedad intelectual y eres responsable sobre el contenido que subas, almacenes o envíes a través de Topdesti, incluyendo fotografías, textos y videos. No obstante, al subir o generar estos contenidos a través de Topdesti, otorgas a Topdesti una licencia no exclusiva, de ámbito universal y limitada al tiempo que tengas activa tu cuenta en Topdesti y/o cuando decidas eliminarlos, para reproducir y comunicar los mismos en Topdesti y/o en páginas web o aplicaciones móviles de terceros y adaptarlos a las necesidades técnicas y comerciales de Topdesti.</p>

          <h4>Modificaciones</h4>
          <p>Topdesti se reserva el derecho de revisar las presentes Condiciones en cualquier momento por razones legales, por motivos técnicos, por cambios en los servicios ofrecidos en Topdesti o por decisiones estratégicas o comerciales. En ese caso, te avisaremos de ello publicándose en Topdesti y, si continúas utilizándolo y no solicitas tu baja como usuario, entenderemos que aceptas las modificaciones.</p>

          <h4>Concursos</h4>
          <p>Los concursos organizados por Topdesti, se regirán por las bases legales particulares que se establezcan para cada uno de ellos.</p>

          <h4>Ley y jurisdicción aplicable</h4>
          <p>La normativa vigente determinará las leyes que deban regir y la jurisdicción que deba conocer las relaciones entre Topdesti y los usuarios. No obstante, en aquellos casos en los que la normativa vigente no prevea la obligación de someterse a un fuero o legislación determinado, Topdesti y sus usuarios, con renuncia a cualquier otro fuero que pudiera corresponderles, se someten a los juzgados y tribunales de Madrid capital (España).</p>
          <p>Las presentes Condiciones de Uso han sido elaboradas de conformidad con lo establecido en la Ley 34/2002, de Servicios de la Sociedad de la Información y de Comercio Electrónico, la Ley 7/1998 sobre Condiciones Generales de Contratación, el Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, la Ley 7/1996, de 15 de enero, de Ordenación del Comercio Minorista, el Reglamento (UE) 2016/679 de Protección de Datos de Carácter Personal y cuantas disposiciones legales resulten de aplicación.</p>
          <p>
            En cumplimiento de lo establecido en la Directiva 2013/11/EU del Parlamento Europeo y del Consejo, de 21 de mayo, de 2013, relativa a la resolución alternativa de litigios en materia de consumo, y del Reglamento 524/2013 del Parlamento y del Consejo, de 21 de mayo de 2013, sobre resolución de litigios en línea en materia de consumo, ponemos en conocimiento de todos los Usuarios que podrán llevar a cabo sus reclamaciones en materia de consumo a través de la plataforma online de resolución de conflictos en línea a la cual podrán acceder a través del siguiente enlace
            <a href="http://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer"> http://ec.europa.eu/consumers/odr/</a>
          </p>
          <h4>Responsabilidad</h4>
          <p>Topdesti no asume la obligación de controlar los contenidos que transmitan o compartan los usuarios a través de Topdesti pero sí tratará de evitar y comunicar a las autoridades pertinentes cuando tenga conocimiento de ello, en la medida de lo posible, que se transmitan y difundan contenidos y/o mensajes que puedan ser claramente ilícitos o que, de cualquier modo vayan contra la moral, el orden público o las buenas costumbres.</p>
          <p>Topdesti no será responsable de los problemas de acceso o disponibilidad de Topdesti, ni de los perjuicios que se pudieran causar por ello, cuando éstos procedan de factores ajenos a su ámbito de control y actividad como son, por ejemplo, la velocidad de tu conexión a internet, el tipo de terminal o dispositivo utilizado, tu ubicación geográfica, etc.</p>
          <p>De la misma forma, recuerda que tú eres el único responsable de las interacciones que tengas con otros usuarios de Topdesti por lo que nosotros no seremos responsables de los conflictos que, en su caso, pudieses tener al margen de lo previsto en estas Condiciones. Topdesti no estará obligado pero se reserva el derecho a, en caso de ser necesario, mediar en dichos conflictos en caso de que surjan.</p>
          <p>Igualmente, te informamos de que Topdesti no se hace responsable de los fallos, incompatibilidades y/o daños de tus terminales o dispositivos que, en su caso, se pudiesen derivar de la descarga y/o uso de Topdesti, especialmente cuando éstos no sean compatibles o adecuados para utilizar nuestros servicios.</p>

          <h4>Uso internacional e idioma</h4>
          <p>Entiendes y compartes que Topdesti está pensada para ser utilizada por cualquier persona que cumpla las presentes Condiciones, en cualquier país en el que su uso no sea contrario a las leyes o normativas locales vigentes. Asimismo, es posible que Topdesti tenga ciertas restricciones, no funcione adecuadamente o bien todos sus servicios no estén disponibles en todos los países.</p>
          <p>El uso de Topdesti por parte de los usuarios dentro de un país concreto se hará bajo la responsabilidad de éstos. Únicamente tú eres responsable de cumplir las leyes de los países desde los que te registres y/o accedas a Topdesti, así como desde los que utilices nuestros servicios.</p>
          <p>Si bien las presentes Condiciones se ofrecen traducidas a varios idiomas para garantizar que puedan ser leídas y comprendidas por todos nuestros usuarios, éstos declaran conocer y aceptar que siempre prevalecerá la versión en español en el supuesto de que pudiesen existir contradicciones entre las distintas versiones.</p>
        </div>
      </div>
    </main>
    <Footer />
  </MainLayout>
);

export default ConditionsPage;
