import React, { useState, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BED_HOTEL_ICON,
  RESTAURANT_ICON,
  ACTIVITIES_ICON,
  SHOPPING_BAG_ICON,
  CAR_ICON,
} from '../../../helpers';
import { isEmpty } from '../../../utils';
import TYPES from '../../../constants/types';
import ENV from '../../../config/environment';

class RecommendationsTabsBar extends Component {
  state = {
    recommendationsState: {
      hasAccomodations: false, // Alojamiento - 100
      hasRestaurants: false, // Restauración - 101
      hasActivities: false, // Actividades - 102
      hasRenting: false, // Alquiler de coches - 103
      hasShoping: false, // Compras - 104
    }
  }

  componentDidMount() {
    this.updateDay();
    /*   this.setState({activeDay:this.props.activeDay}) */

  }
  componentDidUpdate() {
    if (this.props.activeDay != this.state.activeDay) {
      this.updateDay()
    }
  }
  async updateDay() {
    let servicePois;
    const recommendations = isEmpty(this.props.tourContents)
      ? []
      : this.props.tourContents.filter(tourContent => tourContent.attributes['content-type'] === 2);
    let getservicePois = await fetch(`${ENV.host}/${ENV.namespace}/pois/servicePois`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        recommendations
      }),
    })
    getservicePois.json().then((data) => {
      servicePois = data.pois
      if(servicePois.length == 0) {
        this.setState({
          recommendationsState: {
            hasAccomodations: false, // Alojamiento - 100
            hasRestaurants: false, // Restauración - 101
            hasActivities: false, // Actividades - 102
            hasRenting: false, // Alquiler de coches - 103
            hasShoping: false, // Compras - 104
          }
        })
      } else {
        const { activeCategory, handleChangeCategory } = this.props;
        const serviceCategories = servicePois.map(service => service.category);
        const newRecommendationsState = {
          hasAccomodations: serviceCategories.includes(100),
          hasRestaurants: serviceCategories.includes(101),
          hasActivities: serviceCategories.includes(102),
          hasRenting: serviceCategories.includes(103),
          hasShoping: serviceCategories.includes(104),
        };
        const categories = [100, 101, 102, 103, 104];
        const initialCategory = Object.values(newRecommendationsState)[categories.indexOf(activeCategory)] === true
          ? activeCategory
          : categories[Object.values(newRecommendationsState).indexOf(true)];
        if (!isEmpty(initialCategory)) {
          this.setState({ recommendationsState: newRecommendationsState });
        }
  
        handleChangeCategory(initialCategory);
        this.setState({ activeDay: this.props.activeDay })
      }
    })
  }

  render() {
    const { activeCategory, handleChangeCategory, servicePois } = this.props;

    /* const [recommendationsState, setRecommendationsState] = useState({
      hasAccomodations: false, // Alojamiento - 100
      hasRestaurants: false, // Restauración - 101
      hasActivities: false, // Actividades - 102
      hasRenting: false, // Alquiler de coches - 103
      hasShoping: false, // Compras - 104
    }); */
    /* useEffect(() => {
      
    }, []); */

    const {
      hasAccomodations, hasRestaurants, hasActivities, hasRenting, hasShoping,
    } = this.state.recommendationsState;

    return (
      <div className="recommendations-tabs-bar">
        <button
          type="button"
          onClick={() => handleChangeCategory(100)}
          className={`recommendations-tabs-bar__btn${
            activeCategory === 100 ? ' recommendations-tabs-bar__btn--active' : ''
            } ${hasAccomodations ? '' : 'recommendations-tabs-bar__btn--disabled'}`}
          disabled={!hasAccomodations}
        >
          {BED_HOTEL_ICON}
        </button>

        <button
          type="button"
          onClick={() => handleChangeCategory(101)}
          className={`recommendations-tabs-bar__btn${
            activeCategory === 101 ? ' recommendations-tabs-bar__btn--active' : ''
            } ${hasRestaurants ? '' : 'recommendations-tabs-bar__btn--disabled'}`}
          disabled={!hasRestaurants}
        >
          {RESTAURANT_ICON}
        </button>
        <button
          type="button"
          onClick={() => handleChangeCategory(102)}
          className={`recommendations-tabs-bar__btn${
            activeCategory === 102 ? ' recommendations-tabs-bar__btn--active' : ''
            } ${hasActivities ? '' : 'recommendations-tabs-bar__btn--disabled'}`}
          disabled={!hasActivities}
        >
          {ACTIVITIES_ICON}
        </button>
        <button
          type="button"
          onClick={() => handleChangeCategory(104)}
          className={`recommendations-tabs-bar__btn${
            activeCategory === 104 ? ' recommendations-tabs-bar__btn--active' : ''
            } ${hasShoping ? '' : 'recommendations-tabs-bar__btn--disabled'}`}
          disabled={!hasShoping}
        >
          {SHOPPING_BAG_ICON}
        </button>
        <button
          type="button"
          onClick={() => handleChangeCategory(103)}
          className={`recommendations-tabs-bar__btn${
            activeCategory === 103 ? ' recommendations-tabs-bar__btn--active' : ''
            } ${hasRenting ? '' : 'recommendations-tabs-bar__btn--disabled'}`}
          disabled={!hasRenting}
        >
          {CAR_ICON}
        </button>
      </div>
    );
  }
}

RecommendationsTabsBar.propTypes = {
  handleChangeCategory: PropTypes.func.isRequired,
  activeCategory: PropTypes.number,
  servicePois: PropTypes.arrayOf(TYPES.poiType),
};
RecommendationsTabsBar.defaultProps = {
  activeCategory: 100,
  servicePois: [],
};

function mapStateToProps(state, ownProps) {
  const { tourContents } = ownProps;
  const recommendations = isEmpty(tourContents)
    ? []
    : tourContents.filter(tourContent => tourContent.attributes['content-type'] === 2);

  const servicePois = recommendations.reduce((acc, recommendation) => {
    const {
      data: { id: poiId },
    } = recommendation.relationships.poi;
    const servicePoi = state.bees.entities.pois[poiId];
    if (!isEmpty(servicePoi)) {
      acc.push(servicePoi);
    }
    return acc;
  }, []);

  return {
    servicePois,
    recommendations,
  };
}

export default connect(mapStateToProps)(RecommendationsTabsBar);