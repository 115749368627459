import React from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import {
  EXPAND_ICON, PLUS_ICON, INBOX_ICON, CLOSE_ICON,
} from '../../../../helpers';
// import { Sticky, StickyContentWrapper } from '../../../../components';
import TourContentsList from './parts/tour-contents-list/index';
import handleError from '../../../../utils/errorHandler';
import { isEmpty } from '../../../../utils';
import TYPES from '../../../../constants/types';
import { PromiseButton } from '../../../../components';

const SHOW_TOUR_CONTENT = {
  showSearchTab: false,
  showTourContentModal: true,
  showConfigModal: false,
  showPreviewTab: false,
  showTourContentMenuModal: false,
};

const SHOW_TOUR_CONTENT_CREATION_MENU = {
  showSearchTab: false,
  showTourContentModal: false,
  showConfigModal: false,
  showPreviewTab: false,
  showTourContentMenuModal: true,
};

class DayContent extends React.Component {
  static sortDayTourContents(tourContentsArray) {
    return tourContentsArray.sort((a, b) => a.attributes.order - b.attributes.order);
  }

  static getUpdatedOrderLocalTourContents(
    currDayTourContents,
    movedTourContents,
    startIndex,
    newIndex,
  ) {
    const reorderDayTourContents = [...currDayTourContents];
    reorderDayTourContents.splice(startIndex, 1);
    reorderDayTourContents.splice(newIndex, 0, movedTourContents);
    return reorderDayTourContents.map((tourContent, index) => {
      const updatedTourContent = { ...tourContent };
      updatedTourContent.attributes.order = index + 1;
      return updatedTourContent;
    });
  }

  static getMappedToursContentsDays(tourContentsData) {
    const tourContentsMappedToDays = {};
    tourContentsData.forEach((tourContent) => {
      const newTourContent = { ...tourContent };
      const { attributes } = tourContent;
      const { day } = attributes;
      let currentDayLatestTourContentArray = [];
      if (tourContentsMappedToDays[day]) {
        currentDayLatestTourContentArray = [...tourContentsMappedToDays[day]];
      }
      tourContentsMappedToDays[day] = [...currentDayLatestTourContentArray, newTourContent];
    });
    Object.keys(tourContentsMappedToDays).forEach((key) => {
      tourContentsMappedToDays[key] = DayContent.sortDayTourContents(tourContentsMappedToDays[key]);
    });
    return tourContentsMappedToDays;
  }

  static getDerivedStateFromProps(nextProps) {
    const { tourContentsData: nextToursData } = nextProps;
    const nextDayContentMap = DayContent.getMappedToursContentsDays(nextToursData);
    return { tourContentsMappedToDays: nextDayContentMap };
  }

  constructor(props) {
    super(props);

    this.state = {
      tourContentsMappedToDays: {},
      ongoingDrag: false,
    };
    this.mapToursContentsDays = this.mapToursContentsDays.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.serverTourContentsReorder = this.serverTourContentsReorder.bind(this);
    this.localTourContentsReorder = this.localTourContentsReorder.bind(this);
    this.handleSliderShift = this.handleSliderShift.bind(this);

    this.scrollableListRef = React.createRef();
  }

  componentDidMount() {
    const { mapToursContentsDays } = this;
    mapToursContentsDays();
  }

  onDragStart() {
    this.setState({ ongoingDrag: true });
  }

  onDragEnd(result) {
    const { serverTourContentsReorder, localTourContentsReorder } = this;
    const { activeDay } = this.props;
    const { tourContentsMappedToDays } = this.state;
    const { destination, source } = result;
    const {
      destination: { index: newIndex = null } = {},
    } = result || {};
    const { index: startIndex = null } = source || {};
    if (!destination || (destination.droppableId === source.droppableId && newIndex === startIndex)) return;

    const { [activeDay]: currDayTourContents } = tourContentsMappedToDays;
    const movedTourContents = currDayTourContents[startIndex];
    localTourContentsReorder(result)
      .then(() => serverTourContentsReorder(movedTourContents, newIndex))
      .finally(() => {
        this.setState({ ongoingDrag: false });
      });
  }

  localTourContentsReorder(result) {
    return new Promise((resolve) => {
      const { activeDay, triggerOrderSynchWithModal } = this.props;
      const { tourContentsMappedToDays } = this.state;
      const {
        destination: { index: newIndex },
        source: { index: startIndex },
      } = result;

      const { [activeDay]: currDayTourContents } = tourContentsMappedToDays;
      const movedTourContent = currDayTourContents[startIndex];
      movedTourContent.attributes.order = newIndex;

      const reorderedDayTourContents = DayContent.getUpdatedOrderLocalTourContents(
        currDayTourContents,
        movedTourContent,
        startIndex,
        newIndex,
      );

      const newState = {
        tourContentsMappedToDays: {
          ...tourContentsMappedToDays,
          [activeDay]: reorderedDayTourContents,
        },
      };
      this.setState(newState, () => {
        triggerOrderSynchWithModal();
        resolve();
      });
    });
  }

  serverTourContentsReorder(movedTourContents, newIndex) {
    const { activeDay, handleServerTourContentsReoreder } = this.props;
    const { attributes: movedTourAttrs } = movedTourContents;

    const payload = {
      id: movedTourContents.id,
      type: movedTourContents.type,
      attributes: { ...movedTourAttrs, order: newIndex + 1, day: activeDay },
    };
    return handleServerTourContentsReoreder(movedTourContents.id, payload).catch(err =>
      handleError(err),
    );
  }

  mapToursContentsDays() {
    return new Promise((resolve) => {
      const { tourContentsData } = this.props;
      const tourContentsMappedToDays = DayContent.getMappedToursContentsDays(tourContentsData);
      this.setState({ tourContentsMappedToDays }, resolve);
    });
  }

  handleSliderShift(direction) {
    if (!direction) return;
    const { scrollableListRef } = this;
    const slider = scrollableListRef.current;
    const scrollToOptions = { behavior: 'smooth' };
    // image width is 150 fixed + 20 padding both sides, total 190
    // every box has a 20 left marging, total sum 210
    scrollToOptions.left = direction === 1 ? 210 : -210;
    slider.scrollBy(scrollToOptions);
  }

  render() {
    const {
      onDragStart, onDragEnd, scrollableListRef, handleSliderShift,
    } = this;
    const {
      tour,
      activeDay,
      isMobile,
      handleNavigation,
      handleContentSelection,
      handleDaySelection,
      handleAddDayToTour,
      handleRemoveDay,
      handleDeleteTourContent,
      handleToogleTourContentVisibility,
      isLoadingContent,
    } = this.props;
    const { tourContentsMappedToDays, ongoingDrag } = this.state;
    const currDayTourContents = tourContentsMappedToDays[activeDay] || [];
    const { 'tour-duration': tourDuration = 1, 'tour-type': tourType } = tour.attributes || {};
    const days = [];
    for (let i = 1; i <= tourDuration; i += 1) {
      if (i === activeDay) {
        days.push({ value: i, text: tour && tour.attributes.selection ? `PÁGINA ${i}` : `DIA ${i}`, active: true });
      } else {
        days.push({ value: i, text: i, active: false });
      }
    }

    return isMobile ? null : (
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div className="container-fluid day-content">
          <div className="row">
            <div className="container">
              <div className="row day-content__captions">
                <div className="col-auto">
                  <p className="day-content__captions-item">
                    <span className="day-content__captions-item-icon">{INBOX_ICON}</span>
                    <span className="day-content__captions-item-text">
                      Contenido en el interior
                    </span>
                  </p>
                </div>
                <div className="col-auto">
                  <p className="day-content__captions-item day-content__captions-item--blue">
                    <span className="day-content__captions-item-text">Recomendación</span>
                  </p>
                </div>
                {/* <div className="col-auto">
                  <p className="day-content__captions-item day-content__captions-item--green">
                    <span className="day-content__captions-item-text">Consejo de viaje</span>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          <div
            className={`row justify-content-center day-content__content${
              isEmpty(currDayTourContents) ? ' day-content__content--no-bg' : ''
            }`}
          >
            <div className="col-auto day-content__slider-wrapper w-100">
              {parseInt(tourType, 10) === 1 ? null : (
                <ul className="day-content__days">
                  {days.map((day, index) => (
                    <li
                      className={`day-content__days-item ${
                        day.active ? 'day-content__days-item--active' : ''
                      }`}
                      key={`day${day.value}`}
                    >
                      <button
                        type="button"
                        className="day-content__days-btn"
                        onClick={() => handleDaySelection(index + 1)}
                      >
                        {day.text}
                      </button>
                    </li>
                  ))}
                  <li
                    className="day-content__days-item day-content__days-item--action"
                    key="add-day"
                  >
                    <PromiseButton
                      type="button"
                      className="day-content__days-btn"
                      title={tour && tour.attributes.selection ? "Añadir página" : "Añadir día"}
                      onClick={handleAddDayToTour}
                    >
                      {PLUS_ICON}
                    </PromiseButton>
                  </li>
                  <li
                    className="day-content__days-item day-content__days-item--action"
                    key="remove-day"
                  >
                    <PromiseButton
                      type="button"
                      className="day-content__days-btn day-content__days-btn--red"
                      title={tour && tour.attributes.selection ? "Eliminar página" : "Eliminar día"}
                      onClick={handleRemoveDay}
                    >
                      {CLOSE_ICON}
                    </PromiseButton>
                  </li>
                </ul>
              )}
              {/*
          <Sticky sides={{ top: 0 }}>
            <StickyContentWrapper hideIfNotStuck>testing sticky component</StickyContentWrapper>
          </Sticky>
          */}
              {isLoadingContent ? (
                <span className="day-content__loading-spinner">
                  <img
                    src="/assets/images/spinner.svg"
                    alt="Cargando..."
                    className="day-content__loading-spinner-img"
                  />
                  <p className="mb-0">Cargando contenido...</p>
                </span>
              ) : isEmpty(currDayTourContents) ? (
                <h4 className="col-12 text-center">
                  {'No tienes ningún contenido en tu ruta, añade destinos desde '
                  + 'el mapa o contenido personal pulsando el icono “+”.'}
                </h4>
              ) : (
                <>
                  <button
                    type="button"
                    className="day-content__back"
                    onClick={() => {
                      handleSliderShift(-1);
                    }}
                  >
                    <span className="sr-only">Volver</span>
                  </button>

                  <TourContentsList
                    scrollableListRef={scrollableListRef}
                    handleContentSelection={handleContentSelection}
                    currDayTourContents={currDayTourContents}
                    handleDeleteTourContent={handleDeleteTourContent}
                    handleToogleTourContentVisibility={handleToogleTourContentVisibility}
                    dropAreaId="tourContentList1"
                    ongoingDrag={ongoingDrag}
                    pois={this.props.pois}
                  />
                  <button
                    type="button"
                    className="day-content__forward"
                    onClick={() => {
                      handleSliderShift(1);
                    }}
                  >
                    <span className="sr-only">Seguir</span>
                  </button>
                </>
              )}
            </div>

            {/* <div className={`col-auto ${isEmpty(currDayTourContents) ? '' : 'align-self-end'}`}>
              <button
                className="day-content__action day-content__action--small-icon"
                title="Crear nuevo contenido"
                type="button"
                onClick={() => handleNavigation(SHOW_TOUR_CONTENT_CREATION_MENU)}
              >
                {PLUS_ICON}
              </button>

              {isEmpty(currDayTourContents) ? null : (
                <button
                  type="button"
                  className="day-content__action"
                  title="Expandir"
                  onClick={() => handleNavigation(SHOW_TOUR_CONTENT)}
                >
                  {EXPAND_ICON}
                </button>
              )}
            </div> */}
          </div>
        </div>
      </DragDropContext>
    );
  }
}

DayContent.propTypes = {
  triggerOrderSynchWithModal: PropTypes.func.isRequired,
  handleToogleTourContentVisibility: PropTypes.func.isRequired,
  handleServerTourContentsReoreder: PropTypes.func.isRequired,
  tour: TYPES.tourType.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleNavigation: PropTypes.func.isRequired,
  handleContentSelection: PropTypes.func.isRequired,
  handleDeleteTourContent: PropTypes.func.isRequired,
  handleDaySelection: PropTypes.func.isRequired,
  handleAddDayToTour: PropTypes.func.isRequired,
  handleRemoveDay: PropTypes.func.isRequired,
  activeDay: PropTypes.number.isRequired,
  tourContentsData: PropTypes.arrayOf(TYPES.tourContentType),
  isLoadingContent: PropTypes.bool.isRequired,
};
DayContent.defaultProps = {
  tourContentsData: [],
};

function mapStateToProps(state) {
  return { isMobile: state.viewport.currentWidth <= 991 };
}

export default connect(mapStateToProps)(DayContent);
