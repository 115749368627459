import React, { useState, useCallback } from 'react';
import Lightbox from 'react-image-lightbox';
import { isEmpty } from '../../utils';

const WHAT_IS_TOPDESTI = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">¿Qué es Topdesti?</h4>
        <p className="faqs-page__question-text">
        Topdesti es una plataforma con la que podrás crear en pocos minutos rutas
         de tus viajes, compartirlas con la comunidad de usuarios y ganar dinero
         con ellas cumpliendo el sueño de todo nómada aventurero: &quot;Ganar dinero
         con tus viajes&quot;.
        </p>

        <p className="faqs-page__question-text">Los cuatro objetivos principales de Topdesti son:</p>
        <ol className="faqs-page__question-list">
          <li className="faqs-page__question-list-item">
          Solucionar un problema para buscar información de destinos o planificar un viaje de manera rápida y sencilla.
          </li>
          <li className="faqs-page__question-list-item">
          Conocer a viajeros con perfiles similares con los que poder compartir experiencias en la vida real.
          </li>
          <li className="faqs-page__question-list-item">
          Lograr un retorno económico de nuestro contenido de viajes,
           por medio principalmente de nuestras recomendaciones.
          </li>
          <li className="faqs-page__question-list-item">
          Fomentar prácticas y actitudes que hagan de los viajes una actividad más sostenible.
          </li>
        </ol>
      </div>
    </div>
  </article>
);

const HOW_WORKS = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          ¿Cómo funciona Topdesti?
        </h4>
        <p className="faqs-page__question-text">
          El funcionamiento de nuestra plataforma es muy sencillo y
           básicamente consiste en premiar económicamente a la mejor
           herramienta de marketing creada jamás: &quot;la recomendación de
           una persona con la que tengamos afinidad o confianza&quot;.
        </p>
        <p className="faqs-page__question-text">
        En nuestras rutas podremos combinar información útil de destinos junto con recomendaciones
         de servicios turísticos o productos. Estas recomendaciones funcionan en sí mismas como un
         canal directo de venta, por medio de planes de afiliados de terceros, con los cuales
         podremos ganar comisiones por las reservas que se hagan desde nuestro contenido.
        </p>
        <p className="faqs-page__question-text">
        Por ejemplo, creamos una ruta que se llame &quot;3 días para conocer los secretos de Gran
         Canaria&quot;. Además de los recursos turísticos para visitar podemos incluir en ella una
         recomendación de un hotel donde dormir o algunas actividades. Cualquier usuario que utilice
         la ruta como fuente de información puede reservar ese hotel o actividad y nosotros
         recibiremos una comisión por ello. Todo al mismo precio que si usa por ejemplo directamente
         Booking.com y con las mismas condiciones de contratación que éstos.
        </p>
        <p className="faqs-page__question-text">
        También podremos recomendar productos que utilizamos en nuestros viajes y que nos son de gran
         utilidad. Por ejemplo, un fotógrafo puede recomendar la cámara que usa, poner su enlace de
         afiliado de Amazon, y ganar una comisión por cada venta sin necesidad de tener ningún stock,
         enviar productos, etc… Solamente tu recomendación es suficiente para ganar dinero.
        </p>
        <p className="faqs-page__question-text">
        Cuanto mejor sea tu contenido más visibilidad tendrá en la plataforma y por lo tanto más
         posibilidades de recibir ingresos. Las mejores rutas tendrán un distintivo de &quot;Staff Pick&quot;
         con lo que saldrán las primeras en los resultados de búsquedas.
        </p>
      </div>

    </div>
  </article>
);

const PROPERLY_CONTENT = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          ¿Qué contenido podemos crear y compartir con Topdesti?
        </h4>
        <ol className="faqs-page__question-list">
          <li className="faqs-page__question-list-item">
            <strong>Destinos:</strong>
            <p className="faqs-page__question-text">
            Se trata de información de recursos turísticos de una zona por medio de imágenes
             y una breve descripción y que después formarán los elementos principales de las
             rutas de los usuarios. Son abiertas al trabajo colaborativo y el proceso de creación
             permitirá además ganar dinero a sus autores por medio de banderas.
            </p>
          </li>
          <li className="faqs-page__question-list-item">
            <strong>Servicios:</strong>
            <p className="faqs-page__question-text">
            Información de servicios turísticos que podrán ser recomendados posteriormente en las
             rutas de la comunidad de usuarios, como hoteles, restaurantes, etc…
            </p>
          </li>
          <li className="faqs-page__question-list-item">
            <strong>Rutas:</strong>
            <p className="faqs-page__question-text">
            Las rutas son la combinación de los dos elementos anteriores, seleccionando los mejores
             destinos y servicios para diseñar el viaje más cercano a tus intereses. Se pueden además
             personalizar con nuestras propias imágenes, vídeos y textos.
            </p>
          </li>
          <li className="faqs-page__question-list-item">
            <strong>Colecciones (próximamente):</strong>
            <p className="faqs-page__question-text">
            Un &quot;cajón&quot; donde guardar de forma temática todo el contenido que encontramos de
             interés. Por ejemplo podemos hacer una Colección que le llamamos &quot;Rutas de fin de
             semana desde Madrid&quot; y ahí guardamos todas las rutas, destinos, imágenes o
             recomendaciones tanto propias como de otros usuarios que nos hayan gustado.
            </p>
          </li>
        </ol>
      </div>
    </div>
  </article>
);

const BETA_VERSION = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
        Versión Beta
        </h4>
        <p className="faqs-page__question-text">
        La plataforma actual se encuentra en una fase de “testeo” por lo que está únicamente
         centrada en rutas y destinos de Gran Canaria. A principios del 2020 lanzaremos la
         versión en abierto del resto de España con muchas nuevas funcionalidades que iremos
         incorporando al proyecto.
        </p>
        <p className="faqs-page__question-text">
        Como todas las versiones Beta, es posible que puedas encontrar algunos errores de
         funcionamiento, por lo que desde ya te pedimos disculpas por ello. Si lo deseas puedes
         echarnos una mano a mejorar nuestra web indicando cualquier error detectado o mejora
         que podamos hacer por medio del correo:
          <a href="mailto:ayuda@topdesti.com"> ayuda@topdesti.com</a>
        </p>
      </div>

    </div>
  </article>
);

const USER_TYPES = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          Tipos de usuarios:
        </h4>
        <h5 className="faqs-page__question-subtitle">
          Toptravelers:
        </h5>
        <p className="faqs-page__question-text">
        Se trata de creadores de contenido que destacan por la calidad del mismo (fotos, vídeos, etc..).
         Tienen una serie de ventajas como que su contenido no tiene que ser validado para ser público
         o que pueden disfrutar de todos los servicios premium de forma gratuita. Sus &quot;me gusta&quot; dentro
         de rutas o destinos tienen mucho mayor peso que el resto de usuarios de la comunidad.
        </p>

        <h5 className="faqs-page__question-subtitle">
          Usuario estandar:
        </h5>
        <p className="faqs-page__question-text">
        Tiene todas las funcionalidades de creación y visualización de rutas habilitadas, pero con publicidad
         y sin algunas opciones de monetización y visualización de sus recomendaciones de los usuarios premium.
        </p>
        <h5 className="faqs-page__question-subtitle">
          Usuario premium:
        </h5>
        <p className="faqs-page__question-text">
        Los usuarios premium tienen una suscripción anual de 20 euros y con lo que disfrutarán de servicios
         especiales como:
        </p>
        <ul className="faqs-page__question-list">
          <li className="faqs-page__question-list-item">
          Visualizan todo el contenido deTopdesti sin publicididad
          </li>
          <li className="faqs-page__question-list-item">
          Pueden gestionar sus propios patrocinios en sus rutas ganando el 100% de sus ingresos. Es importante destacar
            que sus rutas no tendrán ningún elemento publicitario, por lo que es de gran interés para los patrocinadores
            ya que serían exclusivos en ese contenido.
          </li>
          <li className="faqs-page__question-list-item">
          Pueden asegurarse &quot;banderas&quot; de los destinos creados por ellos y ganar dinero mediante ellas
            (
            <button
              type="button"
              className="btn btn-link p-0"
            >
              Información de banderas
            </button>
          )
          </li>
          <li className="faqs-page__question-list-item">
          Disponen de recomendaciones y cajas de perfil &quot;enriquecidos&quot;.
          </li>
          <li className="faqs-page__question-list-item">
          Ganan el 100% de los premios de los concursos de Travel Angel frente al 50% de los usuarios premium.
           (Próximamente)
          </li>
          <li className="faqs-page__question-list-item">
          Disponen de &quot;cajas de perfil&quot; enriquecidas con un sistema automatizado de donaciones donde
           reciben el 100% de las mismas.
          </li>
        </ul>
        <p className="faqs-page__question-text">
        Visita la sección especifica de las características &quot;Premium&quot; para conocer los precios y
         estos servicios más a fondo.
        </p>
        <h5 className="faqs-page__question-subtitle">
          Travel Angel:
        </h5>
        <p className="faqs-page__question-text">
        Los Travel Angel son marcas o agentes turísticos que patrocinan rutas o destinos de su interés
        , y que permite a sus creadores obtener financiación para continuar con sus viajes.
        </p>
        <p className="faqs-page__question-text">
        Podrán también convocar eventos y concursos dentro de la plataforma disponibles para toda la comunidad
         de usuarios.
        </p>
      </div>
    </div>
  </article>
);

const PENALIZED_BEHAVIORS = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
        Conductas penalizadas
        </h4>
        <p className="faqs-page__question-text">
        En Topdesti buscamos ser una plataforma de encuentro entre viajeros, donde se pongan en
         valor los recursos turísticos desde una perspectiva de sostenibilidad y los valores
         humanos que hacen del viajar una de las actividades más enriquecedoras que existen.
         Cualquier comportamiento que vaya en contra del respeto al medio ambiente o a otras
         personas será penalizado con el borrado de contenido ofensivo y el bloqueo de perfiles
         con comportamientos inadecuados.
        </p>
        <ul className="faqs-page__question-list">
          <p className="faqs-page__question-text">
          Entre el contenido no autorizado en nuestra plataforma destacamos:
          </p>
          <li className="faqs-page__question-list-item">
            El que incluya violencia tanto en imágenes como en texto.
          </li>
          <li className="faqs-page__question-list-item">
            El que muestre o incite al maltrato animal.
          </li>
          <li className="faqs-page__question-list-item">
            El que fomente la explotación de trabajadores locales.
          </li>
          <li className="faqs-page__question-list-item">
            El que muestre pornografía.
          </li>
        </ul>
        <p className="faqs-page__question-text">
        También será penalizado el plagio o la utilización de contenido que no sea propio, bien
         copiando de forma literal rutas de otros autores o utilizando fotografías de las cuales
         no somos los propietarios de sus derechos intelectuales.
        </p>
      </div>
    </div>
  </article>
);

const BECOME_COLLABORATOR = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
        Hazte colaborador:
        </h4>
        <p className="faqs-page__question-text">
        Como plataforma de nueva creación estamos todavía en una fase de crecimiento donde una
         mano será más que valiosa para alcanzar nuestros objetivos. Entre las formas en las
         que puedes colaborar con nosotros podemos citar:
        </p>
        <ul className="faqs-page__question-list">
          <li className="faqs-page__question-list-item">
          Avisandonos de cualquier error que cometamos: Especialmente en esta fase beta, es posible que
           encuentres fallos en funcionalidades o incluso faltas ortográficas en los textos. También es
           posible que se nos cuele algún error en el contenido, o una foto de un lugar equivocado.
           Avísanos de cualquiera de ellos en el correo
            <a href="mailto:ayuda@topdesti.com"> ayuda@topdesti.com</a>
          </li>
          <li className="faqs-page__question-list-item">
          Difundiendo nuestro proyecto en redes sociales, etc.
          </li>
          <li className="faqs-page__question-list-item">
          Reservando tus alojanmientos desde las recomendaciones de los miembros de la comunidad de creadores.
          </li>
          <li className="faqs-page__question-list-item">
          Creando contenido: Añade nuevos destinos que conozcas en tu zona o ayúdanos con su traducción en distintos
           idiomas para lograr su máxima difusión.
          </li>
          <li className="faqs-page__question-list-item">
          Sugiriendo mejoras o ideas de cualquier funcionalidad nueva que eches en falta y crees que puede ser de
           utilizad para los viajeros.
          </li>
          <li className="faqs-page__question-list-item">
            Patrocinando recursos: Uno de los grandes problemas que tiene cualquier
             proyecto nuevo es la generación de ingresos, imprescindibles para crecer y
             ofrecer mejores servicios. Los patrocinios nos ayudarán a obtener recursos
             económicos tanto a nosotros como a los viajeros generadores de contenido.
          </li>
        </ul>
      </div>

    </div>
  </article>
);

const NEXT_FEATURES = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
        Próximas funcionalidades:
        </h4>
        <p className="faqs-page__question-text">
          <strong>Búsqueda de usuarios: </strong>
        Podrás conocer los viajeros o locales de una zona y consultar sus rutas, enviarles un mensaje, etc.
        </p>
        <p className="faqs-page__question-text">
          <strong>Rutas calendario: </strong>
        Sería un Bla Bla Car aplicado a las rutas turísticas. Crea tu itinerario, la fecha y condiciones y
         busca a otros viajeros para que te acompañen.
        </p>
        <p className="faqs-page__question-text">
          <strong>Colecciones: </strong>
        Un cajón donde recopilar todo el contenido que quieras de interés.. rutas, destinos, imágenes,
         usuarios que guarden una relación temporal o de temática, por ejemplo: &quot;Mi viaje interrail
         2020 por Europa&quot;.
        </p>
        <p className="faqs-page__question-text">
          <strong>Red social: </strong>
        Funcionalidades de notificaciones, seguimiento de usuarios, etc.
        </p>
        <p className="faqs-page__question-text">
          <strong>Gastos: </strong>
        Añade a tus rutas los gastos del viaje. Podrán servirte para tener un control de tu contabilidad o
         como referencia para otros viajeros que desean visitar el mismo lugar.
        </p>
        <p className="faqs-page__question-text">
          <strong>Ofertas: </strong>
        Puedes llegar a acuerdos con los servicios que recomiendes y añadir ofertas para tus seguidores.
         De esta forma tus rutas serán mucho más interesantes y podrás mejorar su monetización con un mayor
         número de reservas o con patrocinadores.
        </p>
      </div>
    </div>
  </article>
);

const CONTACT_TOPDESTI = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
        Contactar con Topdesti:
        </h4>
        <p className="faqs-page__question-text">
        Nuestra sede se encuentra en la isla de Gran Canaria, en una finca platanera muy cerca de la
         ciudad de Las Palmas.
        </p>
      </div>
    </div>
  </article>
);

const TOUR_STATES = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
        Estados en las rutas.
        </h4>
        <p className="faqs-page__question-text">
        Al crear una ruta se encuentra en un estado incial de “borrador”, es decir, que sólo nosotros
         podemos ver su contenido. Si deseamos compartirla para que sea visible por otros usuarios
         podremos optar a elegir los siguientes estados:
        </p>
        <ul className="faqs-page__question-list">
          <li className="faqs-page__question-list-item">
            <strong>Publicado: </strong>
          Seleccionaremos esta opción si queremos que nuestra ruta se muestre en la sección de
           &quot;Descubre&quot;. Si somos usuarios estándar deberá pasar un proceso de validación para que
           siempre el contenido que se muestre en Topdesti sea de calidad. Hasta entonces el botón de estado
           mostrará un color naranja con el título: &quot;Publicado&quot;. En cuanto un miembro de Topdesti lo valide
           pasará a un color verde con el títlo &quot;Aprobado&quot; y ya podrá ser visualizado por toda la comunidad
           de usuarios desde &quot;Descubre&quot;.
          </li>
          <li className="faqs-page__question-list-item">
            <strong>Oculto: </strong>
          Esta opción nos permite que otros usuarios puedan ver la ruta si les enviamos su dirección
           web, ya que no se mostrará desde la sección de &quot;Descubre&quot;.
          </li>
        </ul>
        <p className="faqs-page__question-text">
          En cualquier momento podemos cambiar el estado de nuestra ruta para que sea o no visible por los demás.
        </p>
      </div>
    </div>
  </article>
);

const TOUR_TYPES = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          Tipos de rutas.
        </h4>
        <p className="faqs-page__question-text">
        Al crear una ruta se encuentra en un estado incial de &quot;borrador&quot;, es decir, que sólo nosotros
         podemos ver su contenido. Si deseamos compartirla para que sea visible por otros usuarios
         podremos optar a elegir los siguientes estados:
        </p>
        <ul className="faqs-page__question-list">
          <li className="faqs-page__question-list-item">
            <strong>Rutas Itinerario: </strong>
           Muestran una selección de destinos con un orden para su visita. Los usuarios podrán añadir horas,
           recorridos, etc, para que sea más sencillo para otros usuarios el realizar el mismo itinerario.
          </li>
          <li className="faqs-page__question-list-item">
            <strong>Rutas Ranking: </strong>
          Se trata de una selección de lugares por orden de importancia definido por su creador. Por ejemplo,
           &quot;Las 5 mejores playas de Ibiza&quot;.
          </li>
        </ul>
      </div>
    </div>
  </article>
);

const KEYWORDS = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          Palabras clave.
        </h4>
        <p className="faqs-page__question-text">
        Las palabras clave nos permitirán de forma sencilla encontrar rutas con contenido de nuestro interés,
         ya sea por medio de etiquetas &quot;estándar&quot; (por ejemplo #2dmadrid son rutas de 2 días en Madrid)
         o por la combinación de varias palabras: #naturaleza #galicia buscaría rutas en Galicia con un contenido
         basado en la naturaleza.
        </p>
        <p className="faqs-page__question-text">
          Algunas de las palabras clave que debemos usar siempre en nuestras rutas para
           buscar la mejor difusión serían:
        </p>
        <ul className="faqs-page__question-list">
          <h5 className="faqs-page__question-subtitle">
            Duración en días u horas:
          </h5>
          <li className="faqs-page__question-list-item">
            #2dMadrid: Rutas de 2 días en Madrid
          </li>
          <li className="faqs-page__question-list-item">
            #5hMadrid: Rutas de 5 horas en Madrid
          </li>
        </ul>
        <ul className="faqs-page__question-list">
          <h5 className="faqs-page__question-subtitle">Origen:</h5>
          <li className="faqs-page__question-list-item">
            #OToledo: Rutas con origen en Toledo.
          </li>
        </ul>
        <ul className="faqs-page__question-list">
          <h5 className="faqs-page__question-subtitle">
            Temática:
          </h5>
          <p className="faqs-page__question-text">
            Añadiendo aquellas características que mejor definan a nuestras rutas.
          </p>
          <li className="faqs-page__question-list-item">
            #naturaleza
          </li>
          <li className="faqs-page__question-list-item">
            #senderismo
          </li>
        </ul>
        <ul className="faqs-page__question-list">
          <h5 className="faqs-page__question-subtitle">Ranking: (añadiendo #top)</h5>
          <li className="faqs-page__question-list-item">
            #topplayas: Ranking de las mejores playas.
          </li>
        </ul>
      </div>
    </div>
  </article>
);

const HowCreateTour = () => {
  const [lightboxes, setLightboxes] = useState([false, false, false, false, false]);
  const handleCloseLightbox = useCallback(
    (value) => {
      const newLightboxesState = lightboxes.map(() => false);
      if (!isEmpty(value)) {
        newLightboxesState[value] = true;
      }
      setLightboxes(newLightboxesState);
    },
    [lightboxes],
  );

  return (
    <article className="container faqs-page__question">
      <div className="row">
        <div className="col-12">
          <h4 className="faqs-page__question-title">
          ¿Cómo crear una ruta?
          </h4>
          <p className="faqs-page__question-text">
            Las rutas se componen de fichas de destino, junto con elementos personalizados y recomendaciones.
             Para ello la pantalla se divide en dos espacios diferenciados, uno superior con las opciones de
             configuración y la línea de tiempo e inmediatamente después el mapa de resultados desde donde
             podremos agregar nuestros destinos y recomendaciones al itinerario.
          </p>
          <button
            type="button"
            className="faqs-page__question-lightbox-btn"
            onClick={() => handleCloseLightbox(0)}
          >
            <span>Zona de configuración de ruta y línea de tiempo:</span>
            <img
              src="/assets/images/faqs/main-tour-edition.jpg"
              alt="Creación de rutas"
              className="faqs-page__question-img"
            />
            <small className="d-block text-center">(Pulsa en la imagén para ampliarla)</small>
          </button>
          {lightboxes[0] && (
            <Lightbox
              mainSrc="/assets/images/faqs/main-tour-edition.jpg"
              alt="Creación de rutas"
              onCloseRequest={() => handleCloseLightbox(null)}
              hideDownload
            />
          )}

          <button
            type="button"
            className="faqs-page__question-lightbox-btn"
            onClick={() => handleCloseLightbox(1)}
          >
            <span>Mapa de resultados:</span>
            <img
              src="/assets/images/faqs/tour-edition-map.jpg"
              alt="Creación de rutas"
              className="faqs-page__question-img"
            />
            <small className="d-block text-center">(Pulsa en la imagén para ampliarla)</small>
          </button>
          <p className="faqs-page__question-text">
            Si es una ruta que hemos hecho ya y queremos compartir su contenido, antes de empezar será
             conveniente crear aquellos recursos que vamos a insertar en ella, como destinos y servicios
             y que no se encuentren todavía en la base de datos. Para ello buscamos en la sección de
             &quot;Descubre&quot; si alguien ya los ha creado previamente, y en cuyo caso podremos agregarlos
             directamente desde el mapa de resultados.
          </p>
          <p className="faqs-page__question-text">
            El primer paso ya en el modo de creación de rutas será editar su configuración, donde podremos
             editar las características básicas (como el título, portada, textos iniciales, etc..).
             Si lo deseamos podremos también aquí usar las pestañas avanzadas que nos permitirán añadir
             patrocinios, consejos de viaje o elementos en nuestra tienda virtual y que puedes conocer en
             las siguientes secciones de este apartado de las faqs.
          </p>
          {lightboxes[1] && (
            <Lightbox
              mainSrc="/assets/images/faqs/tour-edition-map.jpg"
              alt="Creación de rutas - Mapa"
              onCloseRequest={() => handleCloseLightbox(null)}
              hideDownload
            />
          )}

          <button
            type="button"
            className="faqs-page__question-lightbox-btn"
            onClick={() => handleCloseLightbox(2)}
          >
            <span>Configuración de la ruta:</span>
            <img
              src="/assets/images/faqs/tour-edition-config.jpg"
              alt="Creación de rutas"
              className="faqs-page__question-img"
            />
            <small className="d-block text-center">(Pulsa en la imagén para ampliarla)</small>
          </button>

          {lightboxes[2] && (
            <Lightbox
              mainSrc="/assets/images/faqs/tour-edition-config.jpg"
              alt="Creación de rutas - Mapa"
              onCloseRequest={() => handleCloseLightbox(null)}
              hideDownload
            />
          )}
          <p className="faqs-page__question-text">
            Una vez que hayamos finalizado con los campos de configuración pasamos a agregar contenido desde el
             mapa de resultados. Para ello sólo es necesario pulsar sobre el &quot;+&quot; sobre el recurso y
             automáticamente se agregará a la línea de tiempo principal. Si hemos seleccionado el tipo de ruta
             &quot;itinerario&quot; podremos además añadir el contenido en distintos días, tal y como deseemos
             que sea nuestro viaje.
          </p>

          <button
            type="button"
            className="faqs-page__question-lightbox-btn"
            onClick={() => handleCloseLightbox(3)}
          >
            <img
              src="/assets/images/faqs/tour-edition-click.jpg"
              alt="Creación de rutas"
              className="faqs-page__question-img"
            />
            <small className="d-block text-center">(Pulsa en la imagén para ampliarla)</small>
          </button>


          {lightboxes[3] && (
            <Lightbox
              mainSrc="/assets/images/faqs/tour-edition-click.jpg"
              alt="Creación de rutas - Mapa"
              onCloseRequest={() => handleCloseLightbox(null)}
              hideDownload
            />
          )}
          <p className="faqs-page__question-text">
            En cualquier momento podremos previsualizar el resultado de nuestra ruta, pulsando en el icono
             del “cohete” situado en la zona de configuración. Si queremos seguir editando sólo tendremos
             que pulsar en el icono de “editar” situado en la parte derecha de la cabecera.
          </p>

          <button
            type="button"
            className="faqs-page__question-lightbox-btn"
            onClick={() => handleCloseLightbox(4)}
          >
            <img
              src="/assets/images/faqs/tour-edition-preview.jpg"
              alt="Creación de rutas"
              className="faqs-page__question-img"
            />
            <small className="d-block text-center">(Pulsa en la imagén para ampliarla)</small>
          </button>


          {lightboxes[4] && (
            <Lightbox
              mainSrc="/assets/images/faqs/tour-edition-preview.jpg"
              alt="Creación de rutas - Mapa"
              onCloseRequest={() => handleCloseLightbox(null)}
              hideDownload
            />
          )}

          <p className="faqs-page__question-text">
            Además del contenido desde el mapa, podremos añadir imágenes, textos, vídeos, etc. en nuestras rutas.
             Estos elementos pueden añadirse en la línea de tiempo principal o en el interior de las fichas de
             destino, con lo cual si cambiamos su orden o las eliminamos afectará a todo su contenido.
          </p>
        </div>
      </div>
    </article>
  );
};

const HOW_CREATE_PLACE = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          ¿Cómo crear un destino?
        </h4>
        <p className="faqs-page__question-text">
          Próximamente...
        </p>
      </div>
    </div>
  </article>
);

const HOW_CREATE_SERVICE = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          ¿Cómo crear un servicio?
        </h4>
        <p className="faqs-page__question-text">
          Próximamente...
        </p>
      </div>
    </div>
  </article>
);

const MAKE_MONEY = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          Como ganar dinero con Topdesti
        </h4>
        <p className="faqs-page__question-text">
          Existen distintas formas con las que podremos obtener ingresos por nuestros viajes. Algunas de ellas
           están todavía en fase de desarrollo y esperamos cuanto antes tenerlas disponibles e incorporarlas
           a tus rutas.
        </p>
        <p className="faqs-page__question-text">
          Evidentemente y siendo una plataforma nueva no esperes hacerte millonario con la primera ruta :), pero
          según nuestra comunidad vaya creciendo podremos paso a paso incrementar el dinero que el contenido de los
           usuarios
          puede generar. Nuestro objetivo es el de ofrecer a  nuestros usuarios cuanto antes la posibilidad de
           convertir su pasión por los viajes en su forma de vida.
        </p>
      </div>
    </div>
  </article>
);

const RECOMMENDATIONS_AND_AFFILIATIONS = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          Recomendaciones y planes de afiliados.
        </h4>
        <p className="faqs-page__question-text">
          Podemos recomendar servicios turísticos de todo tipo, desde alojamientos, restaurantes, actividades, etc.
           Existen numerosos portales web que ofrecen la posibilidad de afiliarse y recibir una comisión por cada
           reserva que se haga utilizando nuestro identificador. De esta forma sólo tendremos que incorporar el
           enlace hacia el servicio con nuestro plan de afiliado y recibiremos una comisión cada vez que alguien
           ejecute una reserva desde el mismo.
        </p>
        <p className="faqs-page__question-text">
          Ejemplo: Plan de afiliado de Booking.
        </p>
        <ol className="faqs-page__question-list">
          <li className="faqs-page__question-list-item">
            Nos damos de alta en el sistema de afiliados de booking.com en la dirección:
            <a href="https://spadmin.booking.com" target="_blank" rel="noopener noreferrer"> https://spadmin.booking.com</a>
          </li>
          <li className="faqs-page__question-list-item">
            Si no disponemos de un dominio propio en el nombre de nuestra web usaremos Topdesti.com y en el nombre del
             proyecto Topdesti.
          </li>
          <li className="faqs-page__question-list-item">
            Cuando hagamos una recomendación de un alojamiento buscaremos este establecimiento en booking y le
             añadiremos al enlace nuestro identificador de la siguiente forma:
            <ul className="faqs-page__question-list">
              <li className="faqs-page__question-list-item">Buscamos el enlace al hotel dentro de Booking: https://www.booking.com/hotel/es/pura-vida-las-palmas.es.html</li>
              <li className="faqs-page__question-list-item">Nuestro identificador de afiliado: 1686272</li>
              <li className="faqs-page__question-list-item">Enlace a copiar en nuestra recomendación: https://www.booking.com/hotel/es/pura-vida-las-palmas.es.html?aid=1686272</li>
              <li className="faqs-page__question-list-item">
                Lo copiamos en el campo de &quot;Enlace para reservas&quot; y ya cobraremos una comisión por cada vez
                 que alguien efectúe una reserva desde el enlace. Si quieres puedes agregar un video personal con tu
                 recomendación para tener un trato más cercano con tus seguidores, lo que sin duda aumentará el número
                 de reservas. También puedes personalizar  el texto final que se mostrará junto al botón de
                 &quot;reservar&quot; para informar a tus posibles donantes
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  </article>
);

const VIRTUAL_SHOP = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          Tienda virtual
        </h4>
        <p className="faqs-page__question-text">
          El sistema de la tienda virtual funciona de la misma forma que en las recomendaciones, es decir,
           mostramos el producto que queremos vender y lo redirigimos a una web externa de ventas. Podemos
           usar una tienda propia de comercio electrónico o el plan de afiliados de empresas como Amazon.
           La ventaja principal de éste último es que no tenemos que tener ningún stock, enviar producto,
           etc… simplemente recomendamos, usamos nuestro link de afiliado a Amazon y la tienda correspondiente
           se encarga de todo el proceso de venta.
        </p>
      </div>
    </div>
  </article>
);

const TOUR_SPONSOR = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          Patrocinio en las rutas. (Premium)
        </h4>
        <p className="faqs-page__question-text">
          Los usuarios premium dispondrán del control de los elementos publicitarios de sus rutas, y
           además éstas no mostrarán ninguna otra publicidad. De esta forma tendrán mucho más interés
           para posibles patrocinadores que quieran insertar en ellas su imagen corporativa. Es de
           destacar la importancia de la calidad del contenido para lograr el interés de patrocinadores.
           El contenido de más calidad aparecerá antes en los resultados de las búsquedas, por lo que
           tendrá mucha más visibilidad y por tanto mayor interés de las marcas en patrocinarlo.
        </p>
      </div>
    </div>
  </article>
);

const USER_DONATIONS = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          Donaciones de usuarios (Premium, próximamente).
        </h4>
        <p className="faqs-page__question-text">
          Si nuestro contenido es de calidad y entretiene a otros usuarios o les soluciona de un
           problema de planificación de un viaje, habrá gente que desee contribuir con una donación
           a que sigas creando contenido de igual calidad. Por medio de un sencillo sistema de
           donación integrado en tu ruta, cualquier usuario podrá donar dinero a los creadores.
           Recuerda que unos pocos euros de mucha gente es al final una buena fuente de ingresos
           para poder seguir costeando tus viajes.
        </p>
      </div>
    </div>
  </article>
);

const PLACE_FLAGS = (
  <article className="container faqs-page__question">
    <div className="row">
      <div className="col-12">
        <h4 className="faqs-page__question-title">
          Banderas de destinos. (Premium, próximamente).
        </h4>
        <p className="faqs-page__question-text">
          Las banderas de destinos son el distintivo que define a su creador si este es Toptraveler o
           Premium y con las cuales se asegura para siempre el 20% de lo que este destino genere en
           publicidad, siempre y cuando mantenga su condición de Toptraveler o Premium.
        </p>
        <p className="faqs-page__question-text">
          Por ejemplo, un usuario premium crea la ficha de la Alhambra. Una vez validada la información
           y con un mínimo de 5 fotografías tendrá asegurado su bandera, por lo que obtendrá para siempre
           el 20% de lo pague un patrocinador por tener su imagen corporativa asociada a este monumento.
           Si el número de usuarios de la comunidad crece también lo hará el valor de estos patrocinios,
           por lo que los primeros creadores (un destino no se puede crear dos veces) serán los mayores
           beneficiados en el futuro de un retorno económico de lo que creen ahora.
        </p>
      </div>
    </div>
  </article>
);

const FAQS_QUESTIONS = [
  [
    {
      title: '¿Qué es Topdesti?',
      content: WHAT_IS_TOPDESTI,
    },
    {
      title: '¿Cómo funciona Topdesti?',
      content: HOW_WORKS,
    },
    {
      title: '¿Qué podemos crear y compartir?',
      content: PROPERLY_CONTENT,
    },
    {
      title: 'Versión Beta',
      content: BETA_VERSION,
    },
    {
      title: 'Tipo de usuarios',
      content: USER_TYPES,
    },
    {
      title: 'Conductas penalizadas',
      content: PENALIZED_BEHAVIORS,
    },
    {
      title: 'Hazte colaborador',
      content: BECOME_COLLABORATOR,
    },
    {
      title: 'Próximas funcionalidades',
      content: NEXT_FEATURES,
    },
    {
      title: 'Contactar con Topdesti',
      content: CONTACT_TOPDESTI,
    },
  ],
  [
    {
      title: '¿Cómo crear una ruta?',
      content: (<HowCreateTour />),
    },
    {
      title: 'Estados en las rutas',
      content: TOUR_STATES,
    },
    {
      title: 'Tipos de rutas',
      content: TOUR_TYPES,
    },
    {
      title: 'Palabras clave',
      content: KEYWORDS,
    },
    {
      title: '¿Cómo crear un destino?',
      content: HOW_CREATE_PLACE,
    },
    {
      title: '¿Cómo crear un servicio?',
      content: HOW_CREATE_SERVICE,
    },
  ],
  [
    { title: 'Link 1', content: 'Link 1' },
    { title: 'Link 2', content: 'Link 2' },
    { title: 'Link 3', content: 'Link 3' },
    { title: 'Link 4', content: 'Link 4' },
  ],
  [
    { title: 'Link 1', content: 'Link 1' },
    { title: 'Link 2', content: 'Link 2' },
    { title: 'Link 3', content: 'Link 3' },
    { title: 'Link 4', content: 'Link 4' },
  ],
  [
    { title: 'Como ganar dinero con Topdesti', content: MAKE_MONEY },
    { title: 'Recomendaciones y planes de afiliados.', content: RECOMMENDATIONS_AND_AFFILIATIONS },
    { title: 'Tienda virtual', content: VIRTUAL_SHOP },
    { title: 'Patrocinio en las rutas', content: TOUR_SPONSOR },
    { title: 'Donaciones de usuarios', content: USER_DONATIONS },
    { title: 'Banderas de destinos', content: PLACE_FLAGS },
  ],
];

export default FAQS_QUESTIONS;
