import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

class AutosuggestWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultInputValue,
      suggestions: [], // when this is empty no suggestion are showed, else those are showed!
    };
    this.getSuggestions = this.getSuggestions.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.renderInputComponent = this.renderInputComponent.bind(this);

    // only implement this if required, this will overwrite the <ul><li> structure and default classes
    // this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);

    // this.renderSuggestion = this.renderSuggestion.bind(this); //static
    // this.renderSectionTitle = this.renderSectionTitle.bind(this); //static
    // this.getSectionSuggestions = this.getSectionSuggestions.bind(this); // static
  }

  onInputChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
  }

  onKeyDown(e) {
    const { onKeyDown, keysCleaningInput } = this.props;
    if (onKeyDown) {
      onKeyDown(e);
      if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
        if (keysCleaningInput && keysCleaningInput.includes(e.key)) {
          this.setState({
            value: '',
          });
        }
      }
    }
  }

  onKeyUp(e) {
    const { onKeyUp, keysCleaningInput } = this.props;
    if (onKeyUp) {
      onKeyUp(e);
      if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
        if (keysCleaningInput && keysCleaningInput.includes(e.key)) {
          this.setState({
            value: '',
          });
        }
      }
    }
  }

  onKeyPress(e) {
    const { onKeyPress, keysCleaningInput } = this.props;
    if (onKeyPress) {
      onKeyPress(e);
      if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
        if (keysCleaningInput && keysCleaningInput.includes(e.key)) {
          this.setState({
            value: '',
          });
        }
      }
    }
  }

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  onSuggestionSelected(event, {
    suggestion, suggestionValue, suggestionIndex, sectionIndex, method,
  }) {
    const { onSelectCleanInput, onSuggestionSelected } = this.props;
    if (onSelectCleanInput) {
      this.setState({ value: '' });
    }
    if (onSuggestionSelected) {
      onSuggestionSelected(event, {
        suggestion,
        suggestionValue,
        suggestionIndex,
        sectionIndex,
        method,
      });
    }
  }

  // Teach Autosuggest how to calculate the input value for every given suggestion.
  // send the selected suggestion to the parent component if getSuggestionValue props exist
  getSuggestionValue(suggestion) {
    const { getSuggestionValue } = this.props;
    if (getSuggestionValue) {
      getSuggestionValue(suggestion);
    }
    return suggestion.displayedText;
  }

  getSuggestions(inputValue) {
    const actualValue = inputValue.trim().toLowerCase();
    const inputLength = inputValue.length;
    const {
      multiSection,
      suggestionsArray,
      minInputLengthToShow,
      uniqueSuggestions,
      prevSuggestions,
    } = this.props;
    if (inputLength > minInputLengthToShow) {
      // teach reading of suggestions if not multisection
      if (!multiSection) {
        const displayedSuggestions = suggestionsArray.filter((currSuggestion) => {
          const actualCurrSuggestion = currSuggestion.displayedText.trim().toLowerCase();
          let flag = actualCurrSuggestion.indexOf(actualValue) >= 0;
          if (uniqueSuggestions) {
            flag = flag && !prevSuggestions.includes(currSuggestion.displayedText);
          }
          return flag;
        });
        return displayedSuggestions;
      }
      // teach reading of suggestions when multisection
      const displayedSuggestions = suggestionsArray.reduce((accumulator, currSection) => {
        const currSuggestions = currSection.suggestions.filter((currSugg) => {
          const actualCurrSugg = currSugg.displayedText.trim().toLowerCase();
          let flag = actualCurrSugg.indexOf(actualValue) >= 0;
          if (uniqueSuggestions) {
            flag = flag && !prevSuggestions.includes(currSugg.displayedText);
          }
          return flag;
        });
        if (currSuggestions.length > 0) {
          accumulator.push({
            ...currSection,
            suggestions: currSuggestions,
          });
        }
        return accumulator;
      }, []);
      return displayedSuggestions;
    }
    return [];
  }

  static getSectionSuggestions(section) {
    return section.suggestions;
  }

  /*
  //  TODO: only implement this if it's required...
  //    this will substitute the default <ul><li> structure and their classes/hovers...
  renderSuggestionsContainer = ({ containerProps, children }) => {
    let suggListItemContainers = null;
    if (children) {
      suggListItemContainers = children.props.items.map((item, index) => {
        return <div className="suggestion-list-item">{item}</div>;
      });
    }
    return (
      <div {...containerProps} className="suggestion-list-container">
        {suggListItemContainers}
      </div>
    );
  };
  */
  renderInputComponent(inputProps) {
    const { label } = this.props;
    return (
      <div className="autosuggest-wrapper__input">
        {label ? <div className="autosuggest-wrapper__input-icon" /> : null}
        <input {...inputProps} className="form-control autosuggest-wrapper__input-field" />
      </div>
    );
  }

  static renderSectionTitle(section) {
    return <div className="suggestion-list-section-title">{section.title}</div>;
  }

  static renderSuggestion(suggestion) {
    return (
      <div className="suggestion-list-item">
        #
        {suggestion.displayedText}
      </div>
    );
  }

  render() {
    const { value, suggestions } = this.state;
    const {
      placeholder, multiSection, uniqueIdentificator, extraInputProps,
    } = this.props;
    const { renderSuggestion, renderSectionTitle, getSectionSuggestions } = AutosuggestWrapper;
    const {
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      getSuggestionValue,
      renderInputComponent,
      onInputChange,
      onKeyDown,
      onKeyUp,
      onKeyPress,
      onSuggestionSelected,
    } = this;

    // Autosuggest will pass through all these props to the input.
    let inputProps = {
      placeholder,
      value,
      onChange: onInputChange,
      onKeyDown,
      onKeyUp,
      onKeyPress,
    };
    if (extraInputProps) {
      inputProps = { ...inputProps, ...extraInputProps };
    }
    let MultiSectionProps = {};
    if (multiSection) {
      MultiSectionProps = {
        multiSection: true,
        getSectionSuggestions,
        renderSectionTitle,
      };
    }

    // required props:
    //  uniqueIdentificator
    //    unique string --> unique string required to allow multiple Autocompletes to work in the same page
    //  placeholder
    //    string --> what is to be showed when nothing is yet been inputed
    //  suggestionsArray
    //    array of strings --> all possible solutions that can be displayed if string matches
    //  optional props :
    //  label
    //    string --> label to put before the input field ...
    //  getSuggestionValue
    //    function --> receive selected value as parameter on suggestion selection!
    //  multiSection
    //    bool --> if this is true the suggestionsArray should be an array
    //      E.G.
    //        [{title:"section title",suggestions:["string suggestion1","string suggestion2", ...]}]
    return (
      <Autosuggest
        id={uniqueIdentificator}
        {...MultiSectionProps}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        // renderSuggestionsContainer={this.renderSuggestionsContainer} //only implement this if required
        inputProps={inputProps}
      />
    );
  }
}

AutosuggestWrapper.propTypes = {
  uniqueIdentificator: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  suggestionsArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  multiSection: PropTypes.bool,
  onSelectCleanInput: PropTypes.bool,
  uniqueSuggestions: PropTypes.bool,
  prevSuggestions: PropTypes.arrayOf(PropTypes.string),
  defaultInputValue: PropTypes.string,
  minInputLengthToShow: PropTypes.number,
  label: PropTypes.string,
  getSuggestionValue: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func,
  onSuggestionSelected: PropTypes.func,
  keysCleaningInput: PropTypes.arrayOf(PropTypes.string),
  extraInputProps: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.func,
      PropTypes.number,
      PropTypes.objectOf(PropTypes.object),
    ]),
  ),
};
AutosuggestWrapper.defaultProps = {
  multiSection: false,
  onSelectCleanInput: false,
  uniqueSuggestions: false,
  prevSuggestions: null,
  defaultInputValue: '',
  minInputLengthToShow: 0,
  label: '',
  getSuggestionValue: null,
  extraInputProps: null,
  keysCleaningInput: null,
  onKeyDown: null,
  onKeyUp: null,
  onKeyPress: null,
  onSuggestionSelected: null,
};
export default AutosuggestWrapper;
