import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalNewsletterTemplate from './modal-newsletter-template';
import { lockBodyScrolling, restoreBodyScrolling } from '../../utils';

const ModalNewsletter = (props) => {
  const { isOpen, closeModal } = props;

  useEffect(() => {
    lockBodyScrolling();
    return restoreBodyScrolling;
  }, []);

  return (<ModalNewsletterTemplate isOpen={isOpen} closeModal={closeModal} />);
};

ModalNewsletter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalNewsletter;
