import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sortableContainer, sortableElement, SortableHandle } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { isEmptyObject, getTourContentThumbImg } from '../../../../../../utils';
import {
  EYE_NOT_ICON, CLOSE_ICON, EYE_ICON, PLUS_ICON,
} from '../../../../../../helpers';
import TYPES from '../../../../../../constants/types';
import { PromiseButton, ResponsiveImg } from '../../../../../../components';
import axios from 'axios';
import ENV from '../../../../../../config/environment';
import ReactQuill from 'react-quill';


const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];

const DragHandle = SortableHandle(({ children }) => <>{children}</>);

const SortableItem = sortableElement(({ children }) => (
  <li className="tour-content-poi-form__list-item">{children}</li>
));

const SortableContainer = sortableContainer(({ children }) => (
  <ul className="tour-content-poi-form__list">{children}</ul>
));
class TourContentPoiFormTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pois: [],
      imageCachePoi: null,
      descriptionPoi: null,
      titlePoi: null
    };
  }
  componentDidMount() {
    this.setState({
      pois: this.props.pois,
    })
    this.getImagesPoi()
  }

  componentDidUpdate(prevProps) {
    if (this.props.pois.length !== prevProps.pois.length) {
      this.setState({
        pois: this.props.pois,
      })
      this.getImagesPoi()
    }
  }
  getImagesPoi = () => {
    const tourContent = this.props.localTourContent;
    let poiImage = "";
    let poi;
    if (tourContent.attributes["content-type"] == 1) {
      poi = this.props.pois && this.props.pois.find(e => e.id == tourContent.relationships.poi.data.id)
      if (poi == undefined) {
        axios.get(`${ENV.host}/${ENV.namespace}/pois/${tourContent.relationships.poi.data.id}`)
          .then((res) => {
            let image = res.data.included;
            image.sort((a, b) => b.attributes.likes - a.attributes.likes)
            this.setState({ imageCachePoi: image[0], descriptionPoi: res.data.data.attributes.description, titlePoi: res.data.data.attributes.title })
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }
  render() {
    const {
      localTourContent,
      poiDisplayedImage,
      tourContentPoi,
      handleInput,
      handleSave,
      isEditingText,
      handleEditState,
      handleToggleVisibility,
      handleDelete,
      isMobile,
      sortedChildrenTourContents,
      onDragEnd,
      handleOpenTourContentMenu,
      handleContentSelection,
      storedImages,
    } = this.props;

    if (isEmptyObject(localTourContent)) return null;

    let poiImage;
    let descriptionPoi;
    let titlePoi;
    if (localTourContent.attributes["content-type"] == 1) {
      let poi = this.props.pois && this.props.pois.find(e => e.id == localTourContent.relationships.poi.data.id)
      if (poi && poi.attributes && poi.attributes.images && poi.attributes.images.length > 0) {
        let image = poi.attributes.images;
        image.sort((a, b) => b.likes - a.likes)
        let imageA = {
          attributes: image[0]
        }
        poiImage = imageA;
        descriptionPoi = poi.attributes.description
        titlePoi = poi.attributes.title
      }
    }
    const {
      attributes: { text: tourContentText, visible: isVisible },
    } = localTourContent;

    const { attributes: { thumb: imageThumb = null } = {} } = poiImage || {};

    const { attributes: { title: poiTitle = '', description: poiDescription = '' } = {} } = tourContentPoi || {};
    return (
      <>
        <div className="col-12 col-lg-4">
          <h3 className="tour-content-poi-form__title">{titlePoi ? titlePoi : this.state.titlePoi}</h3>
          <div className="row">
            <div className="col">
              <ResponsiveImg
                className="tour-content-poi-form__main-image"
                wrapperClassName="tour-content-poi-form__main-image-wrapper"
                src={imageThumb ? imageThumb : this.state.imageCachePoi && this.state.imageCachePoi.attributes ? this.state.imageCachePoi.attributes.thumb : null}
              >
                <span className="sr-only">{poiTitle}</span>
                {isVisible ? null : (
                  <div className="tour-content-poi-form__no-visible-layer">{EYE_NOT_ICON}</div>
                )}
              </ResponsiveImg>
            </div>
          </div>
          <div className="row tour-content-poi-form__separator">
            <div
              className={`col-12 tour-content-poi-form__text-wrapper ${isEmptyObject(tourContentText) ? 'tour-content-poi-form__text-wrapper--no-border' : ''
                }`}
            >
              {isEditingText ? (
                <ReactQuill
                  id="description"
                  value={tourContentText}
                  // onChange={handleTextChange}
                  placeholder="Por defecto aparecerá la descripción original del destino"
                  onChange={handleInput}
                  // onChange={()=>handleChange(description, image.id)}
                  className="tour-config-main__rich-text-editor-ReactQuill"
                  modules={MODULES}
                  formats={FORMATS}
                />
              ) : (
                  <p className="tour-content-poi-form__text">
                    {descriptionPoi ? descriptionPoi : this.state.descriptionPoi}
                  </p>
                )}
              <button
                type="button"
                className="btn btn-primary d-block ml-auto mx-lg-auto"
                onClick={isEditingText ? () => {
                  handleSave(true).then(() => {
                    handleEditState();
                  });
                } : handleEditState}
              >
                {isEditingText ? 'Guardar' : 'Crear mi texto'}
              </button>
            </div>
          </div>
          <div className="row tour-content-poi-form__separator">
            <div className="col-6 col-lg-9 pr-1 pr-lg-3 mx-lg-auto">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={() => {
                  handleToggleVisibility();
                }}
              >
                {isVisible ? 'Ocultar en la ruta' : 'Mostrar en la ruta'}
              </button>
            </div>
            <div className="col-6 col-lg-9 pl-1 pl-lg-3 mx-lg-auto mt-lg-1">
              <PromiseButton
                type="button"
                className="btn btn-danger btn-block"
                onClick={() => handleDelete()}
              >
                Eliminar de la ruta
            </PromiseButton>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <h4 className="tour-content-poi-form__contents-title">
            Contenidos personales
          {!isMobile ? ' (Sólo se muestran en esta ruta):' : ':'}
            <small className="tour-content-poi-form__contents-subtitle">
              Puedes arrastrar la miniatura para cambiar su orden y pulsarla para editarla.
          </small>
            {isMobile ? (
              <button
                type="button"
                className="tour-content-poi-form__add-btn"
                onClick={handleOpenTourContentMenu}
              >
                {PLUS_ICON}
              </button>
            ) : null}
          </h4>
          <SortableContainer
            onSortEnd={onDragEnd}
            axis="xy"
            lockToContainerEdges
            lockOffset="50%"
            useDragHandle
            distance={1}
          >
            {sortedChildrenTourContents.map((childTourContent, index) => {
              const {
                attributes: { 'content-type': contentType },
                relationships: {
                  archives: { data: tourContentArchivesData },
                },
              } = childTourContent;
              const coverImage = !isEmptyObject(tourContentArchivesData) && !isEmptyObject(storedImages)
                ? storedImages[tourContentArchivesData[0].id]
                : null;
              const thumbImageUrl = getTourContentThumbImg(contentType, coverImage);

              return (
                <SortableItem key={`image-tour-content-${childTourContent.id}`} index={index}>
                  <PromiseButton
                    type="button"
                    className="tour-content-poi-form__list-show"
                    title="Ocultar contenido"
                    onClick={() => handleToggleVisibility({ contentToToggle: childTourContent })}
                    primarySpinner
                  >
                    {EYE_ICON}
                  </PromiseButton>
                  <PromiseButton
                    type="button"
                    className="tour-content-poi-form__list-remove"
                    title="Eliminar contenido"
                    onClick={() => handleDelete({ contentToDelete: childTourContent })}
                    primarySpinner
                  >
                    {CLOSE_ICON}
                  </PromiseButton>
                  <DragHandle>
                    <button
                      type="button"
                      className="tour-content-poi-form__list-btn"
                      onClick={() => handleContentSelection(childTourContent)}
                    >
                      {contentType === 3 && tourContentArchivesData.length > 0 ? (
                        <span className="tour-content-poi-form__images-amount">
                          {tourContentArchivesData.length}
                        </span>
                      ) : null}
                      <ResponsiveImg
                        src={thumbImageUrl}
                        alt="Imagen"
                        className={`tour-content-poi-form__list-img ${isEmptyObject(coverImage) ? 'tour-content-poi-form__list-img--icon' : ''
                          }`}
                      />
                      {childTourContent.attributes.visible ? null : (
                        <div className="tour-content-poi-form__no-visible-layer">{EYE_NOT_ICON}</div>
                      )}
                    </button>
                  </DragHandle>
                </SortableItem>
              );
            })}
            {!isMobile ? (
              <li className="tour-content-poi-form__list-item">
                <button
                  type="button"
                  className="tour-content-poi-form__list-add"
                  onClick={handleOpenTourContentMenu}
                >
                  {PLUS_ICON}
                </button>
              </li>
            ) : null}
          </SortableContainer>
        </div>
      </>
    );
  }
}

TourContentPoiFormTemplate.propTypes = {
  isEditingText: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  handleOpenTourContentMenu: PropTypes.func.isRequired,
  handleContentSelection: PropTypes.func.isRequired,
  handleEditState: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  tourContentPoi: TYPES.poiType,
  sortedChildrenTourContents: PropTypes.arrayOf(TYPES.tourContentType).isRequired,
  localTourContent: TYPES.tourContentType,
  poiDisplayedImage: TYPES.imageType,
  storedImages: PropTypes.shape({
    [PropTypes.number]: TYPES.imageType,
  }),
};

TourContentPoiFormTemplate.defaultProps = {
  localTourContent: null,
  tourContentPoi: null,
  poiDisplayedImage: null,
  storedImages: null,
};

function mapStateToProps(state) {
  const storedImages = state.bees.entities.images;
  return {
    storedImages,
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(TourContentPoiFormTemplate);
