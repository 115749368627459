import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash.clonedeep';
import { toastr } from 'react-redux-toastr';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import TourContentTipFormTemplate from './tour-content-tip-form-template';
import tourContentType from '../../../../../../constants/types/tour-content-type';
import TYPES from '../../../../../../constants/types';
import reduxBeeMakeRequest from '../../../../../../utils/redux-bee/redux-bee-make-request';
import { isEmpty } from '../../../../../../utils';

const TourContentTipForm = (props) => {
  const {
    handleToggleVisibility,
    handleDelete,
    localTourContent,
    tourContentArchives,
    setLocalTourContent,
    handleOnImageUpload,
    dispatch,
    handleSave,
  } = props;

  // ***************** USERS INPUTS HANDLERS *********************
  const handleTextChange = (value) => {
    // the empty editor value is '<p><br></p>'
    // before setting the state, check if editor is empty and convert it to empty string if it is
    const richText = value === '<p><br></p>' ? '' : value;

    const immutableTourContent = _cloneDeep(localTourContent);
    immutableTourContent.attributes.text = richText;
    setLocalTourContent(immutableTourContent);
  };

  const handleInputChange = (e) => {
    const immutableTourContent = _cloneDeep(localTourContent);
    const {
      target: { value: newValue, name: attributeIdentifier },
    } = e;
    immutableTourContent.attributes[attributeIdentifier] = newValue;
    setLocalTourContent(immutableTourContent);
  };

  // *************** USERS INPUTS HANDLERS END *******************

  const [isVisibleMapLocalizator, setIsVisibleMapLocalizator] = useState(false);

  const showMapLocalizator = (shouldShow) => {
    setIsVisibleMapLocalizator(shouldShow);
  };

  const saveMarkerPosition = (markerCoord) => {
    const immutableTourContent = _cloneDeep(localTourContent);
    const { lat, lng: long } = markerCoord;
    immutableTourContent.attributes = { ...immutableTourContent.attributes, lat, long };
    setIsVisibleMapLocalizator(false);
    setLocalTourContent(immutableTourContent);
  };

  const [address, setAddress] = useState('');
  const [mapInstance, setMapInstance] = useState(null);
  const [orderedImages, setOrderedImages] = useState([]);

  useEffect(() => {
    const newOrderedArchives = isEmpty(tourContentArchives)
      ? []
      : tourContentArchives.sort((a, b) => a.attributes.order - b.attributes.order);
    setOrderedImages(newOrderedArchives);
  }, [tourContentArchives]);

  const onMapComponentDidMount = (mountedMapInstance) => {
    setMapInstance(mountedMapInstance);
  };

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleAddressSelect = (selectedAddress) => {
    let results = null;
    geocodeByAddress(selectedAddress)
      .then((selectionResults) => {
        results = selectionResults;
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setAddress(results[0].formatted_address);
        mapInstance.panTo(latLng);
      })
      .catch((error) => {
        if (error.body && error.body.errors[0] && error.body.errors[0].detail) {
          toastr.error('Error', error.body.errors[0].detail);
        } else if (error.body && !Array.isArray(error.body.errors)) {
          toastr.error('Error', 'Error de servidor inesperado');
        } else {
          toastr.error('Error', error);
        }
      });
  };

  const handleDeleteImage = image =>
    reduxBeeMakeRequest(dispatch, 'deleteImage', { id: image.id }, {})
      .then(() => reduxBeeMakeRequest(dispatch, 'getTourContent', { id: localTourContent.id }, {}))
      .catch(() => {
        toastr.error('No se ha podido eliminar la imagen, por favor, intentalo de nuevo');
      });

  const handleOnDragEnd = ({ oldIndex, newIndex }) => {
    if (isEmpty(newIndex) || oldIndex === newIndex) return;

    const movedImage = orderedImages[oldIndex];
    reduxBeeMakeRequest(
      dispatch,
      'updateImage',
      { id: movedImage.id },
      { ...movedImage, attributes: { ...movedImage.attributes, order: newIndex + 1 } },
    )
      .then(() =>
        reduxBeeMakeRequest(dispatch, 'getTourContentArchives', { id: localTourContent.id }, {}),
      )
      .then((images) => {
        const newOrderedImages = images.sort((a, b) => a.attributes.order - b.attributes.order);
        setOrderedImages(newOrderedImages);
      });
  };

  return (
    <TourContentTipFormTemplate
      localTourContent={localTourContent}
      orderedImages={orderedImages}
      handleToggleVisibility={handleToggleVisibility}
      handleDelete={handleDelete}
      handleTextChange={handleTextChange}
      handleInputChange={handleInputChange}
      onMapComponentDidMount={onMapComponentDidMount}
      isVisibleMapLocalizator={isVisibleMapLocalizator}
      showMapLocalizator={showMapLocalizator}
      saveMarkerPosition={saveMarkerPosition}
      address={address}
      handleAddressChange={handleAddressChange}
      handleAddressSelect={handleAddressSelect}
      handleOnImageUpload={() => handleOnImageUpload(localTourContent)}
      handleDeleteImage={handleDeleteImage}
      handleOnDragEnd={handleOnDragEnd}
      handleSave={handleSave}
    />
  );
};

TourContentTipForm.propTypes = {
  localTourContent: tourContentType,
  tourContentArchives: PropTypes.arrayOf(TYPES.imageType),
  setLocalTourContent: PropTypes.func.isRequired,
  handleOnImageUpload: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

TourContentTipForm.defaultProps = {
  localTourContent: null,
  tourContentArchives: null,
};

function mapStateToProps(state, ownProps) {
  const { localTourContent } = ownProps;
  const tourContent = isEmpty(localTourContent.id)
    ? localTourContent
    : state.bees.entities.tourContents[localTourContent.id];
  const archivesData = isEmpty(localTourContent.id) ? [] : tourContent.relationships.archives.data;
  const tourContentArchives = archivesData.map(
    archiveData => state.bees.entities[archiveData.type][archiveData.id],
  );
  return {
    tourContentArchives,
  };
}

export default connect(mapStateToProps)(TourContentTipForm);
