import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import TYPES from '../../../../../../constants/types';
import { TRAVEL_TIP_ICON, LINK_ICON } from '../../../../../../helpers';
import { isEmpty, checkExternalUrl, getImageSrcSet } from '../../../../../../utils';
import ResponsiveImg from '../../../../../responsive-img';
import SimpleMap from '../../../../../simple-map';

const TourContentTipBoxTemplate = (props) => {
  const {
    tourContent,
    tourContentImages,
    isMobile,
    handleCarouselChange,
    carouselPosition,
    handleMapOnMount,
    initializeMapBounds,
  } = props;
  if(!isEmpty(tourContentImages)){
    tourContentImages.sort(function (a, b) {
      if (a.attributes.order > b.attributes.order) {
        return 1;
      }
      if (a.attributes.order < b.attributes.order) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }
  
  const {
    attributes: {
      title, text, 'link-url': linkUrl, lat, long: lng,
    } = {},
  } = tourContent;
  const hasLocation = !isEmpty(lat) && !isEmpty(lng);

  return (
    <article className="container tour-content-tip-box">
      <header className="row tour-content-tip-box__header">
        {!isMobile ? (
          <div className="col-auto px-1">
            <span className="tour-content-tip-box__category">Consejo de viaje</span>
          </div>
        ) : null}
        <div className="col-auto pl-lg-1">
          <figure className="tour-content-tip-box__header-icon">{TRAVEL_TIP_ICON}</figure>
        </div>
      </header>
      <section className="row tour-content-tip-box__content">
        <div className="col-12">
          <div className="row align-items-lg-center">
            <div className="col col-lg-12 order-lg-1">
              <h4 className="tour-content-tip-box__title px-lg-4">{title}</h4>
            </div>
            {isEmpty(linkUrl) ? null : (
              <div className="col-auto order-lg-3 pr-lg-4">
                <a
                  href={checkExternalUrl(linkUrl)}
                  className="tour-content-tip-box__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {LINK_ICON}
                </a>
              </div>
            )}
            <div className="col-12 col-lg order-lg-2">
              <p
                className={`tour-content-tip-box__text ${isEmpty(linkUrl) ? 'px-lg-4' : 'pl-lg-4'}`}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          </div>
        </div>

        {isEmpty(tourContentImages) ? null : (
          <div className={`col-12 px-0 mt-lg-4 ${hasLocation ? 'col-lg-8 col-xl-9' : 'col-lg-10 mx-auto'}`}>
            <Carousel
              showThumbs={false}
              showStatus={false}
              emulateTouch
              onChange={handleCarouselChange}
              selectedItem={carouselPosition}
            >
              {tourContentImages.map(image => (
                <ResponsiveImg
                  key={`service-image-${image.id}`}
                  className="tour-content-tip-box__slide-image"
                  srcSet={getImageSrcSet(image)}
                  forzedVersion={2}
                  loadingInverse={!isMobile}
                />
              ))}
            </Carousel>
          </div>
        )}
        {hasLocation ? (
          <div className="col-12 col-lg px-0 mt-lg-4">
            <SimpleMap
              mapElementId={`map-content-${tourContent.id}`}
              onMapLoad={handleMapOnMount}
              onIdle={initializeMapBounds}
            />
          </div>
        ) : null}
      </section>
    </article>
  );
};

TourContentTipBoxTemplate.propTypes = {
  tourContent: TYPES.tourContentType.isRequired,
  tourContentImages: PropTypes.arrayOf(TYPES.imageType),
  isMobile: PropTypes.bool,
  carouselPosition: PropTypes.number.isRequired,
  handleCarouselChange: PropTypes.func.isRequired,
  handleMapOnMount: PropTypes.func.isRequired,
  initializeMapBounds: PropTypes.func.isRequired,
};

TourContentTipBoxTemplate.defaultProps = {
  isMobile: false,
  tourContentImages: [],
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(TourContentTipBoxTemplate);
