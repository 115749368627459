import React from 'react';

const LinearBar = props => (
  <div className="progressPercentageBarWrapper">
    <span className="progressPercentageBarProgress" style={{ width: `${props.progress}%` }}>
      {props.progress}
%
    </span>
  </div>
);

export default LinearBar;
