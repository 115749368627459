/* eslint-disable react/button-has-type */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '../utils';
import useIsMounted from '../helpers/hooks/useIsMounted';

const PromiseButton = (props) => {
  const {
    type, title, disabled, loadingCondition, className, children, onClick, primarySpinner,
  } = props;

  const [isLoading, setIsLoading] = useState(loadingCondition);
  const isMounted = useIsMounted();

  useEffect(() => {
    setIsLoading(loadingCondition);
  }, [loadingCondition]);

  const handleOnClick = useCallback(
    () => {
      if (isEmpty(onClick)) {
        return true;
      }
      setIsLoading(true);
      return onClick().finally(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
    },
    [isMounted, onClick],
  );

  return (
    <button
      type={type}
      disabled={isLoading || disabled}
      title={title}
      className={`promise-button ${isLoading ? 'promise-button--loading' : ''} ${className}`}
      onClick={handleOnClick}
    >
      {isLoading ? (
        <div
          className={`promise-button__spinner ${
            primarySpinner ? 'promise-button__spinner--primary' : ''
          }`}
        >
          <span className="promise-button__bounce promise-button__bounce--first" />
          <span className="promise-button__bounce promise-button__bounce--second" />
          <span className="promise-button__bounce promise-button__bounce--third" />
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default PromiseButton;

PromiseButton.propTypes = {
  type: PropTypes.string,
  loadingCondition: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.symbol,
    PropTypes.string,
  ]).isRequired,
  onClick: PropTypes.func,
  primarySpinner: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

PromiseButton.defaultProps = {
  type: 'button',
  loadingCondition: false,
  className: null,
  onClick: null,
  primarySpinner: false,
  disabled: false,
  title: null,
};
