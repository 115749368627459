import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '../../utils';
import { STAR_ICON, CLOSE_ICON } from '../../helpers';
import TYPES from '../../constants/types';
import ReactQuill from 'react-quill';


const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];


export default function PoiImage(props) {
  const {
    image,
    handleChange,
    isMobile,
    highlight,
    setMainImage,
    deleteImage,
  } = props;
  const { name, description } = image.attributes;
  const imageInlineStyles = {
    backgroundImage: `url('${image.attributes.thumb}')`,
  };

  const descriptionRows = isMobile ? 3 : 4;
  function handleInt(e) {
    handleChange(e, image.id)
  }
  return (
    <article className="row poi-image">
      <section className="col-12 col-lg-auto poi-image__header">
        <div className="row">
          <div className="col-auto col-lg p-0 mx-auto">
            <figure className="poi-image__image" style={imageInlineStyles}>
              <span className="sr-only">Imagen</span>
              <button
                type="button"
                className={`poi-image__button ${highlight ? 'poi-image__button--hightlight' : ''}`}
                title="Destacar"
                onClick={() => setMainImage(image.id)}
                disabled={highlight}
              >
                {STAR_ICON}
              </button>
              <button
                type="button"
                className="poi-image__button float-right poi-image__button--delete"
                title="Eliminar"
                onClick={() => deleteImage(image.id)}
              >
                {CLOSE_ICON}
              </button>
            </figure>
          </div>
        </div>
      </section>
      <section className="col-12 col-lg poi-image__body">
        <input
          value={isEmpty(name) ? '' : name}
          data-image={image.id}
          type="text"
          placeholder="Escribe aquí el título"
          name="name"
          className="form-control poi-image__input"
          onChange={handleChange}
        />
        <ReactQuill
          id="description"
          data-image={image.id}
          value={isEmpty(description) ? '' : description}
          // onChange={handleTextChange}
          placeholder="Escribe aquí la descripción"
          onChange={handleInt}
          // onChange={()=>handleChange(description, image.id)}
          className="tour-config-main__rich-text-editor"
          modules={MODULES}
          formats={FORMATS}
        />
      </section>
    </article>
  );
}

PoiImage.propTypes = {
  image: TYPES.imageType.isRequired,
  handleChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  highlight: PropTypes.bool.isRequired,
  setMainImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
};
