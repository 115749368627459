import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import BootstrapLoader from '../../loaders/bootstrap-loader';
import SimpleMap from '../../simple-map';
import { parseGoogleBounds, isEmpty } from '../../../utils';
import {
  getUrlBounds,
  getMapBounds,
  shouldUpdateMapBoundsFromUrl,
  isValidBounds,
  isEqualBounds,
  isEqualMarkers,
} from '../utils';
import { history } from '../../../helpers';

const MARKER_URL = '/assets/images/marker.svg';
const BLUE_MARKER_URL = '/assets/images/blue-marker.svg';
const GREEN_MARKER_URL = '/assets/images/green-marker.svg';

const MARKER_ICON = {
  url: MARKER_URL,
  // This marker is 24 pixels wide by 40 pixels high.
  size: new window.google.maps.Size(24, 40),
  // The origin for this image is (0, 0).
  origin: new window.google.maps.Point(0, 0),
  // The anchor for this image is the base of the flagpole at (0, 43).
  anchor: new window.google.maps.Point(0, 40),
};

const BLUE_MARKER_ICON = {
  url: BLUE_MARKER_URL,
  // This marker is 24 pixels wide by 40 pixels high.
  size: new window.google.maps.Size(24, 40),
  // The origin for this image is (0, 0).
  origin: new window.google.maps.Point(0, 0),
  // The anchor for this image is the base of the flagpole at (0, 43).
  anchor: new window.google.maps.Point(0, 40),
};
const GREEN_MARKER_ICON = {
  url: GREEN_MARKER_URL,
  // This marker is 24 pixels wide by 40 pixels high.
  size: new window.google.maps.Size(24, 40),
  // The origin for this image is (0, 0).
  origin: new window.google.maps.Point(0, 0),
  // The anchor for this image is the base of the flagpole at (0, 43).
  anchor: new window.google.maps.Point(0, 40),
};

class SearchMap extends Component {
  constructor(props) {
    super(props);

    this.handleMapMount = this.handleMapMount.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.initializeMapBounds = this.initializeMapBounds.bind(this);
    this.handleBusyState = this.handleBusyState.bind(this);
    this.createMarkers = this.createMarkers.bind(this);
    this.clearMarkers = this.clearMarkers.bind(this);
    this.updateMarkerIcons = this.updateMarkerIcons.bind(this);
    this.resetSelectedPoi = this.resetSelectedPoi.bind(this);

    this._mapInstance = null;
    this._isMapInitialized = false;
    this._currentMapMarkers = [];
    this._isMapBusy = false;
  }

  componentDidUpdate(prevProps) {
    const { urlQueryParams: prevUrlQueryParams, hightlightedPoiId: prevHightlightedPoiId } = prevProps;
    const { markers, urlQueryParams, hightlightedPoiId } = this.props;
    const {
      createMarkers,
      _mapInstance,
      _isMapInitialized,
      _currentMapMarkers,
      _isMapBusy,
      updateMarkerIcons,
    } = this;

    const prevSelectPoiId = prevUrlQueryParams.get('select');
    const selectedPoiId = urlQueryParams.get('select');

    if (_isMapInitialized && !isEmpty(_mapInstance)) {
      const currentUrlBounds = getUrlBounds(urlQueryParams);
      const currentMapBounds = getMapBounds(_mapInstance);
      const shouldUpdateMapBounds = shouldUpdateMapBoundsFromUrl(
        prevUrlQueryParams,
        urlQueryParams,
        currentMapBounds,
      );
      if (!isEqualMarkers(_currentMapMarkers, markers)) {
        createMarkers(_mapInstance, markers);
      }

      if (hightlightedPoiId !== prevHightlightedPoiId || prevSelectPoiId !== selectedPoiId) {
        updateMarkerIcons(hightlightedPoiId, selectedPoiId);
      }

      if (shouldUpdateMapBounds && !_isMapBusy) {
        _mapInstance.fitBounds(parseGoogleBounds(currentUrlBounds), 0);
      }
    }
  }

  createMarkers(map, markers) {
    const { _currentMapMarkers, clearMarkers } = this;
    const currentPoiIds = _currentMapMarkers.map(marker => marker.poiId);

    markers.forEach((marker) => {
      let preview = this.props.pois && Object.values(this.props.pois).find(poi=> (poi.id==marker.poiId && poi.attributes["type-preview"] != 0))
      if (!currentPoiIds.includes(marker.poiId)) {
        const { lat, lng } = marker;
        const mapMarker = new window.google.maps.Marker({
          position: { lat, lng },
          map,
          icon: preview ? GREEN_MARKER_ICON : MARKER_ICON,
        });
        const {
          onClick, onMouseOver, onMouseOut, onTouch,
        } = marker.props;
        mapMarker.addListener('click', onClick);
        mapMarker.addListener('mouseover', onMouseOver);
        mapMarker.addListener('mouseout', onMouseOut);
        mapMarker.addListener('touch', onTouch);
        mapMarker.poiId = marker.poiId;
        _currentMapMarkers.push(mapMarker);
      }
    });
    clearMarkers(_currentMapMarkers, markers);
  }

  clearMarkers(currentMapMarkers, searchMarkers) {
    const searchMarkersIds = searchMarkers.map(marker => marker.poiId);
    const newMapMarkers = currentMapMarkers.reduce((acc, mapMarker) => {
      if (!searchMarkersIds.includes(mapMarker.poiId)) {
        mapMarker.setMap(null);
      } else {
        acc.push(mapMarker);
      }
      return acc;
    }, []);
    this._currentMapMarkers = newMapMarkers;
  }

  updateMarkerIcons(poiId, selectedPoiId) {
    const markers = [...this._currentMapMarkers];
    markers.map((marker) => {
      let preview = Object.values(this.props.pois).find(poi=> (poi.id==marker.poiId && poi.attributes["type-preview"] != 0))
      const currentIconUrl = marker.getIcon().url;
      const { poiId: markerPoiId } = marker;
      if ((markerPoiId === poiId || markerPoiId === selectedPoiId) && currentIconUrl !== BLUE_MARKER_URL) {
        marker.setIcon(BLUE_MARKER_ICON);
      } else if (markerPoiId !== poiId && markerPoiId !== selectedPoiId && currentIconUrl !== MARKER_URL) {
        marker.setIcon( preview ? GREEN_MARKER_ICON : MARKER_ICON);
      }
      return marker;
    });
    this._currentMapMarkers = markers;
  }

  handleMapMount(map) {
    const { handleMapMount } = this.props;
    this._mapInstance = map;
    handleMapMount(map);
  }

  initializeMapBounds() {
    const { createMarkers, _mapInstance, _currentMapMarkers } = this;
    const { markers } = this.props;
    if (!isEmpty(_mapInstance)) {
      const { urlQueryParams } = this.props;
      const currentUrlBounds = getUrlBounds(urlQueryParams);
      const currentMapBounds = getMapBounds(_mapInstance);
      const hasAdrQP = urlQueryParams.has('adr');
      if (
        !hasAdrQP
        && isValidBounds(currentUrlBounds)
        && !isEqualBounds(currentUrlBounds, currentMapBounds)
      ) {
        _mapInstance.fitBounds(parseGoogleBounds(currentUrlBounds), 0);
      }
      if (!isEqualMarkers(_currentMapMarkers, markers)) {
        createMarkers(_mapInstance, markers);
      }
      this._isMapInitialized = true;
    }
  }

  handleSearch(isManualSearch) {
    const { _mapInstance } = this;
    const { handleSearch } = this.props;
    handleSearch(getMapBounds(_mapInstance), isManualSearch);
  }

  handleBusyState(value) {
    this._isMapBusy = value;
  }

  resetSelectedPoi() {
    const { urlQueryParams } = this.props;
    const newUrlQueryParams = new URLSearchParams(`?${urlQueryParams.toString()}`);
    newUrlQueryParams.delete('select');
    history.push({ search: `?${newUrlQueryParams.toString()}` });
  }

  render() {
    const options = {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    };
    const { showLoader, handleSearchMode, autoSearch } = this.props;
    const {
      handleMapMount, handleSearch, initializeMapBounds, handleBusyState, resetSelectedPoi,
    } = this;
    
    return (
      <>
        <div className="search-map">
          <label
            htmlFor="search-mode"
            className="custom-checkbox"
          >
            Búsqueda
            <br />
            automática
            <input
              type="checkbox"
              id="search-mode"
              onChange={handleSearchMode}
              defaultChecked={autoSearch}
            />
            <i />
          </label>
          {showLoader ? <BootstrapLoader extraClasses="whatever-alex-wants" /> : null}
          <SimpleMap
            id="search-map"
            onMapLoad={handleMapMount}
            onZoomChanged={handleSearch}
            onDragEnd={handleSearch}
            onDrag={() => handleBusyState(true)}
            onIdle={initializeMapBounds}
            onClick={resetSelectedPoi}
          />
          {autoSearch ? null : (
            <button
              type="button"
              className="btn btn-primary search-map__button"
              onClick={() => handleSearch(true)}
            >
              Buscar
            </button>
          )}
        </div>
      </>
    );
  }
}

SearchMap.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.object),
  showLoader: PropTypes.bool,
  autoSearch: PropTypes.bool.isRequired,
  searchBounds: PropTypes.shape({
    nel: PropTypes.number,
    nelng: PropTypes.number,
    swl: PropTypes.number,
    swlng: PropTypes.number,
  }),
  urlQueryParams: PropTypes.objectOf(PropTypes.object),
  handleSearch: PropTypes.func.isRequired,
  handleMapMount: PropTypes.func.isRequired,
  handleSearchMode: PropTypes.func.isRequired,
  hightlightedPoiId: PropTypes.string,
};

SearchMap.defaultProps = {
  showLoader: false,
  markers: [],
  searchBounds: null,
  urlQueryParams: null,
  hightlightedPoiId: null,
};

function mapStateToProps(state) {
  const pois = state.bees.entities.pois;
  const urlQueryParams = new URLSearchParams(state.router.location.search);
  return {
    urlQueryParams,
    pois,
  };
}

export default connect(mapStateToProps)(SearchMap);
