import {
  LOCALSTORAGE_SESSION,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  UPDATE_CURRENT_USER,
} from '../constants';
import { isEmpty } from '../utils';

let authSessionData = null;

try {
  authSessionData = JSON.parse(localStorage.getItem(LOCALSTORAGE_SESSION));
} catch (error) {
  console.log(error);
}

const initialState = !isEmpty(authSessionData)
  ? {
    isAuthenticated: true,
    token: authSessionData.accessToken,
    loginErrors: null,
    currentUser: null,
  }
  : {
    isAuthenticated: false,
    token: null,
    loginErrors: null,
    currentUser: null,
  };

export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        isAuthenticated: true,
        token: action.token,
        loginErrors: null,
      };
    case LOGIN_FAILURE:
      return {
        isAuthenticated: false,
        token: null,
        loginErrors: action.errors,
      };
    case LOGOUT:
      return {
        isAuthenticated: false,
        token: null,
        loginErrors: null,
        currentUser: null,
      };
    case UPDATE_CURRENT_USER: {
      const newState = { ...state };
      return {
        ...newState,
        currentUser: action.currentUser,
      };
    }
    default:
      return state;
  }
}
