import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { urlQueryParams, isEmpty } from '../utils';
import { RECOVERY_PASSWORD_ENDPOINT, RESET_PASSWORD_ENDPOINT, LOGIN_URL } from '../constants';
import MainLayout from '../layouts/main-layout';
import { history, LOCK_ICON, EMAIL_ICON } from '../helpers';
import PromiseButton from '../components/promise-button';

const REQUEST_OPTIONS = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: null,
};

function handleResponse(response) {
  if (!response.ok) {
    return response.json().then((jsonResponse) => {
      const error = jsonResponse || response.statusText;
      return Promise.reject(error);
    });
  }
  return Promise.resolve(response);
}

function handleErrorMessages(errors) {
  return !isEmpty(errors)
    ? errors.map((error, idx) => (
      <p key={idx} className="text-danger">
        {error.detail}
      </p>
    ))
    : null;
}
export default class RecoveryPage extends Component {
  constructor(props) {
    super(props);

    const queryParams = urlQueryParams();

    this.state = {
      email: '',
      password: '',
      passwordConfirmation: '',
      isLoading: false,
      errors: null,
      token: queryParams && queryParams.token ? queryParams.token : null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRecoverySubmit = this.handleRecoverySubmit.bind(this);
    this.handleResetSubmit = this.handleResetSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleRecoverySubmit(e) {
    e.preventDefault();
    const { email } = this.state;

    if (!isEmpty(email)) {
      const requestOptions = REQUEST_OPTIONS;
      requestOptions.body = JSON.stringify({ email });
      this.setState({ isLoading: true });
      fetch(RECOVERY_PASSWORD_ENDPOINT, requestOptions)
        .then(response => handleResponse(response))
        .then(() => {
          history.push('/');
        })
        .catch((reason) => {
          const errors = reason.errors ? reason.errors : [{ detail: reason.message }];
          this.setState({
            errors,
            isLoading: false,
          });
        });
    }
  }

  handleResetSubmit(e) {
    e.preventDefault();
    const { token, password, passwordConfirmation } = this.state;
    const requestOptions = REQUEST_OPTIONS;
    if (!isEmpty(token) && !isEmpty(password) && !isEmpty(passwordConfirmation)) {
      requestOptions.body = JSON.stringify({ password, passwordConfirmation });
      fetch(`${RESET_PASSWORD_ENDPOINT}?token=${token}`, requestOptions)
        .then(response => handleResponse(response))
        .then(() => {
          history.push(LOGIN_URL);
        })
        .catch((reason) => {
          const errors = reason.errors ? reason.errors : [{ detail: reason.message }];
          this.setState({
            errors,
            isLoading: false,
          });
        });
    }
  }

  render() {
    const {
      email, password, passwordConfirmation, isLoading, errors, token,
    } = this.state;

    return (
      <MainLayout>
        <Helmet>
          <title>: Recupera tu contraseña</title>
          <meta name="title" content="Topdesti: Recupera tu contraseña" />
          <meta property="og:title" content="Topdesti: Recupera tu contraseña" />
          <meta property="twitter:title" content="Topdesti: Recupera tu contraseña" />
          <meta name="description" content="Utiliza tu correo electrónico usado en el registro para recuperar tu contraseña" />
          <meta property="og:description" content="Utiliza tu correo electrónico usado en el registro para recuperar tu contraseña" />
          <meta property="twitter:description" content="Utiliza tu correo electrónico usado en el registro para recuperar tu contraseña" />
          <meta property="of:image" content={`${window.location.origin}/assets/images/topdesti-logo.png`} />
          <meta property="twitter:image" content={`${window.location.origin}/assets/images/topdesti-logo.png`} />
          <meta property="og:image:width" content="132" />
          <meta property="og:image:height" content="38" />
        </Helmet>
        <div className="container-fluid grey-bg">
          <div className="row">
            <div className="container col-12 col-lg-8 col-xl-6">
              <div className="row intro-box pb-5">
                <div className="col-12 col-md-7 mx-auto">
                  {isEmpty(token) ? (
                    <form onSubmit={this.handleRecoverySubmit}>
                      <h3 className="text-center mb-4">Recupera tu contraseña</h3>
                      <div className="form-group">
                        <label htmlFor="email" className="d-block">
                          {EMAIL_ICON}
                          <input
                            name="email"
                            type="email"
                            placeholder="ESCRIBE TU EMAIL"
                            className="form-control pl-5"
                            value={email}
                            onChange={this.handleChange}
                            required
                          />
                        </label>
                      </div>

                      {handleErrorMessages(errors)}

                      <PromiseButton
                        type="submit"
                        className="btn btn-block btn-primary mt-3"
                        loadingCondition={isLoading}
                      >
                        Enviar
                      </PromiseButton>
                    </form>
                  ) : (
                    <form onSubmit={this.handleResetSubmit}>
                      <h3 className="text-center mb-4">Cambia tu contraseña</h3>
                      <div className="form-group">
                        <label htmlFor="password" className="d-block">
                          {LOCK_ICON}
                          <input
                            name="password"
                            type="password"
                            placeholder="CONTRASEÑA NUEVA"
                            className="form-control pl-5"
                            value={password}
                            onChange={this.handleChange}
                          />
                        </label>
                      </div>

                      <div className="form-group">
                        <label htmlFor="passwordConfirmation" className="d-block">
                          {LOCK_ICON}
                          <input
                            name="passwordConfirmation"
                            type="password"
                            placeholder="REPITE CONTRASEÑA NUEVA"
                            className="form-control pl-5"
                            value={passwordConfirmation}
                            onChange={this.handleChange}
                          />
                        </label>
                      </div>

                      {handleErrorMessages(errors)}

                      <PromiseButton
                        type="submit"
                        className="btn btn-block btn-primary mt-3"
                        loadingCondition={isLoading}
                      >
                        Enviar
                      </PromiseButton>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
