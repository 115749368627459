import React, {
  useState, useEffect, useCallback,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import _cloneDeep from 'lodash.clonedeep';
import { push } from 'connected-react-router';
import api from '../../config/api-endpoints';
import { isEmpty, orderPoiImages } from '../../utils';
import reduxBeeMakeRequest from '../../utils/redux-bee/redux-bee-make-request';
import PoiImagesPageTemplate from './poi-images-page-template';
import TYPES from '../../constants/types';
import { ModalPublishConfirm, ModalWarning, ModalPublishCongrats } from '../../components';
import { PLACE_PAGE_DINAMIC_URL } from '../../constants';

function checkInfoRequirements(poi) {
  const {
    attributes: {
      title, category, description, lat, long, language,
    } = {},
  } = poi || {};

  return !isEmpty(title)
    && !isEmpty(category)
    && !isEmpty(description)
    && !isEmpty(lat)
    && !isEmpty(long)
    && !isEmpty(language);
}

const PoiImagesPage = (props) => {
  const {
    poi, poiMainImage, currentUser, poiId, dispatch,
  } = props;

  const { attributes: { 'url-title': urlTitle } = {} } = poi || {};
  const [localImages, setLocalImages] = useState([]);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [isPublishable, setIsPublishable] = useState(false);
  const [isDragginOver, setIsDragginOver] = useState(false);
  const [showPublishConfirmModal, setShowPublishConfirmModal] = useState(false);
  const [showPublishCongratsModal, setShowPublishCongratsModal] = useState(false);

  const poiPageRoute = PLACE_PAGE_DINAMIC_URL(urlTitle);

  useEffect(() => {
    const poiRequest = reduxBeeMakeRequest(dispatch, 'getPoi', { id: poiId }, {});
    const imagesRequest = reduxBeeMakeRequest(dispatch, 'getPoiImages', { id: poiId }, {});

    Promise.all([poiRequest, imagesRequest])
      .then(([responsedPoi, responsedImages]) => {
        const orderedImages = orderPoiImages(responsedPoi, responsedImages);
        const { attributes: { 'hide-warning-modal': hideWarningModal } = {} } = currentUser;
        setIsOpenWarningModal(!hideWarningModal);
        setLocalImages(_cloneDeep(orderedImages));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsPublishable(!isEmpty(localImages) && checkInfoRequirements(poi));
  }, [localImages, poi]);

  // Check the poi images relationship and add to localImages the new ones
  // avoiding to set it when there are no difference on the length
  const onAllFileComplete = useCallback(async () => {
    const poiImages = await reduxBeeMakeRequest(dispatch, 'getPoiImages', { id: poiId }, {});
    const clonedImages = _cloneDeep(localImages);
    poiImages.forEach((image) => {
      const isNewImage = isEmpty(clonedImages.find(img => img.id === image.id));
      if (isNewImage) {
        clonedImages.push(image);
      }
    });
    if (clonedImages.length !== localImages.length) { setLocalImages(clonedImages); }
  }, [dispatch, localImages, poiId]);

  const handleSetMainImage = useCallback(imageId => reduxBeeMakeRequest(
    dispatch,
    'patchPoiMainImage',
    { id: poiId },
    { id: imageId, type: 'images' },
  )
    .then(() => reduxBeeMakeRequest(dispatch, 'getPoi', { id: poiId }, {}))
    .then((updatedPoi) => {
      const newLocalImages = _cloneDeep(orderPoiImages(updatedPoi, localImages));
      setLocalImages(newLocalImages);
      toastr.success('Imagen principal seleccionada');
    }), [dispatch, localImages, poiId]);

  const handleDeleteImage = useCallback(imageId => reduxBeeMakeRequest(dispatch, 'deleteImage', { id: imageId }, {})
    .then(() => reduxBeeMakeRequest(dispatch, 'getPoiImages', { id: poiId }, {}))
    .then((images) => {
      toastr.success('Imagen eliminada');
      // TODO se pierden cambios sin guardar
      setLocalImages(_cloneDeep(images));
    })
    .catch(() => {
      toastr.error('No se ha podido eliminar la imagen, por favor, inténtalo de nuevo');
    }), [dispatch, poiId]);

  const closeModalWarning = useCallback(() => {
    setIsOpenWarningModal(false);
  }, []);

  const handleShowPublishModal = useCallback((value) => {
    setShowPublishConfirmModal(value);
    return Promise.resolve();
  }, []);

  const handleModalPublishCongrats = useCallback((value) => {
    setShowPublishCongratsModal(value);
  }, []);

  const handleChange = useCallback((e, IdImageProp) => {
    if (e.target) {
      const { name, value } = e.target;
      const imageId = e.target.dataset.image;

      const updatedImages = localImages.map((image) => {
        if (image.id === imageId) {
          const modifiedImg = image;
          modifiedImg.attributes[`${name}`] = value;
          return modifiedImg;
        }
        return image;
      });
      setLocalImages(updatedImages);
    } else {
      const richText = e === '<p><br></p>' ? '' : e;
      const updatedImages = localImages.map((image) => {
        if (image.id === IdImageProp) {
          const modifiedImg = image;
          modifiedImg.attributes[`description`] = richText;
          return modifiedImg;
        }
        return image;
      });
      setLocalImages(updatedImages)
    }
  }, [localImages]);

  const handleSave = useCallback((poiState, transitionRoute) => {
    const publishing = poiState === 2;
    const { attributes: { 'poi-type': poiType } } = poi;
    const saveRequests = [];

    localImages.forEach((image) => {
      const savePromise = dispatch(api.updateImage({ id: image.id }, { data: { ...image } }));
      saveRequests.push(savePromise);
    });

    if (publishing) {
      poi.attributes.state = 2;
      saveRequests.push(dispatch(api.updatePoi({ id: poi.id }, { data: { ...poi } })));
    } else if (!isEmpty(poiState)) {
      poi.attributes.state = poiState;
      saveRequests.push(dispatch(api.updatePoi({ id: poi.id }, { data: { ...poi } })));
    }

    return Promise.all(saveRequests)
      .then((responses) => {
        let hasError = false;
        responses.forEach((response) => {
          if (response.status !== 200) {
            hasError = true;
          }
        });
        if (hasError) {
          toastr.error('Se ha producido un error, por favor, vuelve a intentarlo');
        } else {
          const modelName = poiType === 1 ? 'ficha destino' : 'servicio';
          const sucessMsg = publishing
            ? `¡Felicidades! Has públicado tu ${modelName} con éxito, ahora deberá pasar un proceso de revisión`
            : isEmpty(poiState)
              ? 'Imágenes guardadas con éxito'
              : 'Se ha despublicado con éxito';
          toastr.success(sucessMsg);
          if (!isEmpty(transitionRoute)) {
            dispatch(push(transitionRoute));
          }
        }
      })
      .finally(() => Promise.resolve());
  }, [dispatch, localImages, poi]);

  const handleDraggingState = (value) => {
    setIsDragginOver(value);
  };

  return (
    <>
      <PoiImagesPageTemplate
        poiMainImage={poiMainImage}
        images={localImages}
        poi={poi}
        isPublishable={isPublishable}
        isDragginOver={isDragginOver}
        handleSave={handleSave}
        onAllFileComplete={onAllFileComplete}
        handleChange={handleChange}
        handleSetMainImage={handleSetMainImage}
        handleDeleteImage={handleDeleteImage}
        handleDraggingState={handleDraggingState}
        handleShowPublishModal={handleShowPublishModal}
      />
      {isOpenWarningModal ? (<ModalWarning isOpen={isOpenWarningModal} closeModal={closeModalWarning} />) : null}

      {showPublishConfirmModal ? (
        <ModalPublishConfirm
          isOpen={showPublishConfirmModal}
          handleSave={handleSave}
          closeModal={() => handleShowPublishModal(false)}
          poi={poi}
          handleModalPublishCongrats={handleModalPublishCongrats}
        />
      ) : null}

      {showPublishCongratsModal ? (
        <ModalPublishCongrats
          isOpen={showPublishCongratsModal}
          closeModal={() => handleModalPublishCongrats(false)}
          poiPageRoute={poiPageRoute}
        />
      ) : null}
    </>
  );
};

PoiImagesPage.propTypes = {
  poiId: PropTypes.string.isRequired,
  poi: TYPES.poiType,
  poiMainImage: TYPES.imageType,
  currentUser: TYPES.meType.isRequired,
  dispatch: PropTypes.func.isRequired,
};

PoiImagesPage.defaultProps = {
  poi: null,
  poiMainImage: null,
};

const mapStateToProps = (state, ownProps) => {
  const poiId = ownProps.match.params.poi_id;
  const poi = state.bees.entities.pois && state.bees.entities.pois[poiId];
  const relatedMainImage = poi && poi.relationships['main-image'].data;
  const currentUser = state.auth.isAuthenticated && !isEmpty(state.auth.currentUser) ? state.auth.currentUser : null;

  const poiMainImage = relatedMainImage && state.bees.entities.images
    ? state.bees.entities.images[relatedMainImage.id]
    : null;

  return {
    poiId,
    poi,
    poiMainImage,
    currentUser,
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
};

export default connect(mapStateToProps)(PoiImagesPage);
