import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TourSponsorBoxTemplate from './tour-sponsor-box-template';
import { isEmpty } from '../../utils';
import useGetResource from '../../helpers/hooks/useGetResource';
import reduxStore from '../../reduxStore';

const TourSponsorBox = (props) => {
  const { sponsorId, tourCampaign, campaignCarousel } = props;
  const sponsor = useGetResource({ ...(!isEmpty(sponsorId) && { id: sponsorId }) }, 'sponsors', 'getSponsor');
  const [sponsorImages, setSponsorImages] = useState([]);
  const { attributes: { visibility: sponsorVisibility = false } = {} } = sponsor || {};
  useEffect(() => {
    const { relationships: { images: { data: imagesData } = {} } = {} } = sponsor || {};
    if (!isEmpty(imagesData)) {
      const reduxImages = reduxStore.getState().bees.entities.images;
      const images = imagesData.map(imageData => reduxImages[imageData.id]);
      const orderedImages = isEmpty(images)
        ? []
        : images.sort((a, b) => a.attributes.order - b.attributes.order);

      setSponsorImages(orderedImages);
    }
  }, [sponsor]);

  const [carouselPosition, setCarouselPosition] = useState(0);

  const handleCarouselChange = useCallback(
    (index) => {
      setCarouselPosition(index);
    },
    [setCarouselPosition],
  );
  return tourCampaign == false ? null : (
    <TourSponsorBoxTemplate
      /* sponsor={sponsor}
      sponsorImages={sponsorImages} */
      tourCampaign={tourCampaign}
      campaignCarousel={campaignCarousel}
      handleCarouselChange={handleCarouselChange}
      carouselPosition={carouselPosition}
    />
  );
};

TourSponsorBox.propTypes = {
  sponsorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

function mapStateToProps(state, ownProps) {
  const { tour } = ownProps;
  const { relationships: { sponsor: { data: sponsorData = null } } = {} } = tour;

  return {
    sponsorId: isEmpty(sponsorData) ? null : sponsorData.id,
  };
}

export default connect(mapStateToProps)(TourSponsorBox);
