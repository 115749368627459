import React from 'react';
import PropTypes from 'prop-types';

export default function PoiEditionHeader(props) {
  const { mobileTitle, mobileDescription, children } = props;
  return (
    <header className="container-fluid poi-edition-header">
      <div className="row">
        <div className="container">
          <div className="row poi-edition-header__content poi-edition-header__content--mobile">
            <div className="col">
              <h3 className="poi-edition-header__title">{mobileTitle}</h3>
              <p className="poi-edition-header__description">{mobileDescription}</p>
            </div>
          </div>
          <div className="row poi-edition-header__content poi-edition-header__content--desktop">
            {children}
          </div>
        </div>
      </div>
    </header>
  );
}

PoiEditionHeader.propTypes = {
  mobileTitle: PropTypes.string.isRequired,
  mobileDescription: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};

PoiEditionHeader.defaultProps = {
  mobileDescription: '',
};
