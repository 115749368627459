import React from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash.clonedeep';
import TourContentTextFormTemplate from './tour-content-text-form-template';
import TYPES from '../../../../../../constants/types';

const TourContentTextForm = (props) => {
  const {
    localTourContent,
    setLocalTourContent,
    handleToggleVisibility,
    handleDelete,
  } = props;

  // ***************** USERS INPUTS HANDLERS *********************
  const handleTextChange = (value) => {
    // the empty editor value is '<p><br></p>'
    // before setting the state, check if editor is empty and convert it to empty string if it is
    const richText = value === '<p><br></p>' ? '' : value;

    const immutableTourContent = _cloneDeep(localTourContent);
    immutableTourContent.attributes.text = richText;
    setLocalTourContent(immutableTourContent);
  };

  const handleLenght = (val) => {
    if(val) {
      var str = val;
      var n = str.length;
      return n
    } 
    return 0
  }

  const handleInputChange = (e) => {
    const immutableTourContent = _cloneDeep(localTourContent);
    const {
      target: { value: newValue, name: attributeIdentifier },
    } = e;
    // handleLenght(newValue)
    immutableTourContent.attributes[attributeIdentifier] = attributeIdentifier == "header-text" ? e.target.checked : newValue;
    setLocalTourContent(immutableTourContent);

  };

  // *************** USERS INPUTS HANDLERS END *******************

  return (
    <TourContentTextFormTemplate
      localTourContent={localTourContent}
      handleInputChange={handleInputChange}
      handleTextChange={handleTextChange}
      handleToggleVisibility={handleToggleVisibility}
      handleDelete={handleDelete}
      inputLength={handleLenght}
    />
  );
};

TourContentTextForm.propTypes = {
  localTourContent: TYPES.tourContentType.isRequired,
  setLocalTourContent: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
TourContentTextForm.defaultProps = {};

export default TourContentTextForm;
