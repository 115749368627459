import { PropTypes } from 'prop-types';

const imageType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.oneOf(['archives']).isRequired,
  attributes: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    order: PropTypes.number,
    'file-content-type': PropTypes.string,
    'file-size': PropTypes.string,
    // todo complete...
  }),
});

export default imageType;
