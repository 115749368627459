import isEmpty from './is-empty';
import { DYNAMIC_MARKER_ICON } from '../helpers/svg-inline';

export function parseGoogleBounds(bounds) {
  const {
    swl, nel, swlng, nelng,
  } = bounds;
  const sw = new window.google.maps.LatLng(swl, swlng);
  const ne = new window.google.maps.LatLng(nel, nelng);
  const googleBounds = new window.google.maps.LatLngBounds(sw, ne);
  return googleBounds;
}

export const GOOGLE_MARKER = (label) => {
  const marker = {
    url: isEmpty(label) ? '/assets/images/marker.svg' : `${DYNAMIC_MARKER_ICON(label)}`,
    scaledSize: new window.google.maps.Size(27, 43),
  };
  return marker;
};
