import React from 'react';
import PropTypes from 'prop-types';

import './circular-bar.scss';
// https://codepen.io/cmd430/pen/vOrVqp
const CircularBar = (props) => {
  const { progress } = props;
  const max = -219.99078369140625;
  const dashOffset = ((100 - progress) / 100) * max;
  const fillStyle = {
    strokeDashoffset: dashOffset,
  };
  return (
    <div className="circular-bar">
      <svg className="circular-bar__progress noselect" x="0px" y="0px" viewBox="0 0 80 80">
        <path className="circular-bar__progress-track" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
        <path className="circular-bar__progress-fill" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" style={fillStyle} />
        <text className="circular-bar__value" x="50%" y="60%">
          {progress}
          %
        </text>
      </svg>
    </div>
  );
};

CircularBar.propTypes = {
  progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CircularBar;
