/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  USER_ICON,
  FACEBOOK_SOLID,
  INSTAGRAM_ICON,
  TWITTER_SOLID,
  YOUTUBE_ICON,
  PINTEREST_SOLID,
  VIMEO_SOLID,
  TOUR_SIGNAL_ICON,
  MARKER_BASE_CIRCLE_ICON,
  SERVICES_ICON,
  SOLID_CAMERA_ICON,
  TOP_TRAVELER_IMG,
} from '../helpers';
import { checkExternalUrl, isEmpty, getImageSrcSet } from '../utils';
import { USER_DINAMIC_URL } from '../constants';
import TYPES from '../constants/types';
import ResponsiveImg from './responsive-img';

function getSocialIcon(socialName) {
  switch (socialName) {
    case 'facebook':
      return FACEBOOK_SOLID;
    case 'instagram':
      return INSTAGRAM_ICON;
    case 'twitter':
      return TWITTER_SOLID;
    case 'youtube':
      return YOUTUBE_ICON;
    case 'pinterest':
      return PINTEREST_SOLID;
    case 'vimeo':
      return VIMEO_SOLID;
    default:
      return null;
  }
}
function renderSocialRRSS(authorAttributes) {
  const authorRRSS = {
    facebook: authorAttributes['facebook-profile'],
    instagram: authorAttributes['instagram-profile'],
    twitter: authorAttributes['twitter-profile'],
    youtube: authorAttributes['youtube-profile'],
    pinterest: authorAttributes['pinterest-profile'],
    vimeo: authorAttributes['vimeo-profile'],
  };

  const filledAuthorRRSS = Object.keys(authorRRSS).reduce((result, socialMedia) => {
    const socialProfileUrl = authorRRSS[socialMedia];
    if (!isEmpty(socialProfileUrl)) {
      result.push({ socialMedia, url: socialProfileUrl });
    }
    return result;
  }, []);

  return isEmpty(filledAuthorRRSS)
    ? null
    : filledAuthorRRSS.map(socialProfile => (
      <li key={`${socialProfile.socialMedia}-link`} className="author-box__rrss-item">
        <a
          href={checkExternalUrl(socialProfile.url)}
          target="_blank"
          rel="noopener noreferrer"
          className="author-box__rrss-link"
        >
          {getSocialIcon(socialProfile.socialMedia)}
        </a>
      </li>
    ));
}

function getCategoryText(relatedType) {
  switch (relatedType) {
    case 'tour':
      return 'Creador de la ruta';
    case 'place':
      return 'Creador del destino';
    case 'service':
      return 'Creador del servicio';
    default:
      return 'Creador';
  }
}

function AuthorBox(props) {
  const {
    isMobile, author, avatar, authorBoxRef, relatedType,
  } = props;
  if (!author) return null;
  const {
    attributes: {
      name,
      surname,
      nickname,
      'destination-pois-counter': destinationsCount,
      'images-counter': uploadedImagesCount,
      'service-pois-counter': servicesCount,
      'tours-counter': toursCount,
      website,
      description,
      toptraveler,
    },
  } = author;

  const categoryText = getCategoryText(relatedType);

  return (
    <article className="container author-box" ref={authorBoxRef}>
      <header className="row justify-content-end author-box__header">
        <div className="col-auto px-1">
          <span className="author-box__category">{categoryText}</span>
        </div>
        <div className="col-auto pl-1">
          <figure className="author-box__header-icon">{USER_ICON}</figure>
        </div>
      </header>
      <div className="row">
        <div className="col-12">
          <div className={(isMobile) ? "column" : "row"}>
            <div className={(isMobile) ? "col-lg-auto" : "col-4 col-lg-auto"} >
              <ResponsiveImg
                className="author-box__avatar"
                type="avatar"
                alt="Avatar autor"
                wrapperClassName="author-box__avatar-wrapper"
                srcSet={getImageSrcSet(avatar)}
                key="cover-author-avatar"
              >
                <figcaption className="sr-only">Avatar del autor</figcaption>
              </ResponsiveImg>
              {isMobile && toptraveler
                ? (
                  <div className="author-box__top-traveler-wrapper">
                    {TOP_TRAVELER_IMG}
                  </div>
                )
                : null
              }
              <div className="col-12 px-0">
                <p className="author-box__nickname">{nickname}</p>
              </div>
              {isMobile
                ? (
                  <div className="designprofile">
                    <div className="profileItems">
                      <div>
                        {TOUR_SIGNAL_ICON}
                      </div>
                      <div>
                        {`${toursCount} `}
                      </div>
                    </div>

                    <div className="profileItems">
                      <div>
                        {MARKER_BASE_CIRCLE_ICON}
                      </div>
                      <div>
                        {`${destinationsCount} `}
                      </div>
                    </div>
                    <div className="profileItems">
                      <div>
                        {SERVICES_ICON}
                      </div>
                      <div>
                        {`${servicesCount} `}
                      </div>
                    </div>
                    <div className="profileItems">
                      <div>
                        {SOLID_CAMERA_ICON}
                      </div>
                      <div>
                        {`${uploadedImagesCount} `}
                      </div>
                    </div>
                  </div>
                  // <ul className="author-box__data">
                  //   <li className="author-box__data-item">
                  //     {TOUR_SIGNAL_ICON}
                  //     {`${toursCount} Rutas`}
                  //   </li>
                  //   <li className="author-box__data-item">
                  //     {MARKER_BASE_CIRCLE_ICON}
                  //     {`${destinationsCount} Destinos`}
                  //   </li>
                  //   <li className="author-box__data-item">
                  //     {SERVICES_ICON}
                  //     {`${servicesCount} Recomendaciones`}
                  //   </li>
                  //   <li className="author-box__data-item">
                  //     {SOLID_CAMERA_ICON}
                  //     {`${uploadedImagesCount} Fotografías`}
                  //   </li>
                  // </ul>
                )
                : (
                  <>
                    <div className="row">
                      <Link
                        to={USER_DINAMIC_URL(nickname)}
                        className="btn btn-primary w-50 w-lg-auto d-block mx-auto"
                      >
                      Ver perfil
                      </Link>
                    </div>
                  </>
                )}
            </div>
            <div className= {(isMobile) ? "col-lg" : "col-8 col-lg"} >
              <div className="row author-box__info">
                <div className= {(isMobile) ?  "col-12 col-lg-auto centerNickName" :  "col-12 col-lg-auto"}>
                  <h3 className="author-box__name">{`${name} ${surname}`}</h3>
                  <a className="author-box__website" href={checkExternalUrl(website)}>
                    {website || ''}
                  </a>
                </div>
                <div className="col-12 col-lg">
                  <ul className="author-box__rrss">
                    {renderSocialRRSS(author.attributes)}
                  </ul>
                </div>
              </div>
              {!isMobile ? (
                <>
                  {toptraveler
                    ? (
                      <div className="author-box__top-traveler-wrapper">
                        {TOP_TRAVELER_IMG}
                      </div>
                    )
                    : null}
                  <ul className="author-box__data">
                    <li className="author-box__data-item">
                      {TOUR_SIGNAL_ICON}
                      {`${toursCount} Rutas`}
                    </li>
                    <li className="author-box__data-item">
                      {MARKER_BASE_CIRCLE_ICON}
                      {`${destinationsCount} Destinos`}
                    </li>
                    <li className="author-box__data-item">
                      {SERVICES_ICON}
                      {`${servicesCount} Recomendaciones`}
                    </li>
                    <li className="author-box__data-item">
                      {SOLID_CAMERA_ICON}
                      {`${uploadedImagesCount} Fotografías`}
                    </li>
                  </ul>
                </>
              ) : null}
              <p className="author-box__description" dangerouslySetInnerHTML={{ __html: description || '' }}></p>
            </div>
          </div>
        </div>
      </div>
      {isMobile
        ? (
          <div className="row">
            <Link
              to={USER_DINAMIC_URL(nickname)}
              className="btn btn-primary w-50 w-lg-auto d-block mx-auto"
            >
              Ver perfil
            </Link>
          </div>
        )
        : null}
    </article>
  );
}

AuthorBox.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  author: TYPES.userType.isRequired,
  avatar: TYPES.imageType,
  authorBoxRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  relatedType: PropTypes.string.isRequired,
};

AuthorBox.defaultProps = {
  avatar: null,
  authorBoxRef: null,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(AuthorBox);
