import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import {
  CLOSE_ICON, EYE_ICON, EYE_NOT_ICON, INBOX_ICON,
} from '../../../../../../../helpers';
import TYPES from '../../../../../../../constants/types';
import { getTourContentThumbImg, isEmpty, isEmptyObject } from '../../../../../../../utils';
import getRelatedEntitiesFromRedux from '../../../../../../../utils/redux-bee/get-related-entities-from-redux';
import useGenerateMissingCacheResources from '../../../../../../../helpers/hooks/useGenerateMissingCacheResources';
import { PromiseButton, ResponsiveImg } from '../../../../../../../components';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import ENV from '../../../../../../../config/environment';

class TourContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pois: [],
      imageCachePoi: null,
      titleCachePoi: null
    };
  }
  componentDidMount() {
    this.setState({
      pois: this.props.pois,
    })
    this.getImagesPoi()
  }
  componentDidUpdate(prevProps) {
    if (this.props.pois.length !== prevProps.pois.length) {
      this.setState({
        pois: this.props.pois,
      })
      this.getImagesPoi()
    }
  }

  getImagesPoi = () => {
    const tourContent = this.props.tourContent;
    let poiImage = "";
    let poi;
    if (tourContent.attributes["content-type"] == 1) {
      poi = this.props.pois && this.props.pois.find(e => e.id == tourContent.relationships.poi.data.id)
      if (poi == undefined) {
        axios.get(`${ENV.host}/${ENV.namespace}/pois/${tourContent.relationships.poi.data.id}`)
          .then((res) => {
            let image = res.data.included;
            image.sort((a, b) => b.attributes.likes - a.attributes.likes)
            this.setState({ imageCachePoi: image[0], titleCachePoi: res.data.data.attributes.title })
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }


  render() {
    const {
      handleContentSelection,
      tourContent,
      tourContentArchives,
      handleDeleteTourContent,
      handleToogleTourContentVisibility,
      poiDisplayedImage,
      notCachedResources,
      index,
    } = this.props;
    let poiImage = "";
    let poi;
    if (tourContent.attributes["content-type"] == 1) {
      poi = this.state.pois && this.state.pois.find(e => e.id == tourContent.relationships.poi.data.id)
      if (poi && poi.attributes && poi.attributes.images && poi.attributes.images.length > 0) {
        let image = poi.attributes.images;
        image.sort((a, b) => b.likes - a.likes)
        image[0]["tiny-thumb"] = image[0].tinyThumb
        let imageA = {
          attributes: image[0]
        }
        poiImage = imageA;
      }
    }
    const {
      attributes: { 'content-type': contentType = null },
      relationships: { 'tour-contents': { data: tourContentChilds = null } = {} } = {},
    } = tourContent;
    const displayedImage = (contentType === 2 || contentType === 3) && !isEmpty(tourContentArchives)
      ? tourContentArchives[0]
      : contentType === 1 && !isEmpty(poiImage)
        ? poiImage
        : this.state.imageCachePoi;
    const hasChildContents = contentType === 1 && !isEmpty(tourContentChilds);
    let tooltipTitle;

    switch (contentType) {
      // Place or Service types
      case 1:
        tooltipTitle = poi && poi.attributes.title ? poi.attributes.title : this.state.titleCachePoi ? this.state.titleCachePoi : "NoName";
        break
      case 2:
        tooltipTitle = tourContent.attributes.title;
        break
      // Image type
      case 3:
        tooltipTitle = "imágenes"
        break
      // Text type
      case 4:
        tooltipTitle = tourContent.attributes.title;
        break
      /* Video type */
      case 5:
        tooltipTitle = "vídeo"
        break
      /* Map info type */
      case 6:
        tooltipTitle = "Mapa"
        break
      /* Time type */
      case 7:
        tooltipTitle = "Hora y tiempo de visita"
        break
      default:
        tooltipTitle = "Sin nombre"
        throw new Error('Sin nombre');
    }
    // useGenerateMissingCacheResources(notCachedResources);
    return (
      <Draggable draggableId={tourContent.id} index={index}>
        {(provided, snapshot) => (
          <li
            className={`day-content__list-item ${snapshot.isDragging ? 'dragging' : ''} ${
              contentType === 2 ? 'day-content__list-item--blue' : ''
              } ${contentType === 8 ? 'day-content__list-item--green' : ''}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <PromiseButton
              type="button"
              className="day-content__hide-show-btn"
              title="Mostrar/Ocultar"
              onClick={() => handleToogleTourContentVisibility(tourContent)}
            >
              {EYE_ICON}
            </PromiseButton>
            <PromiseButton
              type="button"
              className="day-content__remove-btn"
              title="Eliminar"
              onClick={() => handleDeleteTourContent(tourContent)}
            >
              {CLOSE_ICON}
            </PromiseButton>
            <button
              data-toggle="tooltip" data-tip={tooltipTitle} data-class='tooltipDesktop' data-place='top' data-type="dark"
              type="button"
              className={`day-content__list-item-btn ${
                contentType === 3 && !isEmpty(tourContentArchives)
                  ? 'day-content__list-item-btn--no-box'
                  : ''
                }`}
              onClick={() => handleContentSelection(tourContent)}
            >
              {(contentType === 2 || contentType === 3) && tourContentArchives.length > 0 ? (
                <span className="day-content__list-item-balloon">{tourContentArchives.length}</span>
              ) : null}
              {hasChildContents ? (
                <span className="day-content__list-item-balloon day-content__list-item-balloon--icon">
                  {INBOX_ICON}
                </span>
              ) : null}
              <ResponsiveImg
                src={getTourContentThumbImg(contentType, displayedImage)}
                alt="Imagen"
                className={`day-content__list-item-img ${
                  isEmpty(displayedImage) ? 'day-content__list-item-img--icon' : ''
                  }`}
                imageProps={provided.dragHandleProps}
              />
              {tourContent.attributes.visible ? null : (
                <div className="day-content__no-visible-layer" {...provided.dragHandleProps}>
                  {EYE_NOT_ICON}
                </div>
              )}
            </button>
            <ReactTooltip effect="solid" html />
          </li>
        )}
      </Draggable>
    );
  };
}

TourContent.propTypes = {
  index: PropTypes.number.isRequired,
  tourContent: TYPES.tourContentType.isRequired,
  poiDisplayedImage: TYPES.imageType,
  tourContentArchives: PropTypes.arrayOf(TYPES.imageType),
  handleContentSelection: PropTypes.func.isRequired,
  handleDeleteTourContent: PropTypes.func.isRequired,
  handleToogleTourContentVisibility: PropTypes.func.isRequired,
};

TourContent.defaultProps = {
  tourContentArchives: [],
  poiDisplayedImage: null,
};

function mapStateToProps(state, ownProps) {
  const { tourContent } = ownProps;
  const beeTourContent = state.bees.entities.tourContents[tourContent.id];
  const {
    attributes: { 'content-type': contentType },
    relationships: {
      archives: { data: archivesData },
    },
  } = beeTourContent;
  const notCachedResources = [];

  const tourContentArchives = isEmpty(archivesData)
    ? []
    : archivesData
      .map(archiveData => state.bees.entities[archiveData.type][archiveData.id])
      .sort((a, b) => a.attributes.order - b.attributes.order);

  let poiDisplayedImage;
  let tourContentPoi;

  const [cachedTourContentPoi, missingTourContentPoiResources] = contentType === 1 ? getRelatedEntitiesFromRedux(beeTourContent, 'poi', 'getPoi') : [null, null];
  if (!isEmptyObject(cachedTourContentPoi)) tourContentPoi = cachedTourContentPoi;
  if (!isEmptyObject(missingTourContentPoiResources)) notCachedResources.push(...missingTourContentPoiResources);
  if (!isEmptyObject(tourContentPoi)) {
    const [
      cachedTourContentMainImage,
      missingTourContentMainImageResources,
    ] = getRelatedEntitiesFromRedux(tourContentPoi, 'main-image', 'getImage');
    if (!isEmptyObject(cachedTourContentMainImage)) poiDisplayedImage = cachedTourContentMainImage;
    if (!isEmptyObject(missingTourContentMainImageResources)) {
      notCachedResources.push(...missingTourContentMainImageResources);
    }
    if (isEmptyObject(poiDisplayedImage)) {
      // try to get the first image of the related "images" and set is as the "main image"
      const [cachedPoiImages, missingPoiImagesResources] = getRelatedEntitiesFromRedux(
        tourContentPoi,
        'images',
        'getImage',
      );
      if (!isEmptyObject(missingPoiImagesResources)) notCachedResources.push(...missingPoiImagesResources);

      if (!isEmptyObject(cachedPoiImages)) {
        [poiDisplayedImage] = cachedPoiImages;
      }
      // if no related images is found, poiDisplayedImage is undefined and default photo is used during render
    }
  }
  return {
    tourContentArchives,
    poiDisplayedImage,
    notCachedResources,
  };
}

export default connect(mapStateToProps)(TourContent);
