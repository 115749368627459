import { toastr } from 'react-redux-toastr';

function handleError(error) {
  const toasterError = {
    title: 'Error',
  };
  // if error is a JSON from the server...
  // if the JSON from the server contains an array of errors, launch the first error
  if (error.body && error.body.errors[0] && error.body.errors[0].detail) {
    const {
      body: {
        errors: [{ title, detail }],
      },
    } = error;
    // if the first error doesn't have a "title" prop, only a detail
    if (title) {
      toasterError.title = title;
      toasterError.detail = detail;
    } else {
      toasterError.detail = detail;
    }
  } else if (error.body && !Array.isArray(error.body.errors)) {
    // if the JSON doesn't contain an array, but a single error
    const {
      body: {
        errors: { title, detail },
      },
    } = error;
    // if the first error doesn't have a "title" prop, only a detail
    if (title) {
      toasterError.title = title;
      toasterError.detail = detail;
    } else {
      toasterError.detail = detail;
    }
  } else {
    // if the error isn't a json from the server, but a standard error object
    toasterError.detail = error.message;
  }

  toastr.error(toasterError.title, toasterError.detail);
  return toasterError;
}
export default handleError;
