/* **** VIDEO **** */
export const VIDEO_PLAYER_CONFIG = {
  youtube: {
    playerVars: { showinfo: 0, controls: 1 },
  },
};

/* **** IMAGES **** */
export const defaultAvatar = '/assets/images/default-avatar.png';
export const defaultPartner = '/assets/images/test-partner.svg';

/* **** MAP/MARKERS **** */
export const defaultMapCenter = {
  lat: 27.95598471816224,
  lng: -15.570511284917075,
};

export const defaultBounds = {
  nel: 28.207093735499434,
  nelng: -15.25774425610848,
  swl: 27.704290261737945,
  swlng: -15.883278313725668,
};
