import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import PlacesAutocomplete from 'react-places-autocomplete';
import TYPES from '../../../../../../constants/types';
import { MAP_CIRCLE_ICON } from '../../../../../../helpers';
import { isEmpty } from '../../../../../../utils';
import { Gmaps, PromiseButton } from '../../../../../../components';
import { defaultMapCenter } from '../../../../../../constants';

const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];

const RenderAutoCompleteFunction = ({
  getInputProps, suggestions, getSuggestionItemProps, loading,
}) => (
    <>
      <input
        onFocus={
          (e) => {
            if (e.target.value != "") {
              e.target.value = "";
            }
          }
        }
        {...getInputProps({
          placeholder: 'Busca un punto de interés...',
          className: 'form-control',
        })}
      />
      {!isEmpty(suggestions) ? (
        <div className="tour-content-map-form__suggestions">
          {loading && <div>Loading...</div>}
          {suggestions.map((suggestion) => {
            const className = `
                    tour-content-map-form__suggestions-item ${
              suggestion.active ? 'tour-content-map-form__suggestions-item--active' : ''
              }`;
            return (
              <div
                {...getSuggestionItemProps(suggestion, {
                  className,
                })}
              >
                <span>{suggestion.description}</span>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );

RenderAutoCompleteFunction.propTypes = {
  getInputProps: PropTypes.func.isRequired,
  getSuggestionItemProps: PropTypes.func.isRequired,
  suggestions: PropTypes.shape({ active: PropTypes.bool, description: PropTypes.string }).isRequired,
  loading: PropTypes.bool.isRequired,
};

const TourContentMapFormTemplate = (props) => {
  const {
    localTourContent,
    handleToggleVisibility,
    handleDelete,
    handleInput,
    handleTextChange,
    isVisibleMapLocalizator,
    showMapLocalizator,
    saveMarkerPosition,
    address,
    handleAddressChange,
    handleAddressSelect,
    onMapComponentDidMount,
  } = props;

  const {
    attributes: {
      text, title, lat, long, visible: isVisible,
    } = {},
    relationships: {
      'tour-content': { data: parentTourContent },
    },
  } = localTourContent;
  const isVisibleExtraBtns = !isEmpty(localTourContent.id);

  return (
    <div className="container-fluid tour-content-map-form">
      <div className="row">
        <div className="col-12">
          <div className="row align-items-center align-items-lg-end">
            <div className="col-7 col-lg-6 ml-auto">
              <div className="row">
                <div className="col-10 col-lg ml-auto pr-lg-1">
                  <input
                    type="number"
                    className="form-control text-center"
                    name="lat"
                    value={lat || ''}
                    readOnly
                  />
                  <p className="tour-content-map-form__location-text">Latitud</p>
                </div>
                <div className="col-10 col-lg ml-auto pl-lg-1">
                  <input
                    type="number"
                    className="form-control mt-2 mt-lg-0 text-center"
                    name="long"
                    value={long || ''}
                    readOnly
                  />
                  <p className="tour-content-map-form__location-text">Longitud</p>
                </div>
              </div>
            </div>
            <div className="col-4 tour-content-map-form__location">
              <button
                href="#"
                className="tour-content-map-form__location-btn"
                type="button"
                onClick={() => showMapLocalizator(!isVisibleMapLocalizator)}
              >
                <img src={MAP_CIRCLE_ICON} alt="Mapa" className="tour-content-map-form__location-img" />
                <p className="tour-content-map-form__location-text">
                  {isVisibleMapLocalizator ? 'Cerrar sin guardar' : 'Ubicar con mapa'}
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isVisibleMapLocalizator ? (
        <div className="row">
          <div className="col-12">
            <h4 className="tour-content-map-form__map-title">Marca un lugar y pulsa en guardar:</h4>
          </div>
          <div className="col-12 col-md-8 col-lg-6 mx-auto mb-3">
            <PlacesAutocomplete value={address} onChange={handleAddressChange} onSelect={handleAddressSelect}>
              {RenderAutoCompleteFunction}
            </PlacesAutocomplete>
          </div>
          <div className="col-12 tour-content-map-form__map">
            <Gmaps
              onMapComponentDidMount={onMapComponentDidMount}
              onMarkerCoordSave={saveMarkerPosition}
              defaultZoom={10}
              defaultCenter={{
                lat: isEmpty(lat) ? defaultMapCenter.lat : lat,
                lng: isEmpty(long) ? defaultMapCenter.lng : long,
              }}
              positionBtnsInset
            />
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-12 mt-3">
          <label htmlFor="title" className="tour-content-map-form__label">
            Título:
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={isEmpty(title) ? '' : title}
            onChange={handleInput}
            className="form-control"
          />
        </div>
        <div className="col-12 mt-3">
          <label htmlFor="map-text" className="tour-content-map-form__label">
            Texto:
          </label>
          <ReactQuill
            id="map-text"
            type="text"
            value={text}
            onChange={handleTextChange}
            className="tour-content-map-form__text-editor"
            modules={MODULES}
            formats={FORMATS}
          />
        </div>
      </div>
      {isVisibleExtraBtns
        ? (
          <div className="row justify-content-end">
            <div className="col-12 col-md-auto pr-md-1">
              <button
                type="button"
                onClick={() => handleToggleVisibility()}
                className="btn btn-primary d-block w-100 mt-3"
              >
                {`${isVisible ? 'ocultar' : 'mostrar'} en ${
                  isEmpty(parentTourContent) ? 'la ruta' : 'el destino'
                  }`}
              </button>
            </div>
            <div className="col-12 col-md-auto px-md-1">
              <PromiseButton
                type="button"
                onClick={() => handleDelete()}
                className="btn btn-danger d-block w-100 mt-3"
                disabled={isEmpty(localTourContent.id)}
              >
                {`eliminar ${isEmpty(parentTourContent) ? 'de la ruta' : 'del destino'}`}
              </PromiseButton>
            </div>
          </div>
        )
        : null}
    </div>
  );
};

TourContentMapFormTemplate.propTypes = {
  localTourContent: TYPES.tourContentType.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isVisibleMapLocalizator: PropTypes.bool.isRequired,
  showMapLocalizator: PropTypes.func.isRequired,
  saveMarkerPosition: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  handleAddressSelect: PropTypes.func.isRequired,
  onMapComponentDidMount: PropTypes.func.isRequired,
};
TourContentMapFormTemplate.defaultProps = {};

export default TourContentMapFormTemplate;
