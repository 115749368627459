import { useState, useEffect } from 'react';
import reduxBeeMakeRequest from '../../utils/redux-bee/redux-bee-make-request';
import { isEmpty } from '../../utils';
import reduxStore, { dispatch } from '../../reduxStore';
import reduxBeeRequestErrorHandler from '../../utils/redux-bee/redux-bee-request-error-handler';

function getRelationshipId(resource, relationshipName) {
  if (isEmpty(resource)) {
    return null;
  }
  const { relationships: { [`${relationshipName}`]: { data: relationshipData } } = {} } = resource;
  if (Array.isArray(relationshipData)) return relationshipData.map(relatedObj => relatedObj.id);
  return isEmpty(relationshipData) ? null : relationshipData.id;
}

export default function useGetRelationshipResource(mainEntity, relationshipName, resourceType, endPoint) {
  const [resource, setResource] = useState(null);

  useEffect(() => {
    const familyResources = reduxStore.getState().bees.entities[resourceType];
    const storedResource = familyResources && familyResources[resourceType];
    if (
      isEmpty(storedResource)
      && !isEmpty(mainEntity)
      && !isEmpty(relationshipName)
      && !isEmpty(resourceType)
      && !isEmpty(endPoint)
    ) {
      const relationshipId = getRelationshipId(mainEntity, relationshipName);
      const params = {};
      if (!isEmpty(relationshipId)) {
        // if the relationship is an array the endpoint MUST BE the {/:mainEntityid/relationshipName} version
        params.id = mainEntity.id;
        // if the relationship is a single entity the endpoint will be the {/resourceType/:relatedEntityId}
        if (!Array.isArray(relationshipId)) params.id = relationshipId;
        reduxBeeMakeRequest(dispatch, endPoint, params)
          .then((serverResource) => {
            setResource(serverResource);
          })
          .catch(reduxBeeRequestErrorHandler);
      }
    } else {
      setResource(storedResource);
    }
  }, [mainEntity, relationshipName, resourceType, endPoint]);

  return resource;
}
