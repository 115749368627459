/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FACEBOOK_SOLID,
  INSTAGRAM_ICON,
  YOUTUBE_ICON,
  PINTEREST_SOLID,
  VIMEO_SOLID,
  TWITTER_SOLID,
} from '../../../helpers';
import { isEmpty } from '../../../utils';
import { FineUploader, PromiseButton } from '../../../components';
import ReactQuill from 'react-quill';


const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    console.log(user)
    const {
      name, surname, nickname, description, website,
    } = !isEmpty(user) && user.attributes;

    const {
      attributes: {
        'facebook-profile': facebookProfile,
        'instagram-profile': instagramProfile,
        'youtube-profile': youtubeProfile,
        'pinterest-profile': pinterestProfile,
        'vimeo-profile': vimeoProfile,
        'twitter-profile': twitterProfile,
        'booking-id': bookingId,
        'getyourguide-id': getyourguideId,
        'donation-box-title': donationBoxTitle,
        'donation-box-video': donationBoxVideo,
        'donation-box-text': donationBoxText,
        'donation-box-link-platform': donationBoxLinkPlatform,
        'donation-box-show-button': donationBoxShowButton,
      } = {},
    } = user || {};

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChangeDonationText = this.handleChangeDonationText.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileUploadComplete = this.onFileUploadComplete.bind(this);
    this.state = {
      editedName: name,
      editedSurname: surname,
      editedNickname: nickname,
      editedDescription: description,
      editedWebsite: website,
      editedFacebookProfile: facebookProfile,
      editedInstagramProfile: instagramProfile,
      editedYoutubeProfile: youtubeProfile,
      editedPinterestProfile: pinterestProfile,
      editedVimeoProfile: vimeoProfile,
      editedTwitterProfile: twitterProfile,
      editedBookingId: bookingId,
      editedGetyourguideId: getyourguideId,
      editedDonationBoxTitle: donationBoxTitle,
      editedDonationBoxVideo: donationBoxVideo,
      editedDonationBoxText: donationBoxText,
      editedDonationBoxLinkPlatform: donationBoxLinkPlatform,
      editedDonationBoxShowButton: donationBoxShowButton
    };
  }

  onFileUploadComplete(id, name, response) {
    const { updateAvatar } = this.props;
    if (response.success) {
      updateAvatar(response.data.attributes.thumb);
    }
  }

  handleSubmit() {
    const { handleSave } = this.props;
    const {
      editedName,
      editedSurname,
      editedNickname,
      editedDescription,
      editedWebsite,
      editedFacebookProfile,
      editedInstagramProfile,
      editedYoutubeProfile,
      editedPinterestProfile,
      editedVimeoProfile,
      editedTwitterProfile,
      editedBookingId,
      editedGetyourguideId,
      editedDonationBoxTitle,
      editedDonationBoxVideo,
      editedDonationBoxText,
      editedDonationBoxLinkPlatform,
      editedDonationBoxShowButton,
    } = this.state;

    return handleSave({
      name: editedName,
      surname: editedSurname,
      nickname: editedNickname,
      description: editedDescription,
      website: editedWebsite,
      'facebook-profile': editedFacebookProfile,
      'instagram-profile': editedInstagramProfile,
      'youtube-profile': editedYoutubeProfile,
      'pinterest-profile': editedPinterestProfile,
      'vimeo-profile': editedVimeoProfile,
      'twitter-profile': editedTwitterProfile,
      'booking-id': editedBookingId,
      'getyourguide-id': editedGetyourguideId,
      'donation-box-title': editedDonationBoxTitle,
      'donation-box-video': editedDonationBoxVideo,
      'donation-box-text': editedDonationBoxText,
      'donation-box-link-platform': editedDonationBoxLinkPlatform,
      'donation-box-show-button': editedDonationBoxShowButton,
    });
  }

  handleChange(e) {
    if (e.target) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    } else {
      const richText = e === '<p><br></p>' ? '' : e;
      this.setState({ editedDescription: richText });
    }
  }
  handleCheckboxChange(e) {
    const { target: { checked } } = e;
    this.setState({ editedDonationBoxShowButton: checked });
  }
  handleChangeDonationText(e) {
    const richText = e === '<p><br></p>' ? '' : e;
    this.setState({ editedDonationBoxText: richText });
  }

  render() {
    const { toggleEditMode, user, mappedErrors } = this.props;
    const {
      'is-computed-nickname': showNicknameEdition,
      nickname,
    } = !isEmpty(user) && user.attributes;

    const {
      nickname: nicknameError,
      facebookProfile: facebookProfileError,
      instagramProfile: instagramProfileError,
      youtubeProfile: youtubeProfileError,
      twitterProfile: twitterProfileError,
      vimeoProfile: vimeoProfileError,
      pinterestProfile: pinterestProfileError,
    } = mappedErrors;
    const {
      editedName,
      editedSurname,
      editedNickname,
      editedDescription,
      editedWebsite,
      editedFacebookProfile,
      editedInstagramProfile,
      editedYoutubeProfile,
      editedPinterestProfile,
      editedVimeoProfile,
      editedTwitterProfile,
      editedBookingId,
      editedGetyourguideId,
      editedDonationBoxTitle,
      editedDonationBoxVideo,
      editedDonationBoxText,
      editedDonationBoxLinkPlatform,
      editedDonationBoxShowButton,
    } = this.state;
    return (
      <div className="row justify-content-center">
        <div className="profile-page__actions">
          <FineUploader
            endpoint="/images"
            autoUpload
            params={{
              imageableId: user.id,
              imageableType: 'user',
              imageableProperty: 'avatar',
            }}
            onComplete={this.onFileUploadComplete}
            isDropzone={false}
            withProgressBars={false}
            withTotalProgressBar
            totalProgressBarType="linear"
          >
            <button className="btn btn-block btn-primary" type="button">
              Subir avatar
            </button>
          </FineUploader>
          <PromiseButton
            type="button"
            onClick={this.handleSubmit}
            className="btn btn-primary"
            title="Guardar"
          >
            Guardar
          </PromiseButton>
          <button
            type="button"
            onClick={toggleEditMode}
            className="btn btn-outline-dark"
            title="Cancelar"
          >
            Cancelar
          </button>
        </div>
        {showNicknameEdition
          ? (
            <div className="col-12 col-md-10 profile-top__profile-desc mt-1 mb-3">
              <div className="row mt-4">
                <div className="col-12 text-center">
                  <h5>Nombre de usuario</h5>
                </div>
                <div className="col-12 text-center">
                  <p>
                    Tu nombre de usuario actual fue generado automáticamente pero puedes cambiarlo una vez si quieres.
                    Una vez cambiado ya no lo podrás volver a editar.
                  </p>
                </div>
                <div className="col-12 col-sm-6 offset-sm-3 offset-md-4 col-md-4">
                  <input
                    placeholder="Nickname"
                    type="text"
                    name="editedNickname"
                    value={isEmpty(editedNickname) ? '' : editedNickname}
                    className="form-control profile-page__input"
                    onChange={this.handleChange}
                  />
                </div>
                {!isEmpty(nicknameError)
                  ? (
                    <div className="col-12 text-center">
                      <span className="d-block text-danger">{nicknameError}</span>
                    </div>
                  )
                  : null
                }
              </div>
            </div>
          )
          : null
        }
        <div className="col-12 col-md-5 profile-page__form-side">
          <input
            type="text"
            name="editedName"
            className="form-control profile-page__input"
            placeholder="Nombre"
            value={isEmpty(editedName) ? '' : editedName}
            onChange={this.handleChange}
          />
          <input
            type="text"
            name="editedSurname"
            className="form-control profile-page__input mt-1"
            placeholder="Apellido"
            value={isEmpty(editedSurname) ? '' : editedSurname}
            onChange={this.handleChange}
          />
          <div className="input-group profile-page__input mt-1">
            <div className="input-group-prepend">
              <span className="input-group-text">{FACEBOOK_SOLID}</span>
            </div>
            <input
              type="text"
              name="editedFacebookProfile"
              className={`form-control${!isEmpty(facebookProfileError) ? ' is-invalid' : ''}`}
              placeholder="Facebook"
              value={isEmpty(editedFacebookProfile) ? '' : editedFacebookProfile}
              onChange={this.handleChange}
            />
          </div>
          {!isEmpty(facebookProfileError)
            ? (
              <div className="col-12 text-center">
                <span className="d-block text-danger">{facebookProfileError}</span>
              </div>
            )
            : null}

          <div className="input-group profile-page__input mt-1">
            <div className="input-group-prepend">
              <span className="input-group-text">{INSTAGRAM_ICON}</span>
            </div>
            <input
              type="text"
              name="editedInstagramProfile"
              className={`form-control${!isEmpty(instagramProfileError) ? ' is-invalid' : ''}`}
              placeholder="Instagram"
              value={isEmpty(editedInstagramProfile) ? '' : editedInstagramProfile}
              onChange={this.handleChange}
            />
          </div>
          {!isEmpty(instagramProfileError)
            ? (
              <div className="col-12 text-center">
                <span className="d-block text-danger">{instagramProfileError}</span>
              </div>
            )
            : null}
        </div>

        <div className="col-12 col-md-5 profile-page__form-side">
          <div className="input-group profile-page__input mt-1 mt-md-0">
            <div className="input-group-prepend">
              <span className="input-group-text">{TWITTER_SOLID}</span>
            </div>
            <input
              type="text"
              name="editedTwitterProfile"
              className={`form-control${!isEmpty(twitterProfileError) ? ' is-invalid' : ''}`}
              placeholder="Twitter"
              value={isEmpty(editedTwitterProfile) ? '' : editedTwitterProfile}
              onChange={this.handleChange}
            />
          </div>
          {!isEmpty(twitterProfileError)
            ? (
              <div className="col-12 text-center">
                <span className="d-block text-danger">{twitterProfileError}</span>
              </div>
            )
            : null}
          <div className="input-group profile-page__input mt-1">
            <div className="input-group-prepend">
              <span className="input-group-text">{YOUTUBE_ICON}</span>
            </div>
            <input
              type="text"
              name="editedYoutubeProfile"
              className={`form-control${!isEmpty(youtubeProfileError) ? ' is-invalid' : ''}`}
              placeholder="Youtube"
              value={isEmpty(editedYoutubeProfile) ? '' : editedYoutubeProfile}
              onChange={this.handleChange}
            />
          </div>
          {!isEmpty(youtubeProfileError)
            ? (
              <div className="col-12 text-center">
                <span className="d-block text-danger">{youtubeProfileError}</span>
              </div>
            )
            : null}
          <div className="input-group profile-page__input mt-1">
            <div className="input-group-prepend">
              <span className="input-group-text">{PINTEREST_SOLID}</span>
            </div>
            <input
              type="text"
              name="editedPinterestProfile"
              className={`form-control${!isEmpty(pinterestProfileError) ? ' is-invalid' : ''}`}
              placeholder="Pinterest"
              value={isEmpty(editedPinterestProfile) ? '' : editedPinterestProfile}
              onChange={this.handleChange}
            />
          </div>
          {!isEmpty(pinterestProfileError)
            ? (
              <div className="col-12 text-center">
                <span className="d-block text-danger">{pinterestProfileError}</span>
              </div>
            )
            : null}
          <div className="input-group profile-page__input mt-1">
            <div className="input-group-prepend">
              <span className="input-group-text">{VIMEO_SOLID}</span>
            </div>
            <input
              type="text"
              name="editedVimeoProfile"
              className={`form-control profile-page__input${!isEmpty(vimeoProfileError) ? ' is-invalid' : ''}`}
              placeholder="Vimeo"
              value={isEmpty(editedVimeoProfile) ? '' : editedVimeoProfile}
              onChange={this.handleChange}
            />
          </div>
          {!isEmpty(vimeoProfileError)
            ? (
              <div className="col-12 text-center">
                <span className="d-block text-danger">{vimeoProfileError}</span>
              </div>
            )
            : null}
        </div>
        <div className="col-12 col-md-10 profile-top__profile-desc mt-1 mb-3">
          <input
            placeholder="Tu web personal"
            type="text"
            name="editedWebsite"
            value={isEmpty(editedWebsite) ? '' : editedWebsite}
            className="form-control profile-page__input"
            onChange={this.handleChange}
          />
          <ReactQuill
            id="description"
            value={isEmpty(editedDescription) ? '' : editedDescription}
            // onChange={handleTextChange}
            placeholder="Escribe una breve introducción sobre ti"
            onChange={this.handleChange}
            // onChange={()=>handleChange(description, image.id)}
            className="tour-config-main__rich-text-editor form-control-ReactQuill"
            modules={MODULES}
            formats={FORMATS}
          />
        </div>
        <div className="col-12 col-md-10 profile-page__affiliate-plans mt-1 mb-3">
          <span>Tus planes de afiliados:</span>
          <p>Escribe solo tu id de afiliado de estas plataformas y ganarás todas las comisiones que generen las reservas de tus recomendaciones:</p>
          <div className="affiliate-plans-input mt-3">
            <input
              placeholder="Booking.com"
              type="text"
              name="editedBookingId"
              value={isEmpty(editedBookingId) ? '' : editedBookingId}
              className="col-7 form-control profile-page__input"
              onChange={this.handleChange}
            />
            <a
              href={"https://www.booking.com/affiliate-program/v2/index.es.html"}
              className="col-3 ml-3 btn btn-primary tour-sponsor-box__link d-flex justify-content-center align-items-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              + info
            </a>
          </div>
          <div className="affiliate-plans-input mt-2">
            <input
              placeholder="Getyourguide.com"
              type="text"
              name="editedGetyourguideId"
              value={isEmpty(editedGetyourguideId) ? '' : editedGetyourguideId}
              className="col-7 form-control profile-page__input"
              onChange={this.handleChange}
            />
            <a
              href={"https://partner.getyourguide.com/es-es/signup-host"}
              className="col-3 ml-3 btn btn-primary tour-sponsor-box__link d-flex justify-content-center align-items-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              + info
            </a>
          </div>
        </div>
        <div className="col-12 col-md-10 profile-page__donation-box mt-1 mb-3">
          <span>Caja de donaciones:</span>
          <p>Exclusivos para usuarios TopTravelers, Asociaciones sin ánimo de lucro o usuarios Premium. Solo aparecerá en tu perfil si añades un enlace a tu plataforma de donaciones:</p>
          <input
            placeholder="Título"
            type="text"
            name="editedDonationBoxTitle"
            value={isEmpty(editedDonationBoxTitle) ? '' : editedDonationBoxTitle}
            className="mt-3 form-control profile-page__input"
            onChange={this.handleChange}
          />
          <input
            placeholder="Vídeo personal"
            type="text"
            name="editedDonationBoxVideo"
            value={isEmpty(editedDonationBoxVideo) ? '' : editedDonationBoxVideo}
            className="mt-2 form-control profile-page__input"
            onChange={this.handleChange}
          />
          <ReactQuill
            id="donationBoxText"
            value={isEmpty(editedDonationBoxText) ? '' : editedDonationBoxText}
            // onChange={handleTextChange}
            placeholder="Texto personal"
            onChange={this.handleChangeDonationText}
            // onChange={()=>handleChange(description, image.id)}
            className="mt-2 tour-config-main__rich-text-editor form-control-ReactQuill"
            modules={MODULES}
            formats={FORMATS}
          />
          <input
            placeholder="Enlace web a la plataforma de donaciones (paypal.me, patreon...)"
            type="text"
            name="editedDonationBoxLinkPlatform"
            value={isEmpty(editedDonationBoxLinkPlatform) ? '' : editedDonationBoxLinkPlatform}
            className="mt-2 form-control profile-page__input"
            onChange={this.handleChange}
          />
          <label
            htmlFor="duplicatedCheckbox"
            className="mt-2 custom-checkbox"
          >
            Mostrar botón permanente a la caja de donaciones en la ruta.
                    <input
              type="checkbox"
              id="duplicatedCheckbox"
              onChange={this.handleCheckboxChange}
              defaultChecked={editedDonationBoxShowButton}
            />
            <i />
          </label>
          <p className="profile-page__user-nickname-form">
            {`Alias único de usuario: ${nickname}`}
          </p>
        </div>
      </div>
    );
  }
}

ProfileForm.propTypes = {
  mappedErrors: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  user: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
      surname: PropTypes.string,
      description: PropTypes.string,
      website: PropTypes.string,
    }),
  }).isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  updateAvatar: PropTypes.func.isRequired,
};
ProfileForm.defaultProps = {
  mappedErrors: {},
};
export default ProfileForm;
