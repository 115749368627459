import { SPAIN_FLAG, UNITED_KINGDOM_FLAG } from '../helpers/svg-inline';

export const CATEGORY_OPTIONS = [
  {
    value: 1,
    label: 'Arquitectura e ingeniería',
  },
  {
    value: 2,
    label: 'Barrio',
  },
  {
    value: 3,
    label: 'Calle',
  },
  {
    value: 4,
    label: 'Ciudad',
  },
  {
    value: 5,
    label: 'Edificio Religioso',
  },
  {
    value: 6,
    label: 'Entorno Natural',
  },
  {
    value: 7,
    label: 'Monumento',
  },
  {
    value: 8,
    label: 'Museo',
  },
  {
    value: 9,
    label: 'Parque',
  },
  {
    value: 10,
    label: 'Playa',
  },
  {
    value: 11,
    label: 'Plaza',
  },
  {
    value: 12,
    label: 'Pueblo pintoresco',
  },
  {
    value: 13,
    label: 'Resto Arqueológico',
  },
  {
    value: 14,
    label: 'Rincón con Encanto',
  },
  {
    value: 15,
    label: 'Zona de buceo',
  },
  {
    value: 16,
    label: 'Vista Panorámica',
  },
];

export const SERVICE_CATEGORIES = [
  {
    value: 100,
    label: 'Alojamiento',
  },
  {
    value: 101,
    label: 'Restauración',
  },
  {
    value: 102,
    label: 'Actividades',
  },
  {
    value: 103,
    label: 'Alquiler de coches',
  },
  {
    value: 104,
    label: 'Compras',
  },
];

export const POI_LANGUAGES = [
  {
    value: 'en-en',
    // icon: 'GB',
    label: UNITED_KINGDOM_FLAG,
  },
  {
    value: 'es-es',
    // icon: 'ES',
    label: SPAIN_FLAG,
  },
];

export const TOUR_TYPES = [
  {
    value: 1,
    label: 'Ranking / Listado',
  },
  {
    value: 2,
    label: 'Itinerario',
  },
];

export const TOUR_STATES = [
  {
    value: 1,
    label: 'Borrador',
    selectable: true,
    name: 'edition',
    description: 'Sólo tú puedes visualizar la ruta mientras terminas de editarla',
  },
  {
    value: 2,
    label: 'Publicado',
    selectable: true,
    name: 'published',
    description: 'Has compartido tu ruta y estará disponible en “Descubre” después '
      + 'de su validación, pasando su estado a “Aprobado”',
  },
  {
    value: 3,
    label: 'Publicado',
    selectable: false,
    name: 'approved',
    description: 'Tu ruta ha sido validada y está disponible en la sección “Descubre” '
      + 'para toda la comunidad de usuarios',
  },
  {
    value: 4,
    label: 'Oculto',
    selectable: true,
    name: 'hidden',
    description: 'Tu ruta sólo será visible por aquellos que conozcan su dirección web',
  },
];

export default {
  CATEGORY_OPTIONS,
  SERVICE_CATEGORIES,
  POI_LANGUAGES,
  TOUR_TYPES,
  TOUR_STATES,
};
