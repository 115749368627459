import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { toastr } from 'react-redux-toastr';
import _cloneDeep from 'lodash.clonedeep';
import { push } from 'connected-react-router';
import { isEmpty } from '../../utils';
import PoiInfoPageTemplate from './poi-info-page-template';
import PoiPositionModal from './poi-position-modal';
import reduxBeeMakeRequest from '../../utils/redux-bee/redux-bee-make-request';
import TYPES from '../../constants/types';
import { ModalPublishCongrats, ModalPublishConfirm } from '../../components';
import { PLACE_PAGE_DINAMIC_URL } from '../../constants';

const formattedDuration = (duration) => {
  if (isEmpty(duration)) {
    return { hour: null, min: null };
  }
  // Get hours from milliseconds
  const hours = duration / (1000 * 60 * 60);
  const absoluteHours = Math.floor(hours);

  // Get remainder from hours and convert to minutes
  const minutes = (hours - absoluteHours) * 60;
  const min = Math.round(minutes) > 9 ? Math.round(minutes) : `0${Math.round(minutes)}`;
  return { hour: absoluteHours, min };
};

function computeDuration(hour, min) {
  let duration = !isEmpty(hour) ? hour * 3600000 : null;
  duration += !isEmpty(min) ? min * 60000 : null;
  return duration;
}

function checkInfoRequirements(poi) {
  const {
    attributes: {
      title, category, description, lat, long, language,
    } = {},
  } = poi || {};

  return !isEmpty(title)
    && !isEmpty(category)
    && !isEmpty(description)
    && !isEmpty(lat)
    && !isEmpty(long)
    && !isEmpty(language);
}

const PoiInfoPage = (props) => {
  const {
    poi = null, poiId, dispatch, poiImages,
  } = props;
  const { attributes: poiAttributes = null } = poi || {};
  const { 'url-title': urlTitle } = poiAttributes || {};
  const [localPoi, setLocalPoi] = useState(_cloneDeep(poi));
  const [localDuration, setLocalDuration] = useState(formattedDuration(poiAttributes['poi-duration']));
  const [isOpenPositionModal, setIsOpenPositionModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [hasRequirements, setHasRequirements] = useState(false);
  const [hasPublishRequirements, setHasPublishRequirements] = useState(false);
  const [showPublishConfirmModal, setShowPublishConfirmModal] = useState(false);
  const [showPublishCongratsModal, setShowPublishCongratsModal] = useState(false);

  const poiPageRoute = PLACE_PAGE_DINAMIC_URL(urlTitle);

  useEffect(() => {
    if (poiId !== 'new' && !isEmpty(poiId)) {
      reduxBeeMakeRequest(dispatch, 'getPoi', { id: poiId }, {})
        .then((poiModel) => {
          setLocalPoi(poiModel);
          setLocalDuration(formattedDuration(poiModel.attributes['poi-duration']));
        }).catch((reason) => {
          setLocalPoi(() => {
            throw new Error(reason);
          });
        });
    }
    return () => {
      const abortController = new AbortController();
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { attributes: { 'poi-duration': poiDuration } = {} } = localPoi;
    const poiModelHasRequirements = checkInfoRequirements(localPoi);
    const newHasPublishRequirements = !isEmpty(poiImages) && poiModelHasRequirements;
    setLocalDuration(formattedDuration(poiDuration));
    setHasRequirements(poiModelHasRequirements);
    setHasPublishRequirements(newHasPublishRequirements);
  }, [localPoi, poiImages]);

  const handleModal = (value) => {
    setIsOpenPositionModal(value);
  };

  const handleChange = useCallback(
    (e) => {
      if (e.target) {
        const { name, value, type } = e.target;
        const clonedPoi = _cloneDeep(localPoi);

        if (false) {
          const clonedDuration = { ...localDuration };
          clonedDuration[name] = parseFloat(value);
          setLocalDuration(clonedDuration);
          formattedDuration(computeDuration(clonedDuration));
        } else {
          clonedPoi.attributes[name] = type === 'number'
            ? parseFloat(value)
            : value;
          setLocalPoi(clonedPoi);
        }
      } else {
        const richText = e === '<p><br></p>' ? '' : e;
        const clonedPoi = _cloneDeep(localPoi);
        clonedPoi.attributes["description"] = richText;
        setLocalPoi(clonedPoi);
      }
    },
    [localDuration, localPoi],
  );

  const handleSelect = useCallback(({ name, selectedOption }) => {
    const clonedPoi = _cloneDeep(localPoi);
    clonedPoi.attributes[name] = selectedOption.value;
    setLocalPoi(clonedPoi);
  }, [localPoi]);

  const handleRange = useCallback((assessmentValue) => {
    const clonedPoi = _cloneDeep(localPoi);
    clonedPoi.attributes.assessment = assessmentValue;
    setLocalPoi(clonedPoi);
  }, [localPoi]);

  const savePosition = useCallback(({ lat, lng }) => {
    const clonedPoi = _cloneDeep(localPoi);
    clonedPoi.attributes.lat = lat;
    clonedPoi.attributes.long = lng;
    setLocalPoi(clonedPoi);
    setIsOpenPositionModal(false);
  }, [localPoi]);

  const handleSave = useCallback((poiState, transitionRoute) => {
    const { attributes: { state: currentPoiState = null, 'poi-type': poiType } = {} } = localPoi || {};
    setErrors([]);

    if (props.auth.currentUser.id == 14 || props.auth.currentUser.id == 40 || props.auth.currentUser.id == 22) {
      console.log("Admin")
    } else {
      if ((currentPoiState !== 1 && poiState !== 1) || currentPoiState === 3) {
        if (!isEmpty(transitionRoute)) {
          dispatch(push(transitionRoute));
          return Promise.resolve();
        }
        toastr.info(
          'Edición deshabilitada',
          'No puedes editar un destino que ya has publicado.',
        );
        return Promise.resolve();
      }
    }


    const savePromise = async () => {
      localPoi.attributes = {
        ...localPoi.attributes,
        /* 'poi-duration': computeDuration(localDuration), */
        ...(!isEmpty(poiState) && { state: poiState }),
      };
      localPoi.attributes.website = localPoi.attributes.website && localPoi.attributes.website.replace("http://", "")
      localPoi.attributes.website = localPoi.attributes.website && localPoi.attributes.website.replace("https://", "")
      localPoi.attributes["twitter-url"] = localPoi.attributes["twitter-url"] && localPoi.attributes["twitter-url"].replace("http://", "")
      localPoi.attributes["twitter-url"] = localPoi.attributes["twitter-url"] && localPoi.attributes["twitter-url"].replace("https://", "")
      localPoi.attributes["facebook-url"] = localPoi.attributes["facebook-url"] && localPoi.attributes["facebook-url"].replace("http://", "")
      localPoi.attributes["facebook-url"] = localPoi.attributes["facebook-url"] && localPoi.attributes["facebook-url"].replace("https://", "")
      localPoi.attributes["instagram-url"] = localPoi.attributes["instagram-url"] && localPoi.attributes["instagram-url"].replace("http://", "")
      localPoi.attributes["instagram-url"] = localPoi.attributes["instagram-url"] && localPoi.attributes["instagram-url"].replace("https://", "")
      reduxBeeMakeRequest(dispatch, 'updatePoi', { id: poiId }, localPoi)
        .then((updatedPoi) => {
          const modelName = poiType === 1 ? 'ficha destino' : 'servicio';
          const sucessMsg = poiState === 2
            ? `¡Felicidades! Has públicado tu ${modelName} con éxito, ahora deberá pasar un proceso de revisión`
            : 'Los cambios se guardaron con éxito';
          toastr.success(sucessMsg);
          setLocalPoi(updatedPoi);
        })
        .catch((reason) => {
          const { errors: responseErrors } = reason && reason.body;
          if (reason.status === 422) {
            // this.setState({ saveErrors: errors });
            setErrors(responseErrors);
          }
          if (!isEmpty(errors)) {
            errors.forEach((error) => {
              const errorMsg = error.title || 'Lo sentimos, ocurrió un error inesperado';
              toastr.error(errorMsg);
            });
          }
        });
    };

    return savePromise().then(() => {
      if (!isEmpty(transitionRoute)) {
        dispatch(push(transitionRoute));
      }
    });
  }, [localPoi, localDuration, dispatch, poiId, errors]);

  const handleShowPublishModal = useCallback((value) => {
    setShowPublishConfirmModal(value);
    return Promise.resolve();
  }, []);

  const handleModalPublishCongrats = useCallback((value) => {
    setShowPublishCongratsModal(value);
  }, []);

  return (
    <>
      <PoiInfoPageTemplate
        poi={localPoi}
        errors={errors}
        hour={localDuration.hour}
        min={localDuration.min}
        poiId={poiId}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleRange={handleRange}
        handleSave={handleSave}
        openModal={() => handleModal(true)}
        hasRequirements={hasRequirements}
        hasPublishRequirements={hasPublishRequirements}
        handleShowPublishModal={handleShowPublishModal}
        computeDuration={computeDuration}
        formattedDuration={formattedDuration}
      />
      {isOpenPositionModal ? (
        <PoiPositionModal
          isOpen={isOpenPositionModal}
          closeModal={() => handleModal(false)}
          savePoiPosition={savePosition}
          latitude={poiAttributes.lat}
          longitude={poiAttributes.long}
        />
      ) : null}

      {showPublishConfirmModal ? (
        <ModalPublishConfirm
          isOpen={showPublishConfirmModal}
          handleSave={handleSave}
          closeModal={() => handleShowPublishModal(false)}
          poi={poi}
          handleModalPublishCongrats={handleModalPublishCongrats}
        />
      ) : null}

      {showPublishCongratsModal ? (
        <ModalPublishCongrats
          isOpen={showPublishCongratsModal}
          closeModal={() => handleModalPublishCongrats(false)}
          poiPageRoute={poiPageRoute}
        />
      ) : null}
    </>
  );
};

function mapStateToProps(state, ownProps) {
  const auth = state.auth;
  const poiId = ownProps.match.params.poi_id;
  const { pois: storedPois, images: storedImages } = state.bees.entities;
  let poi = null;
  let poiImages = null;

  if (poiId !== 'new' && storedPois) {
    poi = state.bees.entities.pois[poiId];
  }

  if (!isEmpty(poi)) {
    const { relationships: { images: { data: imagesData = null } = {} } = {} } = poi || {};
    poiImages = isEmpty(imagesData) || isEmpty(storedImages)
      ? null
      : imagesData.map(imageData => storedImages[imageData.id]);
  }

  return {
    poiId,
    poi,
    poiImages,
    auth,
  };
}

PoiInfoPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  poiId: PropTypes.string.isRequired,
  poi: TYPES.poiType,
  poiImages: PropTypes.arrayOf(TYPES.imageType),
};

PoiInfoPage.defaultProps = {
  poiImages: [],
  poi: {
    type: 'pois',
    attributes: {
      language: 'es-es',
      state: 1,
    },
  },
};

export default connect(mapStateToProps)(PoiInfoPage);
