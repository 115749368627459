import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FaqsPageTemplate from './faqs-page-template';
import { history } from '../../helpers';
import { isEmpty } from '../../utils';

const FaqsPage = (props) => {
  const {
    urlQueryParams, topic = 1, question = 1, isMobile,
  } = props;
  // useEffect(() => {
  //   if (isEmpty(topic)) {
  //     urlQueryParams.set('t', 0);
  //     history.push({
  //       search: `?${urlQueryParams.toString()}`,
  //     });
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (!isMobile) {
      urlQueryParams.set('q', 0);
      history.push({
        search: `?${urlQueryParams.toString()}`,
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  const handleVisibleQuestion = useCallback(
    (idx) => {
      urlQueryParams.set('q', idx);
      history.push({
        search: `?${urlQueryParams.toString()}`,
      });
    },
    [urlQueryParams],
  );

  const handleGoBack = useCallback(
    () => {
      const currentTopic = urlQueryParams.get('t');
      const currentQuestion = urlQueryParams.get('q');
      if (!isEmpty(currentQuestion)) {
        urlQueryParams.delete('q');
      } else if (!isEmpty(currentTopic)) {
        urlQueryParams.delete('t');
      }
      history.push({
        search: `?${urlQueryParams.toString()}`,
      });
    },
    [urlQueryParams],
  );

  return (
    <FaqsPageTemplate
      topic={topic}
      question={question}
      handleVisibleQuestion={handleVisibleQuestion}
      handleGoBack={handleGoBack}
    />
  );
};

FaqsPage.propTypes = {
  urlQueryParams: PropTypes.objectOf(PropTypes.object),
  topic: PropTypes.number,
  question: PropTypes.number,
  isMobile: PropTypes.bool,
};

FaqsPage.defaultProps = {
  urlQueryParams: null,
  topic: null,
  question: null,
  isMobile: false,
};

function mapStateToProps(state) {
  const urlQueryParams = new URLSearchParams(state.router.location.search);
  const topic = urlQueryParams.get('t');
  const question = urlQueryParams.get('q');

  return {
    urlQueryParams,
    topic: isEmpty(topic) ? null : parseInt(topic, 10),
    question: isEmpty(question) ? null : parseInt(question, 10),
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(FaqsPage);
