import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TourContentImagesFormTemplate from './tour-content-images-form-template';
import reduxBeeMakeRequest from '../../../../../../utils/redux-bee/redux-bee-make-request';
import TYPES from '../../../../../../constants/types';
import { dispatch } from '../../../../../../reduxStore';
import { isEmptyObject } from '../../../../../../utils';

const TourContentImagesForm = (props) => {
  const {
    localTourContent,
    handleOnImageUpload,
    handleToggleVisibility,
    handleDelete,
    tourContentImages,
  } = props;
  const { id: localTourContentId } = localTourContent;

  const [hideExtraBtns, setHideExtraBtns] = useState(false);

  const uploaderParams = {
    imageableId: localTourContentId,
    imageableType: 'tour-content',
    imageableProperty: 'archives',
  };

  const handleDeleteImage = () =>
    reduxBeeMakeRequest(dispatch, 'getTourContent', { id: localTourContentId }, {});

  const onImageInEditionChange = isEditingImage =>
    setHideExtraBtns(isEditingImage || isEmptyObject(localTourContentId));


  return (
    <TourContentImagesFormTemplate
      localTourContent={localTourContent}
      tourContentImages={tourContentImages}
      uploaderParams={uploaderParams}
      onImageUpload={handleOnImageUpload}
      handleToggleVisibility={handleToggleVisibility}
      handleDelete={handleDelete}
      handleOnDeleteImage={handleDeleteImage}
      onImageInEditionChange={onImageInEditionChange}
      hideExtraBtns={hideExtraBtns}
    />
  );
};

TourContentImagesForm.propTypes = {
  localTourContent: TYPES.tourContentType.isRequired,
  tourContentImages: PropTypes.arrayOf(TYPES.imageType),
  handleOnImageUpload: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

TourContentImagesForm.defaultProps = {
  tourContentImages: [],
};

function mapStateToProps(state, ownProps) {
  const { localTourContent } = ownProps;
  const { id: localTourContentId } = localTourContent;
  const beesTourContent = state.bees.entities.tourContents[localTourContentId];

  const { relationships: { archives: { data: imagesData = null } = {} } = {} } = beesTourContent || {};
  const imageIds = isEmptyObject(imagesData) ? [] : imagesData.map(imageData => imageData.id);
  const tourContentImages = imageIds
    .map(archiveId => state.bees.entities.images[archiveId])
    .sort((a, b) => a.attributes.order - b.attributes.order);

  return {
    tourContentImages,
  };
}

export default connect(mapStateToProps)(TourContentImagesForm);
