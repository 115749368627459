import {
  CUSTOM_IMG_ICON_URL,
  TEXT_ICON_URL,
  VIDEO_ICON_URL,
  MARKER_BASE_CIRCLE_ICON_URL,
  CLOCK_ICON_URL,
  SERVICE_ICON_URL,
} from '../helpers/svg-inline';

const getTourContentThumbImg = (contentType, image) => {
  switch (contentType) {
    // Place or Service types
    case 1:
      return (image && image.attributes && image.attributes['tiny-thumb']) || CUSTOM_IMG_ICON_URL;
    case 2:
      return (image && image.attributes && image.attributes['tiny-thumb']) || SERVICE_ICON_URL;
    // Image type
    case 3:
      return (image && image.attributes && image.attributes['tiny-thumb']) || CUSTOM_IMG_ICON_URL;
    // Text type
    case 4:
      return TEXT_ICON_URL;
    /* Video type */
    case 5:
      return VIDEO_ICON_URL;
    /* Map info type */
    case 6:
      return MARKER_BASE_CIRCLE_ICON_URL;
    /* Time type */
    case 7:
      return CLOCK_ICON_URL;
    default:
      throw new Error('content type not in range!');
  }
};

export default getTourContentThumbImg;
