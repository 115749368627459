import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash.clonedeep';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import TourContentMapFormTemplate from './tour-content-map-form-template';
import TYPES from '../../../../../../constants/types';

const TourContentMapForm = (props) => {
  const {
    localTourContent,
    setLocalTourContent,
    handleToggleVisibility,
    handleDelete,
  } = props;

  const [isVisibleMapLocalizator, setIsVisibleMapLocalizator] = useState(false);

  const showMapLocalizator = (shouldShow) => {
    setIsVisibleMapLocalizator(shouldShow);
  };

  const saveMarkerPosition = (markerCoord) => {
    const immutableTourContent = _cloneDeep(localTourContent);
    const { lat, lng: long } = markerCoord;
    immutableTourContent.attributes = { ...immutableTourContent.attributes, lat, long };
    setIsVisibleMapLocalizator(false);
    setLocalTourContent(immutableTourContent);
  };

  const handleInput = (e) => {
    const immutableTourContent = _cloneDeep(localTourContent);
    const {
      target: { value: newValue, name: attributeIdentifier },
    } = e;
    immutableTourContent.attributes[attributeIdentifier] = newValue;
    setLocalTourContent(immutableTourContent);
  };

  const handleTextChange = (value) => {
    // the empty editor value is '<p><br></p>'
    // before setting the state, check if editor is empty and convert it to empty string if it is
    const richText = value === '<p><br></p>' ? '' : value;

    const immutableTourContent = _cloneDeep(localTourContent);
    immutableTourContent.attributes.text = richText;
    setLocalTourContent(immutableTourContent);
  };

  const [address, setAddress] = useState('');
  const [mapInstance, setMapInstance] = useState(null);

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleAddressSelect = (selectedAddress) => {
    let results = null;
    geocodeByAddress(selectedAddress)
      .then((selectionResults) => {
        results = selectionResults;
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setAddress(results[0].formatted_address);
        mapInstance.panTo(latLng);
      })
      .catch(error => console.error('Error', error));
  };

  const onMapComponentDidMount = (mountedMapInstance) => {
    setMapInstance(mountedMapInstance);
  };

  return (
    <TourContentMapFormTemplate
      localTourContent={localTourContent}
      handleInput={handleInput}
      handleTextChange={handleTextChange}
      handleToggleVisibility={handleToggleVisibility}
      handleDelete={handleDelete}
      isVisibleMapLocalizator={isVisibleMapLocalizator}
      showMapLocalizator={showMapLocalizator}
      saveMarkerPosition={saveMarkerPosition}
      address={address}
      handleAddressChange={handleAddressChange}
      handleAddressSelect={handleAddressSelect}
      onMapComponentDidMount={onMapComponentDidMount}
    />
  );
};

TourContentMapForm.propTypes = {
  localTourContent: TYPES.tourContentType.isRequired,
  setLocalTourContent: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default TourContentMapForm;
