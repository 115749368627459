import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../../utils';
import {
  CIRCLE_CROSS_ICON
} from '../../../../../helpers/svg-inline';

export default class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
    this._isMouseOverDropdown = false;
    this.handleClick = this.handleClick.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  }

  closeDropdown(e) {
    e.preventDefault();
    this.updateMouseState(false);
    setTimeout(() => {
      if (!this._isMouseOverDropdown) {
        this.setState({ isExpanded: false });
      }
    }, 150);
  }

  updateMouseState(value) {
    this._isMouseOverDropdown = value;
  }

  render() {
    const {
      toggle, containerClass, toggleClass, children, disableCondition, align, menuClass,
    } = this.props;

    const { isExpanded } = this.state;
    return (
      <div
        className={`dropdown ${containerClass} ${isExpanded ? 'show' : ''}`}
        onMouseLeave={this.closeDropdown}
        onMouseEnter={() => this.updateMouseState(true)}
      >
        <button
          className={`${toggleClass}`}
          type="button"
          aria-expanded={isExpanded}
          onClick={this.handleClick}
          disabled={disableCondition}
        >
          {toggle}
          <img
            alt='Menu button'
            src={'../assets/images/open-menu.svg'}
          />
        </button>
        <div className="test d-flex justify-content-center">
          <div
            onMouseEnter={() => this.updateMouseState(true)}
            className={`dropdown-menu ${!isEmpty(align) ? `dropdown-menu-${align}` : ''} ${
              isExpanded ? 'show' : ''
              } ${menuClass}`}
          >
            <button
              type="button"
              className="closeMenu"
              onClick={this.handleClick}
              title="Cerrar"
            >
              {CIRCLE_CROSS_ICON}
            </button>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

DropDown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.symbol]).isRequired,
  toggle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  containerClass: PropTypes.string,
  toggleClass: PropTypes.string,
  menuClass: PropTypes.string,
  disableCondition: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right']),
};

DropDown.defaultProps = {
  containerClass: '',
  toggleClass: '',
  menuClass: '',
  disableCondition: false,
  align: null,
};
