import React from 'react';
import PropTypes from 'prop-types';
import AutosuggestWrapper from './autosuggest-wrapper';
import { CLOSE_ICON, history } from '../../helpers';
import { isEmpty } from '../../utils';
import { connect } from 'react-redux';

class AutocompleteTag extends React.Component {
  constructor(props) {
    super(props);
    const { suggestionsArray, prevSuggestions } = props;
    this.state = {
      suggestionsArray,
      prevSuggestions,
    };

    this.getSelectedValue = this.getSelectedValue.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onDeleteTag = this.onDeleteTag.bind(this);
  }
  componentDidMount() {
    const { urlQueryParams } = this.props;
    if (urlQueryParams.get("tTags")) {
      urlQueryParams.delete("tTags");
      history.push({
        search: `?${urlQueryParams.toString()}`,
      });
    }
  }

  onKeyUp(e) {
    const { onKeyUp } = this.props;
    const suggestion = { displayedText: e.target.value.trim() };
    if (onKeyUp) {
      onKeyUp(e, suggestion);
    } else if (!onKeyUp) {
      // eslint screw this up if not explictly declaring else if(!onKeyUp)
      if (e.key === ' ') {
        const { tagList } = this.state;
        const newState = { tagList: [...tagList, suggestion] };
        this.setState(newState);
      }
    }
  }

  onDeleteTag(tag, index) {
    const { suggestionSource, onDeleteTag } = this.props;
    if (suggestionSource === 'props') {
      if (onDeleteTag) onDeleteTag(tag, index);
    } else {
      const { prevSuggestions } = this.state;
      const newCurrSuggestions = [...prevSuggestions];
      newCurrSuggestions.splice(index, 1);
      this.setState({ prevSuggestions: newCurrSuggestions });
    }
  }

  getSelectedValue(e, { suggestion, method }) {
    const { onValueSelected } = this.props;
    if (onValueSelected) {
      onValueSelected(e, { suggestion, method });
    } else {
      const { tagList, prevSuggestions } = this.state;
      this.setState({
        tagList: [...tagList, suggestion],
        prevSuggestions: [...prevSuggestions, suggestion.displayedText],
      });
    }
  }

  render() {
    const { getSelectedValue, onKeyUp, onDeleteTag } = this;

    const {
      uniqueIdentificator,
      placeholder,
      multiSection,
      showInput,
      defaultInputValue,
      label,
      suggestionSource,
      canDelete,
      defaultContent,
    } = this.props;

    // loads suggestions/previous tas from props or state based on suggestionSource
    let listSource = this.state;
    if (suggestionSource === 'props') {
      const { prevSuggestions, suggestionsArray } = this.props;
      listSource = {
        suggestionsArray,
        prevSuggestions,
      };
    }
    const { suggestionsArray, prevSuggestions } = listSource;
    return (
      <div className="autocomplete-tag">
        <div className="autocomplete-tag__selected-tags">
          {isEmpty(prevSuggestions) ? defaultContent : prevSuggestions.map((tag, index) => {
            const tagText = `#${tag.displayedText}`.trim();
            return (
              <span className="autocomplete-tag__tag" key={`tag-${tag.displayedText}`}>
                {canDelete ? (
                  <button
                    className="autocomplete-tag__delete-btn"
                    type="button"
                    onClick={() => onDeleteTag(tag, index)}
                    title="Eliminar"
                  >
                    {CLOSE_ICON}
                  </button>
                ) : null}

                {tagText}
              </span>
            );
          })}
        </div>
        {showInput ? (
          <AutosuggestWrapper
            onSuggestionSelected={getSelectedValue}
            onSelectCleanInput
            uniqueIdentificator={uniqueIdentificator}
            placeholder={placeholder}
            suggestionsArray={suggestionsArray}
            multiSection={multiSection}
            defaultInputValue={defaultInputValue}
            minInputLengthToShow={2}
            label={label}
            onKeyUp={onKeyUp}
            keysCleaningInput={[' ']}
            uniqueSuggestions
            prevSuggestions={prevSuggestions.map(sugg => sugg.displayedText)}
          />
        ) : null}
      </div>
    );
  }
}
AutocompleteTag.propTypes = {
  uniqueIdentificator: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  suggestionsArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  prevSuggestions: PropTypes.arrayOf(PropTypes.object),
  canDelete: PropTypes.bool,
  multiSection: PropTypes.bool,
  showInput: PropTypes.bool,
  defaultInputValue: PropTypes.string,
  suggestionSource: PropTypes.string,
  label: PropTypes.string,
  onKeyUp: PropTypes.func,
  onValueSelected: PropTypes.func,
  onDeleteTag: PropTypes.func,
  defaultContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
AutocompleteTag.defaultProps = {
  canDelete: false,
  multiSection: false,
  showInput: true,
  defaultInputValue: '',
  label: '',
  prevSuggestions: [{}],
  onKeyUp: null,
  onValueSelected: null,
  onDeleteTag: null,
  suggestionSource: 'state',
  defaultContent: null,
};
function mapStateToProps(state) {
  const urlQueryParams = new URLSearchParams(state.router.location.search);
  const savedValidatedContentFilter = urlQueryParams.get('includeNoVal');
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    urlQueryParams,
  };
}
export default connect(mapStateToProps)(AutocompleteTag);
