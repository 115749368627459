import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import handleError from '../../utils/errorHandler';
import AutocompleteTag from './autocomplete-tag';

import api from '../../config/api-endpoints';

class TagAjaxManager extends Component {
  static jsonApiTagToSuggestion(jsonApiTag) {
    const { name } = jsonApiTag.attributes;
    return {
      displayedText: name,
      ...jsonApiTag,
    };
  }

  static jsonApiTagsToSuggestion(jsonApiTagsArray) {
    return jsonApiTagsArray.map(jsonApiTag => TagAjaxManager.jsonApiTagToSuggestion(jsonApiTag));
  }

  static suggestionToTagJsonApi(suggestion) {
    return {
      type: 'tags',
      attributes: {
        name: suggestion.displayedText,
      },
    };
  }

  /*
  static handleError(error) {
    if (error.body && error.body.errors[0] && error.body.errors[0].detail) {
      toastr.error('Error', error.body.errors[0].detail);
    } else if (error.body && !Array.isArray(error.body.errors)) {
      toastr.error('Error', 'Error de servidor inesperado');
    } else {
      toastr.error('Error', error);
    }
  }
*/
  constructor(props) {
    super(props);
    this.state = {
      suggestionsArray: [],
      currentTags: [],
    };
    this.getTags = this.getTags.bind(this);
    this.onValueSelected = this.onValueSelected.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onDeleteTag = this.onDeleteTag.bind(this);
    this.createNewTag = this.createNewTag.bind(this);
  }

  componentDidMount() {
    const { getCurrentResourceTags, getSuggestions } = this.props;
    let suggestionsArray = null;
    this.getTags()
      .then((suggestions) => {
        if (suggestions) {
          const formattedSuggestions = TagAjaxManager.jsonApiTagsToSuggestion(suggestions.body.data);
          suggestionsArray = formattedSuggestions;
          if (getSuggestions) getSuggestions(suggestionsArray);
        }
      })
      .then(() => {
        if (suggestionsArray) return getCurrentResourceTags();
        return null;
      })
      .then((tagsJson) => {
        if (tagsJson) {
          this.setState({
            suggestionsArray,
            currentTags: TagAjaxManager.jsonApiTagsToSuggestion(tagsJson.body.data),
          });
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  // suggestion will be jsonApi because of this manager component flow
  // onMount the resource is taken from the server
  // before every "addition" the tag gets upserted to the server and converted with jsonApiTagToSuggestion
  // suggestion will always be a json:api object with displayedText as a first-level propriety
  onValueSelected(e, { suggestion }) {
    const { associateTagToResource } = this.props;

    associateTagToResource({ data: [suggestion] })
      .then((response) => {
        if (response) {
          const suggestionFromServer = TagAjaxManager.jsonApiTagsToSuggestion(response.body.data);
          this.setState({ currentTags: [...suggestionFromServer] });
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  // suggestion will be jsonApi because of this manager component flow
  // onMount the resource is taken from the server
  // before every "addition" the tag gets upserted to the server and converted with jsonApiTagToSuggestion
  // suggestion will always be a json:api object with displayedText as a first-level propriety
  onKeyUp(e, suggestion) {
    if (e.key === ' ') {
      const { currentTags } = this.state;
      let alreadyPresent = false;
      currentTags.forEach((tag) => {
        if (tag.displayedText === suggestion.displayedText) alreadyPresent = true;
      });

      if (!alreadyPresent) {
        const { associateTagToResource } = this.props;
        let tagToUpsert;
        this.createNewTag(suggestion)
          .then((upsertedTag) => {
            if (upsertedTag) {
              tagToUpsert = upsertedTag.body.data;
              return associateTagToResource({ data: [tagToUpsert] });
            }
            return null;
          })
          .then(() => {
            if (tagToUpsert) {
              const suggestionFromServer = TagAjaxManager.jsonApiTagToSuggestion(tagToUpsert);
              this.setState({ currentTags: [...currentTags, suggestionFromServer] });
            }
          })
          .catch((error) => {
            handleError(error);
          });
      }
    }
  }

  onDeleteTag(tag, index) {
    const { removeTagFromResource } = this.props;
    const requestJsonApi = { data: tag };

    return removeTagFromResource(requestJsonApi)
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          const { currentTags } = this.state;
          const newCurrTags = [...currentTags];
          newCurrTags.splice(index, 1);
          this.setState({ currentTags: newCurrTags });
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  getTags() {
    const { dispatch } = this.props;
    return dispatch(api.getTags()).catch(() => {
      handleError('No se han encontrado palabras clave...');
    });
  }

  createNewTag(jsonApiTag) {
    const { dispatch } = this.props;
    const newTagRequestBody = TagAjaxManager.suggestionToTagJsonApi(jsonApiTag);
    // newTagRequestBody.responseStatus = 500; // if test route, this fakes a failure
    return dispatch(api.createNewTag({ data: newTagRequestBody })).catch((error) => {
      handleError(error);
    });
  }

  render() {
    const { suggestionsArray, currentTags } = this.state;
    const { canDelete, showInput, defaultContent } = this.props;
    const { onValueSelected, onKeyUp, onDeleteTag } = this;

    return (
      <AutocompleteTag
        uniqueIdentificator="test12345"
        placeholder="Busca o crea las palabras clave"
        suggestionsArray={suggestionsArray}
        prevSuggestions={currentTags}
        // sending this to AutocompleteTag prevent it from updating it's local state
        onKeyUp={onKeyUp}
        // sending this to AutocompleteTag prevent it from updating it's local state
        onValueSelected={onValueSelected}
        // tells AutocompleteTag to read suggestions from props instead of local state
        suggestionSource="props"
        canDelete={canDelete}
        onDeleteTag={onDeleteTag}
        showInput={showInput}
        defaultContent={defaultContent}
      />
    );
  }
}

TagAjaxManager.propTypes = {
  getCurrentResourceTags: PropTypes.func.isRequired,
  associateTagToResource: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  canDelete: PropTypes.bool,
  showInput: PropTypes.bool,
  removeTagFromResource: PropTypes.func,
  getSuggestions: PropTypes.func,
  defaultContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
TagAjaxManager.defaultProps = {
  canDelete: false,
  showInput: true,
  removeTagFromResource: null,
  getSuggestions: null,
  defaultContent: null,
};

export default connect()(TagAjaxManager);
