import React, { Component } from 'react';
import TYPES from '../../../../../constants/types';
import SimpleMap from '../../../../simple-map';
import { isEmpty } from '../../../../../utils';
import { GOOGLE_MARKER } from '../../../../../utils/google-maps-utils';

class TourContentMapBox extends Component {
  constructor(props) {
    super(props);
    this._mapBoxRef = React.createRef();
    this._mapInstance = null;

    this.initializeMapBounds = this.initializeMapBounds.bind(this);
    this.handleMapMount = this.handleMapMount.bind(this);
  }

  handleMapMount(map) {
    this._mapInstance = map;
  }

  initializeMapBounds() {
    const { _mapInstance } = this;

    if (!isEmpty(_mapInstance)) {
      const { tourContent } = this.props;
      const { attributes: { lat = null, long: lng = null } = {} } = tourContent;
      const bounds = new window.google.maps.LatLngBounds();
      const mapMarker = new window.google.maps.Marker({
        position: { lat, lng },
        map: _mapInstance,
        icon: GOOGLE_MARKER(),
        clickable: false,
      });
      bounds.extend(mapMarker.getPosition());
      _mapInstance.fitBounds(bounds, 5);
      _mapInstance.setZoom(17);
    }
  }

  render() {
    const { tourContent } = this.props;
    const { attributes: { text = null, title = null } = {} } = tourContent;
    const hasText = !isEmpty(text) || !isEmpty(title);
    return (
      <article className={`container tour-content-map-box ${hasText ? '' : 'tour-content-map-box--squared'}`}>
        <div className="row">
          <div className="col-12 col-sm px-0">
            <SimpleMap
              mapElementId={`map-content-${tourContent.id}`}
              onMapLoad={this.handleMapMount}
              onIdle={this.initializeMapBounds}
            />
          </div>
          {hasText ? (
            <div className="col-12 col-sm px-3 pl-sm-3 pr-sm-0">
              {isEmpty(title) ? null : <h4 className="tour-content-map-box__title">{title}</h4>}
              {isEmpty(text) ? null : (
                <p className="tour-content-map-box__text" dangerouslySetInnerHTML={{ __html: text }} />
              )}
            </div>
          ) : null}
        </div>
      </article>
    );
  }
}

TourContentMapBox.propTypes = {
  tourContent: TYPES.tourContentType.isRequired,
};

export default TourContentMapBox;
