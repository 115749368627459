import React from 'react';
import { Helmet } from 'react-helmet';
import MainLayout from '../layouts/main-layout';
import { Footer } from '../components';

const PrivacyPage = () => (
  <MainLayout>
    <Helmet>
      <title>: Política de privacidad</title>
      <meta name="title" content="Topdesti: Política de privacidad" />
      <meta property="og:title" content="Topdesti: Política de privacidad" />
      <meta property="twitter:title" content="Topdesti: Política de privacidad" />
      <meta
        name="description"
        content="Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas"
      />
      <meta
        property="og:description"
        content="Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas"
      />
      <meta
        property="twitter:description"
        content="Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas"
      />
      <meta property="of:image" content={`${window.location.origin}/assets/images/topdesti-logo.png`} />
      <meta property="twitter:image" content={`${window.location.origin}/assets/images/topdesti-logo.png`} />
      <meta property="og:image:width" content="132" />
      <meta property="og:image:height" content="38" />
    </Helmet>
    <main className="container f-opensans">
      <div className="row">
        <div className="col-12">
          <h2 className="text-center mt-3">Política de Privacidad de Topdesti</h2>

          <h3>INFORMACIÓN BÁSICA</h3>
          <h4>Información básica sobre Protección de Datos con respecto a los usuarios</h4>
          <p>
            <strong>Responsable: </strong>
            Topdesti, S.L. (en adelante, &quot;Topdesti&quot;)
          </p>
          <p>
            <strong>Finalidad: </strong>
            Prestación del servicio contratado.
          </p>
          <p>
            <strong>Legitimación: </strong>
            Ejecución de un contrato en el que el interesado es parte, aplicación a petición de este de medidas precontractuales y/o consentimiento del interesado.
          </p>
          <p>
            <strong>Destinatarios: </strong>
            Entidades colaboradoras necesarias para la prestación del servicio, expositores y destinatarios que el usuario haya consentido explícitamente.
          </p>
          <p>
            <strong>Derechos: </strong>
            Derecho de información y de acceso a los datos personales, de rectificación y supresión, derecho a la limitación del tratamiento, derecho de oposición, al testamento digital y a no ser objeto de una elaboración de perfiles.
          </p>
          <p>
            <strong>Procedencia: </strong>
            Los propios interesados.
          </p>

          <h3>INFORMACIÓN ADICIONAL</h3>
          <h4>Información adicional sobre Protección de Datos</h4>
          <p><strong>¿Quién es el responsable del tratamiento de sus datos?</strong></p>
          <p>Topdesti, S.L.,  con CIF B-76327972 y domicilio en calle Calle Simancas, 49, 2 D, 35010, Las Palmas (España).</p>
          <p>Datos de contacto: contacto@topdesti.com.</p>

          <p><strong>¿Quién es el Delegado de Protección de Datos y cómo puede ponerse en contacto con él?</strong></p>
          <p>El Delegado de Protección de Datos es la persona encargada de supervisar el cumplimiento de lo dispuesto en el Reglamento General de Protección de Datos. El Delegado de Protección de Datos de Topdesti es la entidad jurídica privada EUROPEAN DATA PROTECTION OFFICERS, S.L., CIF: B88047220. Se podrá contactar con él en la siguiente dirección de correo electrónico: delegadopd@topdesti.com</p>

          <p><strong>¿Con qué finalidad tratamos sus datos y por cuánto tiempo los conservaremos?</strong></p>
          <p>Tratamos los datos personales que nos facilitan nuestros usuarios con el fin de prestar el servicio contratado cuyas condiciones puede consultar aquí y entre las finalidades se incluyen: Compartir automáticamente las fotos de tus viajes con tus amigos; contestar a sus preguntas y brindar informaciones en respuesta a su consulta; para finalidades de marketing y publicidad sobre servicios que le podrían interesar; personalizar la información proporcionada y su experiencia de usuario; entender cómo el usuarios accede y utiliza el servicio prestado; administrar, monitorear y mejorar el servicio prestado y para otros fines de búsqueda y análisis; proteger a los usuarios, investigando fraudes, acosos y otros tipos de actividades ilegales. Con estas finalidades, se podrán tomar decisiones basadas únicamente en el tratamiento automatizado de sus datos, incluida la elaboración de perfiles.</p>
          <p>Los datos brindados se conservarán al menos mientras se mantenga la relación comercial y, en el caso de que el tratamiento se base en el consentimiento del interesado, hasta que el interesado retire su consentimiento o, posteriormente, durante los plazos legalmente exigidos.</p>

          <p><strong>¿Cuál es la legitimación para el tratamiento de sus datos?</strong></p>
          <p>La legitimación principal es la necesidad del tratamiento para la ejecución de un contrato en el que el interesado es parte o para la aplicación a petición de este de medidas precontractuales y en algunos casos el consentimiento del usuario para el tratamiento de sus datos personales para uno o varios fines específicos, incluida la creación de perfiles.</p>
          <p>El tratamiento de los datos personales de un menor de edad únicamente podrá fundarse en su consentimiento cuando sea mayor de catorce años. En el caso de que sea menor de catorce años, el tratamiento fundado en el consentimiento sólo será lícito si consta el del titular de la patria potestad o tutela.</p>

          <p><strong>¿A qué destinatarios se comunicarán sus datos?</strong></p>
          <p>Topdesti únicamente cederá tus datos a terceros en el caso de que des tu consentimiento explícito para ello. En este sentido, mediante la acción expresa de subir tus fotos o contenidos a la Plataforma, consientes compartir tus fotos y datos personales con todos los usuarios de Topdesti.</p>
          <p>Los datos personales se comunicarán a terceros, en concreto a las entidades que colaboran para la prestación del servicio, en calidad de encargados de tratamiento.</p>
          <p>Al facilitarnos su información personal, nos autorizas expresamente a tratar y compartir su información con dichos colaboradores, en la medida que están directamente implicados la prestación del servicio contratado.</p>
          <p>Aseguramos que estas entidades colaboradoras con las que compartimos su información personal garanticen un nivel de protección adecuado, según lo dispuesto en el Reglamento General de Protección de Datos.</p>
          <p>Registrándose en Topdesti consientes y aceptas que se podrán transmitir tus datos personales fuera del territorio del Espacio Económico Europeo para la correcta prestación del servicio contratado. Estas transferencias se pueden hacer a países como Suiza o Estados Unidos con los cuales existe una decisión de adecuación adoptada por la Comisión Europea (Privacy Shield). En ausencia de una decisión de adecuación, solo se podrá transmitir datos personales a un tercer país u organización internacional si se hubiera ofrecido garantías adecuadas y a condición de que los interesados cuenten con derechos exigibles y acciones legales efectivas.</p>

          <p><strong>¿Cuáles son tus derechos cuando nos facilites tus datos?</strong></p>
          <p>
            <strong>Derecho de información: </strong>
            cuando el interesado nos facilite sus datos personales, en el momento o con anterioridad en que estos se obtengan, le facilitaremos toda la información pertinente, según lo dispuesto en el Reglamento General de Protección de Datos.
          </p>
          <p>
            <strong>Derecho de acceso: </strong>
            el interesado que nos facilita sus datos tendrá derecho a obtener confirmación de si se están tratando o no datos personales que le conciernen y, en tal caso, derecho de acceso a los datos y a la información pertinente.
          </p>
          <p>
            <strong>Derecho de rectificación: </strong>
            la persona interesada tendrá derecho a obtener sin dilación indebida la rectificación de los datos personales inexactos que le conciernen y derecho a que se completen los datos que sean incompletos, inclusive mediante una declaración adicional.
          </p>
          <p>
            <strong>Derecho de supresión: </strong>
            el interesado tendrá derecho a obtener sin dilación indebida la supresión de los datos personales que le conciernen cuando, entre otros motivos, los datos ya no sean necesarios en relación con los fines para los que fueron recogidos o tratados.
          </p>
          <p>
            <strong>Derecho a la limitación del tratamiento: </strong>
            en determinadas circunstancias, el interesado podrá solicitar la limitación del tratamiento de los datos personales que le conciernen, en cuyo caso únicamente los trataremos con el consentimiento del interesado o para la formulación, el ejercicio o la defensa de reclamaciones, o con miras a la protección de los derechos de otra persona física o jurídica o por razones de interés público importante.
          </p>
          <p>
            <strong>Derecho de oposición: </strong>
            la persona interesada podrá oponerse en cualquier momento, por motivos relacionados con su situación particular, al tratamiento de sus datos personales basado en lo dispuesto en el artículo 6, apartado 1, letras e) o f), incluida la elaboración de perfiles sobre la base de dichas disposiciones. Dejaremos de tratar dichos datos, salvo por motivo legítimos imperiosos que prevalezcan sobre los intereses, los derechos y las libertades del interesado, o para la formulación, el ejercicio o la defensa de reclamaciones.
          </p>
          <p>
            <strong>Derecho a no ser objeto de una elaboración de perfiles: </strong>
            el interesado tendrá derecho a no ser objeto de una  decisión basada únicamente en  el tratamiento automatizado, incluida la elaboración de perfiles. Cuando la decisión se base únicamente en el tratamiento automatizado, el interesado tendrá derecho a obtener intervención humana por parte del responsable, a expresar su punto de vista y a impugnar la decisión, si el tratamiento  es necesario para la celebración o la ejecución de un contrato entre el interesado y el responsable o se basa en el consentimiento explícito del interesado. No se aplicará tal derecho cuando la decisión automática se base en el consentimiento explícito del interesado, sea necesaria para la celebración o la ejecución de un contrato entre el responsable y el interesado o se base en un tratamiento fundamentado en la legislación vigente.
          </p>
          <p>
            <strong>Derecho al testamento digital: </strong>
            el acceso a contenidos digitales sobre personas fallecidas se regirá de la siguiente forma: las personas vinculadas al fallecido (familiares o de hecho) podrán acceder al contenido digital del fallecido e impartir instrucciones en relación con el mismo (utilización, destino o supresión). Dicha posibilidad se encontrará vetada, en el caso de que el fallecido haya indicado lo contrario expresamente. En caso de existir testamento, podrá realizar lo anterior el albacea testamentario o la persona que haya designado el fallecido. Cuando la persona fallecida sea menor de edad o persona con discapacidad, las facultades podrán ser ejercidas por sus representantes legales o por el Ministerio Fiscal.
          </p>
          <p>Cualesquiera de los derechos descritos con anterioridad deberán notificarse por escrito debidamente firmado a las siguientes direcciones de correo electrónico, acreditando su identidad: contacto@topdesti.com.</p>
          <p>Si como interesado considera que hay un problema con la forma en que estamos manejando sus datos, puede dirigir sus reclamaciones a nuestro Delegado de Protección de Datos (delegadopd@topdesti.com) o a la autoridad de control en España, es decir la Agencia Española de Protección de Datos.</p>
          <p><strong>¿Qué categorías de datos personales tratamos y cómo hemos obtenido sus datos?</strong></p>
          <p>Las categorías de datos que tratamos son las siguientes: datos de identificación, datos de contacto (teléfono, email), datos de imagen, metadatos de las fotografías, geolocalización y perfiles en redes sociales.</p>
          <p>Los datos personales que tratamos han sido facilitados por el interesado.</p>
        </div>
      </div>
    </main>
    <Footer />
  </MainLayout>
);

export default PrivacyPage;
