import React, {
  Component,
  useState,
} from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { push } from 'connected-react-router';
import Modal from 'react-modal';

import { isEmpty, isEmptyObject, getImageSrcSet } from '../../utils';
import { dispatch } from '../../reduxStore';
import { TOUR_INFO_DINAMIC_URL, TOUR_IMAGE_PAGE_DINAMIC_URL, PLACE_PAGE_DINAMIC_URL } from '../../constants';
import { Navbar, TourFile, LocationSearch, PromiseButton } from '../../components';
import { TOUR_SIGNAL_ICON, LINK_ICON, HEART_ICON, EYE_ICON, CIRCLE_CROSS_ICON } from '../../helpers';
import ENV from '../../config/environment';
import ModalOnlyUsers from '../../components/modal-log-out';
import $ from 'jquery';
import ModalPreviewContent from '../../components/modal-preview-content';
import axios from 'axios';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ResponsiveImg from '../../components/responsive-img';
import {
  USER_ICON,
} from '../../helpers/svg-inline';
import { toastr } from 'react-redux-toastr';
import moment from 'moment'
moment.locale('es')

//  const stripePromise = loadStripe('pk_test_76T4RyPv2cvEMCa5eEzVMuVP'); //topdesti test
const stripePromise = loadStripe('pk_live_SsA79XyZDFFaHRhvS9TgC8FY'); //topdesti live
// const stripePromise = loadStripe('pk_test_oxhgpKAz1y4GPsKKSOICdNoQ002QdHXSLl'); //afkar


class Sponsorplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poi: {},
      tours: [],
      avatar: "",
      isMobileSP: false,
      showModalLogOut: false,
      showModalPay: false,
      paymentConfirmed: false,
      flecha0: 'down-arrow',
      flecha1: 'down-arrow',
      flecha2: 'down-arrow',
      flecha3: 'down-arrow',
      flecha4: 'down-arrow',
      flecha5: 'down-arrow',
      showModalPreviewContent: false,
      infoTours: false,
      modalImagesSponsor: false,
      srcModalImagesSponsor: ''
    };
  }
  modalImagesSponsor = (img) => {
    this.setState({
      modalImagesSponsor: true,
      srcModalImagesSponsor: img
    })
  }
  modalPreviewContentOpen = () => {
    this.setState({ showModalPreviewContent: true })
  }
  modalPreviewContentClose = () => {
    this.setState({ showModalPreviewContent: false })
  }
  modalLogOutOpen = () => {
    this.setState({ showModalLogOut: true })
  }
  handlemodalLogOutClose = () => {
    this.setState({ showModalLogOut: false })
  }
  modalPay = (element, x) => {
    this.setState({ showModalPay: element });
    if (x) {
      this.setState({ infoTours: true })
    } else {
      this.setState({ infoTours: false })
    }
  }
  // payment = () => {
  //   this.setState({ paymentConfirmed: true })
  // }

  bindOnSave(handleHeaderSave) {
    this.setState({
      handleHeaderSave,
    });
  }

  componentDidMount() {
    let poi = window.location.pathname.replace('/sponsor-place/', '');
    fetch(`${ENV.host}/${ENV.namespace}/pois/sponsorPlace/${parseInt(poi)}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          poi: data.destino,
          avatar: data.avatar ? data.avatar.smallThumb : "../../../assets/images/default-avatar.png",
          tours: data.tourContent
        });
      });
  }


  isMobileSP = (isMobile) => {
    this.setState({ isMobileSP: isMobile })
  }
  openPanel = num => {
    $('.panel-' + num).slideToggle('fast', () => {
      if ($('.panel-' + num).css("display") == "block") {
        $('#flecha-' + num).css("transform", "rotate(180deg)");
      } else {
        $('#flecha-' + num).css("transform", "rotate(0deg)");
      }

    });

    // switch (num) {
    //   case 0:
    //     this.setState({
    //       flecha0: this.state.flecha0 == 'down-arrow' ? 'up-chevron' : 'down-arrow',
    //     });
    //     break;

    //   case 1:
    //     this.setState({
    //       flecha1: this.state.flecha1 == 'down-arrow' ? 'up-chevron' : 'down-arrow',
    //     });
    //     break;

    //   case 2:
    //     this.setState({
    //       flecha2: this.state.flecha2 == 'down-arrow' ? 'up-chevron' : 'down-arrow',
    //     });
    //     break;

    //   case 3:
    //     this.setState({
    //       flecha3: this.state.flecha3 == 'down-arrow' ? 'up-chevron' : 'down-arrow',
    //     });
    //     break;

    //   case 4:
    //     this.setState({
    //       flecha4: this.state.flecha4 == 'down-arrow' ? 'up-chevron' : 'down-arrow',
    //     });
    //     break;

    //   case 5:
    //     this.setState({
    //       flecha5: this.state.flecha5 == 'down-arrow' ? 'up-chevron' : 'down-arrow',
    //     });
    //     break;

    //   default:
    //     break;
    // }
  };

  confirmPay = () => {
    this.setState({ paymentConfirmed: true })
  }
  oneYear = () => {
    let oneYearFromNow = new Date();
    let one_year = oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    return moment(one_year).calendar();
  }
  render() {
    var currentPoi = null;
    var currentPoiImages = null;
    const {
      isAuthenticated
    } = this.props;

    let imagepoi =
      this.state.poi && this.state.poi.images && this.state.poi.images.length >= 1
        ? this.state.poi.images
        : [];

    imagepoi.sort((a, b) => {
      if (a.likes > b.likes) {
        return -1;
      }
      if (a.likes < b.likes) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
    if (this.state.poi !== {}) {
      currentPoi = this.state.poi;
      currentPoiImages = currentPoi.images;
      currentPoi["type-preview"] = currentPoi.typePreview;
      currentPoi = {
        id: currentPoi.id,
        attributes: currentPoi
      }
      if (currentPoiImages && currentPoiImages.length >= 1) {
        currentPoiImages.map(img => {
          img["imageable-type"] = img.imageableType;
          img["imageable-id"] = img.imageableId;
          img["large-thumb"] = img.largeThumb;
          img["small-thumb"] = img.smallThumb;
          img["tiny-thumb"] = img.tinyThumb;
          img.attributes = img;
        })
      }
    }

    return (
      <div className="sponsor-place">
        <Navbar isMobileSP={this.isMobileSP}>

        </Navbar>

        <div className="div1 d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center text-justify title1">
            <h3>¿Quieres ser uno de nuestros Travel Angel?</h3>
          </div>
          <div className={this.state.isMobileSP ? "d-flex justify-content-start text-justify title2" : "d-flex justify-content-center text-justify title2 text-center"}>
            <h6>
              Mediante el patrocinio de este destino podrás promocionar tus productos y servicios y
              al mismo tiempo ayudar económicamente a la comunidad de viajeros, ya que el 50% de su
              importe estará dedicado a ellos. En una primera fase este 50% lo ganará el creador
              original del destino, y posteriormente se repartirá 20% para el creador y 30% para el
              usuario que añada las mejores imágenes de ese lugar.
            </h6>
          </div>
        </div>
        <div className={this.state.isMobileSP ? "flex-column div2 d-flex justify-content-start align-items-center" : "flex-row div2 d-flex justify-content-center align-items-start"}>
          {/* <div className="flex-row div2 d-flex justify-content-center align-items-center"> */}
          <div className="ItemDiv2 I1 flex-column justify-content-start align-items-center" >
            <div className="divPatrocinar">
              <span>DESTINO A PATROCINAR</span>
              <img className="Img2" src={imagepoi.length >= 1 && imagepoi[0].smallThumb} alt="" />
              <img
                src="../../../assets/images/external-link-symbol_blanco.svg"
                width="20"
                alt=""
                className="ml-2 img-link1"
                onClick={this.modalPreviewContentOpen}
              />
            </div>
          </div>
          <div className="ItemDiv2 I2 d-flex flex-column justify-content-start align-items-center">
            <div className="title2">
              <h3>{this.state.poi.title}</h3>
            </div>
            <div className="d-flex flex-column justify-content-start align-items-center">
              <ul className="UL">
                <li className="author-box__data-item itemList">
                  {HEART_ICON}
                  {this.state.poi.likesNumber == null ? 0 : this.state.poi.likesNumber} me gusta
                </li>
                <li className="author-box__data-item itemList">
                  {EYE_ICON}
                  {this.state.poi.views == null ? 0 : this.state.poi.views} visualizaciones
                </li>
                <li className="author-box__data-item icono2 itemList d-flex align-items-end">
                  {TOUR_SIGNAL_ICON}
                  <span>añadido en {this.state.tours.length} rutas</span>
                  <img
                    src="../../../assets/images/external-link-symbol_azul.svg"
                    width="20"
                    alt=""
                    className="ml-2 img-link2"
                    onClick={() => this.modalPay(true, true)}
                  />
                </li>
              </ul>
            </div>
            <div className="d-flex mt-4 flex-column justify-content-start align-items-center msg2">
              <p className='text-center'>
                Patrocinando este destino tu publicidad se verá directamente en todas estas rutas y
                todas las que se creen y añadan este destino
              </p>
            </div>
          </div>
          <div className="ItemDiv2 I3  d-flex flex-column justify-content-start align-items-center">
            {
              (this.state.isMobileSP) ? <div className="LineVertical"></div> : <div></div>
            }
            <div className="Title2-3 BackGround-Blue d-flex justify-content-center align-items-end">
              <h3>Coste del patrocinio</h3>
            </div>

            <div className="bloque-2-I3 BackGround-Blue d-flex flex-column justify-content-start align-items-center">
              <div className="SubTitle2-3 d-flex justify-content-center align-items-center">
                { this.state.poi && this.state.poi.sponsorPrice ?
                 ( <span>{
                    (this.state.isMobileSP) ? `${this.state.poi ? this.state.poi.sponsorPrice : 0}€` : `${this.state.poi ? this.state.poi.sponsorPrice : 0} €`
                  }</span>) : (<span>{(this.state.isMobileSP) ? `0€` : `0 €`}</span>)
                }
              </div>
              <div className="Anuales">
                <span>ANUALES</span>
              </div>
              {
                this.state.poi && this.state.poi.sponsorPrice <= 100 ? <div className="PrecioProm">
                  <span>Precio de promoción</span>
                  <span> de lanzamiento.</span>
                </div> : null
              }
            </div>
            <div className={this.state.isMobileSP ? "DirectAutor BackGround-Blue d-flex flex-column justify-content-center align-items-center text-center" : "DirectAutor BackGround-Blue text-center"}>
              <span className="Eur50">{this.state.poi && this.state.poi.sponsorPrice ? (this.state.poi.sponsorPrice/2) : 0}€ </span>
              <span className="DirectAutor2 BackGround-Blue"> {this.state.isMobileSP ? "D" : "d"}irectamente a su autor</span>
            </div>


            <span className="NombrePersona">
              {this.state.poi.owner &&
                `${this.state.poi.owner.name} ${this.state.poi.owner.surname}`}
            </span>
            <img src={this.state.avatar == null ? "" : this.state.avatar} alt="" className="img-foto" />
          </div>
          {isAuthenticated ? (
            <button className="btn btn-primary botonMitad" onClick={() => this.modalPay(true)}>Contratar</button>
          )
            :
            (
              <button className="btn btn-primary botonMitad" onClick={this.modalLogOutOpen}>Contratar</button>
            )}
        </div>
        <div className={this.state.isMobileSP ? 'mobile' : ''}>
          <div className="div3  d-flex flex-colum justify-content-center align-items-button">
            <h3>¿Dónde se verá tu publicidad?</h3>
          </div>

          <div className="div4  d-flex flex-row justify-content-center align-items-stretch">
            <div className="ItemDiv4">
              <div>
                <h5 className="Titulo">En la ficha de ese destino</h5>
                <p className="d-flex justify-content-center text-justify title2 text-center">
                  Es un espacio exclusivo antes de la galería de imágenes, ocupando todo el ancho de
                  la pantalla.
              </p>
              </div>
              <div className="d-flex-column justify-content-center align-items-end mb-5">
                <p className="text-center transparentTitle">Ver</p>
                <div className='d-flex justify-content-center align-items-center'>
                  <button className="btn btn-primary" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/ficha_destino.jpg')}>Ver ejemplo</button>
                </div>
              </div>
            </div>

            <div className="ItemDiv4">
              <div>
                <h5 className="Titulo">En todas las rutas que lo añadan excepto en rutas de canales oficiales</h5>
                <p className="d-flex justify-content-center text-justify title2 text-center">
                  Junto a la descripción del destino y en cada una de las ventanas de ampliar
                  información del mismo.
                </p>
              </div>
              <div className='mb-5'>
                  <div className="d-flex-column justify-content-center align-items-end">
                  <p className='text-center' style={{ color: '#00affe'}}>Ver ejemplos</p>
                    <div className='d-flex justify-content-center align-items-center'>
                      <button className="btn btn-primary tinyButtons" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/1_ruta.jpg')}>1</button>
                      <button className="btn btn-primary tinyButtons" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/2_modal_ruta.jpg')}>2</button>
                      <button className="btn btn-primary tinyButtons" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/3.jpg')}>3</button>
                    </div>
                  </div>
              </div>
            </div>

            <div className="ItemDiv4">
              <div>
                <h5 className="Titulo">En todas sus imágenes</h5>
                <p className="d-flex justify-content-center text-justify title2 text-center">
                  Siempre que un usuario quiera comentar una imagen o leer la descripción o título del autor verá la identidad corporativa del patrocinador del destino.
                </p>
              </div>
                <div className="d-flex-column justify-content-center align-items-end mb-5">
                  <div className="d-flex justify-content-center align-items-center">
                    <button className="btn btn-primary" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/imagen_ampliada.jpg')}>Ver ejemplo</button>
                  </div>
                </div>
            </div>
          </div>

          <div className="div4  d-flex flex-row justify-content-center align-items-stretch">
            <div className="ItemDiv4">
              <div>
                <h5 className="Titulo">En las ventanas previas</h5>
                <p className="d-flex justify-content-center text-justify title2 text-center">
                  Que permiten una versión previa del recurso antes de ir a su página. El logotipo aparecerá junto a su descripción y bajo el carrusel de imágenes.
                </p>
              </div>
                <div className="divbotones d-flex justify-content-center align-items-end mb-5">
                <button className="btn btn-primary" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/previa.jpg')}>Ver ejemplo</button>
                </div>
            </div>

            <div className="ItemDiv4">
              <div>
                <h5 className="Titulo">En los resultados de descubre</h5>
                <p className="d-flex justify-content-center text-justify title2 text-center">
                  Con una tarjeta doble en los resultados de búsqueda. (Solo en destinos “Selection” y cuando haya menos de 3 resultados “Selection” en pantalla de forma simultánea).
                </p>
              </div>
              <div className="divbotones d-flex justify-content-center align-items-end mb-5">
                <button className="btn btn-primary" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/info_ruta_selection.jpg')}>Ver ejemplo</button>
              </div>
            </div>

            <div className="ItemDiv4">
              <div>
                <h5 className="Titulo">En las próximas funcionalidades</h5>
                <p className="d-flex justify-content-center text-justify title2 text-center">
                  En Topdesti seguiremos creando utilidades pensando en que los viajeros dispongan
                  de las mejores herramientas para planificar y difundir sus rutas de viaje. Tu
                  publicidad estará en todas ellas y vinculada siempre al destino que patrocines.
                </p>
              </div>
            </div>
          </div>

          <h3 className="text-center mt-3">Ventajas</h3>

          <div className="div5 d-flex flex-colum justify-content-center align-items-button">
            <div className="div6  flex-colum justify-content-star align-items-button">
              <p>Tu publicidad de forma exclusiva de ese destino.</p>
              <div className="d-flex justify-content-start title2 ">
                Un destino, un patrocinador... tu marca será exclusiva y única relacionada con ese
                lugar.
              </div>

              <p>Modifica tus imágenes o texto desde tu panel de control las veces que quieras.</p>
              <div className="d-flex justify-content-start title2 ">
                Podrás hacer campañas temáticas según la temporada, promociones temporales, etc...
                puedes cambiar tu contenido publicitario de forma muy cómoda y todas las veces que
                quieras.
              </div>

              <p>
                No sólo tendrás la promoción de tu marcar, si no además el refuerzo positivo de ser
                un agente activo en la financiación de los mejores creadores de nuestra comunidad de
                viajeros.
              </p>
              <div className="d-flex justify-content-start title2 ">
                Los viajeros no te verán como un "banner" publicitario molesto, si no como una ayuda
                que les permite hacer lo que más les gusta: "viajar". Los usuarios "no creadores" te
                verán como uno de los elementos que les ha facilitado un contenido de forma gratuita
                que les entretiene o les facilita la planificación de su próximo viaje.
              </div>

              <p>Difusión.</p>
              <div className="d-flex justify-content-start title2 ">
                Patrocina un destino y tendrás presencia en todas las rutas que lo incluyan. ¿Tienes
                un hotel cerca de un monumento? Cada visualización por parte de un usuario de ese
                recurso, tanto en una ruta como en la ficha de ese destino, significará también que
                conozca tu servicios próximos al mismo.
              </div>
            </div>
          </div>

          <div className="div7 d-flex flex-colum justify-content-center align-items-button">
            <div className="div6  flex-colum justify-content-center  align-items-center text-center">
              <p>
                Patrocina ahora este destino mientras está libre y únete a nuestra red de Travel
                Angels.
              </p>
            </div>
          </div>
        </div>

        <div className={!this.state.isMobileSP ? 'mobile' : ''}>
          <div className="div33  d-flex flex-colum justify-content-center align-items-button">
            <h5 className="titulomobile">¿Dónde se verá tu publicidad?</h5>
          </div>

          <h5 className="flip1 d-flex justify-content-between" onClick={() => this.openPanel(0)}>
            En la ficha de ese destino
            <img
              id="flecha-0"
              src={`../../../assets/images/${this.state.flecha0}.svg`}
              width="15"
              alt=""
              className="img2"
            />
          </h5>
          <div className="panel panel-0">
            <div>
              <p className="d-flex justify-content-start  title2 text-left">
                Es un espacio exclusivo antes de la galería de imágenes, ocupando todo el ancho de
                la pantalla.
              </p>
              <div className='d-flex justify-content-center align-items-center my-5'>
                <button className="btn btn-primary" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/ficha_destino.jpg')}>Ver ejemplo</button>
              </div>
              {/* <div className="divbotones">
                <p className="d-flex justify-content-center text-justify title22 text-center">
                  VER EJEMPLO
                </p>
                <div className="d-flex justify-content-center botones1">
                  <img className="botones" src="/assets/images/boton_monitor.svg" alt="" />
                  <img className="botones" src="/assets/images/boton_movil.svg" alt="" />
                </div>
              </div> */}
            </div>
          </div>

          <h5 className="flip1 d-flex justify-content-between" onClick={() => this.openPanel(1)}>
            En todas las rutas que lo añadan
            <img
              id="flecha-1"
              src={`../../../assets/images/${this.state.flecha1}.svg`}
              width="15"
              alt=""
              className="img2"
            />
          </h5>
          <div className="panel panel-1">
            <div>
              <p className="d-flex justify-content-start  title2 text-left">
                Junto a la descripción del destino y en cada una de las ventanas de ampliar
                información del mismo.
              </p>
              <div className="d-flex-column justify-content-center align-items-end my-5">
                <p className='text-center' style={{ color: '#00affe' }}>Ver ejemplos</p>
                <div className='d-flex justify-content-center align-items-center'>
                  <button className="btn btn-primary tinyButtons" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/1_ruta.jpg')}>1</button>
                  <button className="btn btn-primary tinyButtons" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/2_modal_ruta.jpg')}>2</button>
                  <button className="btn btn-primary tinyButtons" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/3.jpg')}>3</button>
                </div>
              </div>
              {/* <div className="divbotones">
                <p className="d-flex justify-content-center text-justify title22 text-center">
                  VER EJEMPLO
                </p>
                <div className="d-flex justify-content-center botones1">
                  <img className="botones" src="/assets/images/boton_monitor.svg" alt="" />
                  <img className="botones" src="/assets/images/boton_movil.svg" alt="" />
                </div>
              </div> */}
            </div>
          </div>

          <h5 className="flip1 d-flex justify-content-between" onClick={() => this.openPanel(2)}>
            En todas sus imágenes
            <img
              id="flecha-2"
              src={`../../../assets/images/${this.state.flecha2}.svg`}
              width="15"
              alt=""
              className="img2"
            />
          </h5>
          <div className="panel panel-2">
            <div>
              <p className="d-flex justify-content-start  title2 text-left">
                Siempre que un usuario quiera comentar una imagen o leer la descripción o título del autor verá la identidad corporativa del patrocinador del destino.
              </p>
              <div className="d-flex-column justify-content-center align-items-end my-5">
                <div className="d-flex justify-content-center align-items-center">
                  <button className="btn btn-primary" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/imagen_ampliada.jpg')}>Ver ejemplo</button>
                </div>
              </div>
              {/* <div className="divbotones">
                <p className="d-flex justify-content-center text-justify title22 text-center">
                  VER EJEMPLO
                </p>
                <div className="d-flex justify-content-center botones1">
                  <img className="botones" src="/assets/images/boton_monitor.svg" alt="" />
                  <img className="botones" src="/assets/images/boton_movil.svg" alt="" />
                </div>
              </div> */}
            </div>
          </div>

          <h5 className="flip1 d-flex justify-content-between" onClick={() => this.openPanel(3)}>
            En los resultados de descubre
            <img
              id="flecha-3"
              src={`../../../assets/images/${this.state.flecha3}.svg`}
              width="15"
              alt=""
              className="img2"
            />
          </h5>
          <div className="panel panel-3">
            <div>
              <p className="d-flex justify-content-start  title2 text-left">
                Con una tarjeta doble en los resultados de búsqueda. (Solo en destinos “Selection” y cuando haya menos de 3 resultados “Selection” en pantalla de forma simultánea).
              </p>
              <div className="divbotones d-flex justify-content-center align-items-end my-5">
                <button className="btn btn-primary" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/previa.jpg')}>Ver ejemplo</button>
              </div>
              {/* <div className="divbotones">
                <p className="d-flex justify-content-center text-justify title22 text-center">
                  VER EJEMPLO
                </p>
                <div className="d-flex justify-content-center botones1">
                  <img className="botones" src="/assets/images/boton_monitor.svg" alt="" />
                  <img className="botones" src="/assets/images/boton_movil.svg" alt="" />
                </div>
              </div> */}
            </div>
          </div>

          <h5 className="flip1 d-flex justify-content-between" onClick={() => this.openPanel(4)}>
            En las ventanas previas
            <img
              id="flecha-4"
              src={`../../../assets/images/${this.state.flecha4}.svg`}
              width="15"
              alt=""
              className="img2"
            />
          </h5>
          <div className="panel panel-4">
            <div>
              <p className="d-flex justify-content-start  title2 text-left">
                Que permiten una versión previa del recurso antes de ir a su página. El logotipo aparecerá junto a su descripción y bajo el carrusel de imágenes.
              </p>
              <div className="divbotones d-flex justify-content-center align-items-end mb-5">
                <button className="btn btn-primary" onClick={() => this.modalImagesSponsor('../../../assets/patrocinios/info_ruta_selection.jpg')}>Ver ejemplo</button>
              </div>
              {/* <div className="divbotones">
                <p className="d-flex justify-content-center text-justify title22 text-center">
                  VER EJEMPLO
                </p>
                <div className="d-flex justify-content-center botones1">
                  <img className="botones" src="/assets/images/boton_monitor.svg" alt="" />
                  <img className="botones" src="/assets/images/boton_movil.svg" alt="" />
                </div>
              </div> */}
            </div>
          </div>

          <h5 className="flip1 d-flex justify-content-between" onClick={() => this.openPanel(5)}>
            En las próximas funcionalidades
            <img
              id="flecha-5"
              src={`../../../assets/images/${this.state.flecha5}.svg`}
              width="15"
              alt=""
              className="img2"
            />
          </h5>
          <div className="panel panel-5">
            <div>
              <p className="d-flex justify-content-start  title2 text-left">
                En Topdesti seguiremos creando utilidades pensando en que los viajeros dispongan de
                las mejores herramientas para planificar y difundir sus rutas de viaje. Tu
                publicidad estará en todas ellas y vinculada siempre al destino que patrocines.
              </p>
              {/* <div className="divbotones">
                <p className="d-flex justify-content-center text-justify title22 text-center">
                  VER EJEMPLO
                </p>
                <div className="d-flex justify-content-center botones1">
                  <img className="botones" src="/assets/images/boton_monitor.svg" alt="" />
                  <img className="botones" src="/assets/images/boton_movil.svg" alt="" />
                </div>
              </div> */}
            </div>
          </div>

          <div className="div33 divVentajas d-flex flex-colum justify-content-center align-items-button">
            <h5 className="titulomobile">Ventajas</h5>
          </div>

          <div className="div55 d-flex flex-colum justify-content-start align-items-button">
            <div className="div66  flex-colum justify-content-star align-items-button">
              <p>Tu publicidad de forma exclusiva de ese destino.</p>
              <div className="d-flex justify-content-start title2 ">
                Un destino, un patrocinador... tu marca será exclusiva y única relacionada con ese
                lugar.
              </div>
              
              <p>Modifica tus imágenes o texto desde tu panel de control las veces que quieras.</p>
              <div className="d-flex justify-content-start title2 ">
                Podrás hacer campañas temáticas según la temporada, promociones temporales, etc...
                puedes cambiar tu contenido publicitario de forma muy cómoda y todas las veces que
                quieras.
              </div>

              <p>
                No sólo tendrás la promoción de tu marcar, si no además el refuerzo positivo de ser
                un agente activo en la financiación de los mejores creadores de nuestra comunidad de
                viajeros.
              </p>
              <div className="d-flex justify-content-start title2 ">
                Los viajeros no te verán como un "banner" publicitario molesto, si no como una ayuda
                que les permite hacer lo que más les gusta: "viajar". Los usuarios "no creadores" te
                verán como uno de los elementos que les ha facilitado un contenido de forma gratuita
                que les entretiene o les facilita la planificación de su próximo viaje.
              </div>

              <p>Difusión.</p>
              <div className="d-flex justify-content-start title2 ">
                Patrocina un destino y tendrás presencia en todas las rutas que lo incluyan. ¿Tienes
                un hotel cerca de un monumento? Cada visualización por parte de un usuario de ese
                recurso, tanto en una ruta como en la ficha de ese destino, significará también que
                conozca tu servicios próximos al mismo.
              </div>
            </div>
          </div>

          <div className="div7 d-flex flex-colum justify-content-center align-items-button">
            <div className="div66  flex-colum justify-content-center  align-items-center text-center">
              <p>
                Patrocina ahora este destino mientras está libre y únete a nuestra red de Travel
                Angels.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          {isAuthenticated ? (
            <button className="btn btn-primary botonabajo " onClick={() => this.modalPay(true)}>contratar</button>
          )
            :
            (
              <button className="btn btn-primary botonabajo " onClick={this.modalLogOutOpen}>contratar</button>
            )}
        </div>
        <ModalOnlyUsers
          modalLogOut={this.state.showModalLogOut}
          modalLogOutClose={this.handlemodalLogOutClose}
        />
        {/* Modal confirmacion pago */}
        <Modal
          className="modalPay"
          overlayClassName="modalPay__contentModal"
          isOpen={this.state.showModalPay}
          contentLabel="ContentModal"
          onRequestClose={() => this.modalPay(false)}
          shouldCloseOnOverlayClick={true}
        >
          <div className="content">
            <div className="w-100 d-flex justify-content-end">
              <button onClick={() => this.modalPay(false)} className="closeIcon p-0">
                {CIRCLE_CROSS_ICON}
              </button>
            </div>
            {this.state.infoTours ? (
              <>
                <div className="infoTours">
                  <h4 className='ml-4 mb-5'>Rutas donde se han añadido ({this.state.tours.length}):</h4>
                  <div className="tour">
                    {this.state.tours.map((tour, i) => {
                        return (
                          <div className="d-flex division2 align-items-between w-100 tour">
                            <div className="d-flex align-items-center w-100 ">
                              <div className='d-block d-sm-block d-md-none d-lg-none d-xl-none'>
                                <div className="d-flex justify-content-between">
                                  <h5 className='mb-2'>{tour.title}</h5>
                                  <Link to={`/tour/${tour.urlTitle}`} className="mr-2">
                                    <img
                                      src="../../../assets/images/external-link-symbol_azul.svg"
                                      width="22"
                                      alt=""
                                      className=""
                                    />
                                  </Link>
                                </div>
                                <div className="d-flex">
                                  <img className='col-5 portada mr-3 p-0' src={tour.desktopImage && tour.desktopImage.tinyThumb ? tour.desktopImage.tinyThumb : '../../../assets/images/empty_photo.svg' }  alt=""/>
                                  <p> {tour.previousSearchText && tour.previousSearchText.length <= 100 ? tour.previousSearchText : tour.previousSearchText ? tour.previousSearchText.substr(0, 100) + '...' : '' } </p>
                                </div>
                              </div>
                              <img className=' col-3 portada mr-3 d-none  d-md-block d-lg-block d-xl-block' src={tour.desktopImage && tour.desktopImage.tinyThumb ? tour.desktopImage.tinyThumb : '../../../assets/images/empty_photo.svg'} alt="" />
                              <div className='desc d-none  d-md-block d-lg-block d-xl-block'>
                                <h5>{ tour.title }</h5>
                                <p>{tour.previousSearchText && tour.previousSearchText.length <= 120 ? tour.previousSearchText : tour.previousSearchText ? tour.previousSearchText.substr(0, 120) + '...' : '' }</p>
                                {/* <p>{tour.previousSearchText }</p> */}
                              </div> 
                            </div>
                            <Link to={`/tour/${tour.urlTitle}`} className="col-1 justify-content-center align-items-center d-none  d-md-flex d-lg-flex d-xl-flex">
                              <img
                                src="../../../assets/images/external-link-symbol_azul.svg"
                                width="25"
                                alt=""
                                className="img-link2"
                              />
                            </Link>
                          </div>
                        );
                    })}
                  </div>
                </div>
              </>
            ) : (
                <>
                  <div className="infoPay">
                    <h4 className='ml-3' >Confirmación del pago:</h4>
                    {!this.state.paymentConfirmed ? (
                      <p className="text-center mx-3">Una vez aceptado el pago podrás personalizar los elementosde tu patrocinio desde el área profesional de tu perfil.</p>
                    ) : null}
                  </div>
                  {!this.state.paymentConfirmed ? (
                    <div className="contentPay division">
                      <div className="top d-flex">
                        <div className="circle">
                          {
                            this.props.auth.avatar && this.props.auth.avatar.avatar && this.props.auth.avatar.avatar.tinyThumb ? <img src={this.props.auth.avatar.avatar.tinyThumb} alt="" /> : <img src="../../../assets/images/default-avatar.png" alt=""/>
                          }
                        </div>
                        <div className="d-flex flex-column justify-content-center ml-3">
                          {this.props.auth.currentUser ? (
                            <>
                              <h3 className="m-0 textBlue">{this.props.auth.currentUser.attributes.name} {this.props.auth.currentUser.attributes.surname}</h3>
                              <span>{this.props.auth.currentUser.attributes.nickname}</span>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="center d-flex row">
                        <div className="col-6">
                          <div className="payService d-flex flex-column align-items-center">
                            <h3>Pago del servicio</h3>
                            <h1>{this.state.poi ? this.state.poi.sponsorPrice : 0} €</h1>
                          </div>
                          <p className="text-center mt-2 sub">Suscripción válida hasta el {this.oneYear()}</p>
                          
                        </div>
                        <div className='col text-center'>
                          <img width='' className='mt-4 text-center' src="../assets/images/stripe.png" alt=""/>
                          <p className='text-center mt-3'>Todo el proceso de pago se realiza de forma segura con la plataforma de pago de Stripe, sin que nosotros almacenemos o tengamos acceso a los datos de la tarjeta de crédito.</p>
                        </div>
                      </div>
                      <div className="center d-flex row ">
                        <Elements stripe={stripePromise}>
                          <CheckoutForm confirmPay={this.confirmPay} poi={this.state.poi} client={this.props.auth.currentUser ? this.props.auth.currentUser.attributes.nickname : 'anonimo'} userId={this.props.auth.currentUser ? this.props.auth.currentUser.id : 'anonimo'} />
                        </Elements>
                      </div>
                      {/* <div className="d-flex justify-content-center">
                  <button className="btn btn-primary mt-5" onClick={() => this.payment()}>Pagar</button>
                </div> */}
                    </div>
                  ) :
                    (
                      <div className="paymentConfirmed d-flex flex-column">
                        <div className="d-flex paymentConfirmed__content">
                          <div className="col-6 d-flex justify-content-center">
                            <img className="grafismo" src="/assets/images/confirmacion_grafismo.svg" alt="" />
                          </div>
                          <div className="col-6 text-center d-flex flex-column justify-content-end">
                            <h3 className="mb-3 textBlue">PAGO CONFIRMADO</h3>
                            <p className="m-0">Muchísimas gracias por tu colaboración.</p>
                            <p>Con tu patrocinio nos ayudarás a mejorar nuestra plataforma y al autor de este destino para que siga creando contenido de calidad.</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center paymentConfirmed__buttons">
                          <Link className="btn btn-primary mt-5 mr-2" to={`/user/${this.props.auth.currentUser && this.props.auth.currentUser.attributes.nickname}/${true}`} >Editar mi patrocinio</Link>
                          <button className="btn btn-primary mt-5 ml-2" onClick={() => this.modalPay(true)}>Información y plantillas</button>
                        </div>
                      </div>
                    )}
                </>
              )}
          </div>
        </Modal>
        {currentPoi.id != undefined ? (
          <ModalPreviewContent
            modalPreviewContent={this.state.showModalPreviewContent}
            modalPreviewContentClose={this.modalPreviewContentClose}
            poi={currentPoi}
            auth={this.props.auth}
            redirectURL={PLACE_PAGE_DINAMIC_URL(currentPoi.attributes.urlTitle)}
            imageArray={currentPoiImages}
          />
        ) : null}

        <Modal
          className="modalImagesSponsor"
          overlayClassName="modalImagesSponsor__contentModal"
          isOpen={this.state.modalImagesSponsor}
          contentLabel="ContentModal"
          onRequestClose={() => this.setState({ modalImagesSponsor: false })}
          shouldCloseOnOverlayClick={true}
        >
          <div className="content">
            <div className="w-100 d-flex justify-content-end">
              <button onClick={() => this.setState({ modalImagesSponsor: false })} className="closeIcon p-0 pb-2">
                {CIRCLE_CROSS_ICON}
              </button>
            </div>
            <img className='imageSponsor' src={this.state.srcModalImagesSponsor} alt="" />
          </div>
        </Modal>    

      </div>
    );
  }
}

const processPay = async (paymentMethod, poi, client) => {
  var resp = await  axios.post(`${ENV.host}/${ENV.namespace}/pois/paySponsor`, {
    paymentMethod: paymentMethod,
    price: poi.sponsorPrice,
    poiName: poi.title,
    poiId: poi.id,
    user: client
  })
    return resp
}



const CheckoutForm = (props) => {
  let nickname = props.client;
  const [loadingPay, setLoadingPay] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    const cardElement = elements.getElement(CardElement);

    if (!cardElement._empty) {
      setLoadingPay(true)
      const { token } = await stripe.createToken(cardElement, { name: nickname });
      if (token) {
        let payed = await processPay(token, props.poi, props.userId);
        if (payed.data.status == 400) {
          toastr.error('Lo sentimos este destino ya fue patrocinado.');
        }
        else if (payed.data.status === 200) {
          props.confirmPay()
        }
        setLoadingPay(false);
      }
    }



  }

  return (
    <div className="col-12 col-md-11 col-lg-11 col-xl-11 payForm">
      {/* <div>
                      <select className="browser-default custom-select">
                        <option>Choose your option</option>
                        <option value="1">Option 1</option>
                        <option value="2">Option 2</option>
                        <option value="3">Option 3</option>
                      </select>
                    </div> */}
      <div className="d-flex align-items-center">
        <h4>Pago con tarjeta</h4>
        <img className="mastercard" src="/assets/images/mastercard.jpg" alt="" />
        <img className="visa" src="/assets/images/visa.jpg" alt="" />
        <img className="amex" src="/assets/images/amex.png" alt="" />
      </div>
      <label htmlFor="numberCard" className="d-block">
        <p className="m-0 mb-2">Número de tarjeta</p>
        <CardElement hidePostalCode={true} />
        {/* <input type="text" name="numberCard" required className="form-control inputCard" placeholder="1234 5678 9012 3456" /> */}
      </label>
      {/* <div className="d-flex">
                      <label htmlFor="caducidad" className="d-block col-6 pl-0">
                        <p className="m-0">Caducidad</p>
                        <input type="text" name="caducidad" required className="form-control" placeholder="mm/aa" />
                      </label>
                      <label htmlFor="cvv" className="d-block col-6 pr-0">
                        <p className="m-0">CVV</p>
                        <input type="text" name="cvv" required className="form-control" placeholder="123" />
                      </label>
                    </div> */}
      <span>El CVV es un código númerico, generalmente de 3 dígitos detrás de la tarjeta.</span>
      <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row justify-content-between align-items-center align-items-md-start align-items-lg-start align-items-xl-start mt-4">
        {/* <p className="m-0">Titular de la tarjeta</p>
        <input type="text" name="titular" required className="form-control" placeholder="Titular de la tarjeta" /> */}
        <span className="notice col-6">
          No serán aceptados patrocinios que fomenten la discriminación, violencia, racismo o cualquier activiad
          que consideremos fuera de los valores éticos y morales de Topdesti. Si se diera alguno de estos casos se cancelaría la suscripción y se devolvería el importe cobrado.
                  </span>
        <div className="d-flex justify-content-center">
          <button className="btn btn-primary mt-4 mt-md-0 mt-lg-0 mt-xl-0 pagarButtonSponsor" onClick={() => handleSubmit()} disabled={!stripe}>
            {
              loadingPay ? <img width='20' src="../../../assets/images/spinner.svg" alt="" /> : 'Pagar'
            }
          </button>
        </div>
      </div>
    </div>
  );
};

Sponsorplace.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isSmallDevice: PropTypes.bool,
  searchType: PropTypes.string,
  urlQueryParams: PropTypes.objectOf(PropTypes.object),
  placesTabQueryParams: PropTypes.string,
  servicesTabQueryParams: PropTypes.string,
};

Sponsorplace.defaultProps = {
  isMobile: null,
  isSmallDevice: null,
  urlQueryParams: null,
  searchType: null,
  placesTabQueryParams: null,
  servicesTabQueryParams: null,
};
function mapStateToProps(state) {
  if(state.auth.currentUser) {
    fetch(`${ENV.host}/${ENV.namespace}/images/avatar/${state.auth.currentUser.id}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        state.auth.avatar = data
      });
  }
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    auth: state.auth,
    avatar: state.auth.avatar
    /*   currentUser,
      avatarUrl,
      currentRoute: state.router.location.pathname, */
  };
}

/* export default Sponsorplace; */
export default connect(
  mapStateToProps,
  null,
)(Sponsorplace);
