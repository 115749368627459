import React, { useState } from 'react';
import { CLOSE_ICON } from '../helpers';
import { COOKIES_URL } from '../constants';

const CookieFixedBanner = () => {
  const [aknowledgedCookies, setAknowledgedCookies] = useState(localStorage.getItem('aknowledgedCookies') || false);

  const aknowledgeCookie = () => {
    localStorage.setItem('aknowledgedCookies', true);
    setAknowledgedCookies(true);
  };

  if (aknowledgedCookies) return null;
  return (
    <div className="cookie-fixed-banner">
      <div className="cookie-fixed-banner__wrapper">
        <p className="cookie-fixed-banner__text">
        Topdesti usa las cookies para garantizar la mejor experiencia posible a los usuarios,
        si continuas navegando estarás aceptando nuestra
          {' '}
          <a href={COOKIES_URL}>política de cookies</a>
        </p>
        <button type="button" className="cookie-fixed-banner__close-btn" onClick={aknowledgeCookie}>
          {CLOSE_ICON}
        </button>
      </div>
    </div>
  );
};

export default CookieFixedBanner;
