import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import {
  PLUS_ICON,
  CUSTOM_IMG_ICON,
  CLOCK_ICON,
  VIDEO_ICON,
  TEXT_ICON,
  MAP_ICON,
  TRAVEL_TIP_ICON,
  OFFER_ICON,
  EXPENSE_ICON,
  CLOSE_ICON,
} from '../../../../helpers';
import { lockBodyScrolling, restoreBodyScrolling } from '../../../../utils';

const MenuTourContentModalTemplate = (props) => {
  const {
    isOpen, isChild, closeModal, handleTourContentCreation, isMobile,
  } = props;

  // Acts as componentDidMount. as it's passed a [] as an argument and as componenWillUnmount on the return.
  useEffect(() => {
    lockBodyScrolling();
    return restoreBodyScrolling;
  }, []);

  return (
    <Modal
      className="menu-tour-content-modal"
      overlayClassName="menu-tour-content-modal__overlay"
      name="textTourContentModal"
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      {isMobile ? (
        <header className="row menu-tour-content-modal__header">
          {isMobile ? (
            <div className="col">
              <img
                src="/assets/images/topdesti-logo.png"
                alt="TopDesti"
                className="menu-tour-content-modal__header-logo"
              />
            </div>
          ) : null}
          <div className="col-lg-12 menu-tour-content-modal__header-actions">
            {isMobile ? null : (
              <h4 className="menu-tour-content-modal__header-title">Crear nuevo contenido</h4>
            )}

            <button type="button" className="menu-tour-content-modal__header-btn" onClick={closeModal}>
              {CLOSE_ICON}
            </button>
          </div>
        </header>
      ) : null}
      <div className="menu-tour-content-modal__content">
        <ul className="row menu-tour-content-modal__list">
          {isChild ? null : (
            <li className="col-12 col-md-6 menu-tour-content-modal__option">
              <div className="row align-items-center">
                <div className="col-auto">
                  <span className="menu-tour-content-modal__option-icon">{TRAVEL_TIP_ICON}</span>
                </div>
                <div className="col">
                  <h4 className="menu-tour-content-modal__option-title">Consejo de viaje</h4>
                  <button
                    className="menu-tour-content-modal__option-btn"
                    type="button"
                    onClick={() => handleTourContentCreation(8)}
                    title="Crear nuevo contenido"
                  >
                    {PLUS_ICON}
                  </button>
                  <p className="menu-tour-content-modal__option-description">
                    ¿Un enlace a una web con los horarios de autobuses? ¿Cómo comportarse si te encuentras una
                     tortuga desovando en la playa? Escribe aquí cualquier información que creas que merezca
                     ser destacada para disfrutar al máximo de la ruta.
                  </p>
                </div>
              </div>
            </li>
          )}
          <li className="col-12 col-md-6 menu-tour-content-modal__option">
            <div className="row align-items-center">
              <div className="col-auto">
                <span className="menu-tour-content-modal__option-icon">{CUSTOM_IMG_ICON}</span>
              </div>
              <div className="col">
                <h4 className="menu-tour-content-modal__option-title">Añadir / tomar imágenes</h4>
                {/* <h4 className="menu-tour-content-modal__option-title">Fotografía personal</h4> */}
                <button
                  className="menu-tour-content-modal__option-btn"
                  type="button"
                  onClick={() => handleTourContentCreation(3)}
                  title="Crear nuevo contenido"
                >
                  {PLUS_ICON}
                </button>
                <p className="menu-tour-content-modal__option-description">
                  Dale tu toque personal a tus rutas incorporando tus fotografías de los
                   lugares que has visitado y tus propios comentarios.
                </p>
              </div>
            </div>
          </li>
          <li className="col-12 col-md-6 menu-tour-content-modal__option">
            <div className="row align-items-center">
              <div className="col-auto">
                <span className="menu-tour-content-modal__option-icon">{VIDEO_ICON}</span>
              </div>
              <div className="col">
                <h4 className="menu-tour-content-modal__option-title">Vídeo insertado</h4>
                <button
                  className="menu-tour-content-modal__option-btn"
                  type="button"
                  onClick={() => handleTourContentCreation(5)}
                  title="Crear nuevo contenido"
                >
                  {PLUS_ICON}
                </button>
                <p className="menu-tour-content-modal__option-description">
                  Copia el enlace de tu video desde tu canal de youtube o vimeo y quedará integrado en tu
                   itinerario. Podrás hacerlo también con videos de otros autores cuyo contenido esté
                   relacionado con tu ruta.
                </p>
              </div>
            </div>
          </li>
          <li className="col-12 col-md-6 menu-tour-content-modal__option">
            <div className="row align-items-center">
              <div className="col-auto">
                <span className="menu-tour-content-modal__option-icon">{TEXT_ICON}</span>
              </div>
              <div className="col">
                <h4 className="menu-tour-content-modal__option-title">Título y bloque de texto</h4>
                <button
                  className="menu-tour-content-modal__option-btn"
                  type="button"
                  onClick={() => handleTourContentCreation(4)}
                  title="Crear nuevo contenido"
                >
                  {PLUS_ICON}
                </button>
                <p className="menu-tour-content-modal__option-description">
                  Añade información extra de tu recorrido. Podrás añadir sólo un título, subtítulo,
                   un texto descriptivo o la combinación de ambos.
                </p>
              </div>
            </div>
          </li>
          <li className="col-12 col-md-6 menu-tour-content-modal__option">
            <div className="row align-items-center">
              <div className="col-auto">
                <span className="menu-tour-content-modal__option-icon">{MAP_ICON}</span>
              </div>
              <div className="col">
                <h4 className="menu-tour-content-modal__option-title">Mapa</h4>
                <button
                  className="menu-tour-content-modal__option-btn"
                  type="button"
                  onClick={() => handleTourContentCreation(6)}
                  title="Crear nuevo contenido"
                >
                  {PLUS_ICON}
                </button>
                <p className="menu-tour-content-modal__option-description">
                  ¿Necesitas establecer un punto de encuentro? selecciona las coordenadas con el mapa y si
                   quieres añade un texto sobre el lugar para que sea más sencillo identificarlo.
                </p>
              </div>
            </div>
          </li>
          <li className="col-12 col-md-6 menu-tour-content-modal__option">
            <div className="row align-items-center">
              <div className="col-auto">
                <span className="menu-tour-content-modal__option-icon">{CLOCK_ICON}</span>
              </div>
              <div className="col">
                <h4 className="menu-tour-content-modal__option-title">Hora y tiempo de visita</h4>
                <button
                  className="menu-tour-content-modal__option-btn"
                  type="button"
                  onClick={() => handleTourContentCreation(7)}
                  title="Crear nuevo contenido"
                >
                  {PLUS_ICON}
                </button>
                <p className="menu-tour-content-modal__option-description">
                  Hay lugares que necesitamos llegar en un momento determinado o quedarnos un máximo
                   para poder terminar la ruta en el plazo previsto.
                </p>
              </div>
            </div>
          </li>
          <li className="col-12 col-md-6 menu-tour-content-modal__option">
            <div className="row align-items-center">
              <div className="col-auto">
                <span className="menu-tour-content-modal__option-icon">{EXPENSE_ICON}</span>
              </div>
              <div className="col">
                <h4 className="menu-tour-content-modal__option-title">Gasto</h4>
                <button
                  className="menu-tour-content-modal__option-btn"
                  type="button"
                  title="Crear nuevo contenido"
                  disabled
                >
                  {PLUS_ICON}
                </button>
                <p className="menu-tour-content-modal__option-description">
                  Si quieres llevar un control de la economía de tu viaje podrás añadir los gastos que haces
                   durante tu ruta. Será también muy útil como referencia para otros usuarios que quieren
                   hacer un recorrido similar en la zona.
                </p>
              </div>
            </div>
          </li>
          {isChild ? null : (
            <li className="col-12 col-md-6 menu-tour-content-modal__option">
              <div className="row align-items-center">
                <div className="col-auto">
                  <span className="menu-tour-content-modal__option-icon">{OFFER_ICON}</span>
                </div>
                <div className="col">
                  <h4 className="menu-tour-content-modal__option-title">Oferta</h4>
                  <button
                    className="menu-tour-content-modal__option-btn"
                    type="button"
                    title="Crear nuevo contenido"
                    disabled
                  >
                    {PLUS_ICON}
                  </button>
                  <p className="menu-tour-content-modal__option-description">
                    Muy pronto podrás publicar ofertas para tus seguidores haciendo tus rutas mucho más atractivas.
                     Puedes llegar a acuerdos con los servicios turísticos que estén interesados en promocionarse
                     con un precio especial para la gente que te siga.
                  </p>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </Modal>
  );
};

MenuTourContentModalTemplate.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isChild: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleTourContentCreation: PropTypes.func.isRequired,
};

MenuTourContentModalTemplate.defaultProps = {};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(MenuTourContentModalTemplate);
