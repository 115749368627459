import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { TOUR_TYPES } from '../../../constants';
import { CLOSE_ICON } from '../../../helpers';
import { lockBodyScrolling, restoreBodyScrolling } from '../../../utils';

class FiltersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeFilters: {},
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentDidMount() {
    const { currentTypeFilters } = this.props;
    lockBodyScrolling();
    this.setState({
      typeFilters: currentTypeFilters,
    });
  }

  componentWillUnmount() {
    restoreBodyScrolling();
  }

  handleCheckboxChange(e, value) {
    const { typeFilters } = this.state;
    const newTypeFilters = { ...typeFilters };
    if (e.target.checked) {
      newTypeFilters[value] = true;
    } else {
      delete newTypeFilters[value];
    }
    this.setState({ typeFilters: newTypeFilters });
  }

  render() {
    const { handleCheckboxChange } = this;
    const {
      isOpen, closeModal, isMobile, handleSaveFilters,
    } = this.props;
    const { typeFilters } = this.state;
    return (
      <Modal
        className="filters-modal"
        overlayClassName="filters-modal__overlay"
        name="filtersModal"
        isOpen={isOpen}
        onRequestClose={closeModal}
      >
        <div className="filters-modal__content">
          {!isMobile ? (
            <header className="filters-modal__header">
              <button type="button" className="filters-modal__close-btn" onClick={closeModal}>
                {CLOSE_ICON}
              </button>
              <h4 className="filters-modal__title">Filtrar los resultados</h4>
            </header>
          ) : null}
          <ul className="filters-modal__category-list">
            {!isMobile ? <h5 className="filters-modal__category-title">Categoría</h5> : null}
            {TOUR_TYPES.map(tourType => (
              <li className="filters-modal__category-item" key={`category${tourType.value}`}>
                <label
                  htmlFor={`category${tourType.value}`}
                  className={`custom-checkbox ${isMobile ? 'right' : ''}`}
                >
                  {tourType.label}
                  <input
                    type="checkbox"
                    id={`category${tourType.value}`}
                    onChange={e => handleCheckboxChange(e, tourType.value)}
                    defaultChecked={typeFilters[tourType.value] || false}
                  />
                  <i />
                </label>
              </li>
            ))}
          </ul>
          <footer className="text-right">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleSaveFilters(typeFilters);
                closeModal();
              }}
            >
              Aceptar
            </button>
          </footer>
        </div>
      </Modal>
    );
  }
}

FiltersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  handleSaveFilters: PropTypes.func.isRequired,
  currentTypeFilters: PropTypes.objectOf(PropTypes.bool),
};
FiltersModal.defaultProps = {
  isMobile: false,
  currentTypeFilters: {},
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(FiltersModal);
