// POI REDUCER ACTIONS:
export const ADD_POI = 'ADD_POI';
export const EDIT_POI = 'EDIT_POI';
export const POI_UPLOADED_IMAGE = 'POI_UPLOADED_IMAGE';
export const UPDATE_POI_IMAGES = 'UPDATE_POI_IMAGES';

// AUTHENTICATION ACTIONS
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';

// CURRENT USER ACTIONS
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';

// VIEWPORT ACTIONS
export const UPDATE_VIEWPORT = 'UPDATE_VIEWPORT';

// TOUR EDITION ACTIONS
export const UPDATE_ACTIVE_TOUR = 'UPDATE_ACTIVE_TOUR';
export const UPDATE_PENDING_CHANGES = 'UPDATE_PENDING_CHANGES';
// DISCOVER ACTIONS

export const UPDATE_PLACES_QP = 'UPDATE_PLACES_QP';
export const UPDATE_SERVICES_QP = 'UPDATE_SERVICES_QP';
