import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash.clonedeep';
import TourContentDurationFormTemplate from './tour-content-duration-form-template';
import { isEmpty } from '../../../../../../utils';
import TYPES from '../../../../../../constants/types';

function convertMillisecondsToHoursAndMinutes(milliseconds) {
  if (isEmpty(milliseconds)) return [null, null];

  const hours = Math.floor(Number.parseInt(milliseconds, 10) / 3600000);
  const minutes = Math.floor((Number.parseInt(milliseconds, 10) - hours * 3600000) / 60000);
  return [hours, minutes];
}
function convertMillisecondsTo24HoursFormat(milliseconds) {
  if (isEmpty(milliseconds)) return null;

  const [hours, minutes] = convertMillisecondsToHoursAndMinutes(milliseconds);
  let formattedMinutes = `${minutes}`;
  if (formattedMinutes.length === 1) formattedMinutes = `0${formattedMinutes}`;
  return `${hours}:${formattedMinutes}`;
}
function convertHoursAndMinutesToMilliseconds([hours, minutes]) {
  if (isEmpty(hours) && isEmpty(minutes)) return null;
  let milliseconds = isEmpty(hours) ? null : Number.parseInt(hours, 10) * 3600000;
  milliseconds += isEmpty(minutes) ? null : Number.parseInt(minutes, 10) * 60000;
  return milliseconds;
}

function convert24hoursFormatToMilliseconds(format24hoursString) {
  if (isEmpty(format24hoursString)) return null;
  const [hours, minutes] = format24hoursString.split(':');
  return Number.parseInt(hours, 10) * 3600000 + Number.parseInt(minutes, 10) * 60000;
}

const TourContentDurationForm = (props) => {
  const {
    localTourContent,
    handleToggleVisibility,
    handleDelete,
    setLocalTourContent,
  } = props;
  const {
    attributes: {
      'arrival-time': receivedArrivalTime,
      duration: receivedDuration,
    } = {},
  } = localTourContent;

  const receivedArrivalTime24HoursFormat = convertMillisecondsTo24HoursFormat(receivedArrivalTime);
  const [receivedDurationHours, receivedDurationMinutes] = convertMillisecondsToHoursAndMinutes(
    receivedDuration,
  );

  const [arrivalTimeFormatted, setArrivalTimeFormatted] = useState(receivedArrivalTime24HoursFormat);
  const [durationHours, setDurationHours] = useState(`${receivedDurationHours}`);
  const [durationMins, setDurationMins] = useState(`${receivedDurationMinutes}`);
  useEffect(() => {
    const [hours, mins] = convertMillisecondsToHoursAndMinutes(receivedDuration);
    setDurationHours(hours);
    setDurationMins(mins);
  }, [receivedDuration]);

  const handleArrivalTimeFormattedChange = (e) => {
    const { value } = e.target;
    setArrivalTimeFormatted(value);
    const immutableTourContent = _cloneDeep(localTourContent);
    const newValue = convert24hoursFormatToMilliseconds(value);
    immutableTourContent.attributes['arrival-time'] = newValue;
    setLocalTourContent(immutableTourContent);
  };
  const handleDurationHourChange = (e) => {
    const { value } = e.target;
    setDurationHours(value);
    const immutableTourContent = _cloneDeep(localTourContent);
    const newValue = convertHoursAndMinutesToMilliseconds([value, durationMins]);
    immutableTourContent.attributes.duration = newValue;
    setLocalTourContent(immutableTourContent);
  };
  const handleDurationMinsChange = (e) => {
    const { value } = e.target;
    setDurationMins(value);
    const immutableTourContent = _cloneDeep(localTourContent);
    const newValue = convertHoursAndMinutesToMilliseconds([durationHours, value]);
    immutableTourContent.attributes.duration = newValue;
    setLocalTourContent(immutableTourContent);
  };

  return (
    <TourContentDurationFormTemplate
      localTourContent={localTourContent}
      handleToggleVisibility={handleToggleVisibility}
      handleDelete={handleDelete}
      arrivalTimeFormatted={arrivalTimeFormatted}
      durationHours={durationHours}
      durationMins={durationMins}
      handleDurationMinsChange={handleDurationMinsChange}
      handleDurationHourChange={handleDurationHourChange}
      handleArrivalTimeFormattedChange={handleArrivalTimeFormattedChange}
    />
  );
};

TourContentDurationForm.propTypes = {
  localTourContent: TYPES.tourContentType.isRequired,
  setLocalTourContent: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
TourContentDurationForm.defaultProps = {};

export default TourContentDurationForm;
