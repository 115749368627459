export function lockBodyScrolling() {
  const appWrapper = document.getElementById('appWrapper');
  // Get scroll position to be able to restore it in onCloseModal
  const scrollPos = window.scrollY || 0;
  appWrapper.style.position = 'fixed';
  appWrapper.style.top = `-${scrollPos}px`;
  appWrapper.style.left = 0;
  appWrapper.style.right = 0;
  appWrapper.style.zIndex = '1';
}

export function restoreBodyScrolling() {
  const appWrapper = document.getElementById('appWrapper');
  const bodyScrollPos = appWrapper.style.getPropertyValue('top');
  appWrapper.style.removeProperty('position');
  appWrapper.style.removeProperty('top');
  appWrapper.style.removeProperty('left');
  appWrapper.style.removeProperty('right');
  appWrapper.style.removeProperty('z-index');
  const scrollY = parseInt(bodyScrollPos.slice(1, -2), 10) || document.body.scrollTop;
  window.scrollTo(0, scrollY);
}
