import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { sortableContainer, sortableElement, SortableHandle } from 'react-sortable-hoc';
import ReactTooltip from 'react-tooltip';
import { FineUploader, ResponsiveImg, PromiseButton } from '../../../../../../components';
import {
  PLUS_ICON,
  SERVICE_ICON_URL,
  INFO_CIRCLE_ICON,
  CLOSE_ICON,
} from '../../../../../../helpers/svg-inline';
import { isEmpty, getImageSrcSet } from '../../../../../../utils';
import imageType from '../../../../../../constants/types/image-type';
import TYPES from '../../../../../../constants/types';

const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];

const SortableContainer = sortableContainer(({ children }) => (
  <ul className="tour-content-recommendation-form__photos-list">{children}</ul>
));

const SortableItem = sortableElement(({ children }) => (
  <li className="tour-content-recommendation-form__photos-item">{children}</li>
));

const DragHandle = SortableHandle(({ children }) => <>{children}</>);

const TourContentRecommendationFormTemplate = (props) => {
  const {
    handleSave,
    handleInput,
    handlePersonalOpinion,
    handleToggleVisibility,
    handleDelete,
    coverImage,
    serviceName,
    personalOpinion,
    affiliateLink,
    affiliateTitle,
    affiliateText,
    videoUrl,
    tourDisplayMode,
    recommendationDisplayMode,
    uploaderParams,
    handleOnImageUpload,
    handleDeleteImage,
    handleOnDragEnd,
    orderedImages,
    visible,
    isMobile,
  } = props;

  const sortTrigger = {
    ...(!isMobile && { distance: 1 }),
    ...(isMobile && { pressDelay: 100 }),
  };

  return (
    <section className="container-fluid tour-content-recommendation-form">
      <div className="row">
        {isMobile ? (
          <div className="col-12">
            <h3 className="tour-content-recommendation-form__title">Editar recomendación</h3>
          </div>
        ) : null}
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <h4 className="tour-content-recommendation-form__service-name">
                {serviceName}
              </h4>
            </div>
            <div className="col-auto">
              {isEmpty(coverImage) ? (
                <figure
                  className="tour-content-recommendation-form__cover tour-content-recommendation-form__cover--no-image"
                >
                  <img
                    src={SERVICE_ICON_URL}
                    alt="Servicio"
                    className="tour-content-recommendation-form__cover-img"
                  />
                  <p className="mb-0">No has añadido ninguna imagen</p>
                </figure>
              ) : (
                <img
                  className="tour-content-recommendation-form__cover"
                  src={coverImage.attributes['small-thumb']}
                  alt="Portada"
                />
              )}
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-primary btn-block mt-3"
                onClick={() => handleToggleVisibility()}
              >
                {`${visible ? 'Ocultar' : 'Mostrar'} en la ruta`}
              </button>
              <PromiseButton
                type="button"
                className="btn btn-danger btn-block"
                onClick={handleDelete}
              >
                Eliminar
              </PromiseButton>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <div className="row">
            <div className="col-12">
              <h4 className="tour-content-recommendation-form__label">
                <span
                  data-tip="<p class='data-tip'>Si quieres darle mayor importancia a la recomendación
                   puedes hacer que aparezca insertada en tu itinerario. Por ejemplo si quieres
                   recomendar un restaurante podrás mostrarlo en el momento de la ruta en el que
                   disfrutar de ese servicio.</p><p class='data-tip'>Próximamente, los usuarios premium
                   podrán además seleccionar si la visualización del mismo es en modo simple o mucho
                   más vistoso gráficamente (Enriquecido). Si sólo quieres que se muestre en el apartado
                   &quot;recomendaciones&quot; y no en el itinerario selecciona la opción “No mostrar en la ruta”.</p>"
                >
                  {INFO_CIRCLE_ICON}
                </span>
                Presentación en la ruta:
              </h4>
              <div className="row">
                <div className="col-12 d-lg-flex justify-content-lg-start">
                  <label htmlFor="tourDisplayMode0" className="custom-checkbox right">
                    <span>Modo Simple</span>
                    <input
                      type="radio"
                      value="0"
                      id="tourDisplayMode0"
                      name="tourDisplayMode"
                      onChange={handleInput}
                      checked={tourDisplayMode === '0'}
                      className="tour-content-recommendation-form__option"
                    />
                    <i />
                  </label>
                  <label htmlFor="tourDisplayMode1" className="custom-checkbox right">
                    <span>
                      Modo Enriquecido
                      <small className="tour-content-recommendation-form__tag">Premium</small>
                    </span>

                    <input
                      type="radio"
                      value="1"
                      id="tourDisplayMode1"
                      name="tourDisplayMode"
                      onChange={handleInput}
                      checked={tourDisplayMode === '1'}
                      className="tour-content-recommendation-form__option"
                    />
                    <i />
                  </label>
                  <label htmlFor="tourDisplayMode2" className="custom-checkbox right">
                    <span>No mostrar en la ruta</span>
                    <input
                      type="radio"
                      value="2"
                      id="tourDisplayMode2"
                      name="tourDisplayMode"
                      onChange={handleInput}
                      checked={tourDisplayMode === '2'}
                      className="tour-content-recommendation-form__option"
                    />
                    <i />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12">
              <h4 className="tour-content-recommendation-form__label">
                <span
                  data-tip="<p class='data-tip'>Al igual que en el apartado anterior,
                   los usuarios premium podrán seleccionar cómo mostrar visualmente su
                   recomendación, si más o menos destacada. Los usuarios gratuitos la
                   mostrarán siempre en modo 'simple'.</p>"
                >
                  {INFO_CIRCLE_ICON}
                </span>
                Presentación en recomendaciones:
              </h4>
              <div className="row">
                <div className="col d-flex justify-content-start">
                  <label htmlFor="recommendationDisplayMode0" className="custom-checkbox right">
                    <span>Modo Simple</span>
                    <input
                      type="radio"
                      value="0"
                      id="recommendationDisplayMode0"
                      name="recommendationDisplayMode"
                      onChange={handleInput}
                      checked={recommendationDisplayMode === '0'}
                      className="tour-content-recommendation-form__option"
                    />
                    <i />
                  </label>
                  <label htmlFor="recommendationDisplayMode1" className="custom-checkbox right">
                    <span>
                      Modo Enriquecido
                      <small className="tour-content-recommendation-form__tag">Premium</small>
                    </span>
                    <input
                      type="radio"
                      value="1"
                      id="recommendationDisplayMode1"
                      name="recommendationDisplayMode"
                      onChange={handleInput}
                      checked={recommendationDisplayMode === '1'}
                      className="tour-content-recommendation-form__option"
                    />
                    <i />
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 mb-3">
              <label
                htmlFor="tour-content-text"
                className="tour-content-recommendation-form__label"
              >
                Opinion personal:
              </label>
              <ReactQuill
                id="tour-content-text"
                type="text"
                value={personalOpinion || ''}
                onChange={handlePersonalOpinion}
                className="tour-content-recommendation-form__rich-text-editor"
                modules={MODULES}
                formats={FORMATS}
              />
            </div>
            <div className="col-12 mb-3">
              <label
                htmlFor="tour-content-affiliate-link"
                className="tour-content-recommendation-form__label mb-0"
              >
                <span data-tip="<p class='data-tip'>Numerosas empresas ofrecen planes de
                 afiliados que te pagarán una interesante comisión cuando alguien reserva
                 desde tu recomendación. Actividades y alojamientos son los más comunes.
                 Simplemente tienes que incorporar en este campo el enlace correspondiente
                 y ganarás el 100% de las comisiones que generes. Si quieres saber más
                 consulta el apartado de “Enlaces de reservas” en nuestras FAQs.</p>
                 <p class='data-tip'>Alojamiento: Booking</p>
                 <p class='data-tip'>Restaurantes: El Tenedor</p>
                 <p class='data-tip'>Actividades: Getyourguide, civitatis</p>
                 <p class='data-tip'>Compras: Amazon</p>"
                >
                  {INFO_CIRCLE_ICON}
                </span>
                Enlace para reservas:
              </label>
              <p className="mb-1">
                Utiliza tu ID de afiliado y gana el 100% de la comisiones con ellas
              </p>
              <input
                id="tour-content-affiliate-link"
                type="text"
                value={affiliateLink || ''}
                onChange={handleInput}
                name="affiliate-link"
                className="form-control"
              />
            </div>
            <div className="col-12 mb-3">
              <label
                htmlFor="tour-content-affiliate-title"
                className="tour-content-recommendation-form__label mb-0"
              >
                Título para reservas:
              </label>
              <textarea
                id="tour-content-affiliate-title"
                value={affiliateTitle || ''}
                onChange={handleInput}
                name="affiliate-title"
                className="form-control"
                rows="2"
                maxLength="255"
              />
            </div>
            <div className="col-12 mb-3">
              <label
                htmlFor="tour-content-affiliate-text"
                className="tour-content-recommendation-form__label mb-0"
              >
                Texto para reservas:
              </label>
              <textarea
                id="tour-content-affiliate-text"
                value={affiliateText || ''}
                onChange={handleInput}
                name="affiliate-text"
                className="form-control"
                rows="2"
                maxLength="255"
              />
            </div>
            <div className="col-12 mb-3">
              <label
                htmlFor="tour-content-video"
                className="tour-content-recommendation-form__label mb-0"
              >
                Insertar video personal:
              </label>
              <p className="mb-1">(copie su dirección de youtube o vimeo)</p>
              <input
                id="tour-content-video"
                type="text"
                value={videoUrl || ''}
                onChange={handleInput}
                name="video-url"
                className="form-control"
              />
            </div>
            <div className="col-12">
              <h4 className="tour-content-recommendation-form__label mb-2">
                Imágenes:
                <small className="d-block">
                  Puedes arrastrar las miniaturas para cambiar su orden.
                </small>
              </h4>

              <SortableContainer
                onSortEnd={handleOnDragEnd}
                axis="xy"
                lockToContainerEdges
                useDragHandle
                {...sortTrigger}
              >
                {isEmpty(orderedImages)
                  ? null
                  : orderedImages.map((image, idx) => (
                    <SortableItem key={`service-image-${image.id}`} index={idx}>
                      <DragHandle>
                        <ResponsiveImg
                          className="tour-content-recommendation-form__photos-img"
                          srcSet={getImageSrcSet(image)}
                        >
                          <PromiseButton
                            type="button"
                            className="tour-content-recommendation-form__photos-remove"
                            onClick={() => handleDeleteImage(image)}
                            title="Eliminar imagen"
                            primarySpinner
                          >
                            {CLOSE_ICON}
                          </PromiseButton>
                        </ResponsiveImg>
                      </DragHandle>
                    </SortableItem>
                  ))}
                <li
                  className="tour-content-recommendation-form__photos-item"
                  key="service-image-upload"
                >
                  <FineUploader
                    endpoint="/images"
                    autoUpload
                    params={uploaderParams}
                    onComplete={handleOnImageUpload || null}
                    withProgressBars
                    withTotalProgressBar
                    isDropzone={false}
                    removeThumbAfter={800}
                    onBeforeUpload={() => handleSave(true)}
                  >
                    <button
                      type="button"
                      className="tour-content-modal__add-btn"
                    >
                      {PLUS_ICON}
                    </button>
                  </FineUploader>
                </li>
              </SortableContainer>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip effect="solid" html />
    </section>
  );
};

TourContentRecommendationFormTemplate.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  handlePersonalOpinion: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleOnImageUpload: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  handleOnDragEnd: PropTypes.func.isRequired,
  serviceName: PropTypes.string.isRequired,
  personalOpinion: PropTypes.string,
  affiliateLink: PropTypes.string,
  affiliateTitle: PropTypes.string,
  affiliateText: PropTypes.string,
  videoUrl: PropTypes.string,
  visible: PropTypes.bool,
  tourDisplayMode: PropTypes.string,
  recommendationDisplayMode: PropTypes.string,
  uploaderParams: PropTypes.shape({
    imageableId: PropTypes.string.isRequired,
    imageableType: PropTypes.string.isRequired,
    imageableProperty: PropTypes.string.isRequired,
  }).isRequired,
  orderedImages: PropTypes.arrayOf(imageType),
  coverImage: TYPES.imageType,
  isMobile: PropTypes.bool,
};
TourContentRecommendationFormTemplate.defaultProps = {
  affiliateLink: null,
  affiliateTitle: null,
  affiliateText: null,
  videoUrl: null,
  personalOpinion: null,
  tourDisplayMode: '0',
  recommendationDisplayMode: '0',
  orderedImages: [],
  coverImage: null,
  visible: false,
  isMobile: false,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(TourContentRecommendationFormTemplate);
