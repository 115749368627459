import React, { useState, useCallback, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-router';
import TYPES from '../../../../../../constants/types';
import TourContentTipBoxTemplate from './tour-content-tip-box-template';
import useGetResource from '../../../../../../helpers/hooks/useGetResource';
import { GOOGLE_MARKER } from '../../../../../../utils/google-maps-utils';
import { isEmpty } from '../../../../../../utils';

const TourContentTipBox = (props) => {
  const { tourContent } = props;
  const tourContentImages = useGetResource({ id: tourContent.id }, 'images', 'getTourContentArchives');
  const [carouselPosition, setCarouselPosition] = useState(0);
  const [mapInstance, setMapInstance] = useState(null);
  const [areTilesLoaded, setAreTilesLoaded] = useState(0);

  const handleCarouselChange = useCallback((index) => {
    setCarouselPosition(index);
  }, []);

  const handleMapOnMount = useCallback((map) => {
    setMapInstance(map);
  });

  const initializeMapBounds = useCallback(() => {
    setAreTilesLoaded(areTilesLoaded + 1);
  });

  useEffect(() => {
    if (!isEmpty(mapInstance) && !isEmpty(tourContent) && areTilesLoaded === 1) {
      const { attributes: { lat = null, long: lng = null } = {} } = tourContent;
      const bounds = new window.google.maps.LatLngBounds();
      const mapMarker = new window.google.maps.Marker({
        position: { lat, lng },
        map: mapInstance,
        icon: GOOGLE_MARKER(),
        clickable: false,
      });
      bounds.extend(mapMarker.getPosition());
      mapInstance.fitBounds(bounds, 5);
      mapInstance.setZoom(10);
    }
  }, [mapInstance, areTilesLoaded, tourContent]);

  return (
    <TourContentTipBoxTemplate
      tourContent={tourContent}
      tourContentImages={tourContentImages}
      carouselPosition={carouselPosition}
      handleCarouselChange={handleCarouselChange}
      handleMapOnMount={handleMapOnMount}
      initializeMapBounds={initializeMapBounds}
    />
  );
};

TourContentTipBox.propTypes = {
  tourContent: TYPES.tourContentType.isRequired,
};

export default TourContentTipBox;
