import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MainLayout from '../../layouts/main-layout';
import { FAQ_URL } from '../../constants';
import { isEmpty } from '../../utils';
import {
  HOME_BACK_ICON, DESTINY_BLUE, ROUTE_BLUE, RECOMMENDATION_BLUE, TOPDESTI_LOGO_CIRCLE, MAKE_MONEY_CIRCLE,
} from '../../helpers';
import FAQS_QUESTIONS from './faqs-page-questions';

const ABOUT_ICON = (
  <span
    className="faqs-page__topic-icon"
  >
    {TOPDESTI_LOGO_CIRCLE}
  </span>
);

const TOURS_ICON = (
  <span
    className="faqs-page__topic-icon"
  >
    {ROUTE_BLUE}
  </span>
);

const PLACES_ICON = (
  <span
    className="faqs-page__topic-icon"
  >
    {DESTINY_BLUE}
  </span>
);

const SERVICES_ICON = (
  <span
    className="faqs-page__topic-icon"
  >
    {RECOMMENDATION_BLUE}
  </span>
);

const MONEY_ICON = (
  <span
    className="faqs-page__topic-icon"
  >
    {MAKE_MONEY_CIRCLE}
  </span>
);

const TOPICS = [
  {
    title: 'Sobre Topdesti',
    icon: ABOUT_ICON,
  },
  {
    title: 'Rutas',
    icon: TOURS_ICON,
  },
  {
    title: 'Destinos',
    icon: PLACES_ICON,
  },
  {
    title: 'Servicios y recomendaciones',
    icon: SERVICES_ICON,
  },
  {
    title: 'Ganar dinero',
    icon: MONEY_ICON,
  },
];

const FaqsPageTemplate = (props) => {
  const {
    topic, question, handleVisibleQuestion, handleGoBack, isMobile,
  } = props;

  return (
    <MainLayout>
      <main className="container-fluid faqs-page">
        <header className="row faqs-page__header">
          <div className="container">
            <div className="row faqs-page__header-top">
              <div className="col-12 px-0 px-lg-3">
                <h3 className="faqs-page__header-title">
                  {isMobile ? (
                    <>
                      {isEmpty(topic) && isEmpty(question) ? null : (
                        <button
                          type="button"
                          className="faqs-page__header-back-btn"
                          onClick={handleGoBack}
                        >
                          {HOME_BACK_ICON}
                        </button>
                      )}
                    </>
                  ) : null}
                  Preguntas frecuentes
                </h3>
              </div>
            </div>
            {!isMobile || isEmpty(topic) ? (
              <div className="row faqs-page__header-bottom">
                <div className="col-12">
                  <nav className="faqs-page__topic-tabs">
                    <ul className="faqs-page__topic-tabs-list">
                      <li className="faqs-page__topic-tabs-item mb-3 mb-lg-0">
                        <Link
                          className={`
                      faqs-page__topic-link ${topic === 0 ? 'faqs-page__topic-link--active' : ''}
                    `}
                          to={{
                            pathname: FAQ_URL,
                            search: '?t=0',
                          }}
                        >
                          {isMobile ? ABOUT_ICON : null}
                          Sobre Topdesti
                        </Link>
                      </li>
                      <li className="faqs-page__topic-tabs-item mb-3 mb-lg-0">
                        <Link
                          className={`
                      faqs-page__topic-link ${topic === 1 ? 'faqs-page__topic-link--active' : ''}
                    `}
                          to={{
                            pathname: FAQ_URL,
                            search: '?t=1',
                          }}
                        >
                          {isMobile ? TOURS_ICON : null}
                          Rutas
                        </Link>
                      </li>
                      <li className="faqs-page__topic-tabs-item mb-3 mb-lg-0">
                        <Link
                          className={`
                      faqs-page__topic-link ${topic === 2 ? 'faqs-page__topic-link--active' : ''}
                    `}
                          to={{
                            pathname: FAQ_URL,
                            search: '?t=2',
                          }}
                        >
                          {isMobile
                            ? PLACES_ICON : null}
                          Destinos
                        </Link>
                      </li>
                      <li className="faqs-page__topic-tabs-item">
                        <Link
                          className={`
                      faqs-page__topic-link ${topic === 3 ? 'faqs-page__topic-link--active' : ''}
                    `}
                          to={{
                            pathname: FAQ_URL,
                            search: '?t=3',
                          }}
                        >
                          {isMobile ? SERVICES_ICON : null}
                          Servicios y recomendaciones
                        </Link>
                      </li>
                      <li className="faqs-page__topic-tabs-item">
                        <Link
                          className={`
                          faqs-page__topic-link ${topic === 4 ? 'faqs-page__topic-link--active' : ''}
                        `}
                          to={{
                            pathname: FAQ_URL,
                            search: '?t=4',
                          }}
                        >
                          {isMobile ? MONEY_ICON : null}
                          Ganar dinero
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            ) : null }
          </div>
        </header>
        <section className="row">
          <div className="container faqs-page__content">
            <div className="row">
              <aside className="col-12 col-lg-auto">
                {isEmpty(topic) || (isMobile && !isEmpty(question)) ? null : (
                  <>
                    {isMobile ? (
                      <header className="faqs-page__questions-header">
                        {TOPICS[topic].icon}
                        <h4 className="faqs-page__questions-title">{TOPICS[topic].title}</h4>
                      </header>
                    ) : null}
                    <ul className="faqs-page__questions">
                      {FAQS_QUESTIONS[topic].map((questionItem, idx) => (

                        <li
                          className={`
                            faqs-page__questions-item
                            ${question === idx ? 'faqs-page__questions-item--active' : ''}
                          `}
                          // eslint-disable-next-line react/no-array-index-key
                          key={`question-${idx}`}
                        >
                          <button
                            type="button"
                            className="faqs-page__questions-btn"
                            onClick={() => handleVisibleQuestion(idx)}
                          >
                            {questionItem.title}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </aside>
              {isEmpty(topic) || isEmpty(question)
                ? null
                : (
                  <div className="col">
                    {FAQS_QUESTIONS[topic][question].content}
                  </div>
                )}
            </div>
          </div>
        </section>
      </main>
    </MainLayout>
  );
};

FaqsPageTemplate.propTypes = {
  topic: PropTypes.number,
  question: PropTypes.number,
  handleVisibleQuestion: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

FaqsPageTemplate.defaultProps = {
  topic: 1,
  question: 1,
  isMobile: false,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(FaqsPageTemplate);
