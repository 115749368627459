import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, getImageSrcSet } from '../../../../../../../../utils';
import ResponsiveImg from '../../../../../../../responsive-img';
import TYPES from '../../../../../../../../constants/types';
import { CLOSE_ICON } from '../../../../../../../../helpers';

const RecommendationImagesBoxTemplate = (props) => {
  const { images, title, handleShowImages } = props;

  const recommendationImagesRef = useRef();

  const [isClosing, setIsClosing] = useState(false);
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      handleShowImages(false);
    }, 450);
  };

  useEffect(() => {
    const scrollPosition = recommendationImagesRef.current.offsetTop - 150;
    window.scrollTo(0, scrollPosition);
  }, []);

  return isEmpty(images) ? null : (
    <article
      ref={recommendationImagesRef}
      className={`container recommendation-images-box swing-in-top-fwd ${
        isClosing ? 'swing-out-top-bck' : ''
      }`}
    >
      <header className="row recommendation-images-box__header">
        <div className="col pr-0">
          <h4 className="recommendation-images-box__title">{`Mis fotos en ${title}`}</h4>
        </div>
        <div className="col-auto">
          <button
            type="button"
            onClick={handleClose}
            className="recommendation-images-box__header-btn"
            title="Cerrar Mis Fotos"
          >
            {CLOSE_ICON}
          </button>
        </div>
      </header>
      <section className="row recommendation-images-box__content">
        {images.map(image => (
          <ResponsiveImg
            key={`recommendation-image-${image.id}`}
            wrapperClassName="col-12 recommendation-images-box__image-wrapper"
            className="recommendation-images-box__image"
            srcSet={getImageSrcSet(image)}
          />
        ))}
      </section>
      <footer className="row recommendation-images-box__footer">
        <div className="col">
          <button
            type="button"
            className="btn btn-primary recommendation-images-box__footer-btn"
            onClick={handleClose}
            title="Cerrar Mis Fotos"
          >
            cerrar fotos
          </button>
        </div>
      </footer>
    </article>
  );
};

RecommendationImagesBoxTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(TYPES.imageType),
  handleShowImages: PropTypes.func.isRequired,
};

RecommendationImagesBoxTemplate.defaultProps = {
  images: [],
};

export default RecommendationImagesBoxTemplate;
