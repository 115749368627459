import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/sticky-content-wrapper.scss';

function StickyContentWrapper(props) {
  const { children, modifiers, hideIfNotStuck } = props;

  let finalClassName = 'sticky-wrapper';
  const isStuck = modifiers.length && modifiers.length > 0;

  if (!isStuck && hideIfNotStuck) return null;

  if (isStuck) {
    finalClassName = modifiers.length
      ? modifiers.reduce(
        (finalClass, currModifier) => `${finalClass} ${finalClass}--${currModifier}`,
        'sticky-wrapper',
      )
      : 'sticky-wrapper';
    finalClassName += ' sticky-wrapper--stuck';
  }

  return <div className={finalClassName}>{children}</div>;
}

StickyContentWrapper.propTypes = {
  modifiers: PropTypes.oneOfType([PropTypes.array]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  hideIfNotStuck: PropTypes.bool,
};
StickyContentWrapper.defaultProps = {
  modifiers: [],
  children: null,
  hideIfNotStuck: false,
};

export default StickyContentWrapper;
