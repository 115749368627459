import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sortableContainer, sortableElement, SortableHandle } from 'react-sortable-hoc';
import TYPES from '../../constants/types';
import FineUploader from '../fine-uploader/fine-uploader';
import ResponsiveImg from '../responsive-img';
import { isEmpty, getImageSrcSet } from '../../utils';
import ReactQuill from 'react-quill';
import {
  UPLOAD_CLOUD_URL,
  DROP_COLOR_URL,
  LEFT_ARROW_ANGLE_ICON,
  GALLERY_BLUE,
  DRAG_ICON,
  POINTS_CROSS_ICON,
  CHECK_ICON,
} from '../../helpers';

const SortableContainer = sortableContainer(({ children }) => (
  <div className="row images-edition__gallery">{children}</div>
));

const SortableItem = sortableElement(({ children }) => <>{children}</>);

const DragHandle = SortableHandle(({ children }) => <>{children}</>);
const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];

const ImagesEditionTemplate = (props) => {
  const {
    images,
    uploaderParams,
    onImageUploaded,
    isDraggingOver,
    setIsDraggingOver,
    imageInEdition,
    setImageInEdition,
    handleImageChange,
    handleImagesSave,
    handleDeleteImage,
    handleOnDragEnd,
    isMobile,
  } = props;

  const sortTrigger = {
    ...(!isMobile && { distance: 1 }),
    ...(isMobile && { pressDelay: 100 }),
  };

  function handleTextChange(value) {
    // the empty editor value is '<p><br></p>'
    // before setting the state, check if editor is empty and convert it to empty string if it is
    const richText = value === '<p><br></p>' ? '' : value;
    imageInEdition.attributes.description = richText;
    // const updatedTour = _cloneDeep(localTour);
    // updatedTour.attributes.description = richText;

    // setLocalTour(updatedTour);
  }
  return (
    <section className="container-fluid images-edition">
      {isEmpty(imageInEdition) ? (
        <FineUploader
          key={uploaderParams.imageableId}
          endpoint="/images"
          autoUpload
          params={uploaderParams}
          onComplete={onImageUploaded || null}
          withProgressBars
          withTotalProgressBar
          isDropzone={!isMobile}
          withUploadBtn
          removeThumbAfter={800}
        >
          {isEmpty(images) ? (
            <div
              className={`images-edition__dropzone ${isEmpty(images) ? 'images-edition__dropzone--no-images' : ''
                }`}
              onDragStart={() => setIsDraggingOver(true)}
              onDragOver={() => setIsDraggingOver(false)}
            >
              {!isMobile && isDraggingOver ? (
                <img
                  src={DROP_COLOR_URL}
                  alt="Sube tus imágenes"
                  className="images-edition__dropzone-img"
                />
              ) : (
                  <>
                    <img
                      src={UPLOAD_CLOUD_URL}
                      alt="Sube tus imágenes"
                      className="images-edition__dropzone-img"
                    />
                    {isMobile ? null : (
                      <p className="images-edition__dropzone-text">Arrastra aquí tus imágenes</p>
                    )}
                    <div className="row justify-content-center my-5">
                      <div className="col-auto images-edition__cross-icon">{POINTS_CROSS_ICON}</div>
                      <div className="col-auto pl-0">
                        <p className="text-danger mb-0">No imágenes sin la propiedad intelectual</p>
                      </div>
                    </div>
                  </>
                )}
            </div>
          ) : (
              <>
                <div className="row images-edition__header">
                  {isMobile ? null : (
                    <>
                      <div className="col text-center">
                        <span className="images-edition__header-icon">{DRAG_ICON}</span>
                        <p className="images-edition__header-text">
                          Puedes colocarías en el orden inicial que quieras, y la primera será la
                          portada
                      </p>
                      </div>
                    </>
                  )}
                  <div className="col-12">
                    <h4 className="images-edition__header-title">
                      Haz click en la foto para editar su título y descripción
                  </h4>
                  </div>
                </div>
                <SortableContainer
                  onSortEnd={handleOnDragEnd}
                  axis="xy"
                  lockToContainerEdges
                  useDragHandle
                  {...sortTrigger}
                >
                  {images.map((image, idx) => (
                    <SortableItem key={`service-image-${image.id}`} index={idx}>
                      <button
                        type="button"
                        className="col-4 col-md-3 col-xl-2 images-edition__gallery-btn"
                        onClick={() => setImageInEdition(image)}
                        key={`image-edition-gallery-${image.id}`}
                      >
                        <DragHandle>
                          <ResponsiveImg
                            srcSet={getImageSrcSet(image)}
                            className="images-edition__gallery-img"
                          />
                        </DragHandle>
                      </button>
                    </SortableItem>
                  ))}
                </SortableContainer>
              </>
            )}
        </FineUploader>
      ) : (
          <div className="images-edition__form">
            <button
              type="button"
              className="btn btn-link images-edition__back-btn"
              onClick={() => setImageInEdition(null)}
            >
              {LEFT_ARROW_ANGLE_ICON}
              {GALLERY_BLUE}
            </button>
            <div className="row images-edition__form-data">
              <div className="col-auto">
                <ResponsiveImg
                  srcSet={getImageSrcSet(imageInEdition)}
                  className="images-edition__form-img"
                />
              </div>
              <div className="col-12 mb-2">
                <label htmlFor="image-name" className="images-edition__form-label">
                  Título:
              </label>
                <input
                  type="text"
                  className="form-control"
                  id="image-name"
                  name="name"
                  onChange={e => handleImageChange(e, imageInEdition)}
                  value={imageInEdition.attributes.name || ''}
                />
              </div>
              <div className="col-12">
                <label htmlFor="image-description" className="images-edition__form-label">
                  Descripción:
              </label>
                <ReactQuill
                  id="image-description"
                  value={imageInEdition.attributes.description || ''}
                  // onChange={handleTextChange}
                  onChange={e => handleImageChange(e, imageInEdition)}
                  className="tour-config-main__rich-text-editor"
                  modules={MODULES}
                  formats={FORMATS}
                />
              </div>
            </div>
            <footer className="row justify-content-end mt-5">
              <div className="position">
                <button
                  type="button"
                  className="btn btn-block btn-primary save"
                  onClick={() => handleImagesSave(imageInEdition)}
                >
                  {CHECK_ICON}
                  {/* guardar */}
                </button>
              </div>
              <div className="col col-md-4 col-lg-3 pl-1">
                <button
                  type="button"
                  className="btn btn-block btn-danger"
                  onClick={() => handleDeleteImage(imageInEdition)}
                >
                  eliminar imagen
              </button>
              </div>
            </footer>
          </div>
        )}
    </section>
  );
};

ImagesEditionTemplate.propTypes = {
  images: PropTypes.arrayOf(TYPES.imageType),
  uploaderParams: PropTypes.shape({
    imageableId: PropTypes.string,
    imageableType: PropTypes.string.isRequired,
    imageableProperty: PropTypes.string.isRequired,
  }).isRequired,
  onImageUploaded: PropTypes.func,
  isDraggingOver: PropTypes.bool.isRequired,
  imageInEdition: TYPES.imageType,
  setImageInEdition: PropTypes.func.isRequired,
  setIsDraggingOver: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleImagesSave: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  handleOnDragEnd: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

ImagesEditionTemplate.defaultProps = {
  images: [],
  onImageUploaded: null,
  imageInEdition: null,
  isMobile: false,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(ImagesEditionTemplate);
