import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, getImageSrcSet } from '../../../../../utils';
import TYPES from '../../../../../constants/types';
import {
  EYE_ICON, CLOSE_ICON, EYE_NOT_ICON, PLUS_ICON,
} from '../../../../../helpers/svg-inline';
import { ResponsiveImg, PromiseButton } from '../../../../../components';

const TourConfigShopTemplate = (props) => {
  const {
    products,
    handleContentSelection,
    productCovers,
    handleToggleVisibility,
    handleDelete,
    handleCreateNewProduct,
    handleShopVisibility,
    shopIsHidden,
  } = props;

  return (
    <section className="row tour-config-shop">
      <div className="col-12">
        <div className="row">
          <div className="col">
            <div className="row mb-2">
              <div className="col">
                <h4>Productos a la venta:</h4>
              </div>
              <div className="col-auto">
                <PromiseButton
                  type="button"
                  className={`btn btn-block ${shopIsHidden ? 'btn-outline-dark' : 'btn-primary'}`}
                  onClick={() => handleShopVisibility()}
                >
                  {shopIsHidden ? 'Tienda oculta' : 'Tienda visible'}
                </PromiseButton>
              </div>
            </div>
            <div className="col-12 col-lg-10 col-m-10 mx-0 px-0">
              <p className="mb-0">Pulsa en el producto para editarlo o arrastrarlo para cambiar su orden.</p>
              <p>
                <strong>
                  Los cambios que realices en la tienda se verán reflejados en
                  todas tus rutas excepto si quieres ocultarla completamente
              </strong>
              </p>
            </div>

          </div>
          <div>
            {/* <PromiseButton
              type="button"
              className={`btn btn-block ${shopIsHidden ? 'btn-outline-dark' : 'btn-primary'}`}
              onClick={() => handleShopVisibility()}
            >
              {shopIsHidden ? 'Tienda oculta' : 'Tienda visible'}
            </PromiseButton> */}
          </div>
        </div>
      </div>
      <div className="col-12">
        <ul className="tour-config-shop__list">
          {isEmpty(products)
            ? null
            : products.map((product) => {
              const {
                relationships: {
                  images: { data: productImagesData },
                },
              } = product;
              const coverImage = !isEmpty(productImagesData) && !isEmpty(productCovers)
                ? productImagesData.reduce((acc, imageData) => {
                  const cover = productCovers.filter(
                    productCover => productCover.id === imageData.id,
                  )[0];
                  if (!isEmpty(cover)) return cover;
                  return acc;
                }, {})
                : null;
              return (
                <li
                  key={`image-tour-content-${product.id}`}
                  className="tour-config-shop__list-item"
                >
                  <PromiseButton
                    type="button"
                    className="tour-config-shop__list-show"
                    onClick={() => handleToggleVisibility(product)}
                    title="Ocultar producto"
                    primarySpinner
                  >
                    {EYE_ICON}
                  </PromiseButton>
                  <PromiseButton
                    type="button"
                    className="tour-config-shop__list-remove"
                    onClick={() => handleDelete(product)}
                    title="Eliminar producto"
                    primarySpinner
                  >
                    {CLOSE_ICON}
                  </PromiseButton>
                  <button
                    type="button"
                    className="tour-config-shop__list-btn"
                    onClick={() => handleContentSelection(product)}
                  >
                    <ResponsiveImg
                      srcSet={getImageSrcSet(coverImage)}
                      alt="Imagen"
                      wrapperClassName="h-100"
                      className="tour-config-shop__list-img"
                    />
                    {product.attributes.visible ? null : (
                      <div className="tour-config-shop__no-visible-layer">{EYE_NOT_ICON}</div>
                    )}
                  </button>
                </li>
              );
            })}
          <li className="tour-config-shop__list-item">
            <button
              type="button"
              className="tour-config-shop__list-add"
              onClick={handleCreateNewProduct}
            >
              {PLUS_ICON}
            </button>
          </li>
        </ul>
      </div>
    </section>
  );
};

TourConfigShopTemplate.propTypes = {
  products: PropTypes.arrayOf(TYPES.productType),
  productCovers: PropTypes.arrayOf(TYPES.imageType),
  handleContentSelection: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleCreateNewProduct: PropTypes.func.isRequired,
  handleShopVisibility: PropTypes.func.isRequired,
  shopIsHidden: PropTypes.bool,
};

TourConfigShopTemplate.defaultProps = {
  products: [],
  productCovers: [],
  shopIsHidden: false,
};

export default TourConfigShopTemplate;
