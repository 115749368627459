import React from 'react';
import TYPES from '../../../../../constants/types';
import { isEmpty, formattedDuration } from '../../../../../utils';
import { CLOCK_ICON } from '../../../../../helpers';

function TourContentTimeBox(props) {
  const { tourContent } = props;
  const { attributes: { 'arrival-time': arrivalTime = null, duration = null } = {} } = tourContent;

  const { hour: timeHour, min: timeMin } = !isEmpty(arrivalTime) && formattedDuration(arrivalTime);
  const { hour: durationHour, min: durationMin } = !isEmpty(duration) && formattedDuration(duration);
  return (
    <article className="container tour-content-time-box">
      <div className="row align-items-center allTime justify-content-center m-0">
        <div className="lineTime"></div>
        <div className="d-flex align-items-center justify-content-center b-white">
          {isEmpty(arrivalTime) ? null : (
            <div className="hour justify-content-center d-flex align-items-center">
              <span className="tour-content-time-box__icon d-flex align-items-center justify-content-center">{CLOCK_ICON}</span>
              <p className="tour-content-time-box__time">
                <span className="tour-content-time-box__time-title">Hora de llegada: </span>
                {`${timeHour}:${timeMin}h`}
              </p>
            </div>
          )}
          {isEmpty(duration) ? null : (
            <div className="time justify-content-center d-flex align-items-center">
              <img height="30"
                alt='reloj'
                src={'../assets/images/hourglass.svg'}
              />
              <p
                className={`tour-content-time-box__duration ${
                  isEmpty(arrivalTime) ? '' : 'tour-content-time-box__duration--with-arrival'
                  }`}
              >
                <span className="tour-content-time-box__duration-title">Tiempo de visita: </span>
                {`${durationHour}h ${durationMin}min`}
              </p>
            </div>
          )}
        </div>
      </div>
    </article>
  );
}

TourContentTimeBox.propTypes = {
  tourContent: TYPES.tourContentType.isRequired,
};

export default TourContentTimeBox;
