import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import _cloneDeep from 'lodash.clonedeep';
import reduxBeeMakeRequest from '../../utils/redux-bee/redux-bee-make-request';
import TYPES from '../../constants/types';
import ImagesEditionTemplate from './images-edition-template';
import { isEmpty } from '../../utils';

const ImagesEdition = (props) => {
  const {
    dispatch,
    localTourContent,
    tourContentImages,
    onImageUploaded,
    uploaderParams,
    onDeleteImage,
    onImageInEditionChange,
  } = props;

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const [imageInEdition, setImageInEdition] = useState(null);
  useEffect(() => {
    if (onImageInEditionChange) onImageInEditionChange(imageInEdition);
  }, [imageInEdition, onImageInEditionChange]);

  const [currentImages, setCurrentImages] = useState(_cloneDeep(tourContentImages));
  useEffect(() => {
    if (tourContentImages.length !== currentImages.length) {
      setCurrentImages(_cloneDeep(tourContentImages));
    }
  }, [currentImages.length, tourContentImages]);

  const handleImageChange = (e, editedImage) => {
    if (e.target) {
      const { value, name } = e.target;
      const newImages = currentImages.map((image) => {
        const newImage = { ...image };
        if (editedImage.id === image.id) newImage.attributes[name] = value;
        return newImage;
      });
      setCurrentImages(newImages);
    } else {
      const richText = e === '<p><br></p>' ? '' : e;
      const newImages = currentImages.map((image) => {
        const newImage = { ...image };
        if (editedImage.id === image.id) newImage.attributes["description"] = richText;
        return newImage;
      });
      setCurrentImages(newImages);
    }
  };

  const handleImagesSave = () => {
    /* TODO: fix the currentImage and the archive array prop are equal always
    then it's impossible to know if a image has change or not */
    const updatePromises = currentImages.reduce((acc, currentImage /* idx */) => {
      // const { name: currentName, description: currentDescription } = currentImage.attributes;
      // const { name: originalName, description: originalDescription } = archives[idx].attributes;
      // if (currentName !== originalName || currentDescription !== originalDescription) {
      const updatePromise = reduxBeeMakeRequest(
        dispatch,
        'updateImage',
        { id: currentImage.id },
        currentImage,
      );
      acc.push(updatePromise);
      // }
      return acc;
    }, []);
    Promise.all(updatePromises).then(() => {
      const reorderedImages = currentImages.sort((a, b) => a.attributes.order - b.attributes.order);
      setCurrentImages(reorderedImages);
      setImageInEdition(null);
      toastr.success('Se han guardado todos los cambios');
    });
  };

  const handleDeleteImage = image => reduxBeeMakeRequest(dispatch, 'deleteImage', { id: image.id }, {})
    .then(() => onDeleteImage())
    .then(() => {
      setImageInEdition(null);
      toastr.success('Imagen eliminada con éxito');
    });

  const handleOnDragEnd = ({ oldIndex, newIndex }) => {
    if (isEmpty(newIndex) || oldIndex === newIndex) return;

    const movedImage = currentImages[oldIndex];
    reduxBeeMakeRequest(
      dispatch,
      'updateImage',
      { id: movedImage.id },
      { ...movedImage, attributes: { ...movedImage.attributes, order: newIndex + 1 } },
    )
      .then(() =>
        reduxBeeMakeRequest(dispatch, 'getTourContentArchives', { id: localTourContent.id }, {}),
      )
      .then((updatedImages) => {
        const newOrderedImages = updatedImages.sort(
          (a, b) => a.attributes.order - b.attributes.order,
        );
        setCurrentImages(newOrderedImages);
      });
  };

  return (
    <ImagesEditionTemplate
      images={currentImages}
      isDraggingOver={isDraggingOver}
      setIsDraggingOver={setIsDraggingOver}
      imageInEdition={imageInEdition}
      setImageInEdition={setImageInEdition}
      onImageUploaded={onImageUploaded}
      handleDeleteImage={handleDeleteImage}
      handleImageChange={handleImageChange}
      handleImagesSave={handleImagesSave}
      handleOnDragEnd={handleOnDragEnd}
      uploaderParams={uploaderParams}
    />
  );
};

ImagesEdition.propTypes = {
  localTourContent: TYPES.tourContentType.isRequired,
  tourContentImages: PropTypes.arrayOf(TYPES.imageType),
  onImageUploaded: PropTypes.func.isRequired,
  onDeleteImage: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  uploaderParams: PropTypes.shape({
    imageableId: PropTypes.string,
    imageableType: PropTypes.string.isRequired,
    imageableProperty: PropTypes.string.isRequired,
  }).isRequired,
  onImageInEditionChange: PropTypes.func,
};

ImagesEdition.defaultProps = {
  tourContentImages: [],
  onImageInEditionChange: null,
};

export default connect()(ImagesEdition);
