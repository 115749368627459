import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { SOLID_CAMERA_ICON } from '../helpers';
import TYPES from '../constants/types';
import { getImageSrcSet } from '../utils';
import ResponsiveImg from './responsive-img';

class GalleryBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselPosition: 0,
    };
    this.buildGalleryGrid = this.buildGalleryGrid.bind(this);
    this.handleChangeCarousel = this.handleChangeCarousel.bind(this);
  }

  buildGalleryGrid() {
    const { images, isMobile } = this.props;
    const imagesPerSlide = isMobile ? 9 : 12;

    images.sort((a, b) => {
      if (a.attributes && b.attributes) {
        if (a.attributes.likes > b.attributes.likes) {
          return -1;
        }
        if (a.attributes.likes < b.attributes.likes) {
          return 1;
        }
        // a must be equal to b
        return 0;
      }
    });

    const slidesAmount = images.length <= imagesPerSlide ? 1 : Math.ceil(images.length / imagesPerSlide);

    const slides = [];
    for (let i = 0; i < slidesAmount; i += 1) {
      slides[i] = images.slice(imagesPerSlide * i, imagesPerSlide * (i + 1));
      if (imagesPerSlide * (i + 1) > images.length) {
        const emptyImagesAmount = imagesPerSlide * (i + 1) - images.length;
        for (let j = 0; j < emptyImagesAmount; j += 1) {
          slides[i].push({ type: 'default' });
        }
      }
    }

    return slides;
  }

  handleChangeCarousel(index) {
    this.setState({ carouselPosition: index });
  }

  render() {
    const { isMobile, images, handleImageModal } = this.props;
    const { carouselPosition } = this.state;
    const { handleChangeCarousel } = this;
    const slides = this.buildGalleryGrid();
    const showedImagesAmount = (carouselPosition + 1) * 12 >= images.length
      ? images.length
      : (carouselPosition + 1) * 12;

/* console.log("slides: ", slides); */

    return (
      <article className="container-fluid gallery-box">
        <div className="row">
          <div className="container">
            <header className="row gallery-box__header">
              {isMobile ? (
                <>
                  <div className="col-auto px-1">
                    <span className="gallery-box__category">Galería de fotos</span>
                  </div>
                  <div className="col-auto pl-1">
                    <figure className="gallery-box__header-icon">{SOLID_CAMERA_ICON}</figure>
                  </div>
                </>
              ) : (
                <h3 className="gallery-box__header-title">
                  Galería de imágenes
                  <small className="gallery-box__header-subtitle">
                    {`(${showedImagesAmount} / ${images.length})`}
                  </small>
                </h3>
              )}
            </header>
            <div className="row">
              <div className="col-12 px-0">
                {images.length > 0 ? (
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    emulateTouch
                    onChange={handleChangeCarousel}
                    selectedItem={carouselPosition}
                  >
                    {slides.map((slide, slideIndex) => (
                      <section className="container gallery-box__slide" key={`slide${slideIndex + 1}`}>
                        <div className="row">
                          {slide.map((image, imageIdx) => (
                            <button
                              type="button"
                              data-type={image.type}
                              key={image.type === 'default' ? `default${imageIdx + 1}` : image.id}
                              className={`${isMobile ? 'col-4' : 'col-3'} gallery-box__slide-trigger`}
                              onClick={image.type === 'default' ? null : () => handleImageModal(image.id)}
                            >
                              <ResponsiveImg
                                className="gallery-box__slide-image"
                                srcSet={getImageSrcSet(image)}
                                forzedVersion={2}
                              />
                            </button>
                          ))}
                        </div>
                      </section>
                    ))}
                  </Carousel>
                ) : (
                  <h4 className={`text-center my-5 ${isMobile ? '' : 'text-white'}`}>
                    No se han encontrado fotos
                  </h4>
                )}
              </div>
            </div>
            {!isMobile ? (
              <footer className="row gallery-box__footer">
                <h5 className="mb-0 text-white">
                  Dale un me gusta a tus favoritas para que salgan las primeras
                </h5>
              </footer>
            ) : null}
          </div>
        </div>
      </article>
    );
  }
}

GalleryBox.propTypes = {
  images: PropTypes.arrayOf(TYPES.imageType).isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleImageModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(GalleryBox);
