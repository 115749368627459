import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ANGEL_ICON, CIRCLE_CROSS_ICON } from '../helpers';
import {
  Link
} from "react-router-dom";
import ENV from '../config/environment';
import { getImageSrcSet, checkExternalUrl, isEmpty } from '../utils';
import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import ResponsiveImg from './responsive-img';

class PartnerBox extends Component {
  state = {
    poiCampaign: {},
    showModalPartner: false,
    campaignImages: null,
    carouselPosition: 0,
    videoLink: null,
  };
  handleChangeCarouselr = (index) => {
    this.setState({ carouselPosition: index });
  }
  openModalPartner = () => {
    this.setState({ showModalPartner: true })
  }
  closeModalPartner = () => {
    this.setState({ showModalPartner: false })
  }
  componentDidMount() {
    if (this.props.poi) {
      this._getLikes()
    }
  }
  _getLikes() {
    if (this.props.poi.id) {
      fetch(`${ENV.host}/${ENV.namespace}/pois/likespoi/${this.props.poi.id}/${false}`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.setState({
            likes: data.likes,
            poiId: this.props.poi.id,
            views: data.views,
            poiCampaign: data.campaign,
            campaignImages: data.campaignImages,
          });
          if (data.campaign) {
            this.props.get_campaing(data.campaign)
          }
          this.loadVideo()
        });
    }
  }
  loadVideo = () => {
    if (this.state.poiCampaign && this.state.poiCampaign.videoLink) {
      const videoLink = this.state.poiCampaign.videoLink;
      if (videoLink.indexOf("youtube") != -1) {
        this.setState({
          videoLink: videoLink.replace("watch?v=", "embed/")
        })
      } else if (videoLink.indexOf("vimeo") != -1) {
        this.setState({
          videoLink: videoLink.replace("https://vimeo.com/", "https://player.vimeo.com/video/")
        })
      }
    }
  }
  render() {
    const { isMobile } = this.props;
    const { poiCampaign } = this.state;
    const { title, smallText, linkCampaign, description, videoLink } = poiCampaign || {};
    return (
      <div>
        {poiCampaign ? (
          <article className="container tour-sponsor-box">
            <header className="row justify-content-end tour-sponsor-box__header">
              <div className="col-auto px-1">
                <span className="tour-sponsor-box__category">Destino patrocinado</span>
              </div>
              <div className="col-auto pl-1">
                <figure className="tour-sponsor-box__header-icon">{ANGEL_ICON}</figure>
              </div>
            </header>
            <div className="row">
              {isEmpty(this.state.campaignImages) ? null : (
                <div className="col-12 col-lg-7 px-0">
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    emulateTouch
                    onChange={this.handleChangeCarousel}
                    selectedItem={this.state.carouselPosition}
                  >
                    {this.state.campaignImages.map(image => {
                      const srcSetArray = getImageSrcSet(image);
                      return (
                        <div key={`carousel-img-${image.id}`}>
                          <ResponsiveImg className="tour-content-poi-box__image campaing_image" srcSet={srcSetArray}>
                          </ResponsiveImg>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              )}
              {true ? (
                <div className={`col-12 ${isEmpty(false) ? '' : 'col-lg-5'} pt-3`}>
                  {isEmpty(title) ? null : <h4 className="tour-sponsor-box__title">{title}</h4>}
                  <div dangerouslySetInnerHTML={{
                    __html: description
                  }}></div>
                  <div>
                    {/* {poiCampaign} */}
                  </div>
                  <div className="d-flex">
                    {isEmpty(linkCampaign) ? null : (
                      <a
                        href={checkExternalUrl(linkCampaign)}
                        className="btn btn-primary tour-sponsor-box__link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ver más
                      </a>
                    )}
                    {isEmpty(this.state.videoLink) ? null : (
                      <a
                        onClick={this.openModalPartner}
                        className="btn btn-primary tour-sponsor-box__link botonWhite ml-2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ver vídeo
                      </a>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </article>
        ) : (
            <article className="container partner-box">
              <header className="row justify-content-end partner-box__header">
                <div className="col-auto px-1">
                  <span className="partner-box__category">
                    Patrocina este destino
            {!isMobile ? (
                      ' (el 50% de los patrocinios se destinan a los creadores)'
                    ) : null}
                  </span>
                </div>
                <div className="col-auto pl-1">
                  <figure className="partner-box__header-icon">{ANGEL_ICON}</figure>
                </div>
              </header>
              <div className="row h-100">
                <a href={`/sponsor-place/${this.props.poi.id}`} target='_blank' className="col-12 px-0 h-100">
                  {
                    isMobile ? (
                      <img
                        width='100%'
                        className="partner-box__image partner-box__image--default"
                        src="/assets/images/partner_mobile2.png"
                        alt="LenioLabs_"
                      />
                    ) : (
                        <img
                          className="partner-box__image partner-box__image--default"
                          src="/assets/images/partner.png"
                          alt="LenioLabs_"
                        />
                      )
                  }
                </a>
              </div>
            </article>
          )}
        <Modal className="ModalVideo modalPartner"
          overlayClassName="ContentModal"
          isOpen={this.state.showModalPartner}
          contentLabel="ContentModal"
          onRequestClose={this.closeModalPartner}
          shouldCloseOnOverlayClick={true}
        >
          <div className=" alturaImagen pt-5">
            <div className="buttonClose">
              <button onClick={this.closeModalPartner} className="closeIcon p-0">
                {CIRCLE_CROSS_ICON}
              </button>
            </div >
            <div className="embed-responsive embed-responsive-16by9">
              <iframe className="embed-responsive-item" src={this.state.videoLink} allowfullscreen></iframe>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

PartnerBox.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(PartnerBox);
