import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';

import { isEmptyObject } from '../../../../../../utils';
import reduxBeeMakeRequest from '../../../../../../utils/redux-bee/redux-bee-make-request';
import { dispatch } from '../../../../../../reduxStore';
import useGetResource from '../../../../../../helpers/hooks/useGetResource';
import useGetRelationshipResource from '../../../../../../helpers/hooks/useGetRelationshipResource';
import TourContentPoiFormTemplate from './tour-content-poi-form-template';
import TYPES from '../../../../../../constants/types';

const reorderLocalChildrenTourContents = (childrenTourContents, movedTourContent, startIndex, newIndex) => {
  const reorderedTourContents = _cloneDeep(childrenTourContents);
  reorderedTourContents.splice(startIndex, 1);
  reorderedTourContents.splice(newIndex, 0, movedTourContent);
  return reorderedTourContents.map((tourContent, index) => {
    const updatedTourContent = _cloneDeep(tourContent);
    updatedTourContent.attributes.order = index + 1;
    return updatedTourContent;
  });
};
const updateServerChildTourContentOrder = (updatedChildTourContent, parentId) => {
  const { id: updateId } = updatedChildTourContent;
  // catch is not required due to internally handled error toasters
  return reduxBeeMakeRequest(dispatch, 'updateTourContent', { id: updateId }, updatedChildTourContent).then(
    () => reduxBeeMakeRequest(dispatch, 'getTourContentChildren', { id: parentId }),
  );
};

function TourContentPoiForm(props) {
  const {
    localTourContent,
    setLocalTourContent,
    handleOnImageUpload,
    handleToggleVisibility,
    handleDelete,
    handleOpenTourContentMenu,
    handleContentSelection,
    handleCloseModal,
    beesTourContent,
    childrenTourContents,
    handleSave,
  } = props;

  const {
    id: selectedTourContentId,
    attributes: { text: tourContentText },
  } = localTourContent;

  const [isEditingText, setIsEditingText] = useState(false);

  // ******************** LOADS RESOURCES ************************
  useGetRelationshipResource(beesTourContent, 'tour-contents', 'tourContents', 'getTourContentChildren');
  useGetResource({ id: selectedTourContentId }, 'archives', 'getTourContentArchives');

  const tourContentPoi = null
  /*   const poiImages = useGetRelationshipResource(tourContentPoi, 'images', 'images', 'getPoiImages');
    const mainImage = useGetRelationshipResource(tourContentPoi, 'main-image', 'images', 'getImage'); */

  const poiDisplayedImage = null;
  // ****************** LOADS RESOURCES END **********************

  // ****************** DRAG AND DROP LOGIC **********************
  // this allow visual reorder without waiting the server response...
  const [sortedChildrenTourContents, setSortedChildrenTourContents] = useState([]);
  // everytime tourcontents children length change, copy them sorted in the sortedChildrenTourContents...
  useEffect(() => {
    if (
      Array.isArray(childrenTourContents)
      && childrenTourContents.length !== sortedChildrenTourContents.length
    ) {
      setSortedChildrenTourContents(
        _cloneDeep(childrenTourContents).sort((a, b) => a.attributes.order - b.attributes.order),
      );
    }
    if (
      Array.isArray(childrenTourContents)
      && childrenTourContents.length === sortedChildrenTourContents.length
    ) {
      let anyVisibleChanged = false;
      childrenTourContents.forEach((childrenTourContent, index) => {
        const { attributes: serverTourContentAttrs } = childrenTourContent;
        const { attributes: localTourContentAttrs } = sortedChildrenTourContents[index];
        if (serverTourContentAttrs.visible !== localTourContentAttrs.visible) {
          anyVisibleChanged = true;
        }
      });
      if (anyVisibleChanged) {
        setSortedChildrenTourContents(
          _cloneDeep(childrenTourContents).sort((a, b) => a.attributes.order - b.attributes.order),
        );
      }
    }
  }, [childrenTourContents]);

  // this code allow a fallback re-order if the server response failed
  const [onReorderRequestEnd, setOnReorderRequestEnd] = useState(0);
  useEffect(() => {
    if (onReorderRequestEnd !== 0 && !_isEqual(childrenTourContents, sortedChildrenTourContents)) {
      setSortedChildrenTourContents(
        _cloneDeep(childrenTourContents).sort((a, b) => a.attributes.order - b.attributes.order),
      );
    }
  }, [onReorderRequestEnd]);

  const onDragEnd = ({ oldIndex, newIndex }) => {
    const movedTourContent = sortedChildrenTourContents[oldIndex];
    // generate an array with changed attributes.order locally
    const reorderedChildrenTourContents = reorderLocalChildrenTourContents(
      sortedChildrenTourContents,
      movedTourContent,
      oldIndex,
      newIndex,
    );
    const updatedOrderTourContent = reorderedChildrenTourContents[newIndex];

    // optimistically locally order the tour-contents for more fluid user experience
    setSortedChildrenTourContents(reorderedChildrenTourContents);

    // request the update to the server
    updateServerChildTourContentOrder(updatedOrderTourContent, selectedTourContentId).then(() =>
      setOnReorderRequestEnd(Date.now()),
    );
  };
  // **************** DRAG AND DROP LOGIC END ********************

  // ***************** USERS INPUTS HANDLERS *********************
  const handleEditState = () => {
    // if (isEditingText) {
    //   updateTourContent(textAreaText);
    // }
    setIsEditingText(!isEditingText);
  };

  const handleInput = (e) => {
    if (e.target) {
      const {
        target: { value: newValue, name: attributeIdentifier },
      } = e;
      const immutableTourContent = _cloneDeep(localTourContent);

      immutableTourContent.attributes[attributeIdentifier] = newValue;
      setLocalTourContent(immutableTourContent);
    } else {
      const richText = e === '<p><br></p>' ? '' : e;
      const immutableTourContent = _cloneDeep(localTourContent);
      immutableTourContent.attributes['text'] = richText;
      setLocalTourContent(immutableTourContent);
    }
  };
  // *************** USERS INPUTS HANDLERS END *******************

  return (
    <TourContentPoiFormTemplate
      isEditingText={isEditingText}
      tourContentText={tourContentText}
      poiDisplayedImage={poiDisplayedImage}
      tourContentPoi={tourContentPoi}
      sortedChildrenTourContents={sortedChildrenTourContents}
      handleOnImageUpload={handleOnImageUpload}
      handleToggleVisibility={handleToggleVisibility}
      handleDelete={handleDelete}
      handleEditState={handleEditState}
      handleInput={handleInput}
      handleSave={handleSave}
      onDragEnd={onDragEnd}
      handleOpenTourContentMenu={handleOpenTourContentMenu}
      handleContentSelection={handleContentSelection}
      handleCloseModal={handleCloseModal}
      localTourContent={localTourContent}
      pois={props.pois}
    />
  );
}

TourContentPoiForm.propTypes = {
  localTourContent: TYPES.tourContentType.isRequired,
  beesTourContent: TYPES.tourContentType.isRequired,
  setLocalTourContent: PropTypes.func.isRequired,
  handleOnImageUpload: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleOpenTourContentMenu: PropTypes.func.isRequired,
  handleContentSelection: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  childrenTourContents: PropTypes.arrayOf(TYPES.tourContentType).isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    localTourContent: { id: tourContentId },
  } = ownProps;
  const beesTourContent = state.bees.entities.tourContents[tourContentId];
  const {
    relationships: {
      'tour-contents': { data: childrenTourContentsData },
    },
  } = beesTourContent;

  const childrenTourContents = isEmptyObject(childrenTourContentsData)
    ? []
    : childrenTourContentsData
      .map(tourContentChild => state.bees.entities.tourContents[tourContentChild.id])
      .sort((a, b) => a.attributes.order - b.attributes.order);

  return {
    beesTourContent,
    childrenTourContents,
  };
}

export default connect(mapStateToProps)(TourContentPoiForm);
