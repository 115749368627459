import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Map from './map';
import './gmaps.scss';
import { isEmpty } from '../../utils';

class Gmaps extends Component {
  static getGoogleBounds(bounds) {
    let newBounds = null;
    const {
      swl, nel, swlng, nelng,
    } = bounds;
    const sw = new window.google.maps.LatLng(swl, swlng);
    const ne = new window.google.maps.LatLng(nel, nelng);
    newBounds = new window.google.maps.LatLngBounds(sw, ne);
    return newBounds;
  }

  static getBoundsFromMarkers(markers) {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((marker) => {
      const markerPosition = new window.google.maps.LatLng(marker.lat, marker.lng);
      bounds.extend(markerPosition);
    });
    return bounds;
  }

  constructor(props) {
    super(props);
    const { defaultCenter } = props;
    this.state = {
      lat: defaultCenter.lat,
      lng: defaultCenter.lng,
      bounds: null,
      mapInstance: null,
    };

    this.onMarkerCoordSave = this.onMarkerCoordSave.bind(this);
    this.onCoordsDelete = this.onCoordsDelete.bind(this);
    this.mapCenterChanged = this.mapCenterChanged.bind(this);
    this.onMapComponentDidMount = this.onMapComponentDidMount.bind(this);
    this.moveMapToGoogleBounds = this.moveMapToGoogleBounds.bind(this);
    this.moveMapToBounds = this.moveMapToBounds.bind(this);
    this.fitMapToGoogleBounds = this.fitMapToGoogleBounds.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { markers = [], centerMapOnMarkers } = this.props;
    if (
      centerMapOnMarkers
      && Array.isArray(prevProps.markers)
      && prevProps.markers.length !== markers.length
    ) {
      const { fitMapToGoogleBounds } = this;
      fitMapToGoogleBounds(Gmaps.getBoundsFromMarkers(markers));
    }
  }

  onMapComponentDidMount(mapInstance) {
    const { moveMapToBounds, fitMapToGoogleBounds } = this;
    const {
      defaultBounds, onMapComponentDidMount, markers, centerMapOnMarkers,
    } = this.props;
    this.setState({ mapInstance }, () => {
      if (defaultBounds) {
        moveMapToBounds(defaultBounds);
      }
      if (centerMapOnMarkers) {
        fitMapToGoogleBounds(Gmaps.getBoundsFromMarkers(markers));
      }
      if (onMapComponentDidMount) {
        onMapComponentDidMount(mapInstance);
      }
    });
  }

  // if map wasn't moved at all or if instantied without onMarkerCoordSave callBack returns null
  onMarkerCoordSave() {
    const { lat, lng, bounds } = this.state;
    const { onMarkerCoordSave } = this.props;
    return onMarkerCoordSave ? onMarkerCoordSave({ lat, lng, bounds }) : null;
  }

  onCoordsDelete() {
    const { onCoordsDelete } = this.props;
    return onCoordsDelete();
  }

  fitMapToGoogleBounds(googleBounds) {
    const { mapInstance } = this.state;
    mapInstance.fitBounds(googleBounds, 5);
  }

  moveMapToGoogleBounds(googleBounds) {
    const { mapInstance } = this.state;
    // fitBounds doens't work as expected with zoom use panToBounds instead
    // mapInstance.fitBounds(googleBounds, 0);
    mapInstance.panToBounds(googleBounds, 0);
  }

  moveMapToBounds(bounds) {
    const { moveMapToGoogleBounds } = this;
    const googleBounds = Gmaps.getGoogleBounds(bounds);
    moveMapToGoogleBounds(googleBounds);
    if (bounds.mapFittedCB) bounds.mapFittedCB();
  }

  mapCenterChanged(newCoord) {
    const { lat, lng, bounds } = newCoord;
    const { mapCenterChanged } = this.props;

    this.setState({ lat, lng, bounds });
    return mapCenterChanged ? mapCenterChanged(newCoord) : null;
  }

  render() {
    const { onMapComponentDidMount } = this;
    const { onMarkerCoordSave, onCoordsDelete, positionBtnsInset } = this.props;
    return (
      <div className="mapWrapper">
        {isEmpty(onMarkerCoordSave) ? null : <div className="centerMarker" />}
        <Map
          // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCygd0p2XG8yyNaG8S3yzu6rt3HYcR22GI"
          loadingElement={<div className="gmapLoader" />}
          containerElement={<div className="gmapContainer" />}
          mapElement={<div className="gmapElement" />}
          {...this.props}
          mapCenterChanged={this.mapCenterChanged}
          onMapComponentDidMount={onMapComponentDidMount}
          {...(
            positionBtnsInset
            && {
              defaultOptions: {
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
              },
            }
            )
          }
        />
        {
        !isEmpty(onMarkerCoordSave)
        || !isEmpty(onCoordsDelete)
          ? (
            <div
              className={`gmaps__btn-wrapper${positionBtnsInset ? ' gmaps__btn-wrapper--inset' : ''}`}
            >
              {isEmpty(onMarkerCoordSave) ? null : (
                <button
                  type="button"
                  className="btn btn btn-primary"
                  onClick={this.onMarkerCoordSave}
                >
                  Guardar
                </button>
              )}
              {isEmpty(onCoordsDelete) ? null : (
                <button
                  type="button"
                  className="btn btn btn-danger"
                  onClick={this.onCoordsDelete}
                >
                  Borrar
                </button>
              )}
            </div>
          )
          : null
        }
      </div>
    );
  }
}

Gmaps.propTypes = {
  defaultCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  mapCenterChanged: PropTypes.func,
  onMarkerCoordSave: PropTypes.func,
  onCoordsDelete: PropTypes.func,
  onMapComponentDidMount: PropTypes.func,
  defaultBounds: PropTypes.shape({
    nel: PropTypes.number.isRequired,
    nelng: PropTypes.number.isRequired,
    swl: PropTypes.number.isRequired,
    swlng: PropTypes.number.isRequired,
  }),
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      long: PropTypes.number,
    }),
  ),
  centerMapOnMarkers: PropTypes.bool,
  positionBtnsInset: PropTypes.bool,
};

Gmaps.defaultProps = {
  defaultBounds: null,
  onMapComponentDidMount: null,
  mapCenterChanged: null,
  onMarkerCoordSave: null,
  onCoordsDelete: null,
  centerMapOnMarkers: false,
  positionBtnsInset: false,
  markers: [],
};

export default Gmaps;
