import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import ReactTooltip from 'react-tooltip';
import {
  SERVICES_CIRCLE_ICON,
  POIS_ICON,
  RIGHT_ARROW_ANGLE_ICON,
  LEFT_ARROW_ANGLE_ICON,
  HOME_BACK_ICON,
  BACK_ICON,
  history,
  PENCIL_CIRCLE_ICON,
  INFO_COLOR_ICON,
  SHOPPING_CART_ICON,
  CIRCLE_CROSS_ICON,
} from '../../../helpers';
import reduxBeeMakeRequest from '../../../utils/redux-bee/redux-bee-make-request';
import { TOUR_EDITION_DINAMIC_URL, TOUR_STATES } from '../../../constants';
import { isEmpty } from '../../../utils';
import Modal from 'react-modal';

class TourTopbar extends Component {
  constructor(props) {
    super(props);
    this.renderMobileDaySelection = this.renderMobileDaySelection.bind(this);
    this.renderDesktopDaySelection = this.renderDesktopDaySelection.bind(this);
    this.handleTransitionToEdition = this.handleTransitionToEdition.bind(this);
    this.handleBackBtn = this.handleBackBtn.bind(this);
    this.handleGoToVirtualShop = this.handleGoToVirtualShop.bind(this);
    this.handleShowStatusSelectClick = this.handleShowStatusSelectClick.bind(this);
    this.handleStatusSelect = this.handleStatusSelect.bind(this);

    this.state = {
      showStatusSelection: false,
      headers: [],
      showModal: false
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.tourTourContents !== prevProps.tourTourContents) {
      let content = this.props.tourTourContents;
      let contentReady = content.indexOf(undefined)

      if (contentReady == -1) {
        this.setState({
          headers: content
        })
      } /* else {
        this.setState({
          visibleTourContents: visibleTourContents,
          items: visibleTourContents,
          pois: pois,
        })
      } */
    }
  }

  handleStatusSelect(e) {
    const { dispatch, tour } = this.props;
    const {
      target: { value },
    } = e;

    const newState = {
      showStatusSelection: false,
    };

    this.setState(newState, () => {
      const { id, type } = tour;
      reduxBeeMakeRequest(
        dispatch,
        'updateTour',
        { id },
        { id, type, attributes: { state: parseInt(value, 10) } },
      ).then(() => {
        let successMsg = "";
        /* const successMsg = value === 1
          ? 'Acabas de publicar tu ruta, ¡Felicidades!'
          : 'Se ha cambiado tu ruta a "oculta" con éxito'; */
        if (value == 1) {
          successMsg = 'Se ha cambiado tu ruta a "Borrador" con éxito';
        } else if (value == 2) {
          successMsg = 'Se ha cambiado tu ruta a "Publicado" con éxito';
        } else if (value == 4) {
          successMsg = 'Se ha cambiado tu ruta a "Oculto" con éxito';
        }
        toastr.success(successMsg);
      });
    });
  }

  handleBackBtn() {
    const { isMobile } = this.props;
    if (isMobile) {
      history.goBack(-1);
    } else {
      history.push('/');
    }
  }

  handleTransitionToEdition() {
    const { dispatch, tour, handleNavigation } = this.props;
    if (isEmpty(handleNavigation)) {
      dispatch(push(TOUR_EDITION_DINAMIC_URL(tour.id)));
    } else {
      handleNavigation();
    }
  }

  handleGoToVirtualShop() {
    const { virtualShopRefElement, isMobile, tour } = this.props;
    if (!isEmpty(virtualShopRefElement)) {
      const headerHeight = isMobile ? 100 : 70;
      const auxTopbar = tour.attributes.state === 3 ? 0 : 50;
      const scrollPosition = virtualShopRefElement.offsetTop - 50 - headerHeight - auxTopbar;
      window.scrollTo(0, scrollPosition);
    }
  }

  handleShowStatusSelectClick() {
    const { showStatusSelection } = this.state;
    this.setState({ showStatusSelection: !showStatusSelection });
  }

  openModal = () => {
    this.setState({ showModal: true })
  }
  closeModal = () => {
    this.setState({ showModal: false })
  }
  renderMobileDaySelection(tourDuration) {
    let headers;
    let noUndefined = this.state.headers.length > 0 ? this.state.headers.indexOf(null) : 1;
    if (noUndefined == -1 && this.state.headers.length > 0) {
      headers = this.state.headers.filter(e => e.attributes["content-type"] == 4 && e.attributes["header-text"])
      headers.sort((a, b) => {
        if (a.attributes.day < b.attributes.day) {
          return -1;
        }
        if (a.attributes.day > b.attributes.day) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })
    }
    const { activeDay, handleDaySelection, tour } = this.props;

    return (
      <>
        <button
          type="button"
          className="tour-topbar__days-btn tour-topbar__days-btn--prev"
          title={tour.attributes.selection ? "Página anterior" : "Día anterior"}
          onClick={() => {
            const newDay = activeDay > 1 ? activeDay - 1 : activeDay;
            handleDaySelection(newDay);
          }}
        >
          {LEFT_ARROW_ANGLE_ICON}
        </button>
        {/* button modal for tours selection */}
        {tour.attributes.selection && headers && headers.length > 0 ? (
          <div>
            <span className="tour-topbar__days-current" onClick={this.openModal}>{activeDay}</span>
            {/* modal of pages */}
            <Modal
              className="modal-pages"
              overlayClassName="modal-pages__overlay"
              isOpen={this.state.showModal}
              contentLabel="modal pages"
              shouldCloseOnOverlayClick={true}
              onRequestClose={this.closeModal}
            >
              <div className="contentModal d-flex justify-content-center">
                <div className="nav d-flex justify-content-end">
                  <button onClick={this.closeModal} className="closeIcon p-0">
                    {CIRCLE_CROSS_ICON}
                  </button>
                </div>
                <div className="contentCenter d-flex justify-content-center">
                  {headers.map(header => (
                    <button type="button" className=" btn btn-light buttonPages mb-2 p-3"
                      onClick={() => {
                        handleDaySelection(header.attributes.day);
                          this.closeModal();
                      }}>
                      {header.attributes.title}
                    </button>
                  ))}
                </div>
              </div>
            </Modal>
          </div>
        ) : (
            <span className="tour-topbar__days-current">{activeDay}</span>
          )}
        <button
          type="button"
          className="tour-topbar__days-btn tour-topbar__days-btn--next"
          title={tour.attributes.selection ? "Página siguiente" : "Día siguiente"}
          onClick={() => {
            const newDay = activeDay < tourDuration ? activeDay + 1 : activeDay;
            handleDaySelection(newDay);
          }}
        >
          {RIGHT_ARROW_ANGLE_ICON}
        </button>
        <span className="tour-topbar__days-total">{`/${tourDuration || 1}`}</span>
      </>
    );
  }

  renderDesktopDaySelection(tourDuration) {
    const { activeDay, handleDaySelection, currentWidth, tour } = this.props;
    let headers;
    let noUndefined = this.state.headers.length > 0 ? this.state.headers.indexOf(null) : 1;
    if (noUndefined == -1 && this.state.headers.length > 0) {
      headers = this.state.headers.filter(e => e.attributes["content-type"] == 4 && e.attributes["header-text"])
    }
    const visibleDaysNumber = Math.floor((currentWidth - 600) / 120);
    const daysNavigation = [];
    let prevSeparator = false;
    let nextSeparator = false;
    for (let i = 1; i <= tourDuration; i += 1) {
      const isVisibleDay = i === 1
        || i === activeDay
        || i === tourDuration
        || (i > 1 && i > activeDay - visibleDaysNumber && i < activeDay)
        || (i < tourDuration && i > activeDay && i < activeDay + visibleDaysNumber);

      if (isVisibleDay) {
        let header = headers && headers.find(e => e.attributes.day == i)

        daysNavigation.push(
          <button
            data-toggle="tooltip" data-tip={i > activeDay ? tour.attributes.selection ? `${header != undefined ? header.attributes.title : "cargando..."}` : `Día siguiente ${i}` : tour.attributes.selection ? ` ${header != undefined ? header.attributes.title : "cargando..."}` : `Día anterior ${i}`} data-class='tooltipDesktop' data-place='bottom' data-type="dark"
            type="button"
            className={`tour-topbar__days-btn ml-2 ${
              activeDay === i ? 'tour-topbar__days-btn--active' : ''
              }`}
            /* title={i > activeDay ? tour.attributes.selection ? `${header != undefined ? header.attributes.title : "cargando..."}` : `Día siguiente ${i}` : tour.attributes.selection ? ` ${header != undefined ? header.attributes.title : "cargando..."}` : `Día anterior ${i}`} */
            onClick={() => {
              handleDaySelection(i);
            }}
            /* disabled={activeDay === i} */
            key={`selector${i}`}
          >
            <span>{i}</span>
          </button>,
          <ReactTooltip effect="solid" html />
        );
      } else if (i > 1 && i < activeDay && !prevSeparator) {
        daysNavigation.push(
          <span key="selector-prev" className="tour-topbar__days-separator">
            ...
          </span>,
        );
        prevSeparator = true;
      } else if (i < tourDuration && i > activeDay && !nextSeparator) {
        daysNavigation.push(
          <span key="selector-next" className="tour-topbar__days-separator">
            ...
          </span>,
        );
        nextSeparator = true;
      }
    }
    return daysNavigation;
  }

  render() {
    const {
      tour,
      handleTourContentTypeFilter,
      tourContentTypeFilter,
      isMobile,
      currentWidth,
      showEditOption,
      hasRecommedations,
      hasTips,
      virtualShopRefElement,
    } = this.props;

    const { showStatusSelection } = this.state;

    const {
      attributes: { 'tour-type': tourType, 'tour-duration': tourDuration, state: tourState },
    } = tour;
    const isRanking = tourType === 1;
    const isShowStateActions = (tourState === 1) && true;
    const showShopButton = !isEmpty(virtualShopRefElement) && currentWidth > 768;

    const currentState = TOUR_STATES.reduce((acc, stateOption) => (stateOption.value === tourState ? stateOption : acc),
      {});
    return (
      <nav className={`container-fluid tour-topbar${isShowStateActions ? ' tour-topbar--big' : ''}`}>
        <div className="row d-flex justify-content-between">
          <div className="row">

            <div className="col-auto px-0">
              <button
                type="button"
                className="tour-topbar__back"
                onClick={this.handleBackBtn}
              >
                {isMobile ? BACK_ICON : HOME_BACK_ICON}
              </button>
            </div>
            <div className="col-auto px-0">
              <button
                type="button"
                className={`tour-topbar__filter-btn${
                  tourContentTypeFilter === 1 ? ' tour-topbar__filter-btn--active' : ''
                  }`}
                onClick={() => handleTourContentTypeFilter(1)}
              >
                {isMobile ? POIS_ICON : 'La ruta'}
              </button>
              {hasRecommedations ? (
                <button
                  type="button"
                  className={`tour-topbar__filter-btn${
                    tourContentTypeFilter === 2 ? ' tour-topbar__filter-btn--active' : ''
                    }`}
                  onClick={() => handleTourContentTypeFilter(2)}
                  disabled={!hasRecommedations}
                >
                  {isMobile ? SERVICES_CIRCLE_ICON : 'Recomendaciones'}
                </button>
              ) : null}

              {hasTips ? (
                <button
                  type="button"
                  className={`tour-topbar__filter-btn${
                    tourContentTypeFilter === 8 ? ' tour-topbar__filter-btn--active' : ''
                    }`}
                  onClick={() => handleTourContentTypeFilter(8)}
                  disabled={!hasTips}
                >
                  {isMobile ? INFO_COLOR_ICON : 'Consejos de viaje'}
                </button>
              ) : null}
            </div>
          </div>
          {isRanking ? null : (
            <div className="col-auto px-1 ml-auto tour-topbar__days">
              {
                tour.attributes.selection ? <span className="tour-topbar__days-text">{isMobile ? 'PÁGINA' : 'PÁGINAS'}</span> : <span className="tour-topbar__days-text">{isMobile ? 'DÍA' : 'DÍAS'}</span>
              }
              <div className="tour-topbar__days-select">
                {isMobile
                  ? this.renderMobileDaySelection(tourDuration)
                  : this.renderDesktopDaySelection(tourDuration)}
              </div>
            </div>
          )}
          {virtualShopRefElement ? (
            <div className="col-auto ml-auto px-1">
              <button
                type="button"
                className="tour-topbar__shop-btn"
                title="Ver tienda virtual"
                onClick={this.handleGoToVirtualShop}
              >
                {SHOPPING_CART_ICON}
              </button>
            </div>
          ) : null}
          {showEditOption ? (
            <div className={`col-auto px-1 ${isEmpty(virtualShopRefElement) ? '' : ' ml-md-0'}`}>
              <button
                type="button"
                className="tour-topbar__edit-btn botonEditarMovil"
                onClick={this.handleTransitionToEdition}
                title="Editar ruta"
              >
                {PENCIL_CIRCLE_ICON}
              </button>
            </div>
          ) : null}
        </div>
        <div className="row">
          {isShowStateActions ? (
            <div className="row tour-topbar__publish w-100 m-0">
              <div className="col-auto ml-auto">
                <h4 className="tour-topbar__publish-state">Estado:</h4>
                <button
                  type="button"
                  className={`btn tour-topbar__state-btn tour-topbar__state-btn--${currentState.name}`}
                  onClick={this.handleShowStatusSelectClick}
                >
                  {currentState.label}
                </button>
                {showStatusSelection ? (
                  <div className="custom-state-select">
                    <div className="custom-state-select__list">
                      {TOUR_STATES
                        .filter(stateOption => stateOption.selectable && stateOption.value !== tourState)
                        .map(selectableState => (selectableState.value === 2 && tourState === 3 ? null
                          : (
                            <Fragment key={`tour-state-${selectableState.name}`}>
                              <input
                                type="radio"
                                name="state"
                                id={selectableState.name}
                                aria-checked="false"
                                aria-hidden="true"
                                aria-labelledby={selectableState.name}
                                onChange={this.handleStatusSelect}
                                value={selectableState.value}
                                checked={selectableState.value === tourState}
                              />
                              <label
                                className={`
                              custom-state-select__list-item custom-state-select__list-item--${selectableState.name}
                            `}
                                htmlFor={selectableState.name}
                                aria-label={selectableState.name}
                                data-tip={`
                                <p class="data-tip">${selectableState.description}</p>
                              `}
                              >
                                {selectableState.label}
                              </label>
                            </Fragment>
                          )
                        ))
                      }
                    </div>
                    <ReactTooltip effect="solid" html />
                  </div>
                ) : null}

              </div>
            </div>
          ) : null}
        </div>
      </nav>
    );
  }
}

TourTopbar.propTypes = {
  tour: PropTypes.shape({
    attributes: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
  activeDay: PropTypes.number.isRequired,
  handleDaySelection: PropTypes.func.isRequired,
  handleTourContentTypeFilter: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  tourContentTypeFilter: PropTypes.number,
  isMobile: PropTypes.bool,
  currentWidth: PropTypes.number.isRequired,
  handleNavigation: PropTypes.func,
  showEditOption: PropTypes.bool.isRequired,
  hasRecommedations: PropTypes.bool.isRequired,
  hasTips: PropTypes.bool.isRequired,
  virtualShopRefElement: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

TourTopbar.defaultProps = {
  tourContentTypeFilter: null,
  isMobile: false,
  handleNavigation: null,
  virtualShopRefElement: null,
};

function mapStateToProps(state, ownProps) {
  const { tourOwner } = ownProps;
  const { isAuthenticated, currentUser } = state.auth;
  const isOwner = isAuthenticated
    && !isEmpty(tourOwner)
    && !isEmpty(currentUser)
    && tourOwner.id === currentUser.id;
  const isMobile = state.viewport.isXs || state.viewport.isSm || state.viewport.isMd;
  const isTourEditionPage = state.router.location.pathname.includes('/tour-edition/');
  const showEditOption = isOwner && ((!isMobile && isTourEditionPage) || !isTourEditionPage);

  return {
    isMobile,
    currentWidth: state.viewport.currentWidth,
    showEditOption,
  };
}

export default connect(mapStateToProps)(TourTopbar);
