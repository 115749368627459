import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { CIRCLE_CROSS_ICON } from '../helpers/svg-inline';
import { lockBodyScrolling, restoreBodyScrolling } from '../utils';
import PromiseButton from './promise-button';
import TYPES from '../constants/types';

const ModalPublishConfirm = (props) => {
  const {
    isOpen, closeModal, handleSave, handleModalPublishCongrats, poi,
  } = props;

  useEffect(() => {
    lockBodyScrolling();
    return restoreBodyScrolling;
  }, []);

  const handlePublish = () => handleSave(2).then(() => {
    const { attributes: { 'poi-type': poiType } = {} } = poi || {};
    closeModal();
    if (poiType === 1) {
      handleModalPublishCongrats(true);
    }
  });

  return (
    <Modal
      className="modal-publish-confirm"
      overlayClassName="modal-publish-confirm__overlay"
      name="modal-publish-confirm"
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className="container-fluid">
        <button
          className="modal-publish-confirm__close-btn"
          name="warningModal"
          type="button"
          onClick={closeModal}
        >
          {CIRCLE_CROSS_ICON}
        </button>
        <div className="row align-items-md-center">
          <div className="col-12 col-lg-auto">
            <figure>
              <img
                src="/assets/images/confirmar.svg"
                alt="Aviso"
                className="modal-publish-confirm__main-image"
              />
            </figure>
          </div>
          <div className="col ml-lg-auto modal-publish-confirm__content">
            <h4 className="modal-publish-confirm__content-title">
              Confirmar publicación
            </h4>
            <p className="modal-publish-confirm__content-text">
              Una vez publicado el contenido lo verificaremos para garantizar siempre su calidad
              . Una vez aprobado ya no podrás volver a editarlo (tendrás que enviar a nuestro e
              quipo cualquier modificación), pero siempre podrías modificar/eliminar las imágenes que hayas añadido.
            </p>

            <div className="row justify-content-center justify-content-lg-start mt-lg-5">
              <div className="col-auto pr-1">
                <PromiseButton
                  className="btn btn-primary"
                  type="button"
                  onClick={handlePublish}
                >
                  confirmar publicación
                </PromiseButton>
              </div>
              <div className="col-auto pl-1">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={closeModal}
                >
                  editar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalPublishConfirm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleModalPublishCongrats: PropTypes.func.isRequired,
  poi: TYPES.poiType.isRequired,
  isOpen: PropTypes.bool,
};

ModalPublishConfirm.defaultProps = {
  isOpen: false,
};

export default ModalPublishConfirm;
