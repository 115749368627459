import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ContentCard } from '../../../components';

class ProfileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
    this.handleExpand = this.handleExpand.bind(this);
    this.handleItemsRender = this.handleItemsRender.bind(this);
  }

  handleItemsRender(visibleItemsAmount) {
    
    const { handleRemoveBtnClick, items, isPrivate } = this.props;
    const itemsList = [];
    for (let i = 0; i < visibleItemsAmount; i += 1) {
      const item = items[i];
      const withRemoveButton = handleRemoveBtnClick && isPrivate && item.type === 'tours';
      let removeBtnProps;
      if (withRemoveButton) removeBtnProps = { handleRemoveBtnClick };
      itemsList.push(
        <ContentCard
          withAuthor={false}
          contentModel={item}
          withEditButton={isPrivate}
          {...removeBtnProps}
          key={`${item.type}-${item.id}`}
          showCounter={item.type === 'pois'}
        />,
      );
    }
    return itemsList;
  }

  handleExpand(value) {
    this.setState({
      isExpanded: value,
    });
  }

  render() {
    const { handleItemsRender } = this;
    const { isExpanded } = this.state;
    const { items } = this.props;
    const collapsedAmount = items.length;
    const visibleItemsAmount = isExpanded ? items.length : collapsedAmount;
    return (
      <>
        <section className="row profile-list">
          <div className="col-12 profile-list__items">
            {handleItemsRender(visibleItemsAmount)}
          </div>
        </section>
        {/* <button
          type="button"
          className="btn btn-light btn-rounded"
          onClick={() => this.handleExpand(!isExpanded)}
        >
          {isExpanded ? 'mostrar menos' : 'descubrir más'}
        </button> */}
      </>
    );
  }
}

ProfileList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ).isRequired,
  isPrivate: PropTypes.bool.isRequired,
  handleRemoveBtnClick: PropTypes.func,
};

ProfileList.defaultProps = {
  handleRemoveBtnClick: null,
};

export default ProfileList;
