import api from '../../config/api-endpoints';
import generateReduxBeePayload from './generate-redux-bee-payload';
import reduxBeeRequestErrorHandler from './redux-bee-request-error-handler';

export default function reduxBeeMakeRequest(dispatch, endpoint, params, payload) {
  let generatedPayload = {};
  if (payload) {
    const {
      id, type, attributes, relationships,
    } = payload;
    generatedPayload = generateReduxBeePayload(id, type, attributes, relationships);
  }
  const request = dispatch(api[endpoint]({ ...params }, { ...generatedPayload }));
  if (!request) {
    throw new Error('Request could not be parsed');
  }
  return request.then(reduxBeeRequestErrorHandler).catch((error) => { throw error; });
}
