import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { isEmpty, getImageSrcSet } from '../utils';
import { PLUS_ICON, SIMPLE_FLAG_ICON, CHECK_ICON } from '../helpers';
import { PLACE_PAGE_DINAMIC_URL } from '../constants';
import TYPES from '../constants/types';
import ResponsiveImg from './responsive-img';

function PoiMapPopUp(props) {

  const {
    poi, closeModal, addButtonCB, alreadyPresent,
  } = props;
  if (!poi) return null;
  const {
    title, mainImage, relatedOwner, 'url-title': urlTitle,
  } = (poi && poi.attributes) || {};
  const { name, surname } = (relatedOwner && relatedOwner.attributes) || { name: '', surname: '' };
  let poiImage;
  if (poi && poi.attributes && poi.attributes.images && poi.attributes.images.length > 0) {
    let image = poi.attributes.images;
    image.sort((a, b) => b.likes - a.likes)
    image[0]["tiny-thumb"] = image[0].tinyThumb
    let imageA = {
      attributes: image[0]
    }
    poiImage = imageA;
  }
  return (
    <Modal
      className={`poi-map-popup ${alreadyPresent ? 'poi-map-popup--added' : ''}`}
      overlayClassName="poi-map-popup__overlay"
      isOpen={!isEmpty(poi)}
      onRequestClose={closeModal}
    >
      <article className="poi-map-popup__content">
        <Link to={PLACE_PAGE_DINAMIC_URL(urlTitle)} className="poi-map-popup__show-btn">
          {
            poi.attributes["type-preview"] == 1 ? (
              <img src="../assets/images/destino_modo_previo_trazado.svg" className="imagePreview" alt="" srcset="" />
            ) : <ResponsiveImg
              className="poi-map-popup__image"
              wrapperClassName="poi-map-popup__image-wrapper"
              srcSet={getImageSrcSet(poiImage)}
            >
                <span className="sr-only">Imagen portada</span>
              </ResponsiveImg>
          }


        </Link>
        <div className="poi-map-popup__info">
          <p className="poi-map-popup__title">{title}</p>
          <p className="poi-map-popup__author">
            {SIMPLE_FLAG_ICON}
            {`${name} ${surname}`}
          </p>
        </div>
        {
          !isEmpty(addButtonCB) ? (
            <div className="poi-map-popup__actions">
              <button
                type="button"
                className="poi-map-popup__add-btn"
                onClick={e => (alreadyPresent ? e.preventDefault() : addButtonCB(e, poi))}
              >
                {alreadyPresent ? CHECK_ICON : PLUS_ICON}
              </button>
            </div>
          ) : null
        }
      </article >
    </Modal >
  );
}

PoiMapPopUp.propTypes = {
  poi: PropTypes.shape({
    attributes: PropTypes.shape({
      title: PropTypes.string.isRequired,
      mainImage: TYPES.imageType,
      relatedOwner: PropTypes.shape({
        type: PropTypes.oneOf(['users']),
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        attributes: PropTypes.shape({
          name: PropTypes.string,
          surname: PropTypes.string,
        }),
      }).isRequired,
    }),
  }),
  owner: PropTypes.shape({
    attributes: PropTypes.shape({}),
  }),
  alreadyPresent: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  addButtonCB: PropTypes.func,
};

PoiMapPopUp.defaultProps = {
  poi: null,
  owner: null,
  alreadyPresent: null,
  addButtonCB: null,
};

export default connect()(PoiMapPopUp);
