import isEmpty from './is-empty';

function orderPoiImages(poi, images) {
  const orderedImages = isEmpty(images)
    ? []
    : images.sort((a, b) =>
      (a.attributes.name > b.attributes.name ? 1 : a.attributes.name < b.attributes.name ? -1 : 0),
    );
  if (!isEmpty(images) && !isEmpty(poi) && !isEmpty(poi.relationships)) {
    const mainImageData = poi.relationships['main-image'].data;
    if (!isEmpty(mainImageData) && !isEmpty(mainImageData.id)) {
      const mainImageIndex = images.findIndex(elem => !isEmpty(elem) && elem.id === mainImageData.id);
      const element = images[mainImageIndex];
      images.splice(mainImageIndex, 1);
      images.splice(0, 0, element);
    }
  }
  return orderedImages;
}

export default orderPoiImages;
