import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SortableHandle } from 'react-sortable-hoc';
import {
  CLOSE_ICON, EYE_ICON, EYE_NOT_ICON, INBOX_ICON,
} from '../../../../../../../helpers';
import { getTourContentThumbImg, isEmpty, isEmptyObject } from '../../../../../../../utils';
import TYPES from '../../../../../../../constants/types';
import useGetRelationshipResource from '../../../../../../../helpers/hooks/useGetRelationshipResource';
import { PromiseButton, ResponsiveImg } from '../../../../../../../components';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import ENV from '../../../../../../../config/environment';

const DragHandle = SortableHandle(({ children }) => <>{children}</>);

class TourContentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pois: [],
      imageCachePoi: null,
      titleCachePoi: null
    };
  }
  componentDidMount() {
    this.setState({
      pois: this.props.pois,
    })
    this.getImagesPoi()
  }

  componentDidUpdate(prevProps) {
    if (this.props.pois.length !== prevProps.pois.length) {
      this.setState({
        pois: this.props.pois,
      })
      this.getImagesPoi()
    }
  }
  getImagesPoi = () => {
    const tourContent = this.props.tourContent;
    let poiImage = "";
    let poi;
    if (tourContent.attributes["content-type"] == 1) {
      poi = this.props.pois && this.props.pois.find(e => e.id == tourContent.relationships.poi.data.id)
      if (poi == undefined) {
        axios.get(`${ENV.host}/${ENV.namespace}/pois/${tourContent.relationships.poi.data.id}`)
          .then((res) => {
            let image = res.data.included;
            image.sort((a, b) => b.attributes.likes - a.attributes.likes)
            this.setState({ imageCachePoi: image[0], titleCachePoi: res.data.data.attributes.title })
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  render() {
    const {
      handleContentSelection,
      tourContent,
      handleDeleteTourContent,
      handleToogleTourContentVisibility,
      isMobile,
      tourContentArchives
    } = this.props;

    const {
      attributes: { 'content-type': contentType = null },
      relationships: { 'tour-contents': { data: tourContentChilds = null } = {} } = {},
    } = tourContent;
    // Only for the tour-content of place type need the related poi
    /* const tourContentPoi = contentType === 1 ? useGetRelationshipResource(tourContent, 'poi', 'pois', 'getPoi') : null; */
/*     const tourContentArchives = []/*  useGetRelationshipResource(
      tourContent,
      'archives',
      'archives',
      'getTourContentArchives',
    ); */
    let poiImage;
    let poi;
    // Only for the tour-content of place type need the related poi
   /*  const poiImages = contentType === 1
      ? useGetRelationshipResource(tourContentPoi, 'images', 'images', 'getPoiImages')
      : [];
    const mainImage = useGetRelationshipResource(tourContentPoi, 'main-image', 'images', 'getImage');
    if (contentType === 1) {
      displayedImage = mainImage || (!isEmptyObject(poiImages) && poiImages[0]) || null;
    }
    if ((contentType === 2 || contentType === 3) && !isEmptyObject(tourContentArchives)) {
      [displayedImage] = tourContentArchives;
    } */

    const hasChildContents = contentType === 1 && !isEmpty(tourContentChilds);

    if (tourContent.attributes["content-type"] == 1) {
      poi = this.state.pois && this.state.pois.find(e => e.id == tourContent.relationships.poi.data.id)
      if (poi && poi.attributes && poi.attributes.images && poi.attributes.images.length > 0) {
        let image = poi.attributes.images;
        image.sort((a, b) => b.likes - a.likes)
        image[0]["tiny-thumb"] = image[0].tinyThumb
        let imageA = {
          attributes: image[0]
        }
        poiImage = imageA;
      }
    }
    const displayedImage = (contentType === 2 || contentType === 3) && !isEmpty(tourContentArchives)
      ? tourContentArchives[0]
      : contentType === 1 && !isEmpty(poiImage)
        ? poiImage
        : this.state.imageCachePoi;
    // Acts as componentDidMount. as it's passed a [] as an argument and as componenWillUnmount on the return.

    // useEffect(() => {
    //   lockBodyScrolling();
    //   return restoreBodyScrolling;
    // }, []);
    let tooltipTitle;
    switch (contentType) {
      // Place or Service types
      case 1:
        tooltipTitle = poi && poi.attributes.title ? poi.attributes.title : this.state.titleCachePoi ? this.state.titleCachePoi : "NoName";
        break
      case 2:
        tooltipTitle = tourContent.attributes.title;
        break
      // Image type
      case 3:
        tooltipTitle = "imágenes"
        break
      // Text type
      case 4:
        tooltipTitle = tourContent.attributes.title;
        break
      /* Video type */
      case 5:
        tooltipTitle = "vídeo"
        break
      /* Map info type */
      case 6:
        tooltipTitle = "Mapa"
        break
      /* Time type */
      case 7:
        tooltipTitle = "Hora y tiempo de visita"
        break
      default:
        tooltipTitle = "Sin nombre"
        throw new Error('Sin nombre');
    }

    return (
      <>
        <div className="tour-content-modal__action-wrapper">
          {isMobile ? null : (
            <>
              <PromiseButton
                type="button"
                className="tour-content-modal__show"
                onClick={() => handleToogleTourContentVisibility(tourContent)}
                title="Mostrar/Ocultar"
                primarySpinner
              >
                {EYE_ICON}
              </PromiseButton>
              <PromiseButton
                type="button"
                className="tour-content-modal__remove"
                onClick={() => handleDeleteTourContent(tourContent)}
                title="Eliminar"
                primarySpinner
              >
                {CLOSE_ICON}
              </PromiseButton>
            </>
          )}
        </div>
        <button
          data-toggle="tooltip" data-tip={tooltipTitle} data-class='tooltipDesktop' data-place='top' data-type="dark"
          type="button"
          className="draggable-handle tour-content-modal__content-list-item-btn"
          onClick={() => {
            handleContentSelection(tourContent);
          }}
        >
          <DragHandle>
            <ResponsiveImg
              src={getTourContentThumbImg(contentType, displayedImage)}
              alt="Imagen"
              className={`
              draggable-handle tour-content-modal__content-list-item-img ${
                !isEmpty(displayedImage) && contentType <= 3
                  ? 'tour-content-modal__content-list-item-img--cover'
                  : ''
                }`
              }
              wrapperClassName="tour-content-modal__content-list-item-wrapper"
            />
            {(contentType === 2 || contentType === 3)
              && !isEmptyObject(tourContentArchives)
              && tourContentArchives.length > 0 ? (
                <span className="tour-content-modal__content-list-item-balloon">
                  {tourContentArchives.length}
                </span>
              ) : null}
            {hasChildContents ? (
              <span className="day-content__list-item-balloon day-content__list-item-balloon--icon">
                {INBOX_ICON}
              </span>
            ) : null}
          </DragHandle>

          <DragHandle>
            <div className={tourContent.attributes.visible ? 'd-none' : 'tour-content-modal__no-visible-layer'}>
              {EYE_NOT_ICON}
            </div>
          </DragHandle>
        </button>
        <ReactTooltip effect="solid" html />
      </>
    );
  };
}

TourContentModal.propTypes = {
  tourContent: TYPES.tourContentType.isRequired,
  handleContentSelection: PropTypes.func.isRequired,
  handleDeleteTourContent: PropTypes.func.isRequired,
  handleToogleTourContentVisibility: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { tourContent } = ownProps;
  const beeTourContent = state.bees.entities.tourContents[tourContent.id];
  const {
    attributes: { 'content-type': contentType },
    relationships: {
      archives: { data: archivesData },
    },
  } = beeTourContent;

  const tourContentArchives = isEmpty(archivesData)
    ? []
    : archivesData
      .map(archiveData => state.bees.entities[archiveData.type][archiveData.id])
      .sort((a, b) => a.attributes.order - b.attributes.order);
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    tourContentArchives
  };
}
export default connect(mapStateToProps)(TourContentModal);
