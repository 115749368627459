import isEmpty from './is-empty';

/**
  modifies isEmpty() so that an object without any keys returns true

  isEmptyObject({});               // true
  ```
  @method isEmptyObject
  @param {Object} obj Value to test
  @return {Boolean}
  @public
*/

export default function isEmptyObject(obj) {
  if (obj !== undefined && obj !== null && typeof obj === 'object') {
    return Object.keys(obj).length === 0;
  }

  return isEmpty(obj);
}
