import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TourContentServiceBoxRecommendationsTemplate from './tour-content-service-box-recommendations-template';
import TourContentServiceBoxTimelineTemplate from './tour-content-service-box-timeline-template';
import useGetResource from '../../../../../../helpers/hooks/useGetResource';
import TYPES from '../../../../../../constants/types';
import { GOOGLE_MARKER } from '../../../../../../utils/google-maps-utils';
import { isEmpty } from '../../../../../../utils';
import RecommendationImagesBox from './parts/RecommendationImagesBox';
import RecommendationInfoBox from './parts/RecommendationInfoBox';

/*
  layoutMode -> 0: hide, 1: simple, 2: premium/expanded
*/
const layoutMode = (tourContentTypeFilter, displayMode) => {
  if (tourContentTypeFilter === 1) {
    switch (parseInt(displayMode, 10)) {
      case 0:
      case 3:
        return 1;
      case 2:
      case 5:
        return 0;
      case 1:
      case 4:
        return 2;
      default:
        return 0;
    }
  } else if (tourContentTypeFilter === 2) {
    switch (parseInt(displayMode, 10)) {
      case 0:
      case 1:
      case 2:
        return 1;
      case 3:
      case 4:
      case 5:
        return 2;
      default:
        return 0;
    }
  } else {
    return 0;
  }
};

const TourContentService = (props) => {
  const {
    tourContent, author, authorAvatar, tourContentTypeFilter, handleShowRecommendation,
  } = props;
  const { relationships: { poi: { data: { id: poiId = null } = {} } = {} } = {} } = tourContent || {};
  const poi = useGetResource({ id: poiId }, 'pois', 'getPoi');
  const { relationships: { owner: { data: { id: ownerId = null } = {} } = {} } = {} } = poi || {};
  const owner = useGetResource({ ...(!isEmpty(ownerId) && { id: ownerId }) }, 'users', 'getUser');
  const images = useGetResource({ id: tourContent.id }, 'archives', 'getTourContentArchives');
  const { 'display-mode': displayMode } = tourContent.attributes;

  const [carouselPosition, setCarouselPosition] = useState(0);
  const [mapInstance, setMapInstance] = useState(null);
  const [areTilesLoaded, setAreTilesLoaded] = useState(0);
  const [isShowedImages, setIsShowedImages] = useState(false);
  const [isShowedInfo, setIsShowedInfo] = useState(false);

  useEffect(() => {
    if (!isEmpty(mapInstance) && !isEmpty(poi) && areTilesLoaded === 1) {
      const { attributes: { lat = null, long: lng = null } = {} } = poi;
      const bounds = new window.google.maps.LatLngBounds();
      const mapMarker = new window.google.maps.Marker({
        position: { lat, lng },
        map: mapInstance,
        icon: GOOGLE_MARKER(),
        clickable: false,
      });
      bounds.extend(mapMarker.getPosition());
      mapInstance.fitBounds(bounds, 5);
      mapInstance.setZoom(17);
    }
  }, [mapInstance, areTilesLoaded, poi]);

  const handleCarouselChange = (index) => {
    setCarouselPosition(index);
  };

  const handleMapOnMount = (map) => {
    setMapInstance(map);
  };

  const initializeMapBounds = () => {
    setAreTilesLoaded(areTilesLoaded + 1);
  };

  // Reset dropdowns state when the active tab (La Ruta/Recomendaciones/...) changes
  useEffect(
    () => () => {
      setIsShowedImages(false);
      setIsShowedInfo(false);
    },
    [tourContentTypeFilter],
  );

  const orderedImages = isEmpty(images)
    ? []
    : images.sort((a, b) => a.attributes.order - b.attributes.order);

  return (
    <>
      {tourContentTypeFilter === 1 ? (
        <TourContentServiceBoxTimelineTemplate
          tourContent={tourContent}
          images={orderedImages}
          poi={poi}
          author={author}
          authorAvatar={authorAvatar}
          owner={owner}
          layoutMode={layoutMode(tourContentTypeFilter, displayMode)}
          handleCarouselChange={handleCarouselChange}
          carouselPosition={carouselPosition}
          handleShowImages={() => setIsShowedImages(!isShowedImages)}
          handleShowInfo={() => setIsShowedInfo(!isShowedInfo)}
          handleShowRecommendation={handleShowRecommendation}
        />
      ) : (
          <TourContentServiceBoxRecommendationsTemplate
            tourContent={tourContent}
            images={orderedImages}
            poi={poi}
            author={author}
            authorAvatar={authorAvatar}
            owner={owner}
            layoutMode={layoutMode(tourContentTypeFilter, displayMode)}
            handleCarouselChange={handleCarouselChange}
            carouselPosition={carouselPosition}
            handleMapOnMount={handleMapOnMount}
            initializeMapBounds={initializeMapBounds}
            handleShowImages={() => setIsShowedImages(!isShowedImages)}
            handleShowInfo={() => setIsShowedInfo(!isShowedInfo)}
          />
        )}
      {isShowedInfo ? <RecommendationInfoBox service={poi} handleShowInfo={setIsShowedInfo} /> : null}
      {isShowedImages ? (
        <RecommendationImagesBox
          title={poi.attributes.title}
          images={orderedImages}
          handleShowImages={setIsShowedImages}
        />
      ) : null}
    </>
  );
};

export default TourContentService;

TourContentService.propTypes = {
  tourContent: TYPES.tourContentType.isRequired,
  author: TYPES.userType,
  authorAvatar: TYPES.imageType,
  tourContentTypeFilter: PropTypes.number.isRequired,
  handleShowRecommendation: PropTypes.func.isRequired,
};

TourContentService.defaultProps = {
  author: null,
  authorAvatar: null,
};
