import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { CIRCLE_CROSS_ICON } from '../helpers/svg-inline';
import { lockBodyScrolling, restoreBodyScrolling } from '../utils';

Modal.setAppElement('#root');

const ModalPublishCongrats = (props) => {
  const {
    isOpen, closeModal, poiPageRoute,
  } = props;

  useEffect(() => {
    lockBodyScrolling();
    return restoreBodyScrolling;
  }, []);

  return (
    <Modal
      className="modal-publish-congrats"
      overlayClassName="modal-publish-congrats__overlay"
      name="modal-publish-congrats"
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className="container-fluid">
        <button
          className="modal-publish-congrats__close-btn"
          type="button"
          onClick={closeModal}
        >
          {CIRCLE_CROSS_ICON}
        </button>
        <div className="row align-items-md-center">
          <div className="col-12 col-lg-auto">
            <figure>
              <img
                src="/assets/images/congrats.svg"
                alt="Aviso"
                className="modal-publish-congrats__main-image"
              />
            </figure>
          </div>
          <div className="col ml-lg-auto modal-publish-congrats__content">
            <h4 className="modal-publish-congrats__content-title">
              Enhorabuena
            </h4>
            <p className="modal-publish-congrats__content-text">
              Has publicado tu nuevo destino y esta pendiente de revisión.
              <br />
              En cuanto le demos el visto bueno estará disponible para todos
               los usuarios en la sección de &quot;Descubre&quot;.
            </p>

            <div className="row justify-content-center justify-content-lg-start mt-lg-5">
              <div className="col-auto pl-1">
                <Link
                  to={poiPageRoute}
                  className="btn btn-primary"
                >
                  ver destino
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalPublishCongrats.propTypes = {
  closeModal: PropTypes.func.isRequired,
  poiPageRoute: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

ModalPublishCongrats.defaultProps = {
  isOpen: false,
};

export default ModalPublishCongrats;
