import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { LOGIN_URL } from '../constants';

const PrivateRoute = ({
  component: Component, isAuthenticated, keyFromParams, ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      let key;
      if (keyFromParams) {
        key = `${keyFromParams}-${props.match.params[keyFromParams] || 'empty'}`;
      }
      return isAuthenticated ? (
        keyFromParams ? (
          <Component key={key} {...props} />
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect to={{ pathname: LOGIN_URL, redirectRoute: props.location.pathname }} />
      );
    }}
  />
);

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  keyFromParams: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.object),
  }),
};

PrivateRoute.defaultProps = {
  location: null,
  keyFromParams: null,
  match: null,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default connect(
  mapStateToProps,
  null,
)(PrivateRoute);
