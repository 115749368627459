import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { ANGEL_ICON, CIRCLE_CROSS_ICON } from '../../helpers';
import TYPES from '../../constants/types';
import { getImageSrcSet, checkExternalUrl, isEmpty } from '../../utils';
import ResponsiveImg from '../responsive-img';
import { Component } from 'react';
import Modal from 'react-modal';

class TourSponsorBoxTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoLink: null,
      showModalPartner: false,
    };
  }
  componentDidMount() {
    if (this.props.tourCampaign) {
      this.loadVideo();
    }
  }
  openModalPartner = () => {
    this.setState({ showModalPartner: true })
  }
  closeModalPartner = () => {
    this.setState({ showModalPartner: false })
  }
  loadVideo = () => {
    if (this.props.tourCampaign && this.props.tourCampaign.videoLink) {
      const videoLink = this.props.tourCampaign.videoLink;
      if (videoLink.indexOf("youtube") != -1) {
        this.setState({
          videoLink: videoLink.replace("watch?v=", "embed/")
        })
      } else if (videoLink.indexOf("vimeo") != -1) {
        this.setState({
          videoLink: videoLink.replace("https://vimeo.com/", "https://player.vimeo.com/video/")
        })
      }
    }
  }
  render() {
    const {
      handleCarouselChange, carouselPosition, isMobile, tourCampaign, campaignCarousel,
    } = this.props;
    const { title = null, description = null, linkCampaign = null, videoLink = null } = tourCampaign || {};
    const hasText = !isEmpty(title) || !isEmpty(description) || !isEmpty(linkCampaign);
    
    return (
      <article className="container tour-sponsor-box">
        <header className="row justify-content-end tour-sponsor-box__header">
          <div className="col-auto px-1">
            <span className="tour-sponsor-box__category">Patrocina esta ruta</span>
          </div>
          <div className="col-auto pl-1">
            <figure className="tour-sponsor-box__header-icon">{ANGEL_ICON}</figure>
          </div>
        </header>
        <div className="row">
          {campaignCarousel == false ? null : (
            <div className="col-12 col-lg-8 px-0">
              <Carousel
                showThumbs={false}
                showStatus={false}
                emulateTouch
                onChange={handleCarouselChange}
                selectedItem={carouselPosition}
              >
                {campaignCarousel.map((image, slideIndex) => (
                  <ResponsiveImg
                    wrapperClassName="tour-sponsor-box__slide"
                    className="tour-sponsor-box__slide-image"
                    srcSet={getImageSrcSet(image)}
                    key={`slide${slideIndex + 1}`}
                    loadingInverse={!isMobile}
                  />
                ))}
              </Carousel>
            </div>
          )}
          {hasText ? (
            <div className={`col-12 ${campaignCarousel == false ? '' : 'col-lg-4'} pt-3`}>
              {isEmpty(title) ? null : <h4 className="tour-sponsor-box__title">{title}</h4>}
              {isEmpty(description) ? null : <div className="tour-sponsor-box__p-container" dangerouslySetInnerHTML={{
                __html: description
              }}></div>}
              <div>

              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                {isEmpty(linkCampaign) ? null : (
                  <a
                    href={checkExternalUrl(linkCampaign)}
                    className="btn btn-primary tour-sponsor-box__link mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver más
                  </a>
                )}
                {isEmpty(this.state.videoLink) ? null : (
                  <a
                    onClick={this.openModalPartner}
                    className="btn btn-primary tour-sponsor-box__link botonWhite"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver vídeo
                  </a>
                )}
              </div>
            </div>
          ) : null}
          <Modal className="ModalVideo modalPartner"
            overlayClassName="ContentModal"
            isOpen={this.state.showModalPartner}
            contentLabel="ContentModal"
            onRequestClose={this.closeModalPartner}
            shouldCloseOnOverlayClick={true}
          >
            <div className=" alturaImagen pt-5">
              <div className="buttonClose">
                <button onClick={this.closeModalPartner} className="closeIcon p-0">
                  {CIRCLE_CROSS_ICON}
                </button>
              </div >
              <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" src={this.state.videoLink} allowfullscreen></iframe>
              </div>
            </div>
          </Modal>
        </div>
      </article>
    );
  };
}

TourSponsorBoxTemplate.propTypes = {
  sponsor: TYPES.sponsorType.isRequired,
  sponsorImages: PropTypes.arrayOf(TYPES.imageType),
  carouselPosition: PropTypes.number.isRequired,
  handleCarouselChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

TourSponsorBoxTemplate.defaultProps = {
  sponsorImages: [],
  isMobile: false,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(TourSponsorBoxTemplate);
