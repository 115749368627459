import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash.isequal';
import { isEmpty } from '../utils';
import { EMPTY_PHOTO_ICON } from '../helpers';

class ResponsiveImg extends Component {
  constructor(props) {
    super(props);
    this._imageRef = React.createRef();
    this._observer = null;
    const { type } = props;
    const intialState = {
      isLoaded: false,
      imageUrl: '/assets/images/transparent-img.png',
      isNoPhoto: false,
      observeAttemps: 0,
    };
    switch (type) {
      case 'sponsor':
        intialState.defaultImg = '/assets/images/test-partner.svg';
        break;
      default:
        intialState.defaultImg = null;
        break;
    }
    this.state = intialState;
    this.handleImageLoad = this.handleImageLoad.bind(this);
    this.handleVisibility = this.handleVisibility.bind(this);
    this.handleImageObserve = this.handleImageObserve.bind(this);
    this.getWrapperClasses = this.getWrapperClasses.bind(this);
  }

  componentDidMount() {
    this.handleImageObserve();
  }

  componentDidUpdate(prevProps) {
    const { srcSet: prevSrcSet, src: prevSrc } = prevProps;
    const { srcSet, src } = this.props;
    if (!_isEqual(prevSrcSet, srcSet) || prevSrc !== src) {
      if (!isEmpty(this._observer)) {
        this._observer.disconnect();
        this._observer = null;
        this._imageRef = React.createRef();
      }
      this.handleImageObserve();
    }
  }

  componentWillUnmount() {
    if (!isEmpty(this._observer)) {
      this._observer.disconnect();
    }
  }

  getWrapperClasses() {
    const { isLoaded, isNoPhoto } = this.state;
    const { wrapperClassName, loadingInverse } = this.props;
    let wrapperClasses = 'responsive-img';

    if (!isEmpty(wrapperClassName)) wrapperClasses += ` ${wrapperClassName}`;
    if (!isLoaded) wrapperClasses += ' responsive-img--loading';
    if (!isLoaded && loadingInverse) wrapperClasses += ' responsive-img--inverse';
    if (isNoPhoto) wrapperClasses += ' responsive-img--blue';

    return wrapperClasses;
  }

  handleImageObserve() {
    const { current: imgElement } = this._imageRef;
    const { observeAttemps } = this.state;
    const {
      srcSet,
      src,
    } = this.props;
    if (!isEmpty(imgElement)) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.handleVisibility();
              observer.unobserve(entry.target);
            }
          });
        },
        { rootMargin: '1000px' },
      );
      observer.observe(imgElement);
      this._observer = observer;
    } else if (observeAttemps < 3) {
      setTimeout(() => {
        this.setState({ observeAttemps: observeAttemps + 1, isNoPhoto: isEmpty(srcSet) && isEmpty(src) }, () => {
          this.handleImageObserve();
        });
      }, 300);
    }
  }

  handleImageLoad(imageUrl) {
    const img = new Image();
    const { defaultImg } = this.state;
    this.setState({ imageUrl, isLoaded: false }, () => {
      if (!isEmpty(imageUrl)) {
        img.src = imageUrl;
        img.onload = () => {
          // this._imageRef.current = img;
          this.setState({
            isLoaded: true,
          });
        };
        img.onerror = () => {
          this.setState({
            imageUrl: defaultImg,
            isLoaded: true,
            isNoPhoto: true,
          });
        };
      }
    });
  }

  handleVisibility() {
    const {
      srcSet, src, forzedVersion, forzedElementWidth,
    } = this.props;
    const [
      originalSrc = null,
      bigSrc = null,
      mediumSrc = null,
      smallThumb = null,
      tinyThumb = null,
    ] = srcSet || [];
    const imageElementWidth = this._imageRef.current.width;
    const { devicePixelRatio } = window;
    const realWidth = isEmpty(forzedElementWidth)
      ? imageElementWidth * devicePixelRatio
      : forzedElementWidth * devicePixelRatio;
    const { imageUrl, defaultImg } = this.state;
    let computedImageUrl = imageUrl;

    if (!isEmpty(forzedVersion) && !isEmpty(srcSet[forzedVersion])) {
      computedImageUrl = srcSet[forzedVersion];
    } else if (realWidth > 1920 && !isEmpty(originalSrc)) {
      computedImageUrl = originalSrc;
    } else if (realWidth > 1000 && !isEmpty(bigSrc)) {
      computedImageUrl = bigSrc;
    } else if (realWidth > 600 && !isEmpty(mediumSrc)) {
      computedImageUrl = mediumSrc;
    } else if (realWidth > 300 && !isEmpty(smallThumb)) {
      computedImageUrl = smallThumb;
    } else if (!isEmpty(tinyThumb)) {
      computedImageUrl = tinyThumb;
    } else if (!isEmpty(src)) {
      computedImageUrl = src;
    } else {
      this.setState({ isNoPhoto: true, ...(!isEmpty(defaultImg) && { imageUrl: defaultImg }) });
      if (!isEmpty(defaultImg)) computedImageUrl = defaultImg;
    }

    this.handleImageLoad(computedImageUrl);
  }

  render() {
    const { imageUrl, isNoPhoto } = this.state;
    const {
      className,
      alt,
      children,
      type,
      imageProps,
      allowContextMenu,
    } = this.props;
    // const isEmptyPhoto = isNoPhoto && type === 'card';

    return (
      <figure
        className={this.getWrapperClasses()}
        {
          ...!allowContextMenu && {
            onContextMenu: e => e.preventDefault(),
          }
        }
      >
        {isNoPhoto && type !== 'sponsor' ? (
          <span className={`${className} responsive-img__icon`}>
            {type === 'card' ? EMPTY_PHOTO_ICON : null}
          </span>
        ) : (
          <img
            ref={this._imageRef}
            src={imageUrl}
            className={className}
            alt={alt}
            {
              ...!allowContextMenu && {
                onContextMenu: e => e.preventDefault(),
              }
            }
            {...imageProps}
          />
        )}
        {children}
      </figure>
    );
  }
}

ResponsiveImg.propTypes = {
  srcSet: PropTypes.arrayOf(PropTypes.string),
  src: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  forzedVersion: PropTypes.number,
  forzedElementWidth: PropTypes.number,
  type: PropTypes.string,
  imageProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  loadingInverse: PropTypes.bool,
  allowContextMenu: PropTypes.bool,
};

ResponsiveImg.defaultProps = {
  srcSet: [],
  src: null,
  className: '',
  wrapperClassName: '',
  alt: null,
  children: null,
  forzedVersion: null,
  forzedElementWidth: null,
  type: null,
  imageProps: null,
  loadingInverse: false,
  allowContextMenu: false,
};

export default ResponsiveImg;
