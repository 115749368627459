import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorPageTemplate from './error-page-template';

class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(/* error */) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({ hasError: true, error, errorInfo });
  }

  render() {
    const {
      hasError, error, errorInfo,
    } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorPageTemplate
          error={error}
          errorInfo={errorInfo}
        />
      );
    }

    return children;
  }
}

ErrorPage.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorPage;
