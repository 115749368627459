import { UPDATE_PLACES_QP, UPDATE_SERVICES_QP } from '../constants';

const initialState = {
  placesTabQueryParams: null,
  servicesTabQueryParams: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PLACES_QP: {
      const newState = { ...state };
      newState.placesTabQueryParams = action.placesTabQueryParams;
      return { ...newState };
    }
    case UPDATE_SERVICES_QP: {
      const newState = { ...state };
      newState.servicesTabQueryParams = action.servicesTabQueryParams;
      return { ...newState };
    }
    default:
      return state;
  }
};
