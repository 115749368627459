import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import reduxStore from '../../../../reduxStore';
import { isEmpty } from '../../../../utils';
import TYPES from '../../../../constants/types';
import VirtualShopBoxTemplate from './virtual-shop-box-template';
import useGetResource from '../../../../helpers/hooks/useGetResource';

function getProductsImages(products) {
  const reduxImages = reduxStore.getState().bees.entities.images;
  return products.reduce((acc, product) => {
    const { relationships: { images: { data: imagesData = null } = {} } = {} } = product;
    acc[product.id] = isEmpty(imagesData)
      ? []
      : imagesData.reduce((acc2, imageData) => {
        const image = reduxImages[imageData.id];
        if (!isEmpty(image)) {
          acc2.push(image);
        }
        return acc2;
      }, []).sort((a, b) => a.attributes.order - b.attributes.order);
    return acc;
  }, {});
}
const VirtualShopBox = (props) => {
  const { authorUser, authorAvatar, handleVirtualShopRef } = props;
  const userProducts = useGetResource({ id: authorUser.id }, 'tours', 'getUserProducts');
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [mainProduct, setMainProduct] = useState(null);
  const [asideProducts, setAsideProducts] = useState([]);
  const [productImages, setProductImages] = useState(null);
  const [mainProductImages, setMainProductImages] = useState([]);
  const [carouselPosition, setCarouselPosition] = useState(0);

  const virtualShopRef = useRef();

  useEffect(() => {
    if (!isEmpty(userProducts)) {
      const newVisibleProducts = userProducts.filter(product => product.attributes.visible);
      setVisibleProducts(newVisibleProducts);
    }
  }, [userProducts]);

  useEffect(() => {
    if (isEmpty(mainProduct) && !isEmpty(visibleProducts)) {
      setMainProduct(visibleProducts[0]);
    }
    if (isEmpty(productImages) && !isEmpty(visibleProducts)) {
      const images = getProductsImages(visibleProducts);
      setProductImages(images);
    }
  }, [visibleProducts]);

  useEffect(() => {
    if (!isEmpty(virtualShopRef.current)) {
      handleVirtualShopRef(virtualShopRef.current);
    }
  }, [virtualShopRef.current]);

  useEffect(() => () => {
    handleVirtualShopRef(null);
  }, []);

  useEffect(() => {
    if (!isEmpty(visibleProducts) && !isEmpty(mainProduct)) {
      const newAsideProducts = visibleProducts.reduce((acc, product) => {
        if (parseInt(product.id, 10) !== parseInt(mainProduct.id, 10)) {
          acc.push(product);
        }
        return acc;
      }, []);
      setAsideProducts(newAsideProducts);
    }
  }, [visibleProducts, mainProduct]);

  useEffect(() => {
    if (!isEmpty(mainProduct) && !isEmpty(productImages)) {
      const images = productImages[mainProduct.id];
      const orderedImages = isEmpty(images) ? [] : images.sort((a, b) => a.attributes.order - b.attributes.order);
      setMainProductImages(orderedImages);
    }
  }, [mainProduct, productImages]);

  const handleCarouselChange = useCallback((index) => {
    setCarouselPosition(index);
  }, []);

  const handleSelectMainProduct = useCallback((product) => {
    setCarouselPosition(0);
    setMainProduct(product);
  });

  return isEmpty(mainProduct) ? null : (
    <VirtualShopBoxTemplate
      authorUser={authorUser}
      authorAvatar={authorAvatar}
      mainProduct={mainProduct}
      mainProductImages={mainProductImages}
      asideProducts={asideProducts}
      productImages={productImages}
      handleCarouselChange={handleCarouselChange}
      carouselPosition={carouselPosition}
      handleSelectMainProduct={handleSelectMainProduct}
      virtualShopRef={virtualShopRef}
    />
  );
};

VirtualShopBox.propTypes = {
  authorUser: TYPES.userType.isRequired,
  authorAvatar: TYPES.imageType,
  handleVirtualShopRef: PropTypes.func.isRequired,
};

VirtualShopBox.defaultProps = {
  authorAvatar: null,
};

function mapStateToProps(state, ownProps) {
  const { authorUser } = ownProps;
  const { relationships: { avatar: { data: avatarData = null } = {} } = {} } = authorUser;
  const authorAvatar = isEmpty(avatarData)
    ? null
    : state.bees.entities[avatarData.type][avatarData.id];

  return {
    authorAvatar,
  };
}

export default connect(mapStateToProps)(VirtualShopBox);
