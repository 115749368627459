import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TYPES from '../../constants/types';
import { isEmpty, lockBodyScrolling, restoreBodyScrolling } from '../../utils';
import ModalImageFileTemplate from './modal-image-file-template';
import useGetResource from '../../helpers/hooks/useGetResource';

const ModalImageFile = (props) => {
  const {
    isOpen, closeModal, poi, image, poiCover, storedImages,poiCampaign,
  } = props;
/* console.log(props) */
  if (image !== null) {
    var authorId = image.attributes["created-by"]
  }
  /* const { relationships: { author: { data: { id: authorId = null } = {} } = {} } = {} } = image || {}; */

  const author = useGetResource({ ...(!isEmpty(authorId) && { id: authorId }) }, 'users', 'getUser');

  const { relationships: { avatar: { data: avatarData = null } = {} } = {} } = author || {};
  const authorAvatar = isEmpty(avatarData) ? null : storedImages[avatarData.id];



  useEffect(() => {
    lockBodyScrolling();
    return restoreBodyScrolling;
  }, []);

  return !isOpen || isEmpty(image) ? null : (
    <ModalImageFileTemplate
      isOpen={isOpen}
      closeModal={closeModal}
      image={image}
      author={author}
      authorAvatar={authorAvatar}
      poi={poi}
      poiCover={poiCover}
      poiCampaign={poiCampaign}
      images={props.images}
    />
  );
};

ModalImageFile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  image: TYPES.imageType,
  poi: TYPES.poiType,
  poiCover: TYPES.imageType,
  storedImages: PropTypes.objectOf(TYPES.imageType),
};

ModalImageFile.defaultProps = {
  image: null,
  poi: null,
  poiCover: null,
  storedImages: [],
};

function mapStateToProps(state, ownProps) {
  const { poi } = ownProps;
  const {
    relationships: {
      'main-image': { data: mainImageData = null } = {},
      images: { data: poiImagesData = null } = {},
    } = {},
  } = poi || {};

  const poiCoverData = isEmpty(mainImageData)
    ? !isEmpty(poiImagesData) && poiImagesData[0]
    : mainImageData;
  const poiCover = isEmpty(poiCoverData)
    ? null
    : state.bees.entities[poiCoverData.type]
    && state.bees.entities[poiCoverData.type][poiCoverData.id];
  return {
    poiCover,
    storedImages: state.bees.entities.images,
  };
}

export default connect(mapStateToProps)(ModalImageFile);
