import {
  LOCALSTORAGE_SESSION,
  LOGOUT_ENDPOINT,
  LOGIN_ENDPOINT,
} from '../constants';
import authHeader from '../helpers/auth-header';
import { isEmpty } from '../utils';

function unauthenticate() {
  const localStorateSession = localStorage.getItem(LOCALSTORAGE_SESSION);
  // request to invalidate token and remove token from local storage
  if (!isEmpty(localStorateSession)) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader(),
      },
    };
    return fetch(`${LOGOUT_ENDPOINT}`, requestOptions)
      .then(() => {
        // store user details and jwt token in local storage to
        // keep user logged in between page refreshes
        localStorage.removeItem(LOCALSTORAGE_SESSION);
      });
  }
  return Promise.resolve();
}

function handleResponse(response) {
  return response.json()
    .then((jsonResponse) => {
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          return unauthenticate()
            .then(() => {
              window.location.reload(true);
            });
        }

        const errors = (jsonResponse && jsonResponse.errors) || response.statusText;
        return Promise.reject(errors);
      }

      return jsonResponse;
    });
}

function authenticate(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${LOGIN_ENDPOINT}`, requestOptions)
    .then(handleResponse)
    .then((sessionData) => {
      // store user details and jwt token in local storage to
      // keep user logged in between page refreshes
      localStorage.setItem(LOCALSTORAGE_SESSION, JSON.stringify(sessionData));

      return sessionData;
    });
}

const session = {
  authenticate,
  unauthenticate,
};

export default session;
