import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { CIRCLE_CROSS_ICON, ALERT_NOTICE } from '../helpers/svg-inline';
import * as ROUTES from '../constants/routes.constants';
import { lockBodyScrolling, restoreBodyScrolling, isEmpty } from '../utils';
import TYPES from '../constants/types';
import reduxBeeMakeRequest from '../utils/redux-bee/redux-bee-make-request';
import { dispatch } from '../reduxStore';
import { UPDATE_CURRENT_USER } from '../constants';
import DisabledPlaceEdition from './disabled-place-edition';

class ModalLogOut extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false
    }
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  componentWillReceiveProps(newProps) {
    if (newProps) {
      this.setState({ showModal: newProps.modalLogOut })
    }
  }

  handleCloseModal() {
    this.props.modalLogOutClose();
  }

  render() {
    return (
      <div>
        <Modal
          className="modal-log-out"
          overlayClassName="modal-log-out__overlay"
          isOpen={this.state.showModal}
          contentLabel="modal log out"
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.handleCloseModal}
        >
          <div className="contentModal d-flex justify-content-center">
            <div className="nav d-flex justify-content-end">
              <button onClick={this.handleCloseModal} className="closeIcon p-0">
                {CIRCLE_CROSS_ICON}
              </button>
            </div>
            <div className="contentCenter d-flex justify-content-center">
              <h3>Disponible sólo para usuarios registrados</h3>
              <p>
                Necesitas acceder con tu usuario para poder realizar esa acción.
                Si no tienes todavía una cuenta en Topdesti puedes crearla en minutos
                y de forma totalmente gratuita desde el botón "registrarme".
              </p>
            </div>
            <div className="contentBot d-flex justify-content-end">
              <Link
                onClick={this.handleCloseModal} to={ROUTES.LOGIN_URL} className="acceso btn btn-primary p-0"
              >
                acceso
                </Link>
              <Link
                onClick={this.handleCloseModal} to={ROUTES.REGISTER_URL} className="registrarme btn btn-primary p-0"
              >
                registrarme
                </Link>
            </div>
            <div className="contentAlert">
              <img
                alt='alert'
                src={this.props.url ? '../../../assets/images/aviso.svg' : '../assets/images/aviso.svg'}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const props = {};

export default ModalLogOut;
