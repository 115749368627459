import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, keyFromParams, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      let key;
      if (keyFromParams) {
        key = `${keyFromParams}-${props.match.params[keyFromParams] || 'empty'}`;
      }
      return keyFromParams ? <Component key={key} {...props} /> : <Component {...props} />;
    }}
  />
);

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  keyFromParams: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.object),
  }),
};

PublicRoute.defaultProps = {
  location: null,
  keyFromParams: null,
  match: null,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default connect(
  mapStateToProps,
  null,
)(PublicRoute);
