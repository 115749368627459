/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { FOOTER_CURVE } from '../helpers';
import * as ROUTES from '../constants/routes.constants';

function Footer() {
  return (
    <footer className="main-footer pb-3 pb-md-0 px-5">
      {FOOTER_CURVE}
      <div className="row">
        <div className="col-12 col-md-3 text-center font-foot py-4 pr-5">
          <Link to="/">
            <img src="/assets/images/logo_topdesti.svg" alt="logo topdesti" />
            <p>2019 TODOS LOS DERECHOS RESERVADOS</p>
          </Link>
        </div>
        <div className="col-12 col-md site-map py-4 px-5">
          <ul>
            <h4>Descubre</h4>
            <li>
              <Link to={ROUTES.DISCOVER_TOURS_URL}>
                Rutas
              </Link>
            </li>
            <li>
              <Link to={ROUTES.DISCOVER_PLACES_URL}>
              Destinos
              </Link>
            </li>
          </ul>
          {/* <ul>
            <h4>Aprende</h4>
            <li>
              <a href="">Cómo crear una ruta</a>
            </li>
            <li>
              <a href="">Cómo monetizar tus viajes</a>
            </li>
          </ul>
          <ul>
            <h4>Registra tu negocio</h4>
            <li>
              <a href="">Ventajas de usuaruios premium</a>
            </li>
            <li>
              <a href="">Tarifas</a>
            </li>
          </ul>
          <ul>
            <h4>Travel Angel</h4>
            <li>
              <Link to={ROUTES.REGISTER_URL}>
                Registro
              </Link>
            </li>
            <li>
              <a href="">Cómo hacer una campaña</a>
            </li>
          </ul> */}
        </div>
        <div className="col-12 col-md-auto align-self-md-end font-foot text-right">
          <Link to={ROUTES.TERM_CONDITIONS_URL}>
            TÉRMINOS Y CONDICIONES DE USO
          </Link>
          <br />
          <Link to={ROUTES.PRIVACY_URL}>
            POLÍTICA DE PRIVACIDAD
          </Link>
          <br />
          <Link to={ROUTES.COOKIES_URL}>
            POLÍTICA DE COOKIES
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
