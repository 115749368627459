/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import {
  FLAG_BASE_ICON,
  TOUR_CURVE,
  TOP_TRAVELER_IMG,
  CIRCLE_CROSS_ICON,
} from '../../../helpers/svg-inline';
import { isEmpty, getImageSrcSet } from '../../../utils';
import TYPES from '../../../constants/types';
import ResponsiveImg from '../../responsive-img';
import isEmptyObject from '../../../utils/isEmptyObject';
import * as ROUTES from '../../../constants/routes.constants';
import api from '../../../config/api-endpoints';
import ENV from '../../../config/environment';
// import handleError from '../../../utils/errorHandler';
import ModalOnlyUsers from '../../modal-log-out';
import Modal from 'react-modal';
import axios from 'axios'

function generateDayOptions(tourDuration, selection) {
  const dayOptions = [];
  if (!isEmpty(tourDuration) && tourDuration >= 1) {
    for (let day = 1; day <= tourDuration; day += 1) {
      dayOptions.push({
        value: day,
        label: `${selection ? 'Página' : 'Día'} ${day}`,
      });
    }
  }
  return dayOptions;
}


class TourHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLogOut: false,
      showModalComments: false,
    };
    this.openModalComments = this.openModalComments.bind(this);
    this.closeModalComments = this.closeModalComments.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleGoToAuthorBox = this.handleGoToAuthorBox.bind(this);
    // this.state = {
    //   liked: false
    // }
  }
  openModalComments = () => {
    this.setState({ showModalComments: true })
  }
  closeModalComments = () => {
    this.setState({ showModalComments: false })
  }
  modalLogOutOpen = () => {
    this.setState({ showModalLogOut: true })
  }
  handlemodalLogOutClose = () => {
    this.setState({ showModalLogOut: false })
  }

  componentDidMount() {
    // fetch(`${ENV.host}/${ENV.namespace}/auth/commento`)
    //   .then(response => {
    //     return response.json();
    //   })
    //   .then((data) => {
    //     console.log(data)
    //   });
    
    axios.get(`${ENV.host}/${ENV.namespace}/auth/saveUser/13123`)
      .then(function (response) {
        // handle success
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    this._liked()
    this._handleAddView()
  }

  _handleAddView = () => {
    fetch(`${ENV.host}/${ENV.namespace}/tours/addview/${this.props.tour.id}`)
      .then(response => {
        return response.json();
      })
      .then((data) => {
        this.props.dispatch(api.getTour({ id: this.props.tour.id }));
      });
  }

  handleSelect(selectedOption) {
    const { handleDaySelection } = this.props;
    handleDaySelection(selectedOption.value);
  }

  handleGoToAuthorBox() {
    const { authorBoxRef, isMobile, tour } = this.props;
    const { current: authorBoxElement = null } = authorBoxRef || {};
    if (!isEmpty(authorBoxElement)) {
      const headerHeight = isMobile ? 100 : 70;
      const auxTopbar = tour.attributes.state === 3 ? 0 : 50;
      const scrollPosition = authorBoxElement.offsetTop - 50 - headerHeight - auxTopbar;
      window.scrollTo(0, scrollPosition);
    }
  }

  _liked = () => {
    if (this.props.likes && this.props.auth.isAuthenticated) {
      let liked = Object.values(this.props.likes).find(like => (
        like.attributes['user-id'] === Number(this.props.auth.currentUser.id) &&
        like.attributes['tour-id'] === Number(this.props.tour.id)
      ))
      this.setState({ liked: liked && liked.attributes['deleted-at'] === null })
    } else {
      this.setState({ liked: false })
    }
  }

  handleLike = async () => {
    this.setState({ liked: !this.state.liked })
    // this.props.dispatch(
    //   api.likeDislike({
    //     data: {
    //       type: 'likes',
    //       attributes: {
    //         tourId: this.props.tour.id,
    //         userId: this.props.auth.currentUser.id,
    //       },
    //     },
    //   }),
    // )
    //   .then((like) => {
    //     this.props.dispatch(api.getTour({ id: this.props.tour.id }));
    //   })
    //   .catch(error => handleError(error));
    let like = await fetch(`${ENV.host}/${ENV.namespace}/tours/like`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tourId: this.props.tour.id,
        userId: this.props.auth.currentUser.id,
      }),
    });
    if (like) this.props.dispatch(api.getTour({ id: this.props.tour.id }));
  };

  render() {
    const { handleSelect } = this;
    const {
      tour,
      tourTags,
      tourOwner,
      tourOwnerAvatar,
      tourDesktopImage,
      tourPhoneImage,
      isMobile,
      activeDay,
    } = this.props;
    const {
      attributes: {
        description,
        title,
        'published-at': pubblishedAt,
        'destination-pois-counter': destinationPoisCounter = 0,
        'service-pois-counter': servicePoisCounter = 0,
        'tour-duration': tourDuration = 1,
        'tour-type': tourType,
        'staff-pick': staffPick = false,
        'url-title': urlTitle,
        state: tourState,
        views,
        selection,
        'likes-number': likesNumber,
      },
    } = tour;
    const isRanking = tourType === 1;
    const dayOptions = tourDuration ? generateDayOptions(tourDuration, selection) : generateDayOptions(1, selection);
    const selectedDay = dayOptions.find(option => option.value === activeDay);

    const publishedAtLocal = pubblishedAt ? new Date(pubblishedAt) : null;

    const {
      attributes: { name: ownerName = null, surname: ownerSurname = null, toptraveler } = {},
    } = tourOwner || {};

    // get the avatar with a fallback to default avatar thumb
    const avatarThumb = isEmptyObject(tourOwnerAvatar) ? null : tourOwnerAvatar.attributes.thumb;

    const coverImage = isMobile && !isEmpty(tourPhoneImage) ? tourPhoneImage : tourDesktopImage;
    const shareLinkRRSS = `${ROUTES.TOUR_INFO_DINAMIC_URL_ABSOLUTE(urlTitle)}`;

    return (
      <header
        className={`container-fluid tour-header ${staffPick ? 'tour-header--staff-pick' : ''}`}
      >
        {isEmpty(coverImage) ? null : (
          <div className="row">
            <div className="col-12 px-0">
              <ResponsiveImg
                srcSet={getImageSrcSet(coverImage)}
                className="tour-header__cover-image"
                wrapperClassName="tour-header__cover"
                alt="Imagen portada"
              >
                <section className="tour-header__cover-data">
                  {publishedAtLocal ? (
                    <p className="tour-header__date">
                      Publicado: {publishedAtLocal.toLocaleDateString('en-GB')}
                    </p>
                  ) : null}
                  {title ? <h2 className="tour-header__cover-title">{title}</h2> : null}
                  <button
                    type="button"
                    className="tour-header__cover-author"
                    onClick={this.handleGoToAuthorBox}
                  >
                    <ResponsiveImg
                      className="tour-header__cover-avatar"
                      type="avatar"
                      alt="Avatar autor"
                      wrapperClassName="tour-header__cover-avatar-wrapper"
                      src={avatarThumb}
                      key="cover-author-avatar"
                    >
                      <figcaption className="sr-only">Avatar del autor</figcaption>
                    </ResponsiveImg>
                    {!ownerName && !ownerSurname ? null : (
                      <h4 className="tour-header__cover-author-name">
                        {`${ownerName} ${ownerSurname}`}
                      </h4>
                    )}
                    {toptraveler ? (
                      <figure className="tour-header__cover-author-toptraveler">
                        {TOP_TRAVELER_IMG}
                      </figure>
                    ) : null}
                  </button>
                </section>
                {isMobile ? null : TOUR_CURVE}
              </ResponsiveImg>
            </div>
          </div>
        )}
        <section className="row tour-header__body">
          {/* HIDE UNTIL ACTIONS (LIKE, REPORT, ETC.) IMPLEMENTATION
        <div className="col-12 col-lg-7"> */}
          <div className="col-12 col-lg">
            <div className="row align-items-lg-center">
              {isEmpty(coverImage) ? (
                <div className="col-12 col-lg-auto">
                  <div className="tour-header__body-author">
                    <ResponsiveImg
                      srcSet={getImageSrcSet(tourOwnerAvatar)}
                      className="tour-header__body-avatar"
                      type="avatar"
                      alt="Avatar autor"
                      wrapperClassName="tour-header__body-avatar-wrapper"
                    >
                      <figcaption className="sr-only">Avatar del autor</figcaption>
                    </ResponsiveImg>
                    <div>
                      {publishedAtLocal ? (
                        <p className="tour-header__body-date">
                          Publicado: {publishedAtLocal.toLocaleDateString('en-GB')}
                        </p>
                      ) : null}
                      {!ownerName && !ownerSurname ? null : (
                        <p className="tour-header__body-author-name">{`${ownerName} ${ownerSurname}`}</p>
                      )}
                    </div>
                  </div>
                  {isMobile && !isEmpty(title) ? (
                    <h2 className="tour-header__title">{title}</h2>
                  ) : null}
                </div>
              ) : null}
              <div className="col">
                {tourState > 1 ? (
                  <ul className="tour-header__rrss">
                    <div>
                      <p>COMPARTE ESTA RUTA</p>
                      <li className="tour-header__rrss-item">
                        <FacebookShareButton className="tour-header__rrss-link" url={shareLinkRRSS}>
                          <FacebookIcon size={50} round />
                        </FacebookShareButton>
                      </li>
                      <li className="tour-header__rrss-item">
                        <TwitterShareButton
                          className="tour-header__rrss-link"
                          url={shareLinkRRSS}
                          title={title}
                        >
                          <TwitterIcon size={50} round />
                        </TwitterShareButton>
                      </li>
                      <li className="tour-header__rrss-item">
                        <WhatsappShareButton className="tour-header__rrss-link" url={shareLinkRRSS}>
                          <WhatsappIcon size={50} round />
                        </WhatsappShareButton>
                      </li>
                    </div>
                    {this.props.auth.isAuthenticated ? <div>
                      <li className="tour-header__rrss-item likeTour">
                        <p>ME GUSTA</p>
                        <button onClick={this.handleLike} className="buttonLike">
                          <img
                            alt='like button'
                            src={
                              this.state.liked
                                ? '../assets/images/heart.svg'
                                : '../assets/images/heart-outline.svg'
                            }
                          />
                        </button>
                      </li>
                      <li className="tour-header__rrss-item">
                        <p>COMENTA</p>
                        <button className="buttonLike" onClick={this.props.openModalComments}>
                          <img
                            alt='comment button'
                            src='../assets/images/comment.svg'
                          />
                        </button>
                      </li>
                    </div> :
                      <div>
                        <li className="tour-header__rrss-item likeTour">
                          <p>ME GUSTA</p>
                          <button onClick={this.modalLogOutOpen} className="buttonLike">
                            <img
                              alt='like button'
                              src='../assets/images/heart-outline.svg' />
                          </button>
                        </li>
                        <li className="tour-header__rrss-item">
                          <p>COMENTA</p>
                          <button onClick={this.props.openModalComments} className="buttonLike">
                            <img
                              alt='comment button'
                              src='../assets/images/comment.svg'
                            />
                          </button>
                        </li>
                      </div>
                    }
                    {/* <Modal className="ModalComments"
                      overlayClassName="ContentModal justify-content-end"
                      isOpen={this.state.showModalComments}
                      contentLabel="ContentModal"
                      onRequestClose={this.closeModalComments}
                      shouldCloseOnOverlayClick={true}
                    >
                      <div className="row m-0 d-flex justify-content-center">
                        <div className="buttonClose">
                          <button onClick={this.closeModalComments} className="closeIcon p-0">
                            {CIRCLE_CROSS_ICON}
                          </button>
                        </div >
                        <div className="d-flex justify-content-start w-100 mt-5">
                          <span className="title">Tus comentarios de: {title}</span>
                        </div>
                      </div>
                    </Modal> */}
                  </ul>
                ) : null}
                <ModalOnlyUsers
                  modalLogOut={this.state.showModalLogOut}
                  modalLogOutClose={this.handlemodalLogOutClose}
                />
              </div>
            </div>

            {!isMobile && !isEmpty(title) ? <h2 className="tour-header__title">{title}</h2> : null}
            {isEmpty(description) ? null : (
              <div className="customized-text description_route" dangerouslySetInnerHTML={{ __html: description }} />
            )}
            {tourTags ? (
              <ul className="tour-header__keywords">
                {tourTags.map(tag => (
                  <li key={`tag-${tag.attributes.name}`} className="tour-header__keywords-item">
                    {`#${tag.attributes.name}`}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
          {/* HIDE UNTIL ACTIONS (LIKE, REPORT, ETC.) IMPLEMENTATION
          <div className="col-12 col-lg-5"> */}
          <div className="col-12 col-lg-auto">
            <div className="row">
              <div className="col-auto tour-header__data">
                <p className="tour-header__data-text">{`${likesNumber} ME GUSTA`}</p>
                <p className="tour-header__data-text">{`${destinationPoisCounter} LUGARES`}</p>
                <p className="tour-header__data-text">{`${servicePoisCounter} RECOMENDACIONES`}</p>
                <p className="tour-header__data-text">{`${views ? views : 0} VISUALIZACIONES`}</p>
                {/* HIDE UNTIL ACTIONS (LIKE, REPORT, ETC.) IMPLEMENTATION
                <p className="tour-header__data-text">0 me gusta</p>
                <p className="tour-header__data-text">0 visualizaciones</p>
                <p className="tour-header__data-text">0 en favoritos</p> */}
              </div>
              {/* HIDE UNTIL ACTIONS (LIKE, REPORT, ETC.) IMPLEMENTATION
              <div className="col-7 tour-header__actions">
                <button type="button" className="btn btn-block tour-header__action-btn btn-light btn-rounded">
                  Añadir a favoritos
                </button>
                <button type="button" className="btn btn-block tour-header__action-btn btn-light btn-rounded">
                  Enviar a un amigo
                </button>
                <button type="button" className="btn btn-block tour-header__action-btn btn-light btn-rounded">
                  Denunciar contenido
                </button>
              </div> */}
            </div>
          </div>
          {!isMobile && !isRanking ? (
            <div className="col-12">
              <div className="tour-header__select-wrapper">
                <Select
                  value={selectedDay}
                  options={dayOptions}
                  onChange={handleSelect}
                  className="tour-header__select"
                  classNamePrefix="react-select"
                  isSearchable={false}
                />
                <img
                  src={FLAG_BASE_ICON}
                  alt="Seleccionar día"
                  className="tour-header__select-image"
                />
              </div>
            </div>
          ) : null}
        </section>
      </header>
    );
  }
}

TourHeader.propTypes = {
  tour: TYPES.tourType.isRequired,
  tourOwner: TYPES.userType,
  tourOwnerAvatar: TYPES.imageType,
  tourDesktopImage: TYPES.imageType,
  tourPhoneImage: TYPES.imageType,
  tourTags: PropTypes.arrayOf(TYPES.tagType),
  isMobile: PropTypes.bool.isRequired,
  activeDay: PropTypes.number.isRequired,
  handleDaySelection: PropTypes.func.isRequired,
  authorBoxRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

TourHeader.defaultProps = {
  tourOwner: null,
  tourOwnerAvatar: null,
  tourDesktopImage: null,
  tourPhoneImage: null,
  tourTags: null,
  authorBoxRef: null,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    auth: state.auth,
    likes: state.bees.entities.likes
  };
}

export default connect(mapStateToProps)(TourHeader);
