import PoisDestinationTab from './pois-destination-tab';
import PoisServiceTab from './pois-service-tab';
import ToursTab from './tours-tab';
import PreviewPoisDestinationTab from './preview-pois-destination-tab';

export default {
  PoisDestinationTab,
  PoisServiceTab,
  ToursTab,
  PreviewPoisDestinationTab,
};
