/* eslint-disable max-len */
/* eslint-disable react/prefer-stateless-function */
// richtext-editor styles
import 'react-quill/dist/quill.snow.css';
import './styles/App.scss';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReduxToastr from 'react-redux-toastr';
import { ConnectedRouter } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { history } from './helpers';
import {
  PrivateRoute, UnprivateRoute, PublicRoute, OwnershipRoute,
} from './components';

import {
  ProfilePage,
  LoginPage,
  RegisterPage,
  RecoveryPage,
  HomePage,
  PoiInfoPage,
  PoiImagesPage,
  TourEditionPage,
  TourInfoPage,
  PoiPage,
  DiscoverPage,
  NotFoundPage,
  PrivacyPage,
  ConditionsPage,
  CookiesPage,
  FaqsPage,
  Sponsorplace,
} from './pages';

import * as ROUTES from './constants/routes.constants';
import { UPDATE_VIEWPORT } from './constants';
import CookieFixedBanner from './components/cookie-fixed-banner';
import { isEmpty } from './utils';

const DISABLED_RESET_SCROLL_ROUTES = [
  '/place/',
  '/tour/',
];
class App extends Component {
  constructor(props) {
    super(props);
    this.updateViewport = this.updateViewport.bind(this);
    this._disabledResetScrollRoutes = DISABLED_RESET_SCROLL_ROUTES;
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateViewport();
    window.addEventListener('resize', this.updateViewport);
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props;

    if (pathname !== prevProps.pathname) {
      const isDisabledRoute = !isEmpty(
        DISABLED_RESET_SCROLL_ROUTES.filter(disabledRoute => pathname.includes(disabledRoute)),
      );
      if (!isDisabledRoute) {
        window.scrollTo(0, 0);
      }
    }
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateViewport);
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateViewport() {
    const { dispatch } = this.props;
    const currentWidth = window.innerWidth;
    const vh = window.innerHeight * 0.01;

    dispatch({ type: UPDATE_VIEWPORT, currentWidth });
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  render() {
    const { location } = this.props;
    return (
      <>
        <ReduxToastr position="top-center" />
        <div id="appWrapper">
          <Helmet titleTemplate="Topdesti %s">
            {/* *** HTML attributes *** */}
            <html lang="es" amp />
            {/* *** General head elements *** */}
            <link rel="canonical" href={`https://${location.host}${location.pathname}${location.search}`} />
            <link rel="alternate" hrefLang="x-default" href={`https://${location.host}${location.pathname}${location.search}`} />
            {/* *** General meta tags *** */}
            <meta name="title" content="Encuentra, comparte y gana dinero con tus viajes y Topdesti" />
            <meta name="description" content="Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas" />
            <meta name="keywords" content="Descubre, rutas, viaje, fotografía, vacaciones, recomendaciones, itinerarios, que visitar, que ver, ganar dinero viajando, " />
            {/* *** OpenGraph meta tags *** */}
            <meta property="fb:app_id" content="974537549420442" />
            <meta property="og:title" content="Topdesti: Gana dinero con tus viajes" />
            <meta property="og:url" content={location.href} />
            <meta property="og:description" content="Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas" />
            <meta property="og:image" content={`${location.origin}/assets/images/topdesti-logo.png`} />
            <meta property="og:image:secure_url" content={`https://${location.host}/assets/images/topdesti-logo.png`} />
            <meta property="og:image:width" content="132" />
            <meta property="og:image:height" content="38" />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="es_ES" />
            {/* *** Twitter meta tags *** */}
            <meta property="twitter:site" content="@Topdesti" />
            <meta property="twitter:creator" content="@Topdesti" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={location.href} />
            <meta property="twitter:title" content="Topdesti" />
            <meta property="twitter:description" content="Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas" />
            <meta property="twitter:image" content={`${location.origin}/assets/images/topdesti-logo.png`} />
            {/* Favicons */}
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="theme-color" content="#ffffff" />
          </Helmet>
          <ConnectedRouter history={history}>
            <Switch>
              {/** *************************  Public routes ********************************* */}
              {/* HOME PAGE */}
              <PublicRoute exact path="/" component={HomePage} />
              {/* DISCOVER */}
              <PublicRoute exact path={ROUTES.DISCOVER_URL} component={DiscoverPage} />
              {/* INFO */}
              <PublicRoute exact path={ROUTES.SPONSOR_PLACE_URL} component={Sponsorplace} />
              {/* PUBLIC PROFILE */}
              <PublicRoute
                exact
                path={ROUTES.USER_URL}
                keyFromParams="user_nickname"
                component={ProfilePage}
              />
              {/* POI VIEW */}
              <PublicRoute
                exact
                path={ROUTES.PLACE_PAGE_URL}
                keyFromParams="poi_id"
                component={PoiPage}
              />
              <PublicRoute
                exact
                path={ROUTES.PLACE_IMAGE_PAGE_URL}
                keyFromParams="poi_id"
                component={PoiPage}
              />
              {/* TOUR INFO */}
              <OwnershipRoute
                exact
                path={ROUTES.TOUR_INFO_URL}
                keyFromParams="urlTitle"
                component={TourInfoPage}
                modelType="tours"
                publishRequirement
              />
              {/* sponsor-place */}
              {/* <OwnershipRoute
                exact
                path={ROUTES.TOUR_INFO_URL}
                keyFromParams="urlTitle"
                component={Sponsorplace}
                modelType="tours"
                publishRequirement
              /> */}
              <OwnershipRoute
                exact
                path={ROUTES.TOUR_IMAGE_PAGE_URL}
                keyFromParams="urlTitle"
                component={TourInfoPage}
                modelType="tours"
                publishRequirement
              />
              {/** *******************  Unauthenticated only routes *************************** */}
              {/* LOGIN */}
              <UnprivateRoute exact path={ROUTES.LOGIN_URL} component={LoginPage} />
              {/* REGISTER */}
              <UnprivateRoute exact path={ROUTES.REGISTER_URL} component={RegisterPage} />
              {/* RECOVERY */}
              <UnprivateRoute exact path={ROUTES.RECOVERY_URL} component={RecoveryPage} />
              {/* PROFILE */}
              {/** *********************  Authenticated only routes *************************** */}
              <PrivateRoute exact path={ROUTES.ACCOUNT_URL} component={ProfilePage} />
              {/* POI EDITION - INFO */}
              <OwnershipRoute
                exact
                path={ROUTES.POI_EDITION_INFO_URL}
                keyFromParams="poi_id"
                component={PoiInfoPage}
                modelType="pois"
              />
              {/* POI EDITION - IMAGES */}
              <OwnershipRoute
                exact
                path={ROUTES.POI_EDITION_IMAGES_URL}
                keyFromParams="poi_id"
                component={PoiImagesPage}
                modelType="pois"
              />
              {/* TOUR EDITION */}
              <OwnershipRoute
                exact
                path={ROUTES.TOUR_EDITION_URL}
                keyFromParams="tour_id"
                component={TourEditionPage}
                modelType="tours"
              />
              {/* STATICS */}
              <Route
                exact
                path={ROUTES.FAQ_URL}
                component={FaqsPage}
              />
              <Route
                exact
                path={ROUTES.PRIVACY_URL}
                component={PrivacyPage}
              />
              <Route
                exact
                path={ROUTES.TERM_CONDITIONS_URL}
                component={ConditionsPage}
              />
              <Route
                exact
                path={ROUTES.COOKIES_URL}
                component={CookiesPage}
              />
              {/* 404 error page */}
              <Route exact path={ROUTES.NOT_FOUND_URL} render={() => <NotFoundPage />} />
              <Route render={() => <NotFoundPage />} />
            </Switch>
          </ConnectedRouter>
          <CookieFixedBanner />
        </div>
      </>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired,
    protocol: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    pathname: state.router.location.pathname,
    location: window.location,
  };
}

const connectedApp = connect(mapStateToProps)(App);

export default connectedApp;
