import React from 'react';
import PropTypes from 'prop-types';
import { SPINNER_ICON } from '../helpers';

const LoadingSpinner = (props) => {
  const { onlySpinner = false } = props;
  return (
    <section className={`loading-spinner ${onlySpinner ? 'loading-spinner--inline' : ''}`}>
      {onlySpinner ? null : (
        <img className="loading-spinner__logo" src="/assets/images/logo_topdesti.svg" alt="Logo" />
      )}
      <span className="loading-spinner__spinner loading-spinner__spinner--primary">{SPINNER_ICON}</span>

      {onlySpinner ? null : <h3 className="loading-spinner__text">Cargando...</h3>}
    </section>
  );
};

LoadingSpinner.propTypes = {
  onlySpinner: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  onlySpinner: false,
};

export default LoadingSpinner;
