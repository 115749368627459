import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PoiFile } from './parts';
import TYPES from '../../constants/types';
// import api from '../../config/api-endpoints';
import ENV from '../../config/environment';
import ModalOnlyUsers from '../modal-log-out';
import axios from 'axios';

class PoiView extends Component {
  state = {
    likes: [],
    views: [],
    showModalLogOut: false,
  };

  modalLogOutOpen = () => {
    this.setState({ showModalLogOut: true })
  }
  handlemodalLogOutClose = () => {
    this.setState({ showModalLogOut: false })
  }
  componentDidMount() {
    fetch(`${ENV.host}/${ENV.namespace}/pois/likespoi/${this.props.poi.id}/${true}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({ likes: data.likes, views: data.views });
        axios.post(`${ENV.host}/${ENV.namespace}/pois/user`, {
          token: this.props.currentUser.token,
        })
          .then((res) => {
            this._liked(res.data.user)
          })
          .catch((error) => {
            console.log(error);
          });
      });
  }

  handleLike = () => {
    this.setState({ liked: !this.state.liked });
    axios.post(`${ENV.host}/${ENV.namespace}/pois/likepoi`, {
      token: this.props.currentUser.token,
      poiId: this.props.poi.id
    })
      .then((res) => {
        this.setState({ likes: res.data.likes });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _liked = (user) => {
    if (this.props.currentUser.isAuthenticated && user) {
      let liked = Object.values(this.state.likes).find(
        like =>
          like.userId === Number(user)
      );
      this.setState({ liked: liked });
    } else {
      this.setState({ liked: false });
    }
  };

  render() {
    const { poi } = this.props;
    // console.log(poi);
    return (
      <>
        <PoiFile
          poi={poi}
          isAuthenticated={this.props.currentUser.isAuthenticated}
          likes={this.state.likes}
          views={this.state.views}
          liked={this.state.liked}
          handleLike={this.handleLike}
          modalLogOutOpen={this.modalLogOutOpen}
          openModalComments={this.props.openModalComments}
        />
        <ModalOnlyUsers
          modalLogOut={this.state.showModalLogOut}
          modalLogOutClose={this.handlemodalLogOutClose}
          url={true}
        />
      </>
    );
  }
}

PoiView.propTypes = {
  poi: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.oneOf(['pois']),
    attributes: PropTypes.shape({
      title: PropTypes.string,
    }).isRequired,
    relatedImages: PropTypes.arrayOf(
      TYPES.imageType,
    ).isRequired,
  }).isRequired,
};

export default PoiView;
