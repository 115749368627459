import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import useGetResource from '../helpers/hooks/useGetResource';
import { isEmpty } from '../utils';
import {
  UPDATE_CURRENT_USER, LOGIN_URL, NOT_FOUND_URL, LOCALSTORAGE_SESSION,
} from '../constants';
import LoadingSpinner from './loading-spinner';

const OwnershipRoute = ({
  component: Component,
  isAuthenticated,
  modelType,
  keyFromParams,
  computedMatch,
  dispatch,
  publishRequirement,
  isMobile,
  ...rest
}) => {
  if (isMobile && computedMatch.url.includes('/tour/')) {
    return (
      <Route
        {...rest}
        render={props => <Component key={key} {...props} />}
      />
    )
  }
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const key = `${keyFromParams}-${computedMatch.params[keyFromParams] || 'empty'}`;
  const resourceUniqueIdentifier = Object.values(computedMatch.params)[0];
  const currentUser = useGetResource(
    { ...(isAuthenticated && { id: 'me' }) },
    'me',
    'getCurrentUser',
    () => {
      // if /me fails, logout the user
      localStorage.removeItem(LOCALSTORAGE_SESSION);
      // and refresh the page
      window.location.reload();
    },
  );
  let getMethodName;
  let getMethodPayload;
  let relatedResource;
  const isUrlFriendly = !keyFromParams.includes('id');

  if (isUrlFriendly) {
    getMethodName = `get${modelType.charAt(0).toUpperCase()}${modelType.slice(1, modelType.length)}`;
    getMethodPayload = {
      [`filters[${keyFromParams}]`]: resourceUniqueIdentifier,
      ...((modelType === 'tours' || modelType === 'pois') && { 'filters[state]': [2, 4] }),
    };
  } else {
    getMethodName = `get${modelType.charAt(0).toUpperCase()}${modelType.slice(1, modelType.length - 1)}`;
    getMethodPayload = { id: resourceUniqueIdentifier };
  }
  let shouldReturn404 = false;
  const getResults = useGetResource(getMethodPayload, modelType, getMethodName);

  if (Array.isArray(getResults)) {
    if (getResults.length === 0) {
      shouldReturn404 = true;
    }
    [relatedResource] = getResults;
  } else {
    relatedResource = getResults;
  }


  useEffect(() => {
    if (!isEmpty(currentUser)) {
      dispatch({ type: UPDATE_CURRENT_USER, currentUser });
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if ((!isEmpty(currentUser) && !isEmpty(relatedResource)) || (!isAuthenticated && !isEmpty(relatedResource))) {
      setIsLoaded(true);
      const { id: ownerId = null } = isAuthenticated ? relatedResource.relationships.owner.data : {};
      const { state } = relatedResource.attributes;
      const isPublic = !publishRequirement || (publishRequirement && state !== 1);
      setIsAllowed((publishRequirement && isPublic) || (isAuthenticated && ownerId === currentUser.id) || (currentUser.id == 14 || currentUser.id == 40 || currentUser.id == 22));
    }
  }, [currentUser, isAuthenticated, publishRequirement, relatedResource]);

  if (shouldReturn404) {
    return <Redirect to={{ pathname: NOT_FOUND_URL, redirectRoute: computedMatch.url }} />;
  }
  if (!publishRequirement && !isAuthenticated) {
    return <Redirect to={{ pathname: LOGIN_URL, redirectRoute: computedMatch.url }} />;
  }
  /* if (computedMatch.url.includes('/tour/')){
    console.log(isMobile)
  } */
 /*  if (isMobile && computedMatch.url.includes('/tour/')) {
    return (
      <Route
        {...rest}
        render={props => <Component key={key} {...props} />}
      />
    )
  } */

  if (!isLoaded) {
      return <LoadingSpinner />;
  }
  return isAllowed ? (
    <Route
      {...rest}
      render={props => <Component key={key} {...props} />}
    />
  ) : (
    <Redirect to={{ pathname: '/', redirectRoute: computedMatch.url }} />
  );
};

OwnershipRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  modelType: PropTypes.string.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  keyFromParams: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  computedMatch: PropTypes.shape({
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  publishRequirement: PropTypes.bool,
};

OwnershipRoute.defaultProps = {
  location: null,
  keyFromParams: null,
  publishRequirement: false,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(
  mapStateToProps,
  null,
)(OwnershipRoute);
