import React from 'react';
import FileInput from 'react-fine-uploader/file-input';

import { PropTypes } from 'prop-types';
import CircularBar from '../../progress-bars/circular-bar/circular-bar';
import LinearBar from '../../progress-bars/linear-bar';
import { CLOSE_ICON } from '../../../../../helpers';
import isEmpty from '../../../../../utils/is-empty';

function MultipleThumbnailsWrapper(props) {
  const {
    uploaderFiles,
    filesProgresses,
    filesThumbnails,
    uploader,
    // uploadBtnCB,
    totalProgress,
    removeFromQueueCB,
    withProgressBars,
    withUploadBtn,
    withTotalProgressBar,
    isDropzone,
    children,
    uploadGoingOn,
    totalProgressBarType,
  } = props;
  const showProgressBar = withProgressBars && uploadGoingOn;
  const showTotalProgressBar = withTotalProgressBar && uploadGoingOn;
  const anyProgressBarShown = showTotalProgressBar || showProgressBar;
  const shouldRenderFileInput = (!isDropzone && !anyProgressBarShown) || withUploadBtn;
  return (
    <div className="fine-uploader__files-wrapper">
      {showTotalProgressBar ? (
        totalProgressBarType === 'linear' ? (
          <LinearBar progress={totalProgress} />
        ) : (
          <CircularBar progress={totalProgress} />
        )
      ) : null}
      {Object.keys(uploaderFiles).map((currFileObjKey) => {
        const currFile = uploaderFiles[currFileObjKey];
        const currFileProgress = filesProgresses[currFileObjKey].percentage || 0;
        // URL.createObjectURL(currFile.file); could work too
        const imagePreviewUrl = filesThumbnails[currFile.file.name] || null;
        return (
          <div key={`thumbnail-box-${currFile.file.name}`} className="thumbnails-wrapper__box">
            {showProgressBar ? <LinearBar progress={currFileProgress} /> : null}
            {imagePreviewUrl ? (
              <>
                <button
                  className="thumbnails-wrapper__remove-btn"
                  type="button"
                  onClick={(e) => {
                    removeFromQueueCB(e, currFile.id, currFile.file);
                  }}
                >
                  {CLOSE_ICON}
                </button>
                <img
                  src={imagePreviewUrl}
                  key={`${currFile.file.name}-${currFile.id}-thumbnail`}
                  className="thumbnails-wrapper__preview"
                  alt={`${currFile.file.name} thumbnail`}
                />
              </>
            ) : null}
          </div>
        );
      })}
      {/* {withUploadBtn ? (
        <FileInput
          className="fine-uploader__file-input"
          multiple
          accept="image/*"
          uploader={uploader}
        >
          <button type="button" className="btn btn-primary btn-block">
            Subir imágenes
          </button>
        </FileInput>
      ) : null} */}
      {shouldRenderFileInput ? (
        <FileInput
          className="fine-uploader__file-input"
          multiple
          accept="image/*"
          uploader={uploader}
        >
          {isEmpty(children) ? <span className="icon ion-upload">Choose Files</span> : children}
        </FileInput>
      ) : null}
    </div>
  );
}

MultipleThumbnailsWrapper.propTypes = {
  uploader: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.func])).isRequired,
  uploaderFiles: PropTypes.objectOf(PropTypes.object),
  filesProgresses: PropTypes.objectOf(PropTypes.object),
  filesThumbnails: PropTypes.objectOf(PropTypes.string),
  totalProgress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDropzone: PropTypes.bool,
  uploadGoingOn: PropTypes.bool,
  withTotalProgressBar: PropTypes.bool,
  withProgressBars: PropTypes.bool,
  withUploadBtn: PropTypes.bool,
  // uploadBtnCB: PropTypes.func,
  removeFromQueueCB: PropTypes.func,
  children: PropTypes.element,
  totalProgressBarType: PropTypes.string,
};
MultipleThumbnailsWrapper.defaultProps = {
  uploaderFiles: [],
  filesProgresses: [],
  filesThumbnails: [],
  totalProgress: 0,
  // uploadBtnCB: null,
  removeFromQueueCB: null,
  isDropzone: true,
  uploadGoingOn: false,
  withTotalProgressBar: false,
  withProgressBars: true,
  withUploadBtn: true,
  children: null,
  totalProgressBarType: 'circular',
};
export default MultipleThumbnailsWrapper;
