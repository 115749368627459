import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MainLayout from '../layouts/main-layout';
import { isEmpty } from '../utils';
import SocialButton from '../components/social-button';
import {
  FACEBOOK_LOGIN_ENDPOINT, LOCALSTORAGE_SESSION, RECOVERY_URL, REGISTER_URL,
} from '../constants';
import session from '../services/session';
import { loginSuccess, loginFailure } from '../actions';
import { history, USER_ICON, LOCK_ICON } from '../helpers';
import PromiseButton from '../components/promise-button';
import axios from 'axios';

function handleResponse(response) {
  return response.json().then((jsonResponse) => {
    if (!response.ok) {
      const errors = (jsonResponse && jsonResponse.errors) || response.statusText;
      return Promise.reject(errors);
    }

    return jsonResponse;
  });
}

function handleErrorMessages(errors) {
  return !isEmpty(errors)
    ? errors.map((error, idx) => {
      const errorKey = `error-${idx}`;
      return (
        <p key={errorKey} className="text-danger">
          {error.detail}
        </p>
      );
    })
    : null;
}

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      userpass: '',
      isLoading: false,
      redirectRoute: props.location.redirectRoute ? props.location.redirectRoute.pathname : null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSocialLogin = this.handleSocialLogin.bind(this);
    this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email, userpass, redirectRoute } = this.state;
    const { dispatch } = this.props;

    // stop here if form is invalid
    if (!(email && userpass)) {
      const errors = [{ detail: 'Introduce tu email y contraseña para continuar' }];
      dispatch(loginFailure(errors));
      return;
    }
    this.setState({ isLoading: true });

    if (email && userpass) {
      session.authenticate(email, userpass).then(
        (authSessionData) => {
          var origin = "https://commento.io";

          function get(url, callback) {
            var xmlDoc = new XMLHttpRequest();
        
            xmlDoc.open("GET", url, true);
            xmlDoc.onload = function() {
              callback(JSON.parse(xmlDoc.response));
            };
        
            xmlDoc.send(null);
          }

          function cookieSet(name, value) {
            var expires = "";
            var date = new Date();
            date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        
            document.cookie = name + "=" + value + expires + "; path=/";
          }

          get(origin + "/api/commenter/token/new", function(resp) {
            cookieSet("commentoCommenterToken", resp.commenterToken);
            if(window.location.origin == "https://staging.topdesti.com"){
              axios.get("https://staging-topdesti.afkar.digital:3000/api/v1/auth/saveUser/" + resp.commenterToken)
            }
            else{
              axios.get("https://production-topdesti.afkar.digital:3000/api/v1/auth/saveUser/" + resp.commenterToken)
            }
            
          })

          dispatch(loginSuccess(authSessionData));
          history.push(isEmpty(redirectRoute) ? '/' : redirectRoute);
        },
        (errors) => {
          dispatch(loginFailure(errors));
          this.setState({ isLoading: false });
        },
      );
    }
  }

  handleSocialLogin(facebookResponse) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'access-control-allow-origin': 'no-cors' },
      body: JSON.stringify(facebookResponse),
    };

    this.setState({ isLoading: true });
    return fetch(`${FACEBOOK_LOGIN_ENDPOINT}`, requestOptions)
      .then(handleResponse)
      .then((sessionData) => {
        const { dispatch } = this.props;
        const { redirectRoute } = this.state;
        localStorage.setItem(LOCALSTORAGE_SESSION, JSON.stringify(sessionData));

        dispatch(loginSuccess(sessionData));

        history.push(isEmpty(redirectRoute) ? '/' : redirectRoute);
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleSocialLoginFailure() {
    this.setState({ isLoading: false });
  }

  render() {
    const { email, userpass, isLoading } = this.state;
    const { errors } = this.props;

    return (
      <MainLayout>
        <Helmet>
          <title>: Inicia sesión</title>
          <meta name="title" content="Topdesti: Inicia sesión" />
          <meta property="og:title" content="Topdesti: Inicia sesión" />
          <meta property="twitter:title" content="Topdesti: Inicia sesión" />
          <meta name="description" content="Inicia sesión para acceder a tu perfil de Topdesti" />
          <meta property="og:description" content="Inicia sesión para acceder a tu perfil de Topdesti" />
          <meta property="twitter:description" content="Inicia sesión para acceder a tu perfil de Topdesti" />
          <meta property="of:image" content={`${window.location.origin}/assets/images/topdesti-logo.png`} />
          <meta property="twitter:image" content={`${window.location.origin}/assets/images/topdesti-logo.png`} />
          <meta property="og:image:width" content="132" />
          <meta property="og:image:height" content="38" />
        </Helmet>
        <div className="container-fluid grey-bg">
          <div className="row">
            <div className="container col-12 col-lg-8 col-xl-6">
              <div className="row intro-box">
                <div className="col-12 col-md-4 text-center">
                  <img src="/assets/images/login.png" alt="user typing" className="d-none d-md-block" />
                  <span className="svg-login d-block d-md-none">{USER_ICON}</span>
                </div>
                <div className="col-12 col-md-6 ml-auto mr-3 mt-5">
                  <h3 className="text-center mb-4 d-none d-md-block">Acceso de usuarios</h3>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="email" className="d-block">
                        {USER_ICON}
                        <input
                          name="email"
                          type="text"
                          autoComplete="email"
                          placeholder="EMAIL DE USUARIO"
                          className="form-control pl-5"
                          value={email}
                          onChange={this.handleChange}
                        />
                      </label>
                    </div>

                    <div className="form-group">
                      <label htmlFor="userpass" className="d-block">
                        {LOCK_ICON}
                        <input
                          name="userpass"
                          type="password"
                          autoComplete="current-password"
                          placeholder="CONTRASEÑA"
                          className="form-control pl-5"
                          value={userpass}
                          onChange={this.handleChange}
                        />
                      </label>
                    </div>

                    {handleErrorMessages(errors)}
                    <PromiseButton
                      type="submit"
                      className="btn btn-block btn-primary mb-2 mx-auto w75"
                      loadingCondition={isLoading}
                    >
                      Acceder
                    </PromiseButton>
                  </form>
                  <SocialButton
                    provider="facebook"
                    appId="974537549420442"
                    onLoginSuccess={this.handleSocialLogin}
                    onLoginFailure={this.handleSocialLoginFailure}
                    className="btn btn-block btn-secondary mx-auto w75"
                    autoCleanUri="true"
                  >
                    Entrar con Facebook
                  </SocialButton>
                </div>
                <div className="col-12 mb-4">
                  <hr />
                  <div className="row">
                    <div className="col-12 col-md text-center">
                      <Link to={RECOVERY_URL} className="d-inline-block">
                    ¿No recuerdas tu contraseña?
                      </Link>
                    </div>
                    <div className="col-12 col-md text-center">
                      <Link to={REGISTER_URL} className="d-inline-block">
                        ¿No tienes cuenta aún? Regístrate
                      </Link>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.auth.loginErrors,
  };
}

LoginPage.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string, redirectRoute: PropTypes.string }),
  history: PropTypes.shape({ lenght: PropTypes.number }),
  dispatch: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
};

LoginPage.defaultProps = {
  location: null,
  history: null,
  errors: null,
};

const connectedLoginPage = connect(
  mapStateToProps,
  null,
)(LoginPage);

export default connectedLoginPage;
