/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import InputRange from 'react-input-range-ios-fix';
import Textarea from 'react-textarea-autosize';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import api from '../../config/api-endpoints';
import {
  TopSelect, ValidationInput, PoiEditionHeader, PromiseButton,
} from '../../components';
import {
  CATEGORY_OPTIONS, POI_LANGUAGES, SERVICE_CATEGORIES, POI_EDITION_IMG_DINAMIC_URL,
} from '../../constants';
import TYPES from '../../constants/types';
import {
  MAP_CIRCLE_ICON,
  FLAG_BASE_ICON,
  MARKER_ICON,
  FLOWER_ICON,
  EIFFEL_TOWER_ICON,
  // IMAGE_ICON,
  // CHECK_ICON,
} from '../../helpers';
import TagAjaxManager from '../../components/autocomplete-tag/tag-ajax-manager';
import { isEmpty } from '../../utils';
import MainLayout from '../../layouts/main-layout';
import DisabledPlaceEdition from '../../components/disabled-place-edition';
import PoiInfoPagePreviewTemplate from './poi-info-page-preview-template';

const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];

class PoiInfoPageTemplate extends Component {
  state = {
    hour: "",
    min: "",
  }
  componentDidMount() {
    if (this.props.poi.attributes["poi-duration"] != null) {
      this.setState({
        hour: this.props.formattedDuration(this.props.poi.attributes["poi-duration"]).hour,
        min: this.props.formattedDuration(this.props.poi.attributes["poi-duration"]).min
      })
    }
  }

  render() {
    const {
      formattedDuration,
      computeDuration,
      handleChange,
      handleSelect,
      handleRange,
      handleSave,
      openModal,
      poi,
      hour,
      min,
      dispatch,
      poiId,
      errors,
      isMobile,
      hasRequirements,
      hasPublishRequirements,
      handleShowPublishModal,
      auth,
    } = this.props;
    const {
      attributes: {
        'poi-type': poiType,
        assessment,
        title,
        description,
        category,
        lat,
        long,
        township,
        'full-address': fullAddress,
        phone,
        price,
        website,
        'twitter-url': twitterUrl,
        'facebook-url': facebookUrl,
        'instagram-url': instagramUrl,
        schedule,
        language = 'es-es',
        state: poiState,
        'poi-duration': poiDuration,
      } = {},
    } = poi || {};
    const poiName = poiType === 1 ? 'destino' : 'servicio';
    const categoryOptions = poiType === 1 ? CATEGORY_OPTIONS : SERVICE_CATEGORIES;

    const selectedCategory = categoryOptions.find(option => option.value === category);
    const selectedLanguage = POI_LANGUAGES.find(option => option.value === language);
    const modelErrors = {};
    errors.forEach((error) => {
      if (error.source && error.source.pointer) {
        const attributeName = error.source.pointer.split('/').pop();
        modelErrors[attributeName] = error.title || error.detail;
      }
    });

    // Disabled edition on mobile
    // const contextualActions = () => (
    //   <ul className="navbar-nav d-lg-none navbar-contextuals">
    //     <li className="nav-item">
    //       <PromiseButton
    //         type="button"
    //         className="nav-link"
    //         onClick={() => handleSave(null, POI_EDITION_IMG_DINAMIC_URL(poiId))}
    //         disabled={!hasRequirements}
    //       >
    //         {IMAGE_ICON}
    //       </PromiseButton>
    //     </li>
    //     <li className="nav-item">
    //       <PromiseButton
    //         type="button"
    //         className="nav-link"
    //         title="Guardar"
    //         onClick={() => handleSave(null)}
    //       >
    //         {CHECK_ICON}
    //       </PromiseButton>
    //     </li>
    //   </ul>
    // );

    const headerDescription = poiType === 1 ? 'Rellena la primera parte del formulario para habilitar el botón '
      + 'inferior con la subida de imágenes. Al completar los campos obligatorios del formulario y al menos una '
      + 'imagen del destino podrás publicarlo y añadirlo en tus rutas. Recuerda que para que se vea de forma '
      + 'pública en la sección de “Descubre” necesitará pasar un proceso de validación (excepto si eres un '
      + 'usuario Toptraveler).' : 'Rellena el formulario y añade una imagen general para crear el recurso '
      + 'en la base de datos. Podrás después personalizarlo con tus comentarios, imágenes y vídeo si '
      + 'recomiendas este servicio en una ruta';
    return (
      <MainLayout
        // Disabled edition on mobile
        // ContextualActions={contextualActions}
        key={`poi${poiId}-form`}
      >
        {false ? (
          // Disabled edition on mobile
          <DisabledPlaceEdition />
        ) : (
            <>
              {(poi.relationships.owner.data.id == 14 || poi.relationships.owner.data.id == 40 || poi.relationships.owner.data.id == 22
                || auth.currentUser.id == 14 || auth.currentUser.id == 40 || auth.currentUser.id == 22) && poi.attributes["type-preview"] == 1 ? (
                  <PoiInfoPagePreviewTemplate
                    props={this.props}
                  >
                  </PoiInfoPagePreviewTemplate>
                ) : (
                  <section className="container-fluid poi-edition">
                    <div className="row">
                      <PoiEditionHeader mobileTitle="Crear nuevo destino">
                        <div className="col-lg-8 mx-auto">
                          <h3 className="poi-edition-header__title">
                            {`1. Añade la información del ${poiName}`}
                          </h3>
                          <p className="poi-edition-header__description">
                            {headerDescription}
                          </p>
                        </div>
                      </PoiEditionHeader>
                      <div className="container poi-info-page">
                        <div className="row">
                          <div className="col-12 col-lg-10">
                            <div className="poi-info-page__main">
                              <img
                                src={FLAG_BASE_ICON}
                                alt="Destino"
                                className="poi-info-page__main-deco poi-info-page__main-deco--top"
                              />
                              <img
                                src={MARKER_ICON}
                                alt="Destino"
                                className="poi-info-page__main-deco poi-info-page__main-deco--bottom"
                              />
                              <div className="form-group row mb-0 mb-lg-4">
                                <label htmlFor="category" className="col-auto col-form-label text-medium pl-lg-1">
                                  Idioma de entrada:
                      </label>
                                <div className="col-auto pl-lg-0">
                                  <TopSelect
                                    value={selectedLanguage}
                                    options={POI_LANGUAGES}
                                    name="language"
                                    handleChange={handleSelect}
                                    withIcons
                                  />
                                </div>
                              </div>
                              <div className="form-group row mb-0 mb-lg-4">
                                <label htmlFor="title" className="col-auto col-form-label text-medium pl-lg-1">
                                  Título:
                      </label>
                                <ValidationInput
                                  className="col-12 col-lg pl-lg-0"
                                  errorMsg={modelErrors.title}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    onChange={handleChange}
                                    value={title || ''}
                                  />
                                </ValidationInput>
                              </div>

                              <div className="form-group row mb-0 mb-lg-4">
                                <label htmlFor="category" className="col-auto col-form-label text-medium pl-lg-1">
                                  Categoría:
                      </label>
                                <div className="col-12 col-lg-6 pl-lg-0">
                                  <TopSelect
                                    value={selectedCategory}
                                    options={categoryOptions}
                                    name="category"
                                    placeholder="Selecciona una categoría"
                                    handleChange={handleSelect}
                                  />
                                </div>
                              </div>

                              <div className="form-group row mb-2 mb-lg-4">
                                <label htmlFor="description" className="col-auto col-form-label text-medium pl-lg-1">
                                  {poiType === 1 ? 'Descripción:' : 'Comentario'}
                                </label>
                                <ValidationInput
                                  className="col-12"
                                  errorMsg={modelErrors.description}
                                >
                                  <ReactQuill
                                    id="description"
                                    value={description || ''}
                                    // onChange={handleTextChange}
                                    onChange={handleChange}
                                    className="tour-config-main__rich-text-editor"
                                    modules={MODULES}
                                    formats={FORMATS}
                                  />
                                </ValidationInput>
                              </div>

                              <div className="row">
                                <div className="col-12 pl-lg-1">
                                  <h4 className="text-medium mb-0">Localización GPS:</h4>
                                  <div className="row align-items-center align-items-lg-end">
                                    <div className="col-7 col-lg-6 ml-auto">
                                      <div className="row">
                                        <ValidationInput
                                          className="col-10 col-lg ml-auto"
                                          errorMsg={modelErrors.lat}
                                        >
                                          <input
                                            type="number"
                                            className="form-control text-center"
                                            name="lat"
                                            value={lat || ''}
                                            readOnly
                                          />
                                          <p className="poi-info-page__location-text">Latitud</p>
                                        </ValidationInput>
                                        <ValidationInput
                                          className="col-10 col-lg ml-auto"
                                          errorMsg={modelErrors.lat}
                                        >
                                          <input
                                            type="number"
                                            className="form-control text-center mt-2 mt-lg-0"
                                            name="long"
                                            value={long || ''}
                                            readOnly
                                          />
                                          <p className="poi-info-page__location-text">Longitud</p>
                                        </ValidationInput>
                                      </div>
                                    </div>
                                    <div className="col-4 poi-info-page__location">
                                      <button
                                        href="#"
                                        className="poi-info-page__location-btn"
                                        type="button"
                                        onClick={openModal}
                                      >
                                        <img src={MAP_CIRCLE_ICON} alt="Mapa" className="poi-info-page__location-img" />
                                        <p className="poi-info-page__location-text">Ubicar con mapa</p>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row form-group mb-0 mb-lg-4">
                              <h4 className="col-12 poi-info-page__section-title">Campos opcionales</h4>
                              <label
                                htmlFor="keys"
                                className={`col-auto col-form-label text-medium pl-lg-1 ${poiState !== 3 ? 'pt-lg-3' : ''}`}
                              >
                                Palabras clave:
                    </label>
                              <div className="col-12 col-lg pl-lg-0">
                                <TagAjaxManager
                                  getCurrentResourceTags={() => dispatch(api.getPoiTags({ id: poiId }))}
                                  associateTagToResource={jsonApiRequest =>
                                    dispatch(api.upsertPoiTags({ id: poiId }, jsonApiRequest))}
                                  removeTagFromResource={jsonApiRequest =>
                                    dispatch(api.deletePoiTags({ id: poiId, tagId: jsonApiRequest.data.id }, jsonApiRequest))}
                                  canDelete={poiState !== 3 || auth.currentUser.id == 14 || auth.currentUser.id == 40 || auth.currentUser.id == 22}
                                  showInput={poiState !== 3 || auth.currentUser.id == 14 || auth.currentUser.id == 40 || auth.currentUser.id == 22}
                                />
                                {poiState !== 3 || auth.currentUser.id == 14 || auth.currentUser.id == 40 || auth.currentUser.id == 22 ? (
                                  <small className="d-block mt-1 text-center">
                                    Pulsa &quot;espacio&quot; para crear una palabra o selecciona alguna de las sugeridas
                                  </small>
                                ) : null}
                              </div>
                            </div>
                            {poiType === 1 ? (
                              <div className="row form-group mb-0 mb-lg-4">
                                <label htmlFor="schedule" className="col-auto col-form-label text-medium pl-lg-1">
                                  Horario:
                      </label>
                                <ValidationInput
                                  className="col-12 col-lg pl-lg-0"
                                  errorMsg={modelErrors.lat}
                                >
                                  <Textarea
                                    id="schedule"
                                    name="schedule"
                                    minRows={2}
                                    className="form-control"
                                    value={schedule || ''}
                                    onChange={handleChange}
                                  />
                                </ValidationInput>
                              </div>
                            ) : null}

                            <div className="form-group row mb-0 mb-lg-4">
                              <label htmlFor="website" className="col-auto col-form-label text-medium pl-lg-1">
                                Web oficial:
                    </label>
                              <ValidationInput
                                className="col-12 col-lg pl-lg-0"
                                errorMsg={modelErrors.website}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="website"
                                  name="website"
                                  value={website || ''}
                                  onChange={handleChange}
                                />
                              </ValidationInput>
                            </div>

                            <div className="form-group row mb-0 mb-lg-4">
                              <label htmlFor="address" className="col-auto col-form-label text-medium pl-lg-1">
                                Dirección:
                    </label>
                              <ValidationInput
                                className="col-12 col-lg pl-lg-0"
                                errorMsg={modelErrors['full-address']}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="address"
                                  name="full-address"
                                  value={fullAddress || ''}
                                  onChange={handleChange}
                                />
                              </ValidationInput>
                            </div>
                            {poiType === 1 ? (
                              <>
                                <div className="form-group row mb-0 mb-lg-4">
                                  <label htmlFor="phone" className="col-auto col-form-label text-medium pl-lg-1">
                                    Teléfono:
                        </label>
                                  <ValidationInput
                                    className="col-12 col-lg pl-lg-0"
                                    errorMsg={modelErrors.phone}
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="phone"
                                      name="phone"
                                      value={phone || ''}
                                      onChange={handleChange}
                                    />
                                  </ValidationInput>
                                </div>

                                <div className="form-group row mt-2 mb-0 mb-lg-4">
                                  <h4 className="col-auto text-medium mt-2 pl-lg-1">Tiempo estimado de visita:</h4>
                                  <div className="col-12 col-lg pl-lg-0">
                                    <div className="row">
                                      <ValidationInput
                                        className="col input-group"
                                        errorMsg={modelErrors.hour}
                                      >
                                        <input
                                          type="number"
                                          className="form-control text-right"
                                          id="hour"
                                          name="hour"
                                          value={this.state.hour || 0}
                                          onChange={(e) => {
                                            this.setState({
                                              hour: e.target.value,
                                            })
                                            poi.attributes["poi-duration"] = computeDuration(document.getElementById("hour").value, document.getElementById("min").value) == 0 ? null : computeDuration(document.getElementById("hour").value, document.getElementById("min").value);
                                          }}
                                        />
                                        <div className="input-group-append">
                                          <span className="input-group-text">h</span>
                                        </div>
                                      </ValidationInput>
                                      <ValidationInput
                                        className="col input-group"
                                        errorMsg={modelErrors.min}
                                      >
                                        <input
                                          type="number"
                                          className="form-control text-right"
                                          id="min"
                                          name="min"
                                          value={this.state.min || '00'}
                                          onChange={(e) => {
                                            this.setState({
                                              min: e.target.value,
                                            })
                                            poi.attributes["poi-duration"] = computeDuration(document.getElementById("hour").value, document.getElementById("min").value) == 0 ? null : computeDuration(document.getElementById("hour").value, document.getElementById("min").value);
                                          }}
                                        />
                                        <div className="input-group-append">
                                          <span className="input-group-text">min</span>
                                        </div>
                                      </ValidationInput>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group row mb-0 mb-lg-4">
                                  <label htmlFor="town" className="col-auto col-form-label text-medium pl-lg-1">
                                    Ciudad o pueblo al que pertenece:
                        </label>
                                  <ValidationInput
                                    className="col-12 col-lg pl-lg-0"
                                    errorMsg={modelErrors.township}
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="town"
                                      name="township"
                                      value={township || ''}
                                      onChange={handleChange}
                                    />
                                  </ValidationInput>
                                </div>

                                <div className="form-group row mb-0 mb-lg-4">
                                  <label htmlFor="price" className="col-auto col-form-label text-medium pl-lg-1">
                                    Precio:
                        </label>
                                  <ValidationInput
                                    className="col-12 col-lg pl-lg-0"
                                    errorMsg={modelErrors.price}
                                  >
                                    <Textarea
                                      id="price"
                                      name="price"
                                      minRows={1}
                                      className="form-control"
                                      value={price || ''}
                                      onChange={handleChange}
                                    />
                                  </ValidationInput>
                                </div>

                                <div className="form-group row align-items-start">
                                  <label htmlFor="assessment" className="col-auto col-form-label text-medium mb-2">
                                    Valoración:
                        </label>
                                  <div className="w-100" />
                                  <div className="col-auto pr-0">
                                    <img
                                      src={FLOWER_ICON}
                                      alt=""
                                      className="poi-info-page__range-img poi-info-page__range-img--left"
                                    />
                                  </div>
                                  <div className="col px-4">
                                    <InputRange
                                      minValue={0}
                                      maxvalue={10}
                                      onChange={handleRange}
                                      id="assessment"
                                      name="assessment"
                                      value={isEmpty(assessment) ? 0 : assessment}
                                    />
                                    <span className="poi-info-page__range-text poi-info-page__range-text--left">
                                      Atracción local
                          </span>
                                    <span className="poi-info-page__range-text poi-info-page__range-text--right">
                                      Maravilla mundial
                          </span>
                                  </div>
                                  <div className="col-auto pl-0">
                                    <img
                                      src={EIFFEL_TOWER_ICON}
                                      alt=""
                                      className="poi-info-page__range-img poi-info-page__range-img--right"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                                <>
                                  <div className="form-group row mb-0 mb-lg-4">
                                    <label htmlFor="website" className="col-auto col-form-label text-medium pl-lg-1">
                                      Página de Twitter:
                        </label>
                                    <ValidationInput
                                      className="col-12 col-lg pl-lg-0"
                                      errorMsg={modelErrors['twitter-url']}
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="twitter-url"
                                        name="twitter-url"
                                        value={twitterUrl || ''}
                                        onChange={handleChange}
                                      />
                                    </ValidationInput>
                                  </div>
                                  <div className="form-group row mb-0 mb-lg-4">
                                    <label htmlFor="website" className="col-auto col-form-label text-medium pl-lg-1">
                                      Página de Facebook:
                        </label>
                                    <ValidationInput
                                      className="col-12 col-lg pl-lg-0"
                                      errorMsg={modelErrors['facebook-url']}
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="facebook-url"
                                        name="facebook-url"
                                        value={facebookUrl || ''}
                                        onChange={handleChange}
                                      />
                                    </ValidationInput>
                                  </div>
                                  <div className="form-group row mb-0 mb-lg-4 instagramMobile">
                                    <label htmlFor="instagram-url" className="col-auto col-form-label text-medium pl-lg-1">
                                      Página de Instagram:
                        </label>
                                    <ValidationInput
                                      className="col-12 col-lg pl-lg-0"
                                      errorMsg={modelErrors['instagram-url']}
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="instagram-url"
                                        name="instagram-url"
                                        value={instagramUrl || ''}
                                        onChange={handleChange}
                                      />
                                    </ValidationInput>
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                        <div className="row poi-info-page__footer">
                          <div className="col-12">
                            <p className="text-center">
                              Recuerda que sólo podrás editar el servicio mientras no esté &quot;publicado&quot;, si aún no ha sido aprobada podrás &quot;despublicarla&quot;
                  </p>
                          </div>
                          {true ? (
                            <>
                              <div className={`${isMobile ? 'col-10 col-md-4 mt-2' : 'col-lg-auto pl-1'}`}>
                                <PromiseButton
                                  type="submit"
                                  className="btn btn-block btn-primary"
                                  onClick={() =>
                                    handleSave(null)}
                                >
                                  guardar
                      </PromiseButton>
                              </div>
                              <div className={`${isMobile ? 'col-10 col-md-4 mt-2' : 'col-lg-auto pl-1'}`}>
                                {poiState === 3 ? (
                                  <Link
                                    to={POI_EDITION_IMG_DINAMIC_URL(poiId)}
                                    className="btn btn-block btn-primary"
                                  >
                                    ver imagenes
                                  </Link>
                                ) : (
                                    <PromiseButton
                                      type="submit"
                                      className="btn btn-block btn-primary"
                                      onClick={() => handleSave(null, POI_EDITION_IMG_DINAMIC_URL(poiId))}
                                      disabled={!hasRequirements}
                                    >
                                      ver imagenes
                                    </PromiseButton>
                                  )}

                              </div>
                            </>
                          ) : null}
                          <div className={`${isMobile ? 'col-10 col-md-4 mt-2' : 'col-lg-auto pl-1'}`}>
                            {poiState === 3 ? (
                              <button type="button" className="btn btn-block btn-success" disabled>
                                publicado
                              </button>
                            ) : (
                                <>
                                  <PromiseButton
                                    type="submit"
                                    className={`btn btn-block ${poiState === 1 ? 'btn-primary' : 'btn-outline-dark'
                                      }`}
                                    onClick={() => (poiState === 1 ? handleShowPublishModal(true) : handleSave(1))}
                                    disabled={poiState === 3 || !hasPublishRequirements}
                                  >
                                    {poiState === 1 ? 'publicar' : 'despublicar'}
                                  </PromiseButton>
                                  {/*                     <PromiseButton
                          type="submit"
                          className="btn btn-block btn-primary"
                          onClick={() => handleSave(null, POI_EDITION_IMG_DINAMIC_URL(poiId))}
                          disabled={!hasRequirements}
                        >
                          ver imagenes
                        </PromiseButton> */}
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
            </>
          )}
      </MainLayout>
    );
  }
};

PoiInfoPageTemplate.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleRange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleShowPublishModal: PropTypes.func.isRequired,
  poiId: PropTypes.string.isRequired,
  hour: PropTypes.number,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  poi: TYPES.poiType.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  isMobile: PropTypes.bool,
  hasRequirements: PropTypes.bool.isRequired,
  hasPublishRequirements: PropTypes.bool.isRequired,
};

PoiInfoPageTemplate.defaultProps = {
  hour: null,
  min: null,
  errors: [],
  isMobile: false,
  auth: null,
};

const mapStateToProps = state => (
  {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    auth: state.auth,
  });

export default connect(mapStateToProps)(PoiInfoPageTemplate);
