import isEmpty from './is-empty';

export default function getImageSrcSet(image) {
  if (!isEmpty(image) && !isEmpty(image.attributes)) {
    const {
      url,
      'large-thumb': largeThumb,
      thumb,
      'small-thumb': smallThumb,
      'tiny-thumb': tinyThumb,
    } = image.attributes;
    return [url, largeThumb, thumb, smallThumb, tinyThumb];
  }else if(!isEmpty(image) && isEmpty(image.attributes)){
    const {
      url,
      'largeThumb': largeThumb,
      thumb,
      'smallThumb': smallThumb,
      'tinyThumb': tinyThumb,
    } = image;
    return [url, largeThumb, thumb, smallThumb, tinyThumb];
  }
  return [];
}
