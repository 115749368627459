import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ENV from '../config/environment';
import { isEmpty } from '../utils';

class SimpleMap extends Component {
  constructor(props) {
    super(props);

    const { mapElementId } = this.props;

    this._mapInstance = null;
    this._mapElementId = mapElementId;
    this.onScriptLoad = this.onScriptLoad.bind(this);
  }

  componentDidMount() {
    if (!window.google) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.googleapis.com/maps/api/js?key=${ENV.googleApiKey}&libraries=places`;
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      // Below is important.
      // We cannot access google.maps until it's finished loading
      s.addEventListener('load', () => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  }

  componentWillUnmount() {
    const { _mapInstance } = this;
    window.google.maps.event.clearListeners(_mapInstance, 'bounds_changed');
    window.google.maps.event.clearListeners(_mapInstance, 'zoom_changed');
    window.google.maps.event.clearListeners(_mapInstance, 'dragend');
    window.google.maps.event.clearListeners(_mapInstance, 'click');
  }

  onScriptLoad() {
    const {
      onMapLoad, onDragEnd, onZoomChanged, onIdle, onDrag, onClick, withDelay,
    } = this.props;
    const options = {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    };
    const map = new window.google.maps.Map(document.getElementById(this._mapElementId), options)
    /* console.log(map) */
    // window.google.maps.event.clearInstanceListeners(map);
    // TODO: workaround fix to ensure the fully map load
    // to avoid map zoom out after fit the bounds.
    if (!isEmpty(onIdle)) {
      window.google.maps.event.addListenerOnce(map, 'idle', () => {
        if (withDelay) {
          setTimeout(() => onIdle(), 150);
        } else {
          onIdle();
        }
      });
    }
    if (!isEmpty(onZoomChanged)) map.addListener('zoom_changed', onZoomChanged);
    if (!isEmpty(onDragEnd)) map.addListener('dragend', onDragEnd);
    if (!isEmpty(onDrag)) map.addListener('drag', onDrag);
    if (!isEmpty(onMapLoad)) onMapLoad(map);
    if (!isEmpty(onClick)) map.addListener('click', onClick);

    this._mapInstance = map;
  }

  render() {
    return <div className="simple-map" id={this._mapElementId} />;
  }
}

SimpleMap.propTypes = {
  mapElementId: PropTypes.string,
  onMapLoad: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func,
  onZoomChanged: PropTypes.func,
  onIdle: PropTypes.func,
  onDrag: PropTypes.func,
  onClick: PropTypes.func,
  withDelay: PropTypes.bool,
};

SimpleMap.defaultProps = {
  mapElementId: 'simple-map',
  onDragEnd: null,
  onDrag: null,
  onZoomChanged: null,
  onIdle: null,
  onClick: null,
  withDelay: false,
};

export default SimpleMap;
