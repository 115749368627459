import React from 'react';
import PropTypes from 'prop-types';
import {
  ROUTE_ICON,
  TRAVEL_TIP_ICON,
  OFFER_ICON,
  SHOPPING_CART_ICON,
  ANGEL_ICON,
} from '../../../../../helpers';

const TourConfigNavMenuTemplate = (props) => {
  const { activeTab, handleConfigNavigation } = props;
  return (
    <nav className="tour-config-nav-menu">
      <button
        type="button"
        className={`tour-config-nav-menu__btn ${activeTab === 1 ? 'tour-config-nav-menu__btn--active' : ''}`}
        onClick={() => handleConfigNavigation(1)}
      >
        {ROUTE_ICON}
      </button>
      <button
        type="button"
        className={`tour-config-nav-menu__btn ${
          activeTab === 2 ? 'tour-config-nav-menu__btn--active' : ''
        }`}
        onClick={() => handleConfigNavigation(2)}
      >
        {ANGEL_ICON}
      </button>
      <button
        type="button"
        className={`tour-config-nav-menu__btn ${activeTab === 3 ? 'tour-config-nav-menu__btn--active' : ''}`}
        onClick={() => handleConfigNavigation(3)}
      >
        {TRAVEL_TIP_ICON}
      </button>
      <button
        type="button"
        className={`tour-config-nav-menu__btn ${activeTab === 4 ? 'tour-config-nav-menu__btn--active' : ''}`}
        onClick={() => handleConfigNavigation(4)}
      >
        {SHOPPING_CART_ICON}
      </button>
      <button
        type="button"
        className={`tour-config-nav-menu__btn ${
          activeTab === 5 ? 'tour-config-nav-menu__btn--active' : ''
        }`}
        onClick={() => handleConfigNavigation(5)}
        disabled
      >
        {OFFER_ICON}
      </button>
    </nav>
  );
};

TourConfigNavMenuTemplate.propTypes = {
  activeTab: PropTypes.number.isRequired,
  handleConfigNavigation: PropTypes.func.isRequired,
};

export default TourConfigNavMenuTemplate;
