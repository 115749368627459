import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as beesReducer } from 'redux-bees';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authReducers from './auth.reducers';
import PoiReducer from './PoiReducer';
import ViewportReducer from './viewport.reducer';
import TourEditionReducers from './tour-edition.reducers';
import DiscoverReducers from './discover.reducers';

export default history => combineReducers({
  router: connectRouter(history),
  toastr: toastrReducer,
  bees: beesReducer,
  auth: authReducers,
  viewport: ViewportReducer,
  PoiReducer,
  workspace: TourEditionReducers,
  discover: DiscoverReducers,
});
