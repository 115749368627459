import { UPDATE_VIEWPORT } from '../constants';

export default function ViewportReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_VIEWPORT: {
      const { currentWidth } = action;
      const isXs = currentWidth <= 575;
      const isSm = currentWidth >= 576 && currentWidth <= 767;
      const isMd = currentWidth >= 768 && currentWidth <= 991;
      const isLg = currentWidth >= 992 && currentWidth <= 1199;
      const isXl = currentWidth >= 1200;
      let currentDisplayType = null;
      if (isXs) currentDisplayType = 'xs';
      if (isSm) currentDisplayType = 'sm';
      if (isMd) currentDisplayType = 'md';
      if (isLg) currentDisplayType = 'lg';
      if (isXl) currentDisplayType = 'xl';
      return {
        currentWidth,
        isXs,
        isSm,
        isMd,
        isLg,
        isXl,
        currentDisplayType,
      };
    }
    default:
      return state;
  }
}
