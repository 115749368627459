import { LOGIN_FAILURE, LOGIN_SUCCESS } from '../constants';

export function loginSuccess(authSessionData) {
  return {
    type: LOGIN_SUCCESS,
    token: authSessionData.accessToken,
  };
}

export function loginFailure(errors) {
  return {
    type: LOGIN_FAILURE,
    errors,
  };
}
