import _cloneDeep from 'lodash.clonedeep';
import { UPDATE_ACTIVE_TOUR, UPDATE_PENDING_CHANGES } from '../constants';

const initialState = {
  tourInEdition: null,
  hasPendingChanges: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_TOUR: {
      const newState = { ...state };
      newState.tourInEdition = action.tourInEdition;
      return { ...newState };
    }
    case UPDATE_PENDING_CHANGES: {
      const newState = _cloneDeep(state);
      newState.hasPendingChanges = action.hasPendingChanges;
      return newState;
    }
    default:
      return state;
  }
};
