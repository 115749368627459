import { PropTypes } from 'prop-types';

const userType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['users']),
  attributes: PropTypes.shape({
    name: PropTypes.string,
  }),
});

export default userType;
