import {
  ADD_POI,
  EDIT_POI,
  POI_UPLOADED_IMAGE,
  UPDATE_POI_IMAGES,
} from '../constants';
// this is used only from the example component counter, can be removed with all the related parts without side-effects

export default (state = {}, action) => {
  const newState = { ...state };
  switch (action.type) {
    case ADD_POI:
      return { ...state, ...action.payload.poi };
    case EDIT_POI: {
      const { poiId, editedPoiData } = action.payload;
      newState[poiId] = { ...state[poiId], jsonData: { ...editedPoiData } };
      return newState;
    }
    case POI_UPLOADED_IMAGE: {
      const { poiRemoteId, uploadedImageJson } = action.payload;
      newState[poiRemoteId].files = [
        ...newState[poiRemoteId].files,
        uploadedImageJson,
      ];
      return newState;
    }
    case UPDATE_POI_IMAGES: {
      const { poiId, files } = action.payload;
      newState[poiId] = { ...state[poiId], files: [...files] };
      return newState;
    }
    default:
      return state;
  }
};
