import React, { Component } from 'react';
import PropType from 'prop-types';
import Select, { components } from 'react-select';
import { UP_ARROW_URL_ICON } from '../helpers';

const DropdownIndicator = (props) => {
  const indicator = (
    <components.DropdownIndicator {...props}>
      <img className="top-select__dropdown-indicator-img" src={UP_ARROW_URL_ICON} alt="Seleccionar" />
    </components.DropdownIndicator>
  );

  return indicator;
};

export default class TopSelect extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedOption) {
    const { handleChange, name } = this.props;
    handleChange({ name, selectedOption });
  }

  render() {
    const { withIcons, placeholder } = this.props;

    return (
      <Select
        onChange={this.handleChange}
        className={`top-select ${withIcons ? 'top-select--icon' : ''}`}
        classNamePrefix="top-select"
        components={{ DropdownIndicator }}
        placeholder={placeholder}
        {...this.props}
      />
    );
  }
}

TopSelect.propTypes = {
  handleChange: PropType.func.isRequired,
  selectedOption: PropType.objectOf(PropType.object),
  options: PropType.arrayOf(PropType.object).isRequired,
  name: PropType.string.isRequired,
  withIcons: PropType.bool,
  placeholder: PropType.string,
};

TopSelect.defaultProps = {
  selectedOption: null,
  withIcons: false,
  placeholder: null,
};
