import { PropTypes } from 'prop-types';

const tourType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['tours']).isRequired,
  attributes: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    'previous-search-text': PropTypes.string,
    lat: PropTypes.number,
    long: PropTypes.number,
    'tour-duration': PropTypes.number,
    'tour-type': PropTypes.number,
    state: PropTypes.number.isRequired,
    'published-at': PropTypes.string,
    'no-virtual-shop': PropTypes.bool,
  }),
});

export default tourType;
