import isEmptyObject from '../isEmptyObject';
import getEntityFromRedux from './get-entitiy-from-redux';
import reduxStore from '../../reduxStore';

let NotCachedResources = [];

function getSingleLinkageResource(relationshipLinkage, endpoint) {
  const reduxState = reduxStore.getState();
  const cacheEntity = getEntityFromRedux(reduxState, relationshipLinkage.type, relationshipLinkage.id);

  if (!cacheEntity && endpoint) {
    NotCachedResources.push({ endpoint, id: relationshipLinkage.id });
  }

  return cacheEntity;
}

function getArrayOfLinkagesResources(relationshipLinkage, endpoint) {
  const returnedData = relationshipLinkage.map(currLinkage =>
    getSingleLinkageResource(currLinkage, endpoint),
  );
  return returnedData;
}

export default function getRelatedEntitiesFromRedux(mainEntity, relationshipIdentifier, endPoint) {
  NotCachedResources = [];
  if (isEmptyObject(mainEntity)) return [null, []];

  const {
    relationships: {
      [relationshipIdentifier]: { data: relationshipLinkage },
    },
  } = mainEntity;
  if (isEmptyObject(relationshipLinkage)) return [null, []];

  const isArray = Array.isArray(relationshipLinkage);
  let returnedEntities = null;
  if (!isArray) returnedEntities = getSingleLinkageResource(relationshipLinkage, endPoint);
  else returnedEntities = getArrayOfLinkagesResources(relationshipLinkage, endPoint);
  // returnedEntities.NotCachedResources = NotCachedResources;
  return [returnedEntities, NotCachedResources];
}
