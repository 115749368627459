import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { geocodeByAddress } from 'react-places-autocomplete';
import { history } from '../../helpers';
import {
  SearchMap, ResultCardList, SearchFilters, PoiGallery, SimplePoiList,
} from './parts';
import { isEmpty } from '../../utils';
import api from '../../config/api-endpoints';
import {
  buildSearchQueryParams,
  checkFilterQueryParamChanges,
  getUserPosition,
  isEqualBounds,
  getUrlBounds,
  isValidBounds,
} from './utils';
import PoiMapPopUp from '../poi-map-popup';
import YurisHell from './yuris-hell';
import TYPES from '../../constants/types';
import LoadingSpinner from '../loading-spinner';
class LocationSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchPending: false,
      results: [],
      isMapVisible: true,
      showedTabName: 'map',
      userPosition: null,
      hightlightedPoiId: null,
      markers: [],
      autoSearch: false,
      limit: 50,
      onlyPreviewApproval: false,
      onlyPreview: false,
      noPreview: false,
      pruebaPois: [],
      isLoading: true
    };

    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.handleMapSearch = this.handleMapSearch.bind(this);
    this.updateURLBounds = this.updateURLBounds.bind(this);
    this.toggleMapVisibility = this.toggleMapVisibility.bind(this);
    this.switchMobileTab = this.switchMobileTab.bind(this);
    this.handleGooglePlacesSelect = this.handleGooglePlacesSelect.bind(this);
    this.handleMapMount = this.handleMapMount.bind(this);
    this.centerToUserPosition = this.centerToUserPosition.bind(this);
    this.setSelectedPoi = this.setSelectedPoi.bind(this);
    this._mapInstance = null;
    // Yuri's hell dependecies
    this.poiSearchSideEffects = YurisHell.poiSearchSideEffects.bind(this);
    this.populatePois = YurisHell.populatePois.bind(this);
    this.populatePoi = YurisHell.populatePoi.bind(this);
    this.getPoisMarkers = YurisHell.getPoisMarkers.bind(this);
    this.highlightRelatedPoiCard = YurisHell.highlightRelatedPoiCard.bind(this);
    this.handleHightlightMarker = this.handleHightlightMarker.bind(this);
    this.handleSearchMode = this.handleSearchMode.bind(this);
    this.handleSelectLimit = this.handleSelectLimit.bind(this);
    // <-- used by imported services -->
    this.latestSearchId = 0;

    // set default searchType to 1 if not already present before first render
    // this avoids invoking the render method twice on first page load
    const { urlQueryParams, enableNoValidated, currentUserId } = props;
    const urlPoiType = urlQueryParams.get('searchType');
    const noValidatedContentFilter = urlQueryParams.get('includeNoVal');
    let isChangedQueryParams = false;
    if (urlPoiType === null) {
      urlQueryParams.set('searchType', 1);
      isChangedQueryParams = true;
    }
    if (enableNoValidated && isEmpty(noValidatedContentFilter)) {
      urlQueryParams.set('includeNoVal', currentUserId);
      isChangedQueryParams = true;
    }
    if (!enableNoValidated && !isEmpty(noValidatedContentFilter)) {
      urlQueryParams.delete('includeNoVal');
      isChangedQueryParams = true;
    }
    if (isChangedQueryParams) {
      history.push({
        search: `?${urlQueryParams.toString()}`,
      });
    }
  }

  componentDidMount() {
    const { handleSearchRequest } = this;
    const { urlQueryParams } = this.props;
    const { limit } = this.state;
    const searchQueryParams = buildSearchQueryParams(urlQueryParams, null, limit);

    return handleSearchRequest(searchQueryParams);
  }

  componentDidUpdate(prevProps, prevState) {
    const { handleSearchRequest } = this;
    const { urlQueryParams } = this.props;
    const { limit } = this.state;
    const { limit: prevLimit } = prevState;
    const { urlQueryParams: prevUrlQueryParams } = prevProps;
    const isFiltersChanged = checkFilterQueryParamChanges(urlQueryParams, prevUrlQueryParams);
    if (isFiltersChanged || limit !== prevLimit) {
      const searchQueryParams = buildSearchQueryParams(urlQueryParams, null, limit);
      handleSearchRequest(searchQueryParams);
    }
  }

  setSelectedPoi(poi) {
    const { urlQueryParams } = this.props;
    if (isEmpty(poi)) urlQueryParams.delete('select');
    else urlQueryParams.set('select', poi.id);

    history.push({
      search: `?${urlQueryParams.toString()}`,
    });
  }

  handleHightlightMarker(poiId) {
    this.setState({ hightlightedPoiId: poiId });
  }

  handleMapMount(map) {
    this._mapInstance = map;
  }

  handleMapSearch(mapBounds, isManualSearch) {
    const { autoSearch, limit } = this.state;
    const { urlQueryParams } = this.props;
    const urlBounds = getUrlBounds(urlQueryParams);
    const isNewMapBounds = !isEqualBounds(mapBounds, urlBounds);
    if ((isNewMapBounds && autoSearch) || isManualSearch) {
      const searchQueryParams = buildSearchQueryParams(urlQueryParams, mapBounds, limit);
      this.handleSearchRequest(searchQueryParams);
    } else if (isNewMapBounds) {
      this.updateURLBounds(mapBounds);
    }
  }

  handleSearchRequest(searchQueryParams) {
    this.setState({ isLoading: true })
    const { dispatch } = this.props;
    const { poiSearchSideEffects } = this;
    const newSearchId = this.latestSearchId + 1;
    this.latestSearchId = newSearchId;
    return dispatch(api.getPois(searchQueryParams))
      .then(response => 
      
          poiSearchSideEffects(response, newSearchId))
        .then(({ responseMeta, localSearchId }) => {
          this.setState({isLoading: false})
          if (localSearchId === this.latestSearchId) {
            this.updateURLBounds(responseMeta);
          }
        }
      );
  }

  updateURLBounds(bounds) {
    if (isValidBounds(bounds)) {
      const { urlQueryParams } = this.props;
      const {
        nel, nelng, swl, swlng,
      } = bounds;

      urlQueryParams.set('nel', nel);
      urlQueryParams.set('nelng', nelng);
      urlQueryParams.set('swl', swl);
      urlQueryParams.set('swlng', swlng);
      urlQueryParams.delete('adr');
      urlQueryParams.delete('lat');
      urlQueryParams.delete('lng');
      history.push({
        search: `?${urlQueryParams.toString()}`,
      });
    }
  }

  toggleMapVisibility() {
    const { isMapVisible } = this.state;
    this.setState({ isMapVisible: !isMapVisible });
  }

  switchMobileTab(showedTabName) {
    this.setState({
      showedTabName,
    });
  }

  handleGooglePlacesSelect(address, selectionId) {
    this.setState({ isMapVisible: false });
    setTimeout(() => { this.setState({ isMapVisible: true }); }, 0);
    const { handleSearchRequest } = this;
    const { urlQueryParams } = this.props;
    const { limit } = this.state;

    if (!address && isEmpty(selectionId)) {
      return Promise.resolve();
    }
    return geocodeByAddress(address).then((results) => {

      const addressInputValue = results[0].formatted_address;

      urlQueryParams.set('adr', addressInputValue);
      urlQueryParams.delete('lat');
      urlQueryParams.delete('lng');
      urlQueryParams.delete('swl');
      urlQueryParams.delete('swlng');
      urlQueryParams.delete('nel');
      urlQueryParams.delete('nelng');

      const searchQueryParams = buildSearchQueryParams(urlQueryParams, null, limit);
      handleSearchRequest(searchQueryParams);
    });
  }

  centerToUserPosition() {
    const { _mapInstance } = this;
    getUserPosition().then((position) => {
      _mapInstance.panTo(position);
      _mapInstance.setZoom(15);
      this.setState({
        userPosition: position,
      });
    });
  }

  handleSearchMode(e) {
    const { checked } = e.target;
    this.setState({ autoSearch: checked });
  }

  handleSelectLimit(value) {
    this.setState({ limit: value });
  }

  onlyPreviewApproval = () => {
    this.setState({ onlyPreviewApproval: !this.state.onlyPreviewApproval })
  }
  onlyPreview = () => {
    this.setState({ onlyPreview: !this.state.onlyPreview })
  }
  noPreview = () => {
    this.setState({ noPreview: !this.state.noPreview })
  }
  pruebaPois = (e) => {
    this.setState({ pruebaPois: e })
  }

  render() {
    const {
      isMobile,
      addButtonCB,
      addedTourContents,
      handleDaySelection,
      showTypeFilter,
      handleAddDayToTour,
      tourDuration,
      activeDay,
      urlQueryParams,
      orderBy,
      enableNoValidated,
    } = this.props;

    const {
      isSearchPending,
      results,
      isMapVisible,
      showedTabName,
      userPosition,
      markers,
      hightlightedPoiId,
      autoSearch,
      limit,
      isLoading
    } = this.state;

    var newMarkers = isMobile ? this.getPoisMarkers(results) : this.getPoisMarkers(this.state.pruebaPois)
    const isShowMap = (isMobile && showedTabName === 'map') || (!isMobile && isMapVisible);
    const isShowGallery = isMobile && showedTabName === 'gallery';
    const isShowList = isMobile && showedTabName === 'list';

    const addedPoiIds = YurisHell.getArrayOfUniqueIdsOfRelatedPois(addedTourContents);
    const currentSelectedId = urlQueryParams.get('select');
    const selectedPoi = results[results.findIndex(poi => parseInt(poi.id, 10) === parseInt(currentSelectedId, 10))];
    const isAddedSelectedPoi = !isEmpty(addedPoiIds)
      && !isEmpty(selectedPoi)
      && addedPoiIds.indexOf(selectedPoi.id) >= 0;
    return (
      <div className="container-fluid location-search">
        <SearchFilters
          activeDay={activeDay}
          tourDuration={tourDuration}
          handleDaySelection={handleDaySelection}
          handleAddDayToTour={handleAddDayToTour}
          switchSearchSection={this.switchMobileTab}
          showedSection={showedTabName}
          handlePoiTypeFilterSwitch={this.handlePoiTypeFilterSwitch}
          handleGooglePlacesChange={this.handleGooglePlacesChange}
          handleGooglePlacesSelect={this.handleGooglePlacesSelect}
          centerToUserPosition={this.centerToUserPosition}
          handleToggleMapDisplay={this.toggleMapVisibility}
          isMapVisible={isMapVisible}
          showTypeFilter={showTypeFilter}
          enableNoValidated={enableNoValidated}
          limit={limit}
          handleSelectLimit={this.handleSelectLimit}
          handleSearch={this.handleMapSearch}
          onlyPreviewApproval={this.onlyPreviewApproval}
          onlyPreview={this.onlyPreview}
          noPreview={this.noPreview}
        />

        <div className="location-search__wrapper">
          {!isMobile ? (
            <>
              {!isLoading ? (
                <>
                  {results.length >= 1 ?
                    <ResultCardList
                      results={results}
                      addedPoiIds={addedPoiIds}
                      handleHightlightMarker={this.handleHightlightMarker}
                      addButtonCB={addButtonCB}
                      orderBy={orderBy}
                      onlyPreviewApproval={this.state.onlyPreviewApproval}
                      onlyPreview={this.state.onlyPreview}
                      noPreview={this.state.noPreview}
                      pruebaPois={this.pruebaPois}
                    />
                    :
                    (
                      <div className="results-card-list">
                        <div>
                          <div>No hay resultados</div>
                        </div>
                      </div>

                    )
                  }

                </>

              ) : (
                  <div className="results-card-list">
                    <div>
                      <div className="mt-5">
                        <LoadingSpinner onlySpinner />
                      </div>
                      <p className="w-100 text-center mt-3">Cargando...</p>
                    </div>
                  </div>
                )
              }
            </>

          ) : (
              null
            )}
          {isShowMap ? (
            <SearchMap
              autoSearch={autoSearch}
              showLoader={isSearchPending}
              handleSearch={this.handleMapSearch}
              markers={newMarkers}
              hightlightedPoiId={hightlightedPoiId}
              handleMapMount={this.handleMapMount}
              userPosition={userPosition}
              addButtonCB={addButtonCB}
              handleSearchMode={this.handleSearchMode}
            />
            // <div></div>
          ) : null}
          {isShowGallery ? (
            <PoiGallery results={results} onPoiClick={this.setSelectedPoi} addButtonCB={addButtonCB} />
          ) : null}
          {isShowList ? (
            <SimplePoiList
              results={results}
              onPoiClick={this.setSelectedPoi}
              addButtonCB={addButtonCB}
              addedPoiIds={addedPoiIds}
            />
          ) : null}
          {isMobile ? (
            <PoiMapPopUp
              poi={selectedPoi}
              closeModal={() => this.setSelectedPoi(null)}
              addButtonCB={addButtonCB}
              alreadyPresent={isAddedSelectedPoi}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

LocationSearch.propTypes = {
  dispatch: PropTypes.func.isRequired,
  urlQueryParams: PropTypes.objectOf(PropTypes.object),
  isMobile: PropTypes.bool,
  addButtonCB: PropTypes.func,
  addedTourContents: PropTypes.arrayOf(TYPES.tourContentType),
  handleDaySelection: PropTypes.func,
  showTypeFilter: PropTypes.bool,
  handleAddDayToTour: PropTypes.func,
  tourDuration: PropTypes.number,
  activeDay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  orderBy: PropTypes.string,
  enableNoValidated: PropTypes.bool,
  currentUserId: PropTypes.string,
};

LocationSearch.defaultProps = {
  isMobile: false,
  urlQueryParams: null,
  addButtonCB: null,
  addedTourContents: [],
  handleDaySelection: null,
  showTypeFilter: false,
  handleAddDayToTour: null,
  tourDuration: null,
  activeDay: null,
  orderBy: null,
  enableNoValidated: false,
  currentUserId: null,
};

function mapStateToProps(state, props) {
  const urlQueryParams = new URLSearchParams(state.router.location.search);
  const currentUserId = state.auth.isAuthenticated
    && !isEmpty(state.auth.currentUser)
    ? state.auth.currentUser.id : null;

  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    urlQueryParams,
    currentUserId,
  };
}

export default connect(mapStateToProps)(LocationSearch);
