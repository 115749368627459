import React from 'react';
import { Helmet } from 'react-helmet';
import MainLayout from '../layouts/main-layout';
import { Footer } from '../components';

const CookiesPage = () => (
  <MainLayout>
    <Helmet>
      <title>: Política de cookies</title>
      <meta name="title" content="Topdesti: Política de cookies" />
      <meta property="og:title" content="Topdesti: Política de cookies" />
      <meta property="twitter:title" content="Topdesti: Política de cookies" />
      <meta name="description" content="Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas" />
      <meta property="og:description" content="Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas" />
      <meta property="twitter:description" content="Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas" />
      <meta property="of:image" content={`${window.location.origin}/assets/images/topdesti-logo.png`} />
      <meta property="twitter:image" content={`${window.location.origin}/assets/images/topdesti-logo.png`} />
      <meta property="og:image:width" content="132" />
      <meta property="og:image:height" content="38" />
    </Helmet>
    <main className="container f-opensans">
      <div className="row">
        <div className="col-12">
          <h2 className="text-center mt-3">Política de Cookies de Topdesti</h2>
          <p>Una cookie es un fichero que se descarga en tu dispositivo al acceder a determinadas páginas Web. Las cookies permiten al sitio Web, entre otras cosas, almacenar y recuperar información sobre tu número de visitas, los hábitos de navegación del usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario. Las cookies te facilitarán tu visita por Topdesti.</p>
          <p>Ten en cuenta que para poder utilizar Topdesti es necesario que tengas habilitadas las cookies, especialmente aquellas de carácter técnico que resultan necesarias para que Topdesti pueda identificarte como usuario registrado cada vez que accedas a Topdesti. En particular, te informamos de que Topdesti utiliza los siguientes tipos de cookies, las cuales son tratadas bien por nosotros directamente o por terceros colaboradores:</p>
          <p>Topdesti hace uso de cookies, bien a través de medios propios, bien a través de terceros contratados para la prestación de servicios de medición, con relación a los usuarios que navegan en el Portal. Las cookies son ficheros enviados al navegador, mediante un servidor web, con la finalidad de registrar las actividades de los Usuarios durante su tiempo de navegación. El uso de las cookies permite que el servidor de Topdesti reconozca el navegador web utilizado por el Usuario con la finalidad de que la navegación sea más sencilla, permitiendo medir la audiencia y parámetros del tráfico, controlar el progreso y número de entradas, así como las operaciones más relevantes de los Usuarios. El Usuario tiene la posibilidad de configurar su navegador para ser avisado de la recepción de cookies e impedir su instalación en su navegador. Por favor, consulte las instrucciones y manuales de su navegador para ampliar esta información.</p>
          <p>El conjunto de cookies utilizado se clasifica en las siguientes tipologías:</p>
          <ul>
            <li>
              <strong>Técnica: </strong>
              permiten navegar o utilizar algunos servicios o procesos del sitio web
            </li>
            <li>
              <strong>De personalización: </strong>
              definen algunas configuraciones regionales, como el idioma, y definen el tipo de navegador utilizado
            </li>
            <li>
              <strong>De análisis: </strong>
              proporcionan información estadística sobre la actividad de los usuarios en nuestro sitio web con el fin de introducir mejoras, como modificar procesos, introducir información sobre búsquedas frecuentes, etc.
            </li>
            <li>
              <strong>Publicitaria: </strong>
              son las que gestionan los espacios publicitarios y definen el contenido y la frecuencia de los anuncios, por ejemplo para que no se repitan los mismos
            </li>
          </ul>
          <p>Utilizamos el siguiente conjunto de cookies en nuestro website:</p>
          <hr />
          <h5><strong>Google Analytics</strong></h5>
          <p>
            <strong>Nombre: </strong>
_ga, _gid, _gat
          </p>
          <p>
            <strong>Tipo: </strong>
Third-party
          </p>
          <p>Estas cookies se utilizan para recopilar información sobre cómo los visitantes usan nuestro sitio. Usamos la información para compilar informes y para ayudarnos a mejorar el sitio. Las cookies recopilan información de forma anónima, incluida la cantidad de visitantes del sitio, de dónde han venido los visitantes y las páginas que visitaron.</p>
          <p>
            <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank" rel="noopener noreferrer">
              Política de cookies de Google Analytics
            </a>
          </p>
          <hr />
          <h5><strong>Facebook</strong></h5>
          <p>
            <strong>Nombre: </strong>
            fr
          </p>
          <p>
            <strong>Tipo: </strong>
            Third-party
          </p>
          <p>Nos pemite medir, optimizar y construir audiencias para campañas de participación servidas en Facebook. Para garantizar que nuestra  publicidad en Facebook sea vista por nuestros usuarios, es muy probable que estén interesados en dicha publicidad analizando qué contenido ha visto un usuario.</p>
          <p>
            <a href="https://www.facebook.com/policies/cookies/" target="_blank" rel="noopener noreferrer">
              Política de cookies de Facebook
            </a>
          </p>
          <hr />
          <p>Ten en cuenta que para poder utilizar determinados servicios y funcionalidades que te ofrecemos en Topdesti es necesario que tengas habilitadas las cookies en tu navegador de Internet. En concreto, mediante estas cookies técnicas, Topdesti podrá identificarte como usuario registrado cada vez que accedas a Topdesti, permitiéndote así el acceso y uso a dichos servicios y funcionalidades.</p>
          <p>No obstante, recuerda que puedes permitir, bloquear o eliminar estas cookies cuando quieras a través de las opciones de configuración de tu dispositivo o terminal, así como de tu navegador de internet.</p>
          <p>Recuerda siempre acceder a la información de soporte más actualizada de dichos navegadores para conocer el modo en que puedes eliminar las cookies, dado que el modo puede diferir entre distintas versiones y sistemas operativos.</p>
        </div>
      </div>
    </main>
    <Footer />
  </MainLayout>
);

export default CookiesPage;
