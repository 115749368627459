/* eslint-disable no-underscore-dangle */
import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import { middleware as beesMiddleware } from 'redux-bees';
import createRootReducer from './reducers/root-reducer';
import history from './helpers/history';

const initialState = {};

const reduxDevtoolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = typeof window === 'object' && reduxDevtoolsExtension
  ? reduxDevtoolsExtension({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  })
  : compose;

const middlewares = [routerMiddleware(history), thunkMiddleware, beesMiddleware()];

const reduxStore = createStore(
  createRootReducer(history),
  initialState,
  composeEnhancers(applyMiddleware(...middlewares)),
);

export default reduxStore;

const { dispatch } = reduxStore;
export { dispatch };
