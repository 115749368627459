import isEmptyObject from './isEmptyObject';
import isEmpty from './is-empty';

// function validate24HoursFormat(evaluatedString) {
//   if (isEmpty(evaluatedString)) return null;
//   const lowerCasedString = evaluatedString.toLocaleLowerCase('en-US');
//   let errorMsg = 'Formato no valido, poner una hora de llegada en formado 24 horas (e.g. 15:35)';
//   if (
//     lowerCasedString.indexOf('am') >= 0
//     || lowerCasedString.indexOf('pm') >= 0
//     || lowerCasedString.indexOf(':') <= 0
//   ) return errorMsg;

//   const [hours, minutes] = lowerCasedString.split(':');

//   if (hours.length >= 3 || hours.length < 1) return errorMsg;
//   if (minutes.length !== 2) return errorMsg;

//   if (Number.parseInt(hours, 10) > 23) {
//     errorMsg = 'Formato no válido, horas permitidas 0~23 (ej. 23:59, 0:00)';
//     return errorMsg;
//   }
//   if (Number.parseInt(minutes, 10) > 59) {
//     errorMsg = 'Formato no válido, minutos permitidos 0~59 (ej. 23:59, 0:00)';
//     return errorMsg;
//   }

//   return null;
// }

const isValidTextTourContent = (tourContent) => {
  const { attributes: tourContentAttributes } = tourContent;
  const { title, subtitle, text } = tourContentAttributes;
  if (
    isEmptyObject(title)
    && isEmptyObject(subtitle)
    && (isEmptyObject(text) || text === '<p><br></p>')
  ) {
    return [false, 'Debe de rellenarse al menos el título, el subtítulo o el texto general.'];
  }
  return [true, null];
};

const isValidMapTourContent = (tourContent) => {
  const { attributes: tourContentAttributes } = tourContent;
  const { lat, long } = tourContentAttributes;
  if (isEmptyObject(lat) || isEmptyObject(long)) {
    return [false, 'Se requieren las coordenadas de latitud y longitud.'];
  }

  return [true, null];
};

const isValidDurationTourContent = (tourContent) => {
  const { attributes: tourContentAttributes } = tourContent;
  const { 'arrival-time': arrivalTime, duration } = tourContentAttributes;
  if (isEmptyObject(arrivalTime) && isEmptyObject(duration)) {
    return [false, 'Al menos se debe de rellenar la hora de llegada o la duración.'];
  }

  // TODO: add validation for arrival-time value format

  return [true, null];
};

function isValidUrl(testedUrl) {
  // eslint-disable-next-line max-len
  const regExp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  const matches = testedUrl.match(regExp);
  return !!(matches && matches.length);
}
// https://regex101.com/r/xCCtQS/32
// regex use can be seen in action at the above link
function validYoutubeUrl(testedUrl) {
  const regExp = /((http(s)?:\/\/)?)(www\.|m.)?((youtube\.com\/)|(youtu.be\/))[\S]+/;
  const matches = testedUrl.match(regExp);
  return !!(matches && matches.length);
}

function validateVimeoUrlRequest(testedUrl) {
  return new Promise((resolve, reject) => {
    fetch(`https://vimeo.com/api/oembed.json?url=${testedUrl}`).then(resolve, reject);
  });
}

async function isValidVideoUrl(testedUrl) {
  const validYoutube = validYoutubeUrl(testedUrl);
  if (validYoutube) return true;

  let validVimeoRequestResult;
  validVimeoRequestResult = await validateVimeoUrlRequest(testedUrl).catch((e) => {
    validVimeoRequestResult = e;
  });
  const { status: vimeoApiStatus } = validVimeoRequestResult;
  const validVimeo = vimeoApiStatus === 200;
  if (validVimeo) return true;

  return false;
}

const isValidVideoTourContent = async (tourContent) => {
  const { attributes: tourContentAttributes } = tourContent;
  const { 'video-url': videoUrl } = tourContentAttributes;
  if (isEmptyObject(videoUrl)) return [false, 'El enlace del video está vacia.'];
  if (!isValidUrl(videoUrl)) return [false, 'El enlace no es válido, por favor, compruebalo.'];

  const validSupportedHostFlag = await isValidVideoUrl(videoUrl);

  if (!validSupportedHostFlag) return [false, 'El enlace del video no es de un servidor soportado.'];

  return [true, null];
};

const isValidServiceTourContent = async (tourContent) => {
  const {
    attributes: { text, 'video-url': videoUrl, 'affiliate-link': affiliateLink } = {},
  } = tourContent;

  if (isEmptyObject(text) || text === '<p><br></p>') {
    return [false, 'Al menos se debe de rellenar el texto personalizado'];
  }
  if (!isEmptyObject(affiliateLink)) {
    if (!isValidUrl(affiliateLink)) return [false, 'El enlace de afiliación no es válido, por favor, compruebalo.'];
  }

  if (!isEmptyObject(videoUrl)) {
    const validSupportedHostFlag = await isValidVideoUrl(videoUrl);
    if (!validSupportedHostFlag) return [false, 'El enlace no es de un servidor soportado.'];
  }

  return [true, null];
};

const isValidTipTourContent = async (tourContent) => {
  const { attributes: tourContentAttributes } = tourContent;
  const { title, text, 'link-url': linkUrl } = tourContentAttributes;
  if (isEmptyObject(title) || (isEmptyObject(text) || text === '<p><br></p>')) {
    return [false, 'Deben de rellenarse al menos el título y el texto general.'];
  }
  if (!isEmpty(linkUrl) && !isValidUrl(linkUrl)) {
    return [false, 'El enlace no es válido, por favor, compruebalo.'];
  }
  return [true, null];
};

const isValidProduct = async (product) => {
  const {
    title, text, link, 'video-url': videoUrl,
  } = product.attributes;
  if (isEmptyObject(title) || (isEmptyObject(text) || text === '<p><br></p>')) {
    return [false, 'Deben de rellenarse al menos el título y el texto general.'];
  }
  if (!isEmpty(link) && !isValidUrl(link)) {
    return [false, 'El enlace no es válido, por favor, compruebalo.'];
  }
  if (!isEmpty(videoUrl)) {
    const validSupportedHostFlag = await isValidVideoUrl(videoUrl);
    if (!isValidUrl(videoUrl)) {
      return [false, 'El enlace del video no es válido, por favor, compruebalo.'];
    }
    if (!validSupportedHostFlag) return [false, 'El enlace del video no pertenece a un servidor soportado.'];
  }
  return [true, null];
};

const isValidSponsor = (sponsor) => {
  const {
    attributes: { 'link-url': linkUrl },
  } = sponsor;
  if (!isEmpty(linkUrl) && !isValidUrl(linkUrl)) {
    return [false, 'El enlace no es válido, por favor, compruebalo.'];
  }
  return [true, null];
};

export default {
  isValidTextTourContent,
  isValidMapTourContent,
  isValidDurationTourContent,
  isValidVideoTourContent,
  isValidServiceTourContent,
  isValidTipTourContent,
  isValidProduct,
  isValidSponsor,
};
