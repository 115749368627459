import isEmpty from './is-empty';

export default function formattedDuration(duration) {
  if (isEmpty(duration)) {
    return { hour: null, min: null };
  }
  // Get hours from milliseconds
  const hours = duration / (1000 * 60 * 60);
  const absoluteHours = Math.floor(hours);

  // Get remainder from hours and convert to minutes
  const minutes = (hours - absoluteHours) * 60;
  const min = Math.round(minutes) > 9 ? Math.round(minutes) : `0${Math.round(minutes)}`;
  return { hour: absoluteHours, min };
}
