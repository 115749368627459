import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '../utils';

export default class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
    this._isMouseOverDropdown = false;
    this.handleClick = this.handleClick.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  }

  closeDropdown(e) {
    e.preventDefault();
    this.updateMouseState(false);
    setTimeout(() => {
      if (!this._isMouseOverDropdown) {
        this.setState({ isExpanded: false });
      }
    }, 150);
  }

  updateMouseState(value) {
    this._isMouseOverDropdown = value;
  }

  render() {
    const {
      toggle,
      containerClass,
      toggleClass,
      children,
      disableCondition,
      align,
      menuClass,
    } = this.props;

    const { isExpanded } = this.state;
    return (
      <div
        className={`dropdown ${containerClass} ${isExpanded ? 'show' : ''}`}
        onMouseLeave={this.closeDropdown}
        onMouseEnter={() => this.updateMouseState(true)}
      >
        <button
          className={`dropdown-toggle ${toggleClass}`}
          type="button"
          aria-expanded={isExpanded}
          onClick={this.handleClick}
          disabled={disableCondition}
        >
          {toggle}
        </button>
        <div
          onMouseEnter={() => this.updateMouseState(true)}
          className={`dropdown-menu ${!isEmpty(align) ? `dropdown-menu-${align}` : ''} ${
            isExpanded ? 'show' : ''
          } ${menuClass}`}
        >
          {children}
        </div>
      </div>
    );
  }
}

DropDown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.symbol]).isRequired,
  toggle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  containerClass: PropTypes.string,
  toggleClass: PropTypes.string,
  menuClass: PropTypes.string,
  disableCondition: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right']),
};

DropDown.defaultProps = {
  containerClass: '',
  toggleClass: '',
  menuClass: '',
  disableCondition: false,
  align: null,
};
