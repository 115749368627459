import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';

import { TITLE_BOX_FORM_ICON, SUBTITLE_BOX_FORM_ICON, TEXT_BOX_FORM_ICON } from '../../../../../../helpers';
import TYPES from '../../../../../../constants/types';
import { isEmpty } from '../../../../../../utils';
import { PromiseButton } from '../../../../../../components';

const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];

const tourContentTextFormTemplate = (props) => {
  const {
    localTourContent,
    handleToggleVisibility,
    handleDelete,
    handleInputChange,
    handleTextChange,
    inputLength
  } = props;
  const {
    attributes: {
      subtitle, text, title, visible: isVisible,
    } = {},
    relationships: {
      'tour-content': { data: parentTourContent },
    },
  } = localTourContent;
  const isVisibleExtraBtns = !isEmpty(localTourContent.id);

  const isChildTourContent = !!parentTourContent;

  
  return (
    <div className="container-fluid tour-content-text-form">
      <div className="row mb-4">
        <figure className="col-auto tour-content-text-form__figure">{TITLE_BOX_FORM_ICON}</figure>
        <div className="col">
          <label htmlFor="tour-content-title" className="tour-content-text-form__label">
            Título principal:
          </label>
          <input
            id="tour-content-title"
            type="text"
            value={isEmpty(title) ? '' : title}
            onChange={handleInputChange}
            name="title"
            className="form-control"
          />
        </div>
      </div>
      <div className="row mb-4">
        <figure className="col-auto tour-content-text-form__figure">{SUBTITLE_BOX_FORM_ICON}</figure>
        <div className="col">
          <div class='d-flex justify-content-between'>
            <label htmlFor="tour-content-subtitle" className="tour-content-text-form__label">
              Subtítulo:
          </label>
            <label style={{ color: inputLength(subtitle) >= 256 ? 'red' : null }}>
              {inputLength(subtitle)}/255
          </label>
          </div>
          <textarea
            rows="2"
            id="tour-content-subtitle"
            value={isEmpty(subtitle) ? '' : subtitle}
            onChange={handleInputChange}
            name="subtitle"
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <figure className="col-auto tour-content-text-form__figure">{TEXT_BOX_FORM_ICON}</figure>
        <div className="col">
          <label htmlFor="tour-content-text" className="tour-content-text-form__label">
            Texto:
          </label>
          <ReactQuill
            id="tour-content-text"
            type="text"
            value={isEmpty(text) ? '' : text}
            name="text"
            onChange={handleTextChange}
            className="tour-content-text-form__text-editor"
            modules={MODULES}
            formats={FORMATS}
          />
        </div>
      </div>
      <div className="row justify-content-end mt-3 align-items-center">
        <div className="left__category-item mb-3">
          <label
            htmlFor="header-text"
            className="custom-checkbox"
          >
            {"Texto de cabecera"}
            <input
              type="checkbox"
              id="header-text"
              onChange={handleInputChange}
              defaultChecked={localTourContent.attributes["header-text"]}
              name="header-text"
            />
            <i />
          </label>
        </div>
      </div>
      {isVisibleExtraBtns
        ? (
          <div className="row justify-content-end">
            <div className="col-12 col-md-auto pr-md-1">
              <button
                type="button"
                onClick={() => handleToggleVisibility()}
                className="btn btn-primary d-block w-100 mt-3"
              >
                {`${isVisible ? 'ocultar' : 'mostrar'} en ${isChildTourContent ? 'el destino' : 'la ruta'}`}
              </button>
            </div>
            <div className="col-12 col-md-auto px-md-1">
              <PromiseButton
                type="button"
                onClick={() => handleDelete()}
                className="btn btn-danger d-block w-100 mt-3"
                disabled={isEmpty(localTourContent.id)}
              >
                {`eliminar ${isChildTourContent ? 'del destino' : 'de la ruta'}`}
              </PromiseButton>
            </div>
          </div>

        )
        : null}
    </div>
  );
};

tourContentTextFormTemplate.propTypes = {
  localTourContent: TYPES.tourContentType.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default tourContentTextFormTemplate;
