import React from 'react';
import PropTypes from 'prop-types';
import TourConfigNavMenuTemplate from './tour-config-nav-menu-template';

const TourConfigNavMenu = (props) => {
  const { activeTab, handleConfigNavigation } = props;
  return <TourConfigNavMenuTemplate activeTab={activeTab} handleConfigNavigation={handleConfigNavigation} />;
};

TourConfigNavMenu.propTypes = {
  activeTab: PropTypes.number.isRequired,
  handleConfigNavigation: PropTypes.func.isRequired,
};

export default TourConfigNavMenu;
