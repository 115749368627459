import React from 'react';
import ReactPlayer from 'react-player';
import TYPES from '../../../../../constants/types';
import { isEmpty } from '../../../../../utils';
import { VIDEO_PLAYER_CONFIG } from '../../../../../constants';

function TourContentVideoBox(props) {
  const { tourContent } = props;
  const { attributes: { 'video-url': videoUrl = null, text = null } = {} } = tourContent;

  return (
    <article className="container tour-content-video-box">
      <div className="row">
        <div className="col-12 tour-content-video-box__video-wrapper">
          <ReactPlayer url={videoUrl} config={VIDEO_PLAYER_CONFIG} width="100%" height="100%" />
        </div>
        {isEmpty(text) ? null : (
          <div className="col-12 p-0">
            <p className="tour-content-video-box__text" dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        )}
      </div>
    </article>
  );
}

TourContentVideoBox.propTypes = {
  tourContent: TYPES.tourContentType.isRequired,
};

export default TourContentVideoBox;
