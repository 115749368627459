/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import InputRange from 'react-input-range-ios-fix';
import Textarea from 'react-textarea-autosize';
import { connect } from 'react-redux';
import api from '../../config/api-endpoints';
import {
  TopSelect, ValidationInput, PoiEditionHeader, PromiseButton,
} from '../../components';
import {
  CATEGORY_OPTIONS, POI_LANGUAGES, SERVICE_CATEGORIES, POI_EDITION_IMG_DINAMIC_URL,
} from '../../constants';
import TYPES from '../../constants/types';
import {
  MAP_CIRCLE_ICON,
  FLAG_BASE_ICON,
  MARKER_ICON,
  FLOWER_ICON,
  EIFFEL_TOWER_ICON,
  CHECK_ICON,
  CLOSE_ICON,
} from '../../helpers';
import TagAjaxManager from '../../components/autocomplete-tag/tag-ajax-manager';
import { isEmpty } from '../../utils';
import MainLayout from '../../layouts/main-layout';
import DisabledPlaceEdition from '../../components/disabled-place-edition';
import { Component } from 'react';
import ReactQuill from 'react-quill';

const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];

function handledQuill(text){
  console.log(text);
}

class PoiInfoPagePreviewTemplate extends Component {
  state = {
    Dflag_spain: true,
    Dflag_united: false,
    Dflag_italy: false,
    Dflag_germany: false,
    Dflag_france: false,
    Tflag_spain: true,
    Tflag_united: false,
    Tflag_italy: false,
    Tflag_germany: false,
    Tflag_france: false,
    partner1: false,
    partner2: false,
    partner3: true,
    partner4: "",
    sponsorlvl: "100",
    hour: "",
    min: "",

  };
  selectPartner1 = () => {
    this.setState({
      partner1: !this.state.partner1,
      partner2: false,
      partner3: false,
      partner4: "",
      sponsorlvl: "1000",
    })
  }
  selectPartner2 = () => {
    this.setState({
      partner2: !this.state.partner2,
      partner1: false,
      partner3: false,
      partner4: "",
      sponsorlvl: "300",
    })
  }
  selectPartner3 = () => {
    this.setState({
      partner3: !this.state.partner3,
      partner1: false,
      partner2: false,
      partner4: "",
      sponsorlvl: "100",
    })
  }
  selectPartner4 = () => {
    this.setState({
      /*       partner4: this.state.partner4, */
      partner1: false,
      partner3: false,
      partner2: false,
      sponsorlvl: this.state.partner4,
    })
  }
  flagSelectDS = () => {
    this.setState({
      Dflag_spain: !this.state.Dflag_spain,
      Dflag_united: false,
      Dflag_italy: false,
      Dflag_germany: false,
      Dflag_france: false,
    })
  }
  flagSelectDU = () => {
    this.setState({
      Dflag_united: !this.state.Dflag_united,
      Dflag_spain: false,
      Dflag_italy: false,
      Dflag_germany: false,
      Dflag_france: false,
    })
  }
  flagSelectDI = () => {
    this.setState({
      Dflag_italy: !this.state.Dflag_italy,
      Dflag_spain: false,
      Dflag_united: false,
      Dflag_germany: false,
      Dflag_france: false,
    })
  }
  flagSelectDG = () => {
    this.setState({
      Dflag_germany: !this.state.Dflag_germany,
      Dflag_spain: false,
      Dflag_united: false,
      Dflag_italy: false,
      Dflag_france: false,
    })
  }
  flagSelectDF = () => {
    this.setState({
      Dflag_france: !this.state.Dflag_france,
      Dflag_spain: false,
      Dflag_united: false,
      Dflag_italy: false,
      Dflag_germany: false,
    })
  }
  flagSelectTS = () => {
    this.setState({
      Tflag_spain: !this.state.Tflag_spain,
      Tflag_united: false,
      Tflag_italy: false,
      Tflag_germany: false,
      Tflag_france: false,
    })
  }
  flagSelectTU = () => {
    this.setState({
      Tflag_united: !this.state.Tflag_united,
      Tflag_spain: false,
      Tflag_italy: false,
      Tflag_germany: false,
      Tflag_france: false,
    })
  }
  flagSelectTI = () => {
    this.setState({
      Tflag_italy: !this.state.Tflag_italy,
      Tflag_spain: false,
      Tflag_united: false,
      Tflag_germany: false,
      Tflag_france: false,
    })
  }
  flagSelectTG = () => {
    this.setState({
      Tflag_germany: !this.state.Tflag_germany,
      Tflag_spain: false,
      Tflag_united: false,
      Tflag_italy: false,
      Tflag_france: false,
    })
  }
  flagSelectTF = () => {
    this.setState({
      Tflag_france: !this.state.Tflag_france,
      Tflag_spain: false,
      Tflag_united: false,
      Tflag_italy: false,
      Tflag_germany: false,
    })
  }

  componentDidMount() {
    if (this.props.props.poi.attributes["poi-duration"] != null) {
      this.setState({
        hour: this.props.props.formattedDuration(this.props.props.poi.attributes["poi-duration"]).hour,
        min: this.props.props.formattedDuration(this.props.props.poi.attributes["poi-duration"]).min
      })
    }
  }


  render() {
    const {
      computeDuration,
      handleChange,
      handleSelect,
      handleRange,
      handleSave,
      openModal,
      poi,
      dispatch,
      poiId,
      errors,
      isMobile,
      hasRequirements,
      hasPublishRequirements,
      handleShowPublishModal,
      auth,
    } = this.props.props;
    var {
      attributes: {
        'poi-type': poiType,
        assessment,
        title,
        titleEn,
        titleIt,
        titleGr,
        titleFr,
        description,
        descriptionEn,
        descriptionIt,
        descriptionGr,
        descriptionFr,
        sponsorPrice,
        category,
        lat,
        long,
        township,
        'full-address': fullAddress,
        phone,
        price,
        website,
        'twitter-url': twitterUrl,
        'facebook-url': facebookUrl,
        'instagram-url': instagramUrl,
        schedule,
        language = 'es-es',
        state: poiState,
      } = {},
    } = poi || {};
    var special;
    const poiName = poiType === 1 || poiType === 3 ? 'destino' : 'servicio';
    const categoryOptions = poiType === 1 ? CATEGORY_OPTIONS : SERVICE_CATEGORIES;
    const createPoiPreview = (poi) => {
      poi.attributes["poi-duration"] = computeDuration(this.state.hour, this.state.min) == 0 ? null : computeDuration(this.state.hour, this.state.min);
      poi.attributes.sponsorPrice = parseInt(this.state.sponsorlvl);
      handleSave(3);
    }
    const selectedCategory = categoryOptions.find(option => option.value === category);
    const selectedLanguage = POI_LANGUAGES.find(option => option.value === language);
    const modelErrors = {};
    errors.forEach((error) => {
      if (error.source && error.source.pointer) {
        const attributeName = error.source.pointer.split('/').pop();
        modelErrors[attributeName] = error.title || error.detail;
      }
    });

    // Disabled edition on mobile
    // const contextualActions = () => (
    //   <ul className="navbar-nav d-lg-none navbar-contextuals">
    //     <li className="nav-item">
    //       <PromiseButton
    //         type="button"
    //         className="nav-link"
    //         onClick={() => handleSave(null, POI_EDITION_IMG_DINAMIC_URL(poiId))}
    //         disabled={!hasRequirements}
    //       >
    //         {IMAGE_ICON}
    //       </PromiseButton>
    //     </li>
    //     <li className="nav-item">
    //       <PromiseButton
    //         type="button"
    //         className="nav-link"
    //         title="Guardar"
    //         onClick={() => handleSave(null)}
    //       >
    //         {CHECK_ICON}
    //       </PromiseButton>
    //     </li>
    //   </ul>
    // );

    const headerDescription = poiType === 1 ? 'Rellena la primera parte del formulario para habilitar el botón '
      + 'inferior con la subida de imágenes. Al completar los campos obligatorios del formulario y al menos una '
      + 'imagen del destino podrás publicarlo y añadirlo en tus rutas. Recuerda que para que se vea de forma '
      + 'pública en la sección de “Descubre” necesitará pasar un proceso de validación (excepto si eres un '
      + 'usuario Toptraveler).' : 'Rellena el formulario y añade una imagen general para crear el recurso '
      + 'en la base de datos. Podrás después personalizarlo con tus comentarios, imágenes y vídeo si '
      + 'recomiendas este servicio en una ruta';

    return (
      <>
        {false ? (
          // Disabled edition on mobile
          <DisabledPlaceEdition />
        ) : (
            <section className="container-fluid poi-edition">
              <div className="row">
                <div className="d-flex justify-content-end w-100 navTop">
                  <h5 className="navTop__title">Crear destino en modo previo</h5>
                  <div className="d-flex navTop__state">
                    <h5>ESTADO: </h5>
                    <div>
                      <h5>PREVIO</h5>
                    </div>
                    <button
                      type="button"
                      className="tour-content-edition-modal__header-btn blueCheck"
                      /* onClick={() => handleSave(3)} */
                      onClick={() => createPoiPreview(poi)}
                    >
                      {CHECK_ICON}
                    </button>
                    <button
                      type="button"
                      className="tour-content-edition-modal__header-btn"
                    /* onClick={handleCloseModal} */
                    >
                      {CLOSE_ICON}
                    </button>
                  </div>
                </div>
                {/* <PoiEditionHeader mobileTitle="Crear nuevo destino">
                <div className="col-lg-8 mx-auto">
                  <h3 className="poi-edition-header__title">
                    {`1. Añade la información del ${poiName}`}
                  </h3>
                  <p className="poi-edition-header__description">
                    {headerDescription}
                  </p>
                </div>
              </PoiEditionHeader> */}
                <div className="poi-info-page-preview w-100">
                  <div className="row">
                    <div className="col-12 col-lg-11">
                      <div className="poi-info-page-preview__main">
                        {/* <img
                        src={FLAG_BASE_ICON}
                        alt="Destino"
                        className="poi-info-page-preview__main-deco poi-info-page-preview__main-deco--top"
                      />
                      <img
                        src={MARKER_ICON}
                        alt="Destino"
                        className="poi-info-page-preview__main-deco poi-info-page-preview__main-deco--bottom"
                      /> */}
                        <div className="form-group row mb-0 mb-lg-4 d-flex flex-column">
                          <div className={this.state.Tflag_spain ? "d-flex" : "d-none"}>
                            <label htmlFor="title" className="col-auto col-form-label text-medium pl-lg-1 d-flex flex-column align-items-end">
                              Título:
                              <img
                                width="20"
                                alt="flag button"
                                src='../../assets/images/spain.svg'
                              />
                            </label>
                            <ValidationInput
                              className="col-12 col-lg pl-lg-0"
                              errorMsg={modelErrors.title}
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="title"
                                name="title"
                                onChange={handleChange}
                                value={title || ''}
                              />
                            </ValidationInput>
                          </div>
                          <div className={this.state.Tflag_united ? "d-flex" : "d-none"}>
                            <label htmlFor="titleEn" className="col-auto col-form-label text-medium pl-lg-1 d-flex flex-column align-items-end">
                              Título:
                              <img
                                width="20"
                                alt="flag button"
                                src='../../assets/images/united-kingdom.svg'
                              />
                            </label>
                            <ValidationInput
                              className="col-12 col-lg pl-lg-0"
                              errorMsg={modelErrors.title}
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="titleEn"
                                name="titleEn"
                                onChange={handleChange}
                                value={titleEn || ''}
                              />
                            </ValidationInput>
                          </div>
                          <div className={this.state.Tflag_italy ? "d-flex" : "d-none"}>
                            <label htmlFor="titleIt" className="col-auto col-form-label text-medium pl-lg-1 d-flex flex-column align-items-end">
                              Título:
                              <img
                                width="20"
                                alt="flag button"
                                src='../../assets/images/italy.svg'
                              />
                            </label>
                            <ValidationInput
                              className="col-12 col-lg pl-lg-0"
                              errorMsg={modelErrors.title}
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="titleIt"
                                name="titleIt"
                                onChange={handleChange}
                                value={titleIt || ''}
                              />
                            </ValidationInput>
                          </div>
                          <div className={this.state.Tflag_germany ? "d-flex" : "d-none"}>
                            <label htmlFor="titleGr" className="col-auto col-form-label text-medium pl-lg-1 d-flex flex-column align-items-end">
                              Título:
                              <img
                                width="20"
                                alt="flag button"
                                src='../../assets/images/germany.svg'
                              />
                            </label>
                            <ValidationInput
                              className="col-12 col-lg pl-lg-0"
                              errorMsg={modelErrors.title}
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="titleGr"
                                name="titleGr"
                                onChange={handleChange}
                                value={titleGr || ''}
                              />
                            </ValidationInput>
                          </div>
                          <div className={this.state.Tflag_france ? "d-flex" : "d-none"}>
                            <label htmlFor="titleFr" className="col-auto col-form-label text-medium pl-lg-1 d-flex flex-column align-items-end">
                              Título:
                              <img
                                width="20"
                                alt="flag button"
                                src='../../assets/images/france.svg'
                              />
                            </label>
                            <ValidationInput
                              className="col-12 col-lg pl-lg-0"
                              errorMsg={modelErrors.title}
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="titleFr"
                                name="titleFr"
                                onChange={handleChange}
                                value={titleFr || ''}
                              />
                            </ValidationInput>
                          </div>
                          <div className="allFlagsTitle">
                            <button onClick={this.flagSelectTS} className={!this.state.Tflag_spain
                              ? 'flag ml-0'
                              : 'flag select ml-0'}>
                              <img
                                alt="flag button"
                                src='../../assets/images/spain.svg'
                              />
                            </button>
                            <button onClick={this.flagSelectTU} className={!this.state.Tflag_united
                              ? 'flag'
                              : 'flag select'}>
                              <img
                                alt="flag button"
                                src='../../assets/images/united-kingdom.svg'
                              />
                            </button>
                            <button onClick={this.flagSelectTI} className={!this.state.Tflag_italy
                              ? 'flag'
                              : 'flag select'}>
                              <img
                                alt="flag button"
                                src='../../assets/images/italy.svg'
                              />
                            </button>
                            <button onClick={this.flagSelectTG} className={!this.state.Tflag_germany
                              ? 'flag'
                              : 'flag select'}>
                              <img
                                alt="flag button"
                                src='../../assets/images/germany.svg'
                              />
                            </button>
                            <button onClick={this.flagSelectTF} className={!this.state.Tflag_france
                              ? 'flag'
                              : 'flag select'}>
                              <img
                                alt="flag button"
                                src='../../assets/images/france.svg'
                              />
                            </button>
                          </div>
                        </div>
                        {/* categoria */}
                        <div className="form-group row mb-0 mb-lg-4">
                          <label htmlFor="category" className="col-auto col-form-label text-medium pl-lg-1">
                            Categoría:
                      </label>
                          <div className="col-12 col-lg-4 pl-lg-0">
                            <TopSelect
                              value={selectedCategory}
                              options={categoryOptions}
                              name="category"
                              placeholder="Selecciona una categoría"
                              handleChange={handleSelect}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="row align-items-center col-12 pl-lg-1 m-0">
                            <h4 className="text-medium mb-0">Localización GPS:</h4>
                            <div className="row align-items-center col-lg">
                              <div className="col-6 col-lg-9">
                                <div className="row">
                                  <ValidationInput
                                    className="col-10 col-lg ml-auto"
                                    errorMsg={modelErrors.lat}
                                  >
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="lat"
                                      value={long || ''}
                                      placeholder="Longitud"
                                    />
                                    {/* <p className="poi-info-page-preview__location-text">Latitud</p> */}
                                  </ValidationInput>
                                  <ValidationInput
                                    className="col-10 col-lg ml-auto"
                                    errorMsg={modelErrors.lat}
                                  >
                                    <input
                                      type="number"
                                      className="form-control mt-2 mt-lg-0"
                                      name="long"
                                      value={lat || ''}
                                      placeholder="Latitud"
                                    />
                                    {/* <p className="poi-info-page-preview__location-text">Longitud</p> */}
                                  </ValidationInput>
                                </div>
                              </div>
                              <div className="col-3 poi-info-page-preview__location">
                                <button
                                  href="#"
                                  className="poi-info-page-preview__location-btn d-flex align-items-center"
                                  type="button"
                                  onClick={openModal}
                                >
                                  <img src={MAP_CIRCLE_ICON} alt="Mapa" className="poi-info-page-preview__location-img" />
                                  <p className="poi-info-page-preview__location-text ml-3">{"<  "}Ubicar con el mapa</p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* descripcion */}
                        <div className="form-group row mb-2 mb-lg-4 mt-4 d-flex flex-column">
                          <div className={this.state.Dflag_spain ? "d-flex" : "d-none"}>
                            <label htmlFor="description" className="col-auto col-form-label text-medium pl-lg-1 d-flex flex-column align-items-end">
                              Descripción:
                              <img
                                width="20"
                                alt="flag button"
                                src='../../assets/images/spain.svg'
                              />
                            </label>
                            <ValidationInput
                              className="col-10"
                              errorMsg={modelErrors.description}
                            >
                              <Textarea
                                id="description"
                                name="description"
                                minRows={4}
                                className="form-control"
                                value={description || ''}
                                onChange={handleChange}
                              />
                            </ValidationInput>
                          </div>
                          <div className={this.state.Dflag_united ? "d-flex" : "d-none"}>
                            <label htmlFor="descriptionEn" className="col-auto col-form-label text-medium pl-lg-1 d-flex flex-column align-items-end">
                              Descripción:
                              <img
                                width="20"
                                alt="flag button"
                                src='../../assets/images/united-kingdom.svg'
                              />
                            </label>
                            <ValidationInput
                              className="col-10"
                              errorMsg={modelErrors.description}
                            >
                              <Textarea
                                id="descriptionEn"
                                name="descriptionEn"
                                minRows={4}
                                className="form-control"
                                value={descriptionEn || ''}
                                onChange={handleChange}
                              />
                            </ValidationInput>
                          </div>
                          <div className={this.state.Dflag_italy ? "d-flex" : "d-none"}>
                            <label htmlFor="descriptionIt" className="col-auto col-form-label text-medium pl-lg-1 d-flex flex-column align-items-end">
                              Descripción:
                              <img
                                width="20"
                                alt="flag button"
                                src='../../assets/images/italy.svg'
                              />
                            </label>
                            <ValidationInput
                              className="col-10"
                              errorMsg={modelErrors.description}
                            >
                              <Textarea
                                id="descriptionIt"
                                name="descriptionIt"
                                minRows={4}
                                className="form-control"
                                value={descriptionIt || ''}
                                onChange={handleChange}
                              />
                            </ValidationInput>
                          </div>
                          <div className={this.state.Dflag_germany ? "d-flex" : "d-none"}>
                            <label htmlFor="descriptionGr" className="col-auto col-form-label text-medium pl-lg-1 d-flex flex-column align-items-end">
                              Descripción:
                              <img
                                width="20"
                                alt="flag button"
                                src='../../assets/images/germany.svg'
                              />
                            </label>
                            <ValidationInput
                              className="col-10"
                              errorMsg={modelErrors.description}
                            >
                              <Textarea
                                id="descriptionGr"
                                name="descriptionGr"
                                minRows={4}
                                className="form-control"
                                value={descriptionGr || ''}
                                onChange={handleChange}
                              />
                            </ValidationInput>
                          </div>
                          <div className={this.state.Dflag_france ? "d-flex" : "d-none"}>
                            <label htmlFor="descriptionFr" className="col-auto col-form-label text-medium pl-lg-1 d-flex flex-column align-items-end">
                              Descripción:
                              <img
                                width="20"
                                alt="flag button"
                                src='../../assets/images/france.svg'
                              />
                            </label>
                            <ValidationInput
                              className="col-10"
                              errorMsg={modelErrors.description}
                            >
                              <Textarea
                                id="descriptionFr"
                                name="descriptionFr"
                                minRows={4}
                                className="form-control"
                                value={descriptionFr || ''}
                                onChange={handleChange}
                              />
                            </ValidationInput>
                          </div>
                          <div className="allFlagsDescription">
                            <button onClick={this.flagSelectDS} className={!this.state.Dflag_spain
                              ? 'flag ml-0'
                              : 'flag select ml-0'}>
                              <img
                                alt="flag button"
                                src='../../assets/images/spain.svg'
                              />
                            </button>
                            <button onClick={this.flagSelectDU} className={!this.state.Dflag_united
                              ? 'flag'
                              : 'flag select'}>
                              <img
                                alt="flag button"
                                src='../../assets/images/united-kingdom.svg'
                              />
                            </button>
                            <button onClick={this.flagSelectDI} className={!this.state.Dflag_italy
                              ? 'flag'
                              : 'flag select'}>
                              <img
                                alt="flag button"
                                src='../../assets/images/italy.svg'
                              />
                            </button>
                            <button onClick={this.flagSelectDG} className={!this.state.Dflag_germany
                              ? 'flag'
                              : 'flag select'}>
                              <img
                                alt="flag button"
                                src='../../assets/images/germany.svg'
                              />
                            </button>
                            <button onClick={this.flagSelectDF} className={!this.state.Dflag_france
                              ? 'flag'
                              : 'flag select'}>
                              <img
                                alt="flag button"
                                src='../../assets/images/france.svg'
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="form-group row mb-0 mb-lg-4">
                      <label htmlFor="category" className="col-auto col-form-label text-medium pl-lg-1">
                        Idioma de entrada:
                      </label>
                      <div className="col-auto pl-lg-0">
                        <TopSelect
                          value={selectedLanguage}
                          options={POI_LANGUAGES}
                          name="language"
                          handleChange={handleSelect}
                          withIcons
                        />
                      </div>
                    </div> */}
                      <div className="row">
                        <div className="row align-items-center col-12 pl-lg-1 m-0">
                          <h4 className="text-medium mb-0 label">Nivel de patrocinio:</h4>
                          <div className="row align-items-center col-lg">
                            <div className="col-6 col-lg-11">
                              <div className="row">
                                <button onClick={this.selectPartner1} className={!this.state.partner1
                                  ? 'col-10 col-lg-2 ml-auto camp'
                                  : 'col-10 col-lg-2 ml-auto camp camp selectpartner'}>
                                  A: 1000 € / año
                                </button>
                                <button onClick={this.selectPartner2} className={!this.state.partner2
                                  ? 'col-10 col-lg-2 ml-auto camp'
                                  : 'col-10 col-lg-2 ml-auto camp camp selectpartner'}>
                                  B: 300 € / año
                                </button>
                                <button onClick={this.selectPartner3} className={!this.state.partner3
                                  ? 'col-10 col-lg-2 ml-auto camp'
                                  : 'col-10 col-lg-2 ml-auto camp camp selectpartner'}>
                                  C: 100 € / año
                                </button>
                                <ValidationInput
                                  className="col-10 col-lg-4 ml-auto"
                                  errorMsg={modelErrors.lat}
                                >
                                  <input
                                    type="number"
                                    className="form-control special"
                                    name="sponsorPrice"
                                    onClick={this.selectPartner4}
                                    value={this.state.partner4}
                                    placeholder="Especial: escribe la cantidad"
                                    onChange={(e) => this.setState({
                                      sponsorlvl: e.target.value,
                                      partner4: e.target.value
                                    })}
                                  /* onChange={handleChange} */
                                  />
                                  {/* <p className="poi-info-page-preview__location-text">Latitud</p> */}
                                </ValidationInput>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row form-group mb-0 mb-lg-4">
                        {/* <h4 className="col-12 poi-info-page-preview__section-title">Campos opcionales</h4> */}
                        <label
                          htmlFor="keys"
                          className={`label col-auto col-form-label text-medium pl-lg-1 ${poiState !== 3 ? 'pt-lg-3' : ''}`}
                        >
                          Palabras clave:
                      </label>
                        <div className="col-12 col-lg pl-lg-0">
                          <TagAjaxManager
                            getCurrentResourceTags={() => dispatch(api.getPoiTags({ id: poiId }))}
                            associateTagToResource={jsonApiRequest =>
                              dispatch(api.upsertPoiTags({ id: poiId }, jsonApiRequest))}
                            removeTagFromResource={jsonApiRequest =>
                              dispatch(api.deletePoiTags({ id: poiId, tagId: jsonApiRequest.data.id }, jsonApiRequest))}
                            canDelete={poiState !== 3 || auth.currentUser.id == 14 || auth.currentUser.id == 40 || auth.currentUser.id == 22}
                            showInput={poiState !== 3 || auth.currentUser.id == 14 || auth.currentUser.id == 40 || auth.currentUser.id == 22}
                          />
                          {poiState !== 3 || auth.currentUser.id == 14 || auth.currentUser.id == 40 || auth.currentUser.id == 22 ? (
                            <small className="d-block mt-1 text-start">
                              Pulsa &quot;espacio&quot; para crear una palabra o selecciona alguna de las sugeridas
                            </small>
                          ) : null}
                        </div>
                      </div>
                      <div className="row form-group mb-0 mb-lg-4">
                        {/* <h4 className="col-12 poi-info-page-preview__section-title">Campos opcionales</h4> */}
                        <label
                          htmlFor="keys"
                          className={`label col-auto col-form-label text-medium pl-lg-1 ${poiState !== 3 ? 'pt-lg-3' : ''}`}
                        >
                          Etiquetas ocultas:
                      </label>
                        <div className="col-12 col-lg pl-lg-0">
                          <TagAjaxManager
                            getCurrentResourceTags={() => dispatch(api.getPoiTags({ id: poiId }))}
                            associateTagToResource={jsonApiRequest =>
                              dispatch(api.upsertPoiTags({ id: poiId }, jsonApiRequest))}
                            removeTagFromResource={jsonApiRequest =>
                              dispatch(api.deletePoiTags({ id: poiId, tagId: jsonApiRequest.data.id }, jsonApiRequest))}
                            canDelete={poiState !== 3 || auth.currentUser.id == 14 || auth.currentUser.id == 40 || auth.currentUser.id == 22}
                            showInput={poiState !== 3 || auth.currentUser.id == 14 || auth.currentUser.id == 40 || auth.currentUser.id == 22}
                          />
                        </div>
                      </div>

                      <div className="form-group row mb-0 mb-lg-4">
                        <label htmlFor="website" className="label col-auto col-form-label text-medium pl-lg-1">
                          Web oficial:
                    </label>
                        <ValidationInput
                          className="col-12 col-lg pl-lg-0"
                          errorMsg={modelErrors.website}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="website"
                            name="website"
                            value={website || ''}
                            onChange={handleChange}
                          />
                        </ValidationInput>
                      </div>

                      <div className="form-group row mb-0 mb-lg-4">
                        <label htmlFor="address" className="label col-auto col-form-label text-medium pl-lg-1">
                          Dirección:
                    </label>
                        <ValidationInput
                          className="col-12 col-lg pl-lg-0"
                          errorMsg={modelErrors['full-address']}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="full-address"
                            value={fullAddress || ''}
                            onChange={handleChange}
                          />
                        </ValidationInput>
                      </div>
                      <div className="form-group row mb-0 mb-lg-4">
                        <label htmlFor="phone" className="label col-auto col-form-label text-medium pl-lg-1">
                          Teléfono:
                    </label>
                        <ValidationInput
                          className="col-12 col-lg pl-lg-0"
                          errorMsg={modelErrors.phone}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            value={phone || ''}
                            onChange={handleChange}
                          />
                        </ValidationInput>
                      </div>
                      <div className="row form-group mb-0 mb-lg-4">
                        <label htmlFor="schedule" className="label col-auto col-form-label text-medium pl-lg-1">
                          Horario:
                      </label>
                        <ValidationInput
                          className="col-12 col-lg pl-lg-0"
                          errorMsg={modelErrors.lat}
                        >
                          <Textarea
                            id="schedule"
                            name="schedule"
                            minRows={2}
                            className="form-control"
                            value={schedule || ''}
                            onChange={handleChange}
                          />
                          {/* <ReactQuill
                            id="schedule"
                            value={schedule || ''}
                            // onChange={handleTextChange}
                            placeholder="Escribe aquí la descripción"
                            onChange={handledQuill}
                            // onChange={()=>handleChange(description, image.id)}
                            className="tour-config-main__rich-text-editor"
                            modules={MODULES}
                            formats={FORMATS}
                          /> */}
                        </ValidationInput>
                      </div>
                      <div className="form-group row mb-0 mb-lg-4">
                        <label htmlFor="town" className="label2 col-auto col-form-label text-medium pl-lg-1">
                          Ciudad o pueblo al que pertenece:
                        </label>
                        <ValidationInput
                          className="col-12 col-lg pl-lg-0"
                          errorMsg={modelErrors.township}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="town"
                            name="township"
                            value={township || ''}
                            onChange={handleChange}
                          />
                        </ValidationInput>
                      </div>
                      <div className="form-group row mt-2 mb-0 mb-lg-4">
                        <h4 className="label2 col-auto text-medium mt-2 pl-lg-1">Tiempo estimado de visita:</h4>
                        <div className="col-12 col-lg pl-lg-0">
                          <div className="row">
                            <ValidationInput
                              className="col input-group"
                              errorMsg={modelErrors.hour}
                            >
                              <input
                                type="number"
                                className="form-control text-right"
                                id="hour"
                                name="hour"
                                value={this.state.hour || "0"}
                                onChange={(e) => this.setState({
                                  hour: e.target.value,
                                })}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">h</span>
                              </div>
                            </ValidationInput>
                            <ValidationInput
                              className="col input-group"
                              errorMsg={modelErrors.min}
                            >
                              <input
                                type="number"
                                className="form-control text-right"
                                id="min"
                                name="min"
                                value={this.state.min || '00'}
                                onChange={(e) => this.setState({
                                  min: e.target.value,
                                })}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">min</span>
                              </div>
                            </ValidationInput>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row mb-0 mb-lg-4">
                        <label htmlFor="price" className="label2 col-auto col-form-label text-medium pl-lg-1">
                          Precio:
                        </label>
                        <ValidationInput
                          className="col-12 col-lg pl-lg-0"
                          errorMsg={modelErrors.price}
                        >
                          <Textarea
                            id="price"
                            name="price"
                            minRows={1}
                            className="form-control"
                            value={price || ''}
                            onChange={handleChange}
                          />
                        </ValidationInput>
                      </div>

                      <div className="form-group row align-items-start">
                        <label htmlFor="assessment" className="col-auto col-form-label text-medium mb-2">
                          Valoración:
                        </label>
                        <div className="w-100" />
                        <div className="col-auto pr-0">
                          <img
                            src={FLOWER_ICON}
                            alt=""
                            className="poi-info-page-preview__range-img poi-info-page-preview__range-img--left"
                          />
                        </div>
                        <div className="col px-4">
                          <InputRange
                            minValue={0}
                            maxvalue={10}
                            onChange={handleRange}
                            id="assessment"
                            name="assessment"
                            value={isEmpty(assessment) ? 0 : assessment}
                          />
                          <span className="poi-info-page-preview__range-text poi-info-page-preview__range-text--left">
                            Atracción local
                          </span>
                          <span className="poi-info-page-preview__range-text poi-info-page-preview__range-text--right">
                            Maravilla mundial
                          </span>
                        </div>
                        <div className="col-auto pl-0">
                          <img
                            src={EIFFEL_TOWER_ICON}
                            alt=""
                            className="poi-info-page-preview__range-img poi-info-page-preview__range-img--right"
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
      </>
    );
  };
}

PoiInfoPagePreviewTemplate.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleRange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleShowPublishModal: PropTypes.func.isRequired,
  poiId: PropTypes.string.isRequired,
  hour: PropTypes.number,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  poi: TYPES.poiType.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  isMobile: PropTypes.bool,
  hasRequirements: PropTypes.bool.isRequired,
  hasPublishRequirements: PropTypes.bool.isRequired,
};

PoiInfoPagePreviewTemplate.defaultProps = {
  hour: null,
  min: null,
  errors: [],
  isMobile: false,
  auth: null,
};

const mapStateToProps = state => ({
  isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  auth: state.auth,
});

export default connect(mapStateToProps)(PoiInfoPagePreviewTemplate);
