import { useState, useEffect } from 'react';
import reduxBeeMakeRequest from '../../utils/redux-bee/redux-bee-make-request';
import { isEmpty, isEmptyObject } from '../../utils';
import reduxStore, { dispatch } from '../../reduxStore';
import useIsMounted from './useIsMounted';

export default function useGetResource(queryParams, resourceType, endPoint, catchCB = null) {
  /* console.log("1",queryParams, "2",resourceType, "3",endPoint, "4",catchCB ) */
  const [resource, setResource] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const isMounted = useIsMounted();
  const { id: resourceId } = queryParams;
  useEffect(
    () => {
      const familyResources = reduxStore.getState().bees.entities[resourceType];
      const storedResource = familyResources && familyResources[resourceType];

      if (isEmpty(storedResource) && !isEmptyObject(queryParams) && !isEmpty(resourceType) && !isEmpty(endPoint)) {
        // setIsLoading(true);
        reduxBeeMakeRequest(dispatch, endPoint, { ...queryParams })
          .then((serverResource) => {
            if (isMounted) {
              setResource(serverResource);
              // setIsLoading(false);
            }
          })
          .catch((reason) => {
            if (!isEmptyObject(catchCB)) {
              if (isMounted) {
                // setIsLoading(false);
                catchCB(reason);
              }
            } else if (isMounted) {
              setResource(() => {
                throw reason;
              });
            }
          });
      } else if (isMounted) {
        setResource(storedResource);
      }
    },
    [resourceId, resourceType, endPoint],
  );
  // to use isLoading a minor refactor is required where instead of expecting only the resource
  // an array is expected, if in the future isLoading is required, do the refactor and testing.
  // return [resource, isLoading];
  return resource;
}
