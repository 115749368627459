import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import TYPES from '../../../../../../constants/types';
import { LOGO_YOUTUBE_ICON, LOGO_VIMEO_ICON } from '../../../../../../helpers';
import { isEmpty } from '../../../../../../utils';
import { PromiseButton } from '../../../../../../components';

const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];

const TourContentVideoFormTemplate = (props) => {
  const {
    localTourContent,
    handleInput,
    handleTextChange,
    handleDelete,
    handleToggleVisibility,
  } = props;

  const {
    attributes: { 'video-url': videoUrl, text, visible: isVisible } = {},
    relationships: {
      'tour-content': { data: parentTourContent },
    },
  } = localTourContent;

  const isVisibleExtraBtns = !isEmpty(localTourContent.id);

  return (
    <div className="container-fluid tour-content-video-form">
      <div className="row">
        <div className="col-auto ml-auto">
          <p className="tour-content-video-form__supported-text">Formatos soportados:</p>
          <span className="tour-content-video-form__supported-type">
            {LOGO_YOUTUBE_ICON}
            Youtube
          </span>
          <span className="tour-content-video-form__supported-type">
            {LOGO_VIMEO_ICON}
            Vimeo
          </span>
        </div>
        <div className="col-12 mt-2">
          <label htmlFor="video-url" className="tour-content-video-form__label">
            Url del vìdeo
          </label>
          <input
            id="video-url"
            name="video-url"
            type="text"
            value={videoUrl || ''}
            onChange={handleInput}
            className="form-control"
          />
        </div>
        <div className="col-12 mt-2">
          <label htmlFor="video-text" className="tour-content-video-form__label">
            Texto:
          </label>
          <ReactQuill
            type="text"
            value={text}
            onChange={handleTextChange}
            className="tour-content-video-form__text-editor"
            modules={MODULES}
            formats={FORMATS}
          />
        </div>
      </div>
      {isVisibleExtraBtns
        ? (
          <div className="row justify-content-end">
            <div className="col-12 col-md-auto pr-md-1">
              <button
                type="button"
                onClick={() => handleToggleVisibility()}
                className="btn btn-primary d-block w-100 mt-3"
              >
                {`${isVisible ? 'ocultar' : 'mostrar'} en ${
                  isEmpty(parentTourContent) ? 'la ruta' : 'el destino'
                }`}
              </button>
            </div>
            <div className="col-12 col-md-auto px-md-1">
              <PromiseButton
                type="button"
                onClick={() => handleDelete()}
                className="btn btn-danger d-block w-100 mt-3"
                disabled={isEmpty(localTourContent.id)}
              >
                {`eliminar ${isEmpty(parentTourContent) ? 'de la ruta' : 'del destino'}`}
              </PromiseButton>
            </div>
          </div>
        )
        : null}
    </div>
  );
};

TourContentVideoFormTemplate.propTypes = {
  localTourContent: TYPES.tourContentType.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

TourContentVideoFormTemplate.defaultProps = {};

export default TourContentVideoFormTemplate;
