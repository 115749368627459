import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import _isEqual from 'lodash.isequal';
import TYPES from '../../../../../constants/types';
import TourConfigSponsorTemplate from './tour-config-sponsor-template';
import { isEmpty, confirmationToastr, handleError } from '../../../../../utils';
import reduxBeeMakeRequest from '../../../../../utils/redux-bee/redux-bee-make-request';
import VALIDATIONS from '../../../../../utils/validations';
import { UPDATE_PENDING_CHANGES } from '../../../../../constants';

const TourConfigSponsor = (props) => {
  const {
    storedTour, storedSponsor, sponsorImages, sponsorLogo, dispatch, handleBindSaves, hasPendingChanges,
  } = props;
  const { relationships: { sponsor: { data: sponsorData } = {} } = {} } = storedTour || {};
  const [localSponsor, setLocalSponsor] = useState(null);
  const [orderedImages, setOrderedImages] = useState([]);

  useEffect(() => {
    if (isEmpty(storedSponsor) && !isEmpty(sponsorData)) {
      reduxBeeMakeRequest(dispatch, 'getSponsor', { id: sponsorData.id }, {});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorData]);

  useEffect(() => {
    if (
      (!isEmpty(storedSponsor) && isEmpty(localSponsor))
      || (isEmpty(storedSponsor) && !isEmpty(localSponsor))
    ) {
      setLocalSponsor(_cloneDeep(storedSponsor));
    }
    const sponsorHasPendingChanges = !isEmpty(localSponsor)
      && !_isEqual(storedSponsor.attributes, localSponsor.attributes);

    if (hasPendingChanges !== sponsorHasPendingChanges) {
      dispatch({ type: UPDATE_PENDING_CHANGES, hasPendingChanges: sponsorHasPendingChanges });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedSponsor, localSponsor]);

  useEffect(() => {
    const newOrderedImages = isEmpty(sponsorImages)
      ? []
      : sponsorImages.sort((a, b) => a.attributes.order - b.attributes.order);
    setOrderedImages(newOrderedImages);
  }, [sponsorImages]);

  useEffect(() => {
    registerLocale('es', es);
  }, []);

  const handleSaveSponsor = useCallback(() => {
    const [isValid, errorMsg] = VALIDATIONS.isValidSponsor(localSponsor);
    return isValid
      ? reduxBeeMakeRequest(dispatch, 'updateSponsor', { id: localSponsor.id }, localSponsor)
        .then(() => {
          toastr.success('Se han guardado los cambios correctamente.');
        })
        .catch((error) => {
          handleError(error);
        })
      : Promise.resolve().then(() => toastr.error(errorMsg));
  }, [dispatch, localSponsor]);

  useEffect(() => {
    if (isEmpty(localSponsor)) {
      handleBindSaves(null);
    } else {
      handleBindSaves(handleSaveSponsor);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSaveSponsor, localSponsor]);

  const handleChange = useCallback(
    (e) => {
      const modifiedSponsor = _cloneDeep(localSponsor);
      const {
        target: { value: newValue, name: attributeName },
      } = e;
      modifiedSponsor.attributes[attributeName] = newValue;
      setLocalSponsor(modifiedSponsor);
    },
    [localSponsor],
  );

  const handleSponsorCreation = useCallback(() => {
    const sponsorPayload = {
      type: 'sponsors',
      attributes: {
        title: null,
        text: null,
        'link-url': null,
        visibility: false,
      },
      relationships: {
        tour: { data: { id: storedTour.id, type: 'tours' } },
      },
    };
    return reduxBeeMakeRequest(dispatch, 'createSponsor', null, sponsorPayload).then(() =>
      reduxBeeMakeRequest(dispatch, 'getTour', { id: storedTour.id }, {}),
    ).catch(handleError);
  }, [dispatch, storedTour]);

  const handleDeleteSponsor = useCallback(
    () => new Promise((resolve) => {
      confirmationToastr(
        '¿Estás segur@ de eliminar el patrocinador?',
        () =>
          reduxBeeMakeRequest(dispatch, 'deleteSponsor', { id: localSponsor.id })
            .then(() => reduxBeeMakeRequest(dispatch, 'getTour', { id: storedTour.id }, {}))
            .then(() => {
              toastr.success('Patrocinador eliminado');
            })
            .catch((error) => {
              handleError(error);
            })
            .finally(() => resolve(true)),
        () => {
          resolve(false);
        },
      );
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localSponsor],
  );

  const handleDeleteImage = useCallback(image => reduxBeeMakeRequest(dispatch, 'deleteImage', { id: image.id }, {})
    .then(() => reduxBeeMakeRequest(dispatch, 'getSponsor', { id: localSponsor.id }, {}))
    .catch(() => {
      toastr.error('No se ha podido eliminar la imagen, por favor, intentalo de nuevo');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [localSponsor],
  );

  const handleOnDragEnd = useCallback(({ oldIndex, newIndex }) => {
    if (isEmpty(newIndex) || oldIndex === newIndex) return;
    const movedImage = orderedImages[oldIndex];
    reduxBeeMakeRequest(
      dispatch,
      'updateImage',
      { id: movedImage.id },
      { ...movedImage, attributes: { ...movedImage.attributes, order: newIndex + 1 } },
    )
      .then(() => reduxBeeMakeRequest(dispatch, 'getSponsorImages', { id: localSponsor.id }, {}))
      .then((images) => {
        const newOrderedImages = images.sort((a, b) => a.attributes.order - b.attributes.order);
        setOrderedImages(newOrderedImages);
      })
      .catch((error) => {
        handleError(error);
      });
  }, [dispatch, localSponsor, orderedImages]);

  const handleOnImageUpload = useCallback(() => {
    reduxBeeMakeRequest(dispatch, 'getSponsor', { id: localSponsor.id }, {});
  }, [dispatch, localSponsor]);

  const handleVisibility = useCallback(() => {
    const modifiedSponsor = _cloneDeep(localSponsor);
    const { visibility } = modifiedSponsor.attributes;
    modifiedSponsor.attributes.visibility = !visibility;
    return reduxBeeMakeRequest(dispatch, 'updateSponsor', { id: localSponsor.id }, modifiedSponsor)
      .then((sponsor) => {
        const { visibility: newVisibility } = sponsor.attributes;
        const successMsg = newVisibility
          ? 'El patrocinador ahora es visible en la ruta'
          : 'El patrocinador no se mostrará en la ruta';
        toastr.success(successMsg);
        setLocalSponsor(sponsor);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => Promise.resolve());
  }, [dispatch, localSponsor]);

  return (
    <TourConfigSponsorTemplate
      localSponsor={localSponsor}
      orderedImages={orderedImages}
      sponsorLogo={sponsorLogo}
      handleSponsorCreation={handleSponsorCreation}
      handleDeleteSponsor={handleDeleteSponsor}
      handleChange={handleChange}
      handleOnImageUpload={handleOnImageUpload}
      handleDeleteImage={handleDeleteImage}
      handleOnDragEnd={handleOnDragEnd}
      handleVisibility={handleVisibility}
      myCampaigns={props.myCampaigns}
      initialLoad={props.initialLoad}
      tourId={props.tour.id}
      currentCampaign={props.currentCampaign}
      getCampaign={props.getCampaign}
      closeModal={props.closeModal}
    />
  );
};

TourConfigSponsor.propTypes = {
  storedTour: TYPES.tourType,
  storedSponsor: TYPES.sponsorType,
  sponsorImages: PropTypes.arrayOf(TYPES.imageType),
  sponsorLogo: TYPES.imageType,
  dispatch: PropTypes.func,
  handleBindSaves: PropTypes.func.isRequired,
  hasPendingChanges: PropTypes.bool,
};

TourConfigSponsor.defaultProps = {
  storedTour: null,
  storedSponsor: null,
  sponsorImages: [],
  sponsorLogo: null,
  dispatch: null,
  hasPendingChanges: false,
};

function mapStateToProps(state, ownProps) {
  const { tour } = ownProps;
  const storedTour = isEmpty(tour) ? null : state.bees.entities.tours[tour.id];
  const { hasPendingChanges } = state.workspace;
  const { relationships: { sponsor: { data: sponsorData = null } = {} } = {} } = storedTour || {};
  const storedSponsors = state.bees.entities.sponsors;
  const storedSponsor = isEmpty(sponsorData) || isEmpty(storedSponsors) ? null : storedSponsors[sponsorData.id];
  const {
    relationships: {
      images: { data: imagesData = [] } = {},
      logo: { data: logoData = null } = {},
    } = {},
  } = storedSponsor || {};
  const sponsorImages = isEmpty(imagesData)
    ? []
    : imagesData.map(imageData => state.bees.entities.images[imageData.id]);
  const sponsorLogo = isEmpty(logoData) ? null : state.bees.entities.images[logoData.id];

  return {
    storedTour,
    storedSponsor,
    sponsorImages,
    sponsorLogo,
    hasPendingChanges,
  };
}

export default connect(mapStateToProps)(TourConfigSponsor);
