import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  PoiView,
  Navbar,
  PartnerBox,
  MapBox,
  AuthorBox,
  GalleryBox,
  ModalImageFile,
} from '../../components';
import TYPES from '../../constants/types';
import { isEmpty } from '../../utils';
import {
  CIRCLE_CROSS_ICON,
} from '../../helpers/svg-inline';
import Modal from 'react-modal';
import axios from 'axios';

class PoiPageTemplate extends Component {
  state = {
    showModalComments: false,
    campaing: null
  }
  openModalComments = () => {
    this.setState({ showModalComments: true })
    const script = document.createElement("script");
    script.src = "https://afkar.ams3.cdn.digitaloceanspaces.com/commento_v16.afkar.js";
    script.async = true;
    document.body.appendChild(script);
    // console.log("lo que sea");
  }
  closeModalComments = () => {
    this.setState({ showModalComments: false })
  }
  componentDidMount = () => {
    const script2 = document.createElement("script");
    script2.src = "https://afkar.ams3.cdn.digitaloceanspaces.com/count.afkar.js";
    document.body.appendChild(script2);
    window.axios = axios;
  }
  get_campaing = (campaing) => {
    this.setState({ campaing })
  }
  render() {
    const {
      poi,
      poiOwner,
      poiOwnerAvatar,
      handleImageModal,
      selectedImage,
      isMobile,
      currentUser,
    } = this.props;
    const poiImages = poi.relatedImages;
    poiImages.sort((a, b) => {
      if (a.attributes && b.attributes) {
        if (a.attributes.likes > b.attributes.likes) {
          return -1;
        }
        if (a.attributes.likes < b.attributes.likes) {
          return 1;
        }
        // a must be equal to b
        return 0;
      }
    });
    return (
      <>
        <Navbar ContextualActions={null} />
        <section className="poi-page">
          <PoiView poi={poi} currentUser={currentUser} openModalComments={this.openModalComments} />
          <PartnerBox get_campaing={this.get_campaing} poi={poi} />
          <GalleryBox images={poi.relatedImages} handleImageModal={handleImageModal} />
          {isMobile ? (
            <MapBox pois={[poi]} boxCategoryTitle="Localización" displayMode="basic" zoom={12} />
          ) : null}
          <AuthorBox author={poiOwner} avatar={poiOwnerAvatar} relatedType="place" />
          <div className="d-flex justify-content-center mt-3">
            <button className="btn btn-primary" onClick={this.openModalComments}>comentar</button>
            <Modal className="ModalComments"
              overlayClassName="ContentModal justify-content-end"
              isOpen={this.state.showModalComments}
              contentLabel="ContentModal"
              onRequestClose={this.closeModalComments}
              shouldCloseOnOverlayClick={true}
            >
              <div className="row m-0 d-flex justify-content-center">
                <div className="buttonClose">
                  <button onClick={this.closeModalComments} className="closeIcon p-0">
                    {CIRCLE_CROSS_ICON}
                  </button>
                </div >
                <div className="d-flex justify-content-start w-100 mt-5 mb-5">
                  <span className="title">Tus comentarios de: {poi.attributes.title}</span>
                </div>
                <div id="commento"></div>
              </div>
            </Modal>
          </div>
        </section>
        {isEmpty(selectedImage) ? null : (
          <ModalImageFile
            poiCampaign={this.state.campaing}
            isOpen={!isEmpty(selectedImage)}
            closeModal={() => handleImageModal(null)}
            poi={poi}
            image={selectedImage}
            images={poiImages}
          />
        )}
      </>
    );
  }

};

PoiPageTemplate.propTypes = {
  poi: TYPES.poiType,
  poiOwner: TYPES.userType,
  poiOwnerAvatar: TYPES.imageType,
  handleImageModal: PropTypes.func.isRequired,
  selectedImage: TYPES.imageType,
  isMobile: PropTypes.bool.isRequired,
};

PoiPageTemplate.defaultProps = {
  poi: null,
  poiOwner: null,
  poiOwnerAvatar: null,
  selectedImage: null,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(PoiPageTemplate);
