import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import TourContentRecommendationFormTemplate from './tour-content-recommendation-form-template';
import tourContentType from '../../../../../../constants/types/tour-content-type';
import { isEmpty } from '../../../../../../utils';
import reduxBeeMakeRequest from '../../../../../../utils/redux-bee/redux-bee-make-request';
import TYPES from '../../../../../../constants/types';

const DISPLAY_MODE_VALUES = {
  0: ['0', '0'],
  1: ['1', '0'],
  2: ['2', '0'],
  3: ['0', '1'],
  4: ['1', '1'],
  5: ['2', '1'],
};

const getDisplayMode = (presentationTour, presentationRecommendation) => {
  if (presentationTour === '0') {
    if (presentationRecommendation === '0') return '0';
    if (presentationRecommendation === '1') return '3';
  }
  if (presentationTour === '1') {
    if (presentationRecommendation === '0') return '1';
    if (presentationRecommendation === '1') return '4';
  }
  if (presentationTour === '2') {
    if (presentationRecommendation === '0') return '2';
    if (presentationRecommendation === '1') return '5';
  }

  throw new Error(
    'tourDisplayMode or recommendationDisplayMode values outside of their respective ranges',
  );
};

function TourContentRecommendationForm(props) {
  const {
    handleSave,
    handleToggleVisibility,
    handleDelete,
    localTourContent,
    setLocalTourContent,
    handleOnImageUpload,
    dispatch,
    tourContentArchives,
  } = props;

  const {
    id: tourContentId,
    attributes: {
      title,
      'display-mode': displayMode,
      text: personalOpinion,
      'affiliate-link': affiliateLink,
      'affiliate-title': affiliateTitle,
      'affiliate-text': affiliateText,
      'video-url': videoUrl,
      visible,
    },
  } = localTourContent;

  const [tourPresentationValue, recommendationPresentationValue] = DISPLAY_MODE_VALUES[
    displayMode || '0'
  ];

  const [tourDisplayMode, settourDisplayMode] = useState(tourPresentationValue || '0');
  const [recommendationDisplayMode, setrecommendationDisplayMode] = useState(
    recommendationPresentationValue || '0',
  );
  const [orderedImages, setOrderedImages] = useState([]);
  const [coverImage, setCoverImage] = useState(null);

  useEffect(() => {
    const newOrderedArchives = isEmpty(tourContentArchives)
      ? []
      : tourContentArchives.sort((a, b) => a.attributes.order - b.attributes.order);
    setOrderedImages(newOrderedArchives);
  }, [tourContentArchives]);

  useEffect(() => {
    const newCoverImage = orderedImages[0];
    if (!isEmpty(newCoverImage) && !_isEqual(newCoverImage, coverImage)) {
      setCoverImage(newCoverImage);
    }
  }, [orderedImages, coverImage]);

  const handleInput = (e) => {
    const {
      target: { value: newValue, name: attributeIdentifier },
    } = e;
    const immutableTourContent = _cloneDeep(localTourContent);

    if (
      attributeIdentifier !== 'tourDisplayMode'
      && attributeIdentifier !== 'recommendationDisplayMode'
    ) {
      immutableTourContent.attributes[attributeIdentifier] = newValue;
    } else {
      let tourDispMode = tourDisplayMode;
      let reccDispMode = recommendationDisplayMode;
      if (attributeIdentifier === 'tourDisplayMode') {
        tourDispMode = newValue;
        settourDisplayMode(newValue);
      }
      if (attributeIdentifier === 'recommendationDisplayMode') {
        reccDispMode = newValue;
        setrecommendationDisplayMode(newValue);
      }
      const newDisplayMode = getDisplayMode(tourDispMode, reccDispMode);
      immutableTourContent.attributes['display-mode'] = newDisplayMode;
    }
    setLocalTourContent(immutableTourContent);
  };

  const handlePersonalOpinion = (value) => {
    // the empty editor value is '<p><br></p>'
    // before setting the state, check if editor is empty and convert it to empty string if it is
    const richText = value === '<p><br></p>' ? '' : value;

    const immutableTourContent = _cloneDeep(localTourContent);
    immutableTourContent.attributes.text = richText;
    setLocalTourContent(immutableTourContent);
  };

  const uploaderParams = {
    imageableId: tourContentId,
    imageableType: 'tour-content',
    imageableProperty: 'archives',
  };

  const handleDeleteImage = image => reduxBeeMakeRequest(dispatch, 'deleteImage', { id: image.id }, {})
    .then(() => reduxBeeMakeRequest(dispatch, 'getTourContent', { id: localTourContent.id }, {}))
    .catch(() => {
      toastr.error('No se ha podido eliminar la imagen, por favor, intentalo de nuevo');
    })
    .finally(() => Promise.resolve());


  const handleOnDragEnd = ({ oldIndex, newIndex }) => {
    if (isEmpty(newIndex) || oldIndex === newIndex) return;

    const movedImage = orderedImages[oldIndex];
    reduxBeeMakeRequest(
      dispatch,
      'updateImage',
      { id: movedImage.id },
      { ...movedImage, attributes: { ...movedImage.attributes, order: newIndex + 1 } },
    )
      .then(() =>
        reduxBeeMakeRequest(dispatch, 'getTourContentArchives', { id: localTourContent.id }, {}),
      )
      .then((images) => {
        const newOrderedImages = images.sort((a, b) => a.attributes.order - b.attributes.order);
        setOrderedImages(newOrderedImages);
      });
  };

  return (
    <TourContentRecommendationFormTemplate
      serviceName={title}
      personalOpinion={personalOpinion}
      affiliateLink={affiliateLink}
      affiliateTitle={affiliateTitle}
      affiliateText={affiliateText}
      videoUrl={videoUrl}
      handleSave={handleSave}
      handleInput={handleInput}
      handlePersonalOpinion={handlePersonalOpinion}
      handleToggleVisibility={handleToggleVisibility}
      handleDelete={handleDelete}
      tourDisplayMode={tourDisplayMode}
      recommendationDisplayMode={recommendationDisplayMode}
      uploaderParams={uploaderParams}
      handleOnImageUpload={() => handleOnImageUpload(localTourContent)}
      handleDeleteImage={handleDeleteImage}
      orderedImages={orderedImages}
      coverImage={coverImage}
      visible={visible}
      handleOnDragEnd={handleOnDragEnd}
    />
  );
}

TourContentRecommendationForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  localTourContent: tourContentType.isRequired,
  tourContentArchives: PropTypes.arrayOf(TYPES.imageType),
  setLocalTourContent: PropTypes.func.isRequired,
  handleOnImageUpload: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

TourContentRecommendationForm.defaultProps = {
  tourContentArchives: [],
};

function mapStateToProps(state, ownProps) {
  const { localTourContent } = ownProps;
  const tourContent = isEmpty(localTourContent.id)
    ? localTourContent
    : state.bees.entities.tourContents[localTourContent.id];
  const archivesData = isEmpty(localTourContent.id) ? [] : tourContent.relationships.archives.data;
  const tourContentArchives = archivesData.map(
    archiveData => state.bees.entities[archiveData.type][archiveData.id],
  );

  return {
    tourContentArchives,
  };
}

export default connect(mapStateToProps)(TourContentRecommendationForm);
