/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import MainLayout from '../../layouts/main-layout';
import {
  DISCOVER_CURVE,
  EMAIL_ICON,
  ROUTE_BLUE,
  PLUS_ICON,
  DESTINY_BLUE,
  RECOMMENDATION_BLUE,
  history,
  USER_BLUE,
  COLECTIONS_BLUE,
} from '../../helpers';
import TABS from './tabs';
import { isEmpty } from '../../utils';
import { UPDATE_SERVICES_QP, UPDATE_PLACES_QP } from '../../constants';
import ENV from '../../config/environment';


const META_TAGS = {
  3: {
    title: '| Descubre las mejores Rutas',
    metaTitle: 'Topdesti: Descubre las mejores rutas de otros viajeros',
    metaDescription: 'Por medio de #etiquetas podrás buscar las rutas que mejor se '
      + 'adaptan a tu perfil de viajero y disfrutar conociendo nuevos itinerarios',
  },
  1: {
    title: '| Descubre nuevos Destinos',
    metaTitle: 'Topdesti: Descubre los mejores lugares que visitar',
    metaDescription: 'Busca en el mapa interactivo los destinos más populares creados por la comunidad de usuarios.',
  },
  2: {
    title: '| Descubre Servicios',
    metaTitle: 'Topdesti: Descubre las mejores rutas de otros viajeros',
    metaDescription: 'Por medio de #etiquetas podrás buscar las rutas que mejor se '
      + 'adaptan a tu perfil de viajero y disfrutar conociendo nuevos itinerarios',
  },
  4: {
    title: '| Descubre Viajeros',
    metaTitle: 'Topdesti: Descubre las mejores rutas de otros viajeros',
    metaDescription: 'Por medio de #etiquetas podrás buscar las rutas que mejor se '
      + 'adaptan a tu perfil de viajero y disfrutar conociendo nuevos itinerarios',
  },
  5: {
    title: '| Descubre Colecciones',
    metaTitle: 'Topdesti: Descubre las mejores rutas de otros viajeros',
    metaDescription: 'Por medio de #etiquetas podrás buscar las rutas que mejor se '
      + 'adaptan a tu perfil de viajero y disfrutar conociendo nuevos itinerarios',
  },
};

/* function renderSelectedTab(selectedTab) {
  switch (selectedTab) {
    default:
    case '3':
      return <TABS.ToursTab />;
    case '1':
      console.log("XD")
      if(this.state.onlyPreview){
        console.log("SI")
      }else{
        console.log("NO")
      }
      return <TABS.PoisDestinationTab />;
    case '2':
      return <TABS.PoisServiceTab />;
  }
} */
class DiscoverPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onlyPreview: false,
    };
    this.handleSwitchTab = this.handleSwitchTab.bind(this);
  }
  renderSelectedTab = (selectedTab) => {
    switch (selectedTab) {
      default:
      case '3':
        return <TABS.ToursTab />;
      case '1':
        if (this.state.onlyPreview == true) {
          return <TABS.PreviewPoisDestinationTab />;
        } else {
          return <TABS.PoisDestinationTab />;
        }
      case '2':
        return <TABS.PoisServiceTab />;
    }
  }

  componentDidMount() {
    const { searchType, urlQueryParams } = this.props;
    if (isEmpty(searchType)) {
      urlQueryParams.set('searchType', '3');
      history.replace({
        search: `?${urlQueryParams.toString()}`,
      });
    }
  }

  handleSwitchTab(newSearchType) {
    if (newSearchType == "onlyPreview") {
      this.setState({
        onlyPreview: true
      })
      newSearchType = "1";
    } else {
      this.setState({
        onlyPreview: false
      })
    }
    const {
      urlQueryParams, dispatch, placesTabQueryParams, servicesTabQueryParams,
    } = this.props;
    const currentSearchType = urlQueryParams.get('searchType');
    const currentQueryParamString = urlQueryParams.toString();

    switch (currentSearchType) {
      case '1':
        dispatch({ type: UPDATE_PLACES_QP, placesTabQueryParams: currentQueryParamString });
        break;
      case '2':
        dispatch({ type: UPDATE_SERVICES_QP, servicesTabQueryParams: currentQueryParamString });
        break;
      default:
        break;
    }

    let newQueryParams = urlQueryParams;
    newQueryParams.set('searchType', newSearchType);
    newQueryParams.delete('cat');
    switch (newSearchType) {
      case '1':
        newQueryParams = !isEmpty(placesTabQueryParams) ? placesTabQueryParams : newQueryParams.toString();
        break;
      case '2':
        newQueryParams = !isEmpty(servicesTabQueryParams) ? servicesTabQueryParams : newQueryParams.toString();
        break;
      default:
        break;
    }

    history.push({
      search: `?${newQueryParams}`,
    });
  }

  render() {
    const { handleSwitchTab } = this;
    const {
      isMobile, isSmallDevice, searchType, urlQueryParams, userPreview
    } = this.props;

    const ContextualActions = () => (
      <ul className="navbar-nav d-lg-none navbar-contextuals">
        <li className="nav-item nav-item--icon">
          <button
            type="button"
            className={`nav-link ${searchType === '3' || isEmpty(searchType) ? 'nav-link--active' : null}`}
            onClick={() => handleSwitchTab('3')}
          >
            {ROUTE_BLUE}
          </button>
        </li>
        <li className="nav-item nav-item--icon">
          <button
            type="button"
            className={`nav-link ${searchType === '1' ? 'nav-link--active' : null}`}
            onClick={() => handleSwitchTab('1')}
          >
            {DESTINY_BLUE}
          </button>
        </li>
        {/* <li className="nav-item nav-item--icon">
          <button
            type="button"
            className={`nav-link ${searchType === '2' ? 'nav-link--active' : null}`}
            onClick={() => handleSwitchTab('2')}
          >
            {RECOMMENDATION_BLUE}
          </button>
        </li>
        <li className="nav-item nav-item--icon">
          <button
            type="button"
            className={`nav-link ${searchType === '4' ? 'nav-link--active' : null}`}
            onClick={() => handleSwitchTab('4')}
          >
            {USER_BLUE}
          </button>
        </li>
        <li className="nav-item nav-item--icon">
          <button
            type="button"
            className={`nav-link ${searchType === '5' ? 'nav-link--active' : null}`}
            onClick={() => handleSwitchTab('5')}
          >
            {COLECTIONS_BLUE}
          </button>
        </li> */}
      </ul>
    );

    const { title = null, metaTitle = null, metaDescription = null } = META_TAGS[searchType] || {};

    return (
      <MainLayout ContextualActions={ContextualActions} alterBrand={isSmallDevice ? ' ' : null}>
        <Helmet>
          <title>
            {title}
          </title>
          <meta name="title" content={metaTitle} />
          <meta name="description" content={metaDescription} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="twitter:title" content={metaTitle} />
          <meta property="twitter:description" content={metaTitle} />
        </Helmet>
        <main className="discover-page">
          {isMobile ? null : (
            <div className="discover-page__header">
              <div className="row blue-bg">
                <div className="col-12 col-lg-8 offset-lg-2 discover-page__header-content">
                  <h1 className="discover-page__header-title mt-4">
                    Versión Beta
                    {/* <span className="discover-page__header-subtitle">versión Beta</span> */}
                  </h1>
                  <p className="discover-page__header-description">
                    En estos momentos estamos en una versión inicial con usuarios restringidos y completando funcionalidades para la creación de rutas y destinos. Si quieres que te avisemos con novedades o cuando liberemos la plataforma al público puedes suscribirte con tu email:
                  </p>
                  {/* Form Acumbamail */}
                  <form
                    action={ENV.newsletterEndpoint}
                    method="POST"
                    className="form-group row mt-4 discover-page__header-form"
                  >
                    <label htmlFor="email" className="d-block">
                      {EMAIL_ICON}
                      <input type="text" name="email_1" required className="form-control pl-5" placeholder="EMAIL" />
                    </label>
                    <input
                      type="hidden"
                      name="ok_redirect"
                      value={`${window.location.origin}?${urlQueryParams.toString()}&subscription=success`}
                    />
                    <button type="submit">{PLUS_ICON}</button>
                  </form>
                  {/* End Form Acumbamail */}
                </div>
              </div>

              <div className="row discover-page__tabs">
                <div className="col-12 mt-3 discover-page__tabs-buttons">
                  <div className="row justify-content-center">
                    <div className="col-auto">
                      <button
                        type="button"
                        className={`discover-page__tabs-selector ${
                          searchType === '3' ? 'discover-page__tabs-selector--active' : ''
                          }`}
                        onClick={() => handleSwitchTab('3')}
                      >
                        {ROUTE_BLUE}
                        <p>Rutas</p>
                      </button>
                    </div>
                    <div className="col-auto">
                      <button
                        type="button"
                        className={`discover-page__tabs-selector ${
                          searchType === '1' && !this.state.onlyPreview ? 'discover-page__tabs-selector--active' : ''
                          }`}
                        onClick={() => handleSwitchTab('1')}
                      >
                        {DESTINY_BLUE}
                        <p>Destinos</p>
                      </button>
                    </div>
                    {/* {userPreview && userPreview.id == 2 ? (
                      <div className="col-auto">
                        <button
                          type="button"
                          className={`discover-page__tabs-selector ${
                            searchType === '1' && this.state.onlyPreview ? 'discover-page__tabs-selector--active' : ''
                            }`}
                          onClick={() => handleSwitchTab('onlyPreview')}
                        >
                          {DESTINY_BLUE}
                          <p>Destinos previos</p>
                        </button>
                      </div>
                    ) : null} */}
                    <div className="col-auto">
                      <button
                        type="button"
                        className={`discover-page__tabs-selector ${
                          searchType === '2' ? 'discover-page__tabs-selector--active' : ''
                          }`}
                        // onClick={() => handleSwitchTab('2')}
                        disabled
                      >
                        {RECOMMENDATION_BLUE}
                        <p>Recomendaciones</p>
                      </button>
                    </div>
                    <div className="col-auto">
                      <button
                        type="button"
                        className={`discover-page__tabs-selector ${
                          searchType === '4' ? 'discover-page__tabs-selector--active' : ''
                          }`}
                        // onClick={() => handleSwitchTab('4')}
                        disabled
                      >
                        {USER_BLUE}
                        <p>Viajeros</p>
                      </button>
                    </div>
                    <div className="col-auto">
                      <button
                        type="button"
                        className={`discover-page__tabs-selector ${
                          searchType === '5' ? 'discover-page__tabs-selector--active' : ''
                          }`}
                        // onClick={() => handleSwitchTab('5')}
                        disabled
                      >
                        {COLECTIONS_BLUE}
                        <p>Colecciones</p>
                      </button>
                    </div>
                  </div>
                </div>
                {DISCOVER_CURVE}
              </div>
            </div>
          )}
          <div className="discover-page__body">{this.renderSelectedTab(searchType)}</div>
        </main>
      </MainLayout>
    );
  }
}

DiscoverPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isSmallDevice: PropTypes.bool,
  searchType: PropTypes.string,
  urlQueryParams: PropTypes.objectOf(PropTypes.object),
  placesTabQueryParams: PropTypes.string,
  servicesTabQueryParams: PropTypes.string,
};

DiscoverPage.defaultProps = {
  isMobile: null,
  isSmallDevice: null,
  urlQueryParams: null,
  searchType: null,
  placesTabQueryParams: null,
  servicesTabQueryParams: null,
};

function mapStateToProps(state) {
  const userPreview = state.auth.currentUser;
  const urlQueryParams = new URLSearchParams(state.router.location.search);
  const searchType = urlQueryParams.get('searchType');

  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    isSmallDevice: state.viewport.isXs || state.viewport.isSm,
    urlQueryParams,
    searchType,
    placesTabQueryParams: state.discover.placesTabQueryParams,
    servicesTabQueryParams: state.discover.servicesTabQueryParams,
    userPreview,
  };
}

export default connect(
  mapStateToProps,
  null,
)(DiscoverPage);
