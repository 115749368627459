import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import {
  CIRCLE_CROSS_ICON, EYE_ICON,
  HEART_ICON,
  TOUR_SIGNAL_ICON,
  STAR_ICON,
  SIMPLE_FLAG_ICON,
  MARKER_BASE_CIRCLE_ICON,
  SERVICES_ICON,
  SOLID_CAMERA_ICON,
} from '../helpers/svg-inline';
import * as ROUTES from '../constants/routes.constants';
import { lockBodyScrolling, restoreBodyScrolling, isEmpty } from '../utils';
import TYPES from '../constants/types';
import reduxBeeMakeRequest from '../utils/redux-bee/redux-bee-make-request';
import { dispatch } from '../reduxStore';
import { UPDATE_CURRENT_USER, defaultPartner, USER_DINAMIC_URL, SERVICE_CATEGORIES } from '../constants';
import DisabledPlaceEdition from './disabled-place-edition';
import { Carousel } from 'react-responsive-carousel';
import { getImageSrcSet } from '../utils';
import ResponsiveImg from './responsive-img';
import ModalOnlyUsers from './modal-log-out';
import ENV from '../config/environment';
import useGetResource from '../helpers/hooks/useGetResource';

/* function orderImages(images, firstImageId) {
  const orderedImages = images.reduce((acc, image) => {
    if (image.id === firstImageId) {
      acc.splice(0, 0, image);
    } else {
      acc.push(image);
    }

    return acc;
  }, []);
  return orderedImages;
} */
class ModalPreviewContentTour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLogOut: false,
      showModal: false,
      poi: {},
      images: {},
      carouselPosition: 0,
      likes: [],
      pois: [],
      personalImages: [],
      controlTour: [],
      authors: [],
      categories: [
        { code: 100, name: 'ALOJAMIENTO' },
        { code: 101, name: 'RESTAURACIÓN' },
        { code: 102, name: 'ACTIVIDADES' },
        { code: 103, name: 'ALQUILER DE COCHES' },
        { code: 104, name: 'COMPRAS' }
      ],
      logoCampaign: false,
    }
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleChangeCarousel = this.handleChangeCarousel.bind(this);
  }
  componentWillReceiveProps(newProps) {
    if (newProps) {
      this.setState({ showModal: newProps.modalPreviewContent })
      this.setState({ poi: newProps.poi.attributes })
      if (newProps.poi.relationships.images != undefined) {
        this.setState({ images: newProps.poi.relationships.images.data })
      } else {
        this.setState({ images: null })
      }
    }
  }
  componentDidUpdate() {
    if (this.state.showModal) {
      this._init()
      this.getCampaign();
    }
  }
  getCampaign = () => {
    if (this.props.poi) {
      fetch(`${ENV.host}/${ENV.namespace}/tours/getCampaign/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tourId: this.props.poi.id
        }),
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.campaign) {
            this.setState({
              logoCampaign: data.campaign.logo,
              textCampaign: data.campaign.smallText
            })
          }
        });
    }
  }
  _init() {
    if (!this.state.poiId) {
      if (this.state.controlTour.indexOf(this.state.poiId) == -1) {
        let updatedControlView = this.state.controlTour;
        updatedControlView.push(this.state.poiId)
        this.setState({ controlTour: updatedControlView })

        const request = Object.values(this.props.tour.tours).find(tour => tour.id == this.props.poi.id)

        fetch(`${ENV.host}/${ENV.namespace}/tours/preview`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            tourId: this.props.poi.id,
            tourContent: request.relationships['tour-contents'].data
          }),
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            var poiswithOrder = data.pois && data.pois.map(poi => {
              var ordernNumber = data.content.find(cont => cont.poi.id == poi.id)
              if (ordernNumber) {
                poi.day = ordernNumber.day
                poi.orden = ordernNumber.order
                return poi
              }
            })
            this._liked()
            this.setState({ pois: poiswithOrder, personalImages: data.images, likes: this.props.tour.likes ? Object.values(this.props.tour.likes) : [], poiId: this.props.poi.id })
          });

      }
    }
  }

  _liked = () => {
    if (this.props.tour.likes && this.props.auth.isAuthenticated) {
      let liked = Object.values(this.props.tour.likes).find(like => (
        like.attributes['user-id'] === Number(this.props.auth.currentUser.id) &&
        like.attributes['tour-id'] === Number(this.props.poi.id)
      ))
      this.setState({ liked: liked && liked.attributes['deleted-at'] === null })
    } else {
      this.setState({ liked: false })
    }
  }

  handleLike = () => {
    this.setState({ liked: !this.state.liked })
    fetch(`${ENV.host}/${ENV.namespace}/tours/like`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tourId: this.props.poi.id,
        userId: this.props.auth.currentUser.id,
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {

        const likes = this.state.likes.filter(like => this.props.poi.id == like.attributes['tour-id']).map(like => {
          if (like.attributes['user-id'] == data.like.userId) {
            like.attributes['deleted-at'] = data.like.deletedAt
          }
          return like
        })
        this.setState({ likes: likes })

      });
  }
  modalLogOutOpen = () => {
    this.setState({ showModalLogOut: true })
  }
  handlemodalLogOutClose = () => {
    this.setState({ showModalLogOut: false })
  }

  handleChangeCarousel(index) {
    this.setState({ carouselPosition: index });
  }

  handleCloseModal() {
    this.props.modalPreviewContentClose();
  }

  _selectCategory(cat) {
    let selectedCategory = this.state.categories.find(category => category.code === cat);
    return selectedCategory

  }
  render() {
    const notCachedResources = [];
    const { carouselPosition } = this.state;

    /* const orderedImages = this.props.imageArray ? Object.values(this.props.imageArray).filter(image => {
      if (image.attributes["imageable-type"] == "poi" && image.attributes["imageable-id"] == this.props.poi.id) {
        return image;
      }
    }) : []; */

    /*
   const { id: mainImageId = null } = this.state.poi ? this.state.images || {} : {};

   const orderedImages = notCachedResources.length !== 0
     ? this.state.images
     : orderImages(this.state.images, mainImageId); */
    /* if (this.state.images != undefined) {
      var orderedImages = this.state.images;
    } */
    // this.state.pois.sort((a, b) => b.likesNumber - a.likesNumber)

    const imagePois = this.state.pois ? this.state.pois.map(poi => {
      if (poi.poiType === 1 && poi.typePreview == 0) {
        if (poi.images.length === 1) {
          poi.images[0].poiName = poi.title
          poi.images[0].author = poi.owner
          poi.images[0].order = poi.orden
          poi.images[0].day = poi.day
          poi.images[0].likesPoi = poi.likesNumber
          return poi.images[0]
        } else {
          poi.images.sort((a, b) => {
            if (a.likes > b.likes) {
              return -1;
            }
            if (a.likes < b.likes) {
              return 1;
            }
            // a must be equal to b
            return 0;
          })
          poi.images[0].poiName = poi.title
          poi.images[0].author = poi.owner
          poi.images[0].order = poi.orden
          poi.images[0].day = poi.day
          poi.images[0].likesPoi = poi.likesNumber
          return poi.images[0]
        }
      }
    }) : [];
    var orderedImages = imagePois.filter(img => {
      if (img) return img
    })
    const services = this.state.pois ? this.state.pois.filter(poi => (poi.poiType === 2)) : [];

    /* const likes = this.state.likes.filter(like => {
      if ((like.attributes['tour-id'] == this.props.poi.id) && (like.attributes['deleted-at'] == null)) {
        return like
      }
    }); */
    const selectImages = this.state.personalImages ? this.state.personalImages.filter(content => (content.archives.length >= 1 && content.contentType != "8")).map(image => image.archives) : [];
    // selectImages.sort((a, b) => {
    //   if (a.likes > b.likes) {
    //     return -1;
    //   }
    //   if (a.likes < b.likes) {
    //     return 1;
    //   }
    //   // a must be equal to b
    //   return 0;
    // })

    var personalImages = [].concat.apply([], selectImages);

    if (this.state.poi.selection) {
      // orderedImages.sort((a, b) => { return b.likesPoi - a.likesPoi })

      orderedImages.sort((a, b) => {
        if (a.day > b.day) return 1;
        if (a.day < b.day) return -1;

        if (a.likesPoi > b.likesPoi) return -1;
        if (a.likesPoi < b.likesPoi) return 1;
      })
    } else {
      orderedImages.sort((a, b) => { return (a.day - b.day || a.order - b.order) })
    }

    // console.log(orderedImages)
    

    return (
      <div>
        <Modal
          className="modal-preview-content-tour"
          overlayClassName="modal-preview-content-tour__overlay"
          isOpen={this.state.showModal}
          contentLabel="modal log out"
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.handleCloseModal}
        >
          <div className="contentModal d-flex justify-content-center">
            <div className="nav d-flex justify-content-end">
              <button onClick={this.handleCloseModal} className="closeIcon p-0">
                {CIRCLE_CROSS_ICON}
              </button>
            </div>
            <div className="contentCenter d-flex justify-content-center">
              <div className={
                this.state.poi.township
                  ? 'row m-0 tour-content-poi-box__topleft'
                  : 'row m-0 tour-content-poi-box__topleft align-items-center'
              }>
                {/* {true && !isEmpty(orderPosition) ? (
                    // aqui mostramos el día en el que está el tour content de tipo POI
                    <span className="tour-content-poi-box__order">{orderPosition}</span>
                  ) : null} */}
                {this.props.auth.isAuthenticated ? (
                  <button onClick={this.handleLike} className="buttonLike">
                    <img
                      alt="like button"
                      src={
                        this.state.liked
                          ? '../assets/images/heart.svg'
                          : '../assets/images/heart-outline.svg'
                      }
                    />
                  </button>
                ) : (
                    <button onClick={this.modalLogOutOpen} className="buttonLike">
                      <img
                        alt="like button"
                        src={
                          this.state.liked
                            ? '../assets/images/heart.svg'
                            : '../assets/images/heart-outline.svg'
                        }
                      />
                    </button>
                  )}
                <ModalOnlyUsers
                  modalLogOut={this.state.showModalLogOut}
                  modalLogOutClose={this.handlemodalLogOutClose}
                />
                <div className="col">
                  {this.state.poi ? (
                    <>
                      <h3 className="tour-content-poi-box__title" title={this.state.poi.title}>
                        {this.state.poi.title}
                      </h3>
                      <h4 className="tour-content-poi-box__address">{this.state.poi.township}</h4>
                    </>
                  ) : null}
                </div>
              </div>
              {/* carrusel de imagenes */}
              <div>
                {orderedImages.length >= 1 ? (
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    emulateTouch
                    onChange={this.handleChangeCarousel}
                    selectedItem={carouselPosition}
                  >
                    {orderedImages.map((image, i) => {

                      if (image) {
                        return (
                          <div className='contentImgPoi' key={`carousel-img-${i}`}>
                            <img src={image.thumb ? image.thumb : image.url} />
                            <div className='d-flex align-items-end'>
                              <div className='d-flex align-items-center ml-4 mb-2'>
                                <h1 className='mr-2' >{i + 1}</h1>
                                <div>
                                  <h6>{image.poiName}</h6>
                                  <p>{image.author.name} {image.author.surname}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </Carousel>
                ) : null}
                <div className="col-auto mt-0 mb-2 mt-sm-0 d-flex justify-content-between align-items-end">
                  <ul className="modal-preview-content-tour__info">
                    <li className="modal-preview-content-tour__info-item">
                      {MARKER_BASE_CIRCLE_ICON}
                      {this.state.poi ? this.state.poi['destination-pois-counter'] : 0}
                    </li>
                    <li className="modal-preview-content-tour__info-item">
                      {SERVICES_ICON}
                      {services.length}
                    </li>
                    <li className="modal-preview-content-tour__info-item">
                      {SOLID_CAMERA_ICON}
                      {personalImages.length >= 1 ? personalImages.length : 0}
                    </li>
                  </ul>
                  <div className='daysTourModal d-flex align-items-center'>
                    {
                      this.state.poi && this.state.poi.selection ? '' : <span className="mr-3">{this.state.poi['tour-duration']} DÍAS</span>
                    }
                    <img
                      width='20'
                      className='mr-1'
                      alt="like button"
                      src='../assets/images/heart-outline.svg' />
                    {this.state.poi['likes-number']}
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                
                  {
                    this.props.poi.attributes['previous-search-text'] && <p className="modal-preview-content-tour__description" dangerouslySetInnerHTML={{ __html: this.props.poi.attributes['previous-search-text'] && `${this.props.poi.attributes['previous-search-text'].substr(0, 180)} ${this.props.poi.attributes['previous-search-text'].length >= 181 ? "..." : ""}` }} ></p>
                  }
                  <figure className="modal-preview-content-tour__partner d-flex flex-column align-items-center justify-content-center my-3">
                    <img
                      className="modal-preview-content-tour__partner-image"
                      src={this.state.logoCampaign ? this.state.logoCampaign : defaultPartner}
                      alt="Partner name"
                    />
                    <h5 className="tour-content-poi-box__partner-description mt-3">
                      {this.state.textCampaign && this.state.textCampaign }
                    </h5>
                  </figure>
                  {/* <ResponsiveImg
                    className="modal-preview-content-tour__partner"
                    wrapperClassName="modal-preview-content-tour__partner d-flex justify-content-center align-items-center"
                    srcSet={this.state.logoCampaign}
                    type="sponsor"
                    alt="Patrocinador"
                    loadingInverse
                  /> */}
                </div>
                <div className="d-flex justify-content-center division">
                  <Link to={this.props.redirectURL} className="btn btn-primary boton">
                    ver ruta
                  </Link>
                  {/* <button className="btn btn-primary boton">
                    + favoritos
                  </button> */}
                </div>
                <div className="d-flex division">
                  <div className="botLeft">
                    <h6>Recomendaciones ({services.length}):</h6>
                    <div className="services">
                      {services.map((service, i) => {
                        let category = this.state.categories.find(category => category.code == service.category)
                        return (
                          <figure key={i}>
                            {
                              service.images.length >= 1 && <img src={service.images[0].tinyThumb ? service.images[0].tinyThumb : service.images[0].url} />
                            }
                            <div>
                              <p className='ml-2 my-0 py-0'>{service.title}</p>
                              <span className='ml-2 my-0 py-0'>{category.name}</span>
                            </div>
                          </figure>

                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="d-flex division">
                  <div className="botLeft">
                    <h6>Imágenes personales ({personalImages.length}):</h6>
                    <div className="father">
                      {personalImages.map((image, i) => {
                        return <img key={i} src={image.smallThumb ? image.smallThumb : image.url} />
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contentBot d-flex justify-content-end">

            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const props = {};

export default ModalPreviewContentTour;

