import LoginPage from './login-page';
import ProfilePage from './profile-page';
import RegisterPage from './register-page';
import RecoveryPage from './recovery-page';
import HomePage from './home-page';
import PoiInfoPage from './poi-info-page';
import PoiImagesPage from './poi-images-page';

import TourEditionPage from './tour-edition-page';
import PoiPage from './poi-page';
import DiscoverPage from './discover-page';
import TourInfoPage from './tour-info-page/index';
import NotFoundPage from './not-found-page';
import ErrorPage from './error-page';
import PrivacyPage from './privacy-page';
import ConditionsPage from './conditions-page';
import CookiesPage from './cookies-page';
import FaqsPage from './faqs-page';
import Sponsorplace from './sponsor-place';


function modalStaging() {
  var password = 'q3578wer9876ty';

  var enterPass = prompt("Contraseña staging");
  if (!enterPass) {
    modalStaging()
  } else if (enterPass != password) {
    modalStaging()
  }
}

var url = window.location.host;
let subdomain = url.split('.')[0];

if (subdomain == 'staging') {
  modalStaging()
}

export {
  HomePage,
  LoginPage,
  ProfilePage,
  RegisterPage,
  RecoveryPage,
  PoiInfoPage,
  PoiImagesPage,
  TourEditionPage,
  TourInfoPage,
  PoiPage,
  DiscoverPage,
  NotFoundPage,
  ErrorPage,
  PrivacyPage,
  ConditionsPage,
  CookiesPage,
  FaqsPage,
  Sponsorplace,
};
