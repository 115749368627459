import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import Skeleton from '@yisheng90/react-loading';
import ShowMoreText from 'react-show-more-text';

import {
  CIRCLE_CROSS_ICON,
  STAR_ICON,
  EYE_ICON,
  HEART_ICON,
  TOUR_SIGNAL_ICON,
  SIMPLE_FLAG_ICON,
  MARKER_BASE_CIRCLE_ICON,
  ANGEL_ICON,
} from '../../../../../helpers/svg-inline';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import DropDown from './dropdown';
import getRelatedEntitiesFromRedux from '../../../../../utils/redux-bee/get-related-entities-from-redux';
import generateCacheResources from '../../../../../utils/redux-bee/generate-cache-resources';
import {
  defaultPartner, USER_DINAMIC_URL,
} from '../../../../../constants';
import { CATEGORY_OPTIONS } from '../../../../../constants/enums.constants';
import TYPES from '../../../../../constants/types';
import isEmpty from '../../../../../utils/is-empty';
import ResponsiveImg from '../../../../responsive-img';
import { getImageSrcSet, checkExternalUrl } from '../../../../../utils';
import { GOOGLE_MARKER } from '../../../../../utils/google-maps-utils';
import ModalImageFile from '../../../../modal-image-file';
import TourContentTextBox from './tour-content-text-box';
import TourContentVideoBox from './tour-content-video-box';
import TourContentMapBox from './tour-content-map-box';
import TourContentTimeBox from './tour-content-time-box';
import TourContentImagesBox from './tour-content-images-box';
import isEmptyObject from '../../../../../utils/isEmptyObject';
import ENV from '../../../../../config/environment';
import Modal from 'react-modal';
import SimpleMap from '../../../../simple-map';
import ModalOnlyUsers from '../../../../modal-log-out';
import ModalPreviewContent from '../../../../modal-preview-content';
import axios from 'axios';
function orderImages(images, firstImageId) {
  const orderedImages = images.reduce((acc, image) => {
    if (image.id === firstImageId) {
      acc.splice(0, 0, image);
    } else {
      acc.push(image);
    }

    return acc;
  }, []);
  return orderedImages;
}

class TourContentPoiBox extends React.Component {
  static getCategoryLabelFromValue(value) {
    const foundCagetory = CATEGORY_OPTIONS.filter(
      option => parseInt(option.value, 10) === parseInt(value, 10),
    );
    if (foundCagetory.length === 1) return foundCagetory[0].label;
    return '';
  }

  constructor(props) {
    super(props);
    //Estado de los modal por defecto
    this.state = {
      showModalMap: false,
      showModalInfo: false,
      showModalTours: false,
      showModalImagesPois: false,
      showModalLogOut: false,
      showModalPartner: false,
      carouselPosition: 0,
      carouselPositionCampaign: 0,
      likes: [],
      controlView: [],
      showModalPreviewContent: false,
      poiCampaign: null,
      showModalCampaign: false,
      campaignImages: null,
      videoLink: null,
      tours: false,
      userLatitude: null,
      userLongitude: null
    };
    //Bind de abrir y cerrar de cada modal
    this.handleOpenModalMap = this.handleOpenModalMap.bind(this);
    this.handleCloseModalMap = this.handleCloseModalMap.bind(this);
    this.handleOpenModalInfo = this.handleOpenModalInfo.bind(this);
    this.handleCloseModalInfo = this.handleCloseModalInfo.bind(this);
    this.handleOpenModalTours = this.handleOpenModalTours.bind(this);
    this.handleCloseModalTours = this.handleCloseModalTours.bind(this);
    this.handleOpenModalImagesPois = this.handleOpenModalImagesPois.bind(this);
    this.handleCloseModalImagesPois = this.handleCloseModalImagesPois.bind(this);
    this.openModalPartner = this.openModalPartner.bind(this);
    this.closeModalPartner = this.closeModalPartner.bind(this);
    this._requestedMissingResource = false;
    this._isSwippingCarousel = false;

    this.handleChangeCarousel = this.handleChangeCarousel.bind(this);
    this._mapId = `map-box-${Math.floor(Math.random() * (10000 - 1)) + 1}`;
    this.initializeMapBounds = this.initializeMapBounds.bind(this);
    this.handleMapMount = this.handleMapMount.bind(this);
  }
  modalPreviewContentOpen = () => {
    this.setState({ showModalPreviewContent: true })
  }
  modalPreviewContentClose = () => {
    this.setState({ showModalPreviewContent: false })
  }

  // Funciones de abrir y cerrar de cada modal
  modalLogOutOpen = () => {
    this.setState({ showModalLogOut: true })
  }
  handlemodalLogOutClose = () => {
    this.setState({ showModalLogOut: false })
  }
  handleOpenModalMap() {
    this.setState({ showModalInfo: false, showModalTours: false, showModalMap: true, showModalImagesPois: false });
  }
  handleCloseModalMap() {
    this.setState({ showModalMap: false });
  }
  handleOpenModalInfo() {
    this.setState({ showModalMap: false, showModalTours: false, showModalInfo: true, showModalImagesPois: false });
  }
  handleCloseModalInfo() {
    this.setState({ showModalInfo: false });
  }
  handleOpenModalImagesPois() {
    this.setState({ showModalMap: false, showModalTours: false, showModalInfo: false, showModalImagesPois: true });
  }
  handleCloseModalImagesPois() {
    this.setState({ showModalImagesPois: false });
  }
  handleOpenModalTours(poi) {
    fetch(`${ENV.host}/${ENV.namespace}/pois/sponsorPlace/${parseInt(poi)}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          /* poi: data.destino,
          avatar: data.avatar.smallThumb, */
          tours: data.tourContent
        });
      });
    this.setState({ showModalMap: false, showModalInfo: false, showModalTours: true, showModalImagesPois: false });
  }
  handleCloseModalTours() {
    this.setState({ showModalTours: false });
  }
  openModalPartner = () => {
    this.setState({ showModalPartner: true })
  }
  closeModalPartner = () => {
    this.setState({ showModalPartner: false })
  }
  modalCloseAndAlert = () => {
    this.handleCloseModalMap();
    this.handleCloseModalInfo();
    this.modalLogOutOpen();
  }
  modalCampaignOpen = () => {
    this.setState({ showModalCampaign: true })
  }
  modalCampaignClose = () => {
    this.setState({ showModalCampaign: false })
  }

  componentDidMount() {
    /* const FB = window.FB;
    FB.XFBML.parse(); */

    const { notCachedResources } = this.props;
    generateCacheResources(notCachedResources);
    let poi = this.props.tourContent.relationships.poi.data.id
    this._getLikesLoad()
    if (navigator.geolocation) { //check if geolocation is available
      navigator.geolocation.getCurrentPosition((position) => {
        let userLatitude;
        let userLongitude;
        userLatitude = position.coords.latitude;
        userLongitude = position.coords.longitude;
        this.setState({
          userLatitude: userLatitude,
          userLongitude: userLongitude
        })
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { notCachedResources } = this.props;
    if (notCachedResources.length && !this._requestedMissingResource) {
      this._requestedMissingResource = true;
      generateCacheResources(notCachedResources).then(() => {
        this._requestedMissingResource = false;
      });
    }

    // if (prevProps.poi != this.props.poi) {
    //   this.setState({
    //     description: this.props.poi.attributes.description.slice(0, 150)
    //   })
    // }
    /* if (this.props.poi) {
      this._getLikes()
    } */
  }

  _getLikesLoad() {
    let poi = this.props.tourContent.relationships.poi.data.id
    /*         let updatedControlView = this.state.controlView
            updatedControlView.push(this.state.poiId)
            this.setState({ controlView: updatedControlView }) */
    fetch(`${ENV.host}/${ENV.namespace}/pois/likespoi/${poi}/${true}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          likes: data.likes,
          poiId: poi,
          views: data.views,
          poiCampaign: data.campaign,
          campaignImages: data.campaignImages,
        });
        this.loadVideo()
        this._liked()
      });
    /* } */
  }

  _getLikes() {
    let poi = this.props.tourContent.relationships.poi.data.id
    fetch(`${ENV.host}/${ENV.namespace}/pois/likespoi/${poi}/${true}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          likes: data.likes,
          poiId: poi,
          // views: data.views,
          poiCampaign: data.campaign,
          campaignImages: data.campaignImages,
        });
        this.loadVideo()
        this._liked()
      });
    /* } */
  }

  handleLike = () => {
    this.setState({ liked: !this.state.liked });
    axios.post(`${ENV.host}/${ENV.namespace}/pois/likepoi`, {
      token: this.props.currentUser.token,
      poiId: this.props.poi.id
    })
      .then((res) => {
        this._getLikes(this.props.poi.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _liked = () => {
    if (this.props.currentUser.isAuthenticated && this.props.currentUser.currentUser) {
      let liked = Object.values(this.state.likes).find(
        like =>
          like.userId === Number(this.props.currentUser.currentUser.id)
      );
      this.setState({ liked: liked });
    } else {
      this.setState({ liked: false });
    }
  };

  handleChangeCarousel(index) {
    this.setState({ carouselPosition: index });
  }
  handleChangeCarouselCampaign(index) {
    this.setState({ carouselPositionCampaign: index });
  }
  handleMapMount(map) {
    this._mapInstance = map;
  }
  initializeMapBounds() {
    const originLabel = "";
    const showItinerary = "";
    const { _mapInstance } = this;
    const {
      poi, zoom,
    } = this.props;
    const maxZoom = 17;
    if (!isEmpty(_mapInstance)) {
      const { _mapMarkers: mapMarkers } = this;
      const bounds = new window.google.maps.LatLngBounds();
      const { lat, long: lng } = poi.attributes;
      const label = "";
      const mapMarker = new window.google.maps.Marker({
        position: { lat, lng },
        map: _mapInstance,
        icon: GOOGLE_MARKER(label),
        clickable: false,
      });
      bounds.extend(mapMarker.getPosition());
      this._mapMarkers = mapMarkers;
      _mapInstance.fitBounds(bounds, 5);
      if (!isEmpty(zoom)) {
        _mapInstance.setZoom(zoom);
      }
      if (!isEmpty(maxZoom) && _mapInstance.getZoom() > maxZoom) {
        _mapInstance.setZoom(maxZoom);
      }

      /* if (showItinerary) {
        getDirections(_mapInstance, mapMarkers);
      } */
    }
  }

  loadVideo = () => {
    if (this.state.poiCampaign && this.state.poiCampaign.videoLink) {
      const videoLink = this.state.poiCampaign.videoLink;
      if (videoLink.indexOf("youtube") != -1) {
        this.setState({
          videoLink: videoLink.replace("watch?v=", "embed/")
        })
      } else if (videoLink.indexOf("vimeo") != -1) {
        this.setState({
          videoLink: videoLink.replace("https://vimeo.com/", "https://player.vimeo.com/video/")
        })
      }
    }
  }
  render() {
    const {
      isMobile,
      poi,
      poiImages,
      tourContent,
      poiOwner,
      tourContentChildren,
      orderPosition,
      notCachedResources,
      selectedImage,
      handleImageModal,
    } = this.props;
    const { title, description, linkCampaign, videoLink } = this.state.poiCampaign || {};
    const { carouselPosition } = this.state;
    let ownerName;
    let ownerSurname;
    let ownerNickname;
    let ownerTopTraveler;
    let poiCategory;
    let poiDescription;
    const shareLinkRRSS = `${window.location.origin}/place/${this.props.poi && this.props.poi.attributes['url-title']}`;
    if (poiOwner) {
      ({
        attributes: {
          name: ownerName,
          surname: ownerSurname,
          nickname: ownerNickname,
          toptraveler: ownerTopTraveler,
        },
      } = poiOwner);
    }
    if (poi) {
      poiCategory = TourContentPoiBox.getCategoryLabelFromValue(poi.attributes.category);
      poiDescription = poi.attributes.description;
    }

    const { text: tourContentDescription } = tourContent.attributes || {
      text: null,
      order: null,
    };

    const { id: mainImageId = null } = poi ? poi.relationships['main-image'].data || {} : {};

    const orderedImages = isEmpty(mainImageId) || notCachedResources.length !== 0
      ? poiImages
      : orderImages(poiImages, mainImageId);
    const orderedTourContentChildren = isEmpty(tourContentChildren)
      ? []
      : tourContentChildren
        .filter(tourContentChild => !isEmptyObject(tourContentChild))
        .sort((a, b) => a.attributes.order - b.attributes.order);


    orderedImages && orderedImages.sort((a, b) => {
      if (a.attributes && b.attributes) {
        if (a.attributes.likes > b.attributes.likes) {
          return -1;
        }
        if (a.attributes.likes < b.attributes.likes) {
          return 1;
        }
        // a must be equal to b
        return 0;
      }
    })

    return (
      <>
        {poi == null ? (

          <div className="container tour-content-poi-box ">
            <div className="row target d-flex justify-content-center">
              <div className="contentTourLoading column">
                <Skeleton className="itemTour Tittle" />
                <Skeleton className="itemTour Description" />
              </div>
              <div className="contentSponsorLoading d-flex align-items-center flex-column ">
                <Skeleton className="itemSponsor itemSponsorTittle" />
                <Skeleton className="itemSponsor itemSponsorImagen" />
                <Skeleton className="itemSponsor itemSponsorDesc" />
                <Skeleton className="itemSponsor itemSponsorDesc" />
                <Skeleton className="itemSponsor itemSponsorDesc" />
              </div>
            </div>
          </div>

        ) : (

            <>
              {isEmpty(selectedImage) ? null : (
                <ModalImageFile
                  isOpen={!isEmpty(selectedImage)}
                  closeModal={() => handleImageModal(null)}
                  poi={poi}
                  image={selectedImage}
                  poiCampaign={this.state.poiCampaign}
                  images={orderedImages}
                />
              )}
              <article className="container tour-content-poi-box">
                {/*Parte superior de la foto*/}
                <div className="row d-flex justify-content-between px-3 sup">
                  {/*titulo y me gusta de la ficha destino */}
                  {poi && poi.attributes ? (
                    <>
                      <div className={
                        poi.attributes.township
                          ? 'row m-0 tour-content-poi-box__topleft'
                          : 'row m-0 tour-content-poi-box__topleft align-items-center'
                      }>
                        {true && !isEmpty(orderPosition) ? (
                          // aqui mostramos el día en el que está el tour content de tipo POI
                          <span className="tour-content-poi-box__order">{orderPosition}</span>
                        ) : null}
                        {this.props.currentUser.isAuthenticated ? (
                          <button onClick={this.handleLike} className="buttonLike">
                            <img
                              alt="like button"
                              src={
                                this.state.liked
                                  ? '../assets/images/heart.svg'
                                  : '../assets/images/heart-outline.svg'
                              }
                            />
                          </button>
                        ) : (
                            <button onClick={this.modalLogOutOpen} className="buttonLike">
                              <img
                                alt="like button"
                                src={
                                  this.state.liked
                                    ? '../assets/images/heart.svg'
                                    : '../assets/images/heart-outline.svg'
                                }
                              />
                            </button>
                          )}
                        <ModalOnlyUsers
                          modalLogOut={this.state.showModalLogOut}
                          modalLogOutClose={this.handlemodalLogOutClose}

                        />
                        <div className="col">
                          {poi && poi.attributes ? (
                            <>
                              <h3 className="tour-content-poi-box__title" title={poi.attributes.title}>
                                {poi.attributes.title}
                              </h3>
                              {poi.attributes["type-preview"] == 0 ? (
                                <h4 className="tour-content-poi-box__address">{poi.attributes.township}</h4>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      </div>
                      {/*Categoria y Menu */}
                      <div className="col tour-content-poi-box__topright">
                        {/* !isMobile && */ poiCategory ? (
                          <div className="col-auto px-1">
                            <span className="tour-content-poi-box__category">{poiCategory}</span>
                          </div>
                        ) : null}
                        <DropDown align="right" toggle="" toggleClass={'buttonMenu'}>
                          {/* <button type="button" className="dropdown-item" onClick={this.createNewTour}>
                  Denunciar contenido
                    </button> */}
                          <div className="dropdown-item">
                            <p>Compartir</p>
                            <div className=" d-flex justify-content-center">
                              <li className="tour-header__rrss-item">
                                <FacebookShareButton className="tour-header__rrss-link" url={shareLinkRRSS}>
                                  <FacebookIcon size={50} round />
                                </FacebookShareButton>
                              </li>
                              <li className="tour-header__rrss-item">
                                <TwitterShareButton className="tour-header__rrss-link" url={shareLinkRRSS}>
                                  <TwitterIcon size={50} round />
                                </TwitterShareButton>
                              </li>
                              <li className="tour-header__rrss-item">
                                <WhatsappShareButton className="tour-header__rrss-link" url={shareLinkRRSS}>
                                  <WhatsappIcon size={50} round />
                                </WhatsappShareButton>
                              </li>
                            </div>
                          </div>
                          {/* <button
                  type="button"
                  className="dropdown-item"
                  // onClick={() => this.handleDuplicatedModal(2)}
                  onClick={() => this.createNewPoi(2)}
                >
                  Añadir a mis favoritos
                    </button> */}
                          {/* <button
                  type="button"
                  className="dropdown-item"
                  // onClick={() => this.handleDuplicatedModal(2)}
                  onClick={() => this.createNewPoi(2)}
                >
                  Añadir a coleccción
                    </button> */}
                          {/* <button
                  type="button"
                  className="dropdown-item"
                  // onClick={() => this.handleDuplicatedModal(2)}
                  onClick={() => this.createNewPoi(2)}
                >
                  Añadir imágenes
                    </button> */}
                        </DropDown>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="row">
                  <div className="col-12 px-0 contentAuthor mt-1">
                    {/* {!isMobile && !isEmpty(orderPosition) ? (
                // aqui mostramos el día en el que está el tour content de tipo POI
                <span className="tour-content-poi-box__order">{orderPosition}</span>
              ) : null} */}
                    {orderedImages && poi.attributes["type-preview"] == 0 ? (
                      <Carousel
                        showThumbs={false}
                        showStatus={false}
                        emulateTouch
                        onChange={this.handleChangeCarousel}
                        selectedItem={carouselPosition}
                      >
                        {orderedImages.map((image, i) => {
                          const srcSetArray = getImageSrcSet(image);
                          return (
                            <div key={`carousel-img-${image && image.id ? image.id : i}`}>
                              <ResponsiveImg className="tour-content-poi-box__image" srcSet={srcSetArray}>
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (!this._isSwippingCarousel && !isEmpty(handleImageModal)) {
                                      handleImageModal(image.id);
                                    }
                                  }}
                                  onMouseDown={() => {
                                    this._isSwippingCarousel = false;
                                  }}
                                  onMouseMove={() => {
                                    this._isSwippingCarousel = true;
                                  }}
                                  className="responsive-img__invisible-btn"
                                />
                              </ResponsiveImg>
                              {/* <figure
                        className="tour-content-poi-box__image"
                        style={{ backgroundImage: `url('${image.attributes.url}')` }}
                      > */}
                              {/* <div className="tour-content-poi-box__image-author">
                              <figure
                                className="tour-content-poi-box__image-author-avatar"
                                style={{ backgroundImage: `url('${slide.author.avatarUrl}')` }}
                              >
                                <span className="sr-only">owner name</span>
                              </figure>
                              <h4 className="tour-content-poi-box__image-author-name">
                                {`
                              ${slide.author.name} ${slide.author.lastName}
                            `}
                              </h4>
                            </div> */}
                              {/* </figure> */}
                            </div>
                          );
                        })}
                      </Carousel>
                    ) : null}
                    {/* <div className="row authorPhoto align-items-center">
                <button onClick={this.handleLike} className="buttonAuthor">
                  <img
                    alt='Author button'
                    src={'../assets/images/user.svg'}
                  />
                </button>
                <h6 className="m-0 mx-2">JOSE CORRAL</h6>
                <button onClick={this.handleLike} className="buttonLike">
                  <img
                    alt='like button'
                    src={'../assets/images/heart-outline.svg'}
                  />
                </button>
              </div> */}
                  </div>
                </div>
                {/* Parte inferior de la foto */}
                <div>
                  {/* Modal Mapa */}
                  <Modal className="ModalMap"
                    overlayClassName="ContentModal"
                    isOpen={this.state.showModalMap}
                    contentLabel="ContentModal"
                    onRequestClose={this.handleCloseModalMap}
                    shouldCloseOnOverlayClick={true}
                  >
                    <div className="row m-0 d-flex head-map-modal justify-content-between">
                      <div className="contentOneTwo">
                        <div className="d-flex oneH">
                          {this.props.currentUser.isAuthenticated ? (
                            <button onClick={this.handleLike} className="buttonLike">
                              <img
                                alt="like button"
                                src={
                                  this.state.liked
                                    ? '../assets/images/heart.svg'
                                    : '../assets/images/heart-outline.svg'
                                }
                              />
                            </button>
                          ) : (
                              <button onClick={this.modalCloseAndAlert} className="buttonLike">
                                <img
                                  alt="like button"
                                  src={
                                    this.state.liked
                                      ? '../assets/images/heart.svg'
                                      : '../assets/images/heart-outline.svg'
                                  }
                                />
                              </button>
                            )}

                          <div className="col">
                            {poi && poi.attributes ? (
                              <>
                                <h3 className="tour-content-poi-box__title" title={poi.attributes.title}>
                                  {poi.attributes.title}
                                </h3>
                                <h4 className="tour-content-poi-box__address">{poi.attributes.township}</h4>
                              </>
                            ) : null}
                          </div>
                        </div>

                        <div className="twoH pb-1">
                          <button onClick={this.handleOpenModalMap} className="actionIcon buttonActive">
                            <img
                              alt='pin button'
                              src={'../assets/images/pin.svg'}
                            />
                          </button>

                          <button onClick={this.handleOpenModalInfo} className="actionIcon">
                            <img alt="information button" src={'../assets/images/information.svg'} />
                          </button>
                          <button onClick={() => this.handleOpenModalTours(poi.id)} className="actionIcon">
                            <img
                              className="w-100"
                              alt='reward button'
                              src={'../assets/images/ruta_negro.svg'}
                            />
                          </button>
                          <button className="actionIcon" onClick={() => this.handleOpenModalImagesPois(poi.id)}>
                            <img
                              alt='camera button'
                              src={'../assets/images/camera.svg'}
                            />
                          </button>
                          {/* <button onClick={()=>this.handleOpenModalTours(poi.id)} className="actionIcon">
                      <img alt="reward button" src={'../assets/images/reward.svg'} />
                    </button> */}
                          {/* <button className="actionIcon">
                      <img alt="comment button" src={'../assets/images/comment2.svg'} />
                    </button> */}

                        </div>
                      </div>
                      <div className="threeH">
                        <button onClick={this.handleCloseModalMap} className="closeIcon p-0">
                          {CIRCLE_CROSS_ICON}
                        </button>
                      </div>
                    </div>
                    <div className="row modal-mapa-imagenes m-0">
                      <div className="MapaImage">
                        <SimpleMap
                          key={this._mapId}
                          mapElementId={this._mapId}
                          onMapLoad={this.handleMapMount}
                          onIdle={this.initializeMapBounds}
                        />
                        {/* <div className="contentPartner">
                    <img
                      className="partnerImageMobile"
                      alt="partner button"
                      src={'../assets/images/test-partner.svg'}
                    />
                  </div> */}
                      </div>
                      <div className={this.props.tourIsOfficial ? "d-none" : "mapRight pdg0 col"}>
                        <div className="mapRight__top">
                          <img className="partnerImage"
                            alt='partner button'
                            src={this.state.poiCampaign ? this.state.poiCampaign.logo : '../assets/images/test-partner.svg'} />
                          <p>{this.state.poiCampaign ? this.state.poiCampaign.smallText : ""}</p>
                        </div>
                        {this.state.poiCampaign ? (
                          <div className="mapRight__bot">
                            {this.state.poiCampaign.banner ? (
                              <img
                                className="collage"
                                alt="collage button"
                                src={this.state.poiCampaign.banner}
                              />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Modal>
                  {/* Modal Informacion */}
                  <Modal className="ModalMap"
                    overlayClassName="ContentModal"
                    isOpen={this.state.showModalInfo}
                    contentLabel="ModalInfo"
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.handleCloseModalInfo}
                  >
                    <div className="row m-0 d-flex head-map-modal justify-content-between">
                      <div className="contentOneTwo">
                        <div className="d-flex oneH" >

                          {
                            this.props.currentUser.isAuthenticated ? <button onClick={this.handleLike} className="buttonLike">
                              <img
                                alt='like button'
                                src={
                                  this.state.liked ? '../assets/images/heart.svg' : '../assets/images/heart-outline.svg'
                                }
                              />

                            </button> : <button onClick={this.modalCloseAndAlert} className="buttonLike">
                                <img
                                  alt='like button'
                                  src={
                                    this.state.liked ? '../assets/images/heart.svg' : '../assets/images/heart-outline.svg'
                                  }
                                />
                              </button>
                          }



                          <div className="col">
                            {poi && poi.attributes ? (
                              <>
                                <h3 className="tour-content-poi-box__title" title={poi.attributes.title}>
                                  {poi.attributes.title}
                                </h3>
                                <h4 className="tour-content-poi-box__address">{poi.attributes.township}</h4>
                              </>
                            ) : null}
                          </div>
                        </div>

                        <div className="twoH pb-1">
                          <button onClick={this.handleOpenModalMap} className="actionIcon">
                            <img
                              alt='pin button'
                              src={'../assets/images/pin.svg'}
                            />
                          </button>

                          <button onClick={this.handleOpenModalInfo} className="actionIcon buttonActive">
                            <img
                              alt='information button'
                              src={'../assets/images/information.svg'}
                            />
                          </button>
                          <button onClick={() => this.handleOpenModalTours(poi.id)} className="actionIcon">
                            <img
                              className="w-100"
                              alt='reward button'
                              src={'../assets/images/ruta_negro.svg'}
                            />
                          </button>
                          <button className="actionIcon" onClick={() => this.handleOpenModalImagesPois(poi.id)}>
                            <img
                              alt='camera button'
                              src={'../assets/images/camera.svg'}
                            />
                          </button>
                          {/* <button onClick={()=>this.handleOpenModalTours(poi.id)} className="actionIcon">
                      <img
                        alt='reward button'
                        src={'../assets/images/reward.svg'}
                      />
                    </button> */}
                          {/*  <button className="actionIcon">
                      <img
                        alt='comment button'
                        src={'../assets/images/comment2.svg'}
                      />
                    </button> */}


                        </div>
                      </div>

                      <div className="threeH">
                        <button onClick={this.handleCloseModalInfo} className="closeIcon p-0">
                          {CIRCLE_CROSS_ICON}
                        </button>
                      </div >


                    </div>
                    <div className="row modal-mapa-imagenes m-0">
                      <div className="MapaImage infoBoxModal">
                        <div className="col d-flex justify-content-center align-items-start">
                          {poi && poi.attributes ? (
                            <>
                              <div className="info">
                                <div className="info-modal row d-flex align-items-end ">
                                  <h4>Horario: </h4>
                                  <p>{poi.attributes.schedule}</p>
                                </div>
                                <div className=" info-modal row align-items-end">
                                  <h4>Web Oficial: </h4>
                                  <p>
                                    <a href={"http://" + poi.attributes.website} target="_blank">
                                      {poi.attributes.website}
                                    </a>

                                  </p>
                                </div>
                                <div className=" info-modal row align-items-end ">
                                  <h4>Dirección: </h4>
                                  <p>{poi.attributes.fullAddress}</p>
                                </div>
                                <div className=" info-modal row align-items-end ">
                                  <h4>Teléfono: </h4>
                                  <p>{poi.attributes.phone}</p>
                                </div>
                                <div className=" info-modal row align-items-end ">
                                  <h4>Tiempo estimado de visita:  </h4>
                                  <p>{poi.attributes.duration}</p>
                                </div>
                                <div className=" info-modal row align-items-end ">
                                  <h4>Precio :</h4>
                                  <p>{poi.attributes.price}</p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                        {/* <div className="contentPartner">
                    <img className="partnerImageMobile"
                      alt='partner button'
                      src={'../assets/images/test-partner.svg'} />
                  </div> */}
                      </div>
                      <div className={this.props.tourIsOfficial ? "d-none" : "mapRight pdg0 col"}>
                        <div className="mapRight__top">
                          <img className="partnerImage"
                            alt='partner button'
                            src={this.state.poiCampaign ? this.state.poiCampaign.logo : '../assets/images/test-partner.svg'} />
                          <p>{this.state.poiCampaign ? this.state.poiCampaign.smallText : ""}</p>
                        </div>
                        {this.state.poiCampaign ? (
                          <div className="mapRight__bot">
                            {this.state.poiCampaign.banner ? (
                              <img
                                className="collage"
                                alt="collage button"
                                src={this.state.poiCampaign.banner}
                              />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Modal>
                  {/* modal de rutas a las que pertenece el destino */}
                  <Modal className="ModalMap"
                    overlayClassName="ContentModal"
                    isOpen={this.state.showModalTours}
                    contentLabel="ModalTours"
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.handleCloseModalTours}
                  >
                    <div className="row m-0 d-flex head-map-modal justify-content-between">
                      <div className="contentOneTwo">
                        <div className="d-flex oneH" >

                          {
                            this.props.currentUser.isAuthenticated ? <button onClick={this.handleLike} className="buttonLike">
                              <img
                                alt='like button'
                                src={
                                  this.state.liked ? '../assets/images/heart.svg' : '../assets/images/heart-outline.svg'
                                }
                              />

                            </button> : <button onClick={this.modalCloseAndAlert} className="buttonLike">
                                <img
                                  alt='like button'
                                  src={
                                    this.state.liked ? '../assets/images/heart.svg' : '../assets/images/heart-outline.svg'
                                  }
                                />
                              </button>
                          }



                          <div className="col">
                            {poi && poi.attributes ? (
                              <>
                                <h3 className="tour-content-poi-box__title" title={poi.attributes.title}>
                                  {poi.attributes.title}
                                </h3>
                                <h4 className="tour-content-poi-box__address">{poi.attributes.township}</h4>
                              </>
                            ) : null}
                          </div>
                        </div>

                        <div className="twoH pb-1">
                          <button onClick={this.handleOpenModalMap} className="actionIcon">
                            <img
                              alt='pin button'
                              src={'../assets/images/pin.svg'}
                            />
                          </button>

                          <button onClick={this.handleOpenModalInfo} className="actionIcon">
                            <img
                              alt='information button'
                              src={'../assets/images/information.svg'}
                            />
                          </button>
                          <button onClick={() => this.handleOpenModalTours(poi.id)} className="actionIcon buttonActive">
                            <img
                              className="w-100"
                              alt='reward button'
                              src={'../assets/images/ruta_negro.svg'}
                            />
                          </button>
                          <button className="actionIcon" onClick={() => this.handleOpenModalImagesPois(poi.id)}>
                            <img
                              alt='camera button'
                              src={'../assets/images/camera.svg'}
                            />
                          </button>
                          {/* <button onClick={()=>this.handleOpenModalTours(poi.id)} className="actionIcon buttonActive">
                      <img
                        alt='reward button'
                        src={'../assets/images/reward.svg'}
                      />
                    </button> */}
                          {/*  <button className="actionIcon">
                      <img
                        alt='comment button'
                        src={'../assets/images/comment2.svg'}
                      />
                    </button> */}

                        </div>
                      </div>

                      <div className="threeH">
                        <button onClick={this.handleCloseModalTours} className="closeIcon p-0">
                          {CIRCLE_CROSS_ICON}
                        </button>
                      </div >


                    </div>
                    <div className={isMobile ? "row modal-mapa-imagenes m-0 h-100" : "row modal-mapa-imagenes m-0"}>
                      <div className="MapaImage widthAternative">
                        <div className="col d-flex justify-content-center">
                          {poi && this.state.tours ? (
                            <>
                              <div className="infoTours">
                                <h4 className='ml-4 mb-5'>Rutas donde se han añadido ({this.state.tours.length}):</h4>
                                <div className="tour">
                                  {this.state.tours.map((tour, i) => {
                                    return (
                                      <div className="d-flex division2 align-items-between w-100 tour">
                                        <div className="d-flex align-items-center w-100 ">
                                          <div className='d-block d-sm-block d-md-none d-lg-none d-xl-none'>
                                            <div className="d-flex justify-content-between">
                                              <h5 className='mb-2'>{tour.title}</h5>
                                              <Link to={`/tour/${tour.urlTitle}`} className="mr-2">
                                                <img
                                                  src="../../../assets/images/external-link-symbol_azul.svg"
                                                  width="22"
                                                  alt=""
                                                  className=""
                                                />
                                              </Link>
                                            </div>
                                            <div className="d-flex">
                                              <img className='col-5 portada mr-3 p-0' src={tour.desktopImage && tour.desktopImage.tinyThumb ? tour.desktopImage.tinyThumb : '../../../assets/images/empty_photo.svg'} alt="" />
                                              <p> {tour.previousSearchText && tour.previousSearchText.length <= 100 ? tour.previousSearchText : tour.previousSearchText ? tour.previousSearchText.substr(0, 100) + '...' : ''} </p>
                                            </div>
                                          </div>
                                          <img className=' col-3 portada mr-3 d-none  d-md-block d-lg-block d-xl-block' src={tour.desktopImage && tour.desktopImage.tinyThumb ? tour.desktopImage.tinyThumb : '../../../assets/images/empty_photo.svg'} alt="" />
                                          <div className='desc d-none  d-md-block d-lg-block d-xl-block'>
                                            <h5>{tour.title}</h5>
                                            <p>{tour.previousSearchText && tour.previousSearchText.length <= 120 ? tour.previousSearchText : tour.previousSearchText ? tour.previousSearchText.substr(0, 120) + '...' : ''}</p>
                                            {/* <p>{tour.previousSearchText }</p> */}
                                          </div>
                                        </div>
                                        <a href={`/tour/${tour.urlTitle}`} className="col-1 justify-content-center align-items-center d-none  d-md-flex d-lg-flex d-xl-flex" target="_blank">
                                          <img
                                            src="../../../assets/images/external-link-symbol_azul.svg"
                                            width="25"
                                            alt=""
                                            className="img-link2"
                                          />
                                        </a>
                                      </div>
                                    );
                                  })}


                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                        {/* <div className="contentPartner">
                    <img className="partnerImageMobile"
                      alt='partner button'
                      src={'../assets/images/test-partner.svg'} />
                  </div> */}
                      </div>
                      <div className={this.props.tourIsOfficial ? "d-none" : "mapRight pdg0 col"}>
                        <div className="mapRight__top">
                          <img className="partnerImage"
                            alt='partner button'
                            src={this.state.poiCampaign ? this.state.poiCampaign.logo : '../assets/images/test-partner.svg'} />
                          <p>{this.state.poiCampaign ? this.state.poiCampaign.smallText : ""}</p>
                        </div>
                        {this.state.poiCampaign ? (
                          <div className="mapRight__bot">
                            {this.state.poiCampaign.banner ? (
                              <img
                                className="collage"
                                alt="collage button"
                                src={this.state.poiCampaign.banner}
                              />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Modal>

                  {/* modal de imagenes de las que pertenece del destino */}
                  <Modal className="ModalMap "
                    overlayClassName="ContentModal"
                    isOpen={this.state.showModalImagesPois}
                    contentLabel="ModalTours"
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.handleCloseModalTours}
                  >
                    <div className="row m-0 d-flex head-map-modal justify-content-between">
                      <div className="contentOneTwo">
                        <div className="d-flex oneH" >

                          {
                            this.props.currentUser.isAuthenticated ? <button onClick={this.handleLike} className="buttonLike">
                              <img
                                alt='like button'
                                src={
                                  this.state.liked ? '../assets/images/heart.svg' : '../assets/images/heart-outline.svg'
                                }
                              />

                            </button> : <button onClick={this.modalCloseAndAlert} className="buttonLike">
                                <img
                                  alt='like button'
                                  src={
                                    this.state.liked ? '../assets/images/heart.svg' : '../assets/images/heart-outline.svg'
                                  }
                                />
                              </button>
                          }



                          <div className="col">
                            {poi && poi.attributes ? (
                              <>
                                <h3 className="tour-content-poi-box__title" title={poi.attributes.title}>
                                  {poi.attributes.title}
                                </h3>
                                <h4 className="tour-content-poi-box__address">{poi.attributes.township}</h4>
                              </>
                            ) : null}
                          </div>
                        </div>

                        <div className="twoH pb-1">
                          <button onClick={this.handleOpenModalMap} className="actionIcon">
                            <img
                              alt='pin button'
                              src={'../assets/images/pin.svg'}
                            />
                          </button>

                          <button onClick={this.handleOpenModalInfo} className="actionIcon">
                            <img
                              alt='information button'
                              src={'../assets/images/information.svg'}
                            />
                          </button>
                          <button onClick={() => this.handleOpenModalTours(poi.id)} className="actionIcon">
                            <img
                              className="w-100"
                              alt='reward button'
                              src={'../assets/images/ruta_negro.svg'}
                            />
                          </button>
                          <button className="actionIcon buttonActive" onClick={() => this.handleOpenModalImagesPois(poi.id)}>
                            <img
                              alt='camera button'
                              src={'../assets/images/camera.svg'}
                            />
                          </button>
                          {/* <button onClick={()=>this.handleOpenModalTours(poi.id)} className="actionIcon buttonActive">
                      <img
                        alt='reward button'
                        src={'../assets/images/reward.svg'}
                      />
                    </button> */}
                          {/*  <button className="actionIcon">
                      <img
                        alt='comment button'
                        src={'../assets/images/comment2.svg'}
                      />
                    </button> */}


                        </div>
                      </div>

                      <div className="threeH">
                        <button onClick={this.handleCloseModalImagesPois} className="closeIcon p-0">
                          {CIRCLE_CROSS_ICON}
                        </button>
                      </div >


                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className={isMobile ? "row modal-mapa-imagenesPois m-0 h-100" : "row modal-mapa-imagenesPois m-0"}>
                        {!orderedImages ?
                          <>
                            <div>
                              <span>No hay imágenes por mostrar</span>
                            </div>
                          </>
                          : orderedImages.map((image, i) => {
                            return (
                              <>
                                {(!isMobile & i == 4) || (isMobile & i == 3) ?
                                  <>
                                    {
                                      this.state.poiCampaign ?
                                        <div style={{ width: "100%" }}>
                                          <article className="container tour-sponsor-box imageCampain" style={{
                                            marginTop: "0px",
                                            boxShadow: "none"
                                          }}>
                                            <div className="row">
                                              {isEmpty(this.state.campaignImages) ? null : (
                                                <div className="col-12 col-lg-7 px-0">
                                                  <Carousel
                                                    showThumbs={false}
                                                    showStatus={false}
                                                    emulateTouch
                                                    onChange={this.handleChangeCarousel}
                                                    selectedItem={this.state.carouselPosition}
                                                  >
                                                    {this.state.campaignImages.map(image => {
                                                      const srcSetArray = getImageSrcSet(image);
                                                      return (
                                                        <div key={`carousel-img-${image.id}`}>
                                                          <ResponsiveImg className="tour-content-poi-box__image campaing_image" srcSet={srcSetArray}>
                                                          </ResponsiveImg>
                                                        </div>
                                                      );
                                                    })}
                                                  </Carousel>
                                                </div>
                                              )}
                                              {true ? (
                                                <div className={`col-12 ${isEmpty(false) ? '' : 'col-lg-5'} pt-3`}>
                                                  {isEmpty(title) ? null : <h4 className="tour-sponsor-box__title">{title}</h4>}
                                                  <div dangerouslySetInnerHTML={{
                                                    __html: description
                                                  }}></div>
                                                  <div>
                                                    {/* {poiCampaign} */}
                                                  </div>
                                                  <div className="d-flex">
                                                    {isEmpty(linkCampaign) ? null : (
                                                      <a
                                                        href={checkExternalUrl(linkCampaign)}
                                                        className="btn btn-primary tour-sponsor-box__link"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        Ver más
                                                      </a>
                                                    )}
                                                    {isEmpty(this.state.videoLink) ? null : (
                                                      <a
                                                        onClick={this.openModalPartner}
                                                        className="btn btn-primary tour-sponsor-box__link botonWhite ml-2"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        Ver vídeo
                                                      </a>
                                                    )}
                                                  </div>
                                                </div>
                                              ) : null}
                                            </div>
                                          </article>
                                        </div>
                                        : null
                                    }
                                  </>
                                  :
                                  <div className="items"
                                    onClick={() => {
                                      this.handleCloseModalImagesPois();
                                      handleImageModal(image.id);
                                    }}
                                  >
                                    <img src={image && image.attributes && image.attributes["tiny-thumb"] ? image.attributes["tiny-thumb"] : ""} alt="" srcset="" />
                                  </div>}
                              </>
                            )
                          })}
                      </div>
                    </div>
                  </Modal>

                </div>
                <div className="row pt-2">
                  {/* contenido de la izquierda */}
                  <div className={this.props.tourIsOfficial ? "col-12" : "col-12 col-lg-8 poiBoxDescriptionContent"}>
                    {poi && poi.attributes ? (
                      <>
                        <div className="row m-0 tour-content-poi-box__top d-flex justify-content-between align-items-center">
                          <div className="row m-0 col-12 col-sm-6 justify-content-center pin" >
                            <button onClick={this.handleOpenModalMap} className="actionIcon">
                              <img
                                alt='map button'
                                src={'../assets/images/pin.svg'}
                              />
                            </button>
                            <button onClick={this.handleOpenModalInfo} className="actionIcon">
                              <img
                                alt='information button'
                                src={'../assets/images/information.svg'}
                              />
                            </button>
                            <button onClick={() => this.handleOpenModalTours(poi.id)} className="actionIcon">
                              <img
                                className="w-100"
                                alt='reward button'
                                src={'../assets/images/ruta_negro.svg'}
                              />
                            </button>
                            {/* boton como llegar mapa */}
                            {/* <a href={"https://www.google.com/maps/dir/" + this.state.userLatitude + "," + this.state.userLongitude + "/" + poi.attributes.lat + "," + poi.attributes.long} target="_blank" className="actionIcon">
                              <img
                                alt='comment button'
                                src={'../assets/images/comment2.svg'}
                              />
                            </a> */}
                            {/* <button onClick={()=>{
                              if (navigator.geolocation) { //check if geolocation is available
                                navigator.geolocation.getCurrentPosition(function (position) {
                                  let lat = position.coords.latitude;
                                  let long = position.coords.longitude;
                                  console.log(lat, long);
                                });
                              }
                            }} className="actionIcon">
                        <img
                          alt='comment button'
                          src={'../assets/images/comment2.svg'}
                        />
                      </button> */}
                            <button onClick={() => this.handleOpenModalImagesPois(poi.id)} className="actionIcon">
                              <img
                                alt='camera button'
                                src={'../assets/images/camera.svg'}
                              />
                            </button>
                          </div>
                          <div className="col-auto mt-3 mt-sm-0">
                            <ul className="tour-content-poi-box__info">
                              <li className="tour-content-poi-box__info-item">
                                {HEART_ICON}
                                {/* {poi.attributes['likes-number']} */}
                                {this.state.likes.length}
                              </li>
                              <li className="tour-content-poi-box__info-item">
                                {EYE_ICON}
                                {this.state.views ? this.state.views : 0}
                              </li>
                              <li className="tour-content-poi-box__info-item">
                                {TOUR_SIGNAL_ICON}
                                {poi.attributes['tours-counter'] ? poi.attributes['tours-counter'] : 0}
                              </li>
                            </ul>
                          </div>
                        </div>
                        {!isMobile ? (
                          <p className="tour-content-poi-box__description" dangerouslySetInnerHTML={{
                            __html: (
                              isEmpty(tourContentDescription) ? poiDescription : tourContentDescription
                            )
                          }}>
                          </p>
                        ) : (
                            <ShowMoreText
                              /* Default options */
                              lines={3}
                              more='(ver más)'
                              less='(ver menos)'
                              anchorClass=''
                              onClick={this.executeOnClick}
                              expanded={false}
                              width={0}
                            >
                              <p className="tour-content-poi-box__description" dangerouslySetInnerHTML={{
                                __html: (isEmpty(tourContentDescription) ? poiDescription : tourContentDescription
                                )
                              }}></p>
                            </ShowMoreText>
                          )}
                      </>
                    ) : null}
                    <header className="row tour-content-poi-box__header">
                      {poi && poi.attributes["type-preview"] == 0 ? (
                        <>
                          {isEmpty(ownerNickname) ? null : (
                            <p className="tour-content-poi-box__owner">
                              Autor original:
                              <Link
                                to={USER_DINAMIC_URL(ownerNickname)}
                                className="tour-content-poi-box__owner-name"
                              >
                                {`${ownerName} ${ownerSurname}`}
                                {ownerTopTraveler ? (
                                  <>
                                    {STAR_ICON}
                                    {SIMPLE_FLAG_ICON}
                                  </>
                                ) : null}
                              </Link>
                            </p>
                          )}
                        </>
                      ) : (
                          <>
                            {!isMobile && <div className="mt-3">
                              <h6 className="textMP">DESTINO EN MODO PREVIO</h6>
                              <h6 className="textMP2">Añade tus imagenes de este destino y podrás ganar su <span>bandera de creador.</span>
                                <button onClick={this.modalPreviewContentOpen} className="arrowRight d-flex justify-content-center align-items-center">
                                  <img
                                    alt='arrow button'
                                    src={'../assets/images/next.svg'}
                                  />
                                </button>
                              </h6>
                            </div>}
                            {
                              poi ? (<ModalPreviewContent
                                modalPreviewContent={this.state.showModalPreviewContent}
                                modalPreviewContentClose={this.modalPreviewContentClose}
                                poi={poi}
                                auth={this.props.currentUser}
                              />) : null
                            }
                          </>
                        )}
                    </header>
                  </div>
                  <div className={this.props.tourIsOfficial ? "d-none" : "col-12 col-lg-4"}>
                    {!isMobile ? (
                      <>
                        <h5 className="tour-content-poi-box__partner-call">
                          <button type="button">
                            <span className="sr-only">Arriba</span>
                          </button>
                          {this.state.poiCampaign ? 'patrocina a los creadores de este destino' : "ESPACIO PARA EL PATROCINADOR DE ESTE DESTINO"}
                          <button type="button">
                            <span className="sr-only">Abajo</span>
                          </button>
                        </h5>
                        {this.state.poiCampaign ? (
                          <>
                            <figure className="tour-content-poi-box__partner" onClick={this.modalCampaignOpen}>
                              <img
                                className="tour-content-poi-box__partner-image"
                                src={this.state.poiCampaign.logo ? this.state.poiCampaign.logo : "../../assets/images/imagen_sponsor_tour.svg"}
                                alt="Partner name"
                              />
                            </figure>
                            {/* //ModalCampaign */}
                            <Modal className="ModalVideo modalPartner"
                              overlayClassName="ContentModal"
                              isOpen={this.state.showModalCampaign}
                              contentLabel="ContentModal"
                              onRequestClose={this.modalCampaignClose}
                              shouldCloseOnOverlayClick={true}
                            >
                              <div className=" alturaImagen pt-5">
                                <div className="buttonClose">
                                  <button onClick={this.modalCampaignClose} className="closeIcon p-0">
                                    {CIRCLE_CROSS_ICON}
                                  </button>
                                </div >
                                <article className="container tour-sponsor-box m-0" style={{ borderRadius: '10px' }}>
                                  {/* <header className="row justify-content-end tour-sponsor-box__header mr-5">
                              <div className="col-auto px-1">
                                <span className="tour-sponsor-box__category">Destino patrocinado</span>
                              </div>
                              <div className="col-auto pl-1">
                                <figure className="tour-sponsor-box__header-icon">{ANGEL_ICON}</figure>
                              </div>
                            </header> */}
                                  <div className="row">
                                    {isEmpty(this.state.campaignImages) ? null : (
                                      <div className="col-12 col-lg-7 px-0">
                                        <Carousel
                                          showThumbs={false}
                                          showStatus={false}
                                          emulateTouch
                                          onChange={this.handleChangeCarouselCampaign}
                                          selectedItem={this.state.carouselPositionCampaign}
                                        >
                                          {this.state.campaignImages.map(image => {
                                            const srcSetArray = getImageSrcSet(image);
                                            return (
                                              <div key={`carousel-img-${image.id}`}>
                                                <ResponsiveImg className="tour-content-poi-box__image campaing_image" srcSet={srcSetArray}>
                                                </ResponsiveImg>
                                              </div>
                                            );
                                          })}
                                        </Carousel>
                                      </div>
                                    )}
                                    {true ? (
                                      <div className={`col-12 ${isEmpty(false) ? '' : 'col-lg-5'} pt-3`}>
                                        {isEmpty(title) ? null : <h4 className="tour-sponsor-box__title">{title}</h4>}
                                        <div dangerouslySetInnerHTML={{
                                          __html: description
                                        }}></div>
                                        <div className="d-flex">
                                          {isEmpty(linkCampaign) ? null : (
                                            <a
                                              href={checkExternalUrl(linkCampaign)}
                                              className="btn btn-primary tour-sponsor-box__link"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Ver más
                                            </a>
                                          )}
                                          {isEmpty(this.state.videoLink) ? null : (
                                            <a
                                              onClick={this.openModalPartner}
                                              className="btn btn-primary tour-sponsor-box__link botonWhite ml-2"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Ver vídeo
                                            </a>
                                          )}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </article>
                              </div>
                            </Modal>
                          </>
                        ) : (
                            <a href={`/sponsor-place/${poi && poi.id}`} target='_blank'>
                              <figure className="tour-content-poi-box__partner">
                                <img
                                  className="tour-content-poi-box__partner-image"
                                  src={this.state.poiCampaign ? this.state.poiCampaign.logo : "../../assets/images/imagen_sponsor_tour.svg"}
                                  alt="Partner name"
                                />
                              </figure>
                            </a>
                          )}
                        <div className="d-flex justify-content-center">
                          <h5 className="tour-content-poi-box__partner-description">
                            {this.state.poiCampaign ? this.state.poiCampaign.smallText : "Únete a nuestra red de Travel Angel y ayuda a financiar a los mejores viajeros y creadores de contenido."}
                          </h5>
                        </div>
                      </>
                    ) : (
                        <div className='d-flex-column justify-content-center '>
                          <h5 className="tour-content-poi-box__partner-call d-flex justify-content-center mb-3">
                            <button type="button">
                              <span className="sr-only">Arriba</span>
                            </button>
                            {this.state.poiCampaign ? this.state.poiCampaign.title : "ESPACIO PARA EL PATROCINADOR DE ESTE DESTINO"}
                            <button type="button">
                              <span className="sr-only">Abajo</span>
                            </button>
                          </h5>
                          {this.state.poiCampaign ? (
                            <figure className="tour-content-poi-box__partner d-flex align-items-center" onClick={() => window.open(this.state.poiCampaign.linkCampaign)}>
                              <img
                                className="tour-content-poi-box__partner-image"
                                src={this.state.poiCampaign.logo ? this.state.poiCampaign.logo : "../../assets/images/imagen_sponsor_tour.svg"}
                                alt="Partner name"
                              />
                              <h5 className="tour-content-poi-box__partner-description">
                                {this.state.poiCampaign ? this.state.poiCampaign.smallText : "Únete a nuestra red de Travel Angel y ayuda a financiar a los mejores viajeros y creadores de contenido."}
                              </h5>
                            </figure>
                          ) : (
                              <a href={`/sponsor-place/${poi && poi.id}`} target='_blank'>
                                <figure className="tour-content-poi-box__partner d-flex align-items-center">
                                  <img
                                    className="tour-content-poi-box__partner-image"
                                    src={this.state.poiCampaign ? this.state.poiCampaign.logo : "../../assets/images/imagen_sponsor_tour.svg"}
                                    alt="Partner name"
                                  />
                                  <h5 className="tour-content-poi-box__partner-description">
                                    {this.state.poiCampaign ? this.state.poiCampaign.smallText : "Únete a nuestra red de Travel Angel y ayuda a financiar a los mejores viajeros y creadores de contenido."}
                                  </h5>
                                </figure>
                              </a>
                            )}
                        </div>
                      )}
                    {/* Modal Partner */}
                    <Modal className="ModalVideo modalPartner"
                      overlayClassName="ContentModalVideoCampaign"
                      isOpen={this.state.showModalPartner}
                      contentLabel="ContentModal"
                      onRequestClose={this.closeModalPartner}
                      shouldCloseOnOverlayClick={true}
                    >
                      <div className=" alturaImagen pt-5">
                        <div className="buttonClose">
                          <button onClick={this.closeModalPartner} className="closeIcon p-0">
                            {CIRCLE_CROSS_ICON}
                          </button>
                        </div >
                        <div className="embed-responsive embed-responsive-16by9">
                          <iframe className="embed-responsive-item" src={this.state.videoLink} allowfullscreen></iframe>
                        </div>
                      </div>
                    </Modal>

                  </div>
                  {/* <h1>XD</h1>
            <div className="fb-comments" 
              data-href="http://localhost:4200/tour/ruta-xd" data-width="100%" data-numposts="5" > </div> */}
                </div>
                {/* {
            poi && poi.attributes ? (
              <div className="row timePoi m-0 d-flex align-items-center">
                  <img width="30"
                    alt='time'
                    src={'../assets/images/time.svg'}
                  />
                <h5 className="m-0 mx-3">Hora de llegada: <span> {poi.attributes.schedule} </span></h5>
                <h5 className="m-0 mx-3">Tiempo de visita: <span> {poi.attributes["poi-duration"]} </span></h5>
              </div>
            ) : null} */}

                {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
                {orderedTourContentChildren.forEach(e => {
                  if (e.id == 4) {
                    let x = e;
                    var index = orderedTourContentChildren.indexOf(x);
                    orderedTourContentChildren.splice(index, 1);
                    orderedTourContentChildren.unshift(x);
                  }
                  if (e.id == 5) {
                    let x = e;
                    var index = orderedTourContentChildren.indexOf(x);
                    orderedTourContentChildren.splice(index, 1);
                    orderedTourContentChildren.unshift(x);
                  }
                })}
                {!isEmpty(orderedTourContentChildren)
                  ? orderedTourContentChildren.map(tourContentChild => {
                    if (!isEmptyObject(tourContentChild)) {
                      if (!tourContentChild.attributes.visible) return null;
                      // server return an hasMany archives relationships, but it's only possible to upload a single image
                      // so simply get the first image of the array
                      const {
                        attributes: { 'content-type': contentType },
                      } = tourContentChild;
                      switch (contentType) {
                        case 1:
                        case 2:
                          return null;
                        case 3:
                          return (
                            <TourContentImagesBox
                              key={`images-${contentType}-${tourContentChild.id}`}
                              tourContent={tourContentChild}
                            />
                          );
                        case 4:
                          return (
                            <TourContentTextBox
                              key={`text-${contentType}-${tourContentChild.id}`}
                              tourContent={tourContentChild}
                            />
                          );
                        case 5:
                          return (
                            <TourContentVideoBox
                              key={`video-${contentType}-${tourContentChild.id}`}
                              tourContent={tourContentChild}
                            />
                          );
                        case 6:
                          return (
                            <TourContentMapBox
                              key={`map-info-${contentType}-${tourContentChild.id}`}
                              tourContent={tourContentChild}
                            />
                          );
                        case 7:
                          return (
                            <TourContentTimeBox
                              key={`time-${contentType}-${tourContentChild.id}`}
                              tourContent={tourContentChild}
                            />
                          );
                        default:
                          return null;
                      }
                    }
                    return null;
                  })
                  : null}
              </article>
            </>
          )
        }
      </>
    );
  }
}

TourContentPoiBox.propTypes = {
  isMobile: PropTypes.bool,
  poi: TYPES.poiType,
  poiImages: PropTypes.arrayOf(TYPES.imageType),
  tourContent: TYPES.tourContentType.isRequired,
  poiOwner: TYPES.userType,
  tourContentChildren: PropTypes.arrayOf(TYPES.tourContentType),
  selectedImage: TYPES.imageType,
  notCachedResources: PropTypes.arrayOf(PropTypes.object),
  orderPosition: PropTypes.number,
  handleImageModal: PropTypes.func,
};

TourContentPoiBox.defaultProps = {
  poi: null,
  poiImages: null,
  poiOwner: null,
  tourContentChildren: [],
  selectedImage: null,
  notCachedResources: [],
  orderPosition: null,
  isMobile: false,
  handleImageModal: null,
};

function mapStateToProps(state, ownProps) {
  const { tourContent } = ownProps;
  const notCachedResources = [];
  let poi;
  let poiImages;
  let poiOwner;
  let currentUser = state.auth;
  let tourContentChildren;
  if (!isEmptyObject(tourContent)) {
    // get related poi and it's images
    const [cachedPoi, missingPoiResources] = getRelatedEntitiesFromRedux(
      tourContent,
      'poi',
      'getPoi',
    );
    if (!isEmptyObject(cachedPoi)) poi = cachedPoi;
    if (!isEmptyObject(missingPoiResources)) notCachedResources.push(...missingPoiResources);

    if (!isEmptyObject(poi)) {
      const [cachedPoiImages, missingPoiImagesResources] = getRelatedEntitiesFromRedux(
        poi,
        'images',
        'getImage',
      );
      if (!isEmptyObject(cachedPoiImages)) poiImages = cachedPoiImages;
      if (!isEmptyObject(missingPoiImagesResources)) notCachedResources.push(...missingPoiImagesResources);

      const [cachedPoiOwner, missingPoiOwnerResources] = getRelatedEntitiesFromRedux(
        poi,
        'owner',
        'getUser',
      );
      if (!isEmptyObject(cachedPoiOwner)) poiOwner = cachedPoiOwner;
      if (!isEmptyObject(missingPoiOwnerResources)) notCachedResources.push(...missingPoiOwnerResources);
    }

    // get related tourContents images
    const [
      cachedTourContentsChildren,
      missingTourContentsChildrenResources,
    ] = getRelatedEntitiesFromRedux(tourContent, 'tour-contents', 'getTourContent');
    if (!isEmptyObject(cachedTourContentsChildren)) tourContentChildren = cachedTourContentsChildren;
    if (!isEmptyObject(missingTourContentsChildrenResources)) {
      notCachedResources.push(...missingTourContentsChildrenResources);
    }
  }
  return {
    poi,
    poiOwner,
    poiImages,
    tourContentChildren,
    notCachedResources,
    currentUser,
    isMobile: state.viewport.isXs || state.viewport.isSm,
  };
}

export default connect(mapStateToProps)(TourContentPoiBox);