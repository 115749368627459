import qs from 'query-string';

// returns a string starting with "?" and key=value& pairs (no leading "&")
function generateParamQueryString(params) {
  return `?${qs.stringify(params)}`;
}
// returns a string with the base address and query params
function addParamsToUrl(baseAddress, params) {
  return `${baseAddress}${generateParamQueryString(params)}`;
}

// return a string with the base address and updated already presents params values and add new params
function upsertParams(baseAddress, params) {
  const currentParams = qs.parse(window.location.search);
  const newParams = { ...currentParams, ...params };
  return addParamsToUrl(baseAddress, newParams);
}
// returns a string representing current page url without query params
export const CURRENT_URL_BASE_ADDRESS = () => window.location.pathname;
// return a string representing current page url with current query params
export const CURRENT_URL_ADDRESS = () => CURRENT_URL_BASE_ADDRESS() + window.location.search;
// returns an object representing current page query params
export const CURRENT_URL_QUERY_PARAMS = () => qs.parse(window.location.search);
// returns a string representing current page with up-serted (updated or inserted if missing) query params
export const CURRENT_URL_UPSERT_QUERY_PARAMS = params => upsertParams(CURRENT_URL_BASE_ADDRESS(), params);
/* AUTHENTICATION ROUTES */
export const REGISTER_URL = '/register';
export const LOGIN_URL = '/login';
export const RECOVERY_URL = '/recovery';



export const SPONSOR_PLACE_URL = '/sponsor-place/:id'



/* POI EDITION ROUTES */
export const POI_NEW = '/poi/new/info';
export const POI_URL = '/poi/:poi_id';
export const POI_EDITION_PLACE_URL = `${POI_URL}/place`;
export const POI_EDITION_INFO_URL = `${POI_URL}/info`;
export const POI_EDITION_IMAGES_URL = `${POI_URL}/images`;
export const POI_EDITION_IMAGE_URL = `${POI_URL}/image`;
export const POI_EDITION_INFO_DINAMIC_URL = id => `/poi/${id}/info`;
export const POI_EDITION_IMG_DINAMIC_URL = id => `/poi/${id}/images`;
/* TOUR EDITION ROUTES */
export const TOUR_EDITION_NAMESPACE = '/tour-edition';
export const TOUR_EDITION_URL = `${TOUR_EDITION_NAMESPACE}/:tour_id`;
export const TOUR_EDITION_DINAMIC_URL = id => `${TOUR_EDITION_NAMESPACE}/${id}`;
/* TOUR INFO ROUTES */
export const TOUR_INFO_URL = '/tour/:urlTitle';
export const TOUR_IMAGE_PAGE_URL = '/tour/:urlTitle/image/:imageId';
export const TOUR_INFO_DINAMIC_URL = urlTitle => `/tour/${urlTitle}`;
export const TOUR_IMAGE_PAGE_DINAMIC_URL = (urlTitle, imageId) => `/tour/${urlTitle}/image/${imageId}`;
export const TOUR_INFO_DINAMIC_URL_ABSOLUTE = urlTitle => `${window.location.origin}/tour/${urlTitle}`;

/* PLACES VISUALIZATION ROUTES */
export const PLACE_PAGE_URL = '/place/:urlTitle';
export const PLACE_IMAGE_PAGE_URL = '/place/:urlTitle/image/:imageId';
export const PLACE_PAGE_DINAMIC_URL = urlTitle => `/place/${urlTitle}`;
export const PLACE_IMAGE_PAGE_DINAMIC_URL = (urlTitle, imageId) => `/place/${urlTitle}/image/${imageId}`;
/* USER PROFILES ROUTES */
export const ACCOUNT_URL = '/account';
export const USER_URL = '/user/:user_nickname/:from?';
export const USER_DINAMIC_URL = nickname => `/user/${nickname}`;
/* DISCOVER PAGES ROUTES */
export const DISCOVER_URL = '/discover';
export const DISCOVER_DINAMIC_PARAMS = params => upsertParams('/discover', params);
export const DISCOVER_PLACES_URL = '/discover?searchType=1';
export const DISCOVER_TOURS_URL = '/discover?searchType=3';
/* STATICS */
export const PRIVACY_URL = '/privacy';
export const TERM_CONDITIONS_URL = '/conditions';
export const COOKIES_URL = '/cookies';
export const NOT_FOUND_URL = '/not-found';
export const FAQ_URL = '/faqs';
