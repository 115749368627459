/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import MainLayout from '../layouts/main-layout';
import {
  HOME_CURVE,
  EMAIL_ICON,
  /* FOOTER_CURVE, */ INFO_CIRCLE_ICON,
  STAR_ICON,
  history,
  TOP_TRAVELER_IMG,
} from '../helpers';

import {
  Footer, ResponsiveImg, ContentCard, ModalNewsletter,
} from '../components';
import useGetResource from '../helpers/hooks/useGetResource';
import { isEmpty, getImageSrcSet } from '../utils';
import {
  TOUR_INFO_DINAMIC_URL,
  DISCOVER_PLACES_URL,
  DISCOVER_TOURS_URL,
} from '../constants';
import ENV from '../config/environment';
import axios from 'axios';

const NEWSLETTER_OPTIONS = {
  endpoint: ENV.newsletterEndpoint,
  successUrl: `${window.location.origin}?subscription=success`,
};

function getRelationshipId(resource, relationshipName) {
  if (isEmpty(resource)) {
    return null;
  }
  const { relationships: { [`${relationshipName}`]: { data: relationshipData = null } } = {} } = resource;
  return isEmpty(relationshipData) ? null : relationshipData.id;
}
async function getTourCampaign(coverTour) {
  if (coverTour && coverTour.id) {
    let campaign = await axios.post(`${ENV.host}/${ENV.namespace}/tours/getCampaign/`, {
      tourId: coverTour.id,
    })
    if (campaign) return campaign.data.campaign;
  }
}
function HomePage(props) {
  const [tourCampaign, setTourCampaign] = useState(false)
  const { isMobile, subscriptionState, urlQueryParams } = props;
  const staffPickCovers = useGetResource(
    {
      orderBy: ['updatedAt'],
      'filters[staffPickCover]': true,
    },
    'tours',
    'getTours',
  );
  const coverTour = Array.isArray(staffPickCovers) ? staffPickCovers[0] : staffPickCovers;
  const {
    attributes: {
      'url-title': urlTitle,
      title: coverTourTitle,
      'previous-search-text': coverTourDescription,
    } = {},
  } = coverTour || {};
  const campaign = async () => {
    if (coverTour && coverTour.id) {
      let campaign = await axios.post(`${ENV.host}/${ENV.namespace}/tours/getCampaign/`, {
        tourId: coverTour.id,
      })
      setTourCampaign(campaign.data.campaign)
      return campaign;
    }
  }
  if (!tourCampaign) {
    campaign()
  }

  const coverTourOwnerId = isEmpty(coverTour) ? null : getRelationshipId(coverTour, 'owner');
  const coverOwner = useGetResource(
    { ...(!isEmpty(coverTourOwnerId) && { id: coverTourOwnerId }) },
    'users',
    'getUser',
  );

  const phoneImageId = isEmpty(coverTour) ? null : getRelationshipId(coverTour, 'phone-image');
  const coverImageId = isMobile && !isEmpty(phoneImageId)
    ? phoneImageId
    : getRelationshipId(coverTour, 'desktop-image');

  const coverImage = useGetResource({ ...(!isEmpty(coverImageId) && { id: coverImageId }) }, 'images', 'getImage');
  const coverSponsorId = isEmpty(coverTour)
    ? null
    : getRelationshipId(coverTour, 'sponsor');
  const coverSponsor = useGetResource(
    { ...(!isEmpty(coverSponsorId) && { id: coverSponsorId }) },
    'sponsors',
    'getSponsor',
  );

  const coverSponsorLogoId = isEmpty(coverSponsor) ? null : getRelationshipId(coverSponsor, 'logo');
  const coverSponsorLogo = useGetResource(
    { ...(!isEmpty(coverSponsorLogoId) && { id: coverSponsorLogoId }) },
    'images',
    'getImage',
  );
  const ownerAvatarId = isEmpty(coverOwner) ? null : getRelationshipId(coverOwner, 'avatar');

  const coverOwnerAvatar = useGetResource(
    { ...(!isEmpty(ownerAvatarId) && { id: ownerAvatarId }) },
    'images',
    'getImage',
  );

  const {
    attributes: {
      name: coverOwnerName = null,
      surname: coverOwnerSurname = null,
      toptraveler = false,
    } = {},
  } = coverOwner || {};
  const { attributes: { visibility: sponsorVisibility = false } = {} } = coverSponsor || {};
  const highlightedPlaces = useGetResource(
    {
      orderBy: 'toursCounter',
      limit: 10,
      'filters[poiType]': 1,
    },
    'pois',
    'getPois',
  );

  const highlightedTours = useGetResource({
    limit: 10,
    orderBy: 'createdAt',
    'filters[staffPick]': true,
  }, 'tours', 'getTours');

  const handleCloseNewsletterModal = useCallback(
    () => {
      urlQueryParams.delete('subscription');
      history.push({
        search: `?${urlQueryParams.toString()}`,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlQueryParams, subscriptionState],
  );

  return isMobile ? (setTimeout(() => {
    document.getElementById("appWrapper").style.color = "white"
    window.location.href = "https://m.topdesti.com";
  })) : (
      <MainLayout>
        <Helmet>
          <title>: Homepage</title>
        </Helmet>
        <div className="home-page">
          <div className="alert alert-primary text-center d-block d-lg-none" role="alert">
            {INFO_CIRCLE_ICON}
          Versión Beta
        </div>
          <Link
            to={TOUR_INFO_DINAMIC_URL(urlTitle)}
            className="home-page__header img-staff-pick"
          >
            <ResponsiveImg
              srcSet={getImageSrcSet(coverImage)}
              alt="landscape"
              wrapperClassName="home-page__cover"
              className="home-page__cover-image"
            />
            {HOME_CURVE}
          </Link>
          <div className="home-page__grey-bg--desktop">
            <div className="container pb-5">
              <div className="row pb-5">
                <div className="col-12 home-page__cover-user">
                  <span className="home-page__cover-staff-pick">
                    {STAR_ICON}
                  Staff Pick
                </span>
                  <ResponsiveImg
                    srcSet={getImageSrcSet(coverOwnerAvatar)}
                    alt="landscape"
                    wrapperClassName="home-page__cover-user-avatar-wrapper"
                    className="home-page__cover-user-avatar"
                  >
                    {toptraveler
                      ? (<span className="home-page__cover-toptraveler">{TOP_TRAVELER_IMG}</span>)
                      : null
                    }
                  </ResponsiveImg>
                  <span className="home-page__cover-user-name">
                    {`${isEmpty(coverOwnerName) ? '' : coverOwnerName} ${isEmpty(coverOwnerSurname) ? '' : coverOwnerSurname
                      }`}
                  </span>

                </div>
                <div className="col-12 col-md-4 px-4">
                  <h1 className="home-page__main-title">{coverTourTitle}</h1>
                </div>
                <div
                  className="col-12 col-md-5 home-page__main-description customized-text"
                  dangerouslySetInnerHTML={{ __html: coverTourDescription }}
                />
                {tourCampaign ? (
                  <div className="col-12 col-md-3 home-page__sponsor">
                    <p className="f-oswald">PATROCINA:</p>
                    <img
                      width="200"
                      alt="logo campaign"
                      src={tourCampaign.logo}
                    />
                    {/* <ResponsiveImg
                  type="sponsor"
                  alt="Logo patrocinador"
                  srcSet={sponsorVisibility ? getImageSrcSet(tourCampaign.logo) : null}
                /> */}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="blue-bg home-page__diagonal-top">
            <div className="container d-flex justify-content-center">
              <div className="row pt-5">
                <div className="col-12 col-lg-7 d-flex justify-content-center laptop-container">
                  <img
                    src="/assets/images/ordenador5.png"
                    alt="laptop"
                    className="d-none d-lg-block laptop-img mt-5 mb-2 pt-4"
                  />
                </div>
                <div className="col-12 col-lg-5">
                  <div className="home-page__comment mt-5">
                    <img
                      src="/assets/images/frase_comillas2.svg"
                      alt="comillas"
                      className="home-page__comment-quote"
                    />
                    <h2 className="home-page__comment-title">¿Te apasiona viajar?</h2>
                    <p className="home-page__comment-text">
                      suscribete y te avisaremos cuando puedas
                    <span className="home-page__comment-text-white">
                        ganar dinero con tus viajes
                    </span>
                    </p>

                    <div>
                      {/* Form Acumbamail */}
                      <form
                        action={NEWSLETTER_OPTIONS.endpoint}
                        method="POST"
                        className="form-group float-right mr-5 w-50"
                      >
                        <label htmlFor="email" className="d-block">
                          {EMAIL_ICON}
                          <input type="text" name="email_1" required className="form-control pl-5" placeholder="EMAIL" />
                        </label>

                        <input type="hidden" name="ok_redirect" value={NEWSLETTER_OPTIONS.successUrl} />
                        <button type="submit" className="btn btn-light btn-rounded mt-3">suscribirme</button>
                      </form>
                      {/* End Form Acumbamail */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="grey-video">
            <div className="container py-3">
              <div className="row">
                <div className="col-12 text-center py-4 px-0">
                  <h3 className="pb-3 f-quicksand">Descubre Topdesti en 2 minutos</h3>
                  <div>
                    <iframe className="col-12 col-lg-10" width="620" height="500" src="https://www.youtube.com/embed/mJhFtv5UTk8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
        </div> */}

          {/* <div className="home-page__img-w-text home-page__grey-bg--mobile">
          <img src="/assets/images/maspalomas.png" alt="landscape" className="w-100 blue-bg" />
          <div className="home-page__img-w-text--wrapper">
            <h3 className="home-page__img-w-text--title">Descubre.</h3>
            <div className="home-page__img-w-text--bg-text-white">
              <h3 className="home-page__img-w-text--title-white">Versión Beta de Gran Canaria.</h3>
              <p className="home-page__img-w-text--desc">
                Pronto te ofreceremos buscar contenido de todas partes del mundo, pero queremos que
                lo veas y lo crees de la mejor forma. Estaremos los primeros meses en Gran Canaria
                para aprender a hacer la mejor versión de Topdesti.
              </p>
            </div>
          </div>
        </div> */}
          <div className="home-page__grey-bg--mobile">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center pt-4 px-0">
                  <h3 className="text-center f-quicksand">Rutas destacadas</h3>
                  <div className="home-page__highlighted-list">
                    {isEmpty(highlightedTours) ? null : highlightedTours.map(place => (
                      <ContentCard
                        withAuthor={false}
                        contentModel={place}
                        withEditButton={false}
                        key={`${place.type}-${place.id}`}
                        homeCardView={true}
                      />
                    ))}
                  </div>
                  <Link to={DISCOVER_TOURS_URL} className="btn btn-light btn-rounded">
                    descubrir más
                </Link>
                </div>
                <div className="col-12 text-center pt-4 px-0">
                  <h3 className="text-center f-quicksand">Top destinos</h3>

                  {/* HTML for Card List component */}
                  <div className="home-page__highlighted-list">
                    {isEmpty(highlightedPlaces) ? null : highlightedPlaces.map(place => (
                      <ContentCard
                        showCounter={true}
                        withAuthor={false}
                        contentModel={place}
                        withEditButton={false}
                        key={`${place.type}-${place.id}`}
                        showCounter={true}
                      />
                    ))}
                  </div>

                  <Link to={DISCOVER_PLACES_URL} className="btn btn-light btn-rounded">
                    descubrir más
                </Link>

                  {/* END - HTML for Card List component */}
                </div>
                <div className="col-12 text-center pt-4 px-0">
                  {/* <h3 className="text-center f-quicksand">
                  Top recomendaciones en Gran Canaria
                  <span className="home-page__premium">
                    {STAR_ICON}
                    PREMIUM
                  </span>
                </h3> */}

                  {/* HTML for Card List component */}

                  {/* END - HTML for Card List component */}
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <div className="subsidized">
            <img
              alt="grant logos"
              src="/assets/images/barra_logos.jpg"
            />
            <p>Proyecto Cofinanciado en un 85% por el Fondo Europeo de Desarrollo Regional.
            Beneficiario: TOPDESTI, S.L. Nº expediente: EATIC2020010036. Inversión Aprobada: 143.360,00 €.
            Comienzo: 02/01/2020. Finalización: 20/06/2021.
          El presente proyecto tiene como objetivo el desarrollo de una APP para reservas de servicios turísticos con comisiones a recomendadores (Big Data).</p>
          </div>
        </div>
        {subscriptionState === 'success' ? (
          <ModalNewsletter
            isOpen={subscriptionState === 'success'}
            closeModal={handleCloseNewsletterModal}
          />
        ) : null}
      </MainLayout>
    );
}

HomePage.propTypes = {
  isMobile: PropTypes.bool,
  subscriptionState: PropTypes.string,
  urlQueryParams: PropTypes.objectOf(PropTypes.object),
};

HomePage.defaultProps = {
  isMobile: false,
  subscriptionState: null,
  urlQueryParams: null,
};

function mapStateToProps(state) {
  const urlQueryParams = new URLSearchParams(state.router.location.search);
  const subscriptionState = urlQueryParams.get('subscription');
  const isMobile = state.viewport.isXs || state.viewport.isSm || state.viewport.isMd;
  /* if (isMobile) {
    window.location.href = "https://topdesti-mobile.eu.meteorapp.com";
  } */
  return {
    isMobile,
    subscriptionState,
    urlQueryParams,
  };
}

export default connect(mapStateToProps)(HomePage);
