import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { sortableContainer, sortableElement, SortableHandle } from 'react-sortable-hoc';
import PlacesAutocomplete from 'react-places-autocomplete';
import { isEmpty, getImageSrcSet } from '../../../../../../utils';
import {
  PLUS_ICON,
  MAP_CIRCLE_ICON,
  INFO_ICON,
  CLOSE_ICON,
  EYE_NOT_ICON,
} from '../../../../../../helpers';
import {
  ResponsiveImg, PromiseButton, FineUploader, Gmaps,
} from '../../../../../../components';
import { defaultMapCenter } from '../../../../../../constants';
import tourContentType from '../../../../../../constants/types/tour-content-type';
import imageType from '../../../../../../constants/types/image-type';

const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];

const SortableContainer = sortableContainer(({ children }) => (
  <ul className="tour-content-tip-form__photos-list">{children}</ul>
));

const SortableItem = sortableElement(({ children }) => (
  <li className="tour-content-tip-form__photos-item">{children}</li>
));

const DragHandle = SortableHandle(({ children }) => <>{children}</>);

const RenderAutoCompleteFunction = ({
  getInputProps,
  suggestions,
  getSuggestionItemProps,
  loading,
}) => (
  <>
    <input
      {...getInputProps({
        placeholder: 'Busca un punto de interés...',
        className: 'form-control',
      })}
    />
    {!isEmpty(suggestions) ? (
      <div className="tour-content-tip-form__suggestions">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion) => {
          const className = `
                    tour-content-tip-form__suggestions-item ${
                      suggestion.active ? 'tour-content-tip-form__suggestions-item--active' : ''
                    }`;
          return (
            <div
              {...getSuggestionItemProps(suggestion, {
                className,
              })}
            >
              <span>{suggestion.description}</span>
            </div>
          );
        })}
      </div>
    ) : null}
  </>
);

RenderAutoCompleteFunction.propTypes = {
  getInputProps: PropTypes.func.isRequired,
  getSuggestionItemProps: PropTypes.func.isRequired,
  suggestions: PropTypes.shape({ active: PropTypes.bool, description: PropTypes.string })
    .isRequired,
  loading: PropTypes.bool.isRequired,
};

const TourContentTipFormTemplate = (props) => {
  const {
    handleInputChange,
    handleTextChange,
    handleToggleVisibility,
    handleDelete,
    handleOnImageUpload,
    localTourContent,
    orderedImages,
    isMobile,
    onMapComponentDidMount,
    address,
    handleAddressChange,
    handleAddressSelect,
    showMapLocalizator,
    saveMarkerPosition,
    isVisibleMapLocalizator,
    handleDeleteImage,
    handleOnDragEnd,
    handleSave,
  } = props;
  const {
    id: tourContentId = null,
    attributes: {
      title, text, visible, 'link-url': linkUrl, lat, long,
    } = {},
  } = localTourContent;

  const uploaderParams = {
    imageableId: tourContentId,
    imageableType: 'tour-content',
    imageableProperty: 'archives',
  };

  const sortTrigger = {
    ...(!isMobile && { distance: 1 }),
    ...(isMobile && { pressDelay: 100 }),
  };

  return (
    <section className="container-fluid tour-content-tip-form">
      <div className="row">
        {isMobile ? (
          <div className="col-12">
            <h3 className="tour-content-tip-form__title">Editar consejo</h3>
          </div>
        ) : null}
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-auto">
              {isEmpty(orderedImages) ? (
                <figure className="tour-content-tip-form__cover tour-content-tip-form__cover--no-image">
                  <span className="tour-content-tip-form__cover-icon">{INFO_ICON}</span>
                  <p className="mb-0">No has añadido ninguna imagen</p>
                </figure>
              ) : (
                <figure>
                  <img
                    className="tour-content-tip-form__cover"
                    src={orderedImages[0].attributes['small-thumb']}
                    alt="Portada"
                  />
                  {visible ? null : (
                    <div className="tour-content-tip-form__no-visible-layer">
                      {EYE_NOT_ICON}
                    </div>
                  )}
                </figure>
              )}
            </div>
            {!isEmpty(uploaderParams.imageableId) ? (
              <div className="col">
                <button
                  type="button"
                  className="btn btn-primary btn-block mt-3"
                  onClick={() => handleToggleVisibility()}
                >
                  {`${visible ? 'Ocultar' : 'Mostrar'} en la ruta`}
                </button>
                <PromiseButton
                  type="button"
                  className="btn btn-danger btn-block"
                  onClick={handleDelete}
                >
                  Eliminar
                </PromiseButton>
              </div>

            ) : null}
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <div className="row">
            <div className="col-12 mb-3">
              <label htmlFor="tour-content-title" className="tour-content-text-form__label">
                Título principal:
              </label>
              <input
                id="tour-content-title"
                type="text"
                value={isEmpty(title) ? '' : title}
                onChange={handleInputChange}
                name="title"
                className="form-control"
              />
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="tour-content-text" className="tour-content-tip-form__label">
                Texto general:
              </label>
              <ReactQuill
                id="tour-content-text"
                type="text"
                value={text || ''}
                onChange={handleTextChange}
                className="tour-content-tip-form__rich-text-editor"
                modules={MODULES}
                formats={FORMATS}
              />
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="link-url" className="tour-content-tip-form__label mb-0">
                Enlace:
              </label>
              <input
                id="link-url"
                type="text"
                value={linkUrl || ''}
                onChange={handleInputChange}
                name="link-url"
                className="form-control"
              />
            </div>
            <div className="col-12 mb-3 mb-lg-5">
              <div className="row align-items-center align-items-lg-end">
                <div className="col-7 col-lg-6 ml-auto">
                  <div className="row">
                    <div className="col-10 col-lg ml-auto pr-lg-1">
                      <input
                        type="number"
                        className="form-control text-center"
                        name="lat"
                        value={lat || ''}
                        readOnly
                      />
                      <p className="tour-content-tip-form__location-text">Latitud</p>
                    </div>
                    <div className="col-10 col-lg ml-auto pl-lg-1">
                      <input
                        type="number"
                        className="form-control mt-2 mt-lg-0 text-center"
                        name="long"
                        value={long || ''}
                        readOnly
                      />
                      <p className="tour-content-tip-form__location-text">Longitud</p>
                    </div>
                  </div>
                </div>
                <div className="col-4 tour-content-tip-form__location">
                  <button
                    href="#"
                    className="tour-content-tip-form__location-btn"
                    type="button"
                    onClick={() => showMapLocalizator(!isVisibleMapLocalizator)}
                  >
                    <img
                      src={MAP_CIRCLE_ICON}
                      alt="Mapa"
                      className="tour-content-tip-form__location-img"
                    />
                    <p className="tour-content-tip-form__location-text">
                      {isVisibleMapLocalizator ? 'Cerrar sin guardar' : 'Ubicar con mapa'}
                    </p>
                  </button>
                </div>
              </div>
            </div>
            {isVisibleMapLocalizator ? (
              <>
                <div className="col-12">
                  <h4 className="tour-content-tip-form__map-title">
                    Marca un lugar y pulsa en guardar:
                  </h4>
                </div>
                <div className="col-12 col-md-8 col-lg-6 mx-auto mb-3">
                  <PlacesAutocomplete
                    value={isEmpty(address) ? '' : address}
                    onChange={handleAddressChange}
                    onSelect={handleAddressSelect}
                  >
                    {RenderAutoCompleteFunction}
                  </PlacesAutocomplete>
                </div>
                <div className="col-12 tour-content-tip-form__map">
                  <Gmaps
                    onMapComponentDidMount={onMapComponentDidMount}
                    onMarkerCoordSave={saveMarkerPosition}
                    onCoordsDelete={() => saveMarkerPosition({ lat: null, lng: null })}
                    defaultZoom={10}
                    defaultCenter={{
                      lat: isEmpty(lat) ? defaultMapCenter.lat : lat,
                      lng: isEmpty(long) ? defaultMapCenter.lng : long,
                    }}
                    positionBtnsInset
                  />
                </div>
              </>
            ) : null}
            <div className="col-12">
              <h4 className="tour-content-tip-form__label mb-2">
                Imágenes:
                <small className="d-block">
                  Puedes arrastrar las miniaturas para cambiar su orden.
                </small>
              </h4>
              {isEmpty(uploaderParams.imageableId) ? (
                <p className="text-standard text-center text-danger">
                  Es necesario guardar antes de poder subir imagenes
                </p>
              ) : null}

              <SortableContainer
                onSortEnd={handleOnDragEnd}
                axis="xy"
                lockToContainerEdges
                useDragHandle
                {...sortTrigger}
              >
                {isEmpty(orderedImages)
                  ? null
                  : orderedImages.map((image, idx) => (
                    <SortableItem key={`service-image-${image.id}`} index={idx}>
                      <DragHandle>
                        <ResponsiveImg
                          className="tour-content-tip-form__photos-img"
                          wrapperClassName="imageC"
                          srcSet={getImageSrcSet(image)}
                        >
                          <PromiseButton
                            type="button"
                            className="tour-content-tip-form__photos-remove"
                            onClick={() => handleDeleteImage(image)}
                            title="Eliminar imagen"
                            primarySpinner
                          >
                            {CLOSE_ICON}
                          </PromiseButton>
                        </ResponsiveImg>
                      </DragHandle>
                    </SortableItem>
                  ))}
                <li className="tour-content-tip-form__photos-item" key="service-image-upload">
                  {isEmpty(uploaderParams.imageableId) ? (
                    <button type="button" className="tour-content-tip-form__photos-add" disabled>
                      {PLUS_ICON}
                    </button>
                  ) : (
                    <FineUploader
                      endpoint="/images"
                      autoUpload
                      params={uploaderParams}
                      onComplete={handleOnImageUpload || null}
                      withProgressBars
                      withTotalProgressBar
                      isDropzone={false}
                      removeThumbAfter={800}
                      onBeforeUpload={() => handleSave(true)}
                    >
                      <button type="button" className="tour-content-tip-form__photos-add">
                        {PLUS_ICON}
                      </button>
                    </FineUploader>
                  )}
                </li>
              </SortableContainer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

TourContentTipFormTemplate.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleOnImageUpload: PropTypes.func.isRequired,
  localTourContent: tourContentType,
  orderedImages: PropTypes.arrayOf(imageType),
  isMobile: PropTypes.bool,
  onMapComponentDidMount: PropTypes.func.isRequired,
  showMapLocalizator: PropTypes.func.isRequired,
  saveMarkerPosition: PropTypes.func.isRequired,
  isVisibleMapLocalizator: PropTypes.bool.isRequired,
  address: PropTypes.string,
  handleAddressChange: PropTypes.func.isRequired,
  handleAddressSelect: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  handleOnDragEnd: PropTypes.func.isRequired,
};

TourContentTipFormTemplate.defaultProps = {
  localTourContent: null,
  orderedImages: [],
  isMobile: false,
  address: null,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(TourContentTipFormTemplate);
