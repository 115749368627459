import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentCard from '../../content-card';
import { isEmpty } from '../../../utils';

class ResultCardList extends Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.scrollToClickedPoi = this.scrollToClickedPoi.bind(this);
    this.state = {
      orderedResults: []
    }
  }
  componentDidMount() {
    var orderedResultsDemo = true //isEmpty(orderBy)
      ? this.props.results
      : this.props.results.sort((a, b) => b.attributes['likes-number'] - a.attributes['likes-number']);
    if (!Array.isArray(this.props.results)) return null;
    this.setState({ orderedResults: orderedResultsDemo })
    this.props.pruebaPois(orderedResultsDemo);
  }

  componentDidUpdate(prevProps) {
    const { selectedPoiId: prevSelectedPoiId } = prevProps;
    const { selectedPoiId } = this.props;
    if (prevSelectedPoiId !== selectedPoiId) {
      this.scrollToClickedPoi(selectedPoiId);
    }
    if (this.props.results !== prevProps.results) {
      var orderedResultsDemo = true //isEmpty(orderBy)
        ? this.props.results
        : this.props.results.sort((a, b) => b.attributes['likes-number'] - a.attributes['likes-number']);
      if (!Array.isArray(this.props.results)) return null;
      this.setState({ orderedResults: orderedResultsDemo })
      this.props.pruebaPois(orderedResultsDemo);
    }
  }

  scrollToClickedPoi(poiId) {
    const { listRef } = this;
    const { results } = this.props;

    const selectedPoi = results[results.findIndex(item => parseInt(item.id, 10) === parseInt(poiId, 10))];

    const selectedPoiDOMElement = !isEmpty(selectedPoi) && !isEmpty(selectedPoi.reactRef) && selectedPoi.reactRef.current;

    if (!isEmpty(listRef) && !isEmpty(listRef.current) && selectedPoiDOMElement) {
      listRef.current.scrollTo(0, selectedPoiDOMElement.offsetTop);
    }
  }

  render() {
    const { listRef } = this;
    const {
      results,
      addButtonCB,
      addedPoiIds,
      handleHightlightMarker,
      selectedPoiId,
      orderBy,
      onlyPreviewApproval,
      onlyPreview,
      noPreview,
      pruebaPois,
    } = this.props;
    var countSelection = 0;

    results.forEach(element => {
      if (element.attributes.selection && element.attributes["priority-order"] >= 3) countSelection++;
    });
    /* var orderedResults = isEmpty(orderBy)
      ? results
      : results.sort((a, b) => b.attributes[orderBy] - a.attributes[orderBy]);
    if (!Array.isArray(results)) return null;
    if (onlyPreviewApproval == true) {
      orderedResults = orderedResults.filter(element => element.attributes["type-preview"] == 2);
    }
    else if (noPreview == true) {
      orderedResults = orderedResults.filter(element => element.attributes["type-preview"] == null);
    }
    else if (onlyPreview == true) {
      orderedResults = orderedResults.filter(element => element.attributes["type-preview"] != null);
    } */
    return (
      <div className="results-card-list" ref={listRef}>

        {results
          .map((poi, i) => {
            const { id } = poi;
            // check if the current poi is already included in the addedPoiIds prop
            const alreadyPresent = isEmpty(addedPoiIds) ? false : addedPoiIds.indexOf(id) >= 0;
            return (
              <ContentCard
                isAlreadyInTour={alreadyPresent}
                onMouseEnter={() => {
                  handleHightlightMarker(poi.id);
                }}
                onMouseLeave={() => {
                  handleHightlightMarker(null);
                }}
                onFocus={() => {
                  handleHightlightMarker(poi.id);
                }}
                onBlur={() => {
                  handleHightlightMarker(null);
                }}
                key={id}
                reactRef={poi.reactRef}
                contentModel={poi}
                addButtonCB={addButtonCB}
                withAuthor={false}
                isSelected={poi.id === selectedPoiId}
                showCounter={!isEmpty(orderBy)}
                indexSelection={i}
                countSelection={countSelection}
              />
              // <div><p>{id}</p></div>
            );
          })}
      </div>
    );
  }
}

ResultCardList.propTypes = {
  selectedPoiId: PropTypes.string,
  addedPoiIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  results: PropTypes.arrayOf(PropTypes.object),
  addButtonCB: PropTypes.func,
  handleHightlightMarker: PropTypes.func,
  orderBy: PropTypes.string,
};

ResultCardList.defaultProps = {
  selectedPoiId: null,
  addedPoiIds: [],
  results: [],
  addButtonCB: null,
  handleHightlightMarker: null,
  orderBy: null,
};

function mapStateToProps(state) {
  const urlQueryParams = new URLSearchParams(state.router.location.search);
  const selectedPoiId = urlQueryParams.get('select');
  return {
    selectedPoiId,
  };
}

export default connect(mapStateToProps)(ResultCardList);
