import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { CIRCLE_CROSS_ICON, NEWSLETTER_IMG } from '../../helpers';

const ModalNewsletterTemplate = (props) => {
  const { isOpen, closeModal } = props;

  return (
    <Modal
      className="modal-newsletter"
      overlayClassName="modal-newsletter__overlay"
      name="warningModal"
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className="container-fluid modal-newsletter__wrapper">
        <div className="row modal-newsletter__header">
          <button
            type="button"
            className="modal-newsletter__close-btn"
            onClick={closeModal}
            title="Cerrar"
          >
            {CIRCLE_CROSS_ICON}
          </button>
        </div>
        <div className="row">
          <div className="col-12 col-lg-auto">
            <figure className="modal-newsletter__image">
              {NEWSLETTER_IMG}
            </figure>
          </div>
          <div className="col-12 col-lg">
            <h3 className="modal-newsletter__title">Recibido, gracias.</h3>
            <p className="modal-newsletter__text">
              Para completar tu suscripción, debes confirmar haciendo clic
              en el correo electrónico que te hemos enviado. Podrás cancelar tu suscripción cuando lo desees.
            </p>
            <p className="modal-newsletter__text">
              Te enviaremos información de las novedades importantes que tengamos
              en Topdesti para ir mejorando nuestra plataforma.
            </p>
            <p className="modal-newsletter__text">Un saludo.</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalNewsletterTemplate.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalNewsletterTemplate;
