import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RecommendationInfoBoxTemplate from './RecommendationInfoBoxTemplate';
import TYPES from '../../../../../../../../constants/types';
import { isEmpty } from '../../../../../../../../utils';
import { GOOGLE_MARKER } from '../../../../../../../../utils/google-maps-utils';

const RecommendationInfoBox = (props) => {
  const { service, handleShowInfo } = props;

  const [isShowedMap, setIsShowedMap] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [areTilesLoaded, setAreTilesLoaded] = useState(false);

  const handleMapOnMount = (map) => {
    setMapInstance(map);
  };

  const initializeMapBounds = () => {
    setAreTilesLoaded(areTilesLoaded + 1);
  };

  // Delay to load the map after the show-in animation which was bugging the tiles load
  useEffect(() => {
    setTimeout(() => {
      setIsShowedMap(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (!isEmpty(mapInstance) && !isEmpty(service) && areTilesLoaded === 1) {
      const { attributes: { lat = null, long: lng = null } = {} } = service;
      const bounds = new window.google.maps.LatLngBounds();
      const mapMarker = new window.google.maps.Marker({
        position: { lat, lng },
        map: mapInstance,
        icon: GOOGLE_MARKER(),
        clickable: false,
      });
      bounds.extend(mapMarker.getPosition());
      mapInstance.fitBounds(bounds, 5);
      mapInstance.setZoom(17);
    }
  }, [mapInstance, areTilesLoaded, service]);

  return (
    <RecommendationInfoBoxTemplate
      service={service}
      handleShowInfo={handleShowInfo}
      handleMapMount={handleMapOnMount}
      initializeMapBounds={initializeMapBounds}
      isShowedMap={isShowedMap}
    />
  );
};

RecommendationInfoBox.propTypes = {
  service: TYPES.poiType.isRequired,
  handleShowInfo: PropTypes.func.isRequired,
};

export default RecommendationInfoBox;
