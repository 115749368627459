import React from 'react';
import PropTypes from 'prop-types';
import SocialLogin from 'react-social-login';

function SocialButton({ children, triggerLogin, ...props }) {
  return (
    <button type="button" onClick={triggerLogin} {...props}>
      { children }
    </button>
  );
}

SocialButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  triggerLogin: PropTypes.func.isRequired,
};

export default SocialLogin(SocialButton);
