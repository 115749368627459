/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FACEBOOK_SOLID,
  TWITTER_SOLID,
  YOUTUBE_ICON,
  INSTAGRAM_ICON,
  PINTEREST_SOLID,
  VIMEO_SOLID,
  // TOP_TRAVELER,
  PENCIL_ICON,
  TOP_TRAVELER_IMG,
  ANGEL_ICON,
  CIRCLE_CROSS_ICON,
  CHECK_ICON,
  CLOSE_ICON,
  ROUTE_ICON,
  EYE_ICON,
  UPLOAD_CLOUD_URL,
  DROP_COLOR_URL,
  LOGO_YOUTUBE_ICON,
  LOGO_VIMEO_ICON,
  EMPTY_PHOTO_ICON,
} from '../../../helpers';
import { isEmpty, checkExternalUrl, confirmationToastr, getImageSrcSet } from '../../../utils';
import Modal from 'react-modal';
import {
  ValidationInput, FineUploader, ResponsiveImg, PromiseButton, LoadingSpinner
} from '../../../components';
import Textarea from 'react-textarea-autosize';
import ReactQuill from 'react-quill';
import ENV from '../../../config/environment';
import moment from 'moment'
import { Link } from 'react-router-dom'
import { uuid } from 'uuidv4';
import { toastr } from 'react-redux-toastr';
import { sortableContainer, sortableElement, SortableHandle } from 'react-sortable-hoc';

const MODULES = {
  toolbar: ['bold', 'italic', 'link'],
};

const FORMATS = ['bold', 'italic', 'link'];
const SortableContainer = sortableContainer(({ children }) => (
  <ul className="tour-config-sponsor__photos-list">{children}</ul>
));

const SortableItem = sortableElement(({ children }) => (
  <li className="tour-content-tip-form__photos-item">{children}</li>
));

const DragHandle = SortableHandle(({ children }) => <>{children}</>);
let campaignId;

class ProfileInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptionsProfileModal: false,
      activeBtn: 1,
      activeBtn2: 2,
      defaultView: true,
      addCampaign: false,
      assignCampaign: false,
      seeCampaign: false,
      campaignName: "",
      title: "",
      shortText: "",
      text: "",
      link: "",
      linkVideo: "",
      logoCampaign: '',
      bannerCampaign: '',
      images: [],
      idCampaignExterna: "",
      pois: [],
      poi: {},
      sponsorPoi: [],
      campaigns: [],
      campaignSelectable: '',
      currentPoiCampaign: '',
      galleryCampaign: [],
      viewTabTours: false,
      assignCampaignTour: false,
      currentTourCampaign: "",
      currentTour: "",
      campaignTourSelectable: "",
    };
  }
  modalOptions = (element) => {
    this.setState({ showOptionsProfileModal: element });
  }
  viewsModal = (e1, e2, e3, e4) => {
    if (e1) {
      this.setState({
        defaultView: e1,
        addCampaign: e2,
        assignCampaign: e3,
        seeCampaign: e4,
        currentCampaign: '',
        title: '',
        shortText: '',
        campaignName: '',
        text: '',
        link: '',
        logoCampaign: '',
        bannerCampaign: '',
        linkVideo: '',
      });
    } else {
      this.setState({
        defaultView: e1,
        addCampaign: e2,
        assignCampaign: e3,
        seeCampaign: e4,
      });
    }
  }

  componentDidMount() {
    if (window.location.pathname.split('/').slice(-1)[0] == 'true') {
      this.setState({
        showOptionsProfileModal: true
      })
    }
    this.initialLoad()
  }

  initialLoad = () => {
    if (this.props.user) {
      fetch(`${ENV.host}/${ENV.namespace}/pois/sponsoredPois/${this.props.user ? this.props.user.id : ''}`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.setState({
            campaigns: data.campaigns,
            pois: data.pois,
            sponsorPoi: data.sponsorPoi,
          })
        });
    }
  }

  getCurrentPoiCampaign = (poi) => {
    let sponsor = this.state.sponsorPoi.find(sponsor => poi.id == sponsor.poiId)
    let campaign = this.state.campaigns.find(campaign => campaign.campaingId === sponsor.campaignId)
    this.setState({
      currentPoiCampaign: campaign,
      campaignSelectable: campaign ? campaign.campaingId : ''
    })
    this.viewsModal(false, false, true, false)

  }

  removeCampaign = (sponsor) => {
    fetch(`${ENV.host}/${ENV.namespace}/pois/removeCampaign`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        sponsor: sponsor
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.initialLoad()
        toastr.success(data.mensaje);
      });
  }

  deleteCampaign = () => {
    confirmationToastr(
      '¿Estás segur@ de eliminar esta campaña?',
      () => {
        fetch(`${ENV.host}/${ENV.namespace}/pois/deleteCampaign`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.state.currentCampaign && this.state.currentCampaign.id,
            campaignId: this.state.currentCampaign && this.state.currentCampaign.campaingId,
          }),
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.initialLoad()
            toastr.success(data.mensaje);
          });
        this.viewsModal(true, false, false, false)
        return Promise.resolve();
      },
      () => {
        return false;
      },
    );
  }

  viewCampaign = (campaign) => {
    fetch(`${ENV.host}/${ENV.namespace}/pois/imagesCampaign`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: campaign.id
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.initialLoad()
        this.setState({
          currentCampaign: campaign,
          galleryCampaign: data.images,
          defaultView: false,
          addCampaign: false,
          assignCampaign: false,
          seeCampaign: true,
        });
      })
  }

  editarCampaign = () => {

    this.setState({
      title: this.state.currentCampaign.title,
      campaignName: this.state.currentCampaign.name,
      shortText: this.state.currentCampaign.smallText,
      text: this.state.currentCampaign.description,
      link: this.state.currentCampaign.linkCampaign,
      logoCampaign: this.state.currentCampaign.logo,
      bannerCampaign: this.state.currentCampaign.banner,
      linkVideo: this.state.currentCampaign.videoLink,
      defaultView: false,
      addCampaign: true,
      assignCampaign: false,
      seeCampaign: false,
    })
  }

  handleSelectCampaign = (e) => {
    this.setState({ campaignSelectable: e })
  }

  onChange = (value) => {
    this.setState({ text: value })
  }

  saveCampaign = () => {
    if (this.state.text.length <= 500) {
      fetch(`${ENV.host}/${ENV.namespace}/pois/poiCampaing`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user: this.props.user.id,
          campaingId: uuid()
        }),
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.initialLoad()
          campaignId = data.poiSaved.id
          this.setState({
            galleryCampaign: [],
            addCampaign: true, defaultView: false, assignCampaign: false,
            seeCampaign: false, currentCampaign: data.poiSaved
          })
        });
    } else {
      toastr.error('El campo de texto no puede tener mas de 500 caracteres');
    }
  }

  assaignCampaign = () => {
    fetch(`${ENV.host}/${ENV.namespace}/pois/assaignCampaign`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        idCampaign: this.state.campaignSelectable,
        poiId: this.state.currentPoi.id
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.initialLoad()
        this.setState({
          defaultView: true,
          addCampaign: false,
          assignCampaign: false,
          seeCampaign: false,
          currentPoi: {}
        });
        toastr.success('Campaña asignada');
      });
  }

  showPreviewImagesCampaign = (image) => {
    image.data.attributes.order = this.state.galleryCampaign.length > 0 ? this.state.galleryCampaign.length + 1 : 1;
    image.data.attributes.id = image.data.id
    image.data.attributes.tinyThumb = image.data.attributes["tiny-thumb"]
    this.setState(prevState => ({
      galleryCampaign: [...prevState.galleryCampaign, image.data.attributes]
    }))
  }

  deleteImageCampaign = (image) => {
    fetch(`${ENV.host}/${ENV.namespace}/pois/deleteImageCampaign/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: image.id,
        idCampaign: image.imageableId
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        let array = this.state.galleryCampaign
        let index = array.findIndex(img => img.id == image.id)
        if (index > -1) {
          array.splice(index, 1)
          this.setState({
            galleryCampaign: array
          })
        }
      });
  }

  showPreviewImagesCampaignLogo = (photo) => {
    this.setState({ logoCampaign: photo.data.attributes.url })
  }

  showPreviewImagesCampaignBanner = (photo) => {
    this.setState({ bannerCampaign: photo.data.attributes.url })
  }

  updateCampaign = () => {
    if (this.state.text && this.state.title && this.state.campaignName) {
      if (this.state.text.length <= 500) {
        fetch(`${ENV.host}/${ENV.namespace}/pois/updatePoiCampaing`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.state.currentCampaign.id,
            title: this.state.title,
            name: this.state.campaignName,
            smallText: this.state.shortText,
            description: this.state.text,
            link: this.state.link,
            logo: this.state.logoCampaign,
            banner: this.state.bannerCampaign,
            linkVideo: this.state.linkVideo
          }),
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.initialLoad()
            this.setState({
              defaultView: true,
              addCampaign: false,
              assignCampaign: false,
              seeCampaign: false,
              title: '',
              campaignName: '',
              shortText: '',
              text: '',
              link: '',
              logoCampaign: '',
              bannerCampaign: '',
              linkVideo: '',
              currentCampaign: ''
            })
            toastr.success('Campaña guardada');
          });
      } else {
        toastr.error('El campo de texto no puede tener mas de 500 caracteres');
      }
    } else {
      toastr.error('Hay campos sin llenar');
    }

  }
  handleOnDragEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex > newIndex) {
      this.state.galleryCampaign[oldIndex].order = newIndex + 1;
      for (let i = newIndex; i < oldIndex; i++) {
        this.state.galleryCampaign[i].order = i + 2;
      }
    } else if (oldIndex < newIndex) {
      for (let i = oldIndex; i <= newIndex; i++) {
        this.state.galleryCampaign[i].order = i;
      }
      this.state.galleryCampaign[oldIndex].order = newIndex + 1;
    }
    this.state.galleryCampaign.sort(function (a, b) {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    fetch(`${ENV.host}/${ENV.namespace}/images/orderGalleryCampaign`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        images: this.state.galleryCampaign,
      }),
    })
      .then(response => {
        return response.json();
      }).then(data => {
        this.setState({ galleryCampaign: this.state.galleryCampaign })
      })

  };

  removeCampaignTour = (tourId) => {
    fetch(`${ENV.host}/${ENV.namespace}/tours/removeCampaign`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tour: tourId
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.initialLoad();
        this.props.updateToursCampaign()
        this.setState({
          currentTourCampaign: false,
          campaignSelectable: false
        })
        toastr.success('Campaña desasignada');
      });
  }

  addCampaignTour = () => {
    if (document.getElementById('idCampaignInput').value == '') {
      fetch(`${ENV.host}/${ENV.namespace}/tours/addCampaign`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tourId: this.state.currentTour.id,
          campaingId: this.state.campaignTourSelectable
        }),
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.props.updateToursCampaign()
          /* this.getCampaign() */
          this.viewsModal(true, false, false, false)
          this.setState({
            assignCampaignTour: false
          })
          toastr.success('Campaña asignada');
        });
    } else {
      fetch(`${ENV.host}/${ENV.namespace}/tours/addCampaignOthers`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tourId: this.state.currentTour.id,
          campaingId: this.state.campaignTourSelectable
        }),
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.update) {
            this.props.updateToursCampaign()
            /* this.getCampaign() */
            /*             this.initialLoad() */
            this.viewsModal(true, false, false, false)
            this.setState({
              assignCampaignTour: false
            })
            toastr.success('Campaña asignada');
          } else {
            toastr.error('La campaña no existe o es posible que el ID este incorrecto');
          }
        });
    }

  }
  getCampaign = () => {
    fetch(`${ENV.host}/${ENV.namespace}/tours/getCampaign/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tourId: this.state.currentTour.id
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.campaign) {
          this.setState({
            currentTourCampaign: data.campaign,
            campaignSelectable: data.campaign.id
          })
        }
      });
  }
  render() {
    const {
      user,
      toggleEditMode,
      isPrivate,
      isSmallMobile,
    } = this.props;
    const {
      name, surname, description, website, nickname, toptraveler,
    } = !isEmpty(user) && user.attributes;
    let imageParams = {
      imageableId: this.state.currentCampaign && this.state.currentCampaign.id,
      imageableType: 'campaign',
      imageableProperty: 'image',
    }
    var myCampaigns = this.state.campaigns.length >= 1 ? this.state.campaigns.filter(item => item.createdBy == this.props.user.id) : []

    this.state.galleryCampaign.sort(function (a, b) {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });


    const facebookProfile = !isEmpty(user) && user.attributes['facebook-profile'];
    const instagramProfile = !isEmpty(user) && user.attributes['instagram-profile'];
    const youtubeProfile = !isEmpty(user) && user.attributes['youtube-profile'];
    const pinterestProfile = !isEmpty(user) && user.attributes['pinterest-profile'];
    const vimeoProfile = !isEmpty(user) && user.attributes['vimeo-profile'];
    const twitterProfile = !isEmpty(user) && user.attributes['twitter-profile'];
    const sortTrigger = {
      ...(!isSmallMobile && { distance: 1 }),
      ...(isSmallMobile && { pressDelay: 100 }),
    };
    const imageTour = this.props.imageArray ? Object.values(this.props.imageArray) : [];

    const publishedRoutes = this.props.ownedTours/* .filter(tour => tour.attributes.state == 3) */
    return (
      <div
        className={`row profile-page__top${!isPrivate
          ? ' row profile-page__top--public'
          : ''}`}
      >
        {isPrivate && isSmallMobile
          ? (
            <button
              type="button"
              onClick={toggleEditMode}
              className="profile-page__edit-btn"
              title="Editar"
            >
              {PENCIL_ICON}
            </button>
          ) : null
        }
        {toptraveler
          ? (
            <div className="col-12 pt-1 profile-page__top-traveler-wrapper">
              {TOP_TRAVELER_IMG}
            </div>
          )
          : null}
        <div className={`col-12 col-md-6 px-4 pt-1 profile-page__user${toptraveler
          ? ' profile-page__user--toptraveler'
          : ''}`}
        >
          <h1 className="profile-page__user-name">
            {isPrivate && !isSmallMobile
              ? (
                <>
                  <button
                    type="button"
                    onClick={() => this.modalOptions(true)}
                    className="profile-page__angel-btn"
                    title="Sponsor"
                  >
                    {ANGEL_ICON}
                  </button>
                  <button
                    type="button"
                    onClick={toggleEditMode}
                    className="profile-page__edit-btn"
                    title="Editar"
                  >
                    {PENCIL_ICON}
                  </button>
                </>
              ) : null
            }
            {`${name} ${surname}`}
          </h1>
          <p className="profile-page__user-nickname">
            {nickname}
          </p>
          {/* <figure className="profile-page__user-logo">
                      {TOP_TRAVELER}
                    </figure> */}
        </div>
        <div className="col-12 col-md-6 px-4 pt-1">
          <p
            className={`profile-page__website${isPrivate
              ? ' profile-page__website--private'
              : ''}`}
          >
            <a href={checkExternalUrl(website)} className="profile-page__website-link">
              {website}
            </a>
          </p>
          <div className="profile-page__social-list">
            {
              isEmpty(facebookProfile)
                ? null
                : (
                  <a
                    href={checkExternalUrl(facebookProfile)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="profile-page__social-link"
                  >
                    {FACEBOOK_SOLID}
                  </a>
                )
            }
            {
              isEmpty(instagramProfile)
                ? null
                : (
                  <a
                    href={checkExternalUrl(instagramProfile)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="profile-page__social-link"
                  >
                    {INSTAGRAM_ICON}
                  </a>
                )
            }
            {
              isEmpty(youtubeProfile)
                ? null
                : (
                  <a
                    href={checkExternalUrl(youtubeProfile)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="profile-page__social-link"
                  >
                    {YOUTUBE_ICON}
                  </a>
                )
            }
            {
              isEmpty(pinterestProfile)
                ? null
                : (
                  <a
                    href={checkExternalUrl(pinterestProfile)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="profile-page__social-link"
                  >
                    {PINTEREST_SOLID}
                  </a>
                )
            }
            {
              isEmpty(vimeoProfile)
                ? null
                : (
                  <a
                    href={checkExternalUrl(vimeoProfile)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="profile-page__social-link"
                  >
                    {VIMEO_SOLID}
                  </a>
                )
            }
            {
              isEmpty(twitterProfile)
                ? null
                : (
                  <a
                    href={checkExternalUrl(twitterProfile)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="profile-page__social-link"
                  >
                    {TWITTER_SOLID}
                  </a>
                )
            }
          </div>
        </div>
        <div className="col-12 col-md-10 offset-md-1 py-3 ">
          <p className="profile-page__description" dangerouslySetInnerHTML={{ __html: isEmpty(description) ? 'Descubre las rutas y destinos creados por ' + `${name} ${surname}` : description }}>
            {/* {isEmpty(description) ? 'Descubre las rutas y destinos creados por ' + `${name} ${surname}` : description} */}
          </p>
        </div>
        <Modal
          className="modalOptions"
          overlayClassName="modalOptions__contentModal"
          isOpen={this.state.showOptionsProfileModal}
          contentLabel="ContentModal"
          onRequestClose={() => this.modalOptions(false)}
          shouldCloseOnOverlayClick={true}
        >
          <div className="navbarLeft">
            <button
              type="button"
              onClick={() => this.modalOptions(true)}
              className={`navbarLeft__btn ${this.state.activeBtn == 1 ? "navbarLeft__btn--active" : ""}`}
              title="Sponsor"
            >
              {ANGEL_ICON}
            </button>
          </div>
          <div className="content">
            {
              this.state.defaultView && <div className="w-100 d-flex justify-content-end align-items-center division3 fixedObject">
                <h4 className="mb-0 mr-2">Mis patrocinios y campañas</h4>
                {/* <h4 className="mb-0 mr-2">Añadir patrocinador</h4> */}
                <button onClick={() => this.modalOptions(false)} className="closeIcon p-0">
                  {CLOSE_ICON}
                </button>
              </div>
            }
            {
              this.state.seeCampaign && <div className="w-100 d-flex justify-content-end align-items-center division3 fixedObject">
                {/* <h4 className="mb-0 mr-2">Mis patrocinios y campañas</h4> */}
                {/* <h4 className="mb-0 mr-2">Añadir patrocinador</h4> */}
                <button onClick={() => this.viewsModal(true, false, false, false)} className="closeIcon p-0">
                  {CLOSE_ICON}
                </button>
              </div>
            }
            {
              this.state.addCampaign && <div className="w-100 d-flex justify-content-end align-items-center division3 fixedObject">
                <h4 className="mb-0 mr-2">Guardar campaña</h4>
                <button onClick={() => this.updateCampaign()} className="checkIcon p-0">
                  {CHECK_ICON}
                </button>
                <button onClick={() => this.viewsModal(true, false, false, false)} className="closeIcon p-0">
                  {CLOSE_ICON}
                </button>
              </div>
            }
            {
              this.state.assignCampaign && <div className="w-100 d-flex justify-content-end align-items-center division3 fixedObject">
                <h4 className="mb-0 mr-2">Asignar campaña</h4>
                <button onClick={() => this.assaignCampaign()} className="checkIcon p-0">
                  {CHECK_ICON}
                </button>
                <button onClick={() => this.viewsModal(true, false, false, false)} className="closeIcon p-0">
                  {CLOSE_ICON}
                </button>
              </div>
            }
            {
              this.state.assignCampaignTour && <div className="w-100 d-flex justify-content-end align-items-center division3 fixedObject">
                <h4 className="mb-0 mr-2">Asignar campaña</h4>
                <button onClick={() => this.addCampaignTour()} className="checkIcon p-0">
                  {CHECK_ICON}
                </button>
                <button onClick={() => {
                  this.setState({
                    assignCampaignTour: false
                  })
                  this.viewsModal(true, false, false, false)
                }} className="closeIcon p-0">
                  {CLOSE_ICON}
                </button>
              </div>
            }
            <div className="bar">
              <button
                type="button"
                onClick={() => this.setState({
                  viewTabTours: true,
                })}
                className={`barContent__btn ${this.state.viewTabTours ? "barContent__btn--active" : ""}`}
                title="Sponsor"
              >
                {ROUTE_ICON}
                <p>{this.props.ownedTours.length} / {this.props.countTours}</p>
              </button>
              <button
                type="button"
                onClick={() => this.setState({
                  viewTabTours: false,
                })}
                className={`ml-2 barContent__btn ${!this.state.viewTabTours ? "barContent__btn--active" : ""}`}
                title="Sponsor"
              >
                <img
                  width="16"
                  className=""
                  alt="button"
                  src={!this.state.viewTabTours ? '../../../assets/images/marker2.svg' : '../../../assets/images/marker1.svg'}
                />
                <p>{this.state.pois.length}</p>
              </button>
            </div>
            <div className={this.state.defaultView ? "contentCenter d-flex" : "contentCenter d-none"}>
              {/* pois */}
              {!this.state.viewTabTours ? (
                <div className="sponsored-destinations col-7">
                  <h4 className="division2 ml-0">Mis destinos patrocinados</h4>
                  {this.state.pois && this.state.pois.length >= 1 ? (
                    this.state.pois.map((poi, i) => {
                      let sponsor = this.state.sponsorPoi.find(sponsor => poi.id == sponsor.poiId)
                      let campaign = this.state.campaigns.find(campaign => campaign.campaingId === sponsor.campaignId)

                      poi.images.sort((a, b) => {
                        if (a.likes > b.likes) {
                          return -1;
                        }
                        if (a.likes < b.likes) {
                          return 1;
                        }
                        return 0;
                      });

                      return (
                        <div key={i} className="d-flex justify-content-start align-items-start mb-5">
                          {
                            poi.images.length >= 1 ? <img
                              className="imageSponsor mr-2"
                              alt="button"
                              src={poi.images[0].thumb}
                            /> : <img
                                className="imageSponsor mr-2"
                                alt="button"
                                src='../../../assets/images/empty_photo.svg'
                              />
                          }
                          <div className="d-flex flex-column mr-2 align-items-start justify-content-center">
                            <div className="d-flex mb-2 align-items-start justify-content-center">
                              <h5 className="mb-0 mr-2">{poi.title}</h5>
                              <Link to={`/place/${poi.urlTitle}`}>
                                <img
                                  src="../../../assets/images/external-link-symbol_negro.svg"
                                  width="22"
                                  alt=""
                                  className=""
                                />
                              </Link>
                            </div>
                            <h6 className="mb-2">Desde {moment(sponsor.startDate).subtract(10, 'days').calendar()} hasta el {moment(sponsor.expirationDate).subtract(10, 'days').calendar()}</h6>
                            <div className="d-flex mt-2 align-items-start justify-content-center">
                              {
                                sponsor.campaignId ? <h5 className="mb-2 mr-2 textBlue">{campaign.title}</h5> : <h5 className="mb-2 mr-2 textRedCampaign">Sin campañas asociadas</h5>
                              }
                              {
                                sponsor.campaignId && <img
                                  src="../../../assets/images/closeSquare.svg"
                                  width="22"
                                  alt=""
                                  className="mr-2 cursor"
                                  onClick={() => this.removeCampaign(sponsor.id)}
                                />
                              }
                              <img
                                src="../../../assets/images/refresh.svg"
                                width="22"
                                alt=""
                                className="cursor"
                                onClick={() => {
                                  this.setState({
                                    currentPoi: poi
                                  })
                                  this.getCurrentPoiCampaign(poi)
                                }}
                              />
                            </div>
                            <div className="d-flex align-items-stretch justify-content-center">
                              <h5 className="division4 mb-0 mr-3 pr-3">100 €</h5>
                              <div className="division4 mr-3 pr-3 d-flex-column justify-content-center align-items-center">
                                <img width='20' src="../../../assets/images/circle_cross.svg" alt="" />
                                <p className='m-0 text-center'>{poi.toursCounter}</p>
                              </div>
                              <div className="division4 mr-3 pr-3 d-flex-column justify-content-center align-items-center">
                                <img width='20' src="../../../assets/images/eye.svg" alt="" />
                                <p className='m-0 text-center'>{poi.views}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                      <>
                        <h5 className="text-danger text-center">No tienes destinos patrocinados.</h5>
                      </>
                    )
                  }
                </div>
              ) : (
                  /* tours */
                  < div className="sponsored-destinations col-7">
                    <h4 className="division2 ml-0">Mis rutas</h4>
                    {this.props.ownedTours && this.props.ownedTours.length >= 1 ? (
                      <>{publishedRoutes.length > 0 ? (
                        publishedRoutes.map((tour, i) => {
                          let campaign = this.state.campaigns.find(campaign => campaign.id === tour.attributes["campaign-tour"])
                          let image = imageTour.find(image => image.attributes["imageable-id"] == tour.id && image.attributes["imageable-type"] == "tour")
                          return (
                            <div key={i} className="d-flex justify-content-start align-items-start mb-5">
                              {image ? (
                                <img
                                  className={tour.attributes.state != 3 ? "imageSponsor transparent mr-2" : "imageSponsor mr-2"}
                                  alt="button"
                                  src={image.attributes.thumb}
                                />
                              ) : (
                                  <span className="withOutImage mr-2">
                                    {EMPTY_PHOTO_ICON}
                                  </span>
                                )}
                              {/* <img
                                className="imageSponsor mr-2"
                                alt="button"
                                src={image ? image.attributes.thumb : EMPTY_PHOTO_ICON}
                              /> */}
                              <div className="d-flex flex-column mr-2 align-items-start justify-content-center">
                                <div className="d-flex mb-2 align-items-start justify-content-center">
                                  <h5 className="mb-0 mr-2">{tour.attributes.title}</h5>
                                  <Link to={`/tour/${tour.attributes["url-title"]}`}>
                                    <img
                                      src="../../../assets/images/external-link-symbol_negro.svg"
                                      width="22"
                                      alt=""
                                      className=""
                                    />
                                  </Link>
                                </div>
                                <div className="d-flex mt-2 align-items-start justify-content-center">
                                  {
                                    campaign ? <h5 className="mb-2 mr-2 textBlue">{campaign.title}</h5> : <h5 className="mb-2 mr-2 textRedCampaign">Sin campañas asociadas</h5>
                                  }
                                  {
                                    campaign && <img
                                      src="../../../assets/images/closeSquare.svg"
                                      width="22"
                                      alt=""
                                      className="mr-2 cursor"
                                      onClick={() =>
                                        this.removeCampaignTour(tour.id)}
                                    />
                                  }
                                  <img
                                    src="../../../assets/images/refresh.svg"
                                    width="22"
                                    alt=""
                                    className="cursor"
                                    onClick={() => {
                                      this.setState({
                                        assignCampaignTour: true,
                                        currentTourCampaign: campaign,
                                        currentTour: tour,
                                        campaignTourSelectable: campaign ? campaign.id : "",

                                      })
                                      this.viewsModal(false, false, false, false)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        })
                      ) : <h5 className="text-danger text-center">No tienes rutas publicadas.</h5>}
                        {this.props.loadingTours ?
                          <div style={{ width: "200px" }} className="container loadingScroll">
                            <div>
                              <div className="mt-5">
                                <LoadingSpinner onlySpinner />
                              </div>
                              <p className="w-100 text-center mt-3">Cargando...</p>
                            </div>
                          </div>
                          :
                          <div className="d-flex justify-content-center align-items-center">
                            <button
                              onClick={() => this.props.LoadMoreOwnedPois("tours", 12)}
                              className={this.props.ownedTours.length == this.props.countTours ? "d-none" : "btn btn-light btn-rounded"}
                            >cargar mas rutas</button>
                          </div>
                        }
                      </>
                    ) : (
                        <>
                          <h5 className="text-danger text-center">No tienes rutas.</h5>
                        </>
                      )
                    }
                  </div>
                )}


              <div className="campaign col-5">
                <div className="d-flex justify-content-start align-items-start">
                  <h4 className="division2 w-100">Campañas</h4>
                  <img
                    width="32"
                    className="cursor añadir_azul"
                    alt="button"
                    src='../../../assets/images/anadir_azul.svg'
                    onClick={() => this.saveCampaign()}
                  />
                </div>
                {myCampaigns.length >= 1 ? (
                  myCampaigns.map((campaign, i) => {
                    if (this.props.user.id == campaign.createdBy) {
                      return <h6 key={i} className="px-3 cursor" onClick={() => this.viewCampaign(campaign)}>{campaign.title}</h6>
                    }
                  })
                ) : (
                    <>
                      <h5 className="text-danger text-center">No tienes ninguna campaña creada.</h5>
                      <p className="text-center">1. Crea una nueva campaña pulsando el icono "+" de la parte superior.</p>
                      <p className="text-center">2. Una vez creada aparecerá en este espacio de información y ya podrás asociarla a tus destinos pulsando en el icono: <img
                        src="../../../assets/images/refresh.svg"
                        width="22"
                        alt=""
                        className="cursor"
                      /></p>
                    </>
                  )
                }
              </div>
            </div>
            {/* campaña */}
            {
              this.state.addCampaign && (
                <div className="d-flex addCampaign">
                  <div className="left col-7">
                    <h4 className="division2 ml-0 mb-4">{this.state.currentCampaign.title}</h4>
                    <h5 className="d-flex mb-3">ID único: <h5 className="textBlue ml-2">{this.state.currentCampaign && this.state.currentCampaign.campaingId}</h5></h5>
                    {/* <li className="left__category-item mb-3">
                      <label
                        htmlFor="noPreview"
                        className="custom-checkbox"
                      >
                        {"Activar en todos mis destinos patrocinados"}
                        <input
                          type="checkbox"
                          id="noPreview"
                          onChange={this.noPreview}
                          defaultChecked={this.state.noPreview || false}
                        />
                        <i />
                      </label>
                    </li> */}
                    <div className="form-group row mx-0 mb-3">
                      <label htmlFor="campaignName" className="col-auto col-form-label text-medium pl-0">
                        Nombre de la campaña:
                                      </label>
                      <ValidationInput
                        className="col-12 col-lg pl-lg-0"
                      >
                        <input
                          type="text"
                          className="form-control"
                          id="campaignName"
                          name="campaignName"
                          onChange={(e) => this.setState({
                            campaignName: e.target.value
                          })}
                          value={this.state.campaignName || ''}
                        />
                      </ValidationInput>
                    </div>
                    <div className="form-group row mx-0 mb-3">
                      <label htmlFor="title" className="col-auto col-form-label text-medium pl-0">
                        Título:
                                      </label>
                      <ValidationInput
                        className="col-12 col-lg pl-lg-0"
                      >
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          name="title"
                          onChange={(e) => this.setState({
                            title: e.target.value
                          })}
                          value={this.state.title || ''}
                        />
                      </ValidationInput>
                    </div>
                    <div className="form-group row mx-0 mb-3">
                      <label htmlFor="shortText" className="col-auto w-100 d-flex justify-content-between align-items-center col-form-label text-medium pl-0">
                        <span>Texto corto:</span>
                        <span style={{ fontSize: 14 }}>{this.state.shortText ? this.state.shortText.length : 0}/120</span> </label>
                      <ValidationInput
                        className="col-12"
                      >
                        <Textarea
                          placeholder="Máximo 120 caracteres."
                          type="text"
                          className="form-control"
                          id="shortText"
                          name="shortText"
                          minRows={4}
                          maxLength="120"
                          onChange={(e) => this.setState({
                            shortText: e.target.value
                          })}
                          value={this.state.shortText || ''}
                        />
                      </ValidationInput>
                    </div>
                    <div className="col mx-0 mb-3">
                      <label htmlFor="text" className="left__label col-auto w-100 d-flex justify-content-between align-items-center col-form-label text-medium pl-0">
                        <span>Descripción:</span>
                        <span style={{ fontSize: 14 }}>{this.state.text ? this.state.text.length : 0}/500</span>
                      </label>
                      <ValidationInput
                        className="col-12"
                      >
                        <ReactQuill
                          type="text"
                          className="left__text-editor"
                          id="text"
                          name="text"
                          minRows={4}
                          placeholder='Máximo 500 caracteres.'
                          onChange={this.onChange}
                          value={this.state.text || ''}
                          modules={MODULES}
                          formats={FORMATS}
                          maxLength='"24"'
                        />
                      </ValidationInput>
                    </div>
                    <div className="form-group row mx-0 mb-4">
                      <label htmlFor="link" className="col-auto col-form-label text-medium pl-0">
                        Enlace:
                                      </label>
                      <ValidationInput
                        className="col-12 col-lg pl-lg-0"
                      >
                        <input
                          type="text"
                          className="form-control"
                          id="link"
                          name="link"
                          onChange={(e) => this.setState({
                            link: e.target.value
                          })}
                          value={this.state.link || ''}
                        />
                      </ValidationInput>
                    </div>
                    <div className="form-group row mx-0 mb-3 flex-column">
                      <div className="col-auto ml-auto">
                        <p className="tour-content-video-form__supported-text">Formatos soportados:</p>
                        <span className="tour-content-video-form__supported-type">
                          {LOGO_YOUTUBE_ICON}
                                      Youtube
                                      </span>
                        <span className="tour-content-video-form__supported-type">
                          {LOGO_VIMEO_ICON}
                                      Vimeo
                                      </span>
                      </div>
                      <label htmlFor="link" className="col-auto col-form-label text-medium pl-0">
                        Url del vídeo:
                                      </label>
                      <ValidationInput
                        className="col-12 col-lg pl-lg-0"
                      >
                        <input
                          type="text"
                          className="form-control"
                          id="link"
                          name="link"
                          onChange={(e) => this.setState({
                            linkVideo: e.target.value
                          })}
                          value={this.state.linkVideo || ''}
                        />
                      </ValidationInput>
                    </div>
                    <h5>Imágenes del carrusel:</h5>
                    <p>Puedes añadir un máximo de 5 imágenes, en formato cuadrado (1300px de lado recomendado) y en "jpg", "png" o "svg".
                                      Para cambiar el orden simplemente arrastra las imágenes.</p>
                    {/* {this.imageParams} */}
                    <div className=" d-lg-block col-lg-4">
                      <FineUploader
                        key={`desktop-uploader-carousel`}
                        endpoint="/images"
                        autoUpload
                        multiple={/* this.props.poi.attributes['poi-type'] === 1 */ true}
                        withUploadBtn={true}
                        params={imageParams}
                        onAllComplete={null}
                        withProgressBars
                        withTotalProgressBar
                        removeThumbAfter={800}
                        imageUploadName={this.showPreviewImagesCampaign}
                        countUpload={true}
                        // lengthPoiImages={this.state.images.length}
                        addBlue={true}
                      >
                        <div
                          className={`
                                      poi-images-box__dropzone ${false
                              ? 'poi-images-box__dropzone--dragging'
                              : ''}
                                        `}
                        /*                           onDragStart={() => handleDraggingState(true)}
                        onDragOver={() => handleDraggingState(false)} */
                        >
                          <img
                            src={UPLOAD_CLOUD_URL}
                            alt="Sube tus imágenes"
                            className="poi-images-box__dropzone-img poi-images-box__dropzone-img--nodrag"
                          />
                          <img
                            src={DROP_COLOR_URL}
                            alt="Sube tus imágenes"
                            className="poi-images-box__dropzone-img poi-images-box__dropzone-img--drag"
                          />
                          <p className="poi-images-box__dropzone-text">Arrastra aquí tus imágenes</p>
                        </div>
                      </FineUploader>
                      {/*  <p className="poi-images-page__total-images">
                                        Total de imagenes:
                                        <span>{images && images.length}</span>
                                      </p> */}
                    </div>
                    <div className='galleryCampaign flex-wrap w-100 d-flex justify-content-start align-items-center'>
                      <SortableContainer
                        onSortEnd={this.handleOnDragEnd}
                        axis="xy"
                        lockToContainerEdges
                        /* useDragHandle */
                        {...sortTrigger}
                      >
                        {isEmpty(this.state.galleryCampaign)
                          ? null
                          : this.state.galleryCampaign.map((image, idx) => (
                            <SortableItem key={`service-image-${image.id}`} index={idx}>
                              <DragHandle>
                                <figure key={idx} className='mr-1 mb-1 dragg'>
                                  <button onClick={() => this.deleteImageCampaign(image)} className="deleteImgGalleryCampaign p-0">
                                    {CIRCLE_CROSS_ICON}
                                  </button>
                                  <img src={image.tinyThumb} alt="" className="imageC" />
                                </figure>
                              </DragHandle>
                            </SortableItem>
                          ))}
                      </SortableContainer>
                    </div>
                  </div>
                  <div className="divisorLateral"></div>
                  <div className="right col-5">
                    <div className="d-flex flex-column align-items-center mb-3">
                      <p className="textBlue mt-5 mb-0">Elementos gráficos sólo necesarios para el patrocinio de destinos o colecciones, no para las rutas.</p>
                      <img
                        src="../../../assets/images/down-arrow.svg"
                        width="45"
                        alt=""
                        className="pointer"
                      />
                    </div>
                    <h5>Logotipo:</h5>
                    <p className="mb-0">Medidas 250px x 180px</p>
                    <p>preferiblemente en archivo SVG.</p>
                    <div className="logotipo">
                      <div className="mr-3 cuadrado logo">
                        {
                          this.state.logoCampaign ? <img src={this.state.logoCampaign} alt="" /> : (
                            <div className="d-lg-block">
                              <FineUploader
                                key={`desktop-uploader-logo`}
                                endpoint="/images"
                                autoUpload
                                multiple={/* this.props.poi.attributes['poi-type'] === 1 */ true}
                                withUploadBtn={true}
                                params={{
                                  imageableId: '00',
                                  imageableType: 'campaign-logo',
                                  imageableProperty: 'image',
                                }}
                                onAllComplete={null}
                                withProgressBars
                                withTotalProgressBar
                                removeThumbAfter={800}
                                imageUploadNameLogo={this.showPreviewImagesCampaignLogo}
                                countUpload={true}
                                addBlue={true}
                              >
                                <div
                                  className={`
                                          poi-images-box__dropzone ${false
                                      ? 'poi-images-box__dropzone--dragging'
                                      : ''}
                                            `}
                                /*                           onDragStart={() => handleDraggingState(true)}
                                onDragOver={() => handleDraggingState(false)} */
                                >
                                  <img
                                    src={UPLOAD_CLOUD_URL}
                                    alt="Sube tus imágenes"
                                    className="poi-images-box__dropzone-img poi-images-box__dropzone-img--nodrag"
                                  />
                                  <img
                                    src={DROP_COLOR_URL}
                                    alt="Sube tus imágenes"
                                    className="poi-images-box__dropzone-img poi-images-box__dropzone-img--drag"
                                  />
                                  <p className="poi-images-box__dropzone-text">Arrastra aquí tus imágenes</p>
                                </div>
                              </FineUploader>
                              {/*  <p className="poi-images-page__total-images">
                                            Total de imagenes:
                                            <span>{images && images.length}</span>
                                          </p> */}
                            </div>
                          )
                        }

                      </div>
                      {
                        this.state.logoCampaign && <img
                          src="../../../assets/images/closeSquare.svg"
                          width="22"
                          alt=""
                          className="pointer cursor"
                          onClick={() => this.setState({ logoCampaign: '' })}
                        />
                      }

                    </div>
                    <h5 className="mt-3">Banner vertical:</h5>
                    <p className="">Medidas 250px x 400px</p>
                    <div className="logotipo">
                      <div className="rectangulo banner">
                        {
                          this.state.bannerCampaign ? <img src={this.state.bannerCampaign} alt="" /> : (
                            <div className="d-lg-block col-lg-4">
                              <FineUploader
                                key={`desktop-uploader-banner`}
                                endpoint="/images"
                                autoUpload
                                multiple={/* this.props.poi.attributes['poi-type'] === 1 */ true}
                                withUploadBtn={true}
                                params={{
                                  imageableId: '00',
                                  imageableType: 'campaign-banner',
                                  imageableProperty: 'image',
                                }}
                                onAllComplete={null}
                                withProgressBars
                                withTotalProgressBar
                                removeThumbAfter={800}
                                imageUploadNameBanner={this.showPreviewImagesCampaignBanner}
                                countUpload={true}
                                // lengthPoiImages={this.state.images.length}
                                addBlue={true}
                              >
                                <div
                                  className={`
                                            poi-images-box__dropzone ${false
                                      ? 'poi-images-box__dropzone--dragging'
                                      : ''}
                                              `}
                                /*                           onDragStart={() => handleDraggingState(true)}
                                onDragOver={() => handleDraggingState(false)} */
                                >
                                  <img
                                    src={UPLOAD_CLOUD_URL}
                                    alt="Sube tus imágenes"
                                    className="poi-images-box__dropzone-img poi-images-box__dropzone-img--nodrag"
                                  />
                                  <img
                                    src={DROP_COLOR_URL}
                                    alt="Sube tus imágenes"
                                    className="poi-images-box__dropzone-img poi-images-box__dropzone-img--drag"
                                  />
                                  <p className="poi-images-box__dropzone-text">Arrastra aquí tus imágenes</p>
                                </div>
                              </FineUploader>
                            </div>
                          )
                        }
                      </div>
                      {
                        this.state.bannerCampaign && <img
                          src="../../../assets/images/closeSquare.svg"
                          width="22"
                          alt=""
                          className="ml-2 pointer cursor"
                          onClick={() => this.setState({ bannerCampaign: '' })}
                        />
                      }

                    </div>
                  </div>
                </div>
              )
            }
            {/* ver campaña */}
            <div className={this.state.seeCampaign ? "d-flex seeCampaign" : "d-none"}>
              <div className="left col-7">
                <h4 className="division2 ml-0 mb-4">{this.state.currentCampaign && this.state.currentCampaign.title}</h4>
                <h5 className="d-flex mb-3">ID único: <h5 className="textBlue ml-2">{this.state.currentCampaign && this.state.currentCampaign.campaingId}</h5></h5>
                <div className="d-flex mb-3">
                  {/* <div className="division4 mr-3 pr-3">
                    {ROUTE_ICON}
                                          214
                                          </div>
                  <div className="division4 mr-3 pr-3">
                    {EYE_ICON}
                                          35K
                                          </div> */}
                </div>
                {/* <li className="left__category-item mb-3">
                                          <label
                                          htmlFor="xdd"
                                          className="custom-checkbox"
                                          >
                                          {"Activar en todos mis destinos patrocinados"}
                                          <input
                                          type="checkbox"
                                          id="xdd"
                                          onChange={this.noPreview}
                                          defaultChecked={this.state.noPreview || false}
                                          />
                                          <i />
                                          </label>
                                        </li> */}
                <div className="form-group row mx-0 mb-3 align-items-center">
                  <label className="col-auto text-medium pl-0">
                    Título:
                                        </label>
                  <h6> {this.state.currentCampaign && this.state.currentCampaign.title}</h6>
                </div>
                <div className="form-group row mx-0 mb-3 align-items-center">
                  <label className="col-auto text-medium pl-0">
                    Nombre:
                                        </label>
                  <h6> {this.state.currentCampaign && this.state.currentCampaign.name}</h6>
                </div>
                <div className="col mx-0 mb-3 pl-0 contentText">
                  <label className="col-auto text-medium pl-0">
                    Texto Corto:
                                        </label>
                  <h6> {this.state.currentCampaign && this.state.currentCampaign.smallText}</h6>
                </div>
                <div className="col mx-0 mb-3 pl-0 contentText">
                  <label className="col-auto text-medium pl-0">
                    Descripción:
                                        </label>
                  <div className='boldTextCampaing' dangerouslySetInnerHTML={{
                    __html: this.state.currentCampaign && this.state.currentCampaign.description
                  }}></div>
                </div>
                <div className="form-group row mx-0 mb-3 align-items-center">
                  <label className="col-auto text-medium pl-0">
                    Enlace:
                                        </label>
                  <h6>{this.state.currentCampaign && this.state.currentCampaign.linkCampaign}</h6>
                </div>
                <div className="form-group row mx-0 mb-3 align-items-center">
                  <label className="col-auto text-medium pl-0">
                    Video:
                                        </label>
                  <h6>{this.state.currentCampaign && this.state.currentCampaign.videoLink}</h6>
                </div>
                <h5>Imágenes del carrusel:</h5>
                <div className='galleryCampaign flex-wrap d-flex justify-content-start align-items-center'>
                  {
                    this.state.galleryCampaign.map((image, i) => {
                      return (
                        <figure key={i} className='mr-1 mb-1'>
                          <img src={image.url} alt="" />
                        </figure>
                      )
                    })
                  }
                </div>

              </div>
              <div className="divisorLateral"></div>
              <div className="right col-5">
                <div className="d-flex flex-column align-items-center mb-3">
                  <button className="btn btn-primary mt-3 mb-2" onClick={() => this.editarCampaign()}>Editar campaña</button>
                  <button className="btn btn-danger" onClick={() => this.deleteCampaign()}>Eliminar campaña</button>
                </div>
                <h5>Logotipo:</h5>
                <p className="mb-0">Medidas 250px x 180px</p>
                <p>preferiblemente en archivo SVG.</p>
                <div className="logotipo">
                  <div className="cuadrado logo">
                    <img src={this.state.currentCampaign && this.state.currentCampaign.logo} alt="" />
                  </div>
                </div>
                <h5 className='mt-3'>Banner vertical:</h5>
                <p className="">Medidas 250px x 400px</p>
                <div className="logotipo">
                  <div className="rectangulo banner">
                    <img src={this.state.currentCampaign && this.state.currentCampaign.banner} alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* añadir patrocinador destino */}
            {
              this.state.assignCampaign && this.state.currentPoi && this.state.currentPoi.id ? <div className="d-flex flex-column assignCampaign">
                <div className="w-100">
                  {
                    this.state.currentPoiCampaign ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <h5 className="mb-2 mr-2 mt-3 textBlue text-center">{this.state.currentPoiCampaign.title}</h5>
                        {/* <img
                          src="../../../assets/images/closeSquare.svg"
                          width="22"
                          alt=""
                          className="mr-2 cursor mt-2"
                          onClick={() => this.removeCampaign(this.state.currentPoiCampaign.id)}
                        /> */}
                      </div>
                    ) : <p className="text-center mt-3">Esta ruta no tiene asignado ningún patrocinador</p>
                  }

                  {
                    this.state.currentPoiCampaign && this.state.currentPoiCampaign.createdBy != this.props.user.id ? <h5 className="mb-2 mr-2 my-3 textBlue text-center">Campaña externa: {this.state.currentPoiCampaign.campaingId}</h5> : null
                  }
                  <button className="btn btn-primary ml-3" onClick={() => this.saveCampaign()}>Crear una nueva campaña</button>
                </div>
                <div>
                  <h4 className="mb-3 mr-2 division2 mt-5">Asigna una campaña ya creada:</h4>
                  {

                    this.state.campaigns.map((campaign, i) => {
                      // let sponsor = this.state.sponsorPoi.find(sponsor => { sponsor.poiId == this.state.currentPoi.id })
                      if (parseInt(this.props.user.id) === parseInt(campaign.createdBy)) {
                        return (
                          <li key={i} className="left__category-item mb-3 ml-5">
                            <label
                              htmlFor={campaign.id}
                              className="custom-checkbox"
                            >
                              {campaign.title}
                              <input
                                style={{ border: '1px solid red' }}
                                type="radio"
                                id={campaign.id}
                                value={campaign.campaingId}
                                onChange={() => {
                                  document.getElementById('idCampaignInput').value = '';
                                  this.setState({ campaignSelectable: campaign.campaingId })
                                }}
                                checked={this.state.campaignSelectable === campaign.campaingId}
                              />
                              <i />
                            </label>
                          </li>
                        )
                      }
                    })
                  }
                  <h4 className="mb-3 mr-2 division2 mt-5">Añade un ID de una campaña de terceros:</h4>
                  <p className="ml-5">Tu patrocinio se actualizará con los  cambios que haga el creador de esa campaña.</p>
                  <div className="form-group row mx-0 mb-3 ml-5">
                    <label htmlFor="idCampaign" className="col-auto col-form-label text-medium pl-0">
                      ID:
                                            </label>
                    <ValidationInput
                      className="col-12 col-lg pl-lg-0"
                    >
                      <input
                        type="text"
                        className="form-control w-50"
                        id="idCampaignInput"
                        name="idCampaign"
                        onChange={(e) => {
                          this.setState({
                            campaignSelectable: e.target.value
                          })
                        }}
                      />
                    </ValidationInput>
                  </div>
                </div>
              </div> : null
            }
            {/* añadir patrocinador ruta */}
            {
              this.state.assignCampaignTour && this.state.currentTour && this.state.currentTour.id ? <div className="d-flex flex-column assignCampaign">
                <div className="w-100 d-flex justify-content-center align-items-center flex-column mt-3">
                  {
                    this.state.currentTourCampaign ? <h5 className="mb-2 mr-2 my-3 textBlue text-center">
                      {this.state.currentTourCampaign.title}
                      <img
                        src="../../../assets/images/closeSquare.svg"
                        width="22"
                        alt=""
                        className="ml-2 cursor mb-1"
                        onClick={() => this.removeCampaignTour(this.state.currentTour.id)}
                      />
                      {
                        this.state.currentTourCampaign && this.state.currentTourCampaign.createdBy != this.props.user.id ? <h5 className="mb-2 mr-2 my-3 textBlue text-center">Campaña externa: {this.state.currentTourCampaign.campaingId}</h5> : null
                      }
                    </h5> : <p className="text-center mt-3">Esta ruta no tiene asignado ningún patrocinador</p>
                  }
                  <button className="btn btn-primary mt-3" onClick={() => this.saveCampaign()}>Crear una nueva campaña</button>
                </div>
                <div>
                  <h4 className="mb-3 mr-2 division2 mt-5">Asigna una campaña ya creada:</h4>
                  {
                    myCampaigns.map((campaign, i) => {
                      // let sponsor = this.state.sponsorPoi.find(sponsor => { sponsor.poiId == this.state.currentPoi.id })
                      if (parseInt(this.props.user.id) === parseInt(campaign.createdBy)) {
                        return (
                          <li key={i} className="left__category-item mb-3 ml-5">
                            <label
                              htmlFor={campaign.id}
                              className="custom-checkbox"
                            >
                              {campaign.title}
                              <input
                                style={{ border: '1px solid red' }}
                                type="radio"
                                id={campaign.id}
                                value={campaign.id}
                                onChange={() => {
                                  document.getElementById('idCampaignInput').value = '';
                                  this.setState({ campaignTourSelectable: campaign.id })
                                }}
                                checked={this.state.campaignTourSelectable === campaign.id}
                              />
                              <i />
                            </label>
                          </li>
                        )
                      }
                    })
                  }
                  <h4 className="mb-3 mr-2 division2 mt-5">Añade un ID de una campaña de terceros:</h4>
                  <p className="ml-5">Tu patrocinio se actualizará con los  cambios que haga el creador de esa campaña.</p>
                  <div className="form-group row mx-0 mb-3 ml-5">
                    <label htmlFor="idCampaign" className="col-auto col-form-label text-medium pl-0">
                      ID:
                                            </label>
                    <ValidationInput
                      className="col-12 col-lg pl-lg-0"
                    >
                      <input
                        type="text"
                        className="form-control w-50"
                        id="idCampaignInput"
                        name="idCampaign"
                        onChange={(e) => {
                          this.setState({
                            campaignTourSelectable: e.target.value
                          })
                        }}
                      />
                    </ValidationInput>
                  </div>
                  {/* <div className="w-100 d-flex justify-content-center align-items-center division3 mt-5">
                    <button onClick={() => this.addCampaignTour()} className="btn btn-primary mr-3">
                      Asignar
                      </button>
                    <button onClick={() => {
                      this.setState({
                        assignCampaignTour: false
                      })
                      this.viewsModal(true, false, false, false)
                    }} className="btn" style={{ border: "1px solid #303030" }}>
                      Volver
                      </button>
                  </div> */}
                </div>
              </div> : null
            }
          </div>
        </Modal>
      </div>
    );
  }
}

ProfileInfo.propTypes = {
  user: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
      surname: PropTypes.string,
      description: PropTypes.string,
      website: PropTypes.string,
    }),
  }),
  toggleEditMode: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  isSmallMobile: PropTypes.bool,
};

ProfileInfo.defaultProps = {
  user: null,
  isSmallMobile: false,
};
function mapStateToProps(state) {
  const imageArray = state.bees.entities.images;
  return {
    isSmallMobile: state.viewport.isXs || state.viewport.isSm,
    imageArray
  };
}

export default connect(mapStateToProps)(ProfileInfo);
