import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TourContentPoiBox from './parts/tour-content-poi-box';
import TourContentServiceBox from './parts/tour-content-service-box';
import TYPES from '../../../../constants/types';
import { isEmpty } from '../../../../utils';
import getRelatedEntitiesFromRedux from '../../../../utils/redux-bee/get-related-entities-from-redux';
import generateCacheResources from '../../../../utils/redux-bee/generate-cache-resources';
import MapBox from '../../../map-box';
import TourContentTextBox from './parts/tour-content-text-box';
import TourContentTimeBox from './parts/tour-content-time-box';
import TourContentVideoBox from './parts/tour-content-video-box';
import TourContentMapBox from './parts/tour-content-map-box';
import TourContentImagesBox from './parts/tour-content-images-box';
import TourContentTipBox from './parts/tour-content-tip-box';

import isEmptyObject from '../../../../utils/isEmptyObject';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../../../loading-spinner';
import Skeleton from '@yisheng90/react-loading';


const NoContentsForTheDay = () => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-12 offset-md-3 col-md-6 tour-file__no-content-wrapper">
        No se encontró contenido para este día
      </div>
    </div>
  </div>
);

function renderMapItinerary(currentTourContent, previousTourContent, pois, currentIdx, selection) {
  if (selection == false || selection == null) {
    if (isEmpty(previousTourContent)) return null;
    const itineraryPois = [];
    const previousPoiId = previousTourContent.relationships.poi.data.id;
    const currentPoiId = currentTourContent.relationships.poi.data.id;
    const previousPoi = pois.filter(poi => !isEmptyObject(poi) && poi.id === previousPoiId)[0];
    const currentPoi = pois.filter(poi => !isEmptyObject(poi) && poi.id === currentPoiId)[0];
    if (!isEmpty(previousPoi) && !isEmpty(currentPoi)) {
      itineraryPois.push(previousPoi);
      itineraryPois.push(currentPoi);
      return (
        <MapBox
          key={`itinerary-${currentTourContent.id}`}
          boxCategoryTitle="Itinerario"
          pois={itineraryPois}
          originLabel={currentIdx}
          showItinerary
        />
      );
    }
  }
  return null;
}

class TourContents extends Component {
  constructor(props) {
    super(props);
    this._requestedMissingResource = false;
    this.state = {
      countTourContent: false,
      visibleTourContents: [],
      items: [],
      hasMore: true,
      countItems: 5,
      poisReady: false,
    }
  }

  componentDidMount() {
    const { notCachedResources } = this.props;
    generateCacheResources(notCachedResources);
    // this.setState
  }

  componentDidUpdate(prevProps) {
    const { notCachedResources } = this.props;
    if (notCachedResources.length && !this._requestedMissingResource) {
      this._requestedMissingResource = true;
      generateCacheResources(notCachedResources);
    }

    if (this.props.tourContents !== prevProps.tourContents && this.props.refresh) {

      this.setState({ hasMore: true, poisReady: false });
      let pois = this.props.pois;
      const visibleTourContents = this.props.tourContents.filter(
        tourContent =>
          tourContent.attributes.visible && isEmpty(tourContent.relationships['tour-content'].data),
      );
      let contentsToBeOrdered = []
      let poisPositions = []

      if (this.props.tour && this.props.tour.attributes.selection) {
        for (let i = 0; i < visibleTourContents.length; i++) {
          if (visibleTourContents[i].attributes['content-type'] == 1) {
            pois.map(poi => {
              if (poi && poi.id && poi.id === visibleTourContents[i].relationships.poi.data.id) {
                contentsToBeOrdered.push({
                  visibleTourContent: visibleTourContents[i],
                  poi: poi.attributes['likes-number'],
                })
                poisPositions.push(i)
              }
            })
          }
        }
        contentsToBeOrdered.sort((a, b) => b.poi - a.poi)
        for (let i = 0; i < poisPositions.length; i++) {
          visibleTourContents[poisPositions[i]] = contentsToBeOrdered[i].visibleTourContent
        }
        pois.sort((a, b) => b.attributes['likes-number'] - a.attributes['likes-number'])
      }
      let poisReady = pois.indexOf(undefined)
      if (poisReady == -1) {
        this.setState({
          visibleTourContents: visibleTourContents,
          items: visibleTourContents.slice(0, 5),
          pois: pois,
          poisReady: true
        })
      } else {
        this.setState({
          visibleTourContents: visibleTourContents,
          items: visibleTourContents,
          pois: pois,
        })
      }
    }
  }

  fetchMoreData = () => {
    if (this.state.items.length >= this.state.visibleTourContents.length) {
      this.setState({ hasMore: false });
      return;
    }
    // a fake async api call like which sends
    // 20 more records in .5 secs
    let countavance = 5
    setTimeout(() => {
      this.setState({
        items: this.state.visibleTourContents.slice(0, ((this.state.items.length + countavance) > this.state.visibleTourContents.length) ? (this.state.items.length + (this.state.visibleTourContents.length - this.state.items.length)) : this.state.items.length + countavance)
      });
    }, 1200);
    this.props.changeDay(false);
  };

  render() {
    const {
      tourContents,
      layoutMode,
      pois,
      tourContentTypeFilter,
      notCachedResources,
      author,
      authorAvatar,
      selectedImage,
      handleShowRecommendation,
      handleImageModal,
      tour,
    } = this.props;



    const {
      countTourContent
    } = this.state;


    let previousTourContent = null;
    if (!tourContents) return <NoContentsForTheDay />;

    const showMapMarkers = !isEmpty(pois) && tourContentTypeFilter === 1 && notCachedResources.length === 0;
    const visibleTourContents = tourContents.filter(
      tourContent =>
        tourContent.attributes.visible && isEmpty(tourContent.relationships['tour-content'].data),
    );

    let orderPosition = null;

    let contentsToBeOrdered = []
    let poisPositions = []

    if (tour && tour.attributes.selection) {
      for (let i = 0; i < visibleTourContents.length; i++) {
        if (visibleTourContents[i].attributes['content-type'] == 1) {
          pois.map(poi => {
            if (poi && poi.id && poi.id === visibleTourContents[i].relationships.poi.data.id) {
              contentsToBeOrdered.push({
                visibleTourContent: visibleTourContents[i],
                poi: poi.attributes['likes-number'],
              })
              poisPositions.push(i)
            }
          })
        }
      }
      contentsToBeOrdered.sort((a, b) => b.poi - a.poi)

      for (let i = 0; i < poisPositions.length; i++) {
        visibleTourContents[poisPositions[i]] = contentsToBeOrdered[i].visibleTourContent
      }
      pois.sort((a, b) => b.attributes['likes-number'] - a.attributes['likes-number'])
    }
    
    return (
      <Fragment key="poi-tour-contents">
        {showMapMarkers ? (
          <MapBox key="tour-day-map" pois={pois} boxCategoryTitle="Mapa de la ruta" displayMode={2} maxZoom={12} />
        ) : null}
        {!isEmpty(visibleTourContents) ? (
          <InfiniteScroll
            dataLength={this.state.items.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={
              (
                <>{this.state.poisReady && !(this.state.items.length >= this.state.visibleTourContents.length) ? (
                  <div className="container tour-content-poi-box ">
                    <div className="row target d-flex justify-content-center">
                      <div className="contentTourLoading column">
                        <Skeleton className="itemTour Tittle" />
                        <Skeleton className="itemTour Description" />
                      </div>
                      <div className="contentSponsorLoading d-flex align-items-center flex-column ">
                        <Skeleton className="itemSponsor itemSponsorTittle" />
                        <Skeleton className="itemSponsor itemSponsorImagen" />
                        <Skeleton className="itemSponsor itemSponsorDesc" />
                        <Skeleton className="itemSponsor itemSponsorDesc" />
                        <Skeleton className="itemSponsor itemSponsorDesc" />
                      </div>
                    </div>
                  </div>
                ) : null}

                </>
              )
            }
          >
            {this.state.poisReady ? (null) : (
              <div className="container tour-content-poi-box ">
                <div className="row target d-flex justify-content-center">
                  <div className="contentTourLoading column">
                    <Skeleton className="itemTour Tittle" />
                    <Skeleton className="itemTour Description" />
                  </div>
                  <div className="contentSponsorLoading d-flex align-items-center flex-column ">
                    <Skeleton className="itemSponsor itemSponsorTittle" />
                    <Skeleton className="itemSponsor itemSponsorImagen" />
                    <Skeleton className="itemSponsor itemSponsorDesc" />
                    <Skeleton className="itemSponsor itemSponsorDesc" />
                    <Skeleton className="itemSponsor itemSponsorDesc" />
                  </div>
                </div>
              </div>

            )}
            {

              this.state.items.map((tourContent, idx) => {
                const tourContentType = tourContent.attributes['content-type'];
                const mapItinerary = layoutMode === 2 && tourContentType === 1
                  ? renderMapItinerary(tourContent, previousTourContent, pois, idx, tour.attributes.selection)
                  : null;

                orderPosition = layoutMode === 1 && tourContentType === 1 ? orderPosition + 1 : orderPosition;
                switch (tourContentType) {
                  case 1:
                    previousTourContent = tourContent;
                    return (
                      <Fragment key={`place-iti-${tourContentType}-${tourContent.id}`}>
                        {mapItinerary}

                        {/* {tourContent.attributes.category == null ? (null) : ( */}
                        {/* <> */}
                        <div className={this.state.poisReady ? "d-block" : "d-none"}>
                          <TourContentPoiBox
                            selectedImage={selectedImage}
                            key={`place-${tourContentType}-${tourContent.id}`}
                            tourContent={tourContent}
                            orderPosition={orderPosition}
                            handleImageModal={handleImageModal}
                            tourIsOfficial={this.props.tour.attributes.official}
                          />
                        </div>
                        {/* </> */}
                        {/* } */}
                        {/* <TourContentPoiBox
                           selectedImage={selectedImage}
                           key={`place-${tourContentType}-${tourContent.id}`}
                           tourContent={tourContent}
                           orderPosition={orderPosition}
                           handleImageModal={handleImageModal}
                         /> */}
                      </Fragment>
                    );
                  case 2:
                    return (
                      <TourContentServiceBox
                        key={`service-${tourContentType}-${tourContent.id}`}
                        tourContent={tourContent}
                        tourContentTypeFilter={tourContentTypeFilter}
                        author={author}
                        authorAvatar={authorAvatar}
                        handleShowRecommendation={handleShowRecommendation}
                      />
                    );
                  case 3:
                    return (
                      <TourContentImagesBox
                        key={`service-${tourContentType}-${tourContent.id}`}
                        tourContent={tourContent}
                      />
                    );
                  case 4:
                    return (
                      <>
                        {tourContent.attributes["header-text"] && tour.attributes.selection ?
                          null :
                          <TourContentTextBox
                            key={`text-${tourContentType}-${tourContent.id}`}
                            tourContent={tourContent}
                          />
                        }

                      </>
                    );
                  case 5:
                    return (
                      <TourContentVideoBox
                        key={`video-${tourContentType}-${tourContent.id}`}
                        tourContent={tourContent}
                      />
                    );
                  case 6:
                    return (
                      <TourContentMapBox
                        key={`map-info-${tourContentType}-${tourContent.id}`}
                        tourContent={tourContent}
                        zoom={12}
                      />
                    );
                  case 7:
                    return (
                      <TourContentTimeBox
                        key={`time-${tourContentType}-${tourContent.id}`}
                        tourContent={tourContent}
                      />
                    );
                  case 8:
                    return (
                      <TourContentTipBox
                        key={`time-${tourContentType}-${tourContent.id}`}
                        tourContent={tourContent}
                      />
                    );
                  default:
                    return null;
                }
              })
            }
          </InfiniteScroll>
        ) : (
            <NoContentsForTheDay />
          )}
      </Fragment>
    );
  }
}

TourContents.propTypes = {
  layoutMode: PropTypes.number.isRequired,
  tourContentTypeFilter: PropTypes.number.isRequired,
  tourContents: PropTypes.arrayOf(TYPES.tourContentType),
  pois: PropTypes.arrayOf(TYPES.poiType),
  selectedImage: TYPES.imageType,
  notCachedResources: PropTypes.arrayOf(PropTypes.object),
  author: TYPES.userType,
  authorAvatar: TYPES.imageType,
  handleShowRecommendation: PropTypes.func.isRequired,
  handleImageModal: PropTypes.func,
};

TourContents.defaultProps = {
  tourContents: [],
  pois: [],
  notCachedResources: [],
  author: null,
  authorAvatar: null,
  selectedImage: null,
  handleImageModal: null,
};

function mapStateToProps(state, ownProps) {
  const { tourContents } = ownProps;
  const notCachedResources = [];

  const stateProps = {
    pois: [],
    notCachedResources,
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };

  if (!isEmpty(tourContents)) {
    tourContents.forEach((tourContent) => {
      const { attributes: { visible = false } = {} } = tourContent;
      const { relationships: { poi: { data: poiData = {} } = {} } = {} } = tourContent;
      if (visible && !isEmpty(poiData)) {
        const [cachedPoi, missingPoiResources] = getRelatedEntitiesFromRedux(tourContent, 'poi', 'getPoi');
        let poi;
        if (!isEmptyObject(cachedPoi)) poi = cachedPoi;
        if (!isEmpty(missingPoiResources)) notCachedResources.push(...missingPoiResources);
        stateProps.pois.push(poi);
      }
    });
  }

  return stateProps;
}

export default connect(mapStateToProps)(TourContents);
