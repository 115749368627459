/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import { Link } from 'react-router-dom';
import TYPES from '../../constants/types';
import { isEmpty, getImageSrcSet } from '../../utils';
import {
  CIRCLE_CROSS_ICON,
  HEART_ICON,
  EYE_ICON,
  TOUR_SIGNAL_ICON,
  MARKER_BASE_CIRCLE_ICON,
} from '../../helpers';
import ResponsiveImg from '../responsive-img';
import { PLACE_PAGE_DINAMIC_URL } from '../../constants';
import ENV from '../../config/environment';
import ModalOnlyUsers from '../modal-log-out';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import { Helmet } from 'react-helmet';
// let poi = null;



class ModalImageFileTemplate extends Component {
  /* constructor(props) {
    super(props);
    //Estado de los modal por defecto
    this.state = {
      showModalLogOut: false
    };
  } */
  state = {
    likes: [],
    showModalLogOut: false,
    facebookControl: false,
    carouselPosition: 0,
  };
  handleChangeCarouselr = (index) => {
    this.setState({ carouselPosition: index });
  }

  modalLogOutOpen = () => {
    this.setState({ showModalLogOut: true })
  }
  handlemodalLogOutClose = () => {
    this.setState({ showModalLogOut: false })
  }
  componentDidMount() {
    window.axios = axios;
    fetch(`${ENV.host}/${ENV.namespace}/images/likesimage/${this.props.image.id}`)
      .then(response => {
        return response.json();
      })
      .then((data) => {
        this.setState({ likes: data.likes, views: data.views });
        this._liked();
      });

    // console.log(api.getcommentoToken)
    // this.get("https://commento.io/api/commenter/token/new", function (resp) {
    //    console.log(resp) 
    //   let tok = resp.commenterToken;
    //   console.log(tok)
    //   function createCORSRequest(method, url) {
    //     var xhr = new XMLHttpRequest();
    //     if ("withCredentials" in xhr) {
    //       xhr.open(method, url, true);
    //     }  else {
    //       xhr = null;
    //     }
    //     return xhr;
    //   }
    //   var xhr = createCORSRequest('GET', `https://commento.io/api/oauth/sso/redirect?commenterToken=${tok}`);
    //   console.log(xhr)
    //   xhr.onload = function () {
    //     var responseText = xhr.responseText;
    //     console.log(responseText);
    //   };
    //   xhr.onerror = function () {
    //     console.log('There was an error!');
    //   };
    //   xhr.withCredentials = false;
    //   xhr.setRequestHeader(
    //     'X-Custom-Header', 'paquito');
    //   xhr.setRequestHeader(
    //     'Access-Control-Allow-Origin', 'https://staging.topdesti.com');
    //   xhr.setRequestHeader(
    //     'Origin', 'staging');
    //   xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, DELETE, PUT");
    //   xhr.setRequestHeader("Access-Control-Allow-Headers", "X-Requested-With, Content-Type, Authorization, Origin, Accept");
    //   xhr.send();
    // })

    // axios.get(api.getcommentoToken)
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });


  }

  // get(url, callback) {
  //   var xmlDoc = new XMLHttpRequest();
  //   xmlDoc.open("GET", url, true);
  //   xmlDoc.onload = function () {
  //     callback(JSON.parse(xmlDoc.response));
  //   };
  //   xmlDoc.send(null);
  // }


  componentDidUpdate() {
    // if (this.props.currentUser) {
    //   console.log(this.props.currentUser)
    //   axios.post(`${ENV.host}/${ENV.namespace}/auth/saveUser`, {
    //     id: this.props.currentUser.token,
    //   })
    //     .then(function (response) {
    //       console.log(response);
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // }
    this._initFacebook()
  }

  _initFacebook() {
    if (this.props.poi) {
      if (this.props.image) {
        if (parseInt(this.props.poi.id) == parseInt(this.props.image.attributes['imageable-id'])) {
          if (!this.state.facebookControl) {
            this.setState({ facebookControl: true })
            setTimeout(() => {
              const script = document.createElement("script");
              script.src = "https://afkar.ams3.cdn.digitaloceanspaces.com/commento_v16.afkar.js";
              script["data-page-id"] = `image-${this.props.image.id}`
              script.async = true;
              document.body.appendChild(script);
            }, 300)
          }

        }
      }
    }

  }

  _commento() {
    axios.get(`${ENV.host}/${ENV.namespace}/auth/commento`)
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }


  componentDidUpdate() {
    this._initFacebook()
  }

  _initFacebook() {
    if (this.props.poi) {
      if (this.props.image) {
        if (parseInt(this.props.poi.id) == parseInt(this.props.image.attributes['imageable-id'])) {
          if (!this.state.facebookControl) {
            this.setState({ facebookControl: true })
            setTimeout(() => {
              const script = document.createElement("script");
              script.src = "https://afkar.ams3.cdn.digitaloceanspaces.com/commento_v16.afkar.js";
              script.async = true;
              document.body.appendChild(script);
            }, 300)
          }

        }
      }
    }

  }

  // componentDidUpdate() {
  //   if( this.props.poi && this.props.poi.id == this.props.image['imageable-id']) {
  //     poi = this.props.poi
  //     console.log(poi)
  //   }
  // }

  handleLike = () => {
    this.setState({ liked: !this.state.liked });
    fetch(`${ENV.host}/${ENV.namespace}/images/likeimage`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        imageId: this.props.image.id,
        userId: this.props.currentUser.currentUser.id,
        toptraveler: this.props.currentUser.currentUser.attributes.toptraveler,
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({ likes: data.likes });
      });
  };

  _liked = () => {
    if (this.props.currentUser.isAuthenticated && this.props.currentUser.currentUser) {
      let liked = Object.values(this.state.likes).find(
        like => like.userId === Number(this.props.currentUser.currentUser.id),
      );
      this.setState({ liked: liked });
    } else {
      this.setState({ liked: false });
    }
  };

  render() {
    const {
      isOpen,
      closeModal,
      poi,
      image,
      poiCover,
      author,
      authorAvatar,
      currentUrl,
    } = this.props;
    const {
      name,
      description,
      'likes-number': likesNumber,
      'tours-counter': toursCounter,
      visualizations,
      'imageable-id': relPoi,
    } = image.attributes;
    const images = [image];

    const { attributes: { title: poiTitle = null, 'url-title': urlTitle } = {} } = poi || {};

    const { attributes: { name: authorName = null, surname: authorSurname = null } = {} } =
      author || {};

    var showModal =
      this.props.poi && this.props.image && this.props.poi.id == relPoi ? true : false;

    let metaImage = image.attributes && image.attributes["small-thumb"] ? image.attributes["small-thumb"] : image.attributes.smallThumb;
    let genericTextTopdesti = "Topdesti es una plataforma para crear y compartir de forma sencilla rutas de tus viajes y donde podrás además ganar dinero con ellas";
    let genericTitleTopdesti = "Encuentra, comparte y gana dinero con tus viajes y Topdesti";
    let titlePoi = this.props.poi && this.props.poi.attributes && this.props.poi.attributes.title ? this.props.poi.attributes.title : genericTitleTopdesti;
    let descriptionPoi = this.props.poi && this.props.poi.attributes && this.props.poi.attributes.description ? this.props.poi.attributes.description : genericTextTopdesti;
    return (
      <>
        {showModal && (
          <>
            <Helmet>
              <meta property="og:url" content={currentUrl} />
              <meta name="title" content={name ? name : titlePoi} />
              <meta property="og:title" content={name ? name : titlePoi} />
              <meta property="twitter:title" content={name ? name : titlePoi} />
              <meta property="description" content={description ? description : descriptionPoi} />
              <meta property="og:description" content={description ? description : descriptionPoi} />
              <meta property="twitter:description" content={description ? description : descriptionPoi} />
              <meta property="og:url" content={currentUrl} />
              <meta property="og:image" content={metaImage} />
              <meta property="og:image:secure_url" content={metaImage} />
              <meta property="og:image:width" content={isEmpty(metaImage) ? '132' : '600'} />
              <meta property="og:image:height" content={isEmpty(metaImage) ? '38' : '399'} />
              <meta property="twitter:url" content={currentUrl} />
              <meta property="twitter:image" content={metaImage} />
            </Helmet>
            <Modal
              className="modal-image-file"
              overlayClassName="modal-image-file__overlay"
              name="warningModal"
              isOpen={isOpen}
              onRequestClose={closeModal}
            >
              <div className="modal-image-file__wrapper">
                <figure className="modal-image-file__header">
                  <button
                    type="button"
                    className="modal-image-file__close-btn"
                    onClick={closeModal}
                    title="Cerrar"
                  >
                    {/* {CIRCLE_CROSS_ICON} */}
                    <img src="/assets/images/cerrar_negro.svg" alt="cerrar" />
                  </button>
                  <figure className="modal-image-file__wrapper">
                    <div className="overlayModal"></div>
                    {/* <Carousel
                    showThumbs={false}
                    showStatus={false}
                    emulateTouch
                    onChange={this.handleChangeCarousel}
                    selectedItem={this.state.carouselPosition}
                  >
                    {images.map(image => {
                      const srcSetArray = getImageSrcSet(image);
                      console.log(srcSetArray)
                      return (
                        <div key={`carousel-img-${image.id}`}>
                          <ResponsiveImg
                            className="modal-image-file__image"
                            srcSet={getImageSrcSet(image)}
                            // TODO: Improve this solution, it works with images with 4:3 ratio only
                            forzedElementWidth={
                              Math.max(document.documentElement.clientWidth, window.innerWidth || 0) *
                              0.8 *
                              0.66
                            }
                          />
                        </div>
                      );
                    })}
                  </Carousel> */}
                    <ResponsiveImg
                      className="modal-image-file__image"
                      srcSet={getImageSrcSet(image)}
                      // TODO: Improve this solution, it works with images with 4:3 ratio only
                      forzedElementWidth={
                        Math.max(document.documentElement.clientWidth, window.innerWidth || 0) *
                        0.8 *
                        0.66
                      }
                    />
                  </figure>
                  {isEmpty(author) ? null : (
                    <div className="modal-image-file__user">
                      <span className="modal-image-file__autor">Autor: </span>
                      <ResponsiveImg
                        srcSet={getImageSrcSet(authorAvatar)}
                        className="circular-img"
                        wrapperClassName="d-inline-block"
                      />
                      <span className="modal-image-file__name">{`${authorName} ${authorSurname}`}</span>
                    </div>
                  )}
                </figure>
                <div className="container-fluid modal-image-file__content">
                  <div className="row">
                    <div className="col-12 col-md-9 modal-image-file__content-left">
                      <section className="row">
                        <div>
                          <p>COMPARTE</p>
                          <FacebookShareButton
                            className="modal-image-file__rrss-link"
                            url={currentUrl}
                          >
                            <FacebookIcon size={50} round />
                          </FacebookShareButton>

                          <TwitterShareButton
                            className="modal-image-file__rrss-link"
                            url={currentUrl}
                            title={isEmpty(name) ? '' : name}
                          >
                            <TwitterIcon size={50} round />
                          </TwitterShareButton>

                          <WhatsappShareButton
                            className="modal-image-file__rrss-link"
                            url={currentUrl}
                          >
                            <WhatsappIcon size={50} round />
                          </WhatsappShareButton>
                        </div>
                        {this.props.currentUser.isAuthenticated ? (
                          <div>
                            <div className="modal-image-file__rrss-link likePoi">
                              <p>ME GUSTA</p>
                              <button onClick={this.handleLike} className="buttonLike">
                                <img
                                  alt="like poi button"
                                  src={
                                    this.state.liked
                                      ? '../../../assets/images/heart.svg'
                                      : '../../../assets/images/heart-outline.svg'
                                  }
                                />
                              </button>
                            </div>
                            {/* <div className="modal-image-file__rrss-link">
                            <p>COMENTA</p>
                            <button className="buttonComment">
                              <img
                                alt="comment poi button"
                                src="../../../assets/images/comment.svg"
                              />
                            </button>
                          </div> */}
                          </div>
                        ) : (
                            <div>
                              <div className="modal-image-file__rrss-link likePoi">
                                <p>ME GUSTA</p>
                                <button onClick={this.modalLogOutOpen} className="buttonLike">
                                  <img
                                    alt="like poi button"
                                    src={
                                      this.state.liked
                                        ? '../../../assets/images/heart.svg'
                                        : '../../../assets/images/heart-outline.svg'
                                    }
                                  />
                                </button>
                              </div>
                              {/* <div className="modal-image-file__rrss-link">
                            <p>COMENTA</p>
                            <button className="buttonComment">
                              <img
                                alt="comment poi button"
                                src="../../../assets/images/comment.svg"
                              />
                            </button>
                          </div> */}
                            </div>
                          )}
                        <ModalOnlyUsers
                          modalLogOut={this.state.showModalLogOut}
                          modalLogOutClose={this.handlemodalLogOutClose}
                          url={true}
                        />
                      </section>
                      <div className="row">
                        <div className="col-12 col-md-8">
                          {isEmpty(name) ? null : <h2 className="f-quicksand">{name}</h2>}
                          <p className="toplinks">
                            {HEART_ICON}
                            {this.state.likes.length}
                            {EYE_ICON}
                            {this.state.views ? this.state.views : 0}
                            {TOUR_SIGNAL_ICON}
                            {isEmpty(toursCounter) ? 0 : toursCounter}
                          </p>
                          {isEmpty(description) ? null : (
                            <p className="modal-image-file__description" dangerouslySetInnerHTML={{ __html: description }}></p>
                          )}
                          {/* <p className="hashtags">
                    <span>#Teide</span>
                    {' '}
                    <span>#Tenerife</span>
                    {' '}
                    <span>#parquenatural</span>
                    {' '}
                    <span>#volcan</span>
                  </p> */}
                        </div>
                        {/* HIDE UNTIL ACTIONS (LIKE, REPORT, ETC.) IMPLEMENTATION */}
                        {/* <div className="col-12 col-md-4 text-center">
                  <button
                    type="button"
                    className="btn btn-light btn-rounded modal-image-file__action-btn"
                  >
                    Me gusta
                  </button>
                  <button
                    type="button"
                    className="btn btn-light btn-rounded modal-image-file__action-btn"
                  >
                    Añadir a mis rutas
                  </button>
                  <button
                    type="button"
                    className="btn btn-light btn-rounded modal-image-file__action-btn"
                  >
                    Añadir a favoritos
                  </button>
                  <button
                    type="button"
                    className="btn btn-light btn-rounded modal-image-file__action-btn"
                  >
                    Enviar a un amigo
                  </button>
                  <button
                    type="button"
                    className="btn btn-light btn-rounded modal-image-file__action-btn"
                  >
                    Denunciar contenido
                  </button>
                </div> */}
                      </div>
                    </div>
                    <div className="col-10 mx-auto mx-lg-0 col-md-3 modal-image-file__content-right p-5">
                      <p className="modal-image-file__poi-title">
                        {MARKER_BASE_CIRCLE_ICON}
                      Incluída en la ficha de:
                    </p>
                      <Link
                        to={PLACE_PAGE_DINAMIC_URL(urlTitle)}
                        className="modal-image-file__poi-link"
                      >
                        <ResponsiveImg
                          srcSet={getImageSrcSet(this.props.images && this.props.images.length > 0 ? this.props.images[0] : undefined)}
                          className="modal-image-file__poi-cover"
                        >
                          <figcaption>{poiTitle}</figcaption>
                        </ResponsiveImg>
                      </Link>
                      <h5 className="tour-content-poi-box__partner-call mt-4">
                        {this.props.poiCampaign ? this.props.poiCampaign.title : ""}
                      </h5>
                      <img
                        src={this.props.poiCampaign ? this.props.poiCampaign.logo : "/assets/images/test-partner.svg"}
                        alt="partner logo"
                        className="modal-image-file__partner-logo"
                      />
                      <div className="d-flex justify-content-center mt-2">
                        <h5 className="tour-content-poi-box__partner-description">
                          {this.props.poiCampaign ? this.props.poiCampaign.smallText : ""}
                        </h5>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center comments">
                      {/* <FacebookProvider appId="133057720074395">
                      <Comments href="http://topdesti.com" />
                    </FacebookProvider> */}
                      {/* <div class="fb-comments" data-href={URLactual} data-width="90%" data-numposts="5"></div> */}
                      {/* <div className="commentbox"></div> */}
                      <div id="commento"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </>
        )}
      </>
    );
  }
};

ModalImageFileTemplate.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  image: TYPES.imageType,
  poi: TYPES.poiType,
  poiCover: TYPES.imageType,
  author: TYPES.userType,
  authorAvatar: TYPES.imageType,
  currentUrl: PropTypes.string,
};

ModalImageFileTemplate.defaultProps = {
  image: null,
  poi: null,
  poiCover: null,
  author: null,
  authorAvatar: null,
  currentUrl: null,
};

function mapStateToProps(state) {
  const currentUrl = window.location.href;
  const currentUser = state.auth;
  /*   const pois = state.bees.entities.pois ? Object.values(state.bees.entities.pois) : [];
    const poisImages = pois ? pois[0].attributes.images : "";
    poisImages && poisImages.sort((a, b) => b.likes - a.likes)
    let poiMainImage = poisImages[0]; */

  return {
    currentUrl,
    currentUser,
  };
}

export default connect(mapStateToProps)(ModalImageFileTemplate);
