import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '../utils';

function ValidationInput(props) {
  const { errorMsg, children, ...wrapperProps } = props;
  wrapperProps.className += ` validation-input ${!isEmpty(errorMsg) ? ' validation-input--error' : ''}`;

  return (
    <div {...wrapperProps}>
      {children}
      {!isEmpty(errorMsg) ? <p className="text-danger mb-2">{errorMsg}</p> : null}
    </div>
  );
}

ValidationInput.propTypes = {
  errorMsg: PropTypes.string,
  children: PropTypes.element.isRequired,
};

ValidationInput.defaultProps = {
  errorMsg: null,
};

export default ValidationInput;
