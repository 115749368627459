import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, getImageSrcSet } from '../../../../../utils';
import {
  EYE_ICON, CLOSE_ICON, PLUS_ICON, EYE_NOT_ICON,
} from '../../../../../helpers';
import TYPES from '../../../../../constants/types';
import { ResponsiveImg, PromiseButton } from '../../../../../components';

const TourConfigTipsTemplate = (props) => {
  const {
    tourContentTips,
    tipCovers,
    handleToggleVisibility,
    handleDelete,
    handleContentSelection,
    handleCreateNewTip,
  } = props;

  return (
    <section className="row tour-config-tips">
      <div className="col-12">
        <ul className="tour-config-tips__list">
          {isEmpty(tourContentTips)
            ? (
              <>
                <div className="col-12">
                  <h4 className="text-center">La ruta aún no tiene consejos de viaje.</h4>
                  <p className="text-center">¿Quieres crear uno?</p>
                </div>
                <div className="col-10 col-md-4 col-xl-3 mx-auto">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={handleCreateNewTip}
                  >
                    Crear consejo
                  </button>
                </div>
              </>
            )
            : tourContentTips.map((tourContentTip) => {
              const {
                attributes: { visible: isVisible },
                relationships: {
                  archives: { data: tourContentArchivesData },
                },
              } = tourContentTip;
              const coverImage = !isEmpty(tourContentArchivesData) && !isEmpty(tipCovers)
                ? tipCovers.filter(cover => cover.id === tourContentArchivesData[0].id)[0]
                : null;
              return (
                <li
                  key={`image-tour-content-${tourContentTip.id}`}
                  className="tour-config-tips__list-item"
                >
                  <PromiseButton
                    type="button"
                    className="tour-config-tips__list-show"
                    onClick={() => handleToggleVisibility(tourContentTip)}
                    title={`${isVisible ? 'Ocultar consejo' : 'Mostrar consejo'}`}
                    primarySpinner
                  >
                    {EYE_ICON}
                  </PromiseButton>
                  <PromiseButton
                    type="button"
                    className="tour-config-tips__list-remove"
                    onClick={() => handleDelete(tourContentTip)}
                    title="Eliminar consejo"
                    primarySpinner
                  >
                    {CLOSE_ICON}
                  </PromiseButton>

                  <button
                    type="button"
                    className="tour-config-tips__list-btn"
                    onClick={() => handleContentSelection(tourContentTip)}
                  >
                    <ResponsiveImg
                      srcSet={getImageSrcSet(coverImage)}
                      alt="Imagen"
                      wrapperClassName="h-100"
                      className="tour-config-tips__list-img"
                    />
                    {isVisible ? null : (
                      <div className="tour-config-tips__no-visible-layer">{EYE_NOT_ICON}</div>
                    )}
                  </button>

                </li>
              );
            })
          }
          {isEmpty(tourContentTips) ? null : (
            <li className="tour-config-tips__list-item">
              <button
                type="button"
                className="tour-config-tips__list-add"
                onClick={handleCreateNewTip}
              >
                {PLUS_ICON}
              </button>
            </li>
          )}
        </ul>
      </div>
    </section>
  );
};

TourConfigTipsTemplate.propTypes = {
  tourContentTips: PropTypes.arrayOf(TYPES.tourContentType),
  tipCovers: PropTypes.arrayOf(TYPES.imageType),
  handleToggleVisibility: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleContentSelection: PropTypes.func.isRequired,
  handleCreateNewTip: PropTypes.func.isRequired,
};

TourConfigTipsTemplate.defaultProps = {
  tipCovers: [],
  tourContentTips: null,
};

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
  };
}

export default connect(mapStateToProps)(TourConfigTipsTemplate);
