import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import api from '../../config/api-endpoints';
import reduxBeeRequestErrorHandler from '../../utils/redux-bee/redux-bee-request-error-handler';
import handleError from '../../utils/errorHandler';
import reduxBeeMakeRequest from '../../utils/redux-bee/redux-bee-make-request';
import { isEmpty, orderPoiImages, isEmptyObject } from '../../utils';
import PoiPageTemplate from './poi-page-template';
import { NOT_FOUND_URL, PLACE_PAGE_DINAMIC_URL, PLACE_IMAGE_PAGE_DINAMIC_URL } from '../../constants';
import TYPES from '../../constants/types';

// const PoiPage = (props) => {
//   const { poiId } = props;

//   const poi = useGetResource({id:poiId}, 'pois', 'getPoi');
//   const { relationships: { owner: { data: { id: poiOwnerId = null } = {} } = {} } = {} } = poi || {};
//   const poiOwner = useGetResource({id:poiOwnerId}, 'users', 'getUser');
//   const poiImages = useGetRelationshipResource(poi, 'images', 'images', 'getPoiImages');
//   return null;
//   return isEmpty(poi) ? null : (
//     <PoiPageTemplate
//       poi={poi}
//       poiImages={poiImages}
//       poiOwner={poiOwner}
//       poiOwnerAvatar={poiOwnerAvatar}
//       selectedImage={selectedImage}
//       handleImageModal={this.handleImageModal}
//     />
//   );
// };

var currentUser;

class PoiPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poi: null,
      storedScroll: 0,
    };

    this.getPopulatedPoi = this.getPopulatedPoi.bind(this);
    this.getPopulatedServerPoi = this.getPopulatedServerPoi.bind(this);
    this.handleImageModal = this.handleImageModal.bind(this);
  }

  componentDidMount() {
    const { getPopulatedServerPoi } = this;
    getPopulatedServerPoi().then((populatedPoi) => {
      this.setState({ poi: populatedPoi });
    }).catch((error) => { this.setState(() => { throw error; }); });
  }

  componentDidUpdate(prevProps) {
    const { selectedImage: prevSelectedImage } = prevProps;
    const { selectedImage: currentSelectedImage } = this.props;
    const { storedScroll } = this.state;
    if (!isEmpty(prevSelectedImage) && isEmpty(currentSelectedImage)) {
      window.scrollTo(0, storedScroll);
    }
  }

  getPopulatedPoi(poi) {
    return new Promise((resolve, reject) => {
      if (!poi) return reject(new Error('No poi to populate received!'));

      const { dispatch } = this.props;
      const {
        'main-image': { data: mainImageRelationshipData = null } = {},
        images: { data: imagesData = null } = {},
        owner: ownerRelationship,
      } = poi.relationships;
      let populatedPoi = { ...poi };

      const poiImagesPromise = reduxBeeMakeRequest(dispatch, 'getPoiImages', { id: poi.id });

      const poiTagsPromise = reduxBeeMakeRequest(dispatch, 'getPoiTags', { id: poi.id });

      const coverImageData = isEmpty(mainImageRelationshipData)
        ? imagesData[0]
        : mainImageRelationshipData;

      const poiMainImagePromise = isEmpty(coverImageData)
        ? Promise.resolve(null)
        : reduxBeeMakeRequest(dispatch, 'getImage', { id: coverImageData.id });

      const poiOwnerPromise = reduxBeeMakeRequest(dispatch, 'getUser', {
        id: ownerRelationship.data.id,
      });

      return Promise.all([poiImagesPromise, poiTagsPromise, poiMainImagePromise, poiOwnerPromise])
        .then(([images, tags, mainImage, owner]) => {
          const orderedImages = orderPoiImages(poi, images);
          populatedPoi = {
            ...populatedPoi,
            relatedImages: [...orderedImages],
            relatedTags: [...tags],
            relatedMainImage: mainImage,
            poiOwner: { ...owner },
          };
          const { avatar: ownerAvatar } = owner.relationships;

          if (!isEmpty(ownerAvatar.data) && !isEmpty(ownerAvatar.data.id)) {
            return reduxBeeMakeRequest(dispatch, 'getImage', { id: ownerAvatar.data.id });
          }
          return Promise.resolve(null);
        })
        .then((ownerAvatar) => {
          populatedPoi.poiOwnerAvatar = { ...ownerAvatar };
          resolve(populatedPoi);
        })
        .catch(handleError);
    });
  }

  getPopulatedServerPoi() {
    const { getPopulatedPoi } = this;
    const { dispatch, urlTitle } = this.props;
    return dispatch(api.getPois({ 'filters[urlTitle]': urlTitle, 'filters[state]': 2 }))
      .then(reduxBeeRequestErrorHandler)
      .then((poisResultArray) => {
        if (Array.isArray(poisResultArray)) {
          if (poisResultArray.length === 0) {
            // todo dispatch push 404
            dispatch(push(NOT_FOUND_URL));
            return Promise.resolve(null);
          }
          return getPopulatedPoi(poisResultArray[0]);
        }
        return Promise.resolve(null);
      });
  }

  handleImageModal(imageId) {
    const { urlTitle, dispatch, selectedImage } = this.props;
    if (isEmpty(imageId)) {
      dispatch(push(PLACE_PAGE_DINAMIC_URL(urlTitle)));
    } else {
      // Store the current scroll to restore it when the image file modal will be closed.
      if (isEmpty(selectedImage)) {
        this.setState({ storedScroll: window.scrollY });
      }

      dispatch(push(PLACE_IMAGE_PAGE_DINAMIC_URL(urlTitle, imageId)));
    }
  }

  render() {
    const { mainImage, location, selectedImage } = this.props;
    const { poi, isImageModalOpen } = this.state;
    const { poiOwner = null, poiOwnerAvatar = null } = poi || {};
    // const ContextualActions = () => (
    //   /* TODO: Añadir add poi as tour content to the tour in edition in the current day */
    //   <ul className="navbar-nav d-lg-none navbar-contextuals">
    //     <li className="nav-item">
    //       <button type="button" className="nav-link" title="Guardar">
    //         {PLUS_ICON}
    //       </button>
    //     </li>
    //   </ul>
    // );
    const { attributes: { title: poiTitle, description: poiDescription } = {} } = poi || {};
    const {
      attributes: {
        'small-thumb': selectedImageUrl = null,
        name: selectedImageName,
        description: selectedImageDesc,
      } = {},
    } = selectedImage || {};
    const {
      attributes: {
        'small-thumb': mainImageUrl = null,
      } = {},
    } = mainImage || {};
    const poiImageUrl = isEmpty(mainImageUrl)
      ? `${window.location.origin}/assets/images/topdesti-logo.png`
      : mainImageUrl;
    const metaTitle = isEmpty(selectedImage) || isEmpty(selectedImageName) ? poiTitle : selectedImageName;
    const metaDescription = isEmpty(selectedImage) || isEmpty(selectedImageDesc) ? poiDescription : selectedImageDesc;
    const metaImageUrl = isEmpty(selectedImage) ? poiImageUrl : selectedImageUrl;
    /* console.log(metaImageUrl) */
    return (
      <>
        {isEmpty(poi) ? null : (
          <>
            <Helmet>
              <title>{`| ${poiTitle}`}</title>
              <meta property="og:url" content={location.href} />
              {isEmpty(poiTitle) ? null : <meta name="title" content={metaTitle} />}
              {isEmpty(poiTitle) ? null : <meta property="og:title" content={metaTitle} />}
              {isEmpty(poiTitle) ? null : <meta property="twitter:title" content={metaTitle} />}
              {isEmpty(metaDescription) ? null : (
                <meta property="description" content={metaDescription} />
              )}
              {isEmpty(metaDescription) ? null : (
                <meta property="og:description" content={metaDescription} />
              )}
              {isEmpty(metaDescription) ? null : (
                <meta property="twitter:description" content={metaDescription} />
              )}
              <meta property="og:url" content={location.href} />
              <meta property="og:image" content={metaImageUrl} />
              <meta property="og:image:secure_url" content={metaImageUrl} />
              <meta property="og:image:width" content={isEmpty(mainImageUrl) ? '132' : '600'} />
              <meta property="og:image:height" content={isEmpty(mainImageUrl) ? '38' : '399'} />
              <meta property="twitter:url" content={location.href} />
              <meta property="twitter:image" content={metaImageUrl} />
            </Helmet>
            <PoiPageTemplate
              poi={poi}
              currentUser={currentUser}
              poiOwner={poiOwner}
              poiOwnerAvatar={poiOwnerAvatar}
              selectedImage={selectedImage}
              isImageModalOpen={isImageModalOpen}
              handleImageModal={this.handleImageModal}
            />
          </>
        )}
      </>
    );
  }
}

PoiPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  urlTitle: PropTypes.string.isRequired,
  mainImage: TYPES.imageType,
  selectedImage: TYPES.imageType,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired,
    protocol: PropTypes.string.isRequired,
  }),
};

PoiPage.defaultProps = {
  mainImage: null,
  selectedImage: null,
  location: null,
};

function mapStateToProps(state, ownProps) {
  currentUser = state.auth;
  const { match: { params: { urlTitle = null, imageId = null } } } = ownProps;
  const { bees: { entities: { pois = null, images: storedImages = null } } } = state;
  const { location } = window;
  const poi = !isEmptyObject(urlTitle) && !isEmptyObject(pois) ? Object.entries(pois).reduce(
    (matchedPoi, [cachedPoiId, cachePoi]) => {
      const {
        attributes: {
          'url-title': cacheUrlTitle,
        } = {},
      } = cachePoi;
      if (cacheUrlTitle === urlTitle) {
        return cachePoi;
      }
      return matchedPoi;
    },
    null,
  ) : null;
  const { id: poiId = null, relationships: { 'main-image': { data: mainImageData = null } = {} } = {} } = poi || {};
  const mainImage = isEmpty(mainImageData) || isEmpty(storedImages) ? null : storedImages[mainImageData.id];
  const selectedImage = isEmpty(imageId) || isEmpty(storedImages) ? null : storedImages[imageId];

  // TODO refactor to pass the poi here and move the "population" dependencies to sub-components
  return {
    poiId,
    urlTitle,
    mainImage,
    selectedImage,
    location,
  };
}

export default connect(mapStateToProps)(PoiPage);
