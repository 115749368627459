import React from 'react';
import { PropTypes } from 'prop-types';

const BootstrapLoader = (props) => {
  const { extraClasses } = props;
  return (
    <div className={`d-flex justify-content-center ${extraClasses}`}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

BootstrapLoader.propTypes = {
  extraClasses: PropTypes.string,
};
BootstrapLoader.defaultProps = {
  extraClasses: '',
};
export default BootstrapLoader;
