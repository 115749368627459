import { PropTypes } from 'prop-types';

const meType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['me']),
  attributes: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    'active-subscription': PropTypes.bool,
    description: PropTypes.string,
    website: PropTypes.string,
    'facebook-profile': PropTypes.string,
    'images-counter': PropTypes.string,
    'instagram-profile': PropTypes.string,
    'pinterest-profile': PropTypes.string,
    'twitter-profile': PropTypes.string,
    'vimeo-profile': PropTypes.string,
    'youtube-profile': PropTypes.string,
    'destination-pois-counter': PropTypes.string,
    'service-pois-counter': PropTypes.string,
    'tours-counter': PropTypes.string,
  }),
});

export default meType;
