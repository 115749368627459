import isEmpty from '../is-empty';

export default function reduxBeeRequestErrorHandler(request) {
  return new Promise((resolve, reject) => {
    if (isEmpty(request) || !isEmpty(request.stack)) reject(new Error('Ajax request could not be performed'));
    const { status } = request;
    // request statuses not 2xx indicates errors, reject with server data
    if (status === 204) resolve();

    const {
      body: { data: requestData = null } = {},
    } = request || {};

    if (status > 299 && status < 200) reject(requestData);
    // if (included) requestData.included = included;
    resolve(requestData);
  });
}
