import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PlacesAutocomplete from 'react-places-autocomplete';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import isEmpty from '../../../../utils/is-empty';
import {
  FILTERS_ICON,
  EYE_ICON,
  CENTER_ICON,
  MAGNIFYING_ICON,
  SERVICES_CIRCLE_ICON,
  DESTINY_BLUE,
  INFO_CIRCLE_ICON,
} from '../../../../helpers/svg-inline';
import FiltersModal from './parts/filters-modal';
import { CATEGORY_OPTIONS, SERVICE_CATEGORIES } from '../../../../constants/enums.constants';
import { history } from '../../../../helpers';

class SearchFilters extends React.Component {
  static getCategoryLabelFromValue(value) {
    const allTypeCategories = [...CATEGORY_OPTIONS, ...SERVICE_CATEGORIES];
    const foundCagetory = allTypeCategories.filter(
      option => parseInt(option.value, 10) === parseInt(value, 10),
    );
    if (foundCagetory.length === 1) return foundCagetory[0].label;
    return '';
  }

  constructor(props) {
    super(props);
    this.state = {
      showFiltersModal: false,
      addressInputValue: '',
    };

    this.handleFiltersModal = this.handleFiltersModal.bind(this);
    this.renderAutoCompleteFunction = this.renderAutoCompleteFunction.bind(this);
    this.renderMobileAutoCompleteFunction = this.renderMobileAutoCompleteFunction.bind(this);
    this.handleGooglePlacesChange = this.handleGooglePlacesChange.bind(this);
    this.handleGooglePlacesSelect = this.handleGooglePlacesSelect.bind(this);
    this.handleTypeFilter = this.handleTypeFilter.bind(this);
    this.handleDaySelectChange = this.handleDaySelectChange.bind(this);
    this.generateDayOptions = this.generateDayOptions.bind(this);
  }
  componentDidMount(){
    const { urlQueryParams } = this.props;
    urlQueryParams.set('searchType', 1);
    urlQueryParams.delete('cat');
    history.push({
      search: `?${urlQueryParams.toString()}`,
    });
  }

  handleFiltersModal(value) {
    this.setState({
      showFiltersModal: value,
    });
  }

  handleGooglePlacesChange(inputValue) {
    this.setState({
      addressInputValue: inputValue,
    });
  }

  handleGooglePlacesSelect(address, selectionId) {
    const { handleGooglePlacesSelect } = this.props;
    this.setState({
      addressInputValue: address,
    });
    handleGooglePlacesSelect(address, selectionId);
  }

  handleTypeFilter(poiType) {
    const { urlQueryParams } = this.props;
    urlQueryParams.set('searchType', poiType);
    urlQueryParams.delete('cat');
    history.push({
      search: `?${urlQueryParams.toString()}`,
    });
  }

  generateDayOptions() {
    const { tourDuration, handleAddDayToTour } = this.props;
    const options = [];

    for (let i = 1; i <= tourDuration; i += 1) {
      options.push({ value: i, label: `Día ${i}` });
    }
    if (handleAddDayToTour) options.push({ value: 'new', label: 'Añadir día' });

    return options;
  }

  handleDaySelectChange({ value }) {
    const { handleDaySelection, handleAddDayToTour, activeDay } = this.props;
    let newlySelectedDay;

    if (value === 'new') {
      newlySelectedDay = handleAddDayToTour ? handleAddDayToTour() : activeDay;
    } else newlySelectedDay = value;

    handleDaySelection(newlySelectedDay);
  }

  renderAutoCompleteFunction({
    getInputProps, suggestions, getSuggestionItemProps, loading,
  }) {
    const { handleGooglePlacesOnKeyDown } = this.props;
    return (
      <>
        <input
          {...getInputProps({
            placeholder: '¿Dónde quieres buscar?',
            className: 'form-control  search-filters__search-input',
            id: 'search-inp',
            onKeyDown: handleGooglePlacesOnKeyDown,
          })}
        />
        {!isEmpty(suggestions) ? (
          <div className="poi-position-modal__suggestions">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = `
                  poi-position-modal__suggestions-item ${
                suggestion.active ? 'poi-position-modal__suggestions-item--active' : ''
                }`;
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        ) : null}
      </>
    );
  }

  renderMobileAutoCompleteFunction({
    getInputProps, suggestions, getSuggestionItemProps, loading,
  }) {
    const { handleGooglePlacesOnKeyDown, limit } = this.props;
    return (
      <div className="col px-0">
        <label htmlFor="search-inp" className="search-filters__search">
          <input
          onFocus = {
            (e) => {
              if(e.target.value != ""){
                e.target.value = "";
              }
            }
          }
            {...getInputProps({
              placeholder: 'Buscar en el mapa',
              className: 'form-control search-filters__search-input',
              id: 'search-inp',
              onKeyDown: handleGooglePlacesOnKeyDown,
            })}
          />
          <span
            className="search-filters__search-icon"
          >
            {MAGNIFYING_ICON}
          </span>
        </label>
        <span
          className="search-filters__tip"
          data-tip={`<p class='data-tip'>Mostrando los <strong class='text-primary'>
          ${limit} resultados</strong> más relevantes.</p><p class='data-tip'>Pulsa en los filtros
           para editar el número de resultados.</p>`}
          data-event='click'
        >
          {INFO_CIRCLE_ICON}
        </span>
        {!isEmpty(suggestions) ? (
          <div className="poi-position-modal__suggestions">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = `
                  poi-position-modal__suggestions-item ${
                suggestion.active ? 'poi-position-modal__suggestions-item--active' : ''
                }`;
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        ) : null}
        <ReactTooltip effect="solid" html />
      </div>
    );
  }

  render() {
    const {
      poiType,
      categoryFilters,
      isMapVisible,
      isMobile,
      switchSearchSection,
      showedSection,
      centerToUserPosition,
      handleToggleMapDisplay,
      handleSelectLimit,
      showTypeFilter,
      activeDay,
      enableNoValidated,
      limit,
      state,
      onlyPreviewApproval,
      onlyPreview,
      noPreview,
    } = this.props;
    if (state.router.location.state) {
      setTimeout(function () { centerToUserPosition() }, 300);
    }
    const { showFiltersModal, addressInputValue } = this.state;
    const daySelectOptions = this.generateDayOptions();

    return (
      <div className="row search-filters">
        <FiltersModal
          activePoiType={poiType}
          limit={limit}
          isOpen={showFiltersModal}
          closeModal={() => this.handleFiltersModal(false)}
          handleSelectLimit={handleSelectLimit}
          savedCategoryFilters={categoryFilters}
          enableNoValidated={enableNoValidated}
          onlyPreviewApproval={onlyPreviewApproval}
          onlyPreview={onlyPreview}
          noPreview={noPreview}
        />
        {!isMobile ? (
          <>
            <div className="col-12">
              <p>
                Mostrando los
                <strong className="text-primary">{` ${limit} resultados `}</strong>
                más relevantes
                <small className="d-block">
                  Pulsa en &quot;filtros&quot; para editar el número de resultados
                </small>
              </p>
            </div>
            <div className="col-lg-12 mb-lg-3">
              <button
                type="button"
                className="search-filters__toggle"
                title="Filtrar búsqueda"
                onClick={() => this.handleFiltersModal(true)}
              >
                {FILTERS_ICON}
              </button>
              <ul className="search-filters__active-list">
                {Object.keys(categoryFilters).map((filterValue) => {
                  const label = SearchFilters.getCategoryLabelFromValue(filterValue);
                  return (
                    <li className="search-filters__active-list-item" key={`filter-${label}`}>
                      {label}
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        ) : (
            <div className="col">
              <div className="row align-items-center">
                {!isEmpty(activeDay) ? (
                  <div className="col-auto px-1 search-filters__day">
                    <Select
                      onChange={this.handleDaySelectChange}
                      options={daySelectOptions}
                      value={{ value: activeDay, label: activeDay }}
                      className="day-select"
                      classNamePrefix="day-select"
                      isSearchable={false}
                    >
                      {daySelectOptions}
                    </Select>
                  </div>
                ) : null}
                <div className="col px-1">
                  <PlacesAutocomplete
                    value={addressInputValue}
                    onChange={this.handleGooglePlacesChange}
                    onSelect={this.handleGooglePlacesSelect}
                  >
                    {this.renderMobileAutoCompleteFunction}
                  </PlacesAutocomplete>
                </div>
              </div>
            </div>
          )}
        {showTypeFilter ? (
          <div className="col-auto col-lg-auto px-0 px-lg-3">
            <div className="btn-group">
              <button
                type="button"
                className={
                  poiType === 1
                    ? 'btn search-filters__type search-filters__type--active'
                    : 'btn search-filters__type'
                }
                onClick={() => {
                  this.handleTypeFilter(1);
                }}
              >
                {isMobile ? DESTINY_BLUE : 'Destinos'}
              </button>
              <button
                type="button"
                className={
                  poiType === 2
                    ? 'btn search-filters__type search-filters__type--active'
                    : 'btn search-filters__type'
                }
                onClick={() => {
                  this.handleTypeFilter(2);
                }}
              >
                {isMobile ? SERVICES_CIRCLE_ICON : 'Recomendaciones'}
              </button>
            </div>
          </div>
        ) : null}
        {!isMobile ? (
          <div className="col">
            <div className="row align-items-end">
              <div className="col-6 px-0">
                <label htmlFor="search-inp" className="form-group search-filters__search">
                  <PlacesAutocomplete
                    value={addressInputValue}
                    onChange={this.handleGooglePlacesChange}
                    onSelect={this.handleGooglePlacesSelect}
                  >
                    {this.renderAutoCompleteFunction}
                  </PlacesAutocomplete>
                </label>
              </div>
              <div className="col-auto">
                <button type="button" className="search-filters__toggle" onClick={centerToUserPosition}>
                  {CENTER_ICON}
                </button>
                <small className="tour-search__title-legend">
                    <img
                      className="mr-2"
                      width="30"
                      alt="like button"
                      src='../assets/images/iconSelectionPoi.svg'
                    />
                    topdesti selection
                  </small>
              </div>

              <div className="col-auto ml-auto">
                <button
                  type="button"
                  className="btn search-filters__map-toggle"
                  onClick={handleToggleMapDisplay}
                >
                  {EYE_ICON}
                  <span>{isMapVisible ? 'Ocultar mapa' : 'Mostrar mapa'}</span>
                </button>
              </div>
            </div>
          </div>
        ) : (
            <div className="search-filters__footer">
              <div className="row flex-nowrap align-items-center">
                <div className="col flex-shrink-1 px-1">
                  <button
                    type="button"
                    className={`
                      btn search-filters__view ${
                      showedSection === 'map' ? 'search-filters__view--active' : ''
                      }
                    `}
                    onClick={() => switchSearchSection('map')}
                  >
                    Mapa
                </button>
                </div>
                <div className="col flex-shrink-1 px-1">
                  <button
                    type="button"
                    className={`
                      btn search-filters__view ${
                      showedSection === 'gallery' ? 'search-filters__view--active' : ''
                      }
                    `}
                    onClick={() => switchSearchSection('gallery')}
                  >
                    Galería
                </button>
                </div>
                <div className="col flex-shrink-1 px-1">
                  <button
                    type="button"
                    className={`
                      btn search-filters__view ${
                      showedSection === 'list' ? 'search-filters__view--active' : ''
                      }
                    `}
                    onClick={() => switchSearchSection('list')}
                  >
                    Listado
                </button>
                </div>
                <div className="col-auto px-1">
                  <button
                    type="button"
                    className="search-filters__toggle"
                    onClick={() => this.handleFiltersModal(true)}
                  >
                    {FILTERS_ICON}
                  </button>
                  <button id="center" type="button" className="search-filters__toggle" onClick={centerToUserPosition}>
                    {CENTER_ICON}
                  </button>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const urlQueryParams = new URLSearchParams(state.router.location.search);
  const poiType = parseInt(urlQueryParams.get('searchType'), 10);
  const categoryQueryParams = urlQueryParams.getAll('cat');
  const categoryFilters = [...categoryQueryParams.entries()].reduce((acc, [key, value]) => {
    acc[value] = true;
    return acc;
  }, {});

  return {
    isMobile: state.viewport.isXs || state.viewport.isSm || state.viewport.isMd,
    categoryFilters,
    poiType: poiType === 1 || poiType === 2 ? poiType : 1,
    urlQueryParams,
    state,
  };
}

SearchFilters.propTypes = {
  poiType: PropTypes.number.isRequired,
  categoryFilters: PropTypes.objectOf(PropTypes.bool),
  isMapVisible: PropTypes.bool.isRequired,
  showedSection: PropTypes.string,
  isMobile: PropTypes.bool,
  handleToggleMapDisplay: PropTypes.func.isRequired,
  handleGooglePlacesSelect: PropTypes.func.isRequired,
  centerToUserPosition: PropTypes.func.isRequired,
  switchSearchSection: PropTypes.func,
  handleGooglePlacesOnKeyDown: PropTypes.func,
  activeDay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withActiveDay: PropTypes.bool,
  showTypeFilter: PropTypes.bool,
  tourDuration: PropTypes.number,
  handleDaySelection: PropTypes.func,
  handleAddDayToTour: PropTypes.func,
  urlQueryParams: PropTypes.objectOf(PropTypes.object),
  enableNoValidated: PropTypes.bool,
  limit: PropTypes.number.isRequired,
  handleSelectLimit: PropTypes.func.isRequired,
};

SearchFilters.defaultProps = {
  isMobile: false,
  handleGooglePlacesOnKeyDown: null,
  switchSearchSection: () => { },
  showedSection: 'map',
  categoryFilters: {},
  activeDay: 1,
  withActiveDay: false,
  showTypeFilter: false,
  tourDuration: null,
  handleDaySelection: null,
  handleAddDayToTour: null,
  urlQueryParams: null,
  enableNoValidated: false,
};

export default connect(mapStateToProps)(SearchFilters);

