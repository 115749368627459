import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { CIRCLE_CROSS_ICON, POINTS_CROSS_ICON } from '../helpers/svg-inline';
import { lockBodyScrolling, restoreBodyScrolling, isEmpty } from '../utils';
import TYPES from '../constants/types';
import reduxBeeMakeRequest from '../utils/redux-bee/redux-bee-make-request';
import { dispatch } from '../reduxStore';
import { UPDATE_CURRENT_USER } from '../constants';

Modal.setAppElement('#root');

const ModalWarning = (props) => {
  const { isOpen, closeModal, currentUser } = props;
  const { attributes: { 'hide-warning-modal': hideWarningModal = false } = {} } = currentUser || {};

  useEffect(() => {
    lockBodyScrolling();
    return restoreBodyScrolling;
  }, []);

  const handleCheckboxChange = (e) => {
    const { target: { value } } = e;
    const newCurrentUser = {
      ...currentUser,
      attributes: {
        ...currentUser.attributes,
        'hide-warning-modal': value === 'on',
      },
    };
    reduxBeeMakeRequest(dispatch, 'updateCurrentUser', null, newCurrentUser)
      .then((updatedCurrentUser) => {
        dispatch({ type: UPDATE_CURRENT_USER, currentUser: updatedCurrentUser });
      });
  };

  return (
    <Modal
      className="modal-warning"
      overlayClassName="modal-warning__overlay"
      name="warningModal"
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
    >
      <div className="container-fluid">
        <button
          className="modal-warning__close-btn"
          name="warningModal"
          type="button"
          onClick={closeModal}
        >
          {CIRCLE_CROSS_ICON}
        </button>
        <div className="row ">
          <img
            src="/assets/images/attractive-monster.png"
            alt="Aviso"
            className="modal-warning__main-image"
          />
          <div className="col col-md-8 ml-md-auto modal-warning__content">
            <h4 className="modal-warning__content-title">
              Sabemos que eres
              <br />
              increiblemente atractiv@
            </h4>
            <p className="modal-warning__content-text">
              Pero no agregues fotos personales aquí o lo penalizaremos, lo siento :)
            </p>
            <p className="modal-warning__content-text">
              Podrás enseñar lo magnífico que ha sido tu viaje incluyendo tus imágenes personales en tus
              rutas. Si necesitas más información echa un vistazo en nuestras
              {' '}
              <Link to="/">FAQs</Link>
            </p>

            <div className="d-none d-lg-block">
              <p className="modal-warning__content-text">
                Pero sobre todo no utilices fotografías que no son tuyas. Cualquier fraude con imágenes de los
                que no se tenga la autoría supondrá directamente la pérdida de
              </p>
              <p className="modal-warning__highlight">
                <span>todas las banderas</span>
              </p>
              <span className="d-lg-block text-right">del usuario infractor.</span>
            </div>

            <div className="row d-none d-lg-flex my-5 modal-warning__footer">
              <div className="col-auto modal-warning__cross-icon">{POINTS_CROSS_ICON}</div>
              <div className="col pl-0">
                <p className="text-danger mb-0">No subas imágenes personales</p>
                <p className="text-danger mb-0">No imágenes sin la propiedad intelectual</p>
              </div>
            </div>

            <div className="row">
              <div className="col-auto ml-auto">
                <button
                  className="btn btn-primary float-right"
                  name="warningModal"
                  onClick={closeModal}
                  type="button"
                >
                  Entendido
                </button>
              </div>
              <div className="col-12 mt-3">
                <label
                  htmlFor="duplicatedCheckbox"
                  className="custom-checkbox right text-right"
                >
                  No volver a mostrar este mensaje
                  <input
                    type="checkbox"
                    id="duplicatedCheckbox"
                    onChange={handleCheckboxChange}
                    defaultChecked={hideWarningModal}
                  />
                  <i />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalWarning.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentUser: TYPES.meType.isRequired,
};

function mapStateToProps(state) {
  const currentUser = state.auth.isAuthenticated && !isEmpty(state.auth.currentUser) ? state.auth.currentUser : null;
  return { currentUser };
}

export default connect(mapStateToProps)(ModalWarning);
