/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { Helmet } from 'react-helmet';
import { push } from 'connected-react-router';
import api from '../../config/api-endpoints';
import MainLayout from '../../layouts/main-layout';
import {
  USER_CURVE, RECOMMENDATION_BLUE, DESTINY_BLUE, ROUTE_BLUE, HEADER_PROFILE, history,
} from '../../helpers';
import { Footer, LoadingSpinner, ResponsiveImg } from '../../components';
import { ProfileList, ProfileInfo, ProfileForm } from './parts';
import reduxBeeMakeRequest from '../../utils/redux-bee/redux-bee-make-request';
import { isEmpty, confirmationToastr, isEmptyObject } from '../../utils';
import handleError from '../../utils/errorHandler';
import { ACCOUNT_URL, NOT_FOUND_URL, LOCALSTORAGE_SESSION } from '../../constants';
import userType from '../../constants/types/user-type';
import meType from '../../constants/types/me-type';
import ENV from '../../config/environment';
import axios from 'axios';

const handleTabs = (tabName) => {
  history.push({
    search: `?tab=${tabName}`,
  });
};


const ProfilePage = (props) => {
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [mappedErrors, setMappedErrors] = useState([]);
  const [userOwner, setUserOwner] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [ownedPlaces, setOwnedPlaces] = useState([]);
  const [ownedServices, setOwnedServices] = useState([]);
  const [ownedTours, setOwnedTours] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [limitTours, setLimitTours] = useState(4);
  const [limitPois, setLimitPois] = useState(4);
  const [limitServices, setLimitServices] = useState(4);
  const [loadingTours, setloadingTours] = useState(false);
  const [loadingPois, setloadingPois] = useState(false);
  const [loadingServices, setloadingServices] = useState(false);
  const [countTours, setcountTours] = useState();
  const [countPois, setcountPois] = useState();
  const [countServices, setcountServices] = useState();


  const {
    dispatch,
    user,
    isPrivate,
    urlNickname,
  } = props;
  const {
    id: userId = null,
    attributes: {
      name, surname, description = null,
    } = {},
  } = isEmptyObject(user) ? {} : user;

  const countToursAndPois = async () => {
    if ((user && user.id) || userId) {
      let count = await axios.post(`${ENV.host}/${ENV.namespace}/me/countToursAndPois/`, {
        id: isPrivate ? user.id : userId,
        state: isPrivate ? [1, 2, 3, 4] : 3
      })
      setcountTours(count.data.tours)
      setcountPois(count.data.pois)
      setcountServices(count.data.services)
      return;
    }
  }
  if (!countTours) {
    countToursAndPois()
  }



  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = (attributes) => {
    Object.keys(attributes).forEach((attributeName) => {
      userOwner.attributes[attributeName] = attributes[attributeName];
    });

    return reduxBeeMakeRequest(dispatch, 'updateCurrentUser', null, userOwner)
      .then((updatedUser) => {
        toastr.success('Perfil actualizado!');
        setMappedErrors({});
        setUserOwner(updatedUser);
        setIsEditing(false);
      })
      .catch((e) => {
        const { body: { errors = [] } = {} } = e;
        const requestMappedErrors = {};
        errors.forEach((error) => {
          if (error.source && error.detail) {
            requestMappedErrors[`${error.source.pointer.slice(17)}`] = error.detail;
          }
        });
        if (!isEmptyObject(requestMappedErrors)) {
          setMappedErrors(requestMappedErrors);
        }
        return Promise.resolve();
      });
  };

  const updateAvatar = (avatar) => {
    setAvatarUrl(avatar);
  };

  const setTours = (toursOwn) => {
    if (toursOwn.status == 200) {

      setOwnedTours(toursOwn.body.data)
      setIsLoadingContent(false);
    }
  }

  const handleRemoveTourBtnClick = paramsObj => new Promise((resolve) => {
    let toursOwn;
    confirmationToastr(
      '¿Estás segur@ de eliminar esta ruta?',
      async () => {
        const { content: { type: contentType, id: contentId } = {} } = paramsObj;
        if (contentType === 'tours') {
          return reduxBeeMakeRequest(dispatch, 'deleteTour', { id: contentId, limit: limitPois })
            .then((res) => {
              if (res) {
                setOwnedTours(res);
                countToursAndPois();
              }
            }
            )
            .catch(handleError);
        }

        return Promise.resolve();
      },
      () => {
        resolve(false);
      },
    );
  });

  // if props DON'T CONTAIN user retrieve the remote users (so mapStateToProps can take the user)
  useEffect(() => {
    // if user is empty load remote resources based on
    //   if rendering logged-user profile
    //   or other user profile
    if (isEmptyObject(user)) {
      setIsLoadingContent(true);
      if (isPrivate) {
        dispatch(api.getCurrentUser()).then(() => {
          setIsLoadingContent(false);
        })
          .catch(() => {
            // if me fails, logout the user
            localStorage.removeItem(LOCALSTORAGE_SESSION);
            // and refresh the page
            window.location.reload();
          });
        // .catch((error) => {
        //   setUserOwner(() => {
        //     throw error;
        //   });
        // });
      } else {
        const queryParams = {
          'filters[nickname]': urlNickname,
        };
        reduxBeeMakeRequest(dispatch, 'getUsers', queryParams)
          .then((users) => {
            // this load the user in redux-bee
            // if this is found mapStateToProps will give the page
            // the correct user prop, else none is found and visitor is redirected
            if (!Array.isArray(users) || users.length === 0) {
              dispatch(push(NOT_FOUND_URL));
            }
          }).catch((error) => {
            setUserOwner(() => {
              throw error;
            });
          });
      }
    }
  }, [user, isPrivate, dispatch, urlNickname]);

  // if props CONTAINS user retrieve related resources
  // LoadMoreOwnedPois("pois", 2);
  async function LoadMoreOwnedPois(option, limit) {

    if (option == "pois") {
      setloadingPois(true)
      setLimitPois(limitPois + limit);

      const userPoisPromise = dispatch(
        isPrivate
          ? api.getCurrentUserOwnedPois({ id: user.id, limit: (limitPois + limit) })
          : api.getUserOwnedPois({ id: userId, limit: (limitPois + limit) }),
      );

      const [ownedPoisResponse] = await Promise.all([userPoisPromise]);
      var object = ownedPoisResponse.body.data
      var responseOwnedPlaces = object.sort((a, b) => b.attributes['tours-counter'] - a.attributes['tours-counter']);


      setOwnedPlaces(responseOwnedPlaces)
      setloadingPois(false)

    } else if (option == "tours") {
      setloadingTours(true);
      setLimitTours(limitTours + limit);

      const userToursPromise = dispatch(
        isPrivate
          ? api.getCurrentUserTours({ id: user.id, limit: (limitTours + limit) })
          : api.getUserTours({ id: userId, limit: (limitTours + limit) }),
      );

      const [
        ownedToursResponse
      ] = await Promise.all([userToursPromise]);

      setloadingTours(false);
      setOwnedTours(ownedToursResponse.body.data);


    }
    else if (option == "services") {
      setloadingServices(true);
      setLimitServices(limitServices + limit);

      const userServicesPromise = dispatch(
        isPrivate
          ? api.getCurrentUserOwnedServices({ id: user.id, limit: (limitServices + limit) })
          : api.getUserOwnedServices({ id: userId, limit: (limitServices + limit) }),
      );

      const [ownedServicesResponse] = await Promise.all([userServicesPromise]);
      var object = ownedServicesResponse.body.data
      var responseOwnedServices = object.sort((a, b) => b.attributes['tours-counter'] - a.attributes['tours-counter']);
      setOwnedServices(responseOwnedServices)
      setloadingServices(false)
    }
  }

  useEffect(() => {
    async function fetchData() {
      if (!isEmptyObject(user)) {
        setIsLoadingContent(true);

        const { avatar } = user.relationships;
        const userPoisPromise = dispatch(
          isPrivate
            ? api.getCurrentUserOwnedPois({ id: user.id, limit: limitPois })
            : api.getUserOwnedPois({ id: userId, limit: limitPois }),
        );

        const userServicesPromise = dispatch(
          isPrivate
            ? api.getCurrentUserOwnedServices({ id: user.id, limit: limitServices })
            : api.getUserOwnedServices({ id: userId, limit: limitServices }),
        );

        const userToursPromise = dispatch(
          isPrivate
            ? api.getCurrentUserTours({ id: user.id, limit: limitTours })
            : api.getUserTours({ id: userId, limit: limitTours }),
        );

        const avatarPromise = isEmpty(avatar) || isEmpty(avatar.data)
          ? Promise.resolve()
          : dispatch(api.getImage({ id: avatar.data.id }));

        setUserOwner(user);

        const [
          ownedPoisResponse,
          ownedServicesResponse,
          ownedToursResponse,
          avatarResponse,
        ] = await Promise.all([userPoisPromise, userServicesPromise, userToursPromise, avatarPromise]);

        if (ownedPoisResponse.status === 200) {
          const ownedPois = ownedPoisResponse.body.data;
          const responseOwnedPlaces = ownedPois.sort((a, b) => b.attributes['tours-counter'] - a.attributes['tours-counter']);
          setOwnedPlaces(responseOwnedPlaces);
        }
        if (ownedServicesResponse.status === 200) {
          const responseOwnedServices = ownedServicesResponse.body.data;
          responseOwnedServices.sort((a, b) => b.attributes['tours-counter'] - a.attributes['tours-counter']);

          setOwnedServices(responseOwnedServices);
        }
        if (ownedToursResponse.status === 200) {

          setOwnedTours(ownedToursResponse.body.data);
        }

        if (!isEmpty(avatarResponse) && avatarResponse.status === 200) {
          const responseAvatarUrl = !isEmpty(avatarResponse) && !isEmpty(avatarResponse.body.data)
            ? avatarResponse.body.data.attributes.thumb
            : null;
          setAvatarUrl(responseAvatarUrl);
        }

        setIsLoadingContent(false);
      }
    }
    fetchData();
  }, [dispatch, isPrivate, user, userId]);

  async function updateToursCampaign() {
    if (!isEmptyObject(user)) {
      const userToursPromise = dispatch(
        isPrivate
          ? api.getCurrentUserTours({ id: user.id, limit: limitTours })
          : api.getUserTours({ id: userId, limit: limitTours }),
      );


      const [
        ownedToursResponse,
      ] = await Promise.all([userToursPromise]);


      if (ownedToursResponse.status === 200) {
        setOwnedTours(ownedToursResponse.body.data);
      }
    }
  }



  const { visibleTab } = props;
  return isLoadingContent ? (
    <LoadingSpinner />
  ) : (
      <MainLayout>
        <Helmet>
          <title>{`| ${isPrivate ? 'Tu perfil' : `Perfil de ${name} ${surname}`}`}</title>
          <meta name="title" content={`Topdesti: Perfil de personal de ${name} ${surname}`} />
          <meta property="og:title" content={`Topdesti: Perfil de personal de ${name} ${surname}`} />
          <meta property="twitter:title" content={`Topdesti: Perfil de personal de ${name} ${surname}`} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta property="twitter:description" content={description} />
          <meta property="og:image" content={avatarUrl} />
          <meta property="og:image:secure_url" content={avatarUrl} />
          <meta property="twitter:image" content={avatarUrl} />
          <meta property="og:image:width" content="75" />
          <meta property="og:image:height" content="75" />
        </Helmet>
        <div className="profile-page__header">
          <div className="profile-page__main-image"></div>
          {USER_CURVE}

          <ResponsiveImg
            src={avatarUrl}
            className="profile-page__avatar"
            type="avatar"
            wrapperClassName="profile-page__avatar-wrapper"
          >
            <figcaption className="sr-only">Avatar</figcaption>
          </ResponsiveImg>
        </div>
        <div className="profile-page__grey-bg--desktop">
          <div className="container">
            {isEditing ? (
              <ProfileForm
                ownedTours={ownedTours}
                mappedErrors={mappedErrors}
                user={userOwner}
                toggleEditMode={toggleEditMode}
                handleSave={handleSave}
                updateAvatar={updateAvatar}
              />
            ) : (
                <ProfileInfo
                  ownedTours={ownedTours}
                  user={userOwner}
                  isPrivate={isPrivate}
                  toggleEditMode={toggleEditMode}
                  updateToursCampaign={updateToursCampaign}
                  countTours={countTours}
                  loadingTours={loadingTours}
                  LoadMoreOwnedPois={LoadMoreOwnedPois}
                />
              )}
            <div className="row justify-content-center profile-page__tabs">
              <div className="col-4 col-lg-3 text-center p-0">
                <button
                  type="button"
                  onClick={() => handleTabs('tours')}
                  className={`profile-page__tabs-button ${
                    visibleTab === 'tours' ? 'profile-page__tabs-button--active' : ''
                    }`}
                >
                  {ROUTE_BLUE}
                </button>
                <p className="profile-page__tabs-text">{`${ownedTours.length} / ${countTours} rutas`}</p>
              </div>
              <div className="col-4 col-lg-3 text-center p-0">
                <button
                  type="button"
                  onClick={() => handleTabs('places')}
                  className={`profile-page__tabs-button ${
                    visibleTab === 'places' ? 'profile-page__tabs-button--active' : ''
                    }`}
                >
                  {DESTINY_BLUE}
                </button>
                <p className="profile-page__tabs-text">{`${ownedPlaces.length} / ${countPois} destinos`}</p>
              </div>
              <div className="col-4 col-lg-3 text-center p-0">
                <button
                  type="button"
                  onClick={() => handleTabs('services')}
                  className={`profile-page__tabs-button ${
                    visibleTab === 'services' ? 'profile-page__tabs-button--active' : ''
                    }`}
                >
                  {RECOMMENDATION_BLUE}
                </button>
                <p className="profile-page__tabs-text">{`${ownedServices.length} / ${countServices} servicios`}</p>
              </div>
              {/* <div className="col-3 text-center p-0">
                <button type="button">{PHOTOS_BLUE}</button>
                <span>94 fotografías</span>
              </div> */}
            </div>
            <div className="row mt-4">
              {visibleTab === 'tours' ? (
                <div className="col-12 text-center mt-4">
                  {isEmpty(ownedTours) ? (
                    <h5>Sin rutas de momento</h5>
                  ) : (
                      <>
                        <h3 className="text-center f-quicksand mb-4">Mis Rutas</h3>
                        <ProfileList
                          items={ownedTours}
                          handleRemoveBtnClick={handleRemoveTourBtnClick}
                          isPrivate={isPrivate}
                        />
                        {loadingTours ?
                          <div style={{ width: "200px" }} className="container loadingScroll">
                            <div>
                              <div className="mt-5">
                                <LoadingSpinner onlySpinner />
                              </div>
                              <p className="w-100 text-center mt-3">Cargando...</p>
                            </div>
                          </div>
                          :
                          <button
                            onClick={() => LoadMoreOwnedPois("tours", 12)}
                            className={ownedTours.length == countTours ? "d-none" : "btn btn-light btn-rounded"}
                          >cargar mas rutas</button>
                        }

                      </>
                    )}
                </div>
              ) : null}
              {visibleTab === 'places' ? (
                <div className="col-12 text-center mt-4">
                  {isEmpty(ownedPlaces) ? (
                    <h5>Sin destinos de momento</h5>
                  ) : (
                      <>
                        <h3 className="text-center f-quicksand mb-4">Mis Destinos</h3>
                        <ProfileList items={ownedPlaces} isPrivate={isPrivate} />
                        {loadingPois ?
                          <div style={{ width: "200px" }} className="container loadingScroll">
                            <div>
                              <div className="mt-5">
                                <LoadingSpinner onlySpinner />
                              </div>
                              <p className="w-100 text-center mt-3">Cargando...</p>
                            </div>
                          </div> :
                          <button
                            onClick={() => LoadMoreOwnedPois("pois", 12)}
                            className={ownedPlaces.length == countPois ? "d-none" : "btn btn-light btn-rounded"}
                          >cargar mas destinos</button>

                        }

                      </>
                    )}
                </div>
              ) : null}
              {visibleTab === 'services' ? (
                <div className="col-12 text-center mt-4">
                  {isEmpty(ownedServices) ? (
                    <h5>Sin recomendaciones de momento</h5>
                  ) : (
                      <>
                        <h3 className="text-center f-quicksand mb-4">Mis Recomendaciones</h3>
                        <ProfileList items={ownedServices} isPrivate={isPrivate} />
                        {loadingServices ?
                          <div style={{ width: "200px" }} className="container loadingScroll">
                            <div>
                              <div className="mt-5">
                                <LoadingSpinner onlySpinner />
                              </div>
                              <p className="w-100 text-center mt-3">Cargando...</p>
                            </div>
                          </div> :
                          <button
                            onClick={() => LoadMoreOwnedPois("services", 12)}
                            className={ownedServices.length == countServices ? "d-none" : "btn btn-light btn-rounded"}
                          >cargar mas servicios</button>

                        }
                      </>
                    )}
                </div>
              ) : null}
              {/* <div className="col-12 text-center mt-4">
                    <h3 className="text-center f-quicksand mb-4">Mis fotografías</h3>
                    <button type="button" className="btn btn-light btn-rounded">
                      descubrir más
                    </button>
                  </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </MainLayout>
    );
};

ProfilePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool,
  user: PropTypes.oneOfType([meType, userType]),
  visibleTab: PropTypes.string.isRequired,
  urlNickname: PropTypes.string,
};

ProfilePage.defaultProps = {
  user: null,
  urlNickname: null,
  isPrivate: false,
};

function mapStateToProps(state, ownProps) {
  const { search: queryParams, pathname } = state.router.location;
  const urlNickname = ownProps.match.params && ownProps.match.params.user_nickname;

  // get the user from the authentication
  const {
    auth: {
      currentUser: authCurrentUser,
    } = {},
    bees,
  } = state;
  const {
    attributes: {
      nickname: loggedUserNickname = null,
    } = {},
  } = !isEmptyObject(authCurrentUser)
      ? authCurrentUser
      : {};

  // isPrivate when pathname is /account or pathname includes logged user nickname
  const isPrivate = pathname === ACCOUNT_URL
    || (!isEmptyObject(loggedUserNickname) && pathname.toLowerCase().includes(loggedUserNickname.toLowerCase()));

  let user = null;
  let userId = null;
  if (!isPrivate) {
    // search in the cache the user by nickname attribute
    if (!isEmpty(urlNickname) && state.bees.entities.users) {
      user = Object.entries(state.bees.entities.users).reduce(
        (matchedUser, [cacheUserId, cacheUser]) => {
          const {
            attributes: {
              nickname: cacheUserNickname,
            } = {},
          } = cacheUser;
          if (cacheUserNickname === urlNickname) {
            userId = cacheUserId;
            return cacheUser;
          }
          return matchedUser;
        },
        null,
      );
    }
  } else {
    // if user log-out and log-in with other account cachedMes contains more than one entity
    const { me: cachedMes } = bees.entities;
    if (!isEmptyObject(authCurrentUser) && !isEmptyObject(cachedMes)) {
      const { id: authenticadedUserId = null } = authCurrentUser;
      if (!isEmptyObject(authenticadedUserId)) {
        user = cachedMes[authenticadedUserId];
      }
    }
  }


  const visibleTab = isEmpty(queryParams) || !queryParams.includes('tab=')
    ? 'tours'
    : queryParams
      .split('&')
      .filter(qp => qp.includes('tab='))[0]
      .replace(/\?|tab=/gi, '');

  return {
    isPrivate,
    isAuthenticated: state.auth.isAuthenticated,
    urlNickname,
    userId,
    user,
    visibleTab,
  };
}

export default connect(
  mapStateToProps,
  null,
)(ProfilePage);
